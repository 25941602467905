<template>
	<div id="purchase_sessions_component" class="content">
		<div v-if="loading">
			<Loader />
		</div>
		<div v-else class=" animate__animated animate__fadeIn">
			<div class="heading">
				<h4 v-html="getHeading()"></h4>
				<p v-html="getHeadingParagraph()"></p>
			</div>
			<div class="payment">
				<div v-if="user_has_card" class="py-5">
					<b-button v-if="this.status === 'input'" class="cta-button" @click="payWithSavedCard">Use your saved card</b-button>
					<b-button disabled v-else-if="this.status === 'loading'" class="cta-button" @click="payWithSavedCard">Processing <i class="fa-solid fa-circle-notch fa-spin"></i></b-button>
					<div v-else-if="this.status === 'success'">
						<p>{{ui_message}}</p>
						<b-button class="cta-button" @click="closeModal">Done</b-button>
					</div>
					<div v-else-if="status === 'authentication_required'" class=" animate__animated animate__fadeIn">
						<p>Authentication required, please wait ...</p>
						<i class="fa-solid fa-circle-notch fa-spin fa-3x"></i>
					</div>
					<div v-else class=" animate__animated animate__fadeIn">
						<p>{{ui_message}}</p>
						<b-button class="cta-button" @click="closeModal">Close</b-button>
					</div>
				</div>
				<div v-else>
					<form id="payment-form" :class="mounted?'min_height':''">
						<div id="payment-element">
							<!--Stripe.js injects the Payment Element-->
						</div>
						<div v-if="mounted" id="payment_button" class="animate__animated animate__fadeIn">
							<br/>
							<b-button variant="cta" block class="filson-book shadow" v-if="status === 'succeeded'" @click="closeModal">Close</b-button>
							<b-button v-else :disabled="disabled" type="submit" id="submit" variant="cta" block class="filson-book shadow">
								<span v-html="button_text"></span>
							</b-button>
						</div>
						<br/>
						<p id="message">{{ui_message}}</p>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import axios from "axios";
import config from "../../config/constants";
import Loader from "../Loader"

export default {
	components: {
		Loader
	},
	name: "PurchaseSessions",
	data() {
		return {
			loading: true,
			user_has_card: false,
			currency: 'EUR',
			payment_intent: '',
			status : 'input', // ['input','loading','success','failed', 'authentication_required'],
			ui_message : '',
			button_text : 'Pay now',
			mounted : true,
			disabled : false
		};
	},
	mounted() {
		this.getWallet()
	},
	props: ['number_of_sessions'],
	methods: {
		closeModal() {
			this.$bvModal.hide("purchase_sessions_modal");
		},
		async payWithSavedCard(){
			this.status = 'loading'
			const payload = {
				user_id: this.$store.getters.user_id,
				number_of_sessions : this.number_of_sessions
			};
			let res = await axios.post(
				config.api_env + "/application/api/u/wallet/purchase-sessions-with-saved-card",
				payload,
				config.options
			);
			if (res.data.success) {
				this.status = 'success'
			} else {
				if(res.data.status === 'authentication_required'){
					this.status = 'authentication_required'
					this.payment_intent = res.data.intent
					this.checkStripe()
				} else {
					this.status = 'failed';
          this.ui_message = 'Payment was unsuccessful.';
				}
			}
		},
		checkStripe() {
			if (window.Stripe) {
				if(this.user_has_card){
					this.initStripeFormConfirm();
				} else {
					this.initStripeFormCard();
				}
			} else {
				setTimeout(() => this.checkStripe(), 1000);
			}
		},
		async initStripeFormConfirm() {
			const stripe = window.Stripe(config.stripe_key);
			const _this = this
			stripe.confirmCardPayment(this.payment_intent.client_secret, {
				payment_method: this.payment_intent.last_payment_error.payment_method.id
			}).then(function(result) {
				if (result.error) {
					// Show error to your customer
					_this.ui_message = result.error.message
					_this.status = 'failed'
				} else {
					if (result.paymentIntent.status === 'succeeded') {
						// request the backend with payment_id to process payment
						_this.status = 'success'
						_this.ui_message = 'Thanks'
					}
				}
			});
		},
		async initStripeFormCard() {
			let res = await this.api({
				path: "api/u/wallet/purchase-sessions-with-card",
				number_of_sessions : this.number_of_sessions
			});
			if (res.success === false) {
				this.ui_message = 'Something went wrong'
				return
			}
			//create a Stripe client - stripe script is loaded in index.html
			
			const appearance = {
				theme: 'stripe',
				
				variables: {
					colorPrimary: 'white',
					colorBackground: 'white',
					colorText: '#30313d',
					colorDanger: '#df1b41',
					fontFamily: 'Ideal Sans, system-ui, sans-serif',
					spacingUnit: '3px',
					borderRadius: '5px',
				}
			};
			
			const stripe = window.Stripe(config.stripe_key);
			const elements = stripe.elements({
				clientSecret : res.client_secret,
				appearance : appearance
			});
			
			const paymentElement = elements.create("payment");
			paymentElement.mount("#payment-element");
			
			const form = document.getElementById('payment-form');
			this.mounted = true
			form.addEventListener('submit', async (event) => {
				this.button_text = 'Processing <i class="fa-solid fa-circle-notch fa-spin"></i>'
				this.disabled = true
				this.ui_message = ''
				event.preventDefault();
				let return_url = window.location.origin+'/clinics/digital'
				const res = await stripe.confirmPayment({
					//`Elements` instance that was used to create the Payment Element
					elements,
					redirect: "if_required",
					confirmParams: {
						return_url: return_url
					}
				});
				if(res.hasOwnProperty('error')){
					this.ui_message = res.error.message
					this.button_text = 'Pay Now'
					this.disabled = false
				} else if(res.hasOwnProperty('paymentIntent')) {
					if(res.paymentIntent.status === 'succeeded') {
						this.ui_message = 'Payment Succeeded.';
						this.button_text = 'Thanks'
						this.status = 'succeeded'
					}
				} else {
					// dont know whats going on
					this.message = 'Something went wrong'
				}
				
			});
		},
		async getWallet() {
			const payload = {
				user_id: this.$store.getters.user_id,
			};
			let res = await axios.post(
				config.api_env + "/application/api/u/wallet/get-wallet",
				payload,
				config.options
			);
			if (res.data.success) {
				this.user_has_card = !!res.data.card_type
				this.currency = res.data.currency
				this.loading = false
				if(!this.user_has_card){
					this.checkStripe()
				}
			}
		},
		getHeading(){
			console.log(this.number_of_sessions)
			if(this.number_of_sessions === 1){
				return 'Purchase Single Session'
			} else if(this.number_of_sessions === 3) {
				return 'Purchase 3 Sessions Bundle'
			} else {
				return 'Purchase 6 Sessions Bundle'
			}
		},
		getHeadingParagraph(){
			let am = this.getCurrencyPriceString(this.currency, 5000, false)
			if(this.number_of_sessions === 1){
				return 'Add '+am+' to your wallet<br/> using your payment card';
			} else if(this.number_of_sessions === 3) {
        let am = this.getCurrencyPriceString(this.currency, 5000 * 3, false)
				return 'Purchase the value of 3 sessions ('+am+')<br/> and receive a 5% discount<br/>Amount to pay: '+this.getCurrencyPriceString(this.currency, 5000 * 3 * 0.95, true);
			} else {
        let am = this.getCurrencyPriceString(this.currency, 5000 * 6, false)
				return 'Purchase the value of 6 sessions ('+am+')<br/> and receive a 10% discount<br/>Amount to pay: '+this.getCurrencyPriceString(this.currency, 5000 * 6 * 0.90, true);
			}
		}
	},
};
</script>
<style lang="less">
#purchase_sessions_modal {
	text-align: center;
	box-shadow: 0px 0px 100px rgba(0,0,0,0.5) inset;
	.modal-body {
		color: rgba(56, 28, 82, 0.8);
	}
}
#purchase_sessions_component.content{
	padding: 80px 30px 50px;
	min-height: 36rem;
	h4 {
		text-align: center;
		font-size: 2rem;
		font-family: "FilsonProBold", sans-serif;
	}
	p {
		font-size: 14px;
		padding: 20px 50px;
	}
	.success i {
		font-size: 70px;
	}
	&.success i {
		color: forestgreen;
	}
	#submit {
		font-size: 14px;
		font-family: "FilsonProLight", sans-serif;
	}
	#payment_form_message {
		font-size: 14px;
		margin-top: 2rem;
	}
}
</style>
