<template>
  <div id="terms" class="container-fluid px-0">
    <div class="main no-side-bar">
      <div v-if="$store.state.user.authenticated">
        <TopNav></TopNav>
        <MobileNav></MobileNav>
      </div>
      <div class="page-header bg-brand-secondary">
        <div class="content-container container">
          <div class="page-title col-12">
            <h2>{{ componentText.title }}</h2>
          </div>
        </div>
      </div>
      <div class="content-wrap">
        <div class="content-container row py-5">
          <div class="content-card w-100 p-5">
            <div
              class="content-inner p-md-5 m-md-5 p-0 m-0"
              v-html="decodeData(content)"
            ></div>
            <div
              class="
                d-flex
                flex-column
                align-items-center
                justify-content-center
                mt-5
                brand-text
              "
              v-if="!this.$store.getters.user.user.tc_accepted && $store.state.user.authenticated"
            >
              <div
                @click="toggleCheckbox"
                class="d-flex flex-row align-items-center mb-4"
              >
                <i class="far fa-square fa-2x" v-if="!checkbox"></i>
                <i class="fas fa-check-square fa-2x" v-else></i>
                <p class="ml-4">
                  I have read and agree to the above terms and conditions.
                </p>
              </div>

              <b-button
                class="cta-button px-5 pt-3 pb-4"
                :disabled="!checkbox"
                @click="agreeToTCs()"
                ><span v-if="agreementSuccess === false">Agree!</span
                ><span v-else>Success!</span></b-button
              >
            </div>
            <div
              v-else-if="agreementSuccess"
              class="d-flex justify-content-center"
            >
              <p class="brand-text">
                You have successfully agreed to the terms and conditions, you'll
                be redirectly shortly..
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer v-if="$store.state.user.authenticated"></Footer>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import config from "../config/constants";
import TopNav from "@/components/TopNav.vue";
import MobileNav from "@/components/MobileNav.vue";
import Footer from "../components/Footer";
export default {
  name: "TCs",
  components: {
    Footer,
    TopNav,
    MobileNav,
  },
  data() {
    return {
      agreementSuccess: false,
      checkbox: false,
      content: "",
      isLoading: true,
    };
  },
  mounted() {
    if (this.$route.query.lang) {
      this.$store.commit("setLanguage", this.$route.query.lang);
    }
    this.getTCS();
  },
  methods: {
    toggleCheckbox() {
      this.checkbox = !this.checkbox;
    },
    async agreeToTCs() {
      if (this.checkbox) {
        const payload = {
          user_id: this.$store.getters.user_id,
          terms_type: ["tc"],
        };
        let res = await axios.post(
          config.api_env + "/application/api/u/user/accept-terms",
          payload,
          config.options
        );
        if (res.data.success) {
          let self = this;
          this.$store.dispatch("setUserTermsAgreementTrue");
          this.agreementSuccess = true;
          setTimeout(function () {
            self.$router.push("/");
          }, 2000);
        } else if (res.data.err && res.data.err.includes("3994")) {
          this.$store.dispatch("logout");
          this.$router.push("/login").catch((err) => {});
        }
      }
    },
    decodeData(data) {
      try {
        return Buffer.from(data, "base64");
      } catch (error) {
        return data;
      }
    },
    async getTCS() {
      let slug = this.$store.getters.user.user_tc_slug;
      if(slug === 'generic_tc' && this.$store.getters.client.tc_slug) {
        slug = this.$store.getters.client.tc_slug
      }
      const payload = {
        slug,
      };
      let res = await axios.post(
        config.api_env + "/application/api/u/info/get-info",
        payload,
        config.options
      );
      if (res.data.success) {
        this.content = res.data.dynamic_string.string;
        this.isLoading = false;
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login").catch((err) => {});
      }
    },
  },
  computed: {
    componentText() {
      return this.getText.terms;
    },
  },
  watch: {
    skin(newVal) {
      this.$store.commit("setSkin", newVal);
    },
  },
};
</script>
<style lang="less">
#terms {
  font-size: 1.6rem;
  font-family: "DMSans", sans-serif;
  .page-header {
    padding: 60px 0;
  }
  .page-title {
    text-align: left;
    h2 {
      text-align: left;
      font-size: 3.8rem;
      letter-spacing: 0.7rem;
      font-family: "FilsonProBold", sans-serif;
      color: #fff;
    }
  }
  .content-wrap {
    background: #f4f4f4;
    white-space: pre-wrap;
    .content-card {
      transform: translateY(-80px);
      background: #ffffff;
      max-width: 1000px;
      margin: auto;
      section {
        margin-bottom: 30px;
      }
      h4 {
        margin-top: 30px;
        margin-bottom: 20px;
      }
    }
  }
  @media (max-width: 992px) {
    .page-header {
      padding-top: 100px;
    }
    .content-card {
      transform: translateY(0);
    }
  }
  p.brand-text {
    text-align: center;
    font-size: 1.6rem;
    font-weight: bold;
    max-width: 500px;
  }
}
</style>
