<template>
  <div id="TherapyOption" class="mx-auto my-3" :class="{selected: selected}" @click="$emit('selectOption',optionNumber)">
    <div class="image-holder">
      <img v-if="image" :src="image" alt="picture of specialist" style="width: 100%">
      <i v-else class="fa-sharp fa-solid fa-check"></i>
    </div>
    <div class="pl-4 textHolder">{{text}} <span v-if="degree">{{degree}}</span></div>
  </div>
</template>

<script>

export default {
  name: "TherapyOption",
  methods: {

  },
  props: {
    text: {
      type: String
    },
    degree: {
      type: String,
      default: null
    },
    optionNumber: {
      type: Number
    },
    selected: {
      type: Boolean
    },
    image: {
      type: String,
      default: null
    }
  }

}
</script>

<style lang="less">
#TherapyOption {
  max-width: 600px;
  padding: 15px 30px 15px 30px;
  border: 1px solid #F0EFEF;
  border-radius: 10px;
  background-color: white;
  align-items: center;
  cursor: pointer;
  display: grid;
  grid-template-columns: 60px 1fr;
  .textHolder {
    font-size: 15px;
    font-family: FilsonProBold, Arial, Helvetica, sans-serif;
    color: #979797;
    span {
      font-size: 11px;
      font-family: DMSans, Arial, Helvetica, sans-serif;
    }
  }
  .image-holder {
    width: 50px;
    height: 50px;
    background-color: #FAFAFA;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    i {
      font-size: 28px;
      color: #C4C4C4;
    }
  }
  &.selected {
    background-color: #D60570;
    .textHolder {
      color: white;
    }
  }
  &:hover {
    background-color: #D60570;
    .textHolder {
      color: white;
    }
  }
}
</style>