<template>
  <div id="TherapySendSchoolQuestionnaire">
    <div class="content-container p-5">
      <p class="mt-5"  v-if="!complete">This form should be completed by the school or college of the person that is being assessed. You can invite another person by email to complete this questionnaire form. Or Alternatively, you can print this form and have the contact fill it in person. You will need to return to this web form to input the answers.</p>
      <p class="mt-5" v-else>This form has been completed</p>
      <div class="emailHolder" v-if="!complete">
        <div><span>Email address </span>to send invite form to (they will not see any other answers to any other questionnaires you have completed):</div>
        <div class="emailInputHolder" >
          <b-form-input class="emailInput" v-model="email"/>
          <div class="sendBtn position-relative" :class="{disabled: !validEmail}" @click="sendEmail">
            <span v-if="emailSending" class="button-loading"></span>
            <div v-else>Send Invite</div>
          </div>
        </div>
      </div>
      <div class="questionnaire-container" v-if="form">
        <hr class="my-5">
<!--        <div v-if="!complete">-->
<!--          <button @click="downloadDocument" class="therapyBtn2 mx-auto float-right">Download Blank Form</button>-->
<!--        </div>-->
        <div v-for="q in form.questions">
          <TherapyTextQuestion :required="false" :label="q.question" :item="q.answer" @update="(data)=> q.answer = data" v-if="q.type === 'text'" :disabled="true"/>
          <TherapyDateOfBirth :required="false" :label="q.question" :item="q.answer" @update="(data)=> q.answer = data" v-if="q.type === 'dob'" :disabled="true"/>
          <TherapyPhoneNo :required="false" :label="q.question" :item="q.answer" @update="(data)=> q.answer = data" v-if="q.type === 'phone'" :disabled="true"/>
          <TherapyChoiceBox :label="q.question" :item="q.answer" :options="q.options" @update="(data)=> q.answer = data" v-if="q.type === 'choice'" :disabled="true"/>
          <TherapyTextBox :required="false" :label="q.question" :item="q.answer" @update="(data)=> q.answer = data" :rows="10" v-if="q.type === 'longText'" :disabled="true"/>
          <TherapySelectQuestion :label="q.question" :item="q.answer" :options="q.options" @update="(data)=> q.answer = data" v-if="q.type === 'select'" :disabled="true"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import forms from '../../assets/therapyForms.json'
import TherapyDateOfBirth from "./Questions/TherapyDateOfBirth.vue";
import TherapyChoiceBox from "./Questions/TherapyChoiceBox.vue";
import TherapyPhoneNo from "./Questions/TherapyPhoneNo.vue";
import TherapyTextBox from "./Questions/TherapyTextBox.vue";
import TherapyTextQuestion from "./Questions/TherapyTextQuestion.vue";
import TherapySelectQuestion from "./Questions/TherapySelectQuestion.vue";
export default {
  name: "TherapySendSchoolQuestionnaire",
  components: {TherapyTextBox, TherapyPhoneNo, TherapyChoiceBox, TherapyDateOfBirth, TherapyTextQuestion, TherapySelectQuestion},
  data() {
    return {
      email: null,
      emailSending: false,
      form: {},
      forms: forms,
      complete: false
    }
  },
  mounted() {
    this.getQuestionnaire();
    this.form = this.forms['school_questionnaire'];
  },
  computed: {
    first_name() {
      return this.$route.query.first_name
    },
    last_name() {
      return this.$route.query.last_name;
    },
    validEmail() {
      return this.validateEmailAddress(this.email)
    },
  },
  methods: {
    async getQuestionnaire() {
      const res = await this.api({
        path: 'api/u/connected-mental-health/get-school-questionnaire'
      });
      if(res.success) {
        const answers = JSON.parse(atob(res.data.content));
        this.form.questions.forEach(q => {
          if(answers.hasOwnProperty(q.id)) q.answer = answers[q.id];
        });
        if(res.data.complete) this.complete = true;
      }
    },
    downloadDocument() {
      // //const documentPath = require('../../assets/forms/School and college questionnaire.docx');
      // const link = document.createElement('a');
      // link.href = documentPath;
      // link.download = 'your-document.docx';
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
    },
    async sendEmail() {
      if(!this.validEmail) return;
      this.emailSending = true;
      await this.api({
        path: 'api/u/connected-mental-health/send-school-questionnaire',
        recipient_email: this.email,
        patient_first_name: this.first_name,
        patient_last_name: this.last_name
      })
      this.emailSending = false;
    }
  }

}
</script>

<style lang="less">
#TherapySendSchoolQuestionnaire {
  .button-loading::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 4px solid transparent;
    border-radius: 50%;
    border-top-color: white;
    border-bottom-color: white;
    animation: button-loading-spinner 1s ease infinite;
  }
  p {
    max-width: 850px !important;
  }
  .emailHolder {
    color: #394961;
    font-size: 1.5rem;
    max-width: 850px !important;
    span {
      font-family: FilsonProBold, Arial, Helvetica, sans-serif;
    }
    .emailInputHolder {
      max-width: 500px;
      margin-top: 10px;
      display: flex;
      .emailInput {
        width: 75%;
        background-color: white;
        font-size: 1.5rem;
        height: 35px;
        border-radius: 5px 0 0 5px;
      }
      .sendBtn {
        border-radius: 0 5px 5px 0;
        width: 25%;
        height: 35px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        cursor: pointer;
        background-color: #ED3A75;
        &:hover {
          background-color: darken(#ED3A75, 5%);
        }
        &.disabled {
          background-color: grey !important;
        }
      }
    }

  }
}
</style>