<template>
	<div id="who_question" class="text-center">
		<br/>
		<h4>{{question.text}}</h4>
		<br/>
		<br/>
		
		<div class="px-5">
			<input
				type="range"
				id="who_slider"
				class="pulse-answer-slider w-100"
				min="0"
				:max="question.options.length - 1"
				value="2"
				@change="clickSlider()"
				@click="clickSlider()"
				style="padding: 0px 0px 0px 0px; height: 30px"
			/>
			<br/>
		</div>
		
		<div class="options_text">
			<div
				:class="current_answer.length > 0 && option.text === current_answer[0].answer ? 'step-container text-center current-answer':'step-container text-center'"
				v-for="(option, i) in question.options"
				:key="i"
				@click="clickSlider(i)"
			>
				{{ option.text }}
			</div>
		</div>
		
		<hr/>
		<br/>
		
		<div class="text-right">
			<b-button
			class="cta-button pathway-card-button"
          :disabled="current_answer.length === 0" variant="purple" @click="answerQuestion()">Next</b-button>
		</div>
	</div>
</template>
<script>

export default {
	components: {
	
	},
	name: "Question",
	data() {
		return {
			current_slider_value : 0,
			current_answer : []
		};
	},
	watch: {
		question : {
			deep : true,
			handler (question) {
				this.current_answer = []
			}
		}
	},
	methods: {
		async answerQuestion() {
			let res = await this.api({
				path : "api/u/surveys/answer-fixed-survey-question",
				question_id: this.question.id,
				answer: this.current_answer,
			});
			if (res.success) {
				this.flipQuestion()
			}
		},
		clickSlider(position = null) {
			if(position !== null){
				document.getElementById("who_slider").value = position
			} else {
				position = document.getElementById("who_slider").value
			}
			this.current_answer = [
				{
					id: this.question.options[position].id,
					answer: this.question.options[position].text,
				},
			];
		},
		clickOption(){
		
		}
	},
	props : ['question'],
	inject : ['flipQuestion'],
};
</script>
<style lang="less">
#who_question {
	min-height: 200px;
	//slider styles jesus christ
	.step-container {
		width: 16%;
		display: inline-block;
		transition: all 0.1s ease-in-out;
		padding: 15px;
		line-height: 1.1em;
		min-height: 2em;
		&:hover {
			cursor: pointer;
		}
	}
	.current-answer {
		transform: scale(1.3, 1.3);
		font-weight: bold;
		text-shadow: 0px 0px 10px #fff;
	}
	input[type="range"] {
		height: 36px;
		-webkit-appearance: none;
		margin: 10px 0;
		width: 100%;
		background-color: transparent !important;
	}
	input[type="range"]:focus {
		outline: none;
	}
	input[type="range"]::-webkit-slider-runnable-track {
		width: 100%;
		height: 30px;
		cursor: pointer;
		animate: 0.2s;
		box-shadow: 0px 0px 0px #000000;
		background: #eee;
		border-radius: 15px;
		border: 0px solid #000000;
	}
	input[type="range"]::-webkit-slider-thumb {
		box-shadow: 0px 0px 0px #000000;
		border: 0px solid #000000;
		height: 30px;
		width: 30px;
		border-radius: 15px;
		background: #aaa;
		cursor: pointer;
		-webkit-appearance: none;
		margin-top: 0px;
	}
	input[type="range"]:focus::-webkit-slider-runnable-track {
		background: #eee;
	}
	input[type="range"]::-moz-range-track {
		width: 100%;
		height: 30px;
		cursor: pointer;
		animate: 0.2s;
		box-shadow: 0px 0px 0px #000000;
		background: #eee;
		border-radius: 15px;
		border: 0px solid #000000;
	}
	input[type="range"]::-moz-range-thumb {
		box-shadow: 0px 0px 0px #000000;
		border: 0px solid #000000;
		height: 30px;
		width: 30px;
		border-radius: 15px;
		background: #aaa;
		cursor: pointer;
	}
	input[type="range"]::-ms-track {
		width: 100%;
		height: 30px;
		cursor: pointer;
		animate: 0.2s;
		background: transparent;
		border-color: transparent;
		color: transparent;
	}
	input[type="range"]::-ms-fill-lower {
		background: #eee;
		border: 0px solid #000000;
		border-radius: 30px;
		box-shadow: 0px 0px 0px #000000;
	}
	input[type="range"]::-ms-fill-upper {
		background: #eee;
		border: 0px solid #000000;
		border-radius: 30px;
		box-shadow: 0px 0px 0px #000000;
	}
	input[type="range"]::-ms-thumb {
		margin-top: 1px;
		box-shadow: 0px 0px 0px #000000;
		border: 0px solid #000000;
		height: 30px;
		width: 30px;
		border-radius: 15px;
		background: #aaa;
		cursor: pointer;
	}
	input[type="range"]:focus::-ms-fill-lower {
		background: #eee;
	}
	input[type="range"]:focus::-ms-fill-upper {
		background: #eee;
	}
	// end of slider
  .cta-button-id{
    background-color: #651242;
    color: white;
  }
  .cta-button-id:hover {
    background-color: #651242;
    color: white;
  }
}
</style>