<template>
  <div id="Home">
    <div class="home-inner" v-if="$store.getters.user.home_page === 'old_default'">
      <transition-group name="fade" tag="div">
        
        <component
          v-for="(widget, i) in userWidgets"
          :key="widget.widget_id"
          v-if="widget.widget_id !== '11'"
          :is="widgets[widget.widget_id]"
          @stats-info="statsPopup()"
          class="home-widget"
        ></component>
      </transition-group>
    </div>
    <div class="bg-white" v-else-if="$store.getters.user.home_page === 'banner_home'">
      <div class="bg-grey d-flex flex-column justify-content-center align-items-center">
        <h2 class="landg-title">Welcome to your Health and Wellbeing Platform</h2>
        <p class="landg-text">This is your day to day mental health, wellbeing and counselling service, that provides in the moment support to you and your immediate family, 24/7 and 365 days of the year.</p>
      </div>
      <transition-group name="fade" tag="div">
        <component
          v-for="(widget, i) in userWidgets"
          :key="widget.widget_id"
          v-if="widget.widget_id === '5'"
          :is="widgets[widget.widget_id]"
          @stats-info="statsPopup()"
          class="home-widget"
        ></component>
      </transition-group>
      <div class="container">
        <LandGHomeCard :type="'wellbeing'"></LandGHomeCard>
        <LandGHomeCard :type="'eap'"></LandGHomeCard>
        <LandGHomeCard :type="'digital-gym'"></LandGHomeCard>
        <LandGHomeCard :type="'studio'"></LandGHomeCard>
      </div>
    </div>
    <div v-else-if="$store.getters.user.home_page === 'old_default_with_dc_banner'">
      <div id="dcbannercontainer" class="container py-5" v-if="['UK','NI','UK & NI','Ireland'].includes($store.getters.country)">
        <HomeDigitalClinicsBanner class="w-100"/>
      </div>
      <transition-group name="fade" tag="div">
        
        <component
          v-for="(widget, i) in userWidgets"
          :key="widget.widget_id"
          v-if="widget.widget_id !== '11'"
          :is="widgets[widget.widget_id]"
          @stats-info="statsPopup()"
          class="home-widget"
        ></component>
      </transition-group>
    </div>
    <div v-else-if="$store.getters.user.home_page === 'old_default_boi'">
      <home-carousel />
      <div class="page-bg bg-white px-md-5">
        <home-happening></home-happening>
        <home-events class="pb-5 mb-5"></home-events>
      </div>
    </div>
    <div v-else-if="$store.getters.user.home_page === 'default'">
      <DigitalFrontDoor></DigitalFrontDoor>
    </div>
    <div v-else-if="$store.getters.user.home_page === 'tmh'">
      <HomeTMH></HomeTMH>
      <HomeDigitalGym></HomeDigitalGym>
      <HomeWellbeingStudio></HomeWellbeingStudio>
    </div>
    <div  v-else-if="$store.getters.user.home_page === 'dc'">
      <DigitalClinicsGeneric></DigitalClinicsGeneric>
    </div>
    <div v-else-if="$store.getters.user.home_page === 'events'">
      <Events />
    </div>
    <div class="home-inner" v-else>
      
      <transition-group name="fade" tag="div">
        <component
          v-for="(widget, i) in userWidgets"
          :key="widget.widget_id"
          v-if="widget.widget_id !== '11'"
          :is="widgets[widget.widget_id]"
          @stats-info="statsPopup()"
          class="home-widget"
        ></component>
      </transition-group>
    </div>
    <div class="stats-popup" v-if="showStatsPopup">
      <div class="pop-up-background" @click="hidePopup"></div>
      <div class="pop-up-content bg-brand-secondary">
        <div class="content-inner" v-html="statsPopupContent"></div>
        <b-button class="close-popup" @click="hidePopup"
          ><font-awesome-icon icon="times-circle"></font-awesome-icon
        ></b-button>
      </div>
    </div>
    <popup
      v-if="showPopup === true"
      :content="popupContent"
      v-on:close="hidePopup"
      @back="hidePopup"
    ></popup>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import config from "../config/constants";
import Loader from "../components/Loader.vue";
import HomeCarousel from "../components/Home/HomeCarousel.vue";
import HomeEvents from "../components/Home/HomeEvents.vue";
import HomeWellbeing from "../components/Home/HomeWellbeing.vue";
import HomeHappening from "../components/Home/HomeHappening.vue";
import HomeStats from "../components/Home/HomeStats.vue";
import HomeFitness from "../components/Home/HomeFitness.vue";
import HomeNutrition from "../components/Home/HomeNutrition.vue";
import HomeRewards from "../components/Home/HomeRewards.vue";
import HomeMetrics from "../components/Home/HomeMetrics.vue";
import HomeEap from "../components/Home/HomeEap.vue";
import HomeTMH from "../components/Home/HomeTMH.vue";
import HomeWellbeingStudio from "../components/Home/HomeWellbeingStudio.vue";
import HomeDigitalGym from "../components/Home/HomeDigitalGym.vue";
import Popup from "@/components/Popup.vue";
import HomeDigitalClinicsBanner from "../components/Home/New/HomeDigitalClinicsBanner.vue";
import DigitalClinicsGeneric from "../pages/DigitalClinicsGeneric.vue";
import Blank from "../pages/Blank.vue";
import LandGHomeCard from "../components/Home/LandGHomeCard.vue";
import DigitalFrontDoor from "./DigitalFrontDoor.vue";
import Events from "@/pages/Events.vue";


export default {
  name: "home",
  components: {
    Events,
    Loader,
    HomeEap,
    HomeMetrics,
    HomeRewards,
    HomeNutrition,
    HomeFitness,
    HomeHappening,
    HomeWellbeing,
    HomeEvents,
    HomeCarousel,
    HomeStats,
    HomeTMH,
    HomeWellbeingStudio,
    HomeDigitalGym,
    Popup,
    HomeDigitalClinicsBanner,
    DigitalClinicsGeneric,
    Blank,
    LandGHomeCard,
    DigitalFrontDoor,
  },
  data() {
    return {
      courses: {},
      widgets: {
        1: HomeStats,
        2: HomeEvents,
        3: HomeMetrics,
        4: HomeRewards,
        5: HomeWellbeing,
        6: HomeNutrition,
        7: HomeFitness,
        8: HomeEap,
        9: HomeHappening,
        10: HomeCarousel,
        11: "",
      },
      userWidgets: [],
      showStatsPopup: false,
      showPopup: false,
    };
  },
  mounted() {
    if(this.$store.getters.skin === 29){
      this.$router.push('/gp')
    }
    this.getHomeLayout();
    if(this.$route.query.session_id === "cancelled") {
      this.cancelStripeTransaction();
    }
    if (this.$route.name === "iosAppDownload") {
      this.requestAppVoucher();
    }
  },
  methods: {
    async cancelStripeTransaction() {
      const payload = {
        user_id: this.$store.getters.user_id
      };
      await axios.post(
          config.api_env + "/application/api/u/events/cancel-intended-stripe-payment",
          payload,
          config.options
      );
    },
    testSentry() {
      throw new Error("Sentry Error");
    },
    async getHomeLayout() {
      const payload = {
        user_id: this.$store.getters.user_id,
        platform: "web",
      };
      let res = await axios.post(
        config.api_env + "/application/api/customisations/get-home-layout",
        payload,
        config.options
      );
      if (res.data.success) {
        this.userWidgets = res.data.widgets;
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login").catch((err) => {});
      }
    },
    statsPopup() {
      this.showStatsPopup = true;
    },
    hidePopup() {
      this.showStatsPopup = false;
      this.showPopup = false;
    },
    async requestAppVoucher() {
      const payload = {
        user_id: this.$store.getters.user_id,
      };
      let res = await this.axios.post(
        config.api_env + "/application/api/redeem/enterprise-app-voucher",
        payload,
        config.options
      );

      if (res.data.success === true) {
        //TODO update popup content
        this.popupContent = {
          header: "iOS Voucher Link Emailed",
          message:
            "We have emailed you a unique voucher number to install the Vodafone Evolve mobile app",
          subtext: "*Please check your junkmail!",
          buttonText: "OK",
        };
        this.showPopup = true;
        this.emailSent = true;
      } else {
        this.popupContent = {
          header: "Oops!",
          message: "Looks like something went wrong, please try again later.",
          buttonText: "OK",
        };
        this.showPopup = true;
      }
    },
  },
  computed: {
    componentText() {
      return this.getText.loggedIn.home;
    },
    statsPopupContent() {
      return (
        "<h4>" +
        this.componentText.homeStats.steps +
        "</h4><p>" +
        this.componentText.homeStats.stepsMessage +
        "</p>" +
        "<h4>" +
        this.componentText.homeStats.distance +
        "</h4><p> " +
        this.componentText.homeStats.distanceMessage +
        "</p>" +
        "<h4>" +
        this.componentText.homeStats.calories +
        "</h4><p>" +
        this.componentText.homeStats.caloriesMessage +
        " </p>" +
        "<h4>" +
        this.componentText.homeStats.points +
        "</h4><p> " +
        this.componentText.homeStats.pointsMessage +
        " </p>"
      );
    },
  },
};
</script>
<style lang="less">
.bg-white{
  background-color: white !important;
}
.home-link {
  font-family: FilsonProBold, sans-serif;
  font-size: 18px;
  display: inline;
  padding-bottom: 5px;
}
.stats-popup {
  display: flex;
  align-items: center;
  z-index: 500;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
  .pop-up-content {
    max-width: 636px;
    width: 100%;
    margin: auto;
    border-radius: 25px;
    padding: 50px 20px;
    font-size: 1.6rem;
    text-align: left;
    z-index: 10;
    color: #fff;
    position: relative;
    p {
      color: #fff !important;
      margin-bottom: 25px;
    }
    h4 {
      font-family: "FilsonProBold", sans-serif;
      font-size: 2.2rem;
      color: #fff;
      margin-bottom: 15px;
      text-align: left;
    }
    .close-popup {
      position: absolute;
      top: 0;
      right: 0;
      background-color: transparent;
      width: 50px;
      height: 50px;
      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
  .pop-up-background {
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 5;
    background: transparent;
  }

}
.landg-title{
  padding: 50px 10px 30px;
  margin-bottom: 0 !important;
  font-size: 2rem !important;
  font-family: 'FilsonProBold', sans-serif !important;
  font-weight: bold;
}
.landg-text{
  font-size: 1.6rem;
  max-width: 520px;
  text-align: center;
  padding: 10px;
}
@media only screen and (max-width: 768px){
  #dcbannercontainer {
      max-width: calc(100vw - 40px) !important;
  }
}
.c-19-banner {
  display: flex;
  justify-content: center;
  img {
    width: 75%;
    cursor: pointer;
    @media only screen and (max-width: 992px) {
      width: 100%;
    }
  }
}
</style>
