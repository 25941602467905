<template>
  <div id="HighFiveDemo" :class="{displayHighFive: displayHighFive}">
    <i class="fa-solid fa-hand-sparkles"></i> Katie sent you a high five
  </div>
</template>

<script>
export default {
  name: "HighFiveDemo",
  data() {
    return {
      displayHighFive: false,
    }
  },
  mounted() {
    setTimeout(()=> {
      this.displayHighFive = true;
    }, 5000);
    setTimeout(()=> {
      this.displayHighFive = false;
    }, 10000)
  }
}
</script>

<style lang="less">
#HighFiveDemo {
  opacity: 0;
  transition: none;
  bottom: 0;
  &.displayHighFive {
    opacity: 1;
    transform: translateY(-50px);
    transition: opacity 1s ease-in-out, transform 2s linear;
  }
  display: flex;
  align-items: center;
  padding: 10px 20px;
  position: absolute;
  right: 10px;
  bottom: 0;
  background-color: rgba(0,0,0,0.7);
  color: white;
  font-family: FilsonProBold, Arial, Helvetica, sans-serif;
  font-size: 1.4rem;
  i {
    margin-right: 10px;
    font-size: 1.3rem;
    color: #ED3A75;
  }
}
</style>