<template>
  <div id="DigitalClinicsBookingCard">
    <div class="booking_inner">
      <div class="row no-gutters">
        <div class="col-9 px-1">
          <span class="coach">Session with {{ serviceProvider.name }}</span>
          <br v-if="booking.generic_label"/>
          <span class="coach" v-if="booking.generic_label">{{booking.generic_label}}</span>
          <br />
          <span class="day">{{ getDateLineText(booking.start) }}</span>
          <br />
          <span class="hour"><i class="fas fa-clock" aria-hidden="true"></i>
            {{ getHoursMinutes(booking.start) }} -
            {{ getHoursMinutes(booking.end) }}</span>
        </div>
        <div class="col-3 text-right">
          <span class="date">{{ getDay(booking.start) }}</span>
          <br />
          <span class="month">{{ getMonth(booking.start) }}</span>
        </div>
      </div>
      <div class="corner">
        <b-button v-if="shouldShowLink || true" @click="openModal(booking, serviceProvider)" variant="cta" class="video corner_inner">
          <span>Start Video </span> &nbsp;&nbsp;&nbsp;<i class="fas fa-video"></i>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import MeetingRoomModal from "./MeetingRoomModal.vue";
import EarlyModal from "./EarlyModal.vue";


export default {
  name: "DigitalClinicsBookingCard",
  props: {
    booking: Object,
    serviceProvider: Object,
    isEarliest: Boolean,
  },
  data() {
    return {
      shouldShowLink: false,
    };
  },
  mounted() {
    let self = this;
    let now = moment();
    let bookingStart = moment(this.booking.start);
    if (this.isEarliest) {
      if (now.isSameOrAfter(bookingStart.subtract(10, "minute"))) {
        this.shouldShowLink = true;
      } else {
        let offset = bookingStart.diff(now);
        if (offset < 2147483647) {
          setTimeout(() => {
            self.shouldShowLink = true;
          }, offset);
        }
      }
    }
  },
  methods: {
    openModal(booking, sp) {
      if (moment(booking.start).diff(moment(), 'days') >= 1) {
        // this.openEarlyModal(booking, sp);
        this.openBookingModal(booking, sp);
      } else {
        this.openBookingModal(booking, sp);
      }
    },
    openBookingModal(booking, sp) {
      const content = this.$createElement(MeetingRoomModal, { props: { booking: booking, serviceProvider: sp } })
      const skin = this.$store.getters.skin;
      let opts = {
        title: "My Coaching Session",
        hideHeader: true,
        hideFooter: true,
        modalClass: "coach_modal theme-" + skin,
        headerClass: "coach_modal_header bg-brand-secondary d-flex justify-content-center",
        size: "lg",
        buttonSize: "sm",
        centered: true,
        contentClass: "coach_modal",
        id: 'digital_clinics_modal'
      }
      return this.$bvModal.msgBoxOk(content, opts);
    },
    openEarlyModal(booking, sp) {
      const content = this.$createElement(EarlyModal, { props: { booking: booking, serviceProvider: sp } })
      const skin = this.$store.getters.skin;
      let opts = {
        title: "My Session",
        hideHeader: true,
        hideFooter: true,
        modalClass: "coach_modal theme-" + skin,
        headerClass: "coach_modal_header bg-brand-secondary d-flex justify-content-center",
        size: "md",
        buttonSize: "sm",
        centered: true,
        contentClass: "coach_modal",
        id: 'digital_clinics_early_modal'
      }
      return this.$bvModal.msgBoxOk(content, opts);
    },

  },
};
</script>

<style lang="less">
#DigitalClinicsBookingCard {
  .booking_inner {
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
    background-color: #403d56;
    color: white;

    div {
      padding: 6px 10px;
    }

    margin-bottom: 15px;
    border-radius: 15px 15px 0px 15px;

    .coach {
      font-size: 1.5rem;
    }
    .title {
      font-size: 1.5rem;
    }
    .day {
      font-size: 1.5rem;
    }

    .hour {
      font-weight: lighter;
      font-size: 1.3rem;
    }

    .date {
      font-size: 2.5rem;
    }

    .month {
      font-size: 1.75rem;
      font-weight: lighter;
    }

    .corner {
      position: relative;
      padding: 0px;
      min-height: 25px;

      .corner_inner {
        position: absolute;
        right: 0px;
        bottom: 0px;
        padding: 7px 25px;
        border-radius: 15px 0px 0px 0px;
        font-family: 'DMSans', serif;

        &.video {
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
