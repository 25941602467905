<template>
    <div id="Insights">
        <InsightsIntro v-if="page === 0" v-on:start="startInsights"></InsightsIntro>
        <InsightsQuestions v-if="page === 1" ></InsightsQuestions>
    </div>
</template>

<script>
    // @ is an alias to /src
    import axios from "axios";
    import config from "../config/constants";
    import InsightsIntro from "../components/Insights/InsightsIntro"
    import InsightsQuestions from "../components/Insights/InsightsQuestions.vue"
    export default {
        name: "Insights",
        components: {
            InsightsIntro,
            InsightsQuestions
        },
        data() {
            return {
                page: 0,
            };
        },
        mounted() {
            },
        methods: {
            startInsights(){
                this.page = 1
            },
        },
        watch:{

        },
        computed:{
        }
    };
</script>
<style lang="less">
    #Insights {
        .content-container{
            -ms-flex: 1 1 100%;
        }
    }
</style>
