<template>
  <div class="AssessmentText">

    <h3 style="padding-top: 0; padding-bottom: 15px" v-if="service.slug === 'cmh_assessment'">What is the Specialist Assessment?</h3>
    <h3 style="padding-top: 0; padding-bottom: 15px" v-else>What is included in this service?</h3>
    <div v-if="service.slug === 'cmh_assessment'">
      <p>This service includes specialist assessments to determine:</p>
      <ul>
        <li>Emotional and well-being needs</li>
        <li>Assessments of neurodevelopmental differences and neurodivergence (such as autism and ADHD)</li>
        <li>Psychological support for children and young people experiencing mild to moderate emotional and behavioural challenges.
        </li>
      </ul>
      <p>
        The approach is client-centered, emphasising collaboration during the initial assessment to determine the best support package suited to individual needs. Additionally, the service may involve consultations with other professionals involved in the client's care, always with the prior consent of the individual or their caregiver.
      </p>
      <p>
        The overall goal is to provide tailored support and determine if alternative services might be more appropriate based on various factors, ensuring the well-being and comfort of the individuals seeking assistance.
      </p>
    </div>
    <div v-else-if="service.pathway_type === 'Neurodiversity'">
      <p>This service includes specialist assessments to determine:</p>
      <ul>
        <li>Emotional and well-being needs</li>
        <li>Assessments of neurodevelopmental differences and neurodivergence (such as autism and ADHD)</li>
        <li>Psychological support for children and young people experiencing mild to moderate emotional and behavioural challenges.
        </li>
      </ul>
      <p>
        The approach is client-centered, emphasising collaboration during the initial assessment to determine the best support package suited to individual needs. Additionally, the service may involve consultations with other professionals involved in the client's care, always with the prior consent of the individual or their caregiver.
      </p>
      <p>
        The overall goal is to provide tailored support and determine if alternative services might be more appropriate based on various factors, ensuring the well-being and comfort of the individuals seeking assistance.
      </p>
      <p>
        During the consultation we will explain what the Neurodiversity Assessment Day involves and answer any questions you may have and also discuss the next steps with you. Your Neurodiversity Assessment Day will include Clinical Interviews to explore developmental history and current functioning, as well as a Feedback Session post internal Clinical Review. We will discuss this in more detail with you in your Initial Appointment.
      </p>
      <p>
        The intake form below is essential for gathering crucial details about your background and needs. This ensures understanding for emotional, neurodevelopmental, and behavioural challenges through a client-centered approach.
      </p>
    </div>
    <div v-else>
      <p>This service includes specialist support to determine:</p>
      <ul>
        <li>Emotional and well-being needs</li>
        <li>Psychological support for children and young people experiencing mild to moderate emotional and behavioural challenges.
        </li>
      </ul>
      <p>
      The approach is client-centered, emphasising collaboration during the initial assessment to determine the best support package suited to individual needs. Additionally, the service may involve consultations with other professionals involved in the client's care, always with the prior consent of the individual or their caregiver.
      </p>
      <p>
      The overall goal is to provide tailored support and determine if alternative services might be more appropriate based on various factors, ensuring the well-being and comfort of the individuals seeking assistance.
      </p>
      <p>
        The intake form below is essential for gathering crucial details about your background and needs. This ensures understanding for emotional, neurodevelopmental, and behavioural challenges through a client-centered approach.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: ['service'],
  name: "AssessmentText"
}
</script>
<style lang="less">
#AssessmentText {
  p, li {
    font-size: 1.45rem !important;
  }
  p {
    font-family: DMSans, Arial, Helvetica, sans-serif !important;
  }
}
</style>