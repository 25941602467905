<template>
  <div id="TherapyPasswordResetRequest">
    <h2 class="mt-3 mb-3">Password Reset</h2>
    <div>
      <p><a href="#" @click="$emit('changeMode')">Back to Login Page</a></p>
      <b-form class="my-3">
        <b-form-group
            id="emailGroup"
            label="Email"
            label-for="email"
        >
          <b-form-input
              type="text"
              id="email"
              v-model="email"
          />
        </b-form-group>
      </b-form>
      <div v-if="message" v-html="message"></div>
      <button class="therapyBtn mt-5 mx-auto" :disabled="buttonDisabled" @click="resetPw">Request Reset</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TherapyPasswordResetRequest',
  data() {
    return {
      email: "",
      message: null
    }
  },
  methods: {
    async resetPw() {
      this.message = null;
      const path = process.env.NODE_ENV === 'production' ? '.spectrum.life/password-reset' : '.surge-qa.com/password-reset';
      const res = await this.api({
        path: "api-hc/send-reset",
        email: this.email,
        return_domain: this.connectedMentalHealthType() + path
      });
      if(res.success) {
        his.m('password-reset',{status: 'request'});
        this.message = '<p>Your request has been acknowledged. An email will be sent shortly with further instructions to reset your password.  You should note that it may take up to 5 minutes for your email to arrive in your inbox.</p>' +
            '<p>Tip: If you don’t see the email in your inbox, check your junk mail.</p>'
      }
    },
    isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
  },
  computed: {
    buttonDisabled() {
      return !this.isValidEmail(this.email);
    }
  },
  mounted() {
    this.m('password-reset',{status: 'start'});
  }
}
</script>

<style lang="less">
#TherapyPasswordResetRequest {

}
</style>