<template>
  <div id="TherapyLandingPage">
    <div class="content-container" v-if="loaded">
      <div class="p-5">
        <TherapyStart :upcoming_bookings="bookings.upcoming" :therapy_type="service.therapy_type" :age_group="service.age_group" :service="service"/>
      </div>
    </div>
  </div>
</template>
<script>

import TherapyQuestionnaire from "@/components/TherapyPortal/TherapyQuestionnaire.vue";
import TherapyStart from "@/components/TherapyPortal/TherapyStart.vue";

export default {
  name: "TherapyLandingPage",
  components: { TherapyStart, TherapyQuestionnaire},
  props: {
    service: {
      default: null
    }
  },
  data() {
    return {
      loaded: false,
      bookings: {
        upcoming: [],
        past: [],
        intakeFormProgress: null
      }
    }
  },
  computed: {

  },
  mounted() {
    this.getAgreement();
    this.getData()
  },
  methods: {
    async getAgreement() {
      const res = await this.api({
        path: 'api/u/cmh/get-agreement'
      })
    },
    async getData() {
      const res = await this.api({path: 'api/u/digital-bookings/get-cmh-bookings', slug: this.$route.params.slug})
      if(res.success) {
        this.bookings = {
          upcoming: res.bookings.upcoming,
          past: res.bookings.past
        }
      }
      this.loaded = true;
    }
  }
}
</script>

<style lang="less">
#TherapyLandingPage {
  background-color: transparent;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 200px);
}
</style>