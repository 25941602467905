<template >
  <div id="HomeLoading" class="loader" >
    <div class="box-holder" >
      <div class="box" :class="mode"></div>
    </div>
    <div class="line-holder" :class="mode">
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeLoading",
  props: ['mode']
}
</script>

<style lang="less">
#HomeLoading {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  .box-holder {
    width: 25%;
    .box {
      width: 100%;
    }
  }
  .box, .line {
    height: 281px;
    width: 100%;
    background: linear-gradient(90deg,
    #f2f2f2,
    #dbdbdb,
    #eaeaea,#f2f2f2);
    animation: gradient 2s ease infinite;
    background-size: 400% 400%;
  }
  .line-holder {
    width: 65%;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
    .line {
      height: 25%;
      width: 100%;
      background-color: black;
    }
  }
  .medium {
    height: 340px;
  }
  @keyframes gradient {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
}
</style>