<template>
    <div v-if="showBanner" id="benefithub_banner">
        <div class="container">
            <div class="row">
                <div class="col-md-9">
                    <h3>Accessing BenefitHub</h3>
                    <p>
                        Access to BenefitHub is optional and at your own discretion, and any activity/arrangement via <a target="_blank" href="https://www.benefithub.com/">benefithub.com</a> is between the individual and <a target="_blank" href="https://www.benefithub.com/">benefithub.com</a> You must use your personal email address and personal password, if creating a <a target="_blank" href="https://www.benefithub.com/">benefithub.com</a> account.
                    </p>
                </div>
                <div class="col-md-3 pt-5 text-right">
                    <button class="btn cta-button px-5 pt-2 pb-3" @click="agreeToBenefithub()">
                        I Accept
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "BenefitHubBanner",
    data() {
        return {
            showBanner: true,
        };
    },
    methods: {
        agreeToBenefithub() {
            this.showBanner = false;
        },
    },
};
</script>
<style lang="less">
  #benefithub_banner {
    position: fixed;
    bottom: 0px;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: white;
    font-size: 13px;
    z-index: 1000;
  }
</style>