<template>
  <div id="WellbeingStudio">
    <div id="WomensDay">
      <div class="loading" v-if="loading">
        <b-spinner></b-spinner>
      </div>
      <div class="no-series" v-else-if="noSeries">
        <div class="content-container row py-5 mt-5">
          <div class="col-12 text-center">
            <h3 class="m-auto">{{ componentText.noSeries }}</h3>
          </div>
        </div>
      </div>
      <div class="page-wrap" v-else>
        <section class="bg-grey">
          <b-row class="bg-grey content-container">
            <b-col col md="6" class="d-flex justify-content-center">
              <img
                  src="/img/wellbeing-studio/womens-health.png"
                  alt="Wellbeing Studio Logo"
                  class="wd-image"
              />
            </b-col>
            <b-col col md="6" class="justify-content-center p-5">
              <h3 class="banner-title">{{ series.title }}</h3>
              <div v-html="series.description" class="banner-text"></div>
            </b-col>
          </b-row>
        </section>
        <!--      <section >-->
        <!--        <div class="row bg-grey d-flex flex-row align-items-center">-->
        <!--          <div class="header-image-container col-7 px-0 position-relative d-none d-md-flex">-->
        <!--            <img class="img-fluid" src="/img/events-banner.jpg" />-->
        <!--            <div class="triangle-bottom-right"></div>-->
        <!--          </div>-->
        <!--          <div class="col-lg-5 col-12 px-3">-->
        <!--            <div class="px-5 py-5">-->
        <!--              <h1 class="banner-title">{{ series.title }}</h1>-->
        <!--              <div class="d-md-none">-->
        <!--                <img class="img-fluid rounded" src="/img/events-banner.jpg" />-->
        <!--              </div>-->
        <!--              <p class="banner-text pb-4" v-html="series.description">-->
        <!--              </p>-->
        <!--              <div class="btn-wrap px-0">-->
        <!--                <b-button-->
        <!--                    v-if="series.other_series.prev"-->
        <!--                    class="btn-outline px-5 py-3 "-->
        <!--                    @click="getSeries(series.other_series.prev)"-->
        <!--                >{{ componentText.previous }}</b-button-->
        <!--                >-->
        <!--                <b-button-->
        <!--                    v-if="series.other_series.next"-->
        <!--                    class="cta-button px-5 py-3 ml-3"-->
        <!--                    @click="getSeries(series.other_series.next)"-->
        <!--                >{{ componentText.next }}</b-button-->
        <!--                >-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--      </section>-->
        <div class="virtual-events bg-white py-5">
          <div class="content-container row py-5">
            <div class="section-header col-12">
              <div class="section-header-divider"></div>
              <h3 class="section-header-title">Women's Health Events</h3>
            </div>
            <div
                class="col-lg-4 col-md-6 col-12 event-card my-4"
                v-for="(event, i) in series.events"
                :key="i"
            >
              <div
                  class="event-card-content my-2 mx-0 row"
                  @click="
                $router.push({ name: 'womensDayEvent', params: { id: event.id } })
              "
              > <div v-if="event.featured" class="featuredTab bg-brand-primary">Featured</div>
                <div class="col-12 event-title d-flex flex-column align-items-center justify-content-center">
                  <div class="text-center">{{ event.title }}</div>
                  <div class="event-description">{{cardDescription(event.description)}}</div>
                </div>
                <!--              <div class="col-5 event-date pt-4">-->
                <!--                <div class="event-day">-->
                <!--                  {{ getFormatedDate(event.start_date_time, "DD") }}-->
                <!--                </div>-->
                <!--                <div class="event-month">-->
                <!--                  {{ getFormatedDate(event.start_date_time, "MMM") }}-->
                <!--                </div>-->
                <!--                <div class="event-time">-->
                <!--                  <i class="far fa-clock ml-3"></i>-->
                <!--                  {{ getFormatedDate(event.start_date_time, "HH:mm") }} - -->
                <!--                  {{ getFormatedDate(event.end_date_time, "HH:mm") }}-->
                <!--                </div>-->
                <!--              </div>-->
                <div class="book-now cta-button hover-darken py-3 px-5">
                  <span>Watch Now</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="series.courses.length !== 0">
          <div class="laya-e-learning py-5" v-if="skin === 10">
            <div class="content-container row pb-5">
              <div class="col-12 col-lg-5 pb-5 e-learning-content">
                <h2>E-learning</h2>
                <h3>{{ series.courses[0].title }}</h3>
                <p v-html="series.courses[0].description"></p>
                <div class="btn-wrap py-5">
                  <b-button
                      class="px-5 py-3"
                      @click="$router.push('/womens-day-series/' + series.courses[0].id)"
                  >See wellbeing course</b-button
                  >
                </div>
              </div>
              <div class="col-7">
                <div class="section-image d-none d-lg-block"></div>
              </div>
            </div>
          </div>
          <div class="learning pt-4" v-else>
            <div class="content-container row py-5">
              <div class="col-12 col-lg-5 pb-5 e-learning-content">
                <h3>{{ componentText.learning }}</h3>
                <h4>{{ series.courses[0].title }}</h4>
                <p class="mb-5" v-html="series.courses[0].description"></p>
                <a
                    class="text-link"
                    @click="
                  $router.push({
                    name: 'wellbeingCourse',
                    params: { id: series.courses[0].id },
                  })
                "
                >{{ componentText.seeCourse }}
                </a>
              </div>
              <div class="col-3 offset-4 d-none d-md-block course-image">
                <div class="image-wrap">
                  <img :src="series.courses[0].featured_img" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
            class="laya-downloadable-guides py-5"
            v-if="skin === 10 && series.attachments.length > 0"
        >
          <div class="content-container row pt-4 pb-5 mb-5">
            <div class="col-12 text-center pb-4">
              <h3>Downloadable guides</h3>
            </div>
            <div
                class="col-md-6 mb-5"
                v-for="(guide, i) in series.attachments"
                :key="i"
            >
              <div
                  class="download-card row mx-2"
                  @click="downloadGuide(guide.url)"
              >
                <div
                    class="col-3 col-sm-4 card-image"
                    ref="image-box"
                    :style="'background-image: url(' + guide.img_url + ')'"
                >
                  <div class="dots"></div>
                </div>
                <div class="col-9 col-sm-8 card-content pl-5 pt-4">
                  <h3>{{ guide.title }}</h3>
                  <p>{{ guide.description }}</p>
                  <div class="download">Download guide</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
            class="downloadable-guides bg-white py-5"
            v-else-if="series.attachments.length > 0"
        >
          <div class="content-container row py-5">
            <div class="section-header col-12">
              <div class="section-header-divider"></div>
              <h3 class="section-header-title">{{ componentText.guides }}</h3>
            </div>
            <div
                class="col-lg-3 col-md-4 col-sm-6 col-12"
                v-for="(guide, i) in series.attachments"
                :key="i"
            >
              <div class="download-card mx-2" @click="downloadGuide(guide.url)">
                <div
                    class="card-image"
                    ref="image-box"
                    :style="'background-image: url(' + guide.img_url + ')'"
                ></div>
                <div class="card-content px-3 pt-4">
                  <h4 class="my-3">{{ guide.title }}</h4>
                  <div class="download pb-4">
                    <span>{{ componentText.download }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import config from "@/config/constants";
//TODO:: Consider breaking this into laya / spectrum components
export default {
  name: "WD-WellbeingStudio",
  data() {
    return {
      series: {},
      loading: true,
      noSeries: false,
      liveNowDot: true,
    };
  },
  mounted() {
    this.getSeries();
    window.setInterval(() => {
      this.liveNowDot = !this.liveNowDot;
    }, 500);
  },
  methods: {
    cardDescription(str) {
      const text = str.split(" ");
      let description = '';
      let i = 0;
      text.forEach(item => {
        if(i < 100) description += ' ' + item;
        i += item.length
      });
      if(description.length < str.length) description += '...'
      return description
    },
    mindfulnessLink() {
      this.$router.push("/mindfulness");
    },
    async getSeries(seriesID = null) {
      const payload = {
        user_id: this.user_id,
        series_id: seriesID,
      };
      let res = await this.axios.post(
          process.env.VUE_APP_API_PATH +
          "/application/api/u/studio/get-womens-day-series",
          payload, config.options
      );
      if (res.data.success) {
        this.series = res.data.series;
        this.loading = false;
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        seriesID ? this.showError() : (this.noSeries = true);
        this.loading = false;
      }
    },
    downloadGuide(url) {
      window.open(url, "_blank");
    },
    showError() {
      //TODO:: show error
    },
    eventIsLive(event) {
      const eventStart = moment(event.start_date_time);
      const eventEnd = moment(event.end_date_time);
      console.log(moment().locale("fr"));
      const now = moment().locale("en");
      return now >= eventStart && now <= eventEnd;
    },
  },
  computed: {
    ...mapGetters(["skin", "user_id"]),
    componentText() {
      return this.getText.wellbeingStudio;
    },
  },
};
</script>

<style lang="less">
#WomensDay {
  .wd-image {
    max-height: 300px !important;
  }
  .header-image-container {
    height: 350px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    .img-fluid {
      position: relative;
      width: fit-contentl
    }
  }

  .triangle-bottom-right {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 100%;
    border-style: solid;
    border-width: 0 0 700px 350px;
    border-color: transparent transparent #f7f7f7 transparent;
  }

  .banner-title {
    font-size: 3rem;
    font-family: 'FilsonProBold', sans-serif;
    font-weight: bold;
    padding-bottom: 20px;

  }

  .banner-text {
    font-size: 1.6rem;

    width: 100%;
    max-width: 100% !important;

  }
  .rounded{
    border-radius: 16px;
    padding-bottom: 20px;
  }

  background-color: white;
  .mindfulnessContent {
    .mindfulnessTitle {
      margin-bottom: 40px;
      margin-top: 65px;
    }
    font-family: ff-sari-web-regular, sans-serif;
    width: 100% !important;
    .mindfulnessRow {
      margin: 0 auto 0px;
      width: 100%;
    }
    .mindfulnessCard {
      .mindfulnessHeader {
        position: relative;
        img {
          position: absolute;
          bottom: 0;
          right: 0;
          max-width: 150px;
        }
      }
      margin: 0 auto 50px;
      border: 1px solid #eaeaea;
      box-shadow: 0 .5rem 1.2rem rgba(0,0,0,.2);
      border-radius: 0 0 15px 0;
      max-width: 1000px;
      .iconRow {
        font-size: 1.6em;
      }
      .mindfulnessDescription {
        font-size: 1.6em;
      }
      .mindfulBtn {
        font-size: 1.6em;
      }
    }
  }
  .no-series {
    h3 {
      max-width: 600px;
    }
  }
  .text-link {
    background: transparent;
    font-family: FilsonProBold, sans-serif;
    border-bottom: 3px solid #ed3975;
    border-radius: 0;
    letter-spacing: 0;
    font-size: 1.7rem;
    &:hover {
      text-decoration: none !important;
    }
  }
  .section-header {
    h3 {
      text-transform: capitalize;
      padding: 0 20px 20px 0;
    }
    .section-header-divider {
      transform: translateY(-15px);
    }
  }
  .page-header {
    background-color: #f4f4f4;
    h3 {
      letter-spacing: 0;
      padding: 20px 0 10px 0;
    }
    p {
      font-size: 1.6rem;
    }
  }
  .virtual-events {
    h4 {
      color: #919191;
    }
    .event-card {
      .event-description {
        font-size: 0.65em;
        margin-bottom: 20px;
        margin-top: 10px;
      }
      .event-card-content {
        padding: 0 10px 35px 15px;
        border-radius: 15px 15px 0 15px;
        height: 100%;
        min-height: 170px;
        position: relative;
        cursor: pointer;
        .featuredTab {
          position: absolute;
          top: 0;
          left: 0;
          padding: 2px;
          color: #fff;
          font-size: 1.4rem;
          font-family: "DMSans", sans-serif;
          width: 35%;
          text-align: center;
          border-radius: 0 0 10px 0
        }
        &:hover {
          box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.3);
          transform: translate(-2px, -2px);
        }
        .event-title {
          color: #fff;
          font-size: 2rem;
          font-family: "DMSans", sans-serif;
          font-weight: 400;
          line-height: 1.2;
          padding-top: 30px;
        }
        .event-date {
          color: #fff;
          font-family: "FilsonProLight", sans-serif;
          line-height: 1;
          text-align: right;
          position: relative;
          .event-day {
            font-size: 6.4rem;
          }
          .event-month {
            font-size: 2rem;
            line-height: 0.6;
          }
          .event-time {
            font-size: 1.5rem;
            position: absolute;
            padding-top: 10px;
            right: 15px;
            white-space: nowrap;
          }
        }
        .book-now {
          position: absolute;
          right: 0;
          bottom: 0;
          text-align: center;
          font-size: 1.5rem;
          font-family: "DMSans", sans-serif;
          border-radius: 30px 0 0 0;
          cursor: pointer;
        }
      }
    }
  }
  .live-now {
    color: #fff;
    background-color: red;
    border-radius: 8px;
    font-size: 1.6rem;
    font-weight: 400;
    &:before {
      content: "•";
    }
    &.dot-on {
      &:before {
        color: red;
      }
    }
  }
  .learning {
    background-color: #f4f4f4;
    h3 {
      text-transform: capitalize;
      padding-top: 0;
    }
    h4 {
      font-size: 2rem;
      font-family: FilsonProBold, sans-serif;
    }
    p {
      font-size: 1.6rem;
    }
    .image-wrap {
      display: flex;
      align-items: center;
      height: 100%;
      img {
        width: 100%;
      }
    }
  }
  .downloadable-guides {
    .download-card {
      text-align: center;
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
      height: 100%;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      cursor: pointer;
      &:hover {
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
      }
      .card-image {
        width: 100%;
        height: 150px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }
      .card-content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        h4 {
          font-size: 2rem;
          font-family: FilsonProLight;
        }

        .download {
          margin-top: auto;
          font-size: 1.8rem;
          font-family: FilsonProBold, sans-serif;
        }
      }
    }
  }
  #laya_wellbeing_banner {
    overflow: hidden;
    background-color: #fafafa;
    margin-bottom: 100px;
    border-radius : 25px;
    box-shadow: 0px 10px 10px rgba(0,0,0,0.2);
    .img_container {
      padding: 0px;
      max-height: 400px;
      img {

      }
    }

    .banner_text {
      padding: 15px 15px;
      position: relative;
      //height : 100%;
      p {
        margin-top: 16px;
        font-family: ff-sari-web-regular,sans-serif;
        font-size: 15px;
        margin-bottom: 30px;
      }
      h2 {
        text-align: left;
        font-family: ff-sari-web-regular,sans-serif;
      }
      a {
        //position: absolute;
        //left : 0px;
        //bottom: 30px;
      }
    }
  }
}
</style>
