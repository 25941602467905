<template>
    <div id="Nutrition">
        <div class="page-header py-5 bg-transparent"
            v-if="$store.getters.subdomain !== 'pwcire' && !$store.getters.user.access_code_user">
            <div class="content-container row d-block py-md-5 py-0" style="min-height: 420px">
                <div class="page-title col-12 d-block d-md-none">
                    <h2 class="text-center">{{ $route.meta.title ? $route.meta.title : componentText.title }}</h2>
                </div>
                <div class="row">
                    <div class="d-block col-md-6 col-12">
                        <div class="page-title col-md-12">
                            <div v-if="nutritionStats.success " class="col-12 pl-0">
                                <h2 class="d-none d-md-block">{{ $route.meta.title ? $route.meta.title :
                                        componentText.title
                                }}</h2>
                                <b-row align-h="center">
                                    <pie-chart class="col-3 pr-0" :chartData="macroData" :options="macroOptions">
                                    </pie-chart>
                                    <div class="col-9 row todays-stats pl-0 mx-0">
                                        <h4 class="col-12 mb-3">{{ componentText.macros }}</h4>
                                        <div class="col">
                                            <p><span class="macro-value">{{ nutritionStats.nutrition.fat_perc }}%</span>
                                                {{ componentText.fat }}</p>
                                        </div>
                                        <div class="col ">
                                            <p><span class="macro-value">{{ nutritionStats.nutrition.carbs_perc
                                            }}%</span>
                                                {{ componentText.carbs }}</p>
                                        </div>
                                        <div class="col ">
                                            <p><span class="macro-value">{{ nutritionStats.nutrition.protein_perc
                                            }}%</span>
                                                {{ componentText.protein }}</p>
                                        </div>
                                        <div class="col-12">
                                            <p><span class="macro-value calorie-count">{{
                                                    nutritionStats.nutrition.calories
                                            }}/{{ nutritionStats.target
}}</span>
                                                {{ componentText.calories }}</p>
                                        </div>
                                        <div v-if="!user.insights_completed" class="my-3 insights__btn_container">
                                            <router-link to="/personal/insights" class="btn btn-lg btn-event py-3"
                                                type="button">
                                                {{ componentText.insights }}
                                            </router-link>
                                        </div>
                                    </div>
                                </b-row>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-12">
                        <div class="col-12">
                            <div id="pieColour1" ref="colorPick1"></div>
                            <div id="pieColour2" ref="colorPick2"></div>
                            <div id="pieColour3" ref="colorPick3"></div>
                            <PersonalStatsCalendar v-if="nutritionStats.target" v-on:previous="calendarPrevWeek"
                                v-on:next="calendarNextWeek" v-on:select-date="selectDate" :calendar="calendar"
                                :target="target" :label="componentText.cals">
                            </PersonalStatsCalendar>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-grey" v-if="$store.getters.subdomain !== 'pwcire' && !$store.getters.user.access_code_user">
            <div class="content-container row">
                <div class="section-header section-header-right">
                    <div class="section-header-divider"></div>
                    <h3 class="section-header-title">{{ componentText.track }}</h3>
                </div>
                <div class="toolbar col-12 py-5 d-none d-lg-block">
                    <b-button-group>
                        <b-button class="px-5 py-3 col-4 brand-secondary border-brand-secondary"
                            :class="{ active: page === 0, 'col-12 rounded': $store.getters.user.access_code_user }"
                            @click="pageSelect(0)">{{ componentText.recipe }}</b-button>
                        <!--                        <b-button-->
                        <!--                                class="px-5 py-3 col-4 brand-secondary border-brand-secondary"-->
                        <!--                                :class="{ active: page === 1 }"-->
                        <!--                                @click="pageSelect(1)"-->
                        <!--                        >{{componentText.search}}</b-button-->
                        <!--                        >-->
                        <b-button class="px-5 py-3 col-4 brand-secondary border-brand-secondary"
                            v-if="!$store.getters.user.access_code_user" :class="{ active: page === 2 }"
                            @click="pageSelect(2)">{{ componentText.diary }}</b-button>
                        <b-button class="px-5 py-3 col-4 brand-secondary border-brand-secondary"
                            v-if="!$store.getters.user.access_code_user" :class="{ active: page === 3 }"
                            @click="pageSelect(3)">{{ componentText.log }}</b-button>
                    </b-button-group>
                </div>
                <div class="col-12 d-block d-lg-none py-5">
                    <div class="dropdown-wrap">
                        <b-dropdown :text="dropdownText" class="dropdown cta-button py-2">
                            <b-dropdown-item @click="pageSelect(0, 'RECIPE BOOK')">{{ componentText.recipe }}
                            </b-dropdown-item>
                            <b-dropdown-item @click="pageSelect(1, 'FOOD SEARCH')">{{ componentText.search }}
                            </b-dropdown-item>
                            <b-dropdown-item @click="pageSelect(2, 'FOOD DIARY')"
                                v-if="!$store.getters.user.access_code_user">{{ componentText.diary }}</b-dropdown-item>
                            <b-dropdown-item @click="pageSelect(3, 'CUSTOM LOG')"
                                v-if="!$store.getters.user.access_code_user">{{ componentText.log }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
            </div>
        </div>
        <div id="content-wrap">
            <RecipeBook v-if="page === 0"></RecipeBook>
            <FoodSearch v-if="page === 1"></FoodSearch>
            <FoodDiary v-if="page === 2"
                @log-update="getNutritionStats(calendar.weekStart, calendar.calendarFullDates[date])"></FoodDiary>
            <CustomFoodLog v-if="page === 3"
                @log-update="getNutritionStats(calendar.weekStart, calendar.calendarFullDates[date])"></CustomFoodLog>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import config from "../config/constants";
import moment from "moment";
import PersonalStatsCalendar from "../components/PersonalStatsCalendar.vue"
import PieChart from "../components/PieChart.vue"
import RecipeBook from "../components/Nutrition/RecipeBook.vue"
import FoodSearch from "../components/Nutrition/FoodSearch.vue"
import FoodDiary from "../components/Nutrition/FoodDiary.vue"
import CustomFoodLog from "../components/Nutrition/CustomFoodLog.vue"
import { mapState } from "vuex";
export default {
    name: "Nutrition",
    components: {
        PersonalStatsCalendar,
        PieChart,
        RecipeBook,
        FoodSearch,
        FoodDiary,
        CustomFoodLog
    },
    data() {
        return {
            dropdownText: 'RECIPE BOOK',
            nutritionStats: {
                nutrition: {
                    protein_perc: 0,
                    carbs_perc: 0,
                    fat_perc: 0
                },
            },
            page: 0,
            selectedPlan: '',
            steps: 0,
            distance: 0,
            calendar: {
                calendarWeek: '',
                calendarDates: [],
                calendarFullDates: [],
                weekStart: 1,
                weekEnd: 7,
                weeklySteps: [],
                hasSteps: false,
                selectedDate: '',
            },
            macroOptions: {
                legend: {
                    display: false
                },
                title: {
                    display: false,
                },
                elements: {
                    arc: {
                        borderWidth: 0
                    }
                }
            },
            pieColours: ''
        };
    },
    mounted() {
        this.initCalendar(this.calendar.weekStart, this.calendar.weekEnd)
        const today = moment().locale(this.$store.state.lang).format("DD")
        this.calendar.selectedDate = this.calendar.calendarDates.findIndex(x => x === today)
        this.calendar.selectedFullDate = this.calendar.calendarFullDates[this.calendar.selectedDate]
        this.getPieColours()
    },
    methods: {
        async getNutritionStats(start, date) {
            const week = moment().isoWeekday(start).format('MM/DD/YYYY')
            let selectedDate = date
            if (!selectedDate) {
                selectedDate = moment().format('MM/DD/YYYY')
            }
            const payload = {
                user_id: this.$store.getters.user_id,
                start_date: week,
                date: selectedDate
            }
            try {
                let res = await axios.post(config.api_env + '/application/api-hc/get-nutrition-stats', payload, config.options)
                if (res.data.success) {
                    this.nutritionStats = res.data
                }
                this.calendar.weeklySteps = Object.values(this.nutritionStats.stats).map(x => x.toString())
                this.calendar.hasSteps = this.calendar.weeklySteps.some(steps => steps > 0)
            } catch (e) { };

        },
        initCalendar(start, end) {
            this.getNutritionStats(start)
            for (let i = 0; i < 7; i++) {
                this.calendar.calendarDates[i] = moment().isoWeekday(start + i).format("DD")
                this.calendar.calendarFullDates[i] = moment().isoWeekday(start + i).format("MM/DD/YYYY")
            }
            this.calendar.calendarWeek = moment().isoWeekday(start).locale(this.$store.state.lang).format("DD MMM") + ' - ' + moment().isoWeekday(end).locale(this.$store.state.lang).format("DD MMM")
        },
        calendarPrevWeek() {
            this.calendar.weekStart = this.calendar.weekStart - 7
            this.calendar.weekEnd = this.calendar.weekEnd - 7
            this.initCalendar(this.calendar.weekStart, this.calendar.weekEnd)
        },
        calendarNextWeek() {
            this.calendar.weekStart = this.calendar.weekStart + 7
            this.calendar.weekEnd = this.calendar.weekEnd + 7
            this.initCalendar(this.calendar.weekStart, this.calendar.weekEnd)
        },
        pageSelect(page, text) {
            this.page = page
            if (text) {
                this.dropdownText = text
            }
            const scrollTo = document.getElementById('content-wrap')
            scrollTo.scrollIntoView({ behavior: 'smooth' })
        },
        selectDate(date) {
            const week = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
            this.calendar.selectedDate = date
            this.calendar.selectedFullDate = this.calendar.calendarFullDates[date]
            this.getNutritionStats(this.calendar.weekStart, this.calendar.calendarFullDates[date])
        },
        getPieColours() {
            try {
                let el1 = document.getElementById('pieColour1');
                let colour1 = window.getComputedStyle(el1).getPropertyValue('background-color');
                let el2 = document.getElementById('pieColour2');
                let colour2 = window.getComputedStyle(el2).getPropertyValue('background-color');
                let el3 = document.getElementById('pieColour3');
                let colour3 = window.getComputedStyle(el3).getPropertyValue('background-color');
                this.pieColours = [colour1, colour2, colour3]
            } catch (e) {
                this.pieColours = ['#5ea0a1', '#a8c6c6', '#e9f2f2']
            }
        }
    },
    watch: {
        pieData(val) {
        }
    },
    computed: {
        ...mapState(['user']),
        pieData() {
            const protein = this.nutritionStats.nutrition.protein_perc
            const carbs = this.nutritionStats.nutrition.carbs_perc
            const fat = this.nutritionStats.nutrition.fat_perc
            if (protein === 0 && carbs === 0 && fat === 0) {
                return ['1', '1', '1']
            } else {
                return [protein.toString(), carbs.toString(), fat.toString()]
            }
        },
        macroData() {
            return {
                labels: [this.componentText.protein, this.componentText.carbs, this.componentText.fat],
                datasets: [{
                    data: this.pieData,
                    backgroundColor: this.pieColours,
                }],
            }
        },
        skin() {
            return this.$store.getters.skin
        },
        target() {
            return this.nutritionStats.target
        },
        componentText() {
            return this.getText.loggedIn.nutrition;
        },
    }
};
</script>
<style lang="less">
#Nutrition {
    .page-title {
        text-align: left;

        h2 {
            text-align: left;
            font-size: 3.8rem;
            letter-spacing: 0.7rem;
            font-family: "FilsonProBold", sans-serif;
            color: #fff;
        }
    }

    .todays-stats,
    .daily-target,
    .calendar {
        font-size: 1.6rem;
        color: #fff;
        width: 100%;

        span {
            text-align: right;
        }

        &>div {
            display: inline-flex;
        }
    }

    .todays-stats {
        h4 {
            color: #fff;
            font-size: 1.9rem;
        }

        p {
            color: #fff;
            font-size: 1.7rem;
            white-space: nowrap;

            .macro-value {
                font-family: 'FilsonProBold', sans-serif;
                font-size: 2.4rem;
            }
        }

        @media all and (-ms-high-contrast: none),
        (-ms-high-contrast: active) {
            display: flex;
            flex: 1 1 auto;
            flex-wrap: wrap;

            .col {
                flex: 1 1 auto;
            }
        }
    }

    .calendar {
        font-size: 1.9rem;

        .calendar-next {
            text-align: right;
        }

        .calendar-week {
            text-align: center;
        }

        .calendar-body {
            display: flex;

            .calendar-col {
                flex-direction: column;
                text-align: center;

                .calendar-date {
                    height: 40px;
                    width: 40px;

                    &.active {
                        border-radius: 80%;
                        box-shadow: 0 0 3px 2px #d4d4d4;
                        font-family: 'FilsonProBold', sans-serif;
                    }
                }
            }
        }

        .noSteps {
            margin: auto;
            align-items: center;
            background-image: url('../../public/img/nothing-logged.png');
            background-size: 70%;
            background-repeat: no-repeat;
            background-position: center;
            height: 300px;

            canvas {
                display: none !important;
            }
        }
    }

    .toolbar {
        text-align: center;
        z-index: 10;

        .btn-group {
            width: 800px;
            height: 60px;

            .btn {
                font-family: "DMSans", sans-serif;
                font-size: 1.6rem;
                background-color: transparent;
                border-width: 1px;
                border-radius: 0px;
                white-space: nowrap;
                letter-spacing: 0;

                //IE styles
                @media all and (-ms-high-contrast: none),
                (-ms-high-contrast: active) {
                    font-size: 1.3rem;
                    padding: 0 !important;
                }

                &:first-child {
                    border-radius: 60px 0 0 60px;
                }

                &:last-child {
                    border-radius: 0 60px 60px 0;
                }

                &.active {}
            }
        }
    }

    .dropdown-wrap {
        text-align: center;
        font-size: 1.6rem;
        width: 300px;
        margin: auto;
        position: relative;

        .dropdown {
            position: relative;
            border-width: 0;
            border-radius: 10px;
            font-size: 1.6rem;
            width: 300px;
            text-align: center;
            margin: auto;
            box-shadow: none;

            &:focus {
                border-radius: 10px 10px 0 0;
                box-shadow: none;
            }

            &.show {
                border-radius: 10px 10px 0 0;

                .dropdown-toggle {
                    background: none;
                }
            }

            .dropdown-toggle {
                &:active {
                    background: none;
                }
            }

            .dropdown-menu {
                margin-top: 0;
                width: 100%;
                font-size: 1.6rem;
                border-radius: 0 0 10px 10px;
            }
        }
    }

    .btn-event {
        font-size: 16px;
        background: transparent;
        color: #ffffff;
        border: 1px solid #fff;
        border-radius: 25px;
    }

    #content-wrap {
        display: flex;
        min-height: 300px;

        &>div {
            width: 100%;
            flex-grow: 1;
            justify-content: center;
        }
    }
    .rounded{
        border-radius: 60px !important;
    }
}
</style>
