<template>
  <div class="event-video text-center">
    <div class="video-wrap" v-if="videoAvailable">
      <div
        :class="{
          'aspect-ratio': !decodedEmbed.includes('sli.do'),
        }"
        v-html="decodedEmbed"
      ></div>
    </div>
    <div class="not-available" v-else>
      <h3>{{ componentText.unavailable }}</h3>
    </div>
    <div class="text-center" v-if="event.schedule_type === 'gym'">
      <p class="disclaimer-heading pt-4">
        {{ componentText.disclaimerHeading }}
      </p>
      <a v-b-toggle.disclaimer class="text-link" v-if="!disclaimerVisible">{{
        componentText.showMore
      }}</a>
      <b-collapse
        id="disclaimer"
        v-model="disclaimerVisible"
        class="my-5"
        v-html="componentText.disclaimerContent"
        v-if="$store.getters.skin !== 20"
      >
      </b-collapse>
      <b-collapse
        id="disclaimer"
        v-model="disclaimerVisible"
        class="my-5"
        v-else
      >
        <p>
          <b>Health Disclaimer</b><br><br>
          Prior to taking part in any fitness classes, exercise programmes, or
          any other health and fitness related activities delivered via the
          Service, it is the responsibility of the User taking part to
          ensure:<br /><br />
          A. There is sufficient space to perform the exercises safely and move
          freely without obstacles or obstructions, free from furniture,
          equipment and other hazards – a minimum of four square metres per
          person.<br />
          B. Any equipment is safe and in a suitable condition for the activity
          being performed.<br />
          C. The surrounding floor space is entirely clear to remove any hazards
          that may increase the risk of slips, trips or falls.<br />
          D. There are no distractions throughout the duration of the
          session.<br />
          E. There is sufficient heating, lighting, ventilation and
          hydration.<br />
          F. They have contacted their GP or Health Professional prior to
          engaging with this programme, content or classes to confirm it is safe
          for them to do so, especially if they have a pre diagnosed health
          condition.<br />
          G. If at any point during the class they begin to feel faint, dizzy or
          have any physical discomfort, they should stop immediately and seek
          medical advice.<br />
          H. They are wearing appropriate clothing.<br /><br />
          You agree to abide by these terms whenever you participate in any
          fitness class, programme or activity delivered via the Service.<br /><br />
          As with all fitness and health activities, you need to use your common
          sense and good judgement. By performing any fitness activities without
          supervision, you are performing them at your own risk. Spectrum.Life
          is not responsible for any injury or harm you sustain as a result of
          any fitness classes, programmes, activities or information shared or
          accessed as part of the Service.<br /><br />
          The fitness classes, programmes and activities are provided by
          Spectrum.Life for guidance and information only. You must consider
          your own level of fitness and ability and choose the level of the
          activities appropriate for you. You should rest when you need to.
        </p>
      </b-collapse>
      <a v-b-toggle.disclaimer class="text-link" v-if="disclaimerVisible">{{
        componentText.showLess
      }}</a>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "EventVideo",
  props: ["event", "test"],
  data() {
    return {
      disclaimerVisible: false,
      videoReload: false,
    };
  },
  computed: {
    videoAvailable() {
      if (this.test) {
        return true;
      } else if (this.videoReload) {
        return false;
      } else {
        const now = moment();
        const vidAvailable = moment(this.event.start_date_time);
        return now > vidAvailable || now.isSame(vidAvailable, "day");
      }
    },
    showReminder() {
      const now = moment();
      const vidAvailable = moment(this.event.start_date_time);
      return now < vidAvailable;
    },
    decodedEmbed() {
      return this.event.embed_code ? atob(this.event.embed_code) : "";
    },
    componentText() {
      return this.getText.liveEvent;
    },
  },
};
</script>

<style scoped></style>
