<template>
    <div v-if="showBanner" id="cookie_banner">
        <div class="container">
            <div class="row">
                <div class="col-md-9">
                    <h3>This website uses cookies</h3>
                    <p>
                        This website uses cookies to ensure to give you the best
                        experience during your visit and can constantly improve our
                        quality by collecting statistical information. If you have
                        accepted cookies in the settings of your web browser and you
                        continue to use our website, you agree. <br />
                        For more info please read our
                        <a href="/privacy-policy">Privacy Policy</a>
                    </p>
                </div>
                <div class="col-md-3 pt-5 text-right">
                    <button class="btn cta-button px-5 pt-2 pb-3" @click="agreeToCookie()">
                        I Accept
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import config from "./../config/constants";

export default {
    name: "CookieBanner",
    data() {
        return {
            showBanner: false,
        };
    },
    mounted() {
        this.shouldDisplayCookieBanner()
    },
    methods: {
        shouldDisplayCookieBanner() {
            if (
                this.$store.getters.subdomain === "pwcire" &&
                !this.$store.getters.user.user.cookies_accepted
            ) {
                return (this.showBanner = true);
            }
            return (this.showBanner = false);
        },
        async agreeToCookie() {
            let self = this;
            const payload = {
                user_id: this.$store.getters.user_id,
                terms_type: ["cookies"],
            };
            let res = await axios.post(
                config.api_env + "/application/api/u/user/accept-terms",
                payload,
                config.options
            );
            if (res.data.success) {
                self.$store.dispatch("setUserCookiesAgreementTrue");
                self.showBanner = false;
            } else if (res.data.err && res.data.err.includes("3994")) {
                this.$store.dispatch("logout");
                this.$router.push("/login").catch((err) => { });
            }
        },
    },
};
</script>
<style lang="less">
  #cookie_banner {
    position: fixed;
    bottom: 0px;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: white;
    font-size: 13px;
    z-index: 1000;
  }
</style>