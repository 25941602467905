<template>
    <div id="Feedback">
        <b-container>
            <b-progress :value="value" :max="max" style="margin-top: 15%" variant="primary"></b-progress>
            <b-row class="my-5">
                <b-col cols="7">
                    <h1>{{componentText.title}}</h1>
                </b-col>
            </b-row>
            <b-row class="question_container" v-show="question_nr == 0">
                <b-col cols="8" class="py-0 d-flex align-content-center">
                    {{ questions[0].question_title }}
                </b-col>
                <b-col class="d-flex align-content-top">
                    <b-form-select v-model="questions[0].form_item.answer"
                                   :options="questions[0].form_item.options"
                                   style="font-size: 16px">
                        <template v-slot:first>
                            <b-form-select-option :value="null" disabled>-- {{componentText.pleaseSelect}} --
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>

            </b-row>
            <b-row class="question_container"
                   v-show="questions[0].form_item.answer <= 6  && question_nr == 1">
                <b-col cols="8" class="py-0 d-flex align-content-center">
                    {{ questions[1].question_title }}
                </b-col>
                <b-col cols="8" class="py-0 d-flex">
                    <b-form-textarea rows="4"
                                     max-rows="8"
                                     no-resize
                                     v-model="questions[1].form_item.answer">
                    </b-form-textarea>
                </b-col>
            </b-row>
            <b-row class="question_container"
                   v-show="questions[0].form_item.answer > 6  && question_nr == 2">
                <b-col cols="8" class="py-0 d-flex align-content-center">
                    {{ questions[2].question_title }}
                </b-col>
                <b-col cols="8" class="py-0 d-flex">
                    <b-form-textarea rows="4"
                                     max-rows="8"
                                     no-resize
                                     v-model="questions[2].form_item.answer">
                    </b-form-textarea>
                </b-col>
            </b-row>
            <b-row class="question_container"
                   v-show="question_nr == 3 && questions[0].form_item.answer > 6 ||
                   question_nr == 2 && questions[0].form_item.answer <= 6">
                <b-col cols="7" class="py-0 d-flex align-content-center">
                    {{ questions[3].question_title }}
                </b-col>
                <b-col cols="5" class="py-0 d-flex">
                    <b-form-group>
                        <b-form-radio-group
                                id="radio-group-1"
                                v-model="questions[3].form_item.answer"
                                :options="questions[3].form_item.options"
                                name="radio-options"
                        ></b-form-radio-group>
                    </b-form-group>
                </b-col>
                <b-col cols="7" class="py-0 d-flex">
                    <b-form-textarea rows="4"
                                     max-rows="8"
                                     no-resize
                                     v-model="questions[3].form_item.client_benefit"
                                     v-show="questions[3].form_item.answer == 'yes' && user_type == 'sp'"
                                     :placeholder="componentText.tellMore">
                    </b-form-textarea>
                </b-col>
            </b-row>
            <b-row class="my-5">
                <b-col cols="12" class="d-flex align-content-center justify-content-center">
                    <b-button class="ml-5"
                              variant="primary"
                              :disabled="questions[question_nr].form_item.answer == null"
                              v-if="value < 3"
                              @click="increaseValue(question_nr)">
                        {{componentText.next}}
                    </b-button>
                    <b-button class="ml-5"
                              variant="success"
                              v-if="value == 3"
                              :disabled="questions[3].form_item.answer == null"
                              @click="submitFeeback">
                        {{componentText.submit}}
                    </b-button>
                </b-col>
            </b-row>
        </b-container>
        <popup v-if="showPopup === true" :content="popupContent" @back='hidePopup' v-on:close="hidePopup"></popup>
    </div>
</template>

<script>
    import axios from "axios";
    import config from "../config/constants";
    import Popup from "../components/Popup.vue";

    export default {
        name: 'Feedback',
        components: {Popup},
        data() {
            return {
                token: '',
                user_type: '',
                value: 1,
                show_back_button: false,
                max: 3,
                question_nr: 0,
                questions: [],
                showPopup : false,
                popupContent: {
                    header : '',
                    message : '',
                    buttonText : 'OK'
                },
            }
        },
        created() {
            this.setToken(),
            this.setType()
        },
        mounted(){
            if(this.$route.params.lang){
                this.$store.commit("setLanguage", this.$route.params.lang)
            }
        },
        methods: {
            hidePopup(){
                this.showPopup = false;
                this.$router.push("/login");
            },
            setToken() {
                this.token = this.$route.params.token
            },
            increaseValue(question_nr) {
                if(this.questions[question_nr].form_item.answer > 6) {
                    this.question_nr += 2
                    this.value++
                } else {
                    this.value++
                    this.question_nr++
                }
                this.show_back_button = true
            },
            setType() {
                this.user_type = this.$route.params.type
                if (this.user_type == 'emp') {
                    this.questions = [
                        {
                            question_title: this.componentText.emp.q1,
                            form_item: {
                                answer: null,
                                options: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                            },
                        },
                        {
                            question_title: this.componentText.emp.q2,
                            form_item: {
                                answer: null
                            },
                        },
                        {
                            question_title: this.componentText.emp.q3,
                            form_item: {
                                answer: null
                            },
                        },
                        {
                            question_title: this.componentText.emp.q4,
                            form_item: {
                                options: [
                                    {text: this.componentText.yes, value: 'yes'},
                                    {text: this.componentText.no, value: 'no'}
                                ],
                                answer: null
                            },
                        },
                    ]
                } else if (this.user_type == 'user') {
                    this.questions = [
                        {
                            question_title: this.componentText.user.q1,
                            form_item: {
                                answer: null,
                                options: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                            },
                        },
                        {
                            question_title: this.componentText.user.q2,
                            form_item: {
                                answer: null
                            },
                        },
                        {
                            question_title: this.componentText.user.q3,
                            form_item: {
                                answer: null
                            },
                        },
                        {
                            question_title: this.componentText.user.q4,
                            form_item: {
                                options: [
                                    {text: this.componentText.yes, value: 'yes'},
                                    {text: this.componentText.no, value: 'no'}
                                ],
                                answer: null
                            },
                        },
                    ]
                } else {
                    this.questions = [
                        {
                            question_title: this.componentText.other.q1,
                            form_item: {
                                answer: null,
                                options: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                            },
                        },
                        {
                            question_title: this.componentText.other.q2,
                            form_item: {
                                answer: null
                            },
                        },
                        {
                            question_title: this.componentText.other.q3,
                            form_item: {
                                answer: null
                            },
                        },
                        {
                            question_title: this.componentText.other.q4,
                            form_item: {
                                options: [
                                    {text: this.componentText.yes, value: 'yes'},
                                    {text: this.componentText.no, value: 'no'}
                                ],
                                answer: null,
                                client_benefit: null
                            },
                        },
                    ]
                }
            },
            async submitFeeback() {

                let payload = {
                    token: this.$route.params.token,
                    questions: this.questions
                }
                let res = await axios.post(config.api_env + "/application/api/events-feedback/submit-event-feedback", payload , config.options )

                if(res.data.success){
                    this.popupContent.message = res.data.message
                    this.showPopup = true;
                } else {
                    this.popupContent.message = res.data.message
                    this.showPopup = true;
                }
            }
        },
        computed:{
            componentText(){
                return this.getText.feedback
            }
        }
    }
</script>

<style scoped lang="less">
    #Feedback {
        font-size: 16px;
        .question_container {
            min-height: 200px;
        }
        .btn {
            font-family: 'Open Sans', sans-serif;
            font-size: 18px !important;
            padding: 10px 15px !important;
            margin-bottom: 0 !important;

            display: inline-block !important;
            text-decoration: none !important;
            text-align: center !important;
            white-space: nowrap !important;
            vertical-align: middle !important;
            -ms-touch-action: manipulation !important;
            touch-action: manipulation !important;
            cursor: pointer !important;
            -webkit-user-select: none !important;
            -moz-user-select: none !important;
            -ms-user-select: none !important;
            user-select: none !important;
            border-radius: 5px !important;
            background-image: none !important;
            border: 1px solid transparent !important;
        }

        .btn:hover,
        .btn:focus {
            text-decoration: none !important;
        }

        textarea {
            font-size: 16px;
        }


    }
</style>