<template>
  <div id="laya-footer" class="bg-grey">
    <div class="row footer_margin">
      <footer class="full-width">
        <div class="full-width footer-wrapper">
          <div class="container">
            <div class="row footer-content">
              <div
                class="col-md-2 col-sm-4 footer-help footer-section first-section laya-footer_links text-left"
                role="navigation"
              >
                <h5 class="footer-section-title">Need help?</h5>
                <ul class="pl-0 ">
                  <li class="pl-0 border-left-0">
                    <a @click="logout">Logout</a>
                  </li>
                  <li>
                    <a href="https://www.layahealthcare.ie/questions/" target="_blank">FAQ</a>
                  </li>
                  <li>
                    <a href="https://www.layahealthcare.ie/contactus/"
                      >Contact us</a
                    >
                  </li>
                </ul>
              </div>
              <div
                class="col-md-3 col-sm-8 footer-social footer-section"
                role="navigation"
              >
                <h5 class="footer-section-title hidden-xs">Connect with us</h5>
                <ul>
                  <li class="first-item">
                    <!--
                                        <a href="/contactus/">
                                          <meta itemprop="email" content="/contactus/" />
                                          <img src="/web/img/social/social-e.png" alt="E-mail Icon" style="width : 30px; height : 30px;     " />
                                        </a>
                                      </li>
                                      <li>
                                        -->
                    <a
                      itemprop="sameAs"
                      href="https://www.facebook.com/LayaHealthcare"
                    >
                      <img
                        src="/img/social/social-f.png"
                        alt="Facebook Logo"
                        style="width : 30px; height : 30px;     "
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      itemprop="sameAs"
                      href="https://www.twitter.com/LayaHealthcare"
                    >
                      <img
                        src="/img/social/social-t.png"
                        alt="Twitter Logo"
                        style="width : 30px; height : 30px;     "
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      itemprop="sameAs"
                      href="https://www.youtube.com/user/LayaHealthcare"
                    >
                      <img
                        src="/img/social/social-u.png"
                        alt="YouTube"
                        style="width : 30px; height : 30px;     "
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      itemprop="sameAs"
                      href="https://www.linkedin.com/company/laya-healthcare"
                    >
                      <img
                        src="/img/social/social-l.png"
                        alt="LinkedIn"
                        style="width : 30px; height : 30px;     "
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      itemprop="sameAs"
                      href="https://www.instagram.com/layahealthcare/?hl=en"
                    >
                      <img
                        src="/img/social/social-i.png"
                        alt=""
                        style="width : 30px; height : 30px;     "
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      itemprop="sameAs"
                      href="https://www.pinterest.ie/layahealthcare/pins/"
                    >
                      <img
                        src="/img/social/social-p.png"
                        alt="Pinterest"
                        style="width : 30px; height : 30px;     "
                      />
                    </a>
                  </li>
                </ul>
              </div>
              <div
                class="col-md-7 footer-list footer-section laya-footer_links"
                role="navigation"
              >
                <h5 class="footer-section-title">
                  About Laya -
                  <a href="https://www.layahealthcare.ie">Health Insurance</a> -
                  <a href="https://www.layalifeinsurance.ie/">Life Insurance</a>
                  -
                  <a href="http://www.layatravelinsurance.ie/"
                    >Travel Insurance</a
                  >
                </h5>
                <ul>
                  <li class="first-item">
                    <a
                      href="https://www.layahealthcare.ie/ourstoryourpeople/"
                      target="_blank"
                      >Our story</a
                    >
                  </li>
                  <li>
                    <a
                      href="https://www.layahealthcare.ie/ourboard/"
                      target="_blank"
                      >Our board</a
                    >
                  </li>
                  <li>
                    <a
                      href="https://www.layahealthcare.ie/ournews/"
                      target="_blank"
                      >News</a
                    >
                  </li>
                  <li>
                    <a
                      href="https://www.layahealthcare.ie/workingforlayahealthcare/"
                      target="_blank"
                      >Careers</a
                    >
                  </li>
                  <li>
                    <a
                      href="https://www.layahealthcare.ie/pressandmedia/"
                      target="_blank"
                      >Media</a
                    >
                  </li>
                  <li>
                    <a
                      href="https://www.layahealthcare.ie/privacypolicy/"
                      target="_blank"
                      >Privacy Policy</a
                    >
                  </li>
                  <li>
                    <a
                      href="https://www.layahealthcare.ie/termsconditions/"
                      target="_blank"
                      >Terms and Conditions</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="full-width footer-legal">
          <!--Company Links-->
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <p class="text-left">
                  Your Insurance is provided by Elips Insurance Limited trading
                  as Laya Healthcare. Laya Healthcare Limited, trading as Laya
                  Healthcare and Laya Life, is regulated by the Central Bank of
                  Ireland. Laya Healthcare Limited acts as an agent for
                  healthcare products for Elips Insurance Limited and is a
                  private company limited by shares registered in Ireland, No
                  242048. The registered office of Laya Healthcare Limited is
                  Eastgate Road, Eastgate Business Park, Little Island, Co.
                  Cork,&nbsp;T45 E181. Your Life Insurance is provided by IptiQ
                  Life SA. Laya Healthcare Limited trading as Laya Life is
                  regulated by the Central Bank of Ireland. Travel insurance
                  provided by&nbsp;AIG Europe Limited.
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "LayaFooter",
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    logout() {
      this.$store.commit("logout");
      this.$router.push("/login");
    }
  },
  computed: {}
};
</script>
<style lang="less">
#laya-footer {
  padding: 0;
  text-align: center;
  @media only screen and (max-width: 1200px) {
    .footer_margin img {
      max-width: 100%;
      height: auto !important;
    }
  }
  @media only screen and (max-width: 767px) {
    /* Correct the fact T4 sets image sizes */
    .footer_margin img {
      max-width: 100%;
      height: auto !important;
    }
  }
  footer {
    width: 100%;
    max-width: 100%;
    padding-top: 0px;
    margin-top: 0;
    text-align: left;
    /*t4 type="media" id="20990" formatter="path/*"/>) #F1F1F1 repeat-x left top;*/
    background: url(/media/layacommon/images/icons/dots_bg.jpg) repeat-x left
      top;
    ul {
      padding-left: 0;
    }
    li {
      padding-right: 5px;
    }
  }
  footer .footer-wrapper {
    padding-top: 15px;
    padding-bottom: 20px;

    background: #1c3660; /* Old browsers */
    background: -moz-linear-gradient(
      left,
      #1c3660 0%,
      #00679a 100%
    ); /* FF3.6+ */
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(0%, #1c3660),
      color-stop(100%, #00679a)
    ); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(
      left,
      #1c3660 0%,
      #00679a 100%
    ); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(
      left,
      #1c3660 0%,
      #00679a 100%
    ); /* Opera 11.10+ */
    background: -ms-linear-gradient(left, #1c3660 0%, #00679a 100%); /* IE10+ */
    background: linear-gradient(to right, #1c3660 0%, #00679a 100%); /* W3C */
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='#1c3660', endColorstr='#00679a',GradientType=1 )"; /* IE6-9 */
  }

  footer .footer-legal p,
  footer .footer-award p {
    margin-top: 10px;
    color: #747474;
    font-size: 12px;
  }
  footer .footer-content {
    padding-top: 15px;
    padding-bottom: 10px;

    color: #fff;
  }

  footer .footer-content .footer-section {
    display: inline-block;

    height: 95px;
    padding: 10px 10px;

    vertical-align: top;

    border-left: 2px solid #3e6288;
  }
  footer .footer-content .first-section {
    border-left: none;
  }
  footer .footer-content .footer-section li {
    display: inline;

    margin-right: 0;
    padding-left: 5px;

    border-left: 2px solid #3e6288;
  }
  footer .footer-content .footer-section li.first-item {
    border-left: none;
      padding-left: 0;
  }
  footer .footer-content h5 {
    font-size: 14px;

    margin-bottom: 10px;

    color: #4cc2f1;
  }
  footer .footer-content .footer-social h5 {
    margin-bottom: 8px;
  }

  footer .footer-content .footer-social li {
    border: none;
  }
  footer .footer-content .footer-social li a {
    text-decoration: none;
  }

  footer .footer-content a {
    font-size: 14px;

    color: #fff;
  }
  footer .mobile-only-menu {
    padding-top: 0;
  }
  footer .mobile-only-menu ul {
    margin-top: 0;
  }
  @media only screen and (max-width: 767px) {
    footer .footer-content .footer-section {
      width: 100%;
      border: none;
    }
    footer .footer-content .first-section {
      padding-left: 20px;
    }
    footer .footer-wrapper {
      padding-top: 0px;
      padding-bottom: 0px;
    }
    footer .footer-content .footer-social {
      text-align: center;
      height: auto;
      padding-top: 0;
      padding-bottom: 0;
    }
    footer .footer-content .footer-social li {
      margin: 0 1%;
    }

    footer .footer-award {
      text-align: center;
    }
  }

  @media only screen and (max-width: 576px) {
    footer .laya-footer_links {
      display: none !important;
    }
  }

  @media only screen and (max-width: 480px) {
    footer .footer-content .footer-section li {
      display: block;
      padding-right: 5px;
      padding-left: 0;

      border-left: none;
    }
    footer .footer-content .footer-social li {
      display: inline;
    }
  }

  @media only screen and (max-width: 360px) {
    footer .footer-content .footer-section li {
      padding-right: 0px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 992px) {
    footer .footer-content .footer-list {
      border-left: none;
    }
  }
}
</style>
