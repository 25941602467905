<template>
	<div class="col-md-6 col-sm-12 dc-cat">
		<div class="dc-inner-cat">
			<!--			<div class="dc-cat-divider"><div class="dcd"></div></div>-->
			<h4 class="dc-cat-title">
				{{ category.title }}
			</h4>
			<h5 class="dc-duration">Duration : {{ category.duration }} mins</h5>
			<div class="dc-cat-divider"><div class="dcd"></div></div>
			<div v-if="dc_setting !== 'client_subscription'" class="dc-cat-price">
				<h5 class="dc-price">
					Price:
					<span class="dc-amount">{{getCurrencyPriceString(category.currency, this.category.price, true)}}</span>
					
					<span v-if="category.tax > 0" class="dc-vat">&nbsp; (+ {{getCurrencyPriceString(category.currency, category.tax, true)}} VAT)</span>
				</h5>
				<div class="dc-cat-price-triangle"></div>
			</div>
			<div class="mt-4 dc-cat-button">
				<b-button
					class="px-5 shadow fixed_width filson-book"
					variant="cta"
					@click="showPathwayModal(category)"
				>View More</b-button>
			</div>
			<div class="dc-featured-image">
				<div class="img-triangle-overlay"></div>
				<img
					class="dc-cat-image"
					:src="category.featured_img"
				/>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "Category",
	props: ['category','dc_setting'],
	inject : ['showPathwayModal'],
	methods : {
		getFullPrice(){
			return this.category.price + this.category.tax
		}
	}
}
</script>
<style lang="less">
.dc-cat {
	position: relative;
	padding: 15px 30px;
	.dc-inner-cat {
		position: relative;
		background-color: white;
		overflow: hidden;
		padding: 20px;
		border-radius: 20px 0px 0px 20px;
		box-shadow: 0px 5px 15px rgba(0,0,0,0.12);
		.dc-cat-divider {
			position: relative;
			padding: 7px 0px;
			.dcd {
				width: 40%;
				border-bottom: 1px solid rgba(0,0,0,0.05);
			}
		}
		.dc-cat-title {
			font-size: 2rem;
			min-height: 5rem;
			margin: 7px 0px 0px;
			position: relative;
			z-index: 3;
			max-width: 60%;
		}
		.dc-duration {
			color: rgba(0,0,0,0.5);
			font-weight: 300;
			font-family: "FilsonProLight", sans-serif;
		}
		.dc-cat-price {
			position: relative;
			background: rgb(255,255,255);
			background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(242,241,244,1) 50%);
			padding: 10px 30px 1px 0px;
			max-width: 24rem;
			margin: 7px 0px;
			.dc-price {
				font-size: 1.8rem;
				font-weight: 300;
				font-family: "FilsonProLight", sans-serif;
				color: rgba(0,0,0,0.4);
				.dc-amount {
					color: rgba(0,0,0,0.7);
					font-family: "FilsonProBold", sans-serif;
				}
				.dc-vat {
					font-size: 13px;
					color: black;
				}
			}
			.dc-cat-price-triangle {
				position: absolute;
				top: 0px;
				right: 0px;
				width: 0;
				height: 0;
				border-left: 18px solid transparent;
				border-right: 10px solid white;
				border-bottom: 18px solid transparent;
				border-top: 18px solid transparent;
				z-index: 78;
			}
		}
		.dc-featured-image {
			position: absolute;
			right:0;
			top:0;
			max-width: 50%;
			overflow: hidden;
			.img-triangle-overlay {
				position: absolute;
				top: 0px;
				left: 0px;
				width: 0;
				height: 0;
				//border-left: 123px solid transparent;
				border-left: 150px solid white;
				border-right: 300px solid transparent;
				//border-bottom: 123px solid white;
				border-bottom: 300px solid transparent;
				z-index: 1;
			}
			img {
				max-width: 160%;
				position: relative;
				top: -10%;
				left: -20%;
			}
		}
	}
}
</style>
