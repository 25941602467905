<template>
	<div class="p-3">
		<div class="stripe-wrap col-12 pt-2">
			<div v-if="!mounted">
				<Loader />
			</div>
			<form id="payment-form" :class="mounted?'min_height':''">
				<div id="payment-element">
					<!--Stripe.js injects the Payment Element-->
				</div>
				<div v-if="mounted" id="payment_button" class="animate__animated animate__fadeIn">
					<br/>
					<b-button :disabled="disabled" type="submit" id="submit" variant="cta" block class="filson-book shadow">
						<span v-html="button_text"></span>
					</b-button>
				</div>
				<br/>
				<div id="message">{{message}}</div>
			</form>
		</div>
	</div>
</template>
<script>
import EventBus from "@/helpers/eventbus";
import config from "../../config/constants";
import Loader from "../Loader";

export default {
	components: {
		Loader
	},
	name: "DigitalBookingsBookingPaymentCard",
	data() {
		return {
			button_text : 'Pay now',
			message : '',
			mounted : false,
			disabled : false
		};
	},
	created() {
		this.loadStripeScript();
	},
	beforeDestroy() {
	
	},
	methods: {
		loadStripeScript() {
			if (!document.getElementById("stripe-script")) {
				let script = document.createElement("script");
				script.type = "text/javascript";
				script.src = "https://js.stripe.com/v3/";
				script.id = "stripe-script";
				document.head.appendChild(script);
			}
			this.$nextTick().then(() => {
				this.checkStripe();
			});
		},
		checkStripe() {
			//check every two seconds to see if the stripe script has loaded and form can be mounted
			if (window.Stripe) {
				this.initStripeForm();
			} else {
				setTimeout(() => this.checkStripe(), 500);
			}
		},
		async initStripeForm() {
      let path = "api/u/digital-bookings/combined-payment-with-card";
      if(this.isConnectedMentalHealth()) {
        path = "api/u/digital-bookings/payment-with-card-cmh";
      }
			let res = await this.api({
				path: path,
				timeslot_id: this.timeslot_id,
				wallet : this.wallet_amount,
				card : this.card_amount,
				price : this.price
			});
			if (res.success === false) {
				this.message = 'Something went wrong'
				return
			}
			//create a Stripe client - stripe script is loaded in index.html
			console.log('here')

			const appearance = {
				theme: 'stripe',
				
				variables: {
          colorPrimary: '#30313d',
          colorBackground: '#f7fafc',
          colorText: 'black',
          colorDanger: '#df1b41',
          fontFamily: 'Ideal Sans, system-ui, sans-serif',
          spacingUnit: '3px',
          borderRadius: '5px',
				}
			};
			
			const stripe = window.Stripe(config.stripe_key);
			const elements = stripe.elements({
				clientSecret : res.client_secret,
				appearance : appearance
			});
      let paymentElement;
      if(this.currency === 'EUR') {
        paymentElement = elements.create('payment', {
          defaultValues: {
            billingDetails: {
              address: {
                country: 'IE',
              }
            },
          }});
      } else {
        paymentElement = elements.create('payment');
      }

			paymentElement.mount("#payment-element");
			const form = document.getElementById('payment-form');
			this.mounted = true
			form.addEventListener('submit', async (event) => {
				this.button_text = 'Processing <i class="fa-solid fa-circle-notch fa-spin"></i>'
				this.disabled = true
				this.message = ''
				event.preventDefault();
				let return_url = window.location.origin+'/clinics/digital'
        if(this.is_cmh) {
          return_url =  window.location.origin + '/service-dashboard/' + this.$route.params.slug;
        }
				const res = await stripe.confirmPayment({
					//`Elements` instance that was used to create the Payment Element
					elements,
					redirect: "if_required",
					confirmParams: {
						return_url: return_url
					}
				});
				if(res.hasOwnProperty('error')){
					this.message = res.error.message
					this.button_text = 'Pay Now'
					this.disabled = false
				} else if(res.hasOwnProperty('paymentIntent')) {
					if(res.paymentIntent.status === 'succeeded') {
						this.message = 'Payment Succeeded.';
						this.button_text = 'Thanks'
            if(!this.isConnectedMentalHealth()) {
              setTimeout(()=>{
                this.$bvModal.hide("clinician_modal");
                setTimeout(()=>{
                  if(this.is_cmh) {
                    this.$router.push({path: '/service-dashboard/'+this.$route.params.slug})
                  } else {
                    this.$router.push({name :'digitalClinicsGeneric'})
                  }
                }, 500)
              }, 3000)
            } else {
              EventBus.$emit('cmhPaymentSuccess', res)
            }
					}
				} else {
					// dont know whats going on
					this.message = 'Something went wrong'
				}
				
			});
		},
	},
  props: {
    timeslot_id: [Number, String],
    wallet_amount: [Number, String],
    card_amount: [Number, String],
    return_url: String,
    price: [Number, String],
    currency: {
      type: String,
      default: "EUR"
    },
    is_cmh: {
      type: Boolean,
      default: false
    }
  }
};
</script>
<style lang="less">
.stripe-wrap {
	#payment-form {
		&.min_height{
			min-height: 300px;
		}
		button {
			border: none;
		}
	}
}
</style>
