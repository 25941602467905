<template>
	<div id="CompanyBoard" class="bg-white">
    <div v-if="category.image_url && $route.params.id" class="p-4">
      <div class="container mx-auto pb-5 bg-banner" :style="{'background':'url('+category.image_url+') no-repeat'}">
        <h1 class="text-center page-title">{{category.title}}</h1>
      </div>
    </div>
		<div v-else class="bg-grey">
			<div class="container mx-auto pb-5">
				<h1 v-if="!$route.params.id && isLandG" class="text-center page-title">Your Legal &amp General Mental Health &amp Wellbeing Resources</h1>
				<h1 v-if="!$route.params.id" class="text-center page-title">{{alias}}</h1>
        <h1 v-else class="text-center page-title">{{category.title}}</h1>
				<div class="row my-5 d-flex align-items-stretch"  v-if="featuredPosts.length > 0">
					<div class="image-card-container col-lg-4 col-md-4 col-sm-12" v-for="post in featuredPosts">
						<div class="image-card bg-white d-flex flex-column align-items-center">
							<img class="image-card-image img-fluid" :src="post.image_url" />
							<h2 class="image-card-title px-5">{{ post.title }}</h2>
							<p class="image-card-text px-5 pb-3" v-html="decodeData(post.excerpt)"></p>
							<b-button variant="cta" class="mb-5 mt-auto min-width"
								@click="$router.push('/company-board/' + post.id)">{{post.title}}</b-button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container d-flex justify-content-center" v-if="!$route.params.id">
        <b-button-group class="py-5 d-flex flex-row flex-wrap button-flex">
          <div v-for="category in categories"
					class="category-button mx-0 border-brand-secondary"
          :class="{'bg-brand-secondary':category.id === selectedCategoryId}"
					@click="selectCategoryId(category.id)">
            {{ category.title }}
          </div>
			</b-button-group>
		</div>
		<div class="container">
      <h4 class="mt-5" v-if="$route.params.id && category.description"><span>Welcome to {{category.title}}</span><div class="title-line"></div></h4>
      <p class="categoryDescription">{{category.description}}</p>
      <h4 class="mt-5" v-if="$route.params.id && posts.length"><span>News and Events</span><div class="title-line"></div></h4>
			<div class="row my-5 d-flex align-items-stretch" v-if="posts.length">
				<div class="image-card-container col-lg-3 col-md-3 col-sm-12 pb-5" v-for="post in posts">
					<div class="image-card bg-white d-flex flex-column align-items-center shadow">
						<img class="image-card-image img-fluid" :src="post.image_url" />
						<h2 class="image-card-title px-5">{{ post.title }}</h2>
						<p class="image-card-text px-5 pb-3" v-html="decodeData(post.excerpt)"></p>
						<b-button variant="cta" class="mb-5 mt-auto min-width"
							@click="$route.params.id ?
							$router.push('/company-board/' + post.id + '?cat='+ $route.params.id):
							$router.push('/company-board/' + post.id)"
            >{{post.title}}</b-button>
					</div>
				</div>
			</div>
      <h4 class="mt-1" v-if="$route.params.id && resources.length"><span>Resources</span><div class="title-line"></div></h4>
      <div class="row my-5 d-flex align-items-stretch" v-if="$route.params.id && resources.length">
        <div class="image-card-container col-lg-3 col-md-3 col-sm-12 pb-5" v-for="post in resources">
          <div class="image-card bg-white d-flex flex-column align-items-center shadow">
            <img class="image-card-image img-fluid" :src="post.image_url" />
            <h2 class="image-card-title px-5">{{ post.title }}</h2>
            <p class="image-card-text px-5 pb-3" v-html="decodeData(post.excerpt)"></p>
            <b-button variant="cta" class="mb-5 mt-auto min-width"
                      @click="$route.params.id ?
							$router.push('/company-board/' + post.id + '?cat='+ $route.params.id):
							$router.push('/company-board/' + post.id)"
            >{{post.title}}</b-button>
          </div>
        </div>
      </div>
		</div>
	</div>
</template>
<script>
import axios from "axios";
import config from "../config/constants";

export default {
	components: {

	},
	name: "CompanyBoard",
	data() {
		return {
			categories: [],
      resources: [],
			posts: [],
			selectedCategoryId: null,
			featuredPosts: [],
      category: {}
		};
	},
	mounted() {
    if(this.$route.params.id) {
      this.selectedCategoryId = this.$route.params.id
    } else {
      this.getLanding();
    }
	},
	beforeDestroy() {

	},
	computed: {
		alias() {
			const cbNavItem = this.$store.getters.nav.filter(n => n.item_slug === 'companyBoard');
			if(cbNavItem) {
				return cbNavItem[0].label;
			} else {
				return "Mental Health & Wellbeing Resources";
			}
		},
		isLandG() {
			const landgid = process.env.NODE_ENV === 'production' ? 5670 : 6016;
			return Number.parseInt(this.$store.getters.user.user_client_id) === landgid;
		}
	},
	methods: {
		decodeData(data) {
			try {
				return atob(data)
			} catch (error) {
				return data
			}
		},
		async getLanding() {
			const payload = {
				user_id: this.$store.getters.user_id,
			};
			let res = await axios.post(
				config.api_env + "/application/api/u/company-board/get-landing-page",
				payload,
				config.options
			);
			if (res.data.success) {
				this.categories = res.data.categories;
				this.selectedCategoryId = res.data.categories[0].id;
				this.featuredPosts = res.data.featured_posts.slice(0, 3);
			} else if (res.data.err && res.data.err.includes("3994")) {
				this.$store.dispatch("logout");
				this.$router.push("/login").catch((err) => { });
			}
		},
		async getPosts(id) {
			const payload = {
				user_id: this.$store.getters.user_id,
				category_id: this.selectedCategoryId,
			};
			let res = await axios.post(
				config.api_env + "/application/api/u/company-board/get-category-posts",
				payload,
				config.options
			);
			if (res.data.success) {
				this.posts = [];
        this.resources = [];
        res.data.data.forEach(post => {
          if(post.post_type === 'resource' && this.$route.params.id) {
            this.resources.push(post)
          } else {
            this.posts.push(post)
          }
        })
        this.category = res.data.category;
			} else if (res.data.err && res.data.err.includes("3994")) {
				this.$store.dispatch("logout");
				this.$router.push("/login").catch((err) => { });
			}
		},
		selectCategoryId(id) {
			this.selectedCategoryId = id;
		}
	},
	watch: {
    '$route.params.id': {
      handler: function (val) {
        this.selectCategoryId(val)
      }
    },
		selectedCategoryId(id) {
			this.getPosts(id);
		}
	},
};
</script>
<style lang="less">
#CompanyBoard {
  h4 {
    font-size: 2em;
    font-family: 'FilsonProBold', sans-serif;
    line-height: 0.1em;
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    span {
      margin-right: 10px;
    }
    .title-line {
      border-bottom: 1px solid #e5e3e3;
      flex-grow: 1
    }
  }
  .category-button {
    text-align: center;
    font-size: 1.6rem;
    min-width: 100px;
    display: flex;
    align-items: center;
    border-radius: 20px;
    justify-content: center;
    cursor: pointer;
    padding: 3px 10px;
    border-width: 1px;
    border-style: solid;
    margin: 5px 10px !important;
    min-height: 30px;
    &.bg-brand-secondary {
      color: white;
    }
  }
	.page-title {
		padding: 40px 10px !important;
		margin-bottom: 0 !important;
		font-size: 2rem !important;
	}
  .categoryDescription {
    font-style: "Filson Pro Book", sans-serif;
    font-size: 1.8em;
    margin-bottom: 40px;
  }
	.image-card-container {
		.image-card {
			height: 100%;
		}

		.image-card-title {
			font-family: 'FilsonProBold', sans-serif;
			padding-top: 20px;
			font-weight: bold;
			font-size: 1.8rem;
		}

		.image-card-text {
			font-size: 1.4rem;
		}
	}

	.min-width {
		max-width: 90%;
		min-width: 150px;
	}
  .bg-banner {
    background-size: cover !important;
    min-height: 250px;
    display: flex;
    align-items: center;

    .page-title {
      margin-left: 40px;
      color: white;
      font-size: 5em !important;
      text-align: left !important;
    }
  }
}
</style>