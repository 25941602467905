<template>
    <div id="BupaGPMeetingRoom">
            <div class="close-button d-flex flex-row justify-content-end mt-4" @click="closeModal()"><i
                    class="fas fa-times brand-highlight-1"></i></div>
        <AudioRoomContainer :booking_id="booking.id" class="animate__animated animate__fadeIn" />
    </div>
</template>
<script>
import Loader from "@/components/Loader";
import AudioRoomContainer from "./AudioRoomContainer.vue";

export default {
    props: ['booking'],
    components: {
        Loader,
	      AudioRoomContainer
    },
    name: "MeetingRoomModal",
    data() {
        return {
        };
    },
    mounted() {
    },
    methods: {
        async closeModal() {
            this.$bvModal.hide('bupa_gp_modal');
        },
    },
    computed: {
        envString() {
            console.log(process.env.NODE_ENV)
            return process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'staging';
        }
    },

};
</script>
<style lang="less">
#bupa_gp_modal___BV_modal_outer_ {
    z-index: 1000000 !important;

    * {
        scrollbar-width: thin;
        scrollbar-color: #aaa;
    }

    /* Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
        width: 8px;
    }

    *::-webkit-scrollbar-track {
        background: rbga(0, 0, 0, 0);
        margin: 10px
    }

    *::-webkit-scrollbar-thumb {
        background-color: #aaa;
        border-radius: 10px;
        border: 5px none #ffffff;
        opacity: 0;
    }

    .hidden-link {
        opacity: 0;
        cursor: pointer;
    }
    .close-button{
        z-index: 1000;
        font-size: 2rem;
        cursor: pointer;
    }
}

#BupaGPMeetingRoom {
    max-height: 93vh;
    overflow-y: auto;
    overflow-x: hidden;
}
</style>
