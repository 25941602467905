<template>
  <div id="TherapyPasswordReset">
    <h2 class="mt-3 mb-3">Password Reset</h2>
    <hr />
    <b-form class="my-3">
      <b-form-group
          id="passwordGroup"
          label="Password"
          label-for="password"
      >
        <b-form-input
            type="password"
            id="password"
            :state="!passwordTouched || passwordIsValid.valid"
            v-model="password"
            @focus="pw_flag = true"
            @blur="pw_flag = false"
        />
      </b-form-group>
      <b-form-group class="col-md-12 validationBox" v-if="pw_flag && !passwordIsValid.valid">
        <span :class="[passwordIsValid.pw_length?'passed':'failed']"><i class="fa-light fa-xmark" v-if="!passwordIsValid.pw_length"></i><i class="fa-light fa-check" v-else></i> At least 10 characters</span><br>
        <span :class="[passwordIsValid.upper_case?'passed':'failed']"><i class="fa-light fa-xmark" v-if="!passwordIsValid.upper_case"></i><i class="fa-light fa-check" v-else></i> At least 1 uppercase letter</span><br>
        <span :class="[passwordIsValid.lower_case?'passed':'failed']"><i class="fa-light fa-xmark" v-if="!passwordIsValid.lower_case"></i><i class="fa-light fa-check" v-else></i> At least 1 lower letter</span><br>
        <span :class="[passwordIsValid.number?'passed':'failed']"><i class="fa-light fa-xmark" v-if="!passwordIsValid.number"></i><i class="fa-light fa-check" v-else></i> At least 1 number</span><br>
        <span :class="[passwordIsValid.special?'passed':'failed']"><i class="fa-light fa-xmark" v-if="!passwordIsValid.special"></i><i class="fa-light fa-check" v-else></i> At least 1 special character</span>
      </b-form-group>
      <b-form-group
          id="passwordRepeatGroup"
          label="Repeat Password"
          label-for="repeatPassword"
      >
        <b-form-input
            type="password"
            id="repeatPassword"
            v-model="repeatPassword"
            :state="passwordIsValid.match"
        />
      </b-form-group>
    </b-form>
    <div v-if="error" class="error">
      Password reset failed! <br>
      {{error}}
    </div>
    <div v-if="displaySuccess">
      <p>
        Password changed!  You can now <a @click="$router.push('/start')" href="">login</a> as usual.
      </p>
    </div>
    <button class="therapyBtn mt-4 mb-5 mx-auto" :disabled="buttonDisabled" @click="reset">Change Password</button>
    <hr>
    <p>
      <a href="" @click="$router.push('/start')">Back to Login page</a>
    </p>
  </div>
</template>

<script>
  export default {
    name: "TherapyPasswordReset",
    data() {
      return {
        password: '',
        repeatPassword: '',
        pw_flag: false,
        passwordTouched: false,
        error: null,
        displaySuccess: false
      }
    },
    methods: {
      async reset() {
          const res = await this.api({
            path: 'api-hc/reset-password',
            auth_key: this.auth_key,
            email: this.email,
            password: this.password,
            password_repeat: this.repeatPassword
          });
          if(res.success) {
            this.displaySuccess = true;
          } else {
            this.error = res.err;
            setTimeout(()=> {
              this.error = null
            },10000)
          }
      }
    },
    watch: {
      pw_flag() {
        if(!this.passwordTouched) this.passwordTouched = true;
      },
    },
    computed: {
      auth_key() {
        return this.$route.query.auth_key;
      },
      email() {
        return this.$route.query.id
      },
      buttonDisabled() {
        return !this.passwordIsValid.valid || !this.passwordIsValid.match;
      },
      passwordIsValid() {
        const length = this.password.length >= 10;
        const uppercase = /.*[A-Z].*/.test(this.password);
        const lowercase = /.*[a-z].*/.test(this.password);
        const number = /\d/.test(this.password);
        const special = /[^\w\d]/.test(this.password);
        return {
          pw_length: length,
          upper_case: uppercase,
          lower_case: lowercase,
          number: number,
          special: special,
          valid: length && uppercase && lowercase && number && special,
          match: this.password === this.repeatPassword
        }
      },
    }
  }
</script>

<style lang="less">
#TherapyPasswordReset {
  .error {
    color: darkred;
    text-align: center;
    font-size: 1.5rem;
  }
  .validationBox {
    font-size: 1.1rem;
    font-family: FilsonProBold, Arial, Helvetica, sans-serif;
    .passed {
      color: #0BDA51;
    }
    .failed {
      color: darkred;
    }
  }
}
</style>