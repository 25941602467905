<template>
  <div id="VideoRoom">
    <h3 v-if="meetingStatus === 'testRoom'" class="text-center pb-3 px-3 brand-secondary"></h3>
    <h3 v-if="meetingStatus === 'active' && remote_participant_connected === false" class="text-center pb-3 px-3 brand-secondary">Waiting for Clinician</h3>
    <h3 v-if="meetingStatus === 'active' && remote_participant_connected === true" class="text-center pb-3 px-3 brand-secondary">
	    {{clinician_name}}
    </h3>
    <h3 v-if="meetingStatus === 'ended'" class="text-center pb-3 px-3 brand-secondary">Session Ended</h3>
    <div class="col-md-12 utilities_container">
      <div id="remote_audio"></div>
      <div id="video_buttons">
        <div v-if="status_message" class="row d-flex flex-row justify-content-center text-center">
	        <p v-html="status_message"></p>
        </div>
        <div v-else class="row d-flex flex-row justify-content-center text-center">
          <div class="">
            <div key="a1" v-if="audio_enabled" class="vid_button mic animate__animated animate__fadeIn">
              <div class="bt_inner" style="margin: auto" @click="toggleAudio">
                <i class="fas fa-microphone"></i>
              </div>
            </div>
            <div key="a2" v-else class="vid_button mic animate__animated animate__fadeIn">
              <div class="bt_inner off" style="margin: auto" @click="toggleAudio">
                <i class="fas fa-microphone-slash"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-button variant="cta" class="mt-5" v-if="!permissionsStatus" disabled block>
        Accept Permissions
      </b-button>
      <b-button variant="cta" class="mt-5" @click="joinRoom()" v-else-if="meetingStatus === 'testRoom'" block>
        Join Meeting
      </b-button>
      <b-button variant="cta" class="mt-5" @click="endSession" v-else-if="meetingStatus === 'active'" block>
        End Meeting
      </b-button>
      <b-button variant="cta" class="mt-5" @click="closeModal" v-else-if="meetingStatus === 'ended'" block>
        Close
      </b-button>

    </div>
  </div>
</template>

<script>
const { connect, LocalVideoTrack, LocalAudioTrack, RemoteVideoTrack, RemoteAudioTrack } = require('twilio-video');

import * as VideoProcessors from '@twilio/video-processors';
import moment from 'moment';

export default {
  name: "AudioRoom",
  data() {
    return {
      video_processor: false,
      status_message: null,
      room_object: false,
      meetingStatus: 'testRoom',
      meetingStart: null,
      meetingEnd: null,
      permissionsStatus: false,
      audio_enabled: true,
      video_enabled: true,
      remote_audio_enabled: false,
      duration: 0,
	    remote_participant_connected : false
    }
  },
  mounted() {
    let self = this;
    navigator.permissions.query({ name: 'microphone' })
      .then(function (result) {
        if (result.state == 'granted') {
	        self.permissionsStatus = true;
        } else if (result.state == 'prompt') {
          // self.status_message = 'Please allow microphone access...';
	        console.log('prompt for permissions')
        } else {
	        console.log('permissions else')
          // self.status_message = 'Please allow microphone permissions and refresh the page...'
        }
      }).catch((error) => {
        // self.status_message = 'Please allow microphone access...'
        self.permissionsStatus = false;
      });
	
	  // navigator.mediaDevices.getUserMedia(
		// 	{
		// 		audio: true,
		// 		video: true
		// 	}).then(function(mediaStream) {
		// 		return Video.connect(token,
		// 			{
		// 				name: 'my-cool-room',
		// 				tracks: mediaStream.getTracks()
		// 			});
		// 	}).then(function(room) {
		// 		room.on('participantConnected', function(participant) {console.log(participant.identity + ' has connected');});
		//     room.once('disconnected', function() {console.log('You left the Room:', room.name);});}).catch(error => {console.log('Could not connect to the Room:', error.message);});

    // below unfortunetely has to stay for scenario where local track is passed from parent as device tests
    // we could remove that passing media tracks bullshit all together and just destroy and create again in this component
    // we need to think about it, and see how is that in terms of performance
    // if (this.local_video_track instanceof LocalVideoTrack) {
    //   this.attachLocalVideo()
    // }
    // both just in case, can't use await here probably we will need to refactor this
    //this aren't triggering in time for them to be attached to the meeting or osmething like that?
    // this.createLocalVideo()
    // this.createLocalAudio()
    //
    this.initializeLocalTracks();

    // this.requestBookingRoom()
  },
  beforeDestroy() {
    let self = this;
    this.endSession()
    this.$emit('terminateVideoAndAudio');
    if (this.duration > 60 * 5) {
      this.$root.$emit('show_dc_feedback_modal');
    }
  },
  props: ['access_token', 'local_audio_track', 'clinician_name'],
  inject: ['createLocalAudio', 'destroyLocalAudio'],
  methods: {
    async toggleAudio() {
      if (this.local_audio_track.isEnabled) {
        this.local_audio_track.disable()
      } else {
        this.local_audio_track.enable()
      }
      this.audio_enabled = this.local_audio_track.isEnabled
      console.log('audio track enabled : ', this.local_audio_track.isEnabled)
    },
    async closeModal() {
      this.$bvModal.hide('bupa_gp_modal');
      // feedback only requested if the session lasted longer than 5 minutes
    },
    async endSession() {
      let self = this;
      this.meetingStatus = 'ended';
      let duration = moment().diff(self.meetingStart, 'seconds');
      this.duration = duration;
      this.status_message = 'Thanks for joining.<br />Your Session Has Now Ended<br /><b>Duration: ' + moment.utc(duration * 1000).format('HH:mm:ss') + '</b>';
			
	    if (this.room_object) {
		    this.room_object.disconnect();
		    this.room_object = null
	    }
	
	    if (this.local_audio_track) {
		    await this.destroyLocalAudio()
	    }
			
    },
	  
    async initializeLocalTracks() {
      let audioCreated;
      audioCreated = await this.createLocalAudio();
      if (audioCreated) {
        this.permissionsStatus = true;
      }
    },

    async joinRoom() {
	    this.meetingStatus = 'active'
	    this.meetingStart = moment();
      let tracks = []
      tracks.push(this.local_audio_track)
      try {
        this.room_object = await connect(this.access_token, {
          tracks
        });
      } catch (e) {
        console.warn(e)
        return;
      }
			
			this.status_message = null // reset the messge displayed to the user in case its about to accept permissions

			console.log(this.room_object)
			const _this = this
      this.room_object.participants.forEach(coach => {
	      _this.remote_participant_connected = true
        coach.on('trackSubscribed', track => {
          if (track.kind === 'audio') {
            // https://sdk.twilio.com/js/video/releases/2.19.1/docs/RemoteAudioTrack.html
	          document.getElementById('remote_audio').appendChild(track.attach());
            if (track.isEnabled) {
              this.remote_audio_enabled = true
            }
            track.on('enabled', () => {
              console.log('remote audio enabled')
              this.remote_audio_enabled = true
            })
            track.on('disabled', () => {
              console.log('remote audio disabled')
              this.remote_audio_enabled = false
            })
          }
        });
      });
			

      this.room_object.on('participantConnected', participant => {
				console.log('participant connected', participant)
	      participant.on('trackSubscribed', track => {
		      if (track.kind === 'audio') {
			      // https://sdk.twilio.com/js/video/releases/2.19.1/docs/RemoteAudioTrack.html
			      document.getElementById('remote_audio').appendChild(track.attach());
			      if (track.isEnabled) {
				      this.remote_audio_enabled = true
			      }
			      track.on('enabled', () => {
				      console.log('remote audio enabled')
				      this.remote_audio_enabled = true
			      })
			      track.on('disabled', () => {
				      console.log('remote audio disabled')
				      this.remote_audio_enabled = false
			      })
		      }
	      });
	      _this.remote_participant_connected = true
        participant.on('disconnected', participant => {
	        console.log('Participant disconnected');
	        _this.remote_participant_connected = false
	        this.endSession();
        });
	      
      });
	    
			// Below may not be needed because in this twilio video implementation room is always on the booking and not created / destroyed on the fly
      // this.room_object.on('disconnected', (room, error) => {
      //   console.log('Room disconnected');
      //   let self = this;
      //   // document.getElementById('remote_media').innerHTML = ''
      //   this.general_status = 'completed';
      //   this.endSession();
      //   this.connected = false
      // })
	    
    },
  }
};
</script>

<style lang="less">
#VideoRoom {
  h3 {
    font-family: "FilsonProBold", serif;
  }

  #video_page {
    background-color: white;

    .img_wrapper {
      text-align: center;
      padding: 0px 20px;

      img {
        border-radius: 50%;
        border: 7px solid #eee;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.09);
      }
    }

    .meeting_time {
      padding: 30px;

      .meeting_time_inner {
        box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
        background-color: #403D56;
        color: white;

        div {
          padding: 6px 10px;
        }

        margin-bottom: 15px;
        border-radius: 15px 15px 0px 15px;

        .day {
          font-size: 17px;
        }

        .hour {
          font-weight: lighter;
          font-size: 14px;
        }

        .date {
          font-size: 30px;
        }

        .month {
          font-size: 17px;
          font-weight: lighter;
        }

        .start_booking {
          position: relative;
          padding: 0px;
          min-height: 25px;

          .start_booking_inner {
            position: absolute;
            right: 0px;
            bottom: 0px;
            background-color: #E83B75;
            padding: 7px 25px;
            border-radius: 15px 0px 0px 0px;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }

    .clock_icon {
      text-align: center;
      font-size: 50px;
    }
  }

  #remote_media_box {
    position: relative;
    //min-height: 400px;
    //max-height: 400px;

    #local_video_wrapper {
      max-width: 200px;
      position: absolute;
      bottom: 0px;
      right: 0px;
      overflow: hidden;
      border-radius: 10px 0 10px 0;

      #local_video {
        height: fit-content;
        font-size: 0px;

        video {
          box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
          width: 100%;
          font-size: 0px;
        }
      }
    }

    .test-local-video {
      max-width: none !important;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: center;

      #local_video {
        max-height: 400px;
        height: 100%;
        font-size: 0px;
        overflow: hidden;

        video {
          width: 100%;
          font-size: 0px;
        }
      }
    }

    #remote_media_status_container {
      opacity: 0;
      position: absolute;
      top: 0px;
      left: 30px;
      width: 50px;
      text-align: center;
      height: 200px;

      .remote_vid_button {
        //text-align: center;
        //width: 40px;
        //height: 40px;
        //font-size: 25px;
        //border-radius: 50%;
        //background-color: white;
        //opacity: 0.7;

        z-index: 1000;
        text-align: center;
        font-size: 20px;
        border-radius: 50%;
        display: inline-block;
        background-color: rgba(0, 230, 184, 0.25);
        width: 50px;
        height: 50px;
        color: white;
        margin-right: 1em;
        margin-top: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        &.off {
          background-color: rgba(0, 230, 184, 0.08);
        }
      }
    }

    .test-local-video-placeholder {
      background: rgb(15, 15, 15);
      background: linear-gradient(225deg, rgba(15, 15, 15, 1) 0%, rgba(97, 97, 97, 1) 73%, rgba(28, 28, 28, 1) 100%);
      box-shadow: none !important;

      p {
        color: #fff !important;
      }
    }
  }

  #video_buttons {
    .vid_button {
      position: relative;
      margin-top: 1.5rem;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 10px;

      .bt_inner {
        text-align: center;
        font-size: 25px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #00e6b8;
        width: 60px;
        height: 60px;
        color: white;
        margin-right: 1em;
        box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);

        &.off {
          background-color: #4d4d4f;
        }

        &:hover {
          cursor: pointer;
        }
      }

      span {
        padding-top: 10px;
        position: relative;
        bottom: 5px;
      }
    }
  }

  .utilities_container {
    position: relative;

    .bg_images {
      img {
        border-radius: 5px;
        box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);

        &:hover {
          cursor: pointer;
        }
      }
    }


    .cta-button {
      max-width: 200px;
      text-align: center;
      margin: 0 auto;
    }
  }

  #local_media,
  .remote_media {
    background-color: whitesmoke;
  }

  .animated.infinite {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }

  .animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  .pulse {
    -webkit-animation-name: pulse;
    animation-name: pulse;
  }
}
</style>
