<template>
    <div class="py-3 text-center event-book" id="event-date-button">
        <div v-if="!event_date.ed_user_registered && event_date.ed_fully_booked && !event_date.ed_u_on_waiting_list">
            <b-button @click="registerWaiting" :disabled="isPast(event_date.ed_start_date_time)" class="book-button brand-secondary bg-brand-highlight-1 px-5 py-3 mt-5">{{componentText.waitingList}}</b-button>
        </div>
        <div v-if="!event_date.ed_fully_booked && !event_date.ed_user_registered">
            <b-button @click="startBooking" :disabled="isPast(event_date.ed_start_date_time)" class="cta-button px-5 py-3 my-3">{{componentText.book}}</b-button>
        </div>
        <div v-if="event_date.ed_user_registered">
            <b-button @click="cancelRegistration" :disabled="isPast(event_date.ed_start_date_time)" class="book-button brand-secondary bg-brand-highlight-1 px-5 py-3 mt-5">{{componentText.cancel}}</b-button>
        </div>
        <div v-if="event_date.ed_u_on_waiting_list" class="brand-highlight-1 on-waiting-list">
            {{componentText.onList}}
        </div>
    </div>
</template>

<script>
    import config from "../../config/constants";
    export default {
        name: 'BookDateButton',
        props: [
            'pay_flag',
            'event_date'
        ],
        mounted(){
            //this.showMsg('thanks')
        },
        methods: {
            showMsg(msg){
                this.showMsgModal(msg)
            },
            startBooking() {
                if(this.pay_flag){
                    //load stripe
                    if(!document.getElementById('stripe-script')){
                        let script = document.createElement('script')
                        script.type = 'text/javascript'
                        script.src = 'https://js.stripe.com/v3/'
                        script.id = 'stripe-script'
                        document.head.appendChild(script)
                    }
                    this.requestDateStripeSession()
                } else {
                    this.bookDate()
                }
            },
            redirectToStripe(session){
                const stripe = Stripe(config.stripe_key)
                stripe.redirectToCheckout({sessionId: session.id}).then(function (result) { console.log(result)});
            },
            requestDateStripeSession(){
                const url = config.api_env + "/application/api/u/events/get-event-stripe-session-by-date";
                const payload = {
                    user_id: this.$store.getters.user_id,
                    event_date_id: this.event_date.ed_id,
                    all_series: false
                };
                const opts = {
                    'method' : 'post',
                    'url' : url,
                    'data' : payload
                }
                const _this = this;
                this.axios(opts)
                    .then(function (res) {
                        if(res.data.success == true){
                            _this.redirectToStripe(res.data.session)
                        } else {
                            //TODO something else
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            async bookDate() {
                const payload = {
                    user_id: this.$store.getters.user_id,
                    event_date_id: this.event_date.ed_id,
                    all_series: false
                };
                let res = await this.axios.post(
                    config.api_env + "/application/api/u/events/book-date",
                    payload, config.options
                );
                if (res.data.success) {
                    this.$parent.reloadEvent()
                    if(await this.showMsgModal(this.componentText.success)){
                        this.$router.push({name: 'events', params:{page: 'bookings'}})
                    }
                } else {
                    //TODO something else
                }
            },
            async registerWaiting(){
                const payload = {
                    user_id: this.$store.getters.user_id,
                    event_date_id: this.event_date.ed_id
                }
                let res = await this.axios.post(config.api_env + '/application/api/u/events/register-waiting-list', payload, config.options)
                if(res.data.success) {
                    this.$parent.reloadEvent()
                    if(await this.showMsgModal(this.componentText.registered)){
                        this.$router.push({name: 'events', params:{page: 'bookings'}})
                    }
                }else{
                    //TODO something else
                }
            },
            async cancelRegistration(){
                const payload = {
                    user_id: this.$store.getters.user_id,
                    event_date_id: this.event_date.ed_id,
                    all_series: false
                }
                let res = await this.axios.post(config.api_env + '/application/api/u/events/cancel-date-booking', payload, config.options)
                if( res.data.success ) {
                    this.$parent.reloadEvent()
                    if(await this.showMsgModal(this.componentText.cancelled)){
                        this.$router.push({name: 'events', params:{page: 'bookings'}})
                    }
                } else {
                    //TODO something else
                }
            },
        },
        computed:{
            componentText() {
                return this.getText.loggedIn.event.bookDateButton;
            },
        }
    }
</script>
<style scoped lang="less">
    #event-date-button {
        &.event-book {
            text-align: center;
            .cta-button{
                min-width: 127px;
            }
            .book-button{
                border-width: 1px;
                background: #fff;
                min-width: 127px;
                &.disabled{
                    &:hover {
                        background-color: #fff !important;
                    }
                }
            }
        }
        .on-waiting-list{
            font-family: FilsonProBold, sans-serif;
        }
    }
</style>
