<template>
    <div class="metrics-card">
        <div class="metrics-card-head row bg-brand-primary mx-0 pl-5 pb-4">
            <div class="col-8">
                <h3>{{selectedMetric.title}}</h3>
            </div>
            <div class="col-4">
                <div class="image-wrap">
                    <img v-if="$store.getters.skin === 0" :src="'/img/metrics/'+titleEn+ '-blue.svg'">
                    <img :src="'/img/metrics/'+titleEn+ '-card-generic.svg'" v-else>
                </div>
            </div>
        </div>
        <div class="metrics-card-body bg-white py-5 px-5">
            <div class="metrics-card-input">
                <b-input :placeholder="selectedMetric.value" v-model="selectedMetric.value"></b-input>
                <span class="metrics-separator-dash mx-5" v-if="selectedMetric.metric === 'bp_sys'"> / </span>
                <b-input :placeholder="metrics[6].value" v-model="metrics[6].value" v-if="selectedMetric.metric === 'bp_sys'"></b-input>
                <span class="metrics-input-unit ml-3" v-else>{{selectedMetric.unit}}</span>
            </div>
            <div class="metrics-text py-5" v-html="selectedMetric.text"></div>
            <hr>
            <b-button @click="$emit('logMetric', selectedMetric)" class="cta-button px-5 py-3 my-5">{{componentText.save}}</b-button>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'MetricsCard',
        props: [
            'selectedMetric',
            'titleEn',
            'metrics'
        ],
        computed:{
            componentText() {
                return this.getText.loggedIn.metrics;
            },
        }
    }
</script>