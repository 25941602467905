<template>
  <div id="digital-clinics-login" class="container login">
    <div class="d-none d-md-flex row logo-wrap">
      <div class="laya-logo col-12 py-5 my-5" >
        <img src="/img/laya-logo.png">
      </div>
    </div>
    <div class="row page-content">
      <div class="d-none d-md-flex col-md-6 img-part">
        <div class="login-bg d-flex justify-content-center align-items-center">
          <div class="laya-image"></div>
        </div>
      </div>
      <div class="col-12 col-md-6 pl-md-0 card min-height">
        <div class="card-content px-md-5">
          <div class="mobile-logo d-flex d-md-none">
            <div class="logo-wrap">
              <img src="/img/laya-logo.png">
            </div>
          </div>
          <h2 class="card-title brand-highlight-1 pt-5 pb-4">
            Laya Healthcare Digital Wellbeing
          </h2>
          <p class="laya-info px-md-5 pb-5">
            Please login to access laya healthcare's Digital Wellbeing Experts such as as Physiotherapists, Nutritionists, Healthcoaches and more.
            Visit Digital Studio to access our Wellbeing Series & Digital Gym.
          </p>
          <h4>{{screen === 0 ? 'Login to your laya member area' : 'Login as a Spectrum.life user'}}</h4>
        </div>
        <div class="button-wrap pb-5" >
          <b-button @click="trackClicks" class="cta-button py-3" href="https://www.layahealthcare.ie/memberarea/#/healthcoach/fwd?redir=https://api.aluna.app/application/api-hc/laya-clinic-sso">Login</b-button>
        </div>
        <div class="not-laya-member pb-5">
          <a @click="$emit('spectrum-login')">I'm not a Laya Member</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  //import { setOptions, bootstrap } from 'vue-gtag'

  export default {
    name: "DigitalClinicsLogin",
    data(){
      return{
        screen: 0
      }
    },
    mounted() {
      this.initGoogleAnalytics()
      //this.enablePlugin()
      this.track()
    },
    methods: {
      initGoogleAnalytics(){
        if(!document.getElementById('gtag-script')){
          let script = document.createElement('script')
          script.type = 'text/javascript'
          script.src = 'https://www.googletagmanager.com/gtag/js?id=' + process.env.VUE_APP_GA_TRACKING_ID
          script.id = 'gtag-script'
          script.async = true
          document.head.appendChild(script)
          window.dataLayer = window.dataLayer || [];
          this.gtag('js', new Date());
          this.gtag('config', process.env.VUE_APP_GA_TRACKING_ID);
        }
      },
      gtag(){
        window.dataLayer.push(arguments);
      },
      /*
       * changed on 27/10/19 to facilitate sanusX
      enablePlugin () {
        setOptions({
          config: { id: process.env.VUE_APP_GA_TRACKING_ID },
          params: {
            send_page_view: false
          }
        })
        bootstrap().then(() => {
          // all done!
        })
      }, */
      track() {
        this.gtag('pageview', {
          page_path: '/login',
        })
      },
      trackClicks() {
          this.gtag('event', 'click', {
            'event_category': 'Login',
                  'event_label': 'Login user click',
                  'value': 0
        })
      }
    },
  };
</script>

<style scoped lang="less">
#digital-clinics-login {
  .logo-wrap {
    justify-content: center;
    .laya-logo {
      display: flex;
      justify-content: center;
      img {
        max-width: 230px;
      }
    }
  }
  .page-content {
      max-width: 960px;
    width: 100%;
    background-color: #fff;
    border-radius: 30px;
    overflow: hidden;
    min-height: 400px;
    padding: 0px !important;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    margin: auto;
      .button-wrap{
          display: flex;
          justify-content: center;
          .cta-button{
              width: 180px;
          }
      }
    .img-part {
      padding-left: 0px;
      background: #014d71;
      background-size: cover;
      background-position: bottom;
      .login-bg {
        position: relative;
        height: 100%;
        width: 100%;
        .laya-image {
          height: 280px;
          width: 280px;
          background-image: url("/img/laya-wellness-login.jpg");
          background-color: #014d71;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          border-radius: 50%;
          border-width: 3px;
          border-color: #fff;
          border-style: solid;
        }
      }
    }
    .card {
      background: #ffffff;
      .card-content {
        padding: 25px;
        min-heigh: 795px;
        text-align: left;
        h2 {
          color: #d60570;
            font-family: ff-cocon-web-pro;
            font-size: 2rem;
        }
        p {
          font-size: 1.4rem;
          font-family: "Open Sans", sans-serif;
          text-align: left;
        }
        h4{
          text-align: center;
          color: #d60570;
        }
        .mobile-logo{
          justify-content: center;
          .logo-wrap {
            max-width: 200px;
            img {
              width: 100%;
            }
          }
        }
      }
      .not-laya-member{
        width: 100%;
        text-align: center;
        a{
          color: #444444;
          font-size: 1.4rem;
          text-decoration: underline;
          font-family: "Open Sans", sans-serif;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding: 0;
    border: none;
    box-shadow: none;
    .page-content{
      border-radius: 0;
      box-shadow: none ;
      border: none;
      margin: auto;
      outline: none;
      .card{
        border: none;
      }
    }

  }
}
</style>
