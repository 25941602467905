<template>
    <div id="HomeReminders" class="my-5">
        <HomeLoading v-if="loading" :mode="'medium'"/>
        <div v-else-if="bookings.length > 0 || displayWallet" class="row">
            <div class="col-12 col-lg-8 d-flex flex-column align-items-start">
                <div class="mb-5">
                    <h2 class="home-title">{{componentText.myBookingSummary}}</h2>
                    <div class="home-title-underline bg-brand-secondary"></div>
                </div>
                <div v-if="bookings.length === 0">
                    <p class="no-booking-text brand-text"><b>{{componentText.noBookings}}</b></p>
                </div>
                <div class="col-12 col-lg-8 d-flex flex-row flex-wrap w-100 slick-container slideShow" v-else-if="bookings.length > 3">
                    <slick ref="slick" :options="slickOptions" class="w-100">
                        <div v-for="(booking,i) in bookings" :index="booking.id" :key="'bc-'+i+'-'+booking.id">
                            <BookingCard :booking="booking"/>
                        </div>
                    </slick>
                    <div class="slick-overlay"></div>
                </div>
                <div class="col-12 col-lg-8 d-flex flex-row flex-wrap w-100 slick-container slideShow" v-else>
                    <div class="px-0" v-for="booking in bookings" :index="booking.id" :class="[bookings.length < 3 ? 'col-6' : 'col-4' ]" :key="'bc-3-'+booking.id">
                        <BookingCard class="pr-3" :booking="booking"/>
                    </div>
                </div>
            </div>
            <div v-if="displayWallet" class="col-12 col-lg-4 mb-4 mt-3">
                <div class="mb-5">
                    <div class="d-flex flex-row justify-content-between align-items-center">
                        <h2 class="home-title">My Wallet</h2>
<!--                        <a class="view-all-link brand-text" >View All</a>-->
                    </div>
                    <div class="home-title-underline bg-brand-secondary"></div>
                </div>
                <div class="d-flex flex-column">
                    <WalletCard />
                </div>
            </div >
            <div v-else class="col-md-4 office-image">
              <img src="/img/home/Office-Vector.png" class="w-100 h-100"/>
            </div>
        </div>
    </div>
</template>

<script>
import BookingCard from "@/components/Home/New/BookingCard.vue"
import WalletCard from  "@/components/DigitalClinics/Landing/WalletCard.vue"
import slick from "vue-slick";

import HomeLoading from "@/components/Home/New/HomeLoading.vue";

export default {
    name: "HomeReminders",
    props: {
        isEarliest: Boolean,
        bookings: Array,
        displayWallet: Boolean,
        loading: Boolean
    },
    components: {
        BookingCard,
        WalletCard,
        slick,
        HomeLoading
    },
    data() {
        return {
            slickOptions: {
                arrows: true,
                slidesToScroll: 1,
                slidesToShow: 3,
                nextArrow:
                    '<div class="slick-arrow-next">' +
                    '<a class="bg-brand-highlight-1 slick-arrow-container"><i class="fas fa-chevron-right text-white"></i></a>' +
                    '</div>',
                prevArrow:
                    '<div class="slick-arrow-prev">' +
                    '<a class="bg-brand-highlight-1 slick-arrow-container"><i class="fas fa-chevron-left text-white"></i></a>' +
                    '</div>',
                adaptiveHeight: true,
                infinite: true,
	              lazyLoad : 'ondemand',
                responsive: [
                  {
                    breakpoint: 768,
                    settings: {
                      slidesToShow: 1,
                    },
                  }
              ],
            },
        };
    },
    mounted() {

    },
    methods: {},
    computed: {
      componentText() {
        return this.getText.dfd;
      },
  }
};
</script>

<style lang="less">
#HomeReminders {
    .slideShow {
        max-width: 800px !important;
    }
    .no-booking-text{
        font-size: 1.6rem;
    }
    .home-title {
        font-size: 2.4rem;
        font-family: "FilsonProBold", sans-serif;
        text-align: left;
        margin-bottom: 10px;
    }
    .home-title-underline {
        margin-left: 5px;
        height: 3px;
        width: 50px;
    }

    .view-all-link {
        font-size: 1.2rem;
    }

    .slick-overlay{
        pointer-events: none;
        background: rgb(255,255,255);
        background: linear-gradient(90deg, rgba(255,255,255,0) 35%, rgba(255,255,255,0.7) 65%, rgba(255,255,255,1) 100%);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .slick-arrow-container {
        pointer-events: revert-layer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        height: 100%;
    }
    .slick-slider {
       .HomeBookingCard {
         transform: scale(1);
         transition: all ease-in-out 0.3s;
       }
     }
    .slick-current + div {
      .HomeBookingCard {
        transform: translate(2rem, 0) scale(1.13);
        transition: all ease-in-out 0.3s;
      }
    }

    .slick-current {
        .HomeBookingCard {
            transform: scale(1.25) !important;
            transition: all ease-in-out 0.3s;
        }
    }

    .slick-arrow-next {
        left: 275px;
        height: 40px !important;
        width: 40px !important;
        padding: 0 !important;
    }

    .slick-arrow-prev {
        left: -50px;
        display: none !important;
    }

    .slick-disabled {
        .bg-brand-highlight-1 {
            background-color: lightgray;
        }
    }

    .wallet-banner-inner {
        max-width: 400px;
    }
  @media only screen and (max-width: 992px) {
    .office-image {
      display: none !important;
    }
  }
  @media only screen and (max-width: 767px) {
    margin-bottom: 10px !important;
    margin-top: 10px !important;
    .HomeBookingCard {
      margin: auto !important;
    }
    .slick-current {
      .HomeBookingCard {
        transform: scale(1) !important;
      }
    }
    .slick-arrow {
      display: none !important;
    }
    .slick-overlay {
      display: none !important;
    }
    .office-image {
      display: none !important;
    }
  }
}

</style>
