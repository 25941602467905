<template>
  <div id="CoachingHome" v-if="true">
    <section id="EvolveBanner" class="evolve-home-banner-container pb-5">
      <div class="content-container">
        <div class="row evolve-home-banner mx-0">
          <div class="evolve-home-banner-text col-md-7 col-sm-12">
            <h1 class="evolve-home-banner-title">Total Mental Health</h1>
            <p class="white-text">
              Our Team of <b>Mental Health Coaches</b> are on hand to help you
              make the changes you want or need to make in your life. Whether it
              be emotional wellbeing, motivation, goal setting or a feeling that
              things are just a little off, our qualified and experience coaches
              are here to help.
            </p>
            <p class="white-text">
              You can choose a Coach, make a Video Appointment, and from then
              on, you and your coach will work through the best next steps for
              you to achieve your goals
            </p>
            <b-button class="cta-button px-5 py-3 my-4 shadow" @click="$router.push('/evolve/select-coach')"
              >Sign up!</b-button
            >
          </div>
          <div class="col-md-5">
            <img src="/img/evolve/evolve-mobile.png" class="img-fluid" />
          </div>
        </div>
      </div>
    </section>
    <section id="EvolveReviews" class="my-5" style="position: relative">
      <div class="banner-overlay">
        <img class="wave" src="/img/evolve/banner-wave.svg" />
      </div>
      <div class="evolve-reviews-container content-container mt-5">
        <div class="row mx-5 px-5">
          <div class="col-md-4 col-sm-12 mt-3">
            <div class="evolve-review bg-white shadow">
              <div
                class="
                  d-flex
                  flex-column
                  align-items-center
                  justify-content-center
                "
              >
                <div
                  class="
                    evolve-review-stars
                    mb-4
                    flex-row
                    justify-content-center
                    brand-primary
                  "
                >
                  <i class="fas fa-star fa-2x px-1"></i>
                  <i class="fas fa-star fa-2x px-1"></i>
                  <i class="fas fa-star fa-2x px-1"></i>
                  <i class="fas fa-star fa-2x px-1"></i>
                  <i class="fas fa-star fa-2x px-1"></i>
                </div>
              </div>
              <p class="evolve-review-content text-center">
                Lorem ipsum review of the service. Ut enim ad minim veniam, ex
                ea commoderit in volup
              </p>
              <h4
                class="
                  evolve-review-name
                  brand-highlight-1
                  mx-auto
                  text-center
                  mt-5
                  mb-1
                "
              >
                Mary Smith
              </h4>
              <div class="evolve-review-location text-center">
                Liverpool, UK
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-12 mt-3">
            <div class="evolve-review bg-white shadow">
              <div
                class="
                  d-flex
                  flex-column
                  align-items-center
                  justify-content-center
                "
              >
                <div
                  class="
                    evolve-review-stars
                    mb-4
                    flex-row
                    justify-content-center
                    brand-primary
                  "
                >
                  <i class="fas fa-star fa-2x px-1"></i>
                  <i class="fas fa-star fa-2x px-1"></i>
                  <i class="fas fa-star fa-2x px-1"></i>
                  <i class="fas fa-star fa-2x px-1"></i>
                  <i class="fas fa-star fa-2x px-1"></i>
                </div>
              </div>
              <p class="evolve-review-content text-center">
                Lorem ipsum review of the service. Ut enim ad minim veniam, ex
                ea commoderit in volup
              </p>
              <h4
                class="
                  evolve-review-name
                  brand-highlight-1
                  mx-auto
                  text-center
                  mt-5
                  mb-1
                "
              >
                John Smith
              </h4>
              <div class="evolve-review-location text-center">Dublin, ROI</div>
            </div>
          </div>
          <div class="col-md-4 col-sm-12 mt-3">
            <div class="evolve-review bg-white shadow">
              <div
                class="
                  d-flex
                  flex-column
                  align-items-center
                  justify-content-center
                "
              >
                <div
                  class="
                    evolve-review-stars
                    mb-4
                    flex-row
                    justify-content-center
                    brand-primary
                  "
                >
                  <i class="fas fa-star fa-2x px-1"></i>
                  <i class="fas fa-star fa-2x px-1"></i>
                  <i class="fas fa-star fa-2x px-1"></i>
                  <i class="fas fa-star fa-2x px-1"></i>
                  <i class="fas fa-star fa-2x px-1"></i>
                </div>
              </div>
              <p class="evolve-review-content text-center brand-text">
                Lorem ipsum review of the service. Ut enim ad minim veniam, ex
                ea commoderit in volup
              </p>
              <h4
                class="
                  evolve-review-name
                  brand-highlight-1
                  mx-auto
                  text-center
                  mt-5
                  mb-1
                "
              >
                Anonymous Reviewer
              </h4>
              <div class="evolve-review-location text-center">London, UK</div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      id="EvolvePathways"
      class="bg-white mt-5 py-5"
      style="position: relative"
    >
      <div class="pathway-overlay">
        <img src="/img/evolve/pathway-wave.svg" />
      </div>
      <div class="pathway-blob">
        <div class="d-none d-md-flex flex-row justify-content-end pr-5 pb-5 ">
          <svg
            width="1030"
            height="465"
            viewBox="0 0 1030 465"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.70778 224.951C6.95281 32.8981 231.101 -68.2156 379.208 54.2091L458.826 120.022C532.887 181.24 636.367 192.261 721.66 148.014L779.144 118.194C859.943 76.278 959.415 104.925 1005.54 183.395C1030.03 225.052 1035.7 275.347 1021.28 321.466C990.332 420.439 879.721 470.773 785.087 428.373L687.167 384.501C619.421 354.149 542.13 353.206 473.664 381.897L321.246 445.77C165.864 510.884 -4.76762 393.336 0.70778 224.951Z"
              fill="url(#paint0_linear)"
              fill-opacity="0.7"
            />
            <defs>
              <linearGradient
                id="paint0_linear"
                x1="-11.5749"
                y1="389.182"
                x2="1152.61"
                y2="389.182"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.229672" v-bind:stop-color="startColour" />
                <stop offset="0.859082" v-bind:stop-color="stopColour" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
      <div class="content-container">
        <h2 class="pb-5">Explore Coaching <b>Pathways</b></h2>
        <div class="row evolve-pathways-container">
          <div class="col-md-4 col-sm-12">
            <div class="evolve-pathway bg-brand-secondary text-white">
              <h4 class="evolve-pathway-title">Improving Sleep</h4>
              <p class="evolve-pathway-text">
                Being busy isn't the ball, end-all. Join this challenge to
                discover the benefits of slowing down
              </p>
              <div>
                <b-button class="cta-button"
                  ><b>73 people</b> like you joined</b-button
                >
              </div>
              <img class="evolve-pathway-image" src="/img/circle.svg" />
            </div>
          </div>
          <div class="col-md-4 col-sm-12">
            <div class="evolve-pathway bg-brand-secondary text-white">
              <h4 class="evolve-pathway-title">Improving Sleep</h4>
              <p class="evolve-pathway-text">
                Being busy isn't the ball, end-all. Join this challenge to
                discover the benefits of slowing down
              </p>
              <div>
                <b-button class="cta-button"
                  ><b>73 people</b> like you joined</b-button
                >
              </div>
              <img class="evolve-pathway-image" src="/img/circle.svg" />
            </div>
          </div>
          <div class="col-md-4 col-sm-12">
            <div class="evolve-pathway bg-brand-secondary text-white">
              <h4 class="evolve-pathway-title">Improving Sleep</h4>
              <p class="evolve-pathway-text">
                Being busy isn't the ball, end-all. Join this challenge to
                discover the benefits of slowing down
              </p>
              <div>
                <b-button class="cta-button"
                  ><b>73 people</b> like you joined</b-button
                >
              </div>
              <img class="evolve-pathway-image" src="/img/circle.svg" />
            </div>
          </div>
          <div class="col-md-4 col-sm-12">
            <div class="evolve-pathway bg-brand-secondary text-white">
              <h4 class="evolve-pathway-title">Improving Sleep</h4>
              <p class="evolve-pathway-text">
                Being busy isn't the ball, end-all. Join this challenge to
                discover the benefits of slowing down
              </p>
              <div>
                <b-button class="cta-button"
                  ><b>73 people</b> like you joined</b-button
                >
              </div>
              <img class="evolve-pathway-image" src="/img/circle.svg" />
            </div>
          </div>
          <div class="col-md-4 col-sm-12">
            <div class="evolve-pathway bg-brand-secondary text-white">
              <h4 class="evolve-pathway-title">Improving Sleep</h4>
              <p class="evolve-pathway-text">
                Being busy isn't the ball, end-all. Join this challenge to
                discover the benefits of slowing down
              </p>
              <div>
                <b-button class="cta-button"
                  ><b>73 people</b> like you joined</b-button
                >
              </div>
              <img class="evolve-pathway-image" src="/img/circle.svg" />
            </div>
          </div>
          <div class="col-md-4 col-sm-12">
            <div class="evolve-pathway bg-brand-secondary text-white">
              <h4 class="evolve-pathway-title">Improving Sleep</h4>
              <p class="evolve-pathway-text">
                Being busy isn't the ball, end-all. Join this challenge to
                discover the benefits of slowing down
              </p>
              <div>
                <b-button class="cta-button"
                  ><b>73 people</b> like you joined</b-button
                >
              </div>
              <img class="evolve-pathway-image" src="/img/circle.svg" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="EvolveSelfCare" class="bg-white py-5">
      <div
        class="
          d-flex
          flex-row
          align-items-center
          brand-text
          content-container
          pt-5
        "
      >
        <h1>Discover Self Care Courses</h1>
        <hr class="horizontal-divider brand-text" />
      </div>
      <div
        id="home-wellbeing"
        class="page-bg px-md-5"
        :class="{ 'bg-transparent': $store.getters.skin === 0 }"
      >
        <div class="content-container pt-5">
          <link
            rel="stylesheet"
            href="https://use.fontawesome.com/releases/v5.2.0/css/all.css"
            integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ"
            crossorigin="anonymous"
          />
          <slick ref="slick" :options="slickOptions" class="pt-5">
            <a
              v-for="course in courses"
              @click="selectCourse(course.course_id)"
            >
              <div class="slide-inner bg-brand-secondary">
                <div
                  v-if="course.course_recommend === 1"
                  class="recommended-course"
                ></div>
                <div class="course-image">
                  <img :src="course.course_image_url" />
                </div>
                <div class="slide-content">
                  <div
                    v-if="course.course_progress > 0"
                    class="course-progress"
                  >
                    <div class="progress-bar">
                      <div
                        class="progress"
                        :style="'width:' + course.course_progress + '%;'"
                      ></div>
                      <div
                        class="progress-info"
                        :style="'left:' + course.course_progress + '%;'"
                      >
                        {{ course.course_progress }}%
                      </div>
                    </div>
                  </div>
                  <div class="explore">
                    {{ componentText.exploreTitle }}
                    <h3 class="course-title">{{ course.course_title }}</h3>
                  </div>
                </div>
                <div
                  class="recommended-badge"
                  v-if="course.course_recommend === '1'"
                ></div>
              </div>
            </a>
          </slick>
          <a
            class="home-link link-white"
            :class="{ 'border-brand-secondary': $store.getters.skin === 8 }"
            @click="exploreAll"
            >{{ componentText.explore }}</a
          >
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import config from "../../config/constants";
import slick from "vue-slick";

export default {
  name: "CoachingHome",
  components: {
    slick,
  },
  mounted() {},
  data() {
    return {
      startColour: '#381B51',
      stopColour: '#9A5683',
      courses: {},

      slickOptions: {
        infinite: true,
        autoplay: false,
        dots: false,
        nextArrow:
          '<button class="slick-next  py-1" type="button" style=""><i class="fas fa-chevron-right"></i></button>',
        prevArrow:
          '<button class="slick-prev  py-1" type="button" style=""><i class="fas fa-chevron-left"></button>',
        speed: 300,
        initialSlide: 1,
        slidesToScroll: 0,
        slidesToShow: 3,
        centerMode: true,
        centerPadding: "0",
        variableWidth: false,
        adaptiveHeight: true,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 0,
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.getWellbeingCourses();
  },
  methods: {
    async getWellbeingCourses() {
      const payload = {
        user_id: this.$store.getters.user_id,
        limit: 10,
        channel: "surge",
      };
      let res = await axios.post(
        config.api_env + "/application/api-hc/list-surge-courses",
        payload,
        config.options
      );
      this.courses = res.data.courses;
      //destroy and recreate slick carousel
      if (this.$refs.slick) {
        this.$refs.slick.destroy();
      }
      this.$nextTick(function () {
        if (this.$refs.slick) {
          this.$refs.slick.create(this.slickOptions);
        }
      });
    },
    selectCourse(course_id) {
      this.$router.push("/wellbeing/" + course_id).catch((err) => {});
    },
    exploreAll() {
      this.$router.push("/wellbeing").catch((err) => {});
    },
  },
  computed: {
    componentText() {
      return this.getText.loggedIn.home.homeWellbeing;
    },
  },
};
</script>
<style lang="less">
#CoachingHome {
  .evolve-home-banner-container {
    background: rgb(56, 27, 81);
    background: linear-gradient(
      90deg,
      rgba(56, 27, 81, 1) 0%,
      rgba(155, 86, 131, 1) 100%
    );
    .evolve-home-banner {
      padding-top: 60px;
    }
    .evolve-home-banner-text {
      color: white;
      font-size: 1.6rem;
      z-index: 2;
      .evolve-home-banner-title {
        font-size: 2.8rem;
        font-weight: bold;
        padding-bottom: 25px;
      }
      p {
        color: white;
      }
    }
    div {
      z-index: 2;
    }
  }
  .evolve-reviews-container {
    padding-bottom: 30px;
    .evolve-review {
      padding: 20px;
      .evolve-review-content {
        font-size: 1.6rem;
      }
      .evolve-review-name {
        font-size: 1.6rem;
        font-weight: bold;
        text-transform: uppercase;
      }
      .evolve-review-location {
        font-size: 1.4rem;
      }
    }
  }
  .evolve-pathways-container {
    padding-bottom: 30px;
    .evolve-pathway {
      border-radius: 16px;
      margin-top: 20px;
      margin-bottom: 20px;
      padding: 15px;
      display: flex;
      flex-direction: column;
      position: relative;
      overflow: hidden;
      z-index: 2;
      h4 {
        font-size: 1.8rem;
        font-weight: bold;
        margin-bottom: 15px;
        z-index: 10;
      }
      p {
        max-width: 240px;
        color: white;
        font-size: 1.4rem;
        z-index: 10;
      }
      div {
        align-self: flex-end;
        z-index: 10;
      }
      img {
        position: absolute;
        right: 0px;
        top: 0px;
        z-index: 1;
        opacity: 0.7;
        max-width: 70%;
        height: auto;
        overflow: hidden;
      }
    }
  }
  .horizontal-divider {
    flex-grow: 1;
    margin-left: 20px;
    border: 2px solid;
  }
  .banner-overlay {
    position: absolute;
    width: 100%;
    bottom: 100%;
    z-index: 1;
    img {
      width: 100%;
    }
  }
  .pathway-overlay {
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 1;
    img {
      width: 100%;
    }
  }
  .pathway-blob {
    position: absolute;
    width: 100%;
    bottom: 0;
    right: 0;
    z-index: 1;
    div {
    }
  }
}
</style>
