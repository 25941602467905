<template>
    <div id="all-articles">
        <div class="page-header bg-transparent" >
            <div class="content-container row">
                <div class="page-title col-12">
                    <h2>{{$route.meta.title ? $route.meta.title : componentText.title}}</h2>
                </div>
                <div class="search-bar col-12">
                    <b-form-input
                            v-model="searchQuery"
                            type="text"
                            placeholder="Search">
                    </b-form-input>
                    <b-button-group class="filter-buttons row my-5">
                        <b-button @click="taggedArticles('body')" :class="{active: filter==='body'}" class="filter-button filter-button-white px-2 px-md-5 py-3 mx-2 mx-md-3 my-2">{{componentText.body}}</b-button>
                        <b-button @click="taggedArticles('life')" :class="{active: filter==='life'}" class="filter-button filter-button-white px-2 px-md-5 py-3 mx-2 mx-md-3 my-2">{{componentText.life}}</b-button>
                        <b-button @click="taggedArticles('mind')" :class="{active: filter==='mind'}" class="filter-button filter-button-white px-2 px-md-5 py-3 mx-2 mx-md-3 my-2">{{componentText.mind}}</b-button>
                        <b-button @click="filterArticles('video')" :class="{active: filter==='video'}" class="filter-button filter-button-white px-2 px-md-5 py-3 mx-2 mx-md-3 my-2">{{componentText.video}}</b-button>
                        <b-button @click="filterArticles('audio')" :class="{active: filter==='audio'}" class="filter-button filter-button-white px-2 px-md-5 py-3 mx-2 mx-md-3 my-2">{{componentText.podcast}}</b-button>
                        <b-button @click="filterArticles('text')" :class="{active: filter==='text'}" class="filter-button filter-button-white px-2 px-md-5 py-3 mx-2 mx-md-3 my-2">{{componentText.blog}}</b-button>
                        <b-button v-if="!$store.state.user.access_code_user && $store.state.subdomain !== 'laya-wellbeing'" @click="favouritedArticles()" :class="{active: filter==='favourited'}" class="filter-button filter-button-white px-2 px-md-5 py-3 mx-2 mx-md-3 my-2">{{componentText.favourited}}</b-button>
                        <b-button @click="allArticles" :class="{active: filter===''}" class="filter-button filter-button-white px-2 px-md-5 py-3 mx-2 mx-md-3 my-2">{{componentText.all}}</b-button>
                    </b-button-group>
                </div>
            </div>
        </div>
        <div class="articles bg-white pb-5">
            <div class="content-container row">
                <div class="section-header section-header-right">
                    <div class="section-header-divider"></div>
                    <h3 class="section-header-title">{{componentText.articles}}</h3>
                </div>
                <div class="col-12 page-filter ">
                    <b-button-group class="cta-button d-inline-flex mb-5 py-2 px-3" v-if="pages !== 0">
                        <b-button v-if="articlePayload.page" @click="previousPage" class="cta-button"><font-awesome-icon icon="chevron-left" ></font-awesome-icon></b-button>
                        {{articlePayload.page + 1}} of {{pages}}
                        <b-button v-if="articlePayload.page +1 < pages" @click="nextPage" class="cta-button"><font-awesome-icon icon="chevron-right" ></font-awesome-icon></b-button>
                    </b-button-group>
                </div>
                <div class="no-articles d-flex justify-content-center" v-if="articles.length === 0">
                    <h2 class="text-center">Sorry no articles available for this search</h2>
                </div>
                <div  v-for="(article, index) in articles" :key="index" class="col-12 col-sm-6 col-md-3 article-wrap" v-else>
                    <a class="card" @click="selectArticle(article.post_id)">
                        <div class="card-header bg-brand-primary">
                            <img class="card-logo" :src="'/img/' + img_type[article.post_type]" />
                        </div>
                        <div class="card-body" ref="cardBody">
                            <p class="card-title" ref="cardTitle">{{ article.post_type }}</p>
                            <div class="card-text text-eclipse"><h5>{{ article.title }}</h5></div>
                        </div>
                    </a>
                    <div v-if="!$store.state.user.access_code_user" :class="article.fav ? 'is-active ' : ''"
                         @click="likeArticle(article.post_id, index)"
                         class="card-heart">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import axios from "axios";
    import config from "../config/constants";
    import {debounce} from "../helpers/debounce"
    import WellbeingCarousel from "../components/Wellbeing/WellbeingCarousel.vue"
    export default {
        name: "AllArticles",
        components: {

        },
        data() {
            return {
                searchQuery: '',
                debouncedSearchQuery: '',
                filter: '',
                enrolled:0,
                reccommended: 0,
                articles:{},
                resources: {},
                img_type: {
                    video: "video-white.svg",
                    text: "blog-white.svg",
                    image: "far fa-image",
                    audio: "podcast-white.svg"
                },
                articlePayload:{
                    user_id: this.$store.getters.user_id,
                    search_key: '',
                    tag: 'all',
                    filter: '',
                    fav_only: '',
                    page: 0,
                    page_size: 12,
                    surge_only: 1
                },
                pages: ''
            };
        },
        mounted() {
            this.getArticles()
        },
        methods: {
            async getArticles(){
                let res = await axios.post(config.api_env + "/application/api-hc/get-all-resource-list",
                    this.articlePayload, config.options)
                if(res.data.success) {
                    this.resources = res.data.resources
                }else if(res.data.err && res.data.err.includes('3994')){
                    this.$store.dispatch('logout')
                    this.$router.push("/login").catch(err => {});
                }
                this.articles = this.resources;
                this.pages = Math.ceil(res.data.count / this.articlePayload.page_size)
            },
            async likeArticle(articleId, index){
                const payload = {
                    user_id: this.$store.getters.user_id,
                    type: 'module',
                    resource_id: articleId
                }
                if(!this.articles[index].fav){
                    this.articles[index].fav = 1
                    let res = await axios.post(config.api_env + "/application/api-hc/like-item", payload, config.options)
                    if(!res.data.success) {
                        this.articles[index].fav = 0
                    }
                }else{
                    this.articles[index].fav = 0
                    let res = await axios.post(config.api_env + "/application/api-hc/unlike-item", payload, config.options)
                    if(!res.data.success) {
                        this.articles[index].fav = 1
                    }
                }
            },
            selectArticle(articleId){
                this.$router.push('/wellbeing/article/'+ articleId).catch(err => {})
            },
            taggedArticles(tag){
                this.filter = tag
                this.articlePayload.tag = tag
                this.articlePayload.filter = 'none'
                this.articlePayload.fav_only = 0
                this.articlePayload.page = 0
                this.getArticles()
            },
            filterArticles(filter){
                this.filter = filter
                this.articlePayload.tag = 'all'
                this.articlePayload.filter = filter
                this.articlePayload.fav_only = 0
                this.articlePayload.page = 0
                this.getArticles()
            },
            favouritedArticles(){
                this.filter = 'favourited'
                this.articlePayload.tag = 'all'
                this.articlePayload.filter = 'none'
                this.articlePayload.fav_only = 1
                this.articlePayload.page = 0
                this.getArticles()
            },
            allArticles(){
                this.filter = ''
                this.articlePayload.page = 0
                this.articlePayload.tag = 'all'
                this.articlePayload.filter = 'none'
                this.articlePayload.fav_only = 0
                this.getArticles()
            },
            previousPage(){
                this.articlePayload.page = this.articlePayload.page -1
                this.getArticles()
            },
            nextPage(){
                this.articlePayload.page = this.articlePayload.page +1
                this.getArticles()
            },
            setSearchQuery(query){
                this.searchQuery = query;
            }
        },
        watch:{
            searchQuery: debounce(function (newQuery){
                this.debouncedSearchQuery = newQuery;
            }, 350),
            debouncedSearchQuery(newQuery){
                this.articlePayload.search_key = newQuery
                this.getArticles();
            },
        },
        computed:{
            skin(){
                return this.$store.getters.skin
            },
            componentText() {
                return this.getText.loggedIn.wellbeing.allArticles;
            },
        }
    };
</script>
<style lang="less">
    #all-articles{
        .page-header {
            padding: 60px 0 0 0 ;
        }
        .page-title {
            text-align: left;
            padding-bottom: 80px;
            h2 {
                text-align: left;
                font-size: 3.8rem;
                letter-spacing: 0.7rem;
                font-family: "FilsonProBold", sans-serif;
                color: #fff;
            }
        }
        .search-bar{
            input {
                border-radius: 25px;
                height: 50px;
                font-size: 2.4rem;
                font-family: "DMSans", sans-serif;
                padding: 0 30px 5px 30px;
            }
        }
        .filter-buttons{
            width: 100%;
            margin: auto;
        }
        .page-filter{
            text-align: right;
            .btn-group{
                border-radius: 25px;
                font-size: 2rem;
                font-family: DMSans
             }
        }
        .paths{
            .section-header-title{
                color: #fff;
            }
            .section-header-divider{
                opacity: 0.5;
            }
        }
        .no-articles{
            width: 100%;
            padding-bottom: 100px;
        }
        .articles{
            .article-wrap{
                position: relative;
                .card-heart{
                    background-image: url('/img/heart-line_white.svg');
                    position: absolute;
                    width: 30px;
                    height: 26px;
                    top: 15px;
                    right: 30px;
                    background-size: cover;
                    cursor: pointer;
                    &.is-active{
                        background-image: url('/img/heart_white.svg');
                    }
                }
            }
            .card{
                position: relative;
                box-sizing: border-box;
                margin-bottom: 36px;
                transition: .3s ease-out;
                border: none;
                box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
                font-family: 'FilsonProLight', sans-serif;
                border-radius: 10px;
                &:hover{
                    cursor: pointer;
                    text-decoration: none;
                    box-shadow:2px 2px 15px rgba(0, 0, 0, 0.6);
                }
                .card-header{
                    border-radius: 2px 2px 0 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 150px;
                    border-radius: 10px 10px 0 0;
                    .card-logo{
                        max-height: 60%;
                        max-width: 55%;
                    }
                }
                .card-body{
                    width: 100%;
                    height: 110px;
                    box-sizing: border-box;
                    padding: 10px 10px 20px;
                    background: #ffffff;
                    text-align: center;
                    border-radius: 0 0 10px 10px;
                    .card-title{
                        font-family: FilsonProLight, sans-serif;
                        margin: 0;
                        padding: 0 0 10px;
                        font-size: 1.6rem;
                        text-align: center;
                        text-transform: uppercase;
                        opacity: 0.5;
                    }
                    .card-text{
                        text-align: center;
                        white-space: normal;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        h5{
                            font-family: FilsonProLight, sans-serif;
                            font-size: 1.8rem;
                            line-height: 1;
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
</style>
