<template>
    <div id="webinarView">
        <div class="row content-container">
            <div v-if="loading"></div>
            <div class="webinar" v-else>
                <div class="return-wrap">
                    <b-button class="return p-0 pb-4" @click="$router.push({name: 'events', params:{ page: 'webinars', webinar: null}})" alt="return to all courses">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.13 35.13"><defs></defs><title>arrow-left</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><circle class="cls-1" cx="17.56" cy="17.56" r="17.56"/><rect class="cls-2" x="9.82" y="16.6" width="15.95" height="2.13"/><polygon class="cls-2" points="14.22 23.47 8.05 17.5 14.22 11.53 15.7 13.06 11.11 17.5 15.7 21.94 14.22 23.47"/></g></g></svg>
                    </b-button>
                </div>
                <h3>{{webinar.title}}</h3>
                <div class="video-wrap col-sm-12 col-md-8 offset-md-2">
                    <video
                            width="100%"
                            class="video"
                            controls
                            controlsList="nodownload"
                            :src="webinar.video_url"
                    ></video>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import config from "@/config/constants";

    export default {
        name: "WebinarView",
        mounted() {
            this.getWebinar()
            //TODO:: call /application/api/u/webinars/track-view on page exit
            setInterval(this.incrementSeconds, 1000);
            window.addEventListener('beforeunload', this.trackView)
        },
        data(){
            return{
                webinar:{},
                loading: true,
                seconds: 0,
            }
        },
        beforeDestroy(){
            this.trackView()
        },
        methods:{
            async getWebinar() {
                const payload = {
                    user_id: this.$store.getters.user_id,
                    webinar_id: this.$route.params.webinar
                }
                let res = await this.axios.post(process.env.VUE_APP_API_PATH + '/application/api/u/webinars/get-webinar', payload, config.options)
                if (res.data.success) {
                    this.webinar = res.data.webinar
                    this.loading = false
                }
            },
            incrementSeconds() {
                this.seconds ++;
            },
            async trackView(){
                const payload = {
                    user_id: this.$store.getters.user_id,
                    webinar_id: this.webinar.id,
                    seconds: this.seconds
                }
                let res = await this.axios.post(process.env.VUE_APP_API_PATH + '/application/api/u/webinars/track-view', payload, config.options)
                console.log(res.data)
                return
            }
        },
        computed:{

        }
    }
</script>

<style scoped lang="less">
 #webinarView{
     h3{
         font-size: 2.2rem;
         letter-spacing: 0;
         font-family: DMSans, sans-serif;
         text-align: center;
     }
     .return-wrap{
         position: absolute;
         top: -30px;
         left: 0;
     }
     .return{
         background-color: transparent;
         svg{
             width: 45px;
         }
     }
     .video {
         border-radius: 20px;
         &:focus {
             outline: none;
         }
     }
 }
</style>