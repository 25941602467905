<template>
  <div id="TherapyPortalPage" ref="portalPage">
    <TherapyLogin v-if="!loggedIn" @sign-in="signIn"/>
    <NavigationV2 v-else>
      <div class="main">
        <div>
          <ContentRouter @scroll-to="scrollTo"/>
          <Footer></Footer>
        </div>
      </div>
    </NavigationV2>
  </div>
</template>

<script>
import NavigationV2 from "@/components/NavigationV2.vue";
import Footer from "@/components/Footer.vue";
import ContentRouter from '@/components/ContentRouter.vue';
import TherapyLogin from "@/components/TherapyPortal/TherapyLogin.vue";
import TherapyLandingPage from "@/components/TherapyPortal/TherapyLandingPage.vue";

export default {
  name: "TherapyPortalPage",
  components: {TherapyLandingPage, TherapyLogin, ContentRouter, NavigationV2, Footer},
  created() {
    if(!this.isConnectedMentalHealth()) {
      this.$router.push("/login").catch((err) => {});
    }
    this.checkIfLoggedIn();
  },
  data() {
    return {
    }
  },
  computed: {
    loggedIn() {
      const user = this.$store.getters.user.user;
      const user_id = this.$store.getters.user_id;
      const therapy = this.$store.getters.user.therapy;
      return  Boolean((therapy.age_group === "Adult" || therapy.age_group === 'Child') &&
              (therapy.area === 'Therapy' || therapy.area === 'Neurodiversity') &&
              user_id && (typeof user_id !== 'function') && user.email)
    }
  },
  methods: {
    scrollTo(coordinates) {
      this.$refs.portalPage.parentNode.scrollTop = coordinates;
    },
    signIn(data) {
      const {email, code, memberNo} = data;
      //login logic
      this.loggedIn = true;
    },
    checkIfLoggedIn() {
      //check if logged in
      if(!this.loggedIn && (this.$route.name !== 'reset')) {
          this.$router.push("/start");
      }
    }
  },
  watch: {
    '$route.name': {
      handler: function (val) {
        if(val === 'home') {
          this.checkIfLoggedIn();
        }
      }
    },
  }
}
</script>

<style lang="less">
#TherapyPortalPage {
  #content {
    background-color: white;
  }
  .main {
    width: 100%;
    .content-container {
      max-width: 1160px;
      margin: auto;
    }
    .page-bg {
      width: 100%;
      padding-bottom: 30px;
    }
    .bg-white {
      background-color: #fff;
    }
    .bg-grey {
      background-color: #f7f7f7;
    }
    .page-title {
      h2 {
        font-size: 3.8rem;
        letter-spacing: 0.7rem;
        font-family: "FilsonProBold", sans-serif;
      }
    }
    h3 {
      font-size: 2.3rem;
      font-family: "FilsonProBold", sans-serif;
      letter-spacing: 0.3rem;
      padding: 40px 0 30px 0;
    }
    h4 {
      font-family: "DMSans", sans-serif;
      font-size: 1.7rem;
      margin-bottom: 30px;
    }
    .section-header {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      align-items: center;
      flex-direction: row-reverse;
      &.section-header-right {
        flex-direction: row;
        .section-header-title {
          padding-right: 0;
          padding-left: 15px;
          text-transform: capitalize;
        }
      }
      .section-header-title {
        white-space: nowrap;
        padding-right: 10px;
      }
      .section-header-divider {
        height: 1px;
        background: #cdcdcd;
        width: 100%;
        transform: translateY(5px);
      }
    }
  }
}
</style>