<template>
  <div id="TherapySchoolQuestionnaire" class="p-5">
    <div></div>
    <b-container class="position-relative" v-if="passcode_valid && !displayThanksOnCompletion">
      <div class="saveBtn" style="position: fixed; top: 20px; right: 30px" v-if="code_validated">
        <div class="saveErrorBox" v-if="saveError">
          There was a problem saving your form progress.  Please contact: <a href="mailto: neurodiversityassessmentbookings@spectrum.life">neurodiversityassessmentbookings@spectrum.life</a>
        </div>
        <button class="therapyBtn2" style="width: 200px;float: right; margin-right: 20px; position: relative" @click="save">
          <span v-if="saving" class="button-loading"></span>
          <span v-else>{{ complete ? "Submit":"Save" }}</span>
        </button>
        <div class="percentComplete">
          {{percentComplete}}% complete
        </div>
      </div>
      <div class="questionnaire-container">
        <h1 class="text-center">School Questionnaire</h1>
        <div v-for="q in form.questions">
          <TherapyTextQuestion :required="true" :label="q.question" :item="q.answer" @update="(data)=> q.answer = data" v-if="q.type === 'text'"/>
          <TherapyDateOfBirth :required="true" :label="q.question" :item="q.answer" @update="(data)=> q.answer = data" v-if="q.type === 'dob'"/>
          <TherapyPhoneNo :required="true" :label="q.question" :item="q.answer" @update="(data)=> q.answer = data" v-if="q.type === 'phone'"/>
          <TherapyChoiceBox :required="true" :label="q.question" :item="q.answer" :options="q.options" @update="(data)=> q.answer = data" v-if="q.type === 'choice'"/>
          <TherapyTextBox :required="true" :label="q.question" :item="q.answer" @update="(data)=> q.answer = data" :rows="10" v-if="q.type === 'longText'"/>
          <TherapySelectQuestion :required="true" :label="q.question" :item="q.answer" :options="q.options" @update="(data)=> q.answer = data" v-if="q.type === 'select'"/>
        </div>
        <p class="mx-auto" v-if="code_validated">
          Thank you very much for taking the time to share information with us about this young person. <br>

          If you have any questions about the questionnaire, please get in touch: <br>

          Dr Fleur-Michelle Coiffait, Consultant Clinical Psychologist & Clinical Lead for Neurodiversity <br>

          <a href="mailto: fleurcoiffait@spectrum.life">fleurcoiffait@spectrum.life</a>
        </p>
        <div class="errorBox" v-if="error_message">
          <div>There was a problem accessing this questionnaire:</div>
          <div>{{error_message}}</div>
          <div>If you have any questions or require support, please contact <a href="mailto: neurodiversityassessmentbookings@spectrum.life">neurodiversityassessmentbookings@spectrum.life</a></div>
        </div>
      </div>
    </b-container>
    <b-container v-else-if="passcode_valid">
      <div v-if="complete" class="my-5 mx-auto text-center" style="font-family: FilsonProBold, Arial, Helvetica, sans-serif; font-size: 2rem">
        Thank you for submitting the questionnaire!
      </div>
      <div v-else class="my-5 mx-auto text-center" style="font-family: FilsonProBold, Arial, Helvetica, sans-serif; font-size: 2rem">
        Your answers have been saved.  You can return at any time to complete the remaining questions.
      </div>
    </b-container>
    <b-container v-else>
      <div class="passCodeBox mx-auto mt-5 px-3 py-5 d-flex flex-column align-items-center">
        <h3>School Questionnaire</h3>
        <div class="mt-3" style="font-size: 2rem">Please enter your passcode</div>
        <b-form-input v-model="passcode" class="mt-5"></b-form-input>
        <div v-if="error_message" class="mt-2 text-center" style="font-size: 1.5rem; color: darkred">{{ error_message }}</div>
        <button class="therapyBtn2 my-5" style="width: 200px; margin-right: 20px" @click="checkIfCodeIsValid">
          Send
        </button>
      </div>
    </b-container>
  </div>
</template>

<script>

import forms from '../../assets/therapyForms.json'
import TherapyDateOfBirth from "./Questions/TherapyDateOfBirth.vue";
import TherapyChoiceBox from "./Questions/TherapyChoiceBox.vue";
import TherapyPhoneNo from "./Questions/TherapyPhoneNo.vue";
import TherapyTextBox from "./Questions/TherapyTextBox.vue";
import TherapyTextQuestion from "./Questions/TherapyTextQuestion.vue";
import TherapySelectQuestion from "./Questions/TherapySelectQuestion.vue";

export default {
  name: "TherapySchoolQuestionnaire",
  components: {TherapyTextBox, TherapyPhoneNo, TherapyChoiceBox, TherapyDateOfBirth, TherapyTextQuestion, TherapySelectQuestion},
  data() {
    return {
      passcode_valid: false,
      passcode: '',
      form: {},
      forms: forms,
      code_validated: false,
      error_message: null,
      saving: false,
      saveError: false,
      displayThanksOnCompletion: false
    }
  },
  mounted() {

  },
  methods: {
    async save() {
      this.saving = true;
      this.saveError = false;
      let questions = {};
      this.form.questions.map(q => {
        questions[q.id] = q.answer;
      })
      const jsonString = JSON.stringify(questions);
      const content = btoa(unescape(encodeURIComponent(jsonString)));
      const res = await this.api({
        path: 'api/u/connected-mental-health/save-school-questionnaire',
        code: this.$route.params.id,
        content: content,
        complete: this.complete,
        passcode: this.passcode
      })
      if(!res.success) {
        this.saveError = true;
      } else {
        this.displayThanksOnCompletion = true;
      }
      this.saving = false;
    },
    async checkIfCodeIsValid() {
      const res = await this.api({
        path: 'api/u/connected-mental-health/check-questionnaire-code',
        code: this.$route.params.id,
        passcode: this.passcode
      });
      this.code_validated = res.success;
      if(res.success) {
        this.passcode_valid = true;
        this.form = this.forms['school_questionnaire'];
        if(res.content) {
          const data = JSON.parse(atob(res.content));
          this.form.questions.forEach(question => {
            if(data.hasOwnProperty(question.id)) question.answer = data[question.id]
          })
        }
      } else {
        this.error_message = res.err
      }
    }
  },
  computed: {
    percentComplete() {
      if(Object.keys(this.form).length === 0) return 0;
      const q_count = this.form.questions.length;
      const answers = this.form.questions.filter(q => q.answer !== null && q.answer !== '');
      const a_count = answers.length;
      return Number(a_count / q_count * 100).toFixed(0);
    },
    complete() {
      if(Object.keys(this.form).length === 0) return 0;
      let complete = true;
      this.form.questions.forEach(question => {
        if(question.answer === null || question.answer === '') complete = false
      });
      return complete;
    }
  }
}
</script>

<style lang="less">
#TherapySchoolQuestionnaire {
  width: 100%;
  height: fit-content;
  min-height: 100vh;
  background-color: #F8F8F8;
  .percentComplete {
    text-align: center;
    font-family: FilsonProBold, Arial, Helvetica, sans-serif;
    font-size: 1.4rem;
    margin-bottom: 15px;
  }
  .saveErrorBox {
    width: 400px;
    font-size: 1.5rem;
    color: darkred;
    background-color: white;
    padding: 10px 20px;
    border-radius: 15px;
    margin-bottom: 10px;
  }
  .passCodeBox {
    max-width: 600px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    border-radius: 20px;
    h3 {
      font-size: 2.2rem;
      font-family: FilsonProBold, Arial, Helvetica, sans-serif;
    }
    input {
      all: unset;
      width: 100%;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      text-align: center;
      padding: 5px 5px 5px 15px !important;
      height: 55px !important;
      border-radius: 30px !important;
      margin: auto;
      font-family: 'DMSans', sans-serif;
      font-size: 1.75rem;
      border: 1px solid #D0D5DA;
      background-color: #EEEEF3 !important;
    }
  }
  .button-loading::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 4px solid transparent;
    border-radius: 50%;
    border-top-color: white;
    border-bottom-color: white;
    animation: button-loading-spinner 1s ease infinite;
  }
  .therapyBtn2 {
    all: unset;
    font-size: 1.4rem;
    color: white;
    text-align: center;
    width: 100%;
    max-width: 300px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 70px;
    box-shadow: 0 4px 4px 0 #00000040;
    background: #D60570;
    cursor: pointer;
    &:disabled {
      background: #D9D9D9 !important;
    }
    &:hover {
      background: darken(#D60570, 5%);
    }
    &:active:not(:disabled) {
      transform: translateY(2px);
    }
  }
  .incomplete {
    border-color: darkred !important;
  }
  p {
    width: 100%;
    max-width: 800px;
    font-family: FilsonProBold, Arial, Helvetica, sans-serif;
    font-size: 1.6rem;
    margin-top: 50px;
    margin-bottom: 100px;
  }
  .questionnaire-container {
    width: 100%;
    max-width: 800px;
    margin: auto;
    label {
      font-family: FilsonProBold, Arial, Helvetica, sans-serif;
      font-size: 1.3rem;
    }
    input, textarea {
      font-size: 1.3rem;
    }
    .errorBox {
      margin-top: 50px;
      font-size: 1.75rem;
    }
  }
}
</style>