<template>
  <div id="TherapyPayments">
<!--    <div class="p-5 d-flex flex-column align-items-center" v-if="!confirmed">-->
<!--      <img :alt="appointment.sp.name" :src="appointment.sp.image_url" class="spPhoto"/>-->
<!--      <p>{{appointment.timeslot.start.substring(11,16)}}-->
<!--        <br>-->
<!--        {{formattedDate}}-->
<!--      </p>-->
<!--      <p class="description">-->
<!--        Video session with-->
<!--        <br>-->
<!--        <span>{{appointment.sp.name}}</span>-->
<!--      </p>-->
<!--      <hr>-->
<!--      <div class="total text-center mt-5 position-relative" >-->
<!--        <div class="position-absolute" style="top: -20px; left: 0; font-size: 1.3rem">Total</div>-->
<!--        <span>{{currency === 'euros' ? '€' : '£'}}{{price}}</span>-->
<!--      </div>-->
<!--      <button @click="confirmed = true" class="therapyBtn2 mx-auto mt-5">Continue</button>-->
<!--    </div>-->
    <div v-if="!payment_successful">
      <DigitalClinicsBookingPayment :timeslot_id="appointment.timeslot.id" :is_cmh="true"/>
<!--      <TherapyDigitalClinicsBookingPayment :timeslot_id="appointment.timeslot.id"/>-->
    </div>
    <div v-else class="confirmationBox">
      <div class="successIconBox">
        <i class="fa-sharp fa-solid fa-circle-check"></i>
      </div>
      <h4 style="text-align: center">Booking Complete</h4>
      <p>Thank you for your booking.  Your session with <span>{{confirmation.name}}</span> will begin at <span>{{confirmationFormatedDate}}</span>
      </p>
      <p>
        You should shortly receive an <span>email reminder</span> for this in your email inbox. The upcoming session will also be visible on the Homepage. If you have any questions around your session please feel free to reach out to <span>
        <a :href="'mailto:'+contactEmailAddress">{{ contactEmailAddress }}</a>
      </span>
      </p>
      <button class="therapyBtn2" @click="closeScreen">Close</button>
    </div>
  </div>
</template>

<script>

import moment from "moment";
import TherapyDigitalClinicsBookingPayment from "./TherapyDigitalClinicsBookingPayment.vue";
import EventBus from "@/helpers/eventbus";
import DigitalClinicsBookingPayment from "../DigitalClinics/DigitalClinicsBookingPayment.vue";

export default {
  name: "TherapyPayments",
  components: {DigitalClinicsBookingPayment, TherapyDigitalClinicsBookingPayment},
  props: ['appointment','contactEmailAddress'],
  data() {
    return {
      confirmed: true,
      payment_successful: false,
      confirmation: {
        name: '',
        time: ''
      }
    }
  },
  mounted() {
    EventBus.$on('cmhPaymentSuccess', (data)=> {
      if(data.paymentIntent.status === 'succeeded') {
        this.getConfirmation(data.paymentIntent.id);
      }
    })
  },
  methods: {
    closeScreen() {
      EventBus.$emit('updateChmBookings');
      this.$router.push('/start')
    },
    async getConfirmation(id) {
      const res = await this.api({
        payment_id: id,
        path: "api/u/digital-bookings/get-cmh-booking-confirmation"
      })
      if(res.success) {
        this.payment_successful = true;
        this.m('booking-successful', {
          service_provider_name: this.appointment.sp.name,
          timeslot_id: this.appointment.timeslot.id,
          timeslot_start: this.appointment.timeslot.start
        })
        this.confirmation = {
          name: res.name,
          time: res.time
        }
      } else {
        this.m('booking-unsuccessful', {
          service_provider_name: this.appointment.sp.name,
          timeslot_id: this.appointment.timeslot.id,
          timeslot_start: this.appointment.timeslot.start
        })
      }
    }
  },
  computed: {
    price() {
      return this.connectedMentalHealthType() === 'neurodiversity' ? 1950.00 : 85.00;
    },
    confirmationFormatedDate() {
      const date = moment(this.confirmation.time);
      return date.format("h:mma on Do MMMM YYYY");
    },
    formattedDate() {
      const date = moment(this.appointment.timeslot.start);
      return date.format("dddd Do MMMM YYYY");
    },
    currency() {
      if(!this.$store.getters.user.user.country) return 'euros';
      return this.$store.getters.user.user.country.includes('UK') ? 'sterling' : 'euros';
    }
  }
}
</script>

<style  lang="less">
#TherapyPayments{
  .confirmationBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      font-family: FilsonProBold, Arial, Helvetica, sans-serif;
    }
  }
  .successIconBox {
    display: flex;
    justify-content: center;
    i {
      font-size: 60px;
      color: #D70670;
    }
  }
  .therapyBtn2 {
    width: 90%;
    max-width: 500px;
  }
  p {
    font-size: 1.65rem !important;
    text-align: center;
  }
  .total {
    font-size: 1.4rem;
    span {
      font-size: 3rem;
      color: #D60570;
      font-family: FilsonProBold, Arial, Helvetica, sans-serif;
    }
  }
  .description {
    font-size: 1.8rem !important;
    span {
      font-size: 2rem !important;
      font-family: FilsonProBold, Arial, Helvetica, sans-serif;
      color: #D60570;
    }
  }
  hr {
    width: 90%;
    max-width: 450px;
  }
  .spPhoto {
    width: 150px;
    border-radius: 50%;
  }
  #digital_booking_payment{
    max-width: 500px;
    margin: auto;
    .btn-cta-outline {
      display: none;
    }
    .text, .price {
      text-align: center;
    }
    .payment_box {
      display: flex;
      flex-direction: column;
      .cardBtn {
        max-width: 100% !important;
        flex: 0 0 100%;
        width: 100% !important;
        button {
          max-width: 200px;
          margin: auto;
        }
      }
    }
  }
}
</style>