<template>
    <div class="ProgressCircle">
        <svg
                class="circle"
                :width="size"
                :height="size"
                :viewBox="viewBox">
            <defs>
                <filter id="dropshadowAction4" x="-100%" y="-100%" width="300%" height="300%">
                    <feGaussianBlur in="SourceAlpha" stdDeviation="5"/>
                    <feOffset dx="0" dy="0" result="offsetblur"/>
                    <feFlood flood-color="#87D2DE"/>
                    <feComposite in2="offsetblur" operator="in"/>
                    <feMerge>
                        <feMergeNode/>
                        <feMergeNode in="SourceGraphic"/>
                    </feMerge>
                </filter>
            </defs>
            <circle class="circle__background"
                    :cx="center"
                    :cy="center"
                    :r="radius"
                    stroke-dasharray="0.6"
            />
            <circle
                    class="circle-highlight"
                    :stroke-dasharray="circumference"
                    :stroke-width="strokeWidth"
                    :cx="center"
                    :cy="center"
                    :r="radius"
                    :style="stylesSvg"
            />
        </svg>
    </div>
</template>
<script>
    export default {
        name: 'ProgressCircle',
        props:{
            percentage: 0
        },
        data(){
            return{
                size: 150,
                strokeWidth: 8,
                viewBox: '0 0 150 150'
            }
        },
        computed:{
            center(){
                return this.size / 2
            },
            radius(){
                return this.size / 2 - this.strokeWidth *2
            },
            circumference(){
                return 2* Math.PI * this.radius
            },
            offset(){
              return (100 - this.percentage) /100 * this.circumference
            },
            stylesSvg(){
                return {
                    strokeDashoffset: this.offset,
                    filter: `url(${'#dropshadowAction4'})`
                }
            }
        }

    }
</script>