<script>
import { Line } from "vue-chartjs";

export default {
	extends: Line,
	props: {
		graph : {
			data : [],
			labels : []
		}
	},
	watch: {
		graph : {
			deep : true,
			handler (graph) {
				let d = {
					labels: graph.labels,
					datasets: [
						{
							data: graph.data,
						},
					],
				}
				this.renderChart(d, options)
			}
		}
	},
	mounted() {
		let data = {
			labels: this.graph.labels,
			datasets: [
				{
					data: this.graph.data,
				},
			],
		}
		this.renderChart(data, options);
	}
};

const options= {
	maintainAspectRatio: false,
	responsive: true,
	layout: {
		// padding: 15,
	},
	legend: {
		display: false,
	},
	elements : {
		point : {
			// https://www.chartjs.org/docs/latest/configuration/elements.html#point-configuration
			radius : 4,
			pointStyle : 'circle',
			backgroundColor: "#ffffff",
			borderColor: "#6b8d9d",
			
		},
		line : {
			// https://www.chartjs.org/docs/latest/configuration/elements.html#line-configuration
			tension : 0,
			backgroundColor: "#ffffff",
			borderColor: "#6b8d9d",
			fill : false,
		}
	},
	transitions: {
		show: {
			animations: {
				x: {
					from: 'transparent'
				},
				y: {
					from: 'red'
				}
			}
		},
		hide: {
			animations: {
				x: {
					to: 0
				},
				y: {
					to: 0
				}
			}
		}
	},
	scales: {
		xAxes: [
			{
				gridLines: {
					drawBorder: null,
					display: null,
				},
				ticks: {
					// drawOnChartArea: null,
					// beginAtZero: null,
					// display: null,
					fontColor: "rgba(255,255,255,0.75)",
					padding: 10
				},
			},
		],
		yAxes: [
			{
				gridLines: {
					drawBorder: null,
					display: null,
				},
				ticks: {
					beginAtZero: true,
					// display: null,
					suggestedMax: 25,
					fontColor: "rgba(255,255,255,0.4)",
					padding: 10
				},
			},
		],
	},
}

</script>
<style lang="less">

</style>