<template>
  <div id="SmartTaskForm" class="">
    <div class="row d-flex justify-content-end no-gutters">
      <b-button
        class="cta-button mt-3 d-flex align-items-center py-3"
        @click="visible = !visible"
        ><i class="fas fa-plus-circle fa-2x mr-2"></i
        ><span>Set a Smart Goal</span></b-button
      >
    </div>
    <b-collapse v-model="visible">
      <div class="smart-goal-container">
        <div class="d-flex flex-row justify-content-between">
          <div class="d-flex flex-column">
            <div class="mb-1 ml-2">Title</div>
            <input
              class="smart-goal-date-input"
              type="text"
              v-model="smartTaskTitle"
            />
          </div>
          <div class="d-flex flex-column">
            <div class="mb-1 ml-2">Target Date</div>
            <input
              class="smart-goal-date-input"
              type="date"
              v-model="smartTaskDate"
            />
          </div>
        </div>
        <div class="d-flex flex-row mt-2">
          <textarea
            class="smart-goal-text-input w-100"
            maxlength="150"
            id="smart-goal-text"
            v-model="smartTaskContent"
          />
        </div>
        <div class="d-flex flex-row justify-content-end">
          <b>150 character limit</b>
        </div>
      </div>
      <div class="d-flex flex-row justify-content-end mt-3">
        <b-button class="cta-button" @click="createSmartTask()"
          >Add Smart Goal</b-button
        >
      </div>
    </b-collapse>
  </div>
</template>

<script>
import EventBus from "../../helpers/eventbus";
import moment from "moment";
import config from "@/config/constants";

export default {
  name: "SmartTask",
  props: ["goalId", "milestoneId"],
  data() {
    return {
      smartTaskTitle: "",
      smartTaskDate: "",
      smartTaskContent: "",
      visible: false,
    };
  },
  mounted() {
    this.smartTaskDate = moment().format("YYYY-MM-DD");
  },
  methods: {
    async createSmartTask() {
      const payload = {
        user_id: this.$store.getters.user_id,
        milestone_id: this.milestoneId,
        goal_id: this.goalId,
        title: this.smartTaskTitle,
        content: btoa(this.smartTaskContent),
      };
      let res = await this.axios.post(
        process.env.VUE_APP_API_PATH +
          "/application/api/u/coaching/create-task",
        payload, config.options
      );
      if (res.data.success) {
        EventBus.$emit("getCoachingData");
        Object.assign(this.$data, this.$options.data.apply(this));
        this.smartTaskDate = moment().format("YYYY-MM-DD");
      } else {
        console.log("failed to create smart task");
      }
    },
  },
};
</script>

<style lang="less">
#SmartTaskForm {
  .smart-goal-container {
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    margin-top: 10px;
    .smart-goal-date-input {
      outline: none !important;
      border: 2px #f3f3f3 solid !important;
      border-radius: 10px;
      padding: 5px;
    }
    .smart-goal-text-input {
      outline: none !important;
      border: 2px #f3f3f3 solid !important;
      border-radius: 10px;
      padding: 5px;
    }
  }
}
</style>
