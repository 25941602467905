<template>
	<div id="celebration_modal">
		<h3>New Badge Earned</h3>
		<p>Congratulations on this achievement. This badge will be added to the "Your Achievements" area below.</p>
		<div id="celeb_badge_container" :style="{backgroundColor: achievement.colour}">
			<img class="" :src="'/img/totalMentalHealth/achievements/Badges/'+achievement.icon+'.png'" /> <br/>
			<h4>{{achievement.title}}</h4>
		</div>
		<div id="celeb_reward_img_container">
			<img class="" :src="'/img/totalMentalHealth/achievements/'+getRewardIcon()" />
		</div>
		<div id="celeb_reward_text_container">
			<h4>{{getRewardText()}}</h4>
			<p>{{getCoordinatesText()}}</p>
		</div>
	</div>
</template>
<script>
export default {
	name: "AchievementCelebration",
	data() {
		return {

		};
	},
	mounted() {
		console.log(this.achievement)
	},
	methods: {
		getRewardIcon(){
			return this.achievement.reward_type === 'trees' ? 'Tree.png' : 'Bottle.png'
		},
    getCoordinatesText() {
      if(this.achievement.reward_amount === 0) return "";
      if(this.achievement.reward_type === 'trees') {
        return "Tudor Creek Reforestation site: GPS Lat (3 d.p.) -3.999 GPS Lng (3 d.p.) 39.649"
      } else if(this.achievement.reward_type === 'bottles') {
        return "Environmental Conservation Community of Tanzania (ECCT) GPS Lat (3 d.p.) -6.717 GPS Lng (3 d.p.) 39.222"
      }
    },
		getRewardText(){
      if(this.achievement.reward_amount === 0) return "";
			let txt = this.achievement.reward_amount+' '
			if(this.achievement.reward_type === 'trees'){
				txt+= ' tree'+(this.achievement.reward_amount === 1 ? "" : "s")+' planted because of you'
			} else if(this.achievement.reward_type === 'bottles') {
				txt+= ' bottle'+(this.achievement.reward_amount === 1 ? "" : "s")+' recovered from the ocean thanks to you'
			}
			return txt
		}
	},
	props : ['achievement'],
};
</script>
<style lang="less">
#achievement_celebration_modal {
	.modal-content {
		border: none;
		border-radius: 15px;
		overflow: hidden;
		padding-top: 30px;
		#celebration_modal {
			text-align: center;
			#celeb_badge_container {
				margin: 30px -10px 0px;
				padding: 10px;
				background-color: #00e6b8;
				img {
					margin-top: -30px;
					max-width: 25%;
					border: 3px solid #3a4961;
					border-radius: 50%;
				}
				h4 {
					margin-top: 5px;
					color : white;
					font-weight: bold;
				}
			}
			#celeb_reward_img_container {
				padding: 10px 0px 7px;
				margin: 0px -10px;
				background: url('/img/totalMentalHealth/achievements/Map.png') no-repeat left center;
				background-size: cover;
				img {
					max-width: 25%;
				}
			}
			#celeb_reward_text_container {
				padding: 15px;
				h4 {
					font-weight: normal;
				}
			}
		}
		footer.modal-footer {
			display: block;
			border: none;
			background-color: #E83B75;
			text-align: center !important;
			button.btn {
				background-color: transparent !important;
				border: none !important;
				margin: 0px !important;
				padding: 8px;
				height:100%;
				width:100%;
			}
		}
	}
}
</style>