<template>
    <div style="background-color: white" id="EapCallBackModal">
        <div class="header">
            <i class="fa-solid fa-xmark fa-2xl close-btn" @click="close"></i>
        </div>
        <div class="title brand-text">{{title}}</div>
        <div class="select-section row mt-4">
            <div class="label col-4 brand-text">Select a Service</div>
            <b-form-select v-model="selectedService" class="selector col-8">
                <b-form-select-option disabled value="null">Select an option...</b-form-select-option>
                <b-form-select-option v-for="item in filteredServices" :value="item.id">{{item.name}}</b-form-select-option>
            </b-form-select>
        </div>
        <div class="row phone-number-section">
            <div class="label col-4 brand-text">Enter Phone Number</div>
            <b-form-input class="form-control col-8 phoneInput" v-model="phoneNumber" type="number"></b-form-input>
        </div>
        <div class="row name-section" v-if="$store.getters.user.access_code_user">
            <div class="label col-4 brand-text">Enter Name</div>
            <b-form-input class="form-control col-8 nameInput" v-model="accessCodeName" type="text"></b-form-input>
        </div>
        <div class="row name-section" v-if="$store.getters.user.access_code_user">
            <div class="label col-4 brand-text nameInput">Enter Email</div>
            <b-form-input class="form-control col-8 nameInput" v-model="accessCodeEmail" type="text"></b-form-input>
        </div>
        <div class="row name-section">
            <div class="service-text" v-if="!isNaN(selectedService)">
                {{serviceText}}
            </div>
        </div>
        <div class="d-flex justify-content-center mt-4">
            <b-button @click="requestCall" class="cta-button px-5 py-3" :disabled="!formCompleted">
                {{ componentText.callback }}</b-button
            >
        </div>
        <div class="callback-confirmation" v-if="callBackSuccess">
            {{ $store.getters.subdomain === 'landg'? componentText.landg_thanks : componentText.thanks }}
        </div>
    </div>
</template>

<script >
import axios from "axios";
import config from "@/config/constants";

export default {
    name: 'EapCallBackModal',
    props: ['title','services_offered'],
    data() {
        return {
            accessCodeEmail: null,
            accessCodeName: null,
            displayMsg: false,
            msg: null,
            success: false,
            selectedService: null,
            phoneNumber: null,
            callBackSuccess: false,
            pageData: {
                defaults:{
                    eap_title: 'EAP',
                    live_chat: true
                }
            },
        }
    },
    computed: {
        filteredServices(){
            let services = []
            for(let i = 0; i<this.services_offered.length; i++){
                if(this.services_offered[i].active){
                    console.log(this.services[i])
                    services.push(this.services[i])
                }
            }
            return services
        },
        formCompleted() {
          if(!this.phoneNumber || this.selectedService === null) return false;
          if(this.$store.getters.user.access_code_user && (!this.accessCodeEmail || !this.accessCodeName)) return false;
          return true;
        },
        dfdText() {
            return this.getText.dfd;
        },
        componentText() {
            return this.getText.loggedIn.eap;
        },
        services() {
            return [
                {
                    id: 0,
                    name: this.componentText.mentalWellbeing,
                    content:this.componentText.mentalWellbeingContent
                },
                {
                    id: 1,
                    name: this.componentText.finance,
                    content: this.componentText.financeContent
                },
                {
                    id: 2,
                    name: this.componentText.life,
                    content: this.componentText.lifeContent
                },
                {
                    id: 3,
                    name: this.componentText.career,
                    content:
                        this.componentText.careerContent1 +
                        this.pageData.defaults.eap_title +
                        this.componentText.careerContent2
                },
                {
                    id: 4,
                    name: this.componentText.consumer,
                    content:
                        this.componentText.consumerContent1 +
                        this.pageData.defaults.eap_title +
                        this.componentText.consumerContent2
                },
                {
                    id: 5,
                    name: this.componentText.mediation,
                    content: this.componentText.mediationContent
                },
                {
                    id: 6,
                    name: this.componentText.nonNationals,
                    content: this.componentText.nonNationalsContent
                },
                {
                    id: 7,
                    name: this.componentText.legal,
                    content:
                        this.componentText.legalContent1 +
                        this.pageData.defaults.eap_title +
                        this.componentText.legalContent2
                },
                {
                    id: 8,
                    name: this.componentText.medical,
                    content:
                    this.componentText.medicalContent
                }
            ];
        },
        serviceText() {
            const i = this.filteredServices.findIndex(item => item.id === this.selectedService);
            if(i === -1) return null;
            return this.services[i].content;
        }
    },
    methods: {
        close() {
            this.$bvModal.hide('eap-callback')
        },
        async requestCall(){
            const i = this.filteredServices.findIndex(item => item.id === this.selectedService);
            let payload = {
                user_id: this.$store.getters.user_id,
                phone: String(this.phoneNumber),
                service_id: this.serviceComponent[i].id
            }
            if(this.$store.getters.user.access_code_user) {
                payload.name = this.accessCodeName;
                payload.email = this.accessCodeEmail;
            }
            let res = await axios.post(config.api_env + '/application/api-hc/send-eap-request', payload, config.options)
            if(res.data.sent){
                this.callBackSuccess = true
            }
        },
        async copyURL() {
            try {
                await navigator.clipboard.writeText(this.link);
                this.msg = "Copied";
                this.displayMsg = true;
                this.success = true;
                setTimeout(()=> {
                    this.displayMsg = false;
                    this.success = false
                },3000)
            } catch($e) {
                this.msg = "Failed";
                this.displayMsg = true;
                setTimeout(()=> {
                    this.displayMsg = false;
                },3000)
            }
        }
    }
}
</script>

<style lang="less">
#EapCallBackModal {
  .modal-content {
    border-radius: 0;
    width: 500px;
    margin-top: 50px;
  }
  .modal-dialogue, .modal-dialog-centered {
    display: flex !important;
    align-items: flex-start !important;
  }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
}
#EapCallBackModal {
  width: 100%;
  text-align: center;
  font-family: "DMSans", sans-serif;
  font-size: 1.5rem;
  .service-desc {
    min-height: 100px;
  }
  .select-section, .phone-number-section, .service-desc, .name-section, .callback-confirmation {
      padding: 10px 30px;

  }
  .selector, .phoneInput, .nameInput {
      font-size: 1.5rem;
  }
  .close-btn {
    cursor: pointer;
  }
  .title {
    margin-top: 20px;
    font-family: FilsonProBold, Arial, Helvetica, sans-serif;
    font-size: 2rem;
  }
  .header {
    padding: 15px;
    display: flex;
    justify-content: end;
  }

}
</style>