<template>
  <div id="whats-happening">
    <div class="page-header" :class="{'bg-transparent': skin === 0, 'bg-brand-secondary': skin !==0}" >
      <div class="content-container row">
        <div class="page-title col-12">
          <h2>{{$route.meta.title ? $route.meta.title : componentText.title}}</h2>
        </div>
        <div class="header-background d-none d-md-block" v-if="hardcoded.illustration_myorg">
          <img :src="hardcoded.illustration_myorg">
        </div>
      </div>
    </div>
    <div class="bg-white px-md-5">
      <div class="content-container row py-5" v-if="orgPages.length > 0">
        <div class="col-12 col-lg-5 my-5 pr-0 pr-lg-5" v-if="screenWidth > 992 || !pageSelected">
          <div class="page-results-wrap">
            <div
                    v-for="(page, i) in orgPages"
                    :key="i"
                    :class="{ active: i == activeItem }"
                    class="page-event brand-secondary border-brand-primary row mb-3"
                    @click="viewPage( i)"
            >
              <div class="icon px-4">
                <img :src="getIcon(page.category_id)">
              </div>
              <span class="page-title pl-5">
                {{ page.title }}
              </span>
            </div>
          </div>
        </div>
        <div class="col-12 d-block d-lg-none" v-if="pageSelected">
          <a @click="viewMenu"
             class="return-link brand-secondary"
          ><font-awesome-icon icon="chevron-left"></font-awesome-icon>{{componentText.back}}</a>
        </div>
        <div class="col-12 col-lg-7 my-5" v-if="screenWidth > 992 || pageSelected">
          <div class="page-head bg-brand-primary"></div>
          <div class="page-content" v-if="orgPages[activeItem]">
            <div class="page-title mx-5">
              <div class="title-box">
                <h3>{{orgPages[activeItem].title}}</h3>
              </div>
            </div>
            <div class="page-description">
              <h4>{{orgPages[activeItem].content.content_description}}</h4>
            </div>
            <div class="page-text" v-html="orgPages[activeItem].content.content"></div>
          </div>
        </div>
      </div>
      <div class="content-container no-content row my-5" v-else>
        <div class="col-12">
          <img class="pb-5" src="/img/my-company/placeholder.svg" />
          <h4>{{componentText.noPages}}</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import config from "../config/constants";
import { mapGetters} from "vuex";
export default {
  name: "whats_happening",
  components: {},
  data() {
    return {
        orgPages: [],
        icons:[
            { id: 9, path:'icon_general-grey.svg'},
            { id: 1, path:'icon_fitness-grey.svg'},
            { id: 2, path:'icon_nutrition-grey.svg'},
            { id: 8, path:'icon_wellbeing-grey.svg'},
        ],
        activeItem: 0,
        pageSelected: false,
        screenWidth: 0,
    };
  },
    created() {
        window.addEventListener('resize', this.handleResize)
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    },
  mounted() {
      this.getOrgPages()
      console.log(this.orgPages)
  },
  methods: {
      async getOrgPages() {
          const payload = {
              user_id: this.$store.getters.user_id,
              draft: 0
          };
          let res = await axios.post(
              config.api_env + "/application/api-hc/get-company-pages",
              payload,
              config.options
          );
          if (res.data.success) {
              this.orgPages = res.data.pages;
              if (this.$route.query.page) {
                  this.viewPage(this.$route.query.page);
              }
          }
      },
      getIcon(icon){
          var matched = null;
          var output = '';

          this.icons.map(function (i) {
              if (i.id === icon ) {
                  output =  i.path;
                  matched = true;
              }
          });

          if (!matched){
              output = this.icons[0].path;
          }
          return '/img/my-company/' + output;
      },
      viewPage(i){
          this.activeItem = i;
          this.pageSelected = true
      },
      viewMenu(){
          this.pageSelected = false
      },
      handleResize() {
          this.screenWidth = window.innerWidth;
      },
  },
    computed:{
        ...mapGetters(['hardcoded', 'skin']),
        title(){
          if(this.hardcoded.my_org){
              return this.hardcoded.my_org.title
          }else return this.componentText.title
        },
      componentText() {
        return this.getText.loggedIn.myOrganisation;
      },
    }
};
</script>
<style lang="less">
  #whats-happening{
    .page-header {
      padding: 60px 0;
      .content-container {
        position: relative;
        .header-background {
          z-index: 0;
          position: absolute;
          width: 250px;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    .page-title {
      text-align: left;
      z-index: 10;
      &:first-letter{
        text-transform: uppercase;
      }
      h2 {
        text-align: left;
        font-size: 3.8rem;
        letter-spacing: 0.7rem;
        font-family: "FilsonProBold", sans-serif;
        color: #fff;
        z-index: 10;
      }
    }
    .return-link{
      font-size: 1.7rem;
      padding-bottom: 20px;
    }
      .page-results-title {
        margin: 0 0 24px 4px;
        font-family: "FilsonProLight", sans-serif;
        font-size: 19px;
        line-height: 1;
        text-transform: uppercase;
        color: #394961;
        opacity: 0.6;
      }
      .page-results-wrap {
        position: relative;
        &:before{
          position: absolute;
          content: '';
          top: 50%;
          width: 1px;
          height: 96%;
          left: 80px;
          transform: translateY(-50%);
          background-color: #c9c9c9;
          z-index: 1;
        }
        .page-event {
          border-style: solid;
          border-width: 0 0 0 10px;
          border-radius:  0 10px 10px 0;
          box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.3);
          width: 100%;
          min-height: 75px;
          height: 75px;
          font-family: "FilsonProBold", sans-serif;
          display: flex;
          display: -ms-flexbox;
          -ms-flex-pack: start ;
          -webkit-box-align: start;
          justify-content: flex-start;
          flex-direction: row;
          cursor: pointer;
          flex-wrap: nowrap;
          position: relative;
          text-align: left;
          transform-style: flat;
          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            //top: 25px;
            align-items: center;
            justify-content: flex-start;
          }
          &.active {
            box-shadow: none;
            background-color: #f6f6f6;
          }
          &:nth-last-child(1){
            margin-bottom: 0;
          }
          &:before{
            position: absolute;
            left: 78px;
            top: 50%;
            content: '';
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background-color: white;
            transform: translateY(-50%);
            z-index: 2;
            transform-style: flat;
          }
          .icon{
            width: 80px;
            flex-shrink: 0;
          }
          .page-title {
            font-size: 1.6rem;
            display: flex;
            justify-content: flex-start;
            font-family: 'DMSans', sans-serif !important;
            color: #727577;
            &:first-letter{
              text-transform: uppercase;
            }
          }
          .marker {
            content: "";
            position: absolute;
            width: 12px;
            height: 12px;
            border: 1px solid #7e4578;
            border-radius: 50%;
            background-color: white;
            transform: translateY(-50%);
            z-index: 1;
            right: 30px;
          }
        }
      }
    .page-head{
      width: 100%;
      height: 150px;
    }
    .page-content{
      text-align: center;
      .page-title{
        text-align: center;
        background-color: #fff;
        transform: translateY(-50%);
        padding: 5px 8px;
        .title-box{
          border: 1px solid #cdcdcd;
        }
      }
      .page-description{
        h4{
          transform: translateY(-50%);
          text-transform: capitalize;
          font-size: 2rem;
        }
      }
      .page-text{
        font-family: 'DMSans', sans-serif;
        font-size: 1.6rem;
        text-align: left;
        color: #394961;
        img{
          max-width: 100%;
        }
      }
    }
    .bg-white{
      min-height: calc(100vh - 380px);

    }
    .no-content{
      height: 100%;
      display: flex;
      min-height: calc(100vh - 380px);
      align-items: center;
      justify-content: center;
      text-align: center;
      img{
        max-width: 230px;
      }
      &>div {
        flex: 1;
      }
    }
    }
</style>
