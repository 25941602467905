<template>
  <div id="JournalEntry">
    <div class="journal-entry-items-container border-brand-highlight-1">
      <div class="journal-entry-item shadow">
        <JournalEntryQuestion></JournalEntryQuestion>
      </div>
    </div>
    <div class="d-flex flex-row justify-content-center">
      <b-button class="cta-button px-5 py-3 mt-5 mx-auto">Save</b-button>
    </div>
  </div>
</template>

<script>
import JournalEntryQuestion from "./JournalEntryQuestion.vue"

export default {
  components: {
    JournalEntryQuestion
  },
  name: "JournalModal",
  data() {
    return {
      content: "",
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="less">
#JournalEntry{
  .journal-entry-item{
    min-height: 200px;
    border-radius: 0 16px 16px 0;
    border-left: 5px solid;
    border-color: inherit;
  }
}
</style>

