<template>
  <div id="Webinars">
    <div v-if="loading">
      <b-spinner />
    </div>
    <div class="available-webinars" v-else-if="hasWebinars">
      <div class="content-container">
        <div class="row available-webinars section-wrap" v-if="availableWebinars.length > 0">
          <div class="section-header">
            <h3>{{componentText.availableTitle}}</h3>
          </div>
          <div
            v-for="(webinar, i) in availableWebinars"
            :key="i"
            class="col-12 webinar-wrap py-5 my-3"
            @click="viewWebinar(webinar)"
          >
            <div class="webinar-title">
              <h4>{{ webinar.title }}</h4>
            </div>
            <div class="btn-wrap mx-5">
              <b-button class="cta-button py-3 available">{{
                componentText.available
              }}</b-button>
            </div>
          </div>
        </div>
        <div class="row open-webinars section-wrap" v-if="regOpenWebinars.length > 0">
          <div class="section-header">
            <h3>{{componentText.regOpenTitle}}</h3>
          </div>
          <div
            v-for="(webinar, i) in regOpenWebinars"
            :key="i"
            class="col-12 webinar-wrap py-5 my-3"
            @click="selectWebinar(webinar, webinar.user_reg ? 'registered' : 'open', i)"
          >
            <div class="webinar-title">
              <h4>{{ webinar.title }}</h4>
              <p>{{componentText.availableFrom}} {{getFormatedDate(webinar.video_open_from, "DD/MM/YYYY")}} <i class="far fa-clock ml-2"></i> {{getFormatedDate(webinar.video_open_from, "HH:mm")}}</p>
            </div>
            <div class="btn-wrap mx-5">
              <b-button class="cta-button py-3 px-4 " :disabled="webinar.user_reg">{{
                webinar.user_reg
                  ? componentText.registered
                  : componentText.register
              }}</b-button>
            </div>
          </div>
        </div>
        <div class="row upcoming-webinars section-wrap" v-if="upcomingWebinars.length > 0">
          <div class="section-header">
            <h3>{{componentText.upcomingTitle}}</h3>
          </div>
          <div
            v-for="(webinar, i) in upcomingWebinars"
            :key="i"
            class="col-12 webinar-wrap py-5 my-3"
            @click="selectWebinar(webinar, 'upcoming', i)"
          >
            <div class="webinar-title">
              <h4>{{ webinar.title }}</h4>
              <p>{{componentText.registerFrom}} {{getFormatedDate(webinar.registration_open_from, "DD/MM/YYYY")}} <i class="far fa-clock ml-2"></i> {{getFormatedDate(webinar.registration_open_from, "HH:mm")}}</p>
            </div>
            <div class="btn-wrap mx-5">
              <b-button class="cta-button py-3" disabled>{{
                componentText.upcoming
              }}</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center content-container no-events" v-else>
      <div
              class="col-12 col-md-6 py-5 brand-secondary d-flex align-items-center"
      >
        <div class="no-events-heading before-secondary">
          {{componentText.noEvents}}
        </div>
      </div>
      <div class="col-0 offset-1 col-md-5 no-events-image">
        <img src="/img/placeholder-bookings-generic.svg" />
      </div>
    </div>
    <b-modal
      id="webinar-modal"
      :modal-class="'theme-' + $store.getters.skin"
      hide-header
      hide-footer
      centered
    >
      <div
        class="register-success"
        v-if="justRegistered.includes(selectedWebinar.id)"
      >
        <div class="check-circle">
          <i class="fas fa-check-circle"></i>
        </div>
        <h3>{{componentText.thanks}} {{ selectedWebinar.title }}</h3>
      </div>
      <div class="register" v-else>
        <div class="description">
          <p v-if="selectedWebinar.status === 'registered'">{{componentText.confirmation}} </p>
          <h3>{{ selectedWebinar.title }}</h3>
          <p class="py-3">{{ selectedWebinar.description }}</p>
        </div>
      </div>
      <div class="available-to-view" v-if="selectedWebinar.status !== 'upcoming'">
        <p>{{componentText.availableToView}}</p>
        <p class="mb-0">
          <strong>{{
            getFormatedDate(selectedWebinar.video_open_from, "dddd DD/MM/YYYY")
          }}</strong>
        </p>
        <p>
          <i class="far fa-clock"></i
          ><strong>
            {{
              getFormatedDate(selectedWebinar.video_open_from, "HH:mm")
            }}</strong
          >
        </p>
      </div>
      <div v-else>
        <p>{{componentText.availableToRegister}}</p>
        <p class="mb-0">
          <strong>{{
            getFormatedDate(selectedWebinar.registration_open_from, "dddd DD/MM/YYYY")
            }}</strong>
        </p>
        <p>
          <i class="far fa-clock"></i
          ><strong>
          {{
          getFormatedDate(selectedWebinar.registration_open_from, "HH:mm")
          }}</strong>
        </p>
      </div>
      <div class="bt-wrap" v-if="!registerLoading">
        <b-button
          @click="registerAttendance()"
          class="cta-button px-5 py-3 mt-4"
          :disabled="registerSuccess || registerLoading"
          v-if=" selectedWebinar.status === 'open'"
          >Register to view</b-button>
        <b-button class="cta-button px-5 py-3 mt-4" @click="closeModal()" v-else>OK</b-button>
      </div>
      <div v-else>
        <b-spinner />
      </div>
      <div class="close">
        <a @click="closeModal()"><i class="fas fa-times"></i></a>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import config from "@/config/constants";
export default {
  name: "Webinars",
  data() {
    return {
      loading: true,
      availableWebinars: [],
      regOpenWebinars: [],
      upcomingWebinars: [],
      hasWebinars: false,
      selectedWebinar: {},
      registerLoading: false,
      registerSuccess: false,
      justRegistered: []
    };
  },
  mounted() {
    this.getWebinars();
  },
  methods: {
    async getWebinars() {
      const payload = {
        user_id: this.$store.getters.user_id
      };
      let res = await this.axios.post(
        process.env.VUE_APP_API_PATH +
          "/application/api/u/webinars/get-webinars",
        payload, config.options
      );
      if (res.data.success) {
        const now = moment();
        const webinars = res.data.webinars;
        const regOpenUnregisted = []
        const regOpenRegisterd = []
        webinars.length > 0 ? this.hasWebinars = true : this.hasWebinars = false
        for (let i = 0; i < webinars.length; i++) {
          const videoOpen = moment(webinars[i].video_open_from);
          const regOpen = moment(webinars[i].registration_open_from);
          now > videoOpen
            ? this.availableWebinars.push(webinars[i])
            : now > regOpen && webinars[i].user_reg
            ? regOpenRegisterd.push(webinars[i])
            : now > regOpen
            ? regOpenUnregisted.push(webinars[i])
            : this.upcomingWebinars.push(webinars[i]);
        }
        regOpenUnregisted.sort((a,b) => {
          return moment(a.video_open_from) > moment(b.video_open_from) ? 1 : - 1
        })
        regOpenRegisterd.sort((a,b) => {
          return moment(a.video_open_from) > moment(b.video_open_from) ? 1 : - 1
        })
        this.regOpenWebinars = regOpenUnregisted.concat(regOpenRegisterd)
        this.upcomingWebinars.sort((a,b) => {
          return moment(a.registration_open_from) > moment(b.registration_open_from) ? 1 : - 1
        })
        this.loading = false;
      }
    },
    selectWebinar(webinar, status, key) {
      this.selectedWebinar = webinar;
      this.selectedWebinar.status = status
      this.selectedWebinar.key = key
      this.$bvModal.show("webinar-modal");
    },
    async registerAttendance() {
      this.registerLoading = true;
      const payload = {
        user_id: this.$store.getters.user_id,
        webinar_id: this.selectedWebinar.id
      };
      let res = await this.axios.post(
        process.env.VUE_APP_API_PATH + "/application/api/u/webinars/register",
        payload, config.options
      );
      if (res.data.success) {
        this.registerLoading = false;
        this.registerSuccess = true;
        this.justRegistered.push(this.selectedWebinar.id);
        this.selectedWebinar.status = 'registered'
        this.regOpenWebinars[this.selectedWebinar.key].user_reg = true
      }
    },
    closeModal() {
      this.$bvModal.hide("webinar-modal");
      this.registerSuccess = false;
    },
    viewWebinar(webinar) {
      this.$router.push({
        name: "events",
        params: { page: "webinars", webinar: webinar.id }
      });
    }
  },
  computed: {
    componentText() {
      return this.getText.loggedIn.events.webinarsPage;
    }
  }
};
</script>

<style lang="less">
#Webinars {
  height: 100%;
  .no-events-image{
    text-align: right;
    img{
      max-width: 300px;
      margin-left: auto;
    }
  }
    .section-wrap {
        padding-bottom: 40px;
        max-width: 900px;
        margin: auto;
        h3 {
            font-family: DMSans, sans-serif;
            font-size: 2.2rem;
            letter-spacing: 0;
            padding: 0;
        }
    }
  .webinar-wrap {
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
    &:hover {
      box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.3);
    }
    &.disabled {
      cursor: unset;
      &:hover {
        box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.2);
      }
    }
    .webinar-title {
      justify-self: flex-start;
      h4 {
        font-family: FilsonProBold, sans-serif;
        font-size: 1.8rem;
        padding: 0;
        margin-bottom: 0;
        letter-spacing: 0;
      }
      p{
        font-size: 1.5rem;
        margin-bottom: 0;
      }
      i{
        color: #a8a8a8;
        font-size: 1.7rem;
      }
    }
    .btn-wrap {
      justify-self: flex-end;
      max-width: 200px;
      .btn {
          min-width: 150px;
        font-family: DMSans, sans-serif;
          font-size: 1.4rem;
          letter-spacing: 0;
        &.available {
          background-color: #fff;
          color: #e83b75;
          border: 1px solid #e83b75;
          &:hover {
            color: #fff;
          }
        }
      }
    }
    @media (max-width: 576px){
      flex-direction: column;
      text-align: center;
      .btn-wrap{
        margin-top: 15px;
      }
    }
  }
}
#webinar-modal {
  .modal-content {
    padding: 30px;
    border-radius: 20px;
    text-align: center;
    max-width: 600px;
    width: 100%;
    position: relative;
    border-width: 0;
    color: #3a4961;
    .modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 100%;
      .description{
        max-width: 100%;
      }
    }
    .check-circle {
      font-size: 4rem;
      color: #2bb673;
    }
    .icon-wrap {
      width: 150px;
      img {
        width: 100%;
      }
    }
    h3 {
      font-family: FilsonProBold, sans-serif;
      font-size: 2rem;
      padding: 0;
      margin-bottom: 0;
    }
    p {
      font-size: 1.6rem;
      font-family: DMSans, sans-serif;
    }
    strong {
      font-family: FilsonProBold, sans-serif;
    }
    .cta-button {
      border-radius: 30px;
      border-width: 0;
      font-size: 1.6rem;
      font-family: DMSans, sans-serif;
    }
    .close {
      position: absolute;
      top: -10px;
      right: -10px;
      opacity: 1;
      cursor: pointer;
      font-size: 1.8rem;
    }
  }
}
</style>
