<template>
<div id="AdminCompanyBoard">
  <transition name="fade" mode="out-in">
    <div class="text-left">
      <div class=" row page-content">
        <div class="col-md-10 offset-md-1">
          <div class="row">
            <h3 class="heading">Categories</h3>
            <p v-if="nav_type_standard">Use the input field below to create new categories.  Drag and drop the categories to change their position as they will be displayed on the page (furthest left will be displayed first).  Make sure to save your changes once done.</p>
            <p v-else>Use the input below to create new categories.  Contact your account manager to have new categories added to your user portal navigation.</p>
            <b-input-group class="mb-4">
              <b-form-input
                  placeholder="Enter a new category"
                  class="form-control"
                  id="CatInput"
                  v-model="newCatTitle"
              ></b-form-input>
              <b-input-group-append>
                <b-button @click="addCategory()" variant="primary" :disabled="!inputValidity">Add</b-button>
              </b-input-group-append>
            </b-input-group>
            <Vuedraggable @blur="finishedEditingCategory" v-if="categories.length" id="category-draggable" v-model="categories" group="position" @start="drag=true" @end="drag=false" @change="updatePositions" :disabled="!nav_type_standard">
              <div v-for="element in categories" :key="element.id" class="b-form-tag" :class="draggableClass">
                <div @click="editCategory(element.id)" class="drag-pos" v-if="nav_type_standard">{{element.position}}</div>
                <div  @click="editCategory(element.id)" class="drag-title">{{element.title}}</div>
                <div class="drag-delete" @click="deleteCategory(element.id)">x</div>
              </div>
            </Vuedraggable>
          </div>
          <div class="row d-flex align-items-center justify-content-center mt-4">
            <b-button class="btn btn-light" @click="saveCategories" v-if="nav_type_standard">Save Category Order</b-button>
          </div>
          <div class="row">

            <div class="col-6">
              <h3 class="heading">Add Custom Company Pages</h3>
            </div>
            <div class="col-5 mb-2 offset-1">
              <div>
                <b-form-text>
                  Filter By Category
                </b-form-text>
                <b-form-select v-model="cat_filter">
                  <b-form-select-option value="all">All</b-form-select-option>
                  <b-form-select-option v-for="cat in categories" :key="'cat-'+cat.id" :value="cat.id">
                    {{cat.title}}
                  </b-form-select-option>
                </b-form-select>
              </div>
              <div v-if="!nav_type_standard">
                <b-form-text>
                  Filter By Type
                </b-form-text>
                <b-form-select v-model="type_filter">
                  <b-form-select-option value="all">All</b-form-select-option>
                  <b-form-select-option v-for="type in post_type_options" :value="type" :key="'type'+type">{{type}}</b-form-select-option>
                </b-form-select>
              </div>

            </div>

            <b-table
                :per-page="perPage" :current-page="currentPage"
                bordered id="postTable" :items="filteredPosts" :fields="nav_type_standard ? post_fields:post_fields_category_nav" v-if="posts.length">
              <template  v-slot:cell(categories)="data">
                {{getCategoryTitle(data.item.categories)}}
              </template>
              <template v-if="!nav_type_standard" v-slot:cell(rank)="data">
                {{data.item.rank == 9999 ? '' : data.item.rank}}
              </template>
              <template v-slot:cell(featured)="data">
                <i style="color: #07de2d" v-on:click="toggleSetting('featured',data.item.id, 0)" v-if="data.item.featured" class="fa fa-check"></i>
                <i style="color: darkred" v-on:click="toggleSetting('featured',data.item.id, 1)" v-else class="fa fa-times"></i>
              </template>
              <template v-slot:cell(status)="data">
                <i v-on:click="toggleSetting('published',data.item.id, 1)" v-if="data.item.published === 0" class="fa fa-eye-slash"></i>
                <i v-on:click="toggleSetting('published',data.item.id, 0)" v-else class="fa fa-eye"></i>
              </template>
              <template v-slot:cell(delete)="data">
                <i style="color: darkred" v-on:click="deletePost(data.item.id)" class="fa fa-trash"></i>
              </template>
              <template v-slot:cell(edit)="data">
                <i v-on:click="openPostModal(data.item.id)" class="fa fa-edit"></i>
              </template>
            </b-table>
          </div>
          <div class="overflow-auto d-flex justify-content-center" v-if="filteredPosts.length >= perPage">
            <b-pagination
                v-model="currentPage"
                :total-rows="filteredPosts.length"
                :per-page="perPage"
                aria-controls="postTable"
            ></b-pagination>
          </div>
          <div class="row d-flex align-items-center justify-content-center my-2">
            <b-button class="btn btn-light mb-5" @click="createNewPost">Create New Post</b-button>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <b-modal id="edit-post-modal" size="xl" :title="postToEditIndex === null ? 'Create New Post': 'Edit Post'" hide-footer no-close-on-backdrop>
    <CompanyBoardModal :posts="posts" :categories="categories"
                       :postToEditIndex="postToEditIndex"
                       @saved="getData"
                       :nav_type_standard="nav_type_standard"
                       :post_type_options="post_type_options"
    />
  </b-modal>
  <b-modal id="edit-category-modal" title="Edit Category" hide-footer>
    <CompanyBoardCategoryModal :newCategoryDetails="newCategoryDetails" @saved="getData" :nav_type_standard="nav_type_standard"/>
  </b-modal>
</div>
</template>

<script>

import Vuedraggable from "vuedraggable/src/vuedraggable";
import CompanyBoardCategoryModal from "@/components/admin/CompanyBoardCategoryModal.vue";
import CompanyBoardModal from "@/components/admin/CompanyBoardModal.vue";
export default {
  name: "AdminCompanyBoard",
  components: {Vuedraggable, CompanyBoardModal, CompanyBoardCategoryModal},
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      loading: false,
      categories: [],
      posts: [],
      cat_filter: 'all',
      type_filter: 'all',
      post_type_options: ['post','resource'],
      post_fields_category_nav: [{key:'title',label:'Title',sortable: true},
        {key:'categoryNames',label:'Category',sortable: true},
        {key: 'post_type', label: 'Type', sortable: true},
        {key: 'rank', label: 'order', sortable: true},
        {key:'featured',label:'Featured',sortable: true},
        {key: 'status', label: 'Show for Users'},
        {key: 'delete', label: 'Delete'},
        {key: 'edit', label: 'Edit'},
      ],
      post_fields: [{key:'title',label:'Title',sortable: true},
        {key:'categoryNames',label:'Category',sortable: true},
        {key:'featured',label:'Featured',sortable: true},
        {key: 'status', label: 'Show for Users'},
        {key: 'delete', label: 'Delete'},
        {key: 'edit', label: 'Edit'},
      ],
      category_fields: [{key: 'position', label: 'Position', sortable: "true", thStyle: { width: "10%" },},{key: 'title', label: 'Title'},{key: 'actions', label: 'Actions', thStyle: { width: "30%" },}],
      postToEditIndex: null,
      categoryToEditIndex: null,
      newCatTitle: null,
      newCategoryDetails: {
        title: null,
        description: null,
        image_url: null,
      },
      nav_type_standard: true
    }
  },
  mounted() {
    this.getData();
  },
  computed: {
    filteredPosts() {
      return this.posts.filter(post => {
            return (post.categories.includes(this.cat_filter) || this.cat_filter == 'all') &&
                (post.post_type == this.type_filter || this.type_filter == 'all')
          }
      )
    },
    draggableClass() {
      if (this.nav_type_standard) return 'standardDraggable'
      return 'nonStandardDraggable';
    },
    rows() {
      return this.posts.length
    },
    inputValidity() {
      if(!this.newCatTitle) return false;
      let catNameUnique = true;
      this.categories.map(cat => {
        if(cat.title === this.newCatTitle) catNameUnique = false;
      })
      return this.newCatTitle.length > 1 && catNameUnique;
    },
    changeNameValid() {
      if(!this.updatedCatTitle) return false;
      let catNameUnique = true;
      this.categories.map((cat,index) => {
        if(cat.title === this.updatedCatTitle && index !== this.categoryToEditIndex) catNameUnique = false;
      })
      return this.updatedCatTitle.length > 1 && catNameUnique;
    }
  },
  methods: {
    toggleSetting(action,id,position) {
      let url = process.env.VUE_APP_API_PATH ;
      if(action === 'published') {
        url += '/application/api/c/company-board/publish-post'
      } else {
        url += '/application/api/c/company-board/feature-post'
      }
      const opts = {
        method: 'post',
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
        },
        body: JSON.stringify({
          "user_id": this.$store.getters.user.user_token,
          "featured": position,
          "published": position,
          "post_id": id
        })
      }
      fetch(url, opts).then(response => {
            response.json().then(data => {
              if(data.success == true){
                this.$store.commit('setAlert', {
                  visible : true,
                  text: 'Successfully updated',
                  color: 'success'
                });
                this.getData();
              } else {
                this.$store.commit('setAlert', {
                  visible : true,
                  text: data.err,
                  color: 'danger'
                });
              }
            });
          }
      )
    },
    getData() {
      const url = process.env.VUE_APP_API_PATH + '/application/api/c/company-board/get-admin-data';
      const opts = {
        method: 'post',
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
        },
        body: JSON.stringify({
          "user_id": this.$store.getters.user.user_token
        })
      }
      fetch(url, opts).then(response => {
            response.json().then(data => {
              if(data.success == true){
                this.categories = data.categories;
                this.nav_type_standard = data.nav_type === 'standard';
                data.posts.forEach(post => {
                  post.excerpt = atob(post.excerpt)
                  post.content = atob(post.content)
                });
                data.posts.map(post => {
                  post.categoryNames = this.getCategoryTitle(post.categories)
                })
                this.posts = data.posts;
                this.categoryToEditIndex = null;
              } else {
                this.$store.commit('setAlert', {
                  visible : true,
                  text: data.message,
                  color: 'danger'
                });
              }
            });
          }
      )
    },
    updatePositions() {
      this.categories.forEach((cat,i) => {
        cat.position = i + 1;
      });
    },
    saveCategories() {
      const url = process.env.VUE_APP_API_PATH + '/application/api/c/company-board/update-category-list';
      const opts = {
        method: 'post',
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
        },
        body: JSON.stringify({
          "user_id": this.$store.getters.user.user_token,
          "categories": this.categories
        })
      }
      fetch(url, opts).then(response => {
            response.json().then(data => {
              if(data.success == true){
                this.categories = data.categories;
                this.updatePositions();
                this.$store.commit('setAlert', {
                  visible : true,
                  text: 'Categories updated',
                  color: 'success'
                });
              } else {
                this.$store.commit('setAlert', {
                  visible : true,
                  text: data.message,
                  color: 'danger'
                });
              }
            });
          }
      )
    },
    editCategory(id) {
      this.categoryToEditIndex = this.categories.findIndex(i => i.id === id);
      //JSON parse and stringify below is to prevent binding
      this.newCategoryDetails = JSON.parse(JSON.stringify(this.categories[this.categoryToEditIndex]));
      this.$bvModal.show('edit-category-modal')
    },
    deleteCategory(id) {
      let postsAssigned = false;
      this.posts.forEach(post => {
        post.categories.forEach(cat_id => {
          if(cat_id === id) {
            this.$store.commit('setAlert', {
              visible : true,
              text: 'You cannot delete a category that has posts assigned to it',
              color: 'danger'
            });
            postsAssigned = true;
          }
        })
      })
      if(postsAssigned) return;
      const catIndex = this.categories.findIndex(i => i.id === id);
      const position = this.categories[catIndex].position;
      this.categories.forEach(cat => {
        if(cat.position > position) cat.position -= 1;
      })
      this.categories.splice(catIndex,1);
      this.categoryToEditIndex = null;
      this.saveCategories();
    },
    createNewPost() {
      this.postToEditIndex = null;
      this.$bvModal.show('edit-post-modal')
    },
    getCategoryTitle(cats) {
      const catNames = cats.map((cat,i) => this.categories.filter(i => i.id === cat)[0].title)
      catNames.sort(function (a, b) {
        if (a < b) return -1;
        if (a.name > b.name) return 1;
        return 0;
      })
      let string = '';
      catNames.forEach((title, i) => {
        if (i > 0) string += ', '
        string += title;
      })
      return string
    },
    openPostModal(id) {
      this.postToEditIndex = this.posts.findIndex(i => i.id === id);
      this.$bvModal.show('edit-post-modal')
    },
    deletePost(id) {
      const postIndex = this.posts.findIndex(i => i.id === id);
      this.$bvModal.msgBoxConfirm(`Please confirm that you wish to delete '${this.posts[postIndex].title}'.  Once deleted, this action cannot be reversed.`, {
        title: `Delete '${this.posts[postIndex].title}'`,
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'DELETE',
        cancelTitle: 'BACK',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
          .then(value => {
            if(value) {
              let url = process.env.VUE_APP_API_PATH + '/application/api/c/company-board/delete-post';
              const opts = {
                method: 'post',
                headers: {
                  "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
                },
                body: JSON.stringify({
                  "user_id": this.$store.getters.user.user_token,
                  "post_id": id
                })
              }
              fetch(url, opts).then(response => {
                    response.json().then(data => {
                      if(data.success == true){
                        this.$store.commit('setAlert', {
                          visible : true,
                          text: 'Post deleted',
                          color: 'success'
                        });
                        this.getData();
                      } else {
                        this.$store.commit('setAlert', {
                          visible : true,
                          text: data.err,
                          color: 'danger'
                        });
                      }
                    });
                  }
              )
            }
          })
    },
    addCategory() {
      this.categories.push({id: 'new-'+this.newCatTitle, title: this.newCatTitle, position: this.categories.length + 1})
      this.newCatTitle = null;
      this.saveCategories();
    },
    finishedEditingCategory() {
      this.categoryToEditIndex = null;
    }
  }
}
</script>

<style lang="less">
#edit-post-modal {
  h5 {
    font-size: 1.8rem;
  }
}
#edit-category-modal {
  h5 {
    font-size: 2rem;
  }
  .catNameInput, .catDescInput {
    border-radius: 5px 0 0 5px!important;
  }
  #catApplyBtn {
    border-radius: 0 5px 5px 0 !important;
  }
}
#AdminCompanyBoard {
  font-family: "DMSans", sans-serif;
  font-size: 1.5rem;
  td, th {
    text-align: center;
  }
  small {
    font-size: 1.4rem;
  }
  select {
    font-size: 1.6rem;
  }
  .btn {
    font-size: 1.5rem;
  }
  #category-draggable {
    padding: 10px;
    width: 100%;
    background-color: lighten(#e2e2e2,8%);
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    border-radius: 5px;
    .catEditInput {
      margin: 0px !important;
      padding: 0px !important;
      border: none !important;
      border-radius: 0 !important;
      background-color: lighten(#E83B75, 20%) !important;
      width: 120px;
      text-align: center;
      color: white;
    }
  }
  .pill-delete-btn {
    font-weight: bold;
    color: darken(#E83B75, 20%);
  }
  i {
    font-size: 1.35em !important;
  }
  .sub-header {
    width: 100%;
    font-size: 0.9rem;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 15px;
  }
  .form-control {
    background-color: white !important;
    border: none !important;
    border-radius: 5px 0px 0px 5px !important;
  }
  #category-tags {
    border: 1px darkgray solid !important;
  }
  .input-group-append {
    button {
      border-radius: 0px 5px 5px 0px !important;
    }
  }
  .standardDraggable {
    display: inline-grid;
    grid-template-columns: 1fr 4fr 1fr;
  }
  .nonStandardDraggable {
    display: inline-grid;
    grid-template-columns: 4fr 1fr;
  }
  .b-form-tag {
    background-color: #E83B75 !important;
    padding: 5px 10px;
    margin-right: 15px;
    margin-bottom: 5px;
    font-size: 1.1em;
    font-weight: bold;
    color: white;
    border-radius: 20px;
    min-width: 170px;
    .drag-pos {
      padding-left: 10px;
      font-size: 0.7em;
      color: darken(#E83B75, 20%);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .drag-title {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .drag-delete {
      display: flex;
      justify-content: end;
      align-items: center;
      padding-right: 10px;
      color: darken(#E83B75, 20%);
    }
    a:hover, div:hover {
      cursor: pointer;
    }
  }
  #CatInput {
    height: 40px;
    border: 1px solid #ced4da !important;
    font-size: 1.5rem;
  }
  .b-form-tag:hover {
    background-color: darken(#E83B75,10%) !important;
  }
  .heading {
    width: 100%;
    text-align: left;
  }
  #category-tags {
    font-size: 0.8em;
    margin-left: 10px;
  }
}
</style>