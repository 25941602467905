<template>
  <div id="WellnessBenefits">
    <div class="wellness-wrap">
      <div class="page-header bg-brand-secondary">
        <div class="content-container row">
          <div class="page-title col-12">
            <h2>
              {{ $route.meta.title ? $route.meta.title : componentText.title }}
            </h2>
          </div>
        </div>
      </div>
      <div class="bg-grey">
        <div class="content-container row">
          <div class="section-header section-header">
            <div class="section-header-divider"></div>
            <h3 class="section-header-title">{{ componentText.available }}</h3>
          </div>
          <div
            class="col-12 col-md-6 px-5 my-5"
            v-for="(addon, i) in addons"
            :key="i"
            v-if="shouldRender(addon)"
          >
            <div class="row addon-card" @click="addonNav(addon.link)">
              <div class="col-2 card-image px-0">
                <img :src="addon.img" />
              </div>
              <div class="col-10 card-body">
                <div class="title-image mb-4 pt-4">
                  <img :src="addon.logo" />
                </div>
                <div class="card-text">
                  <p class="mb-4 pt-2">{{ addon.description }}</p>
                </div>
                <div class="d-flex justify-content-center">
                  <b-button
                    @click="addonNav(addon.link)"
                    class="cta-button px-4 py-3"
                    >{{ componentText.learn }}</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import config from "../config/constants";

export default {
  name: "WellnessBenefits",
  components: {},
  data() {
    return {
      addons: {},
      specsaversVoucher: {},
    };
  },
  mounted() {
    this.getAddons();
  },
  methods: {
    async getAddons() {
      const payload = {
        user_id: this.$store.getters.user_id,
      };
      let res = await axios.post(
        config.api_env + "/application/api-hc/get-my-addons",
        payload,
        config.options
      );
      this.addons = res.data.addons;
    },
    addonNav(link) {
      if (link) {
        this.$router.push(link);
      } else {
        this.$router.push({ name: "specsavers" });
      }
    },
    //Implemented to stop Specsavers from rendering, should be removed once CORE solution is in place
    shouldRender(item) {
      return item.active && item.name !== "Specsavers";
    }
  },
  computed: {
    componentText() {
      return this.getText.loggedIn.wellnessBenefits;
    },
  },
};
</script>
<style lang="less">
#WellnessBenefits {
  .page-header {
    padding: 60px 0;
    .page-title {
      text-align: left;
      h2 {
        text-align: left;
        font-size: 3.8rem;
        letter-spacing: 0.7rem;
        font-family: "FilsonProBold", sans-serif;
        color: #fff;
      }
    }
  }
  .bg-grey {
    min-height: calc(100vh - 380px);
  }
  .addon-card {
    cursor: pointer;
    height: 240px;
    border-radius: 50px;
    background: #fff;
    box-shadow: 3px 4px 18px 0 rgba(0, 0, 0, 0.35);
    .card-image {
      height: 240px;
      overflow: hidden;
      border-radius: 25px 0 0 25px;
      img {
        height: 100%;
      }
    }
    .card-body {
      text-align: center;
      border-radius: 0 25px 25px 0;
      font-family: DMSans;
      font-size: 16px;
      color: #394961;
      letter-spacing: -1.1px;
      .title-image {
        height: 70px;
        img {
          height: 100%;
        }
      }
      .card-text {
        p {
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}
</style>
