<template>
  <div id="TrainingPlans">
    <div class="content-container pt-5">
      <h2 class="training-plan-heading brand-text" v-html="componentText.exploreTrainingPlans">
        
      </h2>
      <p class="training-plan-intro-text brand-text">
        {{componentText.trainingPlansBlurb}}
      </p>
    </div>
    <div class="content-container" v-if="loading === false">
      <div class="row pb-5 mb-5" v-if="trainingPlans.length !== 0">
        <div
          class="col-12 col-md-6 my-3"
          v-for="plan in trainingPlans"
          :key="plan.id"
        >
          <div
            class="course-card"
            @click="$router.push('/digital-gym/training-plans/' + plan.id)"
          >
            <div
              class="course-image"
              :style="{ backgroundColor: plan.background_color }"
            >
              <img :src="getImage(plan)" />
            </div>
            <div class="course-content flex-grow pt-5 px-5 pb-3">
              <h3 class="mb-4 brand-primary">{{ plan.title }}</h3>
              <div class="course-description">{{ plan.description }}</div>
              <div class="session-info pb-3 w-100">
                <div
                  class="progress-bar-outer mb-3"
                  v-if="plan.attendedClasses !== 0"
                >
                  <div
                    class="progress-bar-inner bg-brand-primary"
                    v-bind:style="{ width: plan.percentageComplete }"
                  ></div>
                </div>
                <div class="d-flex flex-row justify-content-between w-100">
                  <span
                    class="sessions active brand-primary"
                    v-if="plan.attendedClasses !== 0"
                    ><i class="fa fa-calendar-check mr-3"></i>{{componentText.attended}}
                    {{ plan.attendedClasses }}/{{
                      plan.totalClasses
                    }}
                    {{componentText.classes}}</span
                  >
                  <span class="sessions" v-else>
                    <i class="fas fa-calendar mr-3"></i
                    >{{ plan.totalClasses }} {{componentText.classes}}
                  </span>
                  <a
                    class="d-none d-lg-inline class-link"
                    @click="
                      $router.push('/digital-gym/training-plans/' + plan.id)
                    "
                    ><span v-if="plan.attendedClasses === 0"
                      >{{componentText.startTrainingProgramme}}</span
                    ><span v-else>{{componentText.resume}}</span>
                    <i class="fas fa-long-arrow-alt-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row py-5 px-4" v-else>
        <p class="no-training-plans-message">
         {{componentText.noTrainingPlans}}
        </p>
      </div>
    </div>
    <div
      class="spinner-container d-flex flex-row align-items-center mt-5"
      v-else
    >
      <div class="mx-auto my-auto">
        <b-spinner class="brand-primary"></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import TrainingPlanVideo from "./TrainingPlanVideo.vue";
import config from "@/config/constants";
export default {
  name: "TrainingPlans",
  components: {},
  data() {
    return {
      loading: true,
      trainingPlans: [],
      hardcodedData: [
        {
          "featured_img": "https://healthcoach-media.com/res/images/hiit.png",
          "title": "Fitness - HIIT",
        },
        {
          "featured_img": "https://healthcoach-media.com/res/images/Pilates.png",
          "title": "Pilates - Beginners",
        },
        {
          "featured_img": "https://healthcoach-media.com/res/images/yoga.png",
          "title": "Yoga - Beginners",
        },
        {
          "featured_img": "https://healthcoach-media.com/res/GymIcons/combat.png",
          "title": "Fitness - Combat",
        },
        {
          "featured_img": "https://healthcoach-media.com/res/GymIcons/pregnancy_pilates.png",
          "title": "Pilates - Pregnancy",
        },
        {
          "featured_img": "https://healthcoach-media.com/res/GymIcons/yoga.png",
          "title": "Yoga - Intermediate and Advanced",
        },
        {
          "featured_img": "https://healthcoach-media.com/res/GymIcons/bodyweightstrength.png",
          "title": "Bodyweight Strength",
        },
        {
          "featured_img": "https://healthcoach-media.com/res/GymIcons/Yoga_sport&mobility.png",
          "title": "Sport/Mobility ",
        },
        {
          "featured_img": "https://healthcoach-media.com/res/GymIcons/Pilates_Advanced.png",
          "title": "Pilates - Intermediate and Advanced",
        },
        {
          "featured_img": "https://healthcoach-media.com/res/images/Pilates_BackMob.png",
          "title": "Pilates: Back Mobility Pilates",
        },
        {
          "featured_img": "https://healthcoach-media.com/res/images/Crowpose_Masterclass.png",
          "title": "Yoga: Crow Pose Masterclass",
        },
        {
          "featured_img": "https://healthcoach-media.com/res/images/BeginnersFitness.png",
          "title": "Fitness - Back to Basics",
        },
        {
          "featured_img": "https://healthcoach-media.com/res/images/Recovery%20Pilates.png",
          "title": "Recovery Pilates ",
        },
        {
          "featured_img": "https://healthcoach-media.com/res/images/Yoga_elemental.png",
          "title": "Elemental Yoga",
        },
        {
          "featured_img": "https://healthcoach-media.com/res/images/combat%20hit.jpg",
          "title": "Combat HIIT",
        },
        {
          "featured_img": "https://healthcoach-media.com/res/images/power%20pilates.jpg",
          "title": "Power Pilates",
        },
        {
          "featured_img": "https://healthcoach-media.com/res/images/Ninyasa%20Flow%20Yoga.jpg",
          "title": "Vinyasa Flow Yoga",
        }
      ]
    }
  },
  mounted() {
    this.getTrainingPlans();
  },
  methods: {
    getImage(plan) {
      if(plan.featured_img) return plan.featured_img;
      const hardcodedPlan = this.hardcodedData.filter(data => data.title === plan.title);
      if(!hardcodedPlan.length) return 'https://healthcoach-media.com/res/GymIcons/Yoga_sport&mobility.png'
      return hardcodedPlan[0].featured_img;
    },
    async getTrainingPlans() {
      const payload = {
        user_id: this.$store.getters.user_id,
        schedule_id: 10,
      };
      let res = await this.axios.post(
        process.env.VUE_APP_API_PATH +
          "/application/api/u/studio/get-training-plans",
        payload, config.options
      );
      if (res.data.success) {
        let filterFn = function (plan) {
          return plan.attended;
        };
        let plans = res.data.training_plans;
        plans.forEach(function (plan) {
          plan.totalClasses = plan.training_classes.length;
          plan.attendedClasses = plan.training_classes.filter(filterFn).length;
          plan.percentageComplete =
            (plan.attendedClasses / plan.totalClasses) * 100 + "%";
        });


        this.trainingPlans = plans;
        this.loading = false;
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      }
    },
  },
  computed: {
    componentText() {
      return this.getText.digitalGym;
    },
  },
  watch: {},
};
</script>

<style lang="less">
#TrainingPlans {
  min-height: 350px;
  .training-plan-heading {
    text-align: left;
    font-size: 3.2rem;
  }
  .training-plan-intro-text {
    font-size: 1.6rem;
    max-width: 600px;
    padding-bottom: 50px;
  }
  .course-card {
    display: flex;
    flex-direction: row;
    border-radius: 27px;
    box-shadow: 3px 3px 10px #cccccc;
    cursor: pointer;
    min-height: 292px;
    &:hover {
      transform: translate(-3px, -3px);
    }
    h3 {
      padding: 0;
      letter-spacing: 0;
    }
    .course-description {
      font-size: 1.6rem;
      line-height: 1.4;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .course-image {
      min-width: 140px;
      width: 140px;
      border-radius: 27px 0 0 27px;
      display: flex;
      align-items: center;
      overflow: hidden;
      img {
        min-height: 100%;
        width: 140px;
      }
    }
    .course-content {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      .session-info {
        margin-top: auto;
        font-size: 1.6rem;
        color: #ccc;
        a {
          margin-left: auto;
          font-family: DMSans, sans-serif;
          svg {
            width: 20px;
            .cls-1 {
              fill: #e6007e;
            }
          }
          &:hover {
            svg {
              width: 25px;
            }
          }
        }
        .fa,
        .fas {
          font-size: 2rem !important;
        }
        .class-link {
          .fas {
            position: relative;
            top: 2px;
            padding-left: 5px;
            font-size: 2rem;
          }
        }
      }
    }
    @media (max-width: 576px) {
      .course-image {
        min-width: 120px;
        width: 120px;
        img {
          width: 120px;
        }
      }
      .session-info a {
        display: none;
      }
    }
    .progress-bar-outer {
      height: 8px;
      background-color: lightgrey;
    }
    .progress-bar-inner {
      height: 100%;
    }
    .sessions {
      font-size: 1.6rem;
      font-weight: bold;
      font-family: FilsonProBold, Arial, Helvetica, sans-serif;
    }
    .class-link {
      font-weight: 400 !important;
      font-family: DMSans, Arial, Helvetica, sans-serif;
    }
  }
  //IE style
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .course-card {
      .course-content {
        display: flex;
        flex: 1;
        height: 292px;
        .course-description {
          padding: 20px 0;
        }
        .session-info {
          height: 90px;
          padding: 10px 0;
        }
      }
    }
  }
  .spinner-container {
    min-height: 400px;
  }
  .no-training-plans-message{
    font-size: 2rem;
    font-weight: bold;

  }
}
</style>
