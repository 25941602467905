<template>
  <div id="TherapyStart">
    <div v-if="serviceSelected || page === 0">
      <b-row class="startSection">
        <b-col cols="12" lg="5" class="cardHolder p-4">
          <div v-if="!upcoming_bookings.length">
            <ServiceCard v-if="$route.params.slug === 'cmh_assessment'" :name="service.name" :price="service.price" :currency="'EUR'" :slug="service.slug" :show-learn-more="false"/>
            <AssessmentCard :label="service.name" :price="service.price" :currency="service.currency" v-else/>
<!--            <div v-if="$route.params.slug === 'cmh_assessment'" class="mt-5 specialistAssessmentText px-4">-->
<!--              As part of Laya's commitment to your mental health and wellbeing, the cost of this <span>{{service.name.toLowerCase()}}</span> is pre-paid as part of your membership-->
<!--            </div>-->
            <button class="therapyBtn mx-auto mt-5" @click="startBooking" v-if="intakeFormProgress === 1 || $route.params.slug === 'cmh_assessment'">
              {{ service.slug === 'cmh_assessment' ? 'Book ' + service.name :'Book Now' }}
            </button>

            <button v-else class="therapyBtn mx-auto mt-5" @click="gotoIntakeForm(true)">Complete Intake Form</button>
            <div>

            </div>
          </div>
          <div v-if="upcoming_bookings.length">
            <h3 style="padding-top: 0">
              Your booking
            </h3>
            <div v-for="(booking,i) in upcoming_bookings">
              <TherapyBookingCard :booking="booking.booking" :service-provider="booking.service_provider" :is-earliest="i === 0"/>
            </div>
          </div>
        </b-col>
        <b-col cols="12" lg="7" class="textHolder p-4">
          <AssessmentText :service="service"/>
        </b-col>
      </b-row>
      <b-row v-if="$route.params.slug !== 'cmh_assessment'">
        <b-col cols="12" lg="6" offset-lg="0" class="p-4">
          <div class="formHolder">
            <div class="innerText">
              Intake Form <br>
              <span @click="gotoIntakeForm(false)">{{intakeFormProgress === 1 ? 'Click here to review':'Click here to complete' }}</span>
            </div>
            <div class="checkHolder" :class="{incomplete: intakeFormProgress !== 1}">
              <i class="fa-solid fa-circle-check" v-if="intakeFormProgress === 1"></i>
              <i class="fa-solid fa-circle-exclamation" v-else></i>
              {{ getFormText(intakeFormProgress) }}
            </div>
          </div>
        </b-col>
        <b-col cols="12" lg="6" offset-lg="0" class="p-4">
          <div class="formHolder">
            <div class="innerText">
              Agreement and Consent Form <br>
              <span @click="goToConsentForm">Click here to review</span>
            </div>
            <div class="checkHolder">
              <i class="fa-solid fa-circle-check"></i>
              Complete
            </div>
          </div>
        </b-col>
        <b-col cols="12">
          <hr class="mt-5">
        </b-col>
      </b-row>
    </div>
    <div v-else class="p-5">
      <div v-if="page === 1">
        <div class="question-text">Do you have a gender preference for your Specialist?</div>
          <TherapyOption
              v-for="(opt,i) in genderOptions"
              :text="opt.label"
              :option-number="i"
              :selected="genderOptionSelected === i"
              @selectOption="genderOptionSelect"
          />

      </div>
      <div v-else-if="page === 2">
        <TherapyDateSelect :initial-gender-selected="genderOptions[genderOptionSelected].value" :genderOptions="genderOptions"/>
      </div>
    </div>
  </div>
</template>

<script>
import TherapySelectModal from "@/components/TherapyPortal/Modals/TherapySelectModal.vue";
import TherapyOption from "@/components/TherapyPortal/Questions/TherapyOption.vue";
import TherapySelectBackandForward from "@/components/TherapyPortal/TherapySelectBackandForward.vue";
import TherapyDateSelect from "@/components/TherapyPortal/TherapyDateSelect.vue";
import AssessmentCard from "@/components/TherapyPortal/Misc/AssessmentCard.vue";
import AssessmentText from "@/components/TherapyPortal/Misc/AssessmentText.vue";
import EventBus from "@/helpers/eventbus";
import TherapyPayments from "@/components/TherapyPortal/TherapyPayments.vue";
import TherapyBookingCard from "@/components/TherapyPortal/TherapyBookingCard.vue";
import ServiceCard from "@/components/TherapyPortal/Misc/ServiceCard.vue";
export default {
  name: "TherapyStart",
  props: {
    upcoming_bookings: {},
    therapy_type: {
      default: null
    },
    age_group: {
      default: null
    },
    service: {
      default: null
    }
  },
  components: {
    ServiceCard,
    TherapyBookingCard,
    TherapyPayments,
    AssessmentCard, AssessmentText, TherapyDateSelect, TherapySelectBackandForward, TherapyOption, TherapySelectModal},
  data() {
    return {
      page: 0,
      genderOptions: [
        {label: 'Any Gender', value: 'any'},
        {label: 'Prefer Female', value: 'female'},
        {label: 'Prefer Male', value: 'male'}
      ],
      genderOptionSelected: null,
      appointment: {},
      intakeFormProgress: null,
    }
  },
  mounted() {
    this.getIntakeForm();
    EventBus.$on('startPage', ()=> {
      this.page = 0;
    });
  },
  computed: {
    cardDetails() {
      return this.getTherapyDetailsFromSlug(this.$route.params.slug);
    },
    therapyType() {
      if(this.therapy_type === null || this.age_group === null) {
        return this.$store.getters.user.therapy;
      }
      return {
        area: this.therapy_type,
        age_group: this.age_group
      }
    },
    serviceSelected() {
      return this.upcoming_bookings.length > 0;
    },
    intakeFormName() {
        return 'intake_'+this.service.pathway_type.toLowerCase()+'_'+this.service.age_group.toLowerCase();
    },
  },
  methods: {
    startBooking() {
      this.$router.push('/select/'+this.$route.params.slug)
    },
    getFormText(pcComplete) {
      if(pcComplete === 1) return 'Complete';
      if(!pcComplete) return 'Not Started';
      return 'In progress'
    },
    async getIntakeForm() {
      let params = {
        path: 'api/u/cmh/get-intake-form',
        form_name: this.intakeFormName
      }
      const res = await this.api(params)
      if(res.success) {
        const data = JSON.parse(atob(res.data.content));
        this.intakeFormProgress = this.determinePercentageOfFormComplete(this.intakeFormName,data.questions);
      } else {

      }
    },
    gotoIntakeForm(therapyBtnClicked = false) {
      if(!therapyBtnClicked) {
        this.m(this.intakeFormProgress === 1 ? 'intake-form-review-button-clicked' : 'intake-form-complete-button-clicked',{service: this.service.name})
      }
      EventBus.$emit('toggleIntakeForm');
      // this.$router.push('/therapy-form/intake')
    },
    goToConsentForm() {
      this.m('agreement-form-review-button-clicked',{service: this.service.name})
      EventBus.$emit('toggleViewAgreement');
    },
    next() {
      this.page++
    },
    back() {
      this.page--
    },
    book() {
      this.serviceSelected = true;
    },
    genderOptionSelect(i) {
      this.genderOptionSelected = i;
      this.page++;
    }
  },
}
</script>

<style lang="less">
#TherapyStart {
  .specialistAssessmentText {
    font-size: 1.6rem;
    text-align: center;
    color: #3A4A61;
    span {
      font-family: FilsonProBold, Arial, Helvetica, sans-serif;
    }
  }
}
</style>