<template>
  <div id="settings" class="container-fluid px-0">
    <div class="main no-side-bar">
      <div class="page-header bg-brand-secondary">
        <div class="content-container container">
          <div class="page-title col-12">
            <h2>Accessibility Statement</h2>
          </div>
        </div>
      </div>
      <div class="content-wrap">
        <div class="content-container row py-5">
          <div class="content-card w-100 p-5">
            <div
              class="content-inner p-md-5 m-md-5 p-0 m-0"
              v-html="decodeData(content)"
            ></div>
          </div>
        </div>
      </div>
      <Footer v-if="$store.state.user.authenticated"></Footer>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import config from "../config/constants";
import TopNav from "@/components/TopNav.vue";
import MobileNav from "@/components/MobileNav.vue";
import Footer from "../components/Footer";
export default {
  name: "AccessibilityPolicy",
  components: {
    Footer,
    TopNav,
    MobileNav,
  },
  data() {
    return {
      content: "",
      isLoading: true,
    };
  },
  mounted() {
    if (this.$route.query.lang) {
      this.$store.commit("setLanguage", this.$route.query.lang);
    }
    this.getPolicy();
  },
  methods: {
    decodeData(data) {
      try {
        return Buffer.from(data, "base64");
      } catch (error) {
        return data;
      }
    },
    async getPolicy() {
      let slug = 'accessibility';
      const payload = {
        slug,
      };
      let res = await axios.post(
        config.api_env + "/application/api/u/info/get-info",
        payload,
        config.options
      );
      if (res.data.success) {
        this.content = res.data.dynamic_string.string;
        this.isLoading = false;
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login").catch((err) => {});
      }
    },
  },
  computed: {
    componentText() {
      return this.getText.privacy;
    },
  },
  watch: {
    skin(newVal) {
      this.$store.commit("setSkin", newVal);
    },
  },
};
</script>
<style lang="less">
#settings {
  td {
    border: 1px solid black !important;
    padding: 3px;
  }
  strong {
    font-family: "DMSans Bold", sans-serif;
  }
  font-size: 1.6rem;
  font-family: "DMSans", sans-serif;
  .content-wrap {
    background: #f4f4f4;
    .content-card {
      transform: translateY(-80px);
      background: #ffffff;
      max-width: 1000px;
      margin: auto;
    }
  }
  @media (max-width: 992px) {
    .page-header {
      padding-top: 100px;
    }
    .content-card {
      transform: translateY(0);
    }
  }

}
</style>
