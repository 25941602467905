<template>
	<div id="impact-section" class="h-100">
		<div id="trees-impact" class="row no-gutters impact">
			<div class="col-md-6"></div>
			<div class="col-md-6 text-block p-3">
				<h2>{{trees}}</h2>
				<h4>Mangrove {{ trees === 1? 'tree': 'trees'}} Planted<br/><span class="text-light">because of you</span></h4>
				<p>When you complete tasks and earn achievements, you have a direct impact on the environment</p>
			</div>
		</div>
		<div id="water-impact" class="row no-gutters impact">
			<div class="col-md-6"></div>
			<div class="col-md-6 text-block p-3">
				<h2>{{bottles}}</h2>
				<h4>Plastic {{ bottles === 1? 'bottle': 'bottles'}} recovered from the ocean</h4>
				<p>When you complete tasks and earn achievements, you have a direct impact on the environment</p>
			</div>
		</div>
		<div class="d-flex flex-row justify-content-center mt-5">
			<b-button @click="$router.push('/total-mental-health/your-impact')" class="cta-button shadow">View Your Impact</b-button>
		</div>

	</div>
</template>
<script>
export default {
	name: "ImpactColumn",
	props : ['bottles', 'trees'],
};
</script>
<style lang="less">
	#impact-section {
		padding: 30px 15px 100px;
		position: relative;
		.cta-button{
			background: linear-gradient(to right, #985582, #d50870);
			width: 80%;
			max-width: 200px;
			border-radius: 15px;
  	}
		.impact {
			border-radius: 15px;
			overflow: hidden;
			box-shadow: 0px 0px 15px rgba(0,0,0,0.15);
	margin-bottom: 15px;
			h2 {
				text-align: left;
				font-weight: 600;
				font-family: FilsonProBold, sans-serif;
				color: white;
				margin: 0;
			}
			h4 {
				text-align: left;
				font-weight: bolder;
				color: white;
				margin-bottom: 10px;
				.text-light {
					font-weight: 400;
				}
			}
			p {
				color : white;
			}
		}
		#trees-impact {
			background: url('/img/totalMentalHealth/achievements/Trees.jpg') no-repeat center center;
			.text-block {
				background-color: rgba(67, 191, 153, 0.6);
			}
		}
		#water-impact {
			background: url('/img/totalMentalHealth/achievements/Water.jpg') no-repeat top center;
			.text-block {
				background-color: rgba(67, 92, 211, 0.36);
			}
		}
		a.more-impact-link {
			color: white;
			font-size: 1.2rem;
			line-height: 1;
			padding: 12.5px 15px;
			display: inline-block;
		}
	}
</style>