<template>
    <div id="not-found">
        <b-container>
            <b-row class="text-center align-items-center justify-content-center">
                <img src="/img/404.svg" alt="404 page not found"/>
                <h2 class="py-5">Something went wrong!<br> We can't seem to find the page you are looking for.</h2>
            </b-row>
        </b-container>
    </div>
</template>
<script>
    export default {
        name: 'notFound',
    }
</script>
<style lang="less">
    #not-found{

        width: 100%;
        height: 100vh;
        text-align: center;
        img{
            width: 100%;
            margin: auto;
        }
        @media (max-width: 768px){
            h2{
                font-size: 2.5rem;
            }
        }
    }
</style>