<template>
  <div id="wellbeing">
    <div class="page-header bg-transparent">
      <div class="content-container row">
        <div class="page-title pb-5 col-12">
          <div>
            <h2>{{$route.meta.title ? $route.meta.title : componentText.title}}</h2>
          </div>
        </div>
        <div class="search-bar col-12">
          <b-form-input
                  v-model="searchQuery"
                  type="text"
                  :placeholder="componentText.search">
          </b-form-input>
          <b-button-group class="filter-buttons row my-5" fluid>
            <b-button @click="listCourses('body')" :class="{active: filter==='body'}" class="filter-button filter-button-white px-2 px-md-5 py-3 mx-2 mx-md-3 my-2">{{componentText.body}}</b-button>
            <b-button @click="listCourses('life')" :class="{active: filter==='life'}" class="filter-button filter-button-white px-2 px-md-5 py-3 mx-2 mx-md-3 my-2">{{componentText.life}}</b-button>
            <b-button @click="listCourses('mind')" :class="{active: filter==='mind'}" class="filter-button filter-button-white px-2 px-md-5 py-3 mx-2 mx-md-3 my-2">{{componentText.mind}}</b-button>
            <b-button v-if="!$store.state.accessCode && $store.state.subdomain !== 'laya-wellbeing'" @click="listCourses('remote_working')" :class="{active: filter==='remote_working'}" class="filter-button filter-button-white px-2 px-md-5 py-3 mx-2 mx-md-3 my-2 remote">{{componentText.remote}}</b-button>
            <b-button v-if="!$store.getters.user.access_code_user && $store.state.subdomain !== 'laya-wellbeing'" @click="listCourses('active')" :class="{active: filter==='active'}" class="filter-button filter-button-white px-2 px-md-5 py-3 mx-2 mx-md-3 my-2">{{componentText.active}}</b-button>
            <b-button v-if="!$store.getters.user.access_code_user && $store.state.subdomain !== 'laya-wellbeing' && $store.getters.skin !== 13" @click="listCourses('recommended')" :class="{active: filter==='recommended'}" class="filter-button filter-button-white px-2 px-md-5 py-3 mx-2 mx-md-3 my-2">{{componentText.recommended}}</b-button>
            <b-button @click="listCourses('')" :class="{active: filter===''}" class="filter-button filter-button-white px-2 px-md-5 py-3 mx-2 mx-md-3 my-2">{{componentText.all}}</b-button>
          </b-button-group>
        </div>
      </div>
    </div>
    <div class="paths pb-5" :class="{'bg-transparent': skin === 0, 'bg-grey': skin !==0}">
      <div class="content-container row">
        <div class="section-header section-header-right">
          <div class="section-header-divider"></div>
          <h3 class="section-header-title brand-secondary">{{componentText.paths}}</h3>
        </div>
        <WellbeingCarousel class="col-12" :filter="filter" :searchQuery="searchQuery"></WellbeingCarousel>

        <a class="home-link border-brand-secondary" @click="$router.push({name: 'allCourses'})">{{componentText.explore}}</a>
      </div>
    </div>
    <div class="articles pb-5" :class="{'bg-white': skin === 0 || skin === 8 } ">
      <div class="content-container row">
        <div class="section-header section-header-right">
          <div class="section-header-divider"></div>
          <h3 class="section-header-title">{{componentText.articles}}</h3>
        </div>
        <div v-for="(article, index) in articles" class="col-12 col-sm-6 col-md-3 article-wrap">
          <a class="card" @click="selectArticle(article.post_id)">
            <div class="card-header bg-brand-primary">
              <img class="card-logo" :src="'/img/' + img_type[article.post_type]" />
            </div>
            <div class="card-body" ref="cardBody">
              <p class="card-title" ref="cardTitle">{{ article.post_type }}</p>
              <div class="card-text text-eclipse"><h5>{{ article.title }}</h5></div>
            </div>
          </a>
          <div v-if="!$store.state.user.access_code_user" :class="article.fav ? 'is-active ' : ''"
               @click="likeArticle(article.post_id, index)"
               class="card-heart">
          </div>
        </div>
        <a class="home-link border-brand-secondary" @click="exploreAll">{{componentText.seeAll}}</a>
      </div>

    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import config from "../config/constants";
import WellbeingCarousel from "../components/Wellbeing/WellbeingCarousel.vue"
export default {
  name: "wellbeing",
  components: {
      WellbeingCarousel
  },
  data() {
    return {
        searchQuery: '',
        filter: '',
        enrolled:0,
        reccommended: 0,
        articles:{},
        img_type: {
            video: "video-white.svg",
            text: "blog-white.svg",
            image: "far fa-image",
            audio: "podcast-white.svg"
        },
    };
  },
  mounted() {
      this.getArticles()
  },
  methods: {
      async getArticles(){
          const payload = {
              user_id: this.$store.getters.user_id,
              search_key: '',
              tag: 'all',
              filter: '',
              fav_only: '',
              page: 0,
              page_size: 8,
              surge_only: 1
          }
          let res = await axios.post(config.api_env + "/application/api-hc/get-all-resource-list",
              payload, config.options)
          if(res.data.success) {
              this.articles = res.data.resources
          }else if(res.data.err && res.data.err.includes('3994')){
              this.$store.dispatch('logout')
              this.$router.push("/login").catch(err => {});
          }
      },
      async likeArticle(articleId, index){
          const payload = {
              user_id: this.$store.getters.user_id,
              type: 'module',
              resource_id: articleId
          }
          if(!this.articles[index].fav){
              this.articles[index].fav = 1
              let res = await axios.post(config.api_env + "/application/api-hc/like-item", payload, config.options)
              if(!res.data.success) {
                  this.articles[index].fav = 0
              }
          }else{
              this.articles[index].fav = 0
              let res = await axios.post(config.api_env + "/application/api-hc/unlike-item", payload, config.options)
              console.log(res.data)
              if(!res.data.success) {
                  this.articles[index].fav = 1
              }
          }
      },
      selectArticle(articleId){
          this.$router.push('/wellbeing/article/'+ articleId).catch(err => {})
      },
      async listCourses(filter){
          this.filter = filter
      },
      exploreAll(){
          this.$router.push('/wellbeing/articles').catch(err => {})
      }
  },
    computed:{
        skin(){
            return this.$store.getters.skin
        },
      componentText() {
        return this.getText.loggedIn.wellbeing;
      },
    }
};
</script>
<style lang="less">
  #wellbeing{
    .page-header {
      padding: 60px 0 0 0 ;
    }
    .page-title {
      text-align: left;
      h2 {
        text-align: left;
        font-size: 3.8rem;
        letter-spacing: 0.7rem;
        font-family: "FilsonProBold", sans-serif;
        color: #fff;
        text-transform: capitalize;
      }
      .image-wrap {
        display: flex;
        img {
          justify-self: center;
          width: 75%;
          margin: auto;
          @media only screen and (max-width: 992px){
            width: 100%;
          }
        }
      }
    }
    .search-bar{
      input {
        border-radius: 25px;
        height: 50px;
        font-size: 2.4rem;
        font-family: "DMSans", sans-serif;
        padding: 0 30px 5px 30px;
      }
    }
    .filter-buttons{
      width: 100%;
      margin: auto;
      font-family: DMSans;
      justify-content: center;
      .filter-button{
        font-family: DMSans;
        flex-grow: 0;
      }
    }
    .paths{
      .section-header-title{
        color: #fff;
      }
      .section-header-divider{
        opacity: 0.5;
      }
    }
    .articles{
      .article-wrap{
        position: relative;
        .card-heart{
          background-image: url('/img/heart-line_white.svg');
          position: absolute;
          width: 30px;
          height: 26px;
          top: 15px;
          right: 30px;
          background-size: cover;
          cursor: pointer;
          &.is-active{
            background-image: url('/img/heart_white.svg');
          }
        }
      }
      .card{
        position: relative;
        box-sizing: border-box;
        margin-bottom: 36px;
        transition: .3s ease-out;
        border: none;
        border-radius: 10px;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
        font-family: 'FilsonProLight', sans-serif;
        &:hover{
          cursor: pointer;
          text-decoration: none;
          box-shadow:2px 2px 15px rgba(0, 0, 0, 0.6);
        }
        .card-header{
          border-radius: 10px 10px 0 0;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 150px;
          .card-logo{
            max-height: 60%;
            max-width: 55%;
          }
        }
        .card-body{
          width: 100%;
          height: 110px;
          box-sizing: border-box;
          padding: 10px 10px 20px;
          background: #ffffff;
          text-align: center;
          border-radius: 0 0 10px 10px;
          .card-title{
            font-family: FilsonProLight, sans-serif;
            margin: 0;
            padding: 0 0 10px;
            font-size: 1.6rem;
            text-align: center;
            text-transform: uppercase;
            opacity: 0.5;
          }
          .card-text{
            text-align: center;
            white-space: normal;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            h5{
              font-family: FilsonProLight, sans-serif;
              font-size: 1.8rem;
              line-height: 1;
              padding: 0;
            }
          }
        }
      }
    }
  }
</style>
