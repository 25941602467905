<template>
  <div class="ImageParser mx-auto">
    <img class="img-fluid animate__animated animate__fadeIn" :src="content" />
  </div>
</template>

<script>
export default {
  components: {},
  name: "ImageParser",
  props: ["content", "options"],
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {

  },
};
</script>
<style lang="less">
.ImageParser {
  .img-fluid{
    min-width: 100%;
    border-radius: 24px;
    overflow: hidden;
  }
}
</style>
