<template>
  <div id="TotalMentalHealthStart" v-if="true">
    <CoachingHome v-if="view === 'coaching'"></CoachingHome>
    <EAPHome v-if="view === 'EAP'"></EAPHome>
    <CoachingEAPHome v-if="view === 'coachingEAP'"></CoachingEAPHome>
    <div class="mt-5 row" v-if="view === null">
      <b-spinner class="mx-auto brand-primary"></b-spinner>
    </div>
  </div>
</template>

<script>
import CoachingHome from "../components/TotalMentalHealth/CoachingHome.vue";
import EAPHome from "../components/TotalMentalHealth/EAPHome.vue";
import CoachingEAPHome from "../components/TotalMentalHealth/CoachingEAPHome.vue";

export default {
  components: {
    CoachingHome,
    EAPHome,
    CoachingEAPHome,
  },

  name: "TotalMentalHealthStart",
  mounted() {
    if (this.$store.getters.user.coach !== false && this.$store.getters.user.coach !== undefined)
      this.$router.push("/total-mental-health/");
  },
  data() {
    return {
      view: "coachingEAP",
    };
  },
};
</script>
<style lang="less">
#TotalMentalHealthStart {
  background: #f8f8f8;
}
</style>
