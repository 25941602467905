<template>
	<div id="redeem_voucher_component" class="content" :class="status">
		<div v-if="status === 'input'" key="input" class="animate__animated animate__fadeIn">
			<h4>Redeem Voucher</h4>
			<p>You can redeem your voucher<br/> by entering the code in the box below</p>
			<br/>
			<div id="form-container" class="input-group mb-4">
				<input type="text"
				       class="form-control"
				       id="voucher-input"
				       :placeholder="examplePlaceholder"
				       :aria-label="examplePlaceholder"
				       v-model="voucherInput"
				>
				<div @click="redeemVoucher" class="input-group-append">
					<b-button variant="cta" class="filson-book" :disabled="!canBeClicked">Redeem Voucher</b-button>
				</div>
			</div>
		</div>
		<div v-else-if="status === 'loading'" key="loading" class="animate__animated animate__fadeIn">
			<h4>Loading</h4>
			<br/>
			<br/>
			<br/>
			<br/>
			<div class="px-5">
				<i class="fas fa-spinner-third fa-spin"></i>
			</div>
			<br/>
			<br/>
			<br/>
			<br/>
		</div>
		<div v-else-if="status === 'success'" key="success" class="animate__animated animate__fadeIn">
			<h4>Success</h4>
			<p>Your voucher has been redeemed<br/> and {{getCurrencyPriceString(voucher_currency, voucher_amount, true)}} has been added to your wallet</p>
			<i class="fas fa-check-circle"></i>
			<br/>
			<b-button class="cta-button px-5 mt-5" @click="closeModal">Done</b-button>
		</div>
		<div v-else key="failed" class="animate__animated animate__fadeIn">
			<h4>Failed</h4>
			<p>{{message}}</p>
			<i class="fas fa-times-circle"></i>
			<br/>
			<b-button class="cta-button px-5 mt-5" @click="startAgain">Done</b-button>
		</div>
	</div>
</template>
<script>
import axios from "axios";
import config from "../../config/constants";

export default {
	components: {
	
	},
	name: "RedeemVoucher",
	data() {
		return {
			examplePlaceholder : 'Eg. 436E01Y2V5AV',
			voucherInput: '',
			status: 'input', // [success, failed, loading, input],
			message: '',
			voucher_currency : null,
			voucher_amount : null
		};
	},
	mounted() {

	},
	beforeDestroy() {
	
	},
	computed: {
		canBeClicked() {
			return this.voucherInput.length >= 12;
		},
	},
	methods: {
		startAgain(){
			this.status = 'input'
		},
		closeModal() {
			let self = this
			setTimeout(() => {
				self.status = 'input';
				self.voucherInput = '';
			}, 100)
			this.$bvModal.hide("redeem_voucher_modal");
		},
		async redeemVoucher() {
			if(!this.canBeClicked){
				return
			}
			this.status = 'loading'
			const payload = {
				user_id: this.$store.getters.user_id,
				voucher_code: this.voucherInput
			};
			let res = await axios.post(
				config.api_env + "/application/api/u/wallet/redeem-voucher",
				payload,
				config.options
			);
			if (res.data.success) {
				this.message = res.data.message
				this.voucher_amount = res.data.amount
				this.voucher_currency = res.data.currency
				this.status = 'success'
				// this.close_function()
			} else {
				this.status = 'failed'
        this.message = res.data.message
			}
		},
	},
	props : ['close_function'],
};
</script>
<style lang="less">
#redeem_voucher_modal {
	text-align: center;
	box-shadow: 0px 0px 100px rgba(0,0,0,0.5) inset;
	.modal-body {
		color: rgba(56, 28, 82, 0.8);
	}
}
#redeem_voucher_component.content{
	padding: 80px 30px 50px;
	min-height: 36rem;
	h4 {
		font-size: 2rem;
		font-family: "FilsonProBold", sans-serif;
	}
	p {
		font-size: 14px;
		padding: 20px 50px;
	}
	i {
		font-size: 70px;
	}
	&.success i {
		color: forestgreen;
	}
	&.failed i {
		color: red;
	}
	#form-container {
		padding: 15px 50px;
		.form-control, .input-group-text {
			font-size: 14px;
			background-color: #e9ecef;
		}
		input.form-control {
			padding: 20px;
			text-transform: uppercase;
		}
		.input-group-append {
			.input-group-text {
				padding-left: 20px;
				padding-right: 20px;
				border-radius: 0px 15px 15px 0px;
				transition: all 0.1s ease-in-out;
			}
		}
		
		.input-group-append.can-be-clicked {
			.input-group-text {
				background-color: #E83B75 !important;
				color: white;
			}
			&:hover {
				cursor: pointer;
			}
		}
		
	}
}
</style>
