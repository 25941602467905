<template>
    <div id="article" class="bg-grey">
        <div class="article-header bg-brand-secondary row mx-0">
            <div class="col-sm-12 col-md-4 px-0">
                <div class="article-header-img"></div>
            </div>
            <div class="col-0 col-md-4 px-0">
            </div>
            <div class="col-sm-12 col-md-4 px-0">
                <div class="article-info">
                    <p>{{componentText.chapter}} {{article.chapter}} {{componentText.of}} <a @click="navCourse(article.course_id)">{{article.course_name}}</a></p>
                    <div class="row article-info-text px-md-0 mx-0 px-sm-3">
                        <div class="col-1 px-0">
                            <img class="article-info-icon" src="/img/eye-white.svg">
                        </div>
                        <div class="col-11 pb-5 px-0 pb-md-0">
                            <p>{{componentText.reviewed}} {{article.author_name}}, {{article.author_title}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="article-title row mx-0">
            <div class="col-sm-12 col-md-4" ></div>
            <div class="col-sm-0 col-md-4 article-title-box">
                <div class="article-title-box-content border-brand-secondary">
                    <h2 v-if="article.module" class="brand-secondary">{{article.module.title}}</h2>
                </div>
            </div>
            <div class="col-sm-12 col-md-4" ></div>
        </div>
        <div class="bg-grey">
            <div class="content-container row pb-5">
                <div class="wellbeing-nav col-12 brand-primary my-4">
                    <a v-if="returnAvailable" @click="navWellbeing"><font-awesome-icon icon="chevron-left"></font-awesome-icon> {{componentText.back}}</a>
                </div>
                <div class="col-sm-12 col-md-8 offset-md-2 article-content-wrap" v-if="article.module.module_type === 'video'">
                    <!-- <VideoPlayer :src="article.module.asset_url"></VideoPlayer> -->
                  <GAVideoPlayer class="w-100" :video-class="'video'" :src="article.module.asset_url +'#t=2'" />
                </div>
                <div v-if="article.module.module_type === 'text'" class="col-lg-8 offset-lg-2">
                    <div class="article-content-text" v-html="article.module.content"></div>
                </div>
                <div class="col-md-12 module-image" v-if="article.module.module_type === 'image'">
                    <img :src="article.module.asset_url" alt="" width="400px">
                </div>
                <div class="col-lg-12 module-audio" v-if="article.module.module_type === 'audio'">
                    <audio controls class="audio-player">
                        <source :src="article.module.asset_url" type="audio/mpeg">
                    </audio>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import axios from "axios";
    import config from "../config/constants";
    import GAVideoPlayer from "../components/GAVideoPlayer.vue";
    export default {
        name: "Article",
        components: {
          GAVideoPlayer
        },
        data() {
            return {
                article:{
                    module:{}
                }
            };
        },
        mounted() {
            this.getModule()
        },
        methods: {
            async getModule(){
                const payload = {
                    user_id: this.$store.getters.user_id,
                    module_id: this.$route.params.id
                }
                const options = {
                    headers: {
                        "Content-type": "application/x-www-form-urlencoded; charset=utf-8"
                    },
                }
                let res = await axios.post(config.api_env + '/application/api-hc/get-course-module', payload, options)
                if(res.data.success) {
                    this.article = res.data
                }else if(res.data.err && res.data.err.includes('3994')){
                    this.$store.dispatch('logout')
                    this.$router.push("/login").catch(err => {});
                }
            },
            navCourse(courseId){
                this.$router.push('/wellbeing/' + courseId).catch(err => {})
            },
            navWellbeing(){
                this.$router.push('/wellbeing').catch(err => {})
            }
        },
        computed:{
            returnAvailable(){
                //hide return to wellbeing for clients who don't have it in nav
                if(JSON.stringify(this.$store.getters.nav).toLowerCase().includes('wellbeing')){
                    return true
                }else return false
            },
            componentText() {
                return this.getText.loggedIn.wellbeing.article;
            },
        }
    };
</script>
<style lang="less">
    #article {
        min-height: calc(100vh - 190px);
        .article-header{
            padding: 30px 50px;
        }
        .article-header-img {
            height: 100%;
            width: 100%;
            min-height: 150px;
            background: url('/img/place-holder.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain
        }
        .article-info{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            height: 100%;
            overflow: hidden;
            padding: 10px;
            margin-bottom: 0;
            font-size: 2.3rem;
            color: #ffffff;
            font-family: 'DMSans', sans-serif;
            a{
                text-decoration: underline;
            }
            p{
                color: #ffffff;
            }
            .article-info-text{
                margin-top: 10px;
                margin-bottom: 0;
                color: #ffffff;
                font-size: 1.5rem;
                font-family: 'DMSans', sans-serif;
                width: 100%;
                .article-info-icon{
                    width: 18px;
                    height: 12px;
                    margin-right: 5px;
                    display: inline-block;
                }
            }
        }
        .video{
            border-radius: 20px;
            &:focus{
                outline: none;
            }
        }
        .module-audio{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-grow: 1;
            .audio-player{
                border: 1px solid;
                border-radius: 50px;
                &:focus{
                    outline: none;
                }
                @media (max-width: 767px) {
                    width: 100% !important;
                }
            }
        }
        .article-title{
            position: relative;
            height: 0px;
        }
        .article-title-box{
            padding: 15px;
            background-color: #fff;
            transform: translateY(-50%);
            margin: auto;
            @media (max-width: 767px) {
                min-width: 320px;
                max-width: 400px;
                min-height: 75px;
            }
            .article-title-box-content{
                border: 1px solid;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                padding: 10px;
                h2{
                    margin-bottom: 0;
                    text-align: center;
                    font-size: 27px;
                    font-family: 'DMSans', sans-serif;
                }
            }
        }
        .content-container{
            height: 100%;
        }
        .wellbeing-nav{
            padding-top: 50px;
            font-size: 1.6rem;
            font-family: DMSans
        }
        .article-content-text {
            margin-bottom: 30px;
            padding-right: 30px;
            padding-left: 30px;
            font-size: 1.6rem;
            color: #3a4961;
            font-family: 'DMSans', sans-serif;
        }
    }
</style>
