<template>
  <div id="Calendar" class="custom-calendar container">
    <div class="calendar-nav pb-3 d-flex flex-row brand-text mt-2">
      <div class="month">{{ currentMonth }}</div>
      <div class="prev">
        <div class="nav-button px-3" @click="prevMonth">
          <i class="fa fa-arrow-left brand-highlight-1"></i>
        </div>
      </div>
      <div class="next">
        <div class="nav-button px-3" @click="nextMonth">
          <i class="fa fa-arrow-right brand-highlight-1"></i>
        </div>
      </div>
    </div>
    <div v-if="loading">
      <b-spinner></b-spinner>
    </div>
    <div v-else-if="true">
      <div class="row flex-nowrap pb-3">
        <div v-for="(day, index) in days" :key="index" class="col day">
          {{ day }}
        </div>
      </div>
      <div class="row flex-nowrap pb-3" v-for="(row, i) in calendar" :key="i">
        <div
          class="col date-wrap p-2"
          :class="{
            'has-entry': entryDates.includes(
              getFormatedDate(date.fullDate, 'YYYY-MM-DD')
            ),
          }"
          v-for="(date, index) in row"
          :key="index"
        >
          <div
            class="date"
            :class="{
              'not-current': !date.currentMonth,
            }"
            @click="selectDate(date)"
            :ref="'ref-' + date.displayDate"
          >
            {{ date.displayDate }}
          </div>
          <div
            class="has-entry-sticker bg-brand-highlight-1"
            v-if="
              entryDates.includes(getFormatedDate(date.fullDate, 'YYYY-MM-DD'))
            "
          ></div>
          <div
            class="active bg-brand-secondary"
            v-if="selectedDate === formatDate(date.fullDate)"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "Calendar",
  props: {
    entryDates: Array,
    loading: Boolean,
  },
  data() {
    return {
      monthIndex: 0,
      days: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
      activeDate: "",
      selectedDate: null,
    };
  },
  mounted() {
    let self = this;

    this.$parent.selectMonth(
      moment()
        .add(self.monthIndex, "M")
        .startOf("month")
        .startOf("month")
        .format("YYYY-MM")
    );
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    selectDate(date) {
      this.activeDate = this.formatDate(date.fullDate);
      this.selectedDate = this.formatDate(date.fullDate);
      this.$parent.selectDate(date.fullDate);
    },
    nextMonth() {
      let self = this;
      this.monthIndex++;
      this.$parent.selectMonth(
        moment().add(self.monthIndex, "M").startOf("month").format("YYYY-MM")
      );
    },
    prevMonth() {
      let self = this;
      this.monthIndex--;
      this.$parent.selectMonth(
        moment()
          .add(self.monthIndex, "M")
          .startOf("month")
          .startOf("month")
          .format("YYYY-MM")
      );
    },
    getMoodForDate(date) {
      let entryDate = this.entryDates[this.getFormatedDate(date, "YYYY-MM-DD")];
      return entryDate === undefined || entryDate.length == 0
        ? ""
        : entryDate.mood_int;
    },
  },
  computed: {
    /**
     * TODO :: Sorted dates - original method was swapping days and months on certain dates messing up available slots.
     *         most recent version will allow users to book next day appts after 1pm on the last day of the month
     */

    sortedDates() {
      let dates = [];
      let self = this;
      for (const date in this.entryDates) {
        if (this.entryDates[date].length !== 0)
          dates.push(self.entryDates[date].entry_date);
      }
      return dates;
    },
    calendar() {
      let calendar = [];
      const startDay = moment()
        .add(this.monthIndex, "M")
        .startOf("month")
        .startOf("week");
      const endDay = moment()
        .add(this.monthIndex, "M")
        .endOf("month")
        .endOf("week");
      const month = moment().add(this.monthIndex, "M").month();
      let date = startDay.clone();
      if (date.date() === 1) date = startDay.clone().subtract(1, "week");
      while (date.isBefore(endDay, "day")) {
        calendar.push(
          Array(7)
            .fill(0)
            .map(() => {
              const day = date.add(1, "day").clone();
              return {
                fullDate: day,
                displayDate: day.format("DD"),
                currentMonth: month === day.month(),
              };
            })
        );
      }
      return calendar;
    },
    currentMonth() {
      return moment().add(this.monthIndex, "M").format("MMMM YYYY");
    },
    today() {
      return this.formatDate(moment());
    },
    tomorrow() {
      return moment().add(1, "days");
    },
  },
};
</script>

<style lang="less">
#Calendar {
  font-family: "FilsonProBold", sans-serif;

  .calendar-nav {
    justify-content: space-around;
    .month {
      flex-grow: 1;
      font-size: 1.6rem;
      font-family: "FilsonProBold", sans-serif;
    }
    .prev {
      cursor: pointer;
      font-size: 1.6rem;
    }
    .next {
      cursor: pointer;
      font-size: 1.6rem;
    }
  }
  .day {
    text-align: center;
    color: #bbb;
    font-size: 1.6rem;
    padding: 0;
    z-index: 2;
  }
  .not-current {
    color: #ddd;
  }
  .date {
    text-align: center;
    font-size: 1.4rem;
    position: relative;
    z-index: 10;
  }
  .date-wrap {
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 10;
    &.has-entry{
      color: white;
    }
  }
  .has-entry {
    .active {
      background-color: white !important;
    }
  }
  .active {
    position: absolute;
    bottom: 5px;
    height: 2px;
    width: 1.5rem;
    z-index: 10;
  }
  .has-entry-sticker {
    position: absolute;
    top: 0.2rem;
    height: 3rem;
    width: 3rem;
    border-radius: 2000px;
    z-index: 1;
  }
}
</style>
