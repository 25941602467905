<template>
    <div id="app-download" class="row ">
            <div class="col-12 col-md-7 ">
                <div class="download-info text-center">
                    <h2 class="pb-4">{{componentText.title}}</h2>
                    <p v-html="componentText.ios"></p>
                    <div class="location pt-5 mt-0 mt-md-5">
                        <p><font-awesome-icon icon="map-marker-alt"></font-awesome-icon> {{componentText.location}}</p>
                        <v-select v-model="location" :options="downloadOptions" :reduce="label => label.code" :selectable="option =>  option.available"></v-select>
                    </div>
                    <b-button @click="requestAppVoucher" :disabled="location === null" class="cta-button py-3 px-5 my-5">{{componentText.link}}</b-button>
                    <p v-if="emailSent">{{componentText.sent}} <font-awesome-icon icon="check" /></p>
                </div>
            </div>
            <div class="col-md-5  d-none d-md-block image-container text-right align-items-end pt-5 mt-5">
                <div class="image-wrap">
                    <img src="/img/boi/ios@4x.png" />
                </div>
            </div>
        <popup v-if="showPopup === true" :content="popupContent" v-on:close="hidePopup"></popup>
    </div>
</template>

<script>
    // @ is an alias to /src
    import axios from "axios";
    import config from "../config/constants";
    import popup from "../components/Popup.vue";
    export default {
        name: "AppDownload",
        components: {
            popup
        },
        data() {
            return {
                location: null,
                popupContent : {
                    header : '',
                    message : ''
                },
                showPopup : false,
                emailSent: false,
                downloadOptions:[
                    {
                        label: 'Ireland',
                        code: 'ie',
                        available: true
                    },
                    {
                        label: 'UK',
                        code: 'uk',
                        available: true
                    },
                    {
                        label: 'Germany',
                        code: 'de',
                        available: true
                    },
                    {
                        label: 'France',
                        code: 'fr',
                        available: true
                    },
                    {
                        label: 'Spain',
                        code: 'es',
                        available: true
                    },
                    {
                        label: 'USA',
                        code: 'us',
                        available: true
                    },
                ]
            };
        },
        mounted() {
        },
        methods: {
            async requestAppVoucher() {
                const payload = {
                    user_id: this.$store.getters.user_id,
                    region: this.location
                };
                let res = await this.axios.post(config.api_env + "/application/api/redeem/enterprise-app-voucher", payload, config.options);

                if(res.data.success === true){
                    //TODO update popup content
                    this.popupContent = {
                        header : this.componentText.popupTitle,
                        message : this.componentText.popupMessage,
                        subtext: this.componentText.subtext
                    };
                    this.showPopup = true
                    this.emailSent = true
                } else {
                    this.popupContent = {
                        header : this.componentText.error,
                        message : this.componentText.errorMessage,
                    };
                    this.showPopup = true
                }
            },
            hidePopup(){
                this.showPopup = false;
            }
        },
        computed:{
            componentText() {
                return this.getText.loggedIn.appDownload;
            },
        }
    };
</script>
<style lang="less">
    #app-download {
        position: relative;
        background: #fff;
        flex: 1;
        font-size: 1.6rem;
        font-family: "DMSans", sans-serif;
        justify-content: center;
        align-items: center;
        height: calc(100vh - 200px);
        overflow-y: scroll;
        .bg-white{
            background-color: #fff !important;
        }
        .download-info {
            background-color: #fff;
            h2 {
                //text-align: left;
                font-size: 4.5rem;
                font-family: FilsonProLight, sans-serif;
            }
            p{
                font-size: 1.8rem;
            }
            .location{
                max-width: 50%;
                margin: auto;
            }
        }
        .image-container {
            .image-wrap {
                max-width: 250px;
                width: 100%;

                img {
                    width: 100%;
                }
            }
        }
        /*@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {*/
            /*background-color: #fff;*/
            /*height: calc(100vh - 200px);*/
            /*width: 100%;*/
            /*overflow: hidden;*/
            /*.background-panel{*/
                /*display: none;*/
            /*}*/
            /*.content-container{*/
                /*background-color: transparent;*/
                /*left: 20%;*/
                /*top: 10%;*/
            /*}*/
        /*}*/
        @media only screen and (max-width: 768px) {
            .download-info {
                max-width: 480px;
                padding: 0px 5px;
                h2 {
                    text-align: center;
                    font-size: 4rem;
                }
            }
        }
    }
</style>
