<template>
    <div class="calendar">
        <div class="calendar-nav col-12 pb-5">
            <div class="col-2">
                <a @click="calendarPrevWeek"><font-awesome-icon icon="chevron-left"></font-awesome-icon></a>
            </div>
            <div class="col-8 calendar-week">
                {{calendar.calendarWeek}}
            </div>
            <div class="col-2 calendar-next">
                <a @click="calendarNextWeek"><font-awesome-icon icon="chevron-right"></font-awesome-icon></a>
            </div>
        </div>
        <div class="chart-wrap col-12" v-if="!noPoints">
            <div class="chart-labels pt-3">
                <p v-for="(day, i) in getText.global.days" :key="i" class="pb-1 pr-3 text-center"
                   :class="{'cta-button': today === i && weekIndex === 0, 'text-white' : today !== i || weekIndex !== 0}">
                    {{day}}
                </p>
            </div>
            <HorizontalBarChart  v-if="dailyPoints.length > 0"
                                 :chartData="chartData"
                                 :options="chartOptions"
                                 :styles="chartStyles"
                                 :plugins="chartPlugins"
                                 :class="{noPoints: noPoints}"
            >
            </HorizontalBarChart>
        </div>
        <div class="nothing-logged py-5" v-else>
            <div class="img-wrap">
                <img src="/img/nothing-logged.svg">
            </div>
            <h3>{{getText.loggedIn.fitness.nothingLogged}}</h3>
        </div>
    </div>
</template>
<script>
    import axios from "axios";
    import config from "../../config/constants";
    import moment from "moment"
    import HorizontalBarChart from "../HorizontalBarChart.vue"
    import {mapGetters} from "vuex"
    export default {
        name: "RewardsChart",
        components: {
            HorizontalBarChart
        },
        data() {
            return {
                totalPoints: null,
                dailyPoints: [],
                calendar: {
                    calendarWeek: '',
                    calendarDates: [],
                    calendarFullDates: [],
                    weekStart: 1,
                    weekEnd: 7,
                },
                weekIndex: 0,
                hasPoints: false,
                days: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
                engagementExplanation: '',
                showExplanationPopup: false,
                chartOptions: {
                    responsive: true,
                    maintainAspectRatio: false,
                    showAllTooltips: true,
                    tooltips: {
                        yAlign: 'right',
                        xAlign: 'left',
                        backgroundColor: 'transparent',
                        callbacks: {
                            label: function (tooltipItem, data) {
                                const { datasets } = data;
                                const currentIndex = tooltipItem.index;

                                return `${datasets[0].data[currentIndex]} pts`;
                            },
                            title: function (tooltipItem){
                                return '';
                            }
                        }
                    },
                    layout: {
                        padding: {
                            left: 0,
                            right: 50,
                            top: 0,
                            bottom: 0
                        }
                    },
                    legend: {
                        display: false
                    },
                    scales: {
                        xAxes: [{
                            gridLines: {
                                color: '#fff',
                                display: true,
                                drawOnChartArea: false,
                                drawTicks: false,
                            },
                            ticks: {
                                display: false
                            },
                        }],
                        yAxes: [{
                            gridLines: {
                                color: '#fff',
                                display: true,
                                drawTicks: false,
                                drawOnChartArea: false
                            },
                            ticks: {
                                mirror: true,
                                padding: 50
                            },
                        }]
                    },
                    annotation: {
                        annotations: []
                    },
                },
                chartStyles:{
                    height: '300px',
                    width: '100%',
                    position: 'relative',
                    defaultFontColor: '#000'
                },
                chartPlugins:[{
                    beforeRender(chart) {
                        if (chart.config.options.showAllTooltips) {
                            // create an array of tooltips
                            // we can't use the chart tooltip because there is only one tooltip per chart
                            chart.pluginTooltips = [];
                            chart.config.data.datasets.forEach(function (dataset, i) {
                                chart.getDatasetMeta(i).data.forEach(function (sector, j) {
                                    chart.pluginTooltips.push(new Chart.Tooltip({
                                        _chart: chart.chart,
                                        _chartInstance: chart,
                                        _data: chart.data,
                                        _options: chart.options.tooltips,
                                        _active: [sector]
                                    }, chart));
                                });
                            });

                            // turn off normal tooltips
                            chart.options.tooltips.enabled = false;
                        }
                    },
                    afterDraw(chart, easing) {
                        if (chart.config.options.showAllTooltips) {
                            // we don't want the permanent tooltips to animate, so don't do anything till the animation runs atleast once
                            if (!chart.allTooltipsOnce) {
                                if (easing !== 1)
                                    return;
                                chart.allTooltipsOnce = true;
                            }
                            // turn on tooltips
                            chart.options.tooltips.enabled = true;
                            Chart.helpers.each(chart.pluginTooltips, function (tooltip) {
                                tooltip.initialize();
                                tooltip.update();
                                // we don't actually need this since we are not animating tooltips
                                tooltip.pivot();
                                tooltip.transition(easing).draw();
                            });
                            chart.options.tooltips.enabled = false;
                        }
                    }
                }
                ]
            };
        },
        mounted() {
            this.initCalendar(this.calendar.weekStart, this.calendar.weekEnd)
        },
        methods: {
            async getWellnessPoints(start){
                const week = moment().isoWeekday(start).format('MM/DD/YYYY')

                const payload = {
                    user_id: this.$store.getters.user_id,
                    start_date: week
                }
                let res = await axios.post(config.api_env + '/application/api-hc/get-my-points', payload, config.options)
                this.totalPoints = res.data.total_points
                this.dailyPoints = [
                    res.data.daily_points.Monday,
                    res.data.daily_points.Tuesday,
                    res.data.daily_points.Wednesday,
                    res.data.daily_points.Thursday,
                    res.data.daily_points.Friday,
                    res.data.daily_points.Saturday,
                    res.data.daily_points.Sunday
                ]
                this.hasPoints = this.dailyPoints.some(points => points > 0)
            },
            initCalendar(start, end){
                this.getWellnessPoints(start)
                for(let i=0 ; i<7 ; i++)
                {
                    this.calendar.calendarDates[i] = moment().isoWeekday(start + i).format("DD")
                    this.calendar.calendarFullDates[i] = moment().isoWeekday(start + i).format("MM/DD/YYYY")
                }
                this.calendar.calendarWeek = moment().isoWeekday(start).locale(this.$store.state.lang).format("DD MMM") + ' - ' + moment().isoWeekday(end).locale(this.$store.state.lang).format("DD MMM")
                const today = moment().format("DD")
                this.calendar.selectedDate = this.calendar.calendarDates.findIndex(x => x === today)
            },
            calendarPrevWeek(){
                this.calendar.weekStart = this.calendar.weekStart - 7
                this.calendar.weekEnd = this.calendar.weekEnd - 7
                this.initCalendar(this.calendar.weekStart, this.calendar.weekEnd)
                this.weekIndex --
            },
            calendarNextWeek(){
                this.calendar.weekStart = this.calendar.weekStart + 7
                this.calendar.weekEnd = this.calendar.weekEnd + 7
                this.initCalendar(this.calendar.weekStart, this.calendar.weekEnd)
                this.weekIndex ++
            }
        },
        watch:{
            totalPoints(newPoints){
                this.$emit('total-points', newPoints)
            },
        },
        computed:{
            ...mapGetters(['hardcoded']),
            chartData(){
                return{
                    labels:
                        ["M", "T", "W", "T", "F", "S", "S"],
                    datasets: [
                        {
                            data: this.points,
                            backgroundColor: '#fff',
                            fontColor: '#fff',
                            showLine: true,
                            categoryPercentage: 0.5,
                            barPercentage: 0.2,
                        },
                    ]
                }
            },
            points(){
                return this.dailyPoints
            },
            noPoints(){
                return !this.dailyPoints.some(points => points > 0)
            },
            today(){
                return new moment().add(-1, 'days').day()
            },
        },
    }
</script>
<style lang="less">
    #Rewards {
        .chart-labels {
            height: 300px;
            .cta-button{
                border-radius: 50%;
                height: 30px;
                width: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                transform: translate( -5px, 5px);
                padding-right: 0 !important;
                font-weight: 800;
                font-family: Roboto, sans-serif;
            }
            p{
                transform: translateY( 5px);
            }
        }
        .noPoints {
            margin: auto;
            align-items: center;
            background-image: url('../../../public/img/nothing-logged.png');
            background-size: 70%;
            background-repeat: no-repeat;
            background-position: center;
            height: 300px;
            canvas {
                display: none !important;
            }
        }
    }

</style>