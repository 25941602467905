<template>
  <div id="NeurodiversityLandingPage">
    <div v-if="!initialAssessmentComplete || !initialBookingBooked">
      <div class="content-container" v-if="loaded">
        <div class="p-5">
          <b-row class="startSection">
            <b-col cols="12" lg="5" class="cardHolder p-4">
              <div v-if="!bookings.upcoming.length && !bookings.past.length">
                <AssessmentCard :currency="service.currency" :label="service.name" :price="service.price"/>
                <button v-if="intakeFormProgress !== 1" class="therapyBtn mx-auto mt-5" @click="gotoIntakeForm(true)">Complete Intake Forms</button>
                <button v-else class="therapyBtn mx-auto mt-5" @click="$router.push('/select/'+service.slug)">Book Now</button>
              </div>
              <div v-else>
                <h3 style="padding-top: 0">
                  Your booking
                </h3>
                <div v-if="!bookings.upcoming.length" class="noBookingMsg">
                  No upcoming bookings
                </div>
                <div v-for="(booking,i) in bookings.upcoming">
                  <TherapyBookingCard :booking="booking.booking" :service-provider="booking.service_provider" :is-earliest="i === 0"/>
                </div>
              </div>
            </b-col>
            <b-col cols="12" lg="7" class="textHolder p-4">
              <AssessmentText :service="service"/>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" lg="6" offset-lg="0" class="p-4">
              <div class="formHolder">
                <div class="innerText">
                  Intake Form <br>
                  <span @click="gotoIntakeForm(false)">{{intakeFormProgress === 1 ? 'Click here to review':'Click here to complete' }}</span>
                </div>
                <div class="checkHolder" :class="{incomplete: intakeFormProgress !== 1}">
                  <i class="fa-solid fa-circle-check" v-if="intakeFormProgress === 1"></i>
                  <i class="fa-solid fa-circle-exclamation" v-else></i>
                  {{ getFormText(intakeFormProgress) }}
                </div>
              </div>
            </b-col>
            <b-col cols="12" lg="6" offset-lg="0" class="p-4">
              <div class="formHolder">
                <div class="innerText">
                  Agreement and Consent Form <br>
                  <span @click="goToConsentForm">Click here to review</span>
                </div>
                <div class="checkHolder">
                  <i class="fa-solid fa-circle-check"></i>
                  Complete
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <div v-else class="position-relative topDiv">
      <div class="content-container" v-if="loaded">
        <div class="bookingDescriptionBox p-4 mt-5" v-if="!upcomingBookingScheduled">
          <div><span>Booking Type:</span>  Neurodiversity Assessment </div>
          <div><span>Booking Status:</span> {{bookingStatus}}</div>
          <div><span>Booking Date & Time:</span> Not Confirmed</div>
        </div>
        <div v-else>
          <div class="px-5">
            <h3 style="padding-top: 0" class="mt-5">
              Your booking
            </h3>
            <div v-for="(booking,i) in bookings.upcoming">
              <TherapyBookingCard :booking="booking.booking" :service-provider="booking.service_provider" :is-earliest="i === 0"/>
            </div>
          </div>

        </div>

        <template v-if="!allAdditionalFormsCompleted && !upcomingBookingScheduled">
          <h4 class="text-center" >Complete All Forms Below</h4>
          <p style="max-width: 100%">
            Your next session will be your Neurodiversity assessment. Before we can schedule a time for that session, we kindly ask you to complete the provided questionnaires below. Once you have finished this essential step, a dedicated member of our team will promptly reach out to coordinate the details of your assessment.
          </p>
          <p style="max-width: 100%; z-index: 100">
            We appreciate your cooperation and look forward to assisting you on this journey. If you require any assistance, do not hesitate to contact
            <a href="mailto:neurodiversityassessmentsbookings@spectrum.life">neurodiversityassessmentsbookings@spectrum.life</a>
          </p>
        </template>
        <template v-else-if="!upcomingBookingScheduled">
          <h4 class="text-center">You’re all done</h4>
          <p style="max-width: 100%; z-index: 100">
            Our team has been notified that you’ve completed all necessary steps. A member of our team will be in contact to schedule your appointment.
            We appreciate your cooperation and look forward to assisting you on this journey. If you require any assistance, do not hesitate to contact
            <a href="mailto:neurodiversityassessmentsbookings@spectrum.life">neurodiversityassessmentsbookings@spectrum.life</a>
          </p>
        </template>
        <b-row class="mt-5">
          <b-col cols="12" lg="6" offset-lg="0" class="p-4">
            <div class="formHolder">
              <div class="innerText">
                Intake Form <br>
                <span @click="gotoIntakeForm">{{intakeFormProgress === 1 ? 'Click here to review':'Click here to complete' }}</span>
              </div>
              <div class="checkHolder" :class="{incomplete: intakeFormProgress !== 1}">
                <i class="fa-solid fa-circle-check" v-if="intakeFormProgress === 1"></i>
                <i class="fa-solid fa-circle-exclamation" v-else></i>
                {{ getFormText(intakeFormProgress) }}
              </div>
            </div>
          </b-col>
          <b-col cols="12" lg="6" offset-lg="0" class="p-4">
            <div class="formHolder">
              <div class="innerText">
                Agreement and Consent Form <br>
                <span @click="goToConsentForm">Click here to review</span>
              </div>
              <div class="checkHolder">
                <i class="fa-solid fa-circle-check"></i>
                Complete
              </div>
            </div>
          </b-col>
        </b-row>
        <hr>
        <b-row class="mt-5" v-if="therapyType.age_group === 'Adult'">
          <b-col cols="12" lg="6" offset-lg="0" class="p-4" v-for="form in additionalFormsRequired">
            <div class="formHolder my-3">
              <div class="innerText">
                {{ form.form_name }} <br>
                <span @click="goToAdditionalForm(form.id)">{{form.progress === 1 ? 'Click here to review':'Click here to complete' }}</span>
              </div>
              <div class="checkHolder" :class="{incomplete: form.progress !== 1}">
                <i class="fa-solid fa-circle-check" v-if="form.progress === 1"></i>
                <i class="fa-solid fa-circle-exclamation" v-else></i>
                {{ form.form_text }}
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row v-else class="mt-5">
          <b-col cols="12" md="6">
            <p class="mx-auto" style="max-width: 450px; min-height: 50px">The below questionnaires need to completed by (or on behalf of) the child/young person:</p>
            <template v-for="form in additionalFormsRequired">
              <div class="formHolder my-3" v-if="form.id.charAt(form.id.length - 1) === 'c'">
                <div class="innerText">
                  {{ form.form_name }} <br>
                  <span @click="goToAdditionalForm(form.id)">{{form.progress === 1 ? 'Click here to review':'Click here to complete' }}</span>
                </div>
                <div class="checkHolder" :class="{incomplete: form.progress !== 1}">
                  <i class="fa-solid fa-circle-check" v-if="form.progress === 1"></i>
                  <i class="fa-solid fa-circle-exclamation" v-else></i>
                  {{ form.form_text }}
                </div>
              </div>
            </template>

          </b-col>
          <b-col cols="12" md="6">
            <p class=" mx-auto" style="max-width: 450px; min-height: 50px">The below questionnaires need to completed by the parent:</p>
            <template v-for="form in additionalFormsRequired">
              <div class="formHolder my-3" v-if="form.id.charAt(form.id.length - 1) === 'p'">
                <div class="innerText">
                  {{ form.form_name }} <br>
                  <span @click="goToAdditionalForm(form.id)">{{form.progress === 1 ? 'Click here to review':'Click here to complete' }}</span>
                </div>
                <div class="checkHolder" :class="{incomplete: form.progress !== 1}">
                  <i class="fa-solid fa-circle-check" v-if="form.progress === 1"></i>
                  <i class="fa-solid fa-circle-exclamation" v-else></i>
                  {{ form.form_text }}
                </div>
              </div>
            </template>
            <div class="formHolder my-3">
              <div class="innerText">
                School/college questionnaire<br>
                <span @click="goToSchoolForm">{{schoolQuestionnaireComplete ? 'Click here to review':'Click here to edit & submit' }}</span>
              </div>
              <div class="checkHolder" :class="{incomplete: !schoolQuestionnaireComplete}">
                <i class="fa-solid fa-circle-check" v-if="schoolQuestionnaireComplete"></i>
                <i class="fa-solid fa-circle-exclamation" v-else></i>
                {{ schoolQuestionnaireComplete ? "Complete":"Incomplete" }}
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
<!--    <NeurodiversityBookingFlow-->
<!--        class="d-none d-md-block mt-5" v-if="loaded"-->
<!--    />-->
  </div>
</template>

<script>
import EventBus from "@/helpers/eventbus";
import AssessmentText from "@/components/TherapyPortal/Misc/AssessmentText.vue";
import TherapyBookingCard from "@/components/TherapyPortal/TherapyBookingCard.vue";
import AssessmentCard from "@/components/TherapyPortal/Misc/AssessmentCard.vue";
import NeurodiversityBookingFlow from "./NeurodiversityBookingFlow.vue";
import forms from '../../../assets/therapyForms.json'
import moment from "moment";

export default {
  name: "NeurodiversityLandingPage",
  props: ['service'],
  components: {NeurodiversityBookingFlow, AssessmentCard, TherapyBookingCard, AssessmentText},
  data() {
    return {
      loaded: false,
      intakeFormProgress: null,
      initialAssessmentComplete: false,
      forms: forms,
      completed_user_forms: [],
      dob_of_supported_person: null,
      first_name_of_supported_person: null,
      last_name_of_supported_person: null,
      date_intake_form_completed: null,
      schoolQuestionnaireComplete: null,
      allAdditionalFormsCompleted: false,
      bookings: {
        upcoming: [],
        past: []
      }
    }
  },
  mounted() {
    this.getAgreement();
    this.getData();
    this.getIntakeForm();
    EventBus.$on('updateChmBookings', ()=> {
      this.getData();
    });
  },
  computed: {
    initialBookingBooked() {
      return this.bookings.past.length + this.bookings.upcoming.length > 0;
    },
    cardDetails() {
      return this.getTherapyDetailsFromSlug(this.$route.params.slug);
    },
    upcomingBookingScheduled() {
      return this.bookings.upcoming.length > 0;
    },
    age() {
        if(!this.date_intake_form_completed || !this.dob_of_supported_person || this.therapyType.age_group === 'Adult') return null
        const formCompletedDate = moment(this.date_intake_form_completed, "YYYY-MM-DD HH:mm:ss");
        const dob = moment(this.dob_of_supported_person, "DD-MM-YYYY");
        return formCompletedDate.diff(dob, 'years');
    },
    bookingStatus() {
      if(!this.allAdditionalFormsCompleted) return 'Requested - Questionnaires required (see below)';
      if(!this.bookings.upcoming.length) return 'Requested';
      return 'Booked'
    },
    additionalFormsRequired() {
      const formNames = this.cmhAdditionalFormNames(this.therapyType.age_group, this.age)
      const forms = [];
      formNames.forEach(formName => {
        const progress = this.findAdditionalFormProgress(formName);
        forms.push({form_name: this.formatFormName(formName), form_text: this.getFormText(progress), progress: progress, id: formName});
      });
      return forms;
    },
    therapyType() {
      return this.getTherapyFromSlug(this.$route.params.slug);
    },
    intakeFormName() {
      return 'intake_'+this.therapyType.area.toLowerCase()+'_'+this.therapyType.age_group.toLowerCase();
    },
  },
  methods: {
    parseAppointmentTime(date) {
      return {
        day: date.substring(0,10),
        time: date.substring(11,16)
      }
    },
    findAdditionalFormProgress(formName) {
      const i = this.completed_user_forms.findIndex(form => form.name === formName);
      if(i === -1) return 0;
      return this.completed_user_forms[i].progress;
    },
    formatFormName(name) {
      const underscoreIndex = name.indexOf('_');
      let formattedName = name.substring(0, underscoreIndex).toUpperCase();
      if(formattedName.includes('AQ10')) formattedName = 'AQ10';
      if(this.therapyType.age_group === 'Adult') {
        return formattedName;
      } else {
        return name.charAt(name.length - 1) === 'p' ? formattedName + ': For Parent' : formattedName + ': For Young Person';
      }
    },
    async getAllForms() {
      let params = {
        path: 'api/u/cmh/get-intake-forms',
      }
      const res = await this.api(params)
      if(res.success) {
        res.data.forEach(form => {
          if(form.form_name.substring(0,6) !== 'intake') {
            const data = JSON.parse(atob(form.content));
            const progress = this.determinePercentageOfFormComplete(form.form_name,data.questions);
            this.completed_user_forms.push({name: form.form_name, progress: progress});
          }
        });
      }
    },
    goToConsentForm() {
      this.m('agreement-form-review-button-clicked',{service: this.service.name})
      EventBus.$emit('toggleViewAgreement');
      //this.$router.push('/therapy-agreement')
    },
    goToAdditionalForm(name) {
      console.log(name)
      this.$router.push('/questionnaire/'+name+'?back='+this.$route.params.slug)
    },
    goToSchoolForm(name) {
      this.$router.push('/send-school-questionnaire?first_name='+this.first_name_of_supported_person+"&last_name="+this.last_name_of_supported_person)
    },
    getFormText(pcComplete) {
      if(pcComplete === 1) return 'Complete';
      if(!pcComplete) return 'Not Started';
      return 'In progress'
    },
    gotoIntakeForm(therapyButtonClicked = false) {
      if(!therapyButtonClicked) {
        this.m(this.intakeFormProgress === 1 ? 'intake-form-review-button-clicked' : 'intake-form-complete-button-clicked',{service: this.service.name})
      }
      EventBus.$emit('toggleIntakeForm');
      //this.$router.push('/therapy-form/intake')
    },
    async getAgreement() {
      const res = await this.api({
        path: 'api/u/cmh/get-agreement'
      });
    },
    async getIntakeForm() {
      let params = {
        path: 'api/u/cmh/get-intake-form',
        form_name: this.intakeFormName
      }
      const res = await this.api(params)
      if(res.success) {
        const data = JSON.parse(atob(res.data.content));
        this.date_intake_form_completed = res.data.created_on;
        if(data.questions.hasOwnProperty('personal-name-first')) this.first_name_of_supported_person = data.questions['personal-name-first'];
        if(data.questions.hasOwnProperty('personal-name-last')) this.last_name_of_supported_person = data.questions['personal-name-last'];
        if(data.questions.hasOwnProperty('personal-dob')) this.dob_of_supported_person = data.questions['personal-dob'];
        this.intakeFormProgress = this.determinePercentageOfFormComplete(this.intakeFormName,data.questions);
      }
    },
    async getData() {
      const initialAssessment = await this.api({path: 'api/u/cmh/can-access-nd-forms'});
      if(initialAssessment.can_access_nd_forms) {
        await this.getAllForms();
        this.initialAssessmentComplete = true;
      }
      if(initialAssessment.success) {
        this.schoolQuestionnaireComplete = initialAssessment.school_form_complete;
        this.allAdditionalFormsCompleted = initialAssessment.all_forms_are_complete
      }
      const res = await this.api({
        path: 'api/u/digital-bookings/get-cmh-bookings',
        slug: this.$route.params.slug
      })
      if(res.success) {
        this.bookings = {
          upcoming: res.bookings.upcoming,
          past: res.bookings.past
        }
      }
      this.loaded = true;
    }
  }
}
</script>

<style lang="less">
#NeurodiversityLandingPage {
  .noBookingMsg {
    font-size: 2rem;
  }
  .topDiv {
    background: url('/img/therapy/blob.svg') center/contain no-repeat;
  }
  .HomeBookingCard {
    margin-left: 20px !important;
  }
  background-color: transparent;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 200px);
  color: #3A4A61;
}
</style>