<template>
  <div id="ParticipantsDemo">
    <div class="top">
      Participants
    </div>
    <div class="item" v-for="(item, i) in participants">
      <img :src="item.image" />
      <div class="name">{{item.name}}</div>
      <i class="fa-solid fa-hand-sparkles" @click="setWave(i)" :class="{wave: wave === i}"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "ParticipantsDemo",
  data() {
    return {
      wave: -1,
      participants: [
        {name: 'Joe', image: 'https://spectrum-life-media.s3.eu-west-1.amazonaws.com/Test/training/pexels-david-william-beck-16229916+1.png'},
        {name: 'Katie', image: 'https://spectrum-life-media.s3.eu-west-1.amazonaws.com/Test/training/pexels-william-sutherland-11675896+1.png'},
        {name: 'Amy', image: 'https://spectrum-life-media.s3.eu-west-1.amazonaws.com/Test/training/pexels-andrea-piacquadio-733872+1.png'},
        {name: 'James', image: 'https://spectrum-life-media.s3.eu-west-1.amazonaws.com/Test/training/pexels-italo-melo-2379004+1.png'},
        {name: 'Chris', image: 'https://spectrum-life-media.s3.eu-west-1.amazonaws.com/Test/training/pexels-pixabay-220453+1.png'}
      ]
    }
  },
  methods: {
    setWave(i) {
      if(this.waveTimeout) clearTimeout(this.waveTimeout);
      this.wave = i;
      this.waveTimeout = setTimeout(()=> {
        this.wave = -1;
      }, 2000);
    }
  }
}
</script>


<style lang="less">
#ParticipantsDemo {
  @keyframes wave-animation {
    0% { transform: rotate( 0.0deg) }
    25% { transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
    50% { transform: rotate(-8.0deg) }
    100% { transform: rotate(0.0deg) }
  }
  .wave {
    animation-name: wave-animation;  /* Refers to the name of your @keyframes element below */
    animation-duration: 2.5s;        /* Change to speed up or slow down */
    animation-iteration-count: infinite;  /* Never stop waving :) */
    transform-origin: 70% 70%;       /* Pivot around the bottom-left palm */
    display: inline-block;
    color: #ED3A75 !important;
  }
  position: absolute;
  width: 190px;
  padding-bottom: 5px;
  bottom: 60px;
  color: white;
  left: 0;
  background-color: rgba(0,0,0,0.7);
  .top {
    font-size: 1.6rem;
    padding: 12px 15px;
    display: flex;
    align-items: center;
    font-family: FilsonProBold, Arial, Helvetica, sans-serif;
    border-bottom: #0079C8 1px solid;
    color: white;
    i {
      color: #0BDA51;
      font-size: 1.2rem;
      margin-right: 10px;
    }
  }
  .item {
    display: grid;
    padding: 15px 10px;
    grid-template-columns: 30px 1fr 30px;
    font-size: 1.2rem;
    border-bottom: 1px solid #FFFFFF80;
    img {
      border-radius: 50%;
    }
    i {
      font-size: 1.5rem;
    }
    .name {
      cursor: pointer;
      width: 100%;
      text-align: center;
      font-family: FilsonProBold, Arial, Helvetica, sans-serif;
      font-size: 1.6rem;
    }
  }
}
</style>