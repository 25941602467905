<template >
<div id="MyListCard" :class="{selected: selected}">
  <div  class="myListCard">
    <div class="image">
      <img :src="item.thumbnail">
      <div class="upcoming" v-if="upcoming && !selected">Upcoming</div>
      <div class="duration" v-if="runningTime">{{runningTime}}</div>
      <div class="deleteHolder" @click="deleteMyListItem"><i class="fa-solid fa-trash-can"></i></div>
    </div>

  </div>
  <div class="meta">
    <div class="title">{{item.title}}</div>

  </div>
  <div class="info" :class="{recipe: item.media_type === 'recipe'}">
    <b-progress v-if="progress !== false" :value="progress" :max="100" class="progressBar w-100">
    </b-progress>
    <div class="title">{{item.title}}</div>
    <div class="subtitle">{{item.subtitle}}</div>
    <div class="playContainer" v-if="!upcoming">
      <i class="fa-solid fa-circle-play playBtn" @click="seeMore"></i>
      <div class="text" @click="seeMore">{{playNowText}}</div>
    </div>
    <div v-else class="upcomingDate">{{eventStartText}}</div>
    <div v-if="upcoming" class="moreInfo" @click="seeMore">More Info <i class="fa-solid fa-circle-info infoIcon" ></i></div>
    <div class="description" v-html="item.description"></div>

  </div>
</div>
</template>

<script>
import moment from "moment";

export default {
  name: "MyListCard",
  methods: {
    async deleteMyListItem() {
      if(!this.favouritesCard) {
        const res = await this.$store.dispatch('deleteMyListItem',({id: this.item.id}));
        if(res) this.$emit('deleteMyListItem',this.item.id);
      } else {
        this.$emit('deleteMyListItem',this.item.id);
      }
    },
    seeMore() {
      const back = !this.favouritesCard ? '?back=my-list' : '?back=favourites'
      switch (this.item.product_id) {
        case 21:
          this.$router.push("/wellbeing-studio/"+this.item.content_id+back);
          break;
        case 3:
          this.$router.push("/recipe?rec="+this.item.content_id);
          break;
        case 22:
          this.$router.push("/digital-gym/"+this.item.content_id+back);
          break;
        case 4:
          this.$router.push("/wellbeing/"+this.item.content_id+back);
          break;
        case 17:
          this.$router.push("/be-calm/"+this.item.content_id+back);
          break;
        case 18:
          this.$router.push("/sound-space/"+this.item.podcast_id+"?play="+this.item.content_id);
          break;
        case 400:
          this.$router.push("/wellbeing/article/"+this.item.content_id);
          break;
      }
    },
  },
  props: {
    item: Object,
    selected: Boolean,
    favouritesCard: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    course_progress() {
      return this.$store.getters.user.user.course_progress;
    },
    progress() {
      const excludedProducts = [3,18];
      if(excludedProducts.includes(this.item.product_id)) return false;
      if(this.item.hasOwnProperty('progress') && this.item.progress > 0) return this.item.progress;
      const course_progress_data = this.course_progress;
      const channel = this.item.product_id === 21 ? 'ds' : 'dg';
      const i = course_progress_data.findIndex(item => item.channel === channel && item.id === this.item.content_id);
      if(i === -1) return false
      return course_progress_data[i].percent * 100;
    },
    eventStartText() {
      return moment(this.item.start).format("h:mma dddd, Do MMMM")
    },
    playNowText() {
      if(this.progress) return "Continue";
      if(this.item.media_type === 'video' || this.item.media_type === 'podcast') return 'Play Now';
      if(this.item.media_type === 'course') return 'Go to Course';
      return 'View Now';
    },
    runningTime() {
      let duration;
      if(!this.item.hasOwnProperty('duration')) return null;
      if(this.item.media_type === 'podcast' && this.item.duration.includes(':')) {
        return this.item.duration
      } else {
        duration = moment.utc(this.item.duration * 1000).format('HH:mm:ss')
      }
      return duration;
    },
    upcoming() {
      if(!this.item.hasOwnProperty('start')) return false;
      const start = moment(this.item.start);
      const now = moment();
      return now.isBefore(start);
    }
  }
}
</script>
<style lang="less">
#MyListCard {
  position: relative;
  &.selected {
    .myListCard {
      transform: scale(1.2);
      z-index: 300;
      transition: all .3s ease-in-out;
    }
    .info {
      height: fit-content;
      min-height: 50px;
      &.recipe {
        min-height: 40%;
      }
      padding: 5%;
      border-radius: 0 0 10px 10px;
      overflow: hidden;
      transform: scale(1.2);
      transition: transform .3s ease-in-out;
    }
    .deleteHolder {
     display: initial !important;
    }
  }
  .deleteHolder {
    display: none;
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: white;
    padding: 5px;
    cursor: pointer;
    border-radius: 5px;
    i {
      font-size: 1.5rem;
      color: darkred;
    }
  }
  .info {
    position: absolute;
    color: white;
    top: 95%;
    width: 100%;
    height: 0;
    transition: height 0.2ms linear;
    background-color: #3A3A3A;
    z-index: 301;
    overflow: hidden;

    .progressBar {
      position: absolute;
      top: 0;
      left: 0;
      height: 8px;
      z-index: 100;
      border-radius: 0 !important;
      background: grey;
      div {
        background-color: #0BDA51;
        border-radius: 0;
      }
    }
    .title {
      font-size: 1.2rem;
      font-family: FilsonProBold, Arial, Helvetica, sans-serif;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .playContainer {
      align-items: center;
      display: flex;
      .text {
        font-size: 1.4rem;
        font-family: FilsonProBold, Arial, Helvetica, sans-serif;
        cursor: pointer;
      }
      .playBtn {
        cursor: pointer;
        font-size: 30px;
        background-image: linear-gradient(90deg, #ED3A75 0%, #B150B1 100%);
        background-clip: text;
        color: transparent;
        margin-right: 30px;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
    .upcomingDate {
      margin-top: 10px;
      margin-bottom: 6px;
      font-size: 1.1rem;
      font-family: FilsonProBold, Arial, Helvetica, sans-serif;
    }
    .moreInfo {
      display: flex;
      justify-content: start;
      align-items: center;
      cursor: pointer;
      font-size: 1.5rem;
      font-family: FilsonProBold, Arial, Helvetica, sans-serif;
      margin-bottom: 10px;
      i {
        font-size: 1.5rem;
        margin-left: 20px;
      }
    }
    .description {
      margin-top: 5px;
      width: 100%;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      color: white !important;
      * {
        color: white !important;
        font-size: 1rem;
      }
    }
  }
  .meta {
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .title {
      font-size: 1.3rem;
      font-family: FilsonProBold, Arial, Helvetica, sans-serif;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .myListCard {
    position: relative;
    width: 100%;
    border-radius: 10px;
    aspect-ratio: 16/9;

    background-color: #3A3A3A;

    .upcoming {
      position: absolute;
      bottom: 10px;
      left: 10px;
      font-size: 1.2rem;
      background-color: #3A3A3A;
      color: white;
      padding: 3px 8px;
      border-radius: 4px;
    }
    .duration {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 1.2rem;
      background-color: white;
      color: black;
      padding: 3px 8px;
      border-radius: 4px;
    }
    .image {
      position: absolute;
      border-radius: 10px;
      overflow: hidden;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      img {
        height: 100%;
      }
    }

  }

}
</style>
