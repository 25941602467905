<template>
  <div class="dateRow mt-4" id="ChallengeDatePicker">
    <div class="dateUnit">
      <label class="desc">Start Date</label>
      <b-form-datepicker v-model="startDate" :min="start.min" :max="start.max"/>
    </div>
    <div class="dateUnit endDate">
      <label class="desc">End Date</label>
      <b-form-datepicker v-model="endDate" :min="end.min" :max="end.max"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChallengeDatePicker",
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0,0,0,0);
    this.tomorrow = tomorrow;
    const twoMonthsAgo = new Date(today);
    twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2);
    const aYearAhead = new Date(today);
    aYearAhead.setMonth(aYearAhead.getMonth() + 12);
    const twoWeeksAhead = new Date(today);
    twoWeeksAhead.setDate(twoWeeksAhead.getDate() + 14);
    return {
      startDate: null,
      endDate: null,
      start: {
        min: twoMonthsAgo,
        max: twoWeeksAhead,
      },
      end: {
        min: today,
        max: aYearAhead
      }
    }
  },
  watch: {
    startDate(value) {
      const start = new Date(value)
      start.setHours(0,0,0,0);
      const nextDay = new Date();
      nextDay.setDate(start.getDate() + 1);
      nextDay.setHours(0,0,0,0);
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      tomorrow.setHours(0,0,0,0);
      this.end.min = nextDay < tomorrow ? tomorrow : nextDay;
      if(this.endDate !== null) {
        const end = new Date(this.endDate);
        end.setHours(0,0,0,0)
        if(end <= start) this.endDate = null;
      }
    }
  }
}
</script>

<style lang="less">

#ChallengeDatePicker {

  align-items: center;
  justify-content: space-around;
  display: flex;
  .desc {
    font-size: 1.3rem;
  }
  .dropdown-menu {
    background: transparent;
  }
  .b-form-date-calendar {
    font-family: "DMSans", Arial, Helvetica, sans-serif;
    .b-calendar-inner {
      background-color: #292824;
    }
    .b-calendar-nav {
      .btn:not(.disabled) {
        color: white;
      }
    }
    .b-calendar-header {
      .form-control {
        border: none !important;
        background-color: #292824;
        color: white;
      }
    }
    .p-0 {
       .btn {
         padding: 0 !important;
         display: flex;
         justify-content: center;
         align-items: center;
       }
    }
    .b-calendar-grid {
      background-color: #333232 !important;
      border: 1px #6c757d solid !important;
      header, small, .text-dark:not(.disabled) {
        color: white !important;
      }
      .text-dark:hover {
        color: black !important;
      }
    }
    span  {
      &.disabled {
        color: #6c757d !important;
      }
    }
    .bg-light {
      background-color: #333232 !important;
    }
    .btn-outline-primary {
      color: #ED3A75!important;
      text-decoration: underline;
    }
    .btn-outline-light {
      border-color: #ED3A75!important;
    }
    .col .p-0 {
      border: none !important;
    }
    .btn {
      &.focus {
        border: none !important;
      }
      &.active {
        background: linear-gradient(90deg, #ED3A75 0%, #6A356A 100%)!important;

      }
    }
  }

  .dateUnit {
    width: 45%;
    .dropdown {
      header, small {
        color: black;
      }
      footer {
        display: none;
        .small {
          font-size: 1.2rem;
          color: white;
        }
      }
      output {
        font-size: 1.2rem;
      }
      background: black;
      label {
        color: white;
      }
      .h-auto {
        color: white;
      }
    }
    &.endDate {
      .dropdown-menu {
        left: -100px !important;
      }
    }
  }
}
</style>