<template>
  <div id="content" :class="{'bg-white': isWhitePage}">
    <transition name="fade" mode="out-in">
      <component @scroll-to="scrollTo" :is="currentContent" />
    </transition>
  </div>
</template>

<script>
import Home from "../pages/Home.vue";
import Wellbeing from "../pages/Wellbeing.vue";
import Mindfulness from "../pages/Minfulness.vue";
import EAP from "../pages/Eap.vue";
import Events from "../pages/Events.vue";
import Event from "../pages/Event.vue";
import WhatsHappening from "../pages/WhatsHappening.vue";
import FAQ from "../pages/FAQ.vue";
import Settings from "../pages/Settings.vue";
import Article from "../pages/Article.vue";
import AllArticles from "../pages/AllArticles.vue";
import Course from "../pages/Course.vue";
import AllCourses from "../pages/AllCourses.vue";
import Fitness from "../pages/Fitness.vue";
import Nutrition from "../pages/Nutrition.vue";
import Recipe from "../pages/Recipe.vue";
import Metrics from "../pages/Metrics.vue";
import Rewards from "../pages/Rewards.vue";
import Insights from "../pages/Insights.vue";
import WellnessBenefits from "../pages/WellnessBenefits.vue";
import Store from "../pages/Store.vue";
import OrderConfirmation from "../pages/OrderConfirmation.vue";
import AppDownload from "../pages/AppDownload.vue";
import DigitalClinics from "../pages/DigitalClinics";
import DigitalClinicsBookings from "../pages/DigitalClinicsBookings";
import Specsavers from "../pages/Specsavers";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TCs from "../pages/TCs";
import Redirect from "../pages/Redirect";
import BeCalm from "../pages/BeCalm";
import BeCalmCourse from "../pages/BeCalmCourse";
import MyFutureWellbeing from "../pages/MyFutureWellbeing";
import StudioOverview from "../pages/StudioOverview";
import WellbeingStudio from "../pages/WellbeingStudio";
import DigitalGym from "../pages/DigitalGym";
import TrainingPlan from "../pages/TrainingPlan";
import LayaWellbeingCourse from "../pages/LayaWellbeingCourse";
import LiveEvent from "../pages/LiveEvent";
import LiveEventTest from "../pages/LiveEventTest";
import WellbeingCourse from "../pages/WellbeingCourse";
import Pulse from "../pages/Pulse";
import SoundSpace from "../pages/SoundSpace";
import Podcast from "../pages/Podcast";
import BookingVideoRoom from "../pages/BookingVideoRoom";
import TotalMentalHealthHome from "../pages/TotalMentalHealthHome";
import TotalMentalHealthStart from "../pages/TotalMentalHealthStart";
import TotalMentalHealthSelectCoach from "../pages/TotalMentalHealthSelectCoach";
import TotalMentalHealthYourImpact from "../pages/TotalMentalHealthYourImpact";
import DigitalClinicsGeneric from "../pages/DigitalClinicsGeneric";
import DigitalClinicsSelectClinician from "../pages/DigitalClinicsSelectClinician";
import Community from "../pages/Community";
import CommunityPost from "../pages/CommunityPost";
import CommunityUser from "../pages/CommunityUser";
import Info from "../pages/Info";
import Faqs from "../pages/Faqs";
import Wallet from "../pages/Wallet";
import Blank from "../pages/Blank";
import CompanyBoard from "../pages/CompanyBoard";
import CompanyBoardPost from "../pages/CompanyBoardPost";
import WomensWellbeingStudio from "@/pages/WomensWellbeingStudio.vue";
import WomensDayLiveEvent from "@/pages/WomensDayLiveEvent.vue";
import GpHome from "../pages/GpHome";
import GpSelect from "../pages/GpSelect";
import OpenEmailBrowser from "../pages/OpenEmailBrowser";
import CourseV2 from "@/pages/CourseV2.vue";
import TrainingPlanV2 from "@/pages/TrainingPlanV2.vue";
import StudioEvent from "@/components/Wellbeing/StudioEvent.vue";
import MyProgress from "@/pages/MyProgress.vue";
import wellbeingStudioV2 from "@/pages/WellbeingStudioV2.vue";
import Customise from "@/components/admin/Customise.vue";
import CustomContent from "@/components/admin/CustomContent.vue";
import UserManagement from "@/components/admin/UserManagement.vue";
import DataAnalytics from "@/components/admin/DataAnalytics.vue";
import MyList from "@/pages/MyList.vue";
import Favourites from "@/pages/Favourites.vue";
import Cmh from "./CMH/Cmh.vue";

const mappings = {
  home: Home,
  totalMentalHealth: TotalMentalHealthHome,
  totalMentalHealthStart: TotalMentalHealthStart,
  totalMentalHealthSelectCoach: TotalMentalHealthSelectCoach,
  totalMentalHealthYourImpact: TotalMentalHealthYourImpact,
  bookingVideoRoom: BookingVideoRoom,
  iosAppDownload: Home,
  wellbeing: Wellbeing,
  mindfulness: Mindfulness,
  article: Article,
  allArticles: AllArticles,
  course: Course,
  wellbeingCourse: WellbeingCourse,
  layaCourse: LayaWellbeingCourse,
  allCourses: AllCourses,
  eap: EAP,
  events: Events,
  viewEvent: Event,
  viewService: Event,
  viewEventSeries: Event,
  viewWebinar: Events,
  digitalClinics: DigitalClinics,
  digitalClinicsBookings: DigitalClinicsBookings,
  whatsHappening: WhatsHappening,
  myOrganisation: WhatsHappening,
  fitness: Fitness,
  nutrition: Nutrition,
  recipe: Recipe,
  metrics: Metrics,
  rewards: Rewards,
  insights: Insights,
  wellnessBenefits: WellnessBenefits,
  wellbeingBenefits: WellnessBenefits,
  wellnessBenefitsOld: WellnessBenefits,
  faq: FAQ,
  settings: Settings,
  fitbit: Store,
  orderConfirmation: OrderConfirmation,
  download: AppDownload,
  specsavers: Specsavers,
  privacy: PrivacyPolicy,
  TCs: TCs,
  Redirect: Redirect,
  beCalm: BeCalm,
  beCalmCourse: BeCalmCourse,
  myFutureWellbeing: MyFutureWellbeing,
  wellbeingSeries: WellbeingStudio,
  digitalGym: DigitalGym,
  trainingPlan: TrainingPlan,
  virtualEvent: LiveEvent,
  virtualEventTest: LiveEventTest,
  digitalGymArticle: LiveEvent,
  pulse: Pulse,
  soundSpace: SoundSpace,
  podcast: Podcast,
  info: Info,
  community: Community,
  communityPost: CommunityPost,
  communityUser: CommunityUser,
  studioOverview: StudioOverview,
  faqs: Faqs,
  digitalClinicsGeneric: DigitalClinicsGeneric,
	digitalClinicsSelectClinician: DigitalClinicsSelectClinician,
	wallet: Wallet,
	blank: Blank,
  companyBoard: CompanyBoard,
  companyBoardPost: CompanyBoardPost,
  companyBoardCategory: CompanyBoard,
  womensDaySeries: WomensWellbeingStudio,
  womensDayEvent: WomensDayLiveEvent,
	gp: GpHome,
  gpSelect: GpSelect,
  myProgress: MyProgress,
  myList: MyList,
  customise: Customise,
  customContent: CustomContent,
  userManagement: UserManagement,
  dataAnalytics: DataAnalytics,
  challenges: Home,
  favourites: Favourites,
  cmh: Cmh,
  start: Cmh,
  serviceDashboard: Cmh,
  select: Cmh,
  therapyQuestionnaire: Cmh,
  sendSchoolQuestionnaire: Cmh,
  therapyAgreement: Cmh,
};
// the mappings below will overwrite the above mapping where NavigationV2 is being used as the nav
const navigationV2Mappings = {
  virtualEvent: StudioEvent,
  digitalGymArticle: StudioEvent,
  trainingPlan: TrainingPlanV2,
  course: CourseV2,
  beCalmCourse: CourseV2,
  layaCourse: CourseV2,
  wellbeingSeries: wellbeingStudioV2,

  gpSelect: GpSelect,
  OpenEmailBrowser: OpenEmailBrowser
};

export default {
  name: "ContentRouter",
  updated(){
    setTimeout(function(){ window.__vimeoRefresh(); }, 1000);
  },
  methods: {
    scrollTo(coordinates) {
      this.$emit("scroll-to", coordinates);
    },
  },
  computed: {
    navType() {
      return this.$store.getters.navType;
    },
    currentContent() {
      if(this.navType === 'versionTwo') {
        if(navigationV2Mappings.hasOwnProperty(this.$route.name)) {
          return navigationV2Mappings[this.$route.name]
        }
      }
      return mappings[this.$route.name];
    },
    isWhitePage(){
      let whitePages = ['digitalClinicsGeneric','digitalClinicsSelectClinician','wallet','soundSpace','podcast','bookingVideoRoom','totalMentalHealthSelectCoach', 'totalMentalHealth', 'companyBoard', 'wellbeingSeries', 'digitalGym', 'beCalm','myProgress','myList','favourites','gp', 'gpSelect', 'cmh', 'start', 'serviceDashboard', 'select', 'therapyQuestionnaire','sendSchoolQuestionnaire','therapyAgreement'];
      if(this.$store.getters.skin === 25) {
        whitePages = whitePages.concat(['home', 'wellbeing', 'nutrition', 'fitness', 'eap', 'rewards','customise','dataAnalytics','favourites'])
      }
      return whitePages.includes(this.$route.name)
    }
  },
};
</script>
<style lang="less">
#content {
  min-height: calc(100vh - 200px);
  display: flex;
  justify-content: center;
  & > div {
    width: 100%;
  }
}
.fade-enter-active {
  transition-duration: 0.5s;
  transition-property: opacity;
  transition-timing-function: ease-in;
}
.fade-leave-active {
  transition-duration: 0s;
  transition-property: opacity;
  transition-timing-function: ease-in;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.slide-fade-enter-active {
  transition: all 1s ease;
}
.slide-fade-leave-active {
  transition: all 0s ease;
}
.slide-fade-enter
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.slide-fade-leave-to {
  transform: translateX(-10px);
  opacity: 0;
}
</style>
