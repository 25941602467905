<template>
    <div id="ExerciseHistory" class="bg-grey py-5">
        <div v-if="history.count === '0'" class="content-container row py-5">
            <div class="col-12 col-md-8 no-history brand-secondary">
                {{componentText.nothingLogged}}
            </div>
            <div class="col-0 col-md-4">
                <img src="/img/fitness/fitness-placeholder.svg">
            </div>
        </div>
        <div class="content-container row py-5" v-else>
            <div class="col-12 py-3" v-for="(date,i) in history.data" :key="i">
                <div class="date-header px-4 py-2">
                    {{dates[i]}}
                </div>
                <div class="exercise-log col-12 pl-0">
                    <div class="marker-line"></div>
                    <div class="exercise-log-item row mx-0 pl-5 py-4" v-for="exercise in date.exercises">
                        <div class="col-8  pl-5">
                            <a @click="selectExercise(exercise.sur_exercise_plan_id)">{{exercise.exercise_name}}</a>
                        </div>
                        <div class="button-wrap col-4 text-right">
                            <b-button @click="deleteExercise(exercise.log_id)" class="button-delete mr-3"></b-button>
                            <b-button @click="duplicateExercise(exercise.log_id)" class="button-add cta-button"></b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ExercisePopup v-if="showPopup" :currentExercise="currentExercise" v-on:close="hidePopup"></ExercisePopup>
    </div>
</template>

<script>
    // @ is an alias to /src
    import axios from "axios";
    import config from "../../config/constants";
    import ExercisePopup from "./ExercisePopup.vue"
    import moment from "moment"
    export default {
        name: "ExerciseHistory",
        components: {
            ExercisePopup
        },
        props:{
        },
        data() {
            return {
                history:{},
                dates: [],
                showPopup: false,
                currentExercise: {}
            };
        },
        mounted() {
            this.getExercises()
        },
        methods: {
            async getExercises(){
                const payload = {
                    user_id: this.$store.getters.user_id,
                    limit: -1,
                    page_size: 999,
                    page: 0
                }
                let res = await axios.post(config.api_env + "/application/api-hc/get-my-exercises", payload, config.options)
                const today = new moment().format('DD-MM-YYYY')
                const yesterday = new moment().add(-1, 'days').format('DD-MM-YYYY')
                //reformat each of the dates
                if(res.data.success) {
                    this.history = res.data
                    this.dates = this.history.data.map(x => {
                        const date = new moment(x.date).format('DD-MM-YYYY')
                        if(date === today){
                            return this.componentText.today
                        }else if (date === yesterday){
                            return this.componentText.yesterday
                        }else return date
                    })
                }
            },
            async deleteExercise(id){
                const payload = {
                    user_id: this.$store.getters.user_id,
                    log_id: id
                }
                await axios.post(config.api_env + "/application/api-hc/delete-my-exercise", payload, config.options)
                this.getExercises()

            },
            async duplicateExercise(id){
                const payload = {
                    user_id: this.$store.getters.user_id,
                    log_id: id
                }
                await axios.post(config.api_env + "/application/api-hc/duplicate-my-exercise", payload, config.options)
                this.getExercises()
            },
            async selectExercise(id){
                const payload = {
                    user_id: this.$store.getters.user_id,
                    exercise_plan_id: id
                }
                let res = await axios.post(config.api_env + "/application/api-hc/get-surge-exercise", payload, config.options)
                if(res.data.success) {
                    this.currentExercise = res.data.exercise
                    this.showPopup = true;
                }
            },
            hidePopup(){
                this.showPopup = false
            }
        },
        watch:{

        },
        computed:{
            componentText() {
                return this.getText.loggedIn.fitness.exerciseHistory;
            },
        }
    };
</script>
<style lang="less">
    #ExerciseHistory{
        .no-history{
            font-family: 'FilsonProBold', sans-serif;
            font-size: 3rem;

        }
        .date-header{
            background: rgba(35, 31, 32, .05);
            font-family: 'DMSans';
            font-size: 1.8rem
        }
        .exercise-log{
            position: relative;
            .marker-line{
                width: 1px;
                height: 100%;
                background: rgba(0, 0, 0, .2);
                position: absolute;
                transform: translate(35px)
            }
            .exercise-log-item {
                font-size: 1.8rem;
                font-family: 'FilsonProLight', sans-serif;
                position: relative;
                border-bottom: 1px solid rgba(0, 0, 0, .2);
                &:last-child {
                    border-bottom-width: 0px;
                }
                &:before {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    content: '';
                    width: 12px;
                    height: 12px;
                    background-color: white;
                    border: 1px solid #7E4377;
                    border-radius: 50%;
                    transform: translate(30px, -50%);
                }
                .button-wrap {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    .button-delete, .button-add {
                        width: 22px;
                        height: 22px;
                        border-radius: 8px;
                        position: relative;
                        &:before {
                            width: 16px;
                            height: 2px;
                            position: absolute;
                            content: '';
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }
                    .button-add {
                        &:after {
                            position: absolute;
                            content: '';
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 2px;
                            height: 16px;
                        }
                    }
                    .button-delete:before {
                        background-color: #fff;
                    }
                }
            }
        }
    }
</style>
