<template>
  <div id="CommunityUser">
    <div class="w-100" style="background-color: #eee">
      <div class="content-container pt-5 back-button-container">
        <button class="btn-clear" @click="goBack()">
          <i class="fas fa-5x fa-chevron-left header-back"></i>
        </button>
      </div>
    </div>
    <section class="community-user-container h-100" v-if="!isLoadingUser">
      <div class="content-container row">
        <div
          id="user-avatar-container"
          class="user-avatar-container col-sm-12 col-md-3 px-3"
        >
          <img
            class="img-fluid user-avatar shadow"
            :src="user.image_url"
            v-if="user.image_url !== null"
          />
          <img
            class="img-fluid user-avatar shadow"
            :src="'https://eu.ui-avatars.com/api/?name=' + user.firstname + '+' + user.lastname"
            v-else
          />


          <!-- <div
            class="user-avatar-default shadow bg-brand-primary text-white"
            v-else
          >
            <span>{{ getInitials(user) }}</span>
          </div> -->
          <div class="pt-5 w-100 d-flex justify-content-center">
            <button
              class="btn cta-button px-5 py-3 mx-auto"
              v-if="ownUser.profile_id == $route.params.id"
              @click="openMediaModal()"
            >
              Upload <i class="fas fa-upload"></i>
            </button>
          </div>
        </div>
        <div id="user-meta-data-container"
                  class="user-meta-data-container col-sm-12 col-md-9 -3"
>
          <h1 class="user-meta-heading brand-text">
            Welcome <b>{{ user.firstname }}</b>
          </h1>
          <h3 class="input-header brand-text">Full name</h3>
          <div class="user-input-container">
            <input
              id="fullname"
              v-model="fullName"
              class="user-input"
              type="text"
            />
          </div>
          <h3 class="input-header brand-text">Title</h3>
          <div class="user-input-container">
            <input
              id="title"
              v-model="user.title"
              class="user-input"
              type="text"
            />
          </div>
          <h3 class="input-header brand-text">About You</h3>
          <div class="user-input-container">
            <input id="bio" v-model="user.bio" class="user-input" type="text" />
          </div>
          <button
            class="btn cta-button px-5 py-3 mt-5"
            v-if="ownUser.profile_id == $route.params.id"
            @click="updateUserProfile()"
          >
            Update User <i class="fas fa-upload"></i>
          </button>
        </div>
      </div>
      <NewAvatar v-bind:user="ownUser" v-if="mediaModalIsVisible"></NewAvatar>
    </section>
    <section class="w-100 d-flex flex-row justify-content-center pt-5" v-else>
      <b-spinner
        class="brand-primary posts-spinner mx-auto my-auto"
      ></b-spinner>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import config from "../config/constants";
import NewAvatar from "../components/Community/NewAvatar.vue";

export default {
  name: "CommunityUser",
  components: {
    NewAvatar,
  },
  data() {
    return {
      ownUser: null,
      user: null,
      fullName: null,
      isLoadingUser: true,
      mediaModalIsVisible: false,
    };
  },
  mounted() {
    this.getSelf();
  },
  methods: {
    async getSelf() {
      this.isLoadingSelf = true;
      const payload = {
        user_id: this.$store.getters.user_id,
        profile_id: false,
      };
      let res = await axios.post(
        config.api_env + "/application/api/u/community/get-user-profile",
        payload,
        config.options
      );
      if (res.data.success) {
        this.ownUser = res.data.profile;
        this.isLoadingSelf = false;
        this.getUserProfile();
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login").catch((err) => {});
      }
    },
    async getUserProfile() {
      this.isLoadingUser = true;
      const payload = {
        user_id: this.$store.getters.user_id,
        profile_id: this.$route.params.id,
      };
      let res = await axios.post(
        config.api_env + "/application/api/u/community/get-user-profile",
        payload,
        config.options
      );
      if (res.data.success) {
        this.user = res.data.profile;
        this.fullName =
          res.data.profile.firstname + " " + res.data.profile.lastname;
        this.isLoadingUser = false;
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login").catch((err) => {});
      }
    },
    async updateUserProfile() {
      let firstName, secondName
      let firstRun = true
      this.fullName.split(' ').forEach(function(name){
        if(firstRun){
          firstName = name;
        }else{
          secondName = secondName ? secondName + name + ' ' : name + ' ';
        }
        firstRun = false
      });
      console.log(firstName, secondName)
      secondName = secondName.trim();
      const payload = {
        user_id: this.$store.getters.user_id,
        profile: {
          firstname: firstName,
          lastname: secondName,
          bio: this.user.bio,
          title: this.user.title,
          image_url: this.ownUser.image_url,
        }
      };
      let res = await axios.post(
        config.api_env + "/application/api/u/community/update-user-profile",
        payload,
        config.options
      );
      if (res.data.success) {
        this.getUserProfile();
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login").catch((err) => {});
      }
    },
    removePost(id, index) {
      this.posts.splice(index, 1);
    },
    getInitials(user) {
      let first, last;
      first = user.firstname.charAt(0);
      last = user.lastname.charAt(0);
      return first + "." + last;
    },
    goBack() {
      this.$router.push("/community");
    },
    openMediaModal() {
      this.mediaModalIsVisible = true;
    },
    closeMediaModal() {
      this.mediaModalIsVisible = false;
    },
  },
  computed: {
    componentText() {
      return this.getText.loggedIn;
    },
  },
};
</script>
<style lang="less">
#CommunityUser {
  background-color: #eee;
  .community-user-container {
    background-color: #eee;
    padding-top: 80px;
    padding-bottom: 60px;
    .user-avatar {
      height: 260px;
      width: 260px;
      object-fit: cover;
      border-radius: 10000px;
    }
    .user-avatar-default {
      text-align: center;
      max-width: 260px;
      max-height: 260px;
      padding: 100px;
      font-size: 40px;
      border-radius: 10000px;
    }
    .user-meta-data-container {
      flex-grow: 1;
    }
    .user-meta-heading {
      font-size: 4rem;
    }
    .input-header {
      font-size: 2.6rem;
      padding-left: 10px;
      font-family: "DMSans", arial;
      margin-bottom: 0;
      padding-bottom: 15px;
      font-weight: bold;
    }
    .user-input-container {
      max-width: 85%;
      width: 100%;
      .user-input {
        overflow: auto;
        flex-shrink: 1;
        width: 100%;
        font-size: 1.6rem;
        outline: none;
        height: 50px;
        border: 1px solid lightsteelblue;
        border-radius: 12px;
        padding: 10px 10px 10px 10px;
        resize: none;
      }
    }
  }
  .spinner-container {
    height: 200px;
    margin-bottom: 20px;
    .posts-spinner {
      height: 50px;
      width: 50px;
    }
  }
  .btn {
    padding-top: 10px !important;
    padding-bottom: 15px !important;
  }
  .btn-clear {
    color: #ccc;
    border: none;
    background-color: transparent;
  }
  .btn-clear:focus {
    border: none;
    outline: none !important;
  }
}
</style>
