<template>
  <div id="CompanyBoardCategoryModal">
    <b-form-text id="">
      Title
    </b-form-text>
    <b-form-input v-model="newCategoryDetails.title" class="catNameInput" trim/>
    <div v-if="!nav_type_standard">
    <b-form-text id="">
      Description
    </b-form-text>
    <b-form-textarea v-model="newCategoryDetails.description" class="catDescInput"/>
    </div>
    <div class="mb-1" v-if="!nav_type_standard">
      <b-form-text id="">
        Image
      </b-form-text>
      <div v-if="file_errors.length > 0">
        <span v-html="r" class="small text-danger" v-for="(r, i) in file_errors" :key="i"></span>
      </div>
      <div v-else class="">
        <div class="image-preview text-center" v-if="file.length > 0">
          <img class="img-fluid" :src="file">
        </div>
        <div v-else class="image-preview text-center">
          <img class="img-fluid" :src="newCategoryDetails.image_url">
        </div>
        <div class="d-flex justify-content-center align-items-center mt-2">
          <input type="file" id="imgInp" ref="file" style="display: none;" @change="handleUpload" accept="image/jpg,image/png,image/jpeg">
          <label class="file-upload-label btn" for="imgInp">
            Select Image
          </label>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center mt-2">
      <b-button class="btn-purple btn-sm" @click="saveChanges">Save Category</b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanyBoardCategoryModal",
  props: ['newCategoryDetails','nav_type_standard'],
  data() {
    return {
      file_errors: [],
      file: {}
    }
  },
  methods: {
    handleUpload: function(event) {
      // Reference to the DOM input element
      let file_errors = [];
      this.file_errors = file_errors;
      let input = event.target;
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        // create a new FileReader to read this image and convert to base64 format
        let img = new Image();
        this.binary_file = input.files[0];
        img.src = window.URL.createObjectURL( input.files[0] );
        window.URL.revokeObjectURL(img.src);
        // Start the reader job - read file as a data url (base64 format)
        if(file_errors.length === 0){
          let reader = new FileReader();
          // Define a callback function to run, when FileReader finishes its job
          reader.onload = (e) => {
            // Read image as base64 and set to file
            if(e.target.result.size > 5120000){
              file_errors.push('File size should less then 5MB.');
            } else {
              this.file = e.target.result;
            }
          }
          reader.readAsDataURL(input.files[0]);
        }
        this.file_errors = file_errors;
      }
    },
    saveChanges() {
      let formData = new FormData();
      if(this.binary_file) {
        formData.append('file', this.binary_file);
      }
      formData.append('user_id', this.$store.getters.user.user_token);
      for(const [key,value] of Object.entries(this.newCategoryDetails)) {
          formData.append(key,value);
      }
      let url = process.env.VUE_APP_API_PATH + '/application/api/c/company-board/update-category';
      const opts = {
        method: 'post',
        body: formData
      }
      fetch(url, opts).then(response => {
            response.json().then(data => {
              if(data.success == true){
                this.$store.commit('setAlert', {
                  visible : true,
                  text: 'Updated',
                  color: 'success'
                });
                this.$emit('saved');
                this.$bvModal.hide('edit-category-modal')
              } else {
                this.$store.commit('setAlert', {
                  visible : true,
                  text: data.message,
                  color: 'danger'
                });
              }
            });
          }
      )
    },
  }
}
</script>

<style lang="less">
#CompanyBoardCategoryModal {
  h5 {
    font-size: 1.8rem;
  }
  font-size: 1.6rem;
  input {
    font-size: 1.75rem;
  }
  .catDescInput {
    min-height: 200px !important;
  }
}
</style>