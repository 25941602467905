<template>
    <div id="FoodDiary" class="bg-grey py-5">
        <div v-if="noFood.length === 0" class="content-container row py-5">
            <div class="col-12 col-md-8 no-history brand-secondary">
                {{componentText.nothingLogged}}
            </div>
            <div class="col-0 offset-1 col-md-3">
                <img src="/img/nutrition/nutrition-placeholder_1.svg">
            </div>
        </div>
        <div class="content-container row py-5" v-else>
            <div class="col-12 py-3" v-for="(date, i) in history.data" :key="i" v-if="date.logged_food.length > 0">
                <div class="date-header px-4 py-2">
                    {{dates[i]}}
                </div>
                <div class="exercise-log col-12 pl-0">
                    <div class="marker-line"></div>
                    <div class="exercise-log-item row mx-0 pl-5 py-4" v-for="food in date.logged_food">
                        <div class="col-6 pl-5">
                            <a @click="selectFood(food)">{{food.title}}</a>
                        </div>
                        <div class="col-6 col-sm-4 pl-5">
                           {{food.calories}} {{componentText.calories}}
                        </div>
                        <div class="button-wrap col-2 text-right d-none d-sm-flex">
                            <b-button @click="deleteLog(food.log_id, food.type)" class="button-delete mr-3"></b-button>
                            <b-button @click="duplicateLog(food.log_id, food.type)" class="button-add bg-brand-highlight-1"></b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showPopup" id="pop-up">
            <div class="pop-up-background" @click="hidePopup"></div>
            <div class="pop-up-content bg-brand-secondary row">
                <h4 class="col-12">{{selectedLog.title}}</h4>
                <div class="row col-12 log-info px-0 py-2">
                    <div class="col-9">{{componentText.fat}}</div>
                    <div class="col-3">{{selectedLog.fat}}</div>
                </div>
                <div class="row col-12 log-info px-0 py-2">
                    <div class="col-9">{{componentText.protein}}</div>
                    <div class="col-3">{{selectedLog.protein}}</div>
                </div>
                <div class="row col-12 log-info px-0 py-2">
                    <div class="col-9">{{componentText.carbs}}</div>
                    <div class="col-3">{{selectedLog.carbs}}</div>
                </div>
                <div class="row col-12 log-info px-0 py-2">
                    <div class="col-9">{{componentText.cals}}</div>
                    <div class="col-3">{{selectedLog.calories}}</div>
                </div>
                <div v-if="selectedLog.recipe_id > 0" class="col-12 pt-5">
                    <b-button class="cta-button px-5 py-2" @click="recipeNav(selectedLog.recipe_id)">{{componentText.goToRecipe}}</b-button>
                </div>
                <b-button class="close-popup" @click="hidePopup"><font-awesome-icon icon="times-circle"></font-awesome-icon></b-button>
            </div>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import axios from "axios";
    import config from "../../config/constants";
    import moment from "moment"
    export default {
        name: "FoodDiary",
        components: {
        },
        props:{
        },
        data() {
            return {
                history:{
                    data:[]
                },
                dates: [],
                showPopup: false,
                currentExercise: {},
                selectedLog: {}
            };
        },
        mounted() {
            this.getFood()
        },
        methods: {
            async getFood(){
                const payload = {
                    user_id: this.$store.getters.user_id,
                    date: moment().format("MM/DD/YYYY")
                }
                let res = await axios.post(config.api_env + "/application/api-hc/get-month-logged-food", payload, config.options)
                if(res.data.success) {
                    this.history = res.data
                    const today = new moment().format('DD-MM-YYYY')
                    const yesterday = new moment().add(-1, 'days').format('DD-MM-YYYY')
                    this.dates = this.history.data.map(x => {
                        if(x.date === today){
                            return this.componentText.today
                        }else if (x.date === yesterday){
                            return this.componentText.yesterday
                        }else return x.date
                    })
                }
            },
            async deleteLog(id, type){
                const payload = {
                    user_id: this.$store.getters.user_id,
                    log_id: id,
                    type: type
                }
                await axios.post(config.api_env + "/application/api-hc/delete-logged-food", payload, config.options)
                this.getFood()
                this.$emit('log-update')
            },
            async duplicateLog(id, type){
                const payload = {
                    user_id: this.$store.getters.user_id,
                    log_id: id,
                    type: type
                }
                await axios.post(config.api_env + "/application/api-hc/log-food-again", payload, config.options)
                this.getFood()
                this.$emit('log-update')
            },
            selectFood(food){
                this.showPopup = true
                this.selectedLog = food
            },
            hidePopup(){
                this.showPopup = false
            },
            recipeNav(id){
                this.$router.push("/recipe?rec=" + id)
            }
        },
        watch:{

        },
        computed:{
            noFood(){
                return this.history.data.filter((x) => x.logged_food.length > 0)
            },
            componentText() {
                return this.getText.loggedIn.nutrition.foodDiary;
            },
        }
    };
</script>
<style lang="less">
    #FoodDiary{

        .no-history{
            font-family: 'FilsonProBold', sans-serif;
            font-size: 3.5rem;
            &:before{
                height: 8px;
                width: 50px;
                content: "";
                position: absolute;
                top: -10px;
            }
        }
        .date-header{
            background: rgba(35, 31, 32, .05);
            font-family: 'DMSans';
            font-size: 1.8rem
        }
        .exercise-log{
            position: relative;
            .marker-line{
                width: 1px;
                height: 100%;
                background: rgba(0, 0, 0, .2);
                position: absolute;
                transform: translate(35px)
            }
            .exercise-log-item{
                font-size: 1.8rem;
                font-family: 'FilsonProLight', sans-serif;
                position: relative;
                border-bottom: 1px solid rgba(0, 0, 0, .2);
                &:last-child{
                    border-bottom-width: 0px;
                }
                &:before{
                    position: absolute;
                    left: 0;
                    top: 50%;
                    content: '';
                    width: 12px;
                    height: 12px;
                    background-color: white;
                    border: 1px solid #7E4377;
                    border-radius: 50%;
                    transform: translate(30px, -50%);
                }
                .button-wrap {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-end;
                    .button-delete, .button-add {
                        width: 22px;
                        height: 22px;
                        border-radius: 8px;
                        position: relative;
                        &:before {
                            width: 16px;
                            height: 2px;
                            position: absolute;
                            content: '';
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }
                    .button-add {
                        &:after {
                            position: absolute;
                            content: '';
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 2px;
                            height: 16px;
                        }
                    }
                    .button-delete:before {
                        background-color: #fff;
                    }
                }
            }
        }
        #pop-up{
            display: flex;
            align-items: center;
            z-index: 500;
            position:fixed;
            top:0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            text-align: center;
            .pop-up-content{
                max-width: 636px;
                width: 100%;
                margin: auto;
                border-radius: 25px;
                padding: 50px 20px;
                font-size: 1.6rem;
                text-align: center;
                z-index: 10;
                color: #fff;
                position: relative;
                p{
                    color: #fff;
                }
                h4{
                    font-family: 'FilsonProLight', sans-serif;
                    font-size: 2.4rem;
                    color: #fff;
                }
                .log-info{
                    border-bottom: 1px solid grey;
                    :first-child{
                        text-align: left;
                    }
                    :last-child{
                        text-align: right;
                    }
                }
                .close-popup{
                    position: absolute;
                    top: 0;
                    right: 0;
                    background-color: transparent;
                    width: 50px;
                    height:50px;
                    svg{
                        width: 30px;
                        height: 30px;
                    }
                }
            }
            .pop-up-background{
                width: 100vw;
                height: 100vh;
                position: absolute;
                left: 0;
                right: 0;
                z-index: 5;
                background: transparent;
            }
        }
    }
</style>
