<template>
  <div id="TherapyServicesTable">
    <div v-for="service in services" >
      <div class="svcCard mx-auto px-5 py-4 my-2">
        <div class="title mt-3">{{service.label}}</div>
        <div class="middleRow">
          <i class="fa-solid" :class="getIcon(service)"></i>
          {{getMiddleRowText(service)}}
        </div>
        <div class="btnContainer">
          <button  class="tsButton" @click="goToDashboard(service)">Go to Dashboard <i class="fa-solid fa-arrow-right"></i></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import moment from 'moment'
export default {
  name: "TherapyServicesTable",
  props: ["services","forms","is_school_questionnaire_complete","can_access_nd_forms"],
  methods: {
    goToDashboard(service) {
      this.m('go-to-dashboard-button-clicked',{service: service.label})
      this.$router.push('service-dashboard/'+service.global_event_category)
    },
    dob(formName) {
      let dob = '';
      let created_on = '';
      this.forms.forEach(form => {
        if(form.form_name === formName) {
          const data = JSON.parse(atob(form.content));
          if(data.questions.hasOwnProperty('personal-dob')) dob = data.questions['personal-dob'];
          created_on = form.created_on;
        }
      });
      if(!dob) return 0;
      const formCompletedDate = moment(created_on, "YYYY-MM-DD HH:mm:ss");
      const dateOfBirth = moment(dob, "DD-MM-YYYY");
      return formCompletedDate.diff(dateOfBirth, 'years');
    },
    getIntakeFormName(service) {
        return 'intake_'+service.area.toLowerCase()+'_'+service.age_group.toLowerCase();
    },
    calculateIntakeFormComplete(service) {
      const formName = this.getIntakeFormName(service);
      const formIndex = this.forms.findIndex(f => f.form_name === formName);
      if(formIndex === -1) return 0;
      const data = JSON.parse(atob(this.forms[formIndex].content));
      return this.determinePercentageOfFormComplete(formName,data.questions);
    },
    getIcon(service) {
      if(!service.intake_form_completed || !service.agreement_complete) return 'fa-clipboard';
      if(this.isFutureOrWithinLastHour(service.initial_appointment_date) || this.isFutureOrWithinLastHour(service.date_of_next_appointment)) return 'fa-calendar';
      return 'fa-check';
    },
    getMiddleRowText(service) {
      if(this.calculateIntakeFormComplete(service) !== 1 && service.global_event_category !== 'cmh_assessment') return 'Complete intake form';
      if(this.isFutureOrWithinLastHour(service.initial_appointment_date)) return this.getNextAppointmentDateString(service.initial_appointment_date);
      if(this.isFutureOrWithinLastHour(service.date_of_next_appointment)) return this.getNextAppointmentDateString(service.date_of_next_appointment);
      if(service.area === 'Neurodiversity' && service.age_group === 'Child' && this.can_access_nd_forms) {
        const age = this.dob(this.getIntakeFormName(service));
        const formNames = this.cmhAdditionalFormNames(service.age_group, age);
        let formsCompleted = 0;
        this.forms.forEach(form => {
          if(formNames.includes(form.form_name)) formsCompleted++;
        });
        if(this.is_school_questionnaire_complete) formsCompleted++;
        if(formsCompleted < formNames.length + 1) return formsCompleted + "/" + (formNames.length + 1) + " Questionnaires Complete"
      }
      if(service.area === 'Neurodiversity' && service.age_group === 'Adult' && this.can_access_nd_forms) {
        const formNames = this.cmhAdditionalFormNames(service.age_group, 0);
        let formsCompleted = 0;
        this.forms.forEach(form => {
          if(formNames.includes(form.form_name)) formsCompleted++;
        });
        if(formsCompleted < formNames.length) return formsCompleted + "/" + (formNames.length) + " Questionnaires Complete"
      }
      return 'No upcoming appointments'
    },
    isFutureOrWithinLastHour(dateString) {
      if(!dateString) return false;
      const targetDate = new Date(dateString);
      const now = new Date();
      if (targetDate > now) return true;
      const oneHourAgo = new Date(now.getTime() - (3600 * 1000));
      return targetDate > oneHourAgo;
    },
    getNextAppointmentDateString(dateString) {
      const appointmentDate = moment(dateString);
      const today = moment().startOf('day');
      const tomorrow = moment().add(1, 'day').startOf('day');
      if (appointmentDate.isSame(today, 'day')) {
        return `Next appointment: Today at ${appointmentDate.format('h:mma')}`;
      }
      if (appointmentDate.isSame(tomorrow, 'day')) {
        return `Next appointment: Tomorrow at ${appointmentDate.format('h:mma')}`;
      }
      return `Next appointment on ${appointmentDate.format('Do MMM')}`;
    }
  }
}
</script>

<style lang="less">
#TherapyServicesTable {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  .svcCard {
    width: 96%;
    max-width: 400px;
    aspect-ratio: 400 / 236;
    background-color: #DCDCDC;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .tsButton {
      all: unset;
      font-size: 1.3rem;
      color: white;
      text-align: center;
      padding: 7px 20px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      border-radius: 70px;
      background: #ED3A75;
      cursor: pointer;
      i {
        margin-left: 10px;
      }
    }
    .title {
      font-family: FilsonProBold, Arial, Helvetica, sans-serif;
      font-size: 1.6rem;
      color: #3A4A61;
      line-height: 1.65rem;
    }
    .btnContainer {
      display: flex;
      justify-content: end;
    }
    .middleRow {
      font-size: 1.25rem;
      color: #3A4A61;
      display: flex;
      align-items: center;
      i {
        color: #B0B0B0;
        font-size: 2rem;
        margin-right: 20px;
      }

    }
  }
  @media only screen and (max-width: 992px) {
    grid-template-columns: 1fr 1fr;
    .svcCard {
      .middleRow {
        font-size: 1.6rem;
        i {
          font-size: 2.5rem;
        }
      }
    }

  }
  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    .svcCard {
      margin-top: 20px;
    }
  }
}
</style>