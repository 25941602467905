<template>
    <div id="HomeVideo" class="py-3">
        <div class="mb-3 container d-flex flex-row justify-space-between align-items-center">
            <div>
                <h2 class="home-title">My Videos</h2>
                <div class="home-title-line bg-brand-secondary"></div>
            </div>
        </div>
        <div class="position-relative">
            <div class="pb-5 container">
                <slick ref="slick" :options="slickOptions" class="justify-content-start">
                    <VideoCard :type="3"></VideoCard>
                    <VideoCard :type="1"></VideoCard>
                    <VideoCard :type="2"></VideoCard>
                    <VideoCard></VideoCard>
                    <VideoCard></VideoCard>
                    <VideoCard></VideoCard>
                    <VideoCard></VideoCard>
                    <VideoCard></VideoCard>
                    <VideoCard></VideoCard>
                </slick>
            </div>
            <div class="d-flex flex-row flex-nowrap video-blur-container">
                <div class="left-blur bg-brand-primary"></div>
                <div class="container w-100"></div>
                <div class="right-blur bg-brand-primary"></div>
            </div>
        </div>
    </div>
</template>

<script>
import slick from "vue-slick"
import VideoCard from "@/components/Home/New/VideoCard.vue"


export default {
    name: "HomeVideo",
    props: {
        isEarliest: Boolean,
    },
    components: {
        VideoCard,
        slick
    },
    data() {
        return {
            slickOptions: {
                arrows: true,
                slidesToScroll: 1,
                slidesToShow: 3,
                nextArrow:
                    '<div class="slick-arrow-next">' +
                    '<a class="bg-brand-highlight-1 slick-arrow-container"><i class="fas fa-chevron-right text-white fa-4xs"></i></a>' +
                    '</div>',
                prevArrow:
                    '<div class="slick-arrow-prev">' +
                    '<a class="bg-brand-highlight-1 slick-arrow-container"><i class="fas fa-chevron-left text-white fa-4xs"></i></a>' +
                    '</div>',
                adaptiveHeight: true,
                infinite: false,
            },
        };
    },
    mounted() {

    },
};
</script>

<style lang="less">
#HomeVideo {
    .home-title-line{
        height: 3px;
        max-width: 140px;
    }
    .slick-slider {
        .slick-slide {
            transform: scale(1);
            margin: 0 15px;
        }
    }

    .slick-list {
        margin: 0 -15px;
        overflow: visible;
    }

    .slick-arrow-container {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        padding: 25px;
        height: 100%;
        display: none;
    }
    @media only screen and (min-width: 980px) and (max-width: 992px) {
        .slick-arrow-container{
            display: flex;
        }
    }
    @media only screen and (min-width: 1130px) and (max-width: 1200px) {
        .slick-arrow-container{
            display: flex;
        }
    }
    @media only screen and (min-width: 1320px) {
        .slick-arrow-container{
            display: flex;
        }
    }

    .slick-arrow-next {
        right: -45px;
    }

    .slick-arrow-prev {
        left: -75px;
    }

    .slick-slide.slick-active {
        pointer-events: auto;
        opacity: 1;
    }

    .slick-slide {
        pointer-events: none;
    }

    .slick-disabled {
        .bg-brand-highlight-1 {
            background-color: #eee;
            cursor: default;
        }
    }

    .video-blur-container {
        pointer-events: none;
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;

        .container {
            flex-grow: 1;

        }

        .left-blur {
            flex-grow: 1;
            background: rgb(247, 247, 247);
            background: linear-gradient(270deg, rgba(247, 247, 247, 0) 15%, rgba(247, 247, 247, 0.7) 45%, rgba(247, 247, 247, 1) 100%);
        }

        .right-blur {
            flex-grow: 1;
            background: rgb(247, 247, 247);
            background: linear-gradient(90deg, rgba(247, 247, 247, 0) 15%, rgba(247, 247, 247, 0.7) 45%, rgba(247, 247, 247, 1) 100%);
        }
    }
}
</style>
