<template>
  <div id="MyFutureWellbeing">
    <div class="page-header bg-brand-secondary">
      <div class="content-container row">
        <div class="page-title col-12">
          <h2>MY FUTURE WELLBEING</h2>
          <p>
            Wellbeing matters is supporting your future wellbeing through our
            range of partners. We are supported by some of the biggest and most
            well-respected organisations in their field, so you can be assured
            that what matters to you, matters to us.
          </p>
        </div>
      </div>
    </div>
    <div class="bg-white py-5">
      <div class="content-container pb-5 mb-5" v-if="loading">
        <b-spinner></b-spinner>
      </div>
      <div class="content-container pb-5 mb-5" v-else>
        <div class="row py-5" v-for="(category, i) in shelfItems" :key="i">
          <div class="col-12 category-title">
            <h3>{{ category.category }}</h3>
          </div>
          <div
            v-for="(product, index) in category.items"
            :key="index"
            class="col-lg-3 col-md-4 col-sm-6 col-12"
          >
            <div class="product-card my-3">
              <div class="card-heading bg-brand-highlight-1">
                {{ product.title }}
              </div>
              <div class="card-content">
                <h4 class="product-title pt-4 my-4">{{ product.summary }}</h4>
                <p
                  :id="'card' + i + '-' + index"
                  class="clamped"
                  @click="unclamp('card' + i + '-' + index)"
                >
                  {{ product.description }}
                </p>
                <div class="button-wrap">
                  <b-button
                    class="cta-button px-5 py-3"
                    @click="productNav(product)"
                    >{{ product.link_text }}</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyFutureWellbeing",
  data() {
    return {
      shelfItems: [],
      loading: true,
    };
  },
  mounted() {
    this.getShelf();
  },
  methods: {
    async getShelf() {
      const payload = {
        user_id: this.$store.getters.user_id,
      };
      let res = await this.axios.post(
        process.env.VUE_APP_API_PATH +
          "/application/api/u/customisations/get-shelf-items",
        payload
      );
      if (res.data.success) {
        this.shelfItems = res.data.shelf_items;
        this.loading = false;
      }
    },
    productNav(product) {
      product.target === "internal"
        ? this.$router.push(product.link_url)
        : window.open(product.link_url, "_blank");
    },
    unclamp(id) {
      console.log(id);
      const element = document.getElementById(id);
      if (element.classList.contains("clamped")) {
        element.classList.remove("clamped");
      } else {
        element.classList.add("clamped");
      }
    },
  },
};
</script>

<style scoped lang="less">
#MyFutureWellbeing {
  .page-header {
    padding: 60px 0;

    .page-title {
      text-align: left;
      h2 {
        text-align: left;
        font-size: 3.8rem;
        letter-spacing: 0.7rem;
        font-family: "FilsonProBold", sans-serif;
        margin-bottom: 50px;
        color: white;
      }

      p {
        font-size: 2rem;
        font-family: "FilsonProLight";
        max-width: 830px;
        color: white;
      }
    }
  }
  .category-title {
    h3 {
      text-transform: uppercase;
      border-width: 0 0 1px 0;
      border-style: solid;
      border-color: #e4e4e4;
      padding-bottom: 5px;
      margin-bottom: 20px;
    }
  }
  .product-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 15px;
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.2);
    .card-heading {
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: capitalize;
      width: 100%;
      border-radius: 15px 15px 0 0;
      color: #fff;
      font-size: 1.9rem;
      height: 50px;
      line-height: 1;
    }
    .card-content {
      padding: 0 10px;
      height: 100%;
      display: flex;
      flex-direction: column;
      max-width: 100%;
      .featured-image {
        height: 90px;
        width: 90px;
        margin: auto;
        background-color: #ddedf6;
        border-radius: 50%;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
      }
      h4 {
        font-family: DMSans, sans-serif;
        font-size: 2.2rem;
        color: #7d7d7b;
        margin-bottom: 0;
      }
      p {
        color: #7d7d7b;
        font-size: 1.5rem;
        line-height: 1.2;
        cursor: pointer;
        transition: -webkit-line-clamp 2s, height 2s, display 2s,
          -webkit-box-orient 2s;
        &.clamped {
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
      .button-wrap {
        margin: auto auto 20px auto;
        .btn {
          font-family: DMSans, sans-serif;
          font-size: 1.5rem;
        }
      }
    }
  }
}
</style>
