<template>
    <div id="be-calm">
        <div class="page-header bg-grey">
            <div class="content-container row">
                <div class="page-title col-12 col-md-5">
                    <h2 class="pb-4 pt-3"><span class="light">{{componentText.explore}} </span>{{componentText.title}}</h2>
                    <p>Self-guided meditation is just one technique you could use to clear out negative energy from your mind, and promote positive thinking and inner peace. Granted, calming your mind can be a challenge, but with practice, it can become second nature!</p>
                </div>
                <div class="col-md-7 d-none d-md-flex pr-5 icons">
                    <div class="be-calm-icon mr-5">
                        <img src="/img/be-calm/becalm-icon.svg">
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-white px-md-5 py-5" v-if="!loading">
            <div class="content-container row py-5">
                <div v-for="(course, i) in courses" :key="i" class="col-12 col-md-6 my-3">
                    <div class="course-card" @click="$router.push('/be-calm/' + course.course_id)">
                        <div class="course-image" :style="'background-color:'+course.course_color">
                            <img :src="course.course_image_url">
                        </div>
                        <div class="course-content pt-5 px-5 pb-3">
                            <h3 class="mb-4">{{course.course_title}}</h3>
                            <div class="course-description" v-html="course.course_description"></div>
                            <div class="session-info pb-3">
                                <span class="duration"><i class="far fa-clock"></i> {{course.course_duration}}</span>
                                <span class="sessions"><i class="fa fa-volume-up"></i> {{course.course_sessions}} sessions</span>
                                <a class="d-none d-lg-inline" @click="$router.push('/be-calm/' + course.course_id)">{{componentText.start}} <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.09 11.98"><title>arrow</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><polygon class="cls-1" points="11.89 0 10.91 1.02 15.14 5.11 0 5.11 0 6.53 15.49 6.53 10.91 10.97 11.89 11.98 18.09 5.99 11.89 0"/></g></g></svg></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-white px-md-5 py-5" v-else>
            <b-spinner></b-spinner>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    import config from "@/config/constants";
    export default {
        name: "BeCalm",
        data(){
            return{
                loading: true,
                courses:[]
            }
        },
        mounted(){
            this.getCourses()
        },
        methods:{
            async getCourses(){
                const payload = {
                    user_id: this.$store.getters.user_id,
                    channel: "be_calm",
                    limit: 0
                }
                let res = await axios.post(config.api_env + '/application/api/u/resources/get-be-calm-courses', payload, config.options)
                if(res.data.success){
                    this.courses = res.data.courses
                    this.loading = false
                }
            }
        },
        computed:{
            componentText() {
                return this.getText.loggedIn.beCalm;
            },
        }

    }
</script>

<style lang="less">
    #be-calm{
        background: #fff;
        .page-header {
            padding: 60px 0;
            .page-title {
                color: #3a4961;
                h2 {
                    font-family: 'FilsonProBold', sans-serif;
                    letter-spacing: 0;
                    text-align: left;
                    font-size: 3.8rem;
                }
                .light {
                    font-family: 'FilsonProLight', sans-serif;
                }
                p{
                    font-size: 1.6rem;
                    margin-bottom: 0;
                    margin-left: 2px;
                }
            }
        }
        .course-card{
            display: flex;
            flex-direction: row;
            border-radius: 27px;
            box-shadow: 3px 3px 10px #cccccc;
            cursor: pointer;
            min-height: 292px;
            &:hover{
                transform: translate(-3px, -3px);
            }
            h3{
                padding: 0;
                color: #394961;
                letter-spacing: 0;
            }
            .course-description {
                font-size: 1.6rem;
                line-height: 1.4;
                display: -webkit-box;
                -webkit-line-clamp: 5;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
            .course-image{
                width: 140px;
                border-radius: 27px 0 0 27px;
                display: flex;
                align-items: center;
                img{
                    width: 140px;
                }
            }
            .course-content {
                display: flex;
                flex-direction: column;
                .session-info {
                    display: inline-flex;
                    margin-top: auto;
                    font-size: 1.5rem;
                    color: #808080;
                    .duration{

                    }
                    .sessions{
                        margin-left: 20px;
                    }
                    a {
                        color: #e6007e;
                        margin-left: auto;
                        font-family: FilsonProBold, sans-serif;
                        svg {
                            width: 20px;
                            .cls-1{
                                fill: #e6007e;
                            }
                        }
                        &:hover{
                            svg{
                                width: 25px;
                            }
                        }
                    }
                }
            }
            @media (max-width: 992px){
                .course-image{
                    width: 120px;
                    img{
                        width: 120px;
                    }
                }
            }
            @media (max-width: 576px){
                .course-image{
                    width: 50px;
                    img{
                        width: 50px;
                    }
                }
                .session-info a{
                    display: none
                }
            }
        }
        //IE style
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            .course-card {
                .course-content {
                    display: flex;
                    flex: 1;
                    height: 292px;
                    .course-description{
                        padding: 20px 0;
                    }
                    .session-info{
                        height: 90px;
                        padding: 10px 0;
                    }
                }
            }
        }
    }
</style>