<template>
  <div id="Pulse" v-if="!isLoading">
    <section class="page-header bg-brand-secondary">
      <div
        class="
          page-header-container
          content-container
          row
          justify-content-around
          align-items-center
          py-5
          mx-auto
        "
      >
        <div class="page-header-text-container py-5 px-3 col-md-12 col-lg-6">
          <h1 class="header-title">
            Start Your Health and Wellbeing Pulse Assesment
          </h1>
          <div class="row d-lg-none mx-auto">
            <img
              class="header-small-image"
              src="/img/nothing-logged.svg"
              alt="Calendar Icon"
            />
          </div>
          <div class="header-text">
            Use Pulse to get recommendations on your health and wellbeing with
            access to fitness, nutrition and mental health content.
          </div>
          <a @click="scrollTo('pulse-form')" v-if="!hasReports">
            <button class="btn cta-button px-5 py-4">
              Start Pulse Assessment
            </button>
          </a>
          <a @click="scrollTo('pulse-report')" v-else>
            <button class="btn cta-button px-5 py-4">Show Pulse Results</button>
          </a>
        </div>
        <div class="page-header-image-container d-none d-lg-block">
          <img class="header-image" src="/img/nothing-logged.svg" />
        </div>
      </div>
      -
    </section>
    <section class="description row mx-0">
      <div class="description-container content-container mx-auto">
        <p>
          {{ introText }}
        </p>
      </div>
    </section>
    <section class="pulse-container">
      <div
        class="
          pulse-steps-container
          d-flex
          flex-row flex-wrap
          align-items-center
          justify-content-around
        "
        v-if="!hasReports"
      >
        <div
          class="pulse-step d-flex flex-column align-items-center p-3"
          v-for="(section, i) in pulseSections"
          :key="i"
        >
          <div
            class="d-flex flex-column align-items-center"
            :class="[
              currentQuestionNumber <= section.activeQuestions.upper &&
              currentQuestionNumber >= section.activeQuestions.lower
                ? 'brand-highlight-1'
                : 'not-active',
              currentQuestionNumber > section.activeQuestions.upper
                ? 'completed'
                : '',
            ]"
          >
            <i class="fa fa-circle fa-2x" aria-hidden="true"></i>
            <span class="pulse-step-title">{{ section.name }}</span>
          </div>
        </div>
      </div>
      <div class="pulse-form-container content-container pt-5">
        <div
          :class="[
            !hasReports ? 'justify-content-between' : 'justify-content-end',
          ]"
          class="
            pulse-form-question-counter
            d-flex
            flex-row
            align-items-center
            pb-3
          "
        >
          <div class="pulse-counter brand-secondary pl-5" v-if="!hasReports">
            Question {{ currentQuestionNumber }}/{{ numOfQuestions }}
          </div>
          <div>
            <button class="btn start-over-button" @click="setFirstQuestion()">
              <i class="fas fa-retweet"></i> Start Over
            </button>
          </div>
        </div>
        <div class="pulse-form" id="pulse-form" v-if="!hasReports">
          <div class="pulse-form-header-container">
            <div class="pulse-form-header brand-text">
              Health and Wellbeing Pulse Assesment
            </div>
          </div>
          <div v-if="loadingQuestion">
            <div class="d-flex flex-row align-items-center question-spinner">
              <div class="mx-auto my-auto">
                <b-spinner class="brand-primary"></b-spinner>
              </div>
            </div>
          </div>
          <div class="pulse-form-question-container" v-else>
            <div class="pulse-form-question">
              <div
                class="
                  pulse-form-question-text
                  d-flex
                  flex-row
                  justify-content-center
                "
              >
                <span class="question-text brand-secondary">{{
                  currentQuestion.text
                }}</span>
              </div>
              <div class="pulse-form-question-answer">
                <div
                  class="d-flex justify-content between"
                  v-if="currentQuestion.type === 'numeric'"
                >
                  <input
                    type="number"
                    id="pulse-number-input"
                    class="pulse-number-input mx-auto"
                    placeholder="Enter Here..."
                    :min="currentQuestion.min_value"
                    :max="currentQuestion.max_value"
                    @change="setNumericAnswer()"
                  />
                </div>
                <div
                  class="d-flex justify-content between"
                  v-if="currentQuestion.type === 'text'"
                >
                  <input
                    type="text"
                    id="pulse-text-input"
                    class="pulse-text-input mx-auto"
                    placeholder="Enter Here..."
                    @change="setTextAnswer()"
                  />
                </div>
                <div v-if="currentQuestion.type === 'single_choice'">
                  <div v-if="currentQuestion.layout === 'scale'">
                    <input
                      type="range"
                      id="pulse-slider"
                      class="pulse-answer-slider w-100"
                      min="0"
                      :max="currentQuestionOptions.length - 1"
                      value="currentSliderValue"
                      @change="setSliderAnswer()"
                      style="padding: 0px 0px 0px 0px; height: 30px"
                    />
                    <div
                      class="
                        pulse-steps-row
                        flex-row
                        d-flex
                        justify-content-between
                      "
                      v-if="currentQuestionOptions.length > 0"
                    >
                      <div
                        class="step-container text-center"
                        v-for="(option, i) in currentQuestionOptions"
                        :key="i"
                        @click="setSliderAnswer()"
                      >
                        {{ option.text }}
                      </div>
                    </div>
                  </div>
                  <div v-if="currentQuestion.layout === 'dropdown'">
                    <v-select
                      v-model="dropdownAnswer"
                      :options="currentQuestionOptions"
                      :closeOnSelect="true"
                      :multiple="false"
                      label="text"
                    >
                    </v-select>
                  </div>
                  <div
                    class="
                      options-button-container
                      row
                      d-flex
                      flex-row
                      justify-content-between
                    "
                    v-if="currentQuestion.layout === 'other'"
                  >
                    <div
                      class="d-flex flex-grow flex-row flex-wrap options-button"
                      v-for="(option, i) in currentQuestionOptions"
                      :key="i"
                    >
                      <button
                        @click="toggleOption(option.id, true)"
                        :class="[
                          option.selected
                            ? 'selected brand-highlight-1 border-brand-highlight-1'
                            : 'not-selected',
                          'mx-5 mb-3 flex-grow shadow btn btn-outline answer-button',
                        ]"
                      >
                        {{ option.text }}
                        <span v-if="option.selected"
                          ><i class="fas fa-check float-right mr-3"></i
                        ></span>
                      </button>
                    </div>
                  </div>
                </div>
                <div v-if="currentQuestion.type === 'multi_choice'">
                  <div v-if="currentQuestion.layout === 'dropdown'">
                    <v-select
                      v-model="dropdownAnswer"
                      :options="currentQuestionOptions"
                      :closeOnSelect="false"
                      :multiple="true"
                      label="text"
                    >
                    </v-select>
                  </div>
                  <div
                    class="
                      options-button-container
                      row
                      d-flex
                      flex-row
                      justify-content-between
                    "
                    v-if="currentQuestion.layout === 'other'"
                  >
                    <div
                      class="d-flex flex-grow flex-row flex-wrap options-button"
                      v-for="(option, i) in currentQuestionOptions"
                      :key="i"
                    >
                      <button
                        @click="toggleOption(option.id)"
                        :class="[
                          option.selected
                            ? 'selected brand-highlight-1 border-brand-highlight-1'
                            : 'not-selected',
                          'mx-5 mb-3 flex-grow shadow btn btn-outline answer-button',
                        ]"
                      >
                        {{ option.text }}
                        <span v-if="option.selected"
                          ><i class="fas fa-check float-right mr-3"></i
                        ></span>
                      </button>
                    </div>
                  </div>
                </div>
                <!-- <div>The value you've picked is: {{ currentAnswer }}</div> -->
                <div class="row d-flex justify-content-around">
                  <div
                    v-for="(error, i) in errors"
                    :key="i"
                    class="pulse-error"
                  >
                    {{ error }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="pulse-form-navigation-buttons d-flex flex-row flex-wrap"
              :class="[
                onFirstQuestion
                  ? 'justify-content-end'
                  : 'justify-content-between',
              ]"
            >
              <button
                class="
                  btn
                  form-btn
                  btn-outline
                  shadow
                  py-3
                  px-5
                  mb-3
                  border border-brand-highlight-1
                  brand-highlight-1
                "
                @click="previousQuestion()"
                v-if="!onFirstQuestion"
              >
                <i class="fas fa-angle-left mr-3"></i><span>Previous</span>
              </button>
              <button
                class="btn form-btn cta-button shadow py-3 px-5 mb-3"
                @click="answerQuestion(currentQuestion.id)"
              >
                <span v-if="currentQuestionNumber === numOfQuestions"
                  >Complete Assesment</span
                >
                <span v-else>Next</span>
                <i class="fas fa-angle-right ml-3"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="pulse-report" id="pulse-report" v-else>
          <div class="pulse-report-header-container">
            <div class="pulse-report-header d-flex flex-row flex-wrap">
              <!-- For each reports here, -->
              <div
                v-for="(report, i) in reports"
                :key="i"
                @click="setSelectedReport(report.name)"
                :class="[report.selected ? 'brand-highlight-1' : 'brand-text']"
                class="pulse-report-section-header d-flex text-center"
              >
                <span :class="{ selected: report.selected }">{{
                  report.name
                }}</span>
              </div>
              <div
                @click="setShowDailyPulse()"
                :class="[showDailyPulse ? 'brand-highlight-1' : 'brand-text']"
                class="pulse-report-section-header d-flex text-center"
              >
                <span :class="{ selected: showDailyPulse }">Daily Pulse</span>
              </div>
            </div>
          </div>
          <!-- this needs to only render if daily pulse isn't selected -->
          <div
            class="pulse-report-container brand-text d-flex flex-column"
            v-if="!showDailyPulse"
          >
            <p class="pulse-report-intro-text col-lg-8 col-sm-12 px-0">
              We've pulled together some content recommendations that would
              really help you in your journey, please see below.
            </p>
            <!-- This needs to iterate through the reports and print the details -->
            <div v-if="selectedReport.reports.length === 0">
              There's nothing here yo
            </div>
            <div
              v-for="(report, i) in selectedReport.reports"
              :key="i"
              class="pulse-report-item row pt-4"
              v-else
            >
              <div class="col-lg-8 px-0 border-top d-flex flex-column">
                <h3 class="pulse-report-item-title">{{ report.title }}</h3>
                <p class="pulse-report-item-text">{{ report.text }}</p>
                <a
                  @click="selectCourse(report.course.id)"
                  class="
                    pulse-report-item-link
                    brand-highlight-1
                    align-item-end
                    mt-auto
                    mb-5
                  "
                  ><b>Start recommended elearning course ></b></a
                >
              </div>
              <div
                class="
                  pulse-report-item-image
                  bg-brand-primary
                  col-lg-4 col-sm-12
                  py-3
                "
              >
                <img class="img-fluid px-5" :src="report.course.featured_img" />
              </div>
            </div>

            <div
              class="
                pulse-form-navigation-buttons
                d-flex
                flex-row flex-wrap
                align-items-end
                mt-auto
                pt-5
              "
              :class="
                selectedReport.previous === null
                  ? 'justify-content-end'
                  : 'justify-content-between'
              "
            >
              <button
                v-if="selectedReport.previous !== null"
                class="
                  btn
                  form-btn
                  btn-outline
                  shadow
                  py-3
                  px-5
                  mb-3
                  border border-brand-highlight-1
                  brand-highlight-1
                "
                @click="setSelectedReport(selectedReport.previous)"
              >
                <i class="fas fa-angle-left mr-3"></i
                ><span>See {{ selectedReport.previous }}</span>
              </button>
              <button
                v-if="selectedReport.next !== null"
                class="btn form-btn cta-button shadow py-3 px-5 mb-3"
                @click="setSelectedReport(selectedReport.next)"
              >
                See {{ selectedReport.next }}
                <i class="fas fa-angle-right ml-3"></i>
              </button>
            </div>
          </div>
          <div class="pulse-daily-container" v-else>
            <p class="pulse-daily-intro-text border-bottom">
              Our Daily Pulse Tool allows you to assess your own health and
              wellbeing on a daily basis so you can track over time.
            </p>
            <div
              class="pulse-daily-question-container brand-text row"
              v-for="(question, i) in pulseDailyQuestions"
              :key="i"
            >
              <p class="pulse-daily-question-text col-sm-12 col-md-10 col-lg-8">
                {{ question.question.text }}
              </p>
              <div class="col-sm-12 col-md-10 col-lg-8">
                <input
                  type="range"
                  class="pulse-answer-slider w-100"
                  :id="'pulse-slider-' + question.question.id"
                  min="0"
                  :max="question.question.options.length - 1"
                  :value="question.sliderValue"
                  @change="setDailySliderAnswer(question)"
                  style="padding: 0px 0px 0px 0px; height: 30px"
                />
              </div>
            </div>
            <div class="pulse-daily-button-container pt-5">
              <button
                class="btn cta-button px-5 py-4"
                @click="answerPulseQuestions()"
              >
                <i class="fa fa-plus"></i> Log My Activity
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <div class="d-flex flex-row align-items-center mt-5" v-else>
    <div class="mx-auto my-auto">
      <b-spinner class="brand-primary"></b-spinner>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import config from "../config/constants";

export default {
  name: "app",
  data() {
    return {
      isLoading: true,
      loadingQuestion: false,
      hasReports: false,
      reports: [],
      showDailyPulse: false,
      numOfQuestions: 1,
      currentQuestionNumber: 1,
      pulseSections: [],
      currentQuestion: null,
      currentQuestionOptions: [],
      currentAnswer: [{}],
      dropdownAnswer: [],
      currentSliderValue: 0,
      previousQuestionId: null,
      allQuestions: [],
      errors: [],
      introText: "",
    };
  },
  mounted() {
    this.getPulseSurvey();
    this.getPulseDaily();
  },
  methods: {
    log() {
      console.log(this.pulseDailyQuestions);
    },
    async getPulseSurvey() {
      let self = this;
      const payload = {
        user_id: this.$store.getters.user_id,
        client_id: this.$store.getters.client_id,
      };
      let res = await axios.post(
        config.api_env + "/application/api/u/surveys/get-pulse-survey",
        payload,
        config.options
      );
      if (res.data.success) {
        let hasReports = true;
        this.pulseSections = res.data.survey.sections;
        this.introText = res.data.survey.intro_text;
        this.numOfQuestions = res.data.survey.questions_count;
        this.reports = [];
        let count = 1;
        let i = 0;

        this.pulseSections.forEach(function (section) {
          self.reports.push({
            name: section.name,
            reports: section.outputs,
            selected: count === 1 ? true : false,
            previous: i === 0 ? null : self.pulseSections[i - 1].name,
            next:
              i === self.pulseSections.length - 1
                ? null
                : self.pulseSections[i + 1].name,
          });
          section.activeQuestions = {
            lower: count,
            upper: count + section.questions.length - 1,
          };
          count = count + section.questions.length;
          section.questions.forEach(function (q) {
            self.allQuestions.push(q);
          });
          if (section.outputs.length === 0) {
            hasReports = false;
          }
          i = i + 1;
        });
        this.hasReports = hasReports;
        this.currentQuestion = this.pulseSections[0].questions[0];
        this.handleInputValues();
        this.isLoading = false;
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login").catch((err) => {});
      }
    },
    async getPulseDaily() {
      let self = this;
      const payload = {
        user_id: this.$store.getters.user_id,
        client_id: this.$store.getters.client_id,
      };
      let res = await axios.post(
        config.api_env + "/application/api/u/surveys/get-pulse-daily",
        payload,
        config.options
      );
      if (res.data.success) {
        this.dailyPulseIsLoading = false;
        let dailyQuestions = [];
        res.data.survey.questions.forEach(function (question) {
          dailyQuestions.push({
            question: question,
            answer: [
              {
                id: question.id,
                answer:
                  question.options[Math.floor(question.options.length / 2)],
              },
            ],
          });
        });
        this.pulseDailyQuestions = dailyQuestions;
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login").catch((err) => {});
      }
    },
    async answerQuestion(qid) {
      this.errors = [];
      this.loadingQuestion = true;
      if (typeof this.currentAnswer[0].answer === "undefined") {
        this.errors.push("You must enter a valid option");
      } else {
        this.currentQuestionOptions = [];
        const payload = {
          user_id: this.$store.getters.user_id,
          question_id: qid,
          answer: this.currentAnswer,
        };
        let res = await axios.post(
          config.api_env + "/application/api/u/surveys/answer-question",
          payload,
          config.options
        );
        if (res.data.success) {
          //if no question id, call getSurvey again, put loading on and wait for the refresh
          if (res.data.next_question === false) {
            this.getPulseSurvey();
          } else {
            this.previousQuestionId = qid;
            this.currentQuestionNumber++;
            this.currentQuestion = this.allQuestions.find(
              (x) => x.id === res.data.next_question.id
            );
          }
          this.handleInputValues();
          this.loadingQuestion = false;
        } else if (res.data.err && res.data.err.includes("3994")) {
          this.$store.dispatch("logout");
          this.$router.push("/login").catch((err) => {});
        }
      }
    },
    async answerPulseQuestions() {
      let self = this;
      this.pulseDailyQuestions.forEach(async function (question) {
        const payload = {
          user_id: self.$store.getters.user_id,
          question_id: question.question.id,
          answer: question.answer,
        };
        let res = await axios.post(
          config.api_env + "/application/api/u/surveys/answer-question",
          payload,
          config.options
        );
        if (res.data.success) {
          console.log("submitted successfully");
        } else if (res.data.err && res.data.err.includes("3994")) {
          self.$store.dispatch("logout");
          self.$router.push("/login").catch((err) => {});
        }
      });
    },
    clearInputs() {
      if (document.getElementById("pulse-text-input")) {
        document.getElementById("pulse-text-input").value = "";
      }
      if (document.getElementById("pulse-number-input")) {
        document.getElementById("pulse-number-input").value = "";
      }
      this.dropdownAnswer = [];
    },
    toggleOption(id, radio) {
      if (radio) {
        this.currentQuestionOptions.forEach(function (o) {
          o.selected = false;
        });
      }
      let selectedOption = this.currentQuestionOptions.find((x) => x.id === id);
      selectedOption.selected = !selectedOption.selected;
      this.setButtonAnswer();
    },
    setShowDailyPulse() {
      this.showDailyPulse = true;
      this.reports.forEach(function (report) {
        report.selected = false;
      });
    },
    setDailySliderAnswer(question) {
      let sliderValue = document.getElementById(
        "pulse-slider-" + question.question.id
      ).value;
      let option = question.question.options[sliderValue];
      question.answer = [{ id: option.id, answer: option.text }];
      console.log(this.pulseDailyQuestions);
    },
    setSelectedReport(name) {
      this.showDailyPulse = false;
      this.reports.forEach(function (report) {
        report.selected = false;
        if (name === report.name) {
          report.selected = true;
        }
      });
    },
    previousQuestion() {
      let self = this;
      if (this.currentQuestion.id !== this.allQuestions[0].id) {
        this.loadingQuestion = true;
        this.currentQuestion =
          this.allQuestions[
            this.currentQuestionNumber - 2 //-2 because -1 for actual array index and -1 for previous entry
          ];
        this.handleInputValues();
        this.currentQuestionNumber--;
        this.loadingQuestion = false;
      }
    },
    setFirstQuestion() {
      this.hasReports = false;
      this.currentQuestion = this.pulseSections[0].questions[0];
      this.handleInputValues();
      this.currentQuestionNumber = 1;
    },
    handleInputValues() {
      let self = this;
      if (
        this.currentQuestion.type === "single_choice" ||
        this.currentQuestion.type === "multi_choice"
      ) {
        this.currentQuestionOptions = [];
        this.currentQuestion.options.forEach(function (option) {
          self.currentQuestionOptions.push({
            id: option.id,
            text: option.text,
            selected: false,
          });
        });
      }
      this.currentAnswer = [{}];
      if (this.currentQuestion.layout === "scale") {
        self.setSliderAnswer(
          Math.floor(this.currentQuestionOptions.length / 2)
        );
      } else {
        this.clearInputs();
      }
    },
    setTextAnswer() {
      this.currentAnswer = [
        {
          id: false,
          answer: document.getElementById("pulse-text-input").value,
        },
      ];
    },
    setNumericAnswer() {
      let value = document.getElementById("pulse-number-input").value;
      this.errors = [];
      if (
        value > this.currentQuestion.max_value ||
        value < this.currentQuestion.min_value
      ) {
        this.errors.push(
          "Your answer must fall within the minimum and maximum values. Min: " +
            this.currentQuestion.min_value +
            ", Max: " +
            this.currentQuestion.max_value
        );
        if (value > this.currentQuestion.max_value) {
          document.getElementById("pulse-number-input").value =
            this.currentQuestion.max_value;
          this.currentAnswer = [
            {
              id: false,
              answer: document.getElementById("pulse-number-input").value,
            },
          ];
        } else if (value < this.currentQuestion.min_value) {
          document.getElementById("pulse-number-input").value =
            this.currentQuestion.min_value;
        }
      } else {
        this.currentAnswer = [
          {
            id: false,
            answer: value,
          },
        ];
      }
    },
    setButtonAnswer() {
      let answer = [];
      this.currentQuestionOptions.forEach(function (option) {
        if (option.selected) {
          answer.push({ id: option.id, answer: option.text });
        }
      });
      this.currentAnswer = answer;
    },
    setSliderAnswer(position) {
      let option =
        this.currentQuestionOptions[
          position ? position : document.getElementById("pulse-slider").value
        ];
      this.currentAnswer = [
        {
          id: option.id,
          answer: option.text,
        },
      ];
    },
    selectCourse(course_id) {
      this.$router.push("/wellbeing/" + course_id).catch((err) => {});
    },
    scrollTo(id) {
      const scrollTo = document.getElementById(id);
      scrollTo.scrollIntoView({ behavior: "smooth" });
    },
  },
  watch: {
    dropdownAnswer: function (val) {
      if (val) {
        let answer = [];
        if (val.length > 0) {
          val.forEach(function (a) {
            answer.push({ id: a.id, answer: a.text });
          });
          this.currentAnswer = answer;
        } else {
          this.currentAnswer = [{ id: val.id, answer: val.text }];
        }
      }
    },
  },
  computed: {
    onFirstQuestion() {
      return this.currentQuestionNumber === 1;
    },
    skin() {
      return this.$store.getters.skin;
    },
    componentText() {
      return this.getText.loggedIn.insights;
    },
    selectedReport() {
      let reports = this.reports.filter(function (report) {
        return report.selected;
      })[0];
      return reports ? reports : [];
    },
  },
};
</script>
<style lang="less">
#Pulse {
  .page-header {
    .page-header-container {
      .page-header-text-container {
        color: white;
      }
      .header-title {
        font-size: 5rem;
        margin-bottom: 30px;
        font-family: "FilsonProBold";
      }
      .header-text {
        margin-bottom: 50px;
        font-size: 1.6rem;
      }
      .page-header-image-container {
        height: 200px;
        width: auto;
        width: 50%;
      }
      .header-image {
        max-height: 200px;
        margin-left: auto;
        margin-right: auto;
      }
      .header-small-image {
        max-width: 70%;
        max-height: 300px;
        padding-bottom: 30px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  .description {
    padding: 50px 0;
    background-color: white;
    font-size: 1.6rem;
  }
  .pulse-container {
    background-color: #f8fafc;
    min-height: 300px;
    .pulse-steps-container {
      max-width: 900px;
      padding: 60px 0 40px 0;
      margin: 0 auto;
      .pulse-step {
        display: flex;
        .pulse-step-title {
          font-size: 1.6rem;
        }
      }
    }
    .pulse-counter {
      font-size: 1.6rem;
    }
    .pulse-form {
      padding-bottom: 75px;
      .pulse-form-header-container {
        background-color: #cbebec;
        border-radius: 16px 16px 0 0;
        .pulse-form-header {
          font-size: 1.6rem;
          text-transform: uppercase;
          padding: 35px 30px;
        }
      }
      .pulse-form-question-container {
        background-color: white;
        border-radius: 0 0 16px 16px;
        padding: 30px 30px 30px 45px;
        min-height: 320px;
        .pulse-steps-row {
          //need rules in here to get the bottom bits to better align
        }
        .pulse-form-question-text {
          padding-bottom: 30px;
          .question-text {
            font-size: 2.2rem;
            font-weight: bold;
          }
        }
        .pulse-form-question-answer {
          max-width: 1060px;
          padding: 30px 30px;
          margin: 0 auto;
          .answer-button {
            border: 3px #bbb solid;
            color: #bbb;
            i {
              position: relative;
              top: 3px;
            }
          }
          .answer-button.selected {
            border: 3px solid;
          }
          .pulse-number-input {
            height: 50px;
            width: 100%;
            max-width: 260px;
            text-align: center;
            font-size: 1.8rem;
            color: #666;
            border: 0px;
            outline: none !important;
            border-radius: 16px;
            background-color: #eeeeee;

            :focus {
              border: 0px;
            }
            :focus-visible {
              border: 0px;
              outline: none !important;
            }
          }
          .pulse-text-input {
            height: 50px;
            width: 100%;
            max-width: 360px;
            text-align: center;
            font-size: 1.8rem;
            color: #666;
            border: 0px;
            outline: none !important;
            border-radius: 16px;
            background-color: #eeeeee;

            :focus {
              border: 0px;
            }
            :focus-visible {
              border: 0px;
              outline: none !important;
            }
          }
          .pulse-button {
          }
        }
        .options-button-container {
          .options-button {
            flex-basis: 50%;
            max-width: 50%;
            @media only screen and (max-width: 769px) {
              flex-basis: 100%;
              max-width: 100%;
            }
          }
        }
        .pulse-form-navigation-buttons {
          padding-top: 30px;
          button {
            i {
              position: relative;
              top: 1px;
            }
          }
        }
      }
    }
    .pulse-report {
      padding-bottom: 75px;
      .pulse-report-header-container {
        background-color: #cbebec;
        border-radius: 16px 16px 0 0;
        .pulse-report-header {
          font-size: 1.6rem;
          padding: 15px;
          .pulse-report-section-header {
            cursor: pointer;
            padding: 10px 25px 0 25px;
            border-right: 1px solid white;
          }
          .pulse-report-section-header:last-child {
            border-right: 0;
          }
          .selected {
            font-weight: bold;
            padding-bottom: 10px;
            border-bottom: 3px solid;
          }
        }
      }
      .pulse-report-container {
        background-color: white;
        padding: 40px 45px;
        border-radius: 0 0 16px 16px;
        min-height: 500px;
        .pulse-report-intro-text {
          font-size: 1.6rem;
          padding-bottom: 30px;
        }
        .pulse-report-item {
          margin: 0;
          .pulse-report-item-title {
            font-size: 1.8rem;
            text-transform: uppercase;
            display: block;
            width: 100%;
            margin: 0;
            padding-top: 30px;
            padding-bottom: 0;
            font-weight: normal;
            font-family: "DMSans", sans-serif;
          }
          .pulse-report-item-text {
            padding-left: 0;
            padding-top: 10px;
            padding-bottom: 20px;
            font-size: 1.6rem;
          }
          .pulse-report-item-link {
            padding-left: 5px;
            font-size: 1.6rem;
            font-weight: bold;
            margin-top: 15px;
          }
          .pulse-report-item-image {
            margin-top: 30px;
            margin-bottom: 30px;
            border-radius: 16px;
          }
        }
      }
      .pulse-daily-container {
        background-color: white;
        padding: 40px 45px;
        border-radius: 0 0 16px 16px;
        min-height: 500px;
        .pulse-daily-intro-text {
          font-size: 1.8rem;
          padding-bottom: 30px;
        }
        .pulse-daily-question-text {
          font-size: 1.6rem;
          padding-top: 20px;
        }
      }
    }
    .start-over-button {
      background-color: #997c9c;
      font-size: 1.4rem;
      font-weight: 400;
      border-radius: 6px;
      padding: 12.5px 15px;
      font-family: "DMSans";
      color: white;
    }
  }
  .pulse-error {
    color: red;
    font-size: 1.6rem;
    margin-top: 10px;
  }

  .cta-button {
    padding-top: 12.5px !important;
    padding-bottom: 12.5px !important;
  }
  .btn-outline {
    padding-top: 12.5px !important;
    padding-bottom: 12.5px !important;
  }
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #bbb;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #bbb;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #bbb;
  }
  .cta-button {
  }

  .not-active {
    color: lightgrey;
  }
  .not-active.completed {
    color: darkslategrey; //this will need to go in to theme
  }
  .form-btn {
    min-width: 170px;
  }
  .question-spinner {
    border-radius: 16px 16px 0 0;
    margin-top: 0;
    padding-top: 0;
    background-color: white;
    min-height: 320px;
  }

  //select styling
  .v-select {
    margin: 0 auto;
    max-width: 400px;
    cursor: pointer !important;

    .vs__dropdown-toggle {
      border-radius: 20px;
      border: 0px #eee solid;
      font-family: DMSans, sans-serif;
      background: #eee;
      color: #666;
      font-size: 2rem;
      position: relative;
      padding: 12.5px 10px;
      min-height: 50px;
      .vs__selected-options,
      .vs__selected,
      .vs__actions,
      svg {
        color: #666;
        fill: #aaa;
      }
    }
    &.vs--single {
      .vs__clear {
        display: none;
      }
    }
    &.vs--open {
      .vs__selected-options {
        padding-bottom: 10px;
      }
    }
    .vs__dropdown-menu {
      text-align: left;
      padding-left: 0;
      list-style-type: none;
      max-height: 200px;
      font-size: 2rem;
      color: #4e555b;
      li {
        border-bottom: 1px solid #aaa;
        padding: 10px;
        &:last-child {
          border-bottom-width: 0;
        }
      }
      ::-webkit-scrollbar {
        width: 10px;
      }
      ::-webkit-scrollbar-track {
        border-radius: 10px;
      }
      ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #aaa;
        height: 50px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
      }
    }
    .vs__dropdown-option--selected {
      position: relative;
      &:after {
        content: " ";
        position: absolute;
        color: #aaa;
        background-image: url("/img/insights/tick-blue.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 25px;
        width: 28px;
        height: 20px;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .vs__selected-options {
      .vs__selected {
        background-color: white;
      }
    }
  }
  .select {
    border-radius: 20px;
    border: 1px solid #fff;
    font-family: DMSans, sans-serif;
    background: transparent;
  }

  // track styling
  input[type="range"] {
    height: 36px;
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
  }
  input[type="range"]:focus {
    outline: none;
  }
  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 30px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #eee;
    border-radius: 15px;
    border: 0px solid #000000;
  }
  input[type="range"]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 0px solid #000000;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    background: #aaa;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: 0px;
  }
  input[type="range"]:focus::-webkit-slider-runnable-track {
    background: #eee;
  }
  input[type="range"]::-moz-range-track {
    width: 100%;
    height: 30px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #eee;
    border-radius: 15px;
    border: 0px solid #000000;
  }
  input[type="range"]::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 0px solid #000000;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    background: #aaa;
    cursor: pointer;
  }
  input[type="range"]::-ms-track {
    width: 100%;
    height: 30px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type="range"]::-ms-fill-lower {
    background: #eee;
    border: 0px solid #000000;
    border-radius: 30px;
    box-shadow: 0px 0px 0px #000000;
  }
  input[type="range"]::-ms-fill-upper {
    background: #eee;
    border: 0px solid #000000;
    border-radius: 30px;
    box-shadow: 0px 0px 0px #000000;
  }
  input[type="range"]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 0px #000000;
    border: 0px solid #000000;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    background: #aaa;
    cursor: pointer;
  }
  input[type="range"]:focus::-ms-fill-lower {
    background: #eee;
  }
  input[type="range"]:focus::-ms-fill-upper {
    background: #eee;
  }
  .flex-grow {
    flex-grow: 1;
  }
}
</style>
