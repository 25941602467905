<template>
  <div class="dobGroup" :class="{invalid: !isValidDate}">

    <b-form-group
        :id="id+'form'"
        :label="label"
        :label-for="id"
        :class="{required: required}"
    >
      <b-form-input
          type="text"
          :id="id"
          placeholder="DD"
          class="dobInput"
          @keydown="handleDayInput"
          v-model="day"
          :class="{incomplete: (required && !day) || !over18}"
      />
      <b-form-input
          type="text"
          :id="id+'2'"
          placeholder="MM"
          @keydown="handleMonthInput"
          v-model="month"
          :class="{incomplete: (required && !month) || !over18}"
      />
      <b-form-input
          type="text"
          :id="id+'3'"
          placeholder="YYYY"
          v-model="year"
          :class="{incomplete: (required && year.length !== 4) || !over18}"
      />
    </b-form-group>
    <div v-if="!isValidDate" class="invalidDateMsg">
      This is not a valid date
    </div>
    <div v-if="!over18" class="invalidDateMsg">
      Contact must be over 18 years old
    </div>
  </div>

</template>

<script>

import moment from "moment";
export default {
  name: "TherapyDateOfBirth",
  props: ["label","item", "required","over18Validation"],
  data() {
    let id = this.genRandonString(10);
    return {
      counter: 1,
      id: id,
      day: '',
      month: '',
      year: ''
    }
  },
  methods: {
    handleDayInput(evt) {
      if(isNaN(evt.key) && evt.key !== 'Backspace' && evt.key !== 'Tab') {
        evt.preventDefault();
      } else {
        const day = String(this.day) + String(evt.key)
        const dayNumber = Number.parseInt(day)
        if(dayNumber > 31) evt.preventDefault()
      }
    },
    handleMonthInput(evt) {
      if(isNaN(evt.key) && evt.key !== 'Backspace' && evt.key !== 'Tab') {
        evt.preventDefault();
      } else {
        const m = String(this.month) + String(evt.key)
        const mNumber = Number.parseInt(m)
        if(mNumber > 12) evt.preventDefault()
      }
    },
    extractDate() {
      const split = this.item.split("-")
      this.day = split[0];
      this.month = split[1];
      this.year = split[2]
    }
  },
  computed: {
    over18() {
      if(!this.over18Validation) return true;
      return this.isOver18(this.day + "-" + this.month + "-" + this.year)
    },
    isValidDate() {
      if(!day || !month || !year) return true;
      const day = parseInt(this.day, 10);
      const month = parseInt(this.month, 10) - 1;
      const year = parseInt(this.year, 10);
      return moment({year, month, day}).isValid();
    },
    answer() {
      return this.day + "-" + this.month + "-" + this.year
    }
  },
  mounted() {
    if(this.item) {
      this.extractDate();
    }
  },
  watch: {
    item() {
      this.extractDate();
    },
    answer(val) {
      this.$emit('update',val)
    }
  }
}
</script>

<style lang="less">
.dobGroup {
  .invalidDateMsg {
    color: darkred;
    font-size: 1.4rem;
  }
  &.invalid {
    input {
      border-color: darkred;
    }
  }
  .form-group {
    div {
      display: flex;
      input {
        width: 70px;
        margin-right: 10px;
        text-align: center;
      }
    }
  }
}
</style>