<template>
    <div id="HomeCategoryCard">
        <div class="category-card bg-brand-primary mb-3">
            <div class="category-title">Title</div>
            <div class="category-image-container ml-auto" style="display: block;">
                <img class="img-fluid" src="/img/heart_white.svg" />
            </div>
            
        </div>
    </div>
</template>

<script>
import moment from "moment";

export default {
    name: "ItemCard",
    data() {
        return {
        };
    },
    mounted() {

    },
};
</script>

<style lang="less">
#HomeCategoryCard {
    margin-top: 20px;
    width: 100%;
    cursor: pointer;
    .category-card {
        padding: 15px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

        .category-title {
            color: white;
            font-size: 3rem;
            font-family: "FilsonProBold", sans-serif;
        }
        .category-image-container{
            max-width: 150px;

        }
    }
}
</style>
