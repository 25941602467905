<template>
  <div id="Info" class="container-fluid px-0">
    <div class="main no-side-bar">
      <div v-if="$store.state.user.authenticated">
        <TopNav></TopNav>
        <MobileNav></MobileNav>
      </div>
      <div class="page-header bg-brand-secondary">
        <div class="content-container container">
          <div class="page-title col-12">
            <h2>Info</h2>
          </div>
        </div>
      </div>
      <div class="content-wrap">
        <div class="content-container row py-5">
          <div class="content-card w-100 p-5">
            <div
              class="content-inner p-md-5 m-md-5 p-0 m-0"
              v-html="decodeData(content)"
              v-if="!isLoading"
            ></div>
            <div class="d-flex justify-content-center my-5" v-else>
              <b-spinner class="my-5 mx-auto brand-primary"></b-spinner>
            </div>
          </div>
        </div>
      </div>
      <Footer v-if="$store.state.user.authenticated"></Footer>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import config from "../config/constants";
import TopNav from "@/components/TopNav.vue";
import MobileNav from "@/components/MobileNav.vue";
import Footer from "../components/Footer";
export default {
  name: "TCs",
  components: {
    Footer,
    TopNav,
    MobileNav,
  },
  data() {
    return {
      content: "",
      isLoading: true,
    };
  },
  mounted() {
    this.getContent();
  },
  methods: {
    decodeData(data) {
      try {
        return atob(data);
      } catch (error) {
        return data;
      }
    },
    async getContent() {
      const payload = {
        user_id: this.$store.getters.user_id,
        slug: this.$route.params.code,
      };
      let res = await axios.post(
        config.api_env + "/application/api/u/info/get-info",
        payload,
        config.options
      );
      if (res.data.success) {
        this.content = res.data.dynamic_string.string;
        this.isLoading = false;
      } else if (!res.data.success) {
        this.$router.push("/not-found");
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login").catch((err) => {});
      }
    },
  },
  computed: {
    componentText() {
      return this.getText.terms;
    },
  },
  watch: {
    skin(newVal) {
      this.$store.commit("setSkin", newVal);
    },
  },
};
</script>
<style lang="less">
#Info {
  font-size: 1.6rem;
  font-family: "DMSans", sans-serif;
  .page-header {
    padding: 60px 0;
  }
  .page-title {
    text-align: left;
    h2 {
      text-align: left;
      font-size: 3.8rem;
      letter-spacing: 0.7rem;
      font-family: "FilsonProBold", sans-serif;
      color: #fff;
    }
  }
  .content-wrap {
    background: #f4f4f4;
    white-space: pre-wrap;
    .content-card {
      transform: translateY(-80px);
      background: #ffffff;
      max-width: 1000px;
      margin: auto;
      section {
        margin-bottom: 30px;
      }
      h4 {
        margin-top: 30px;
        margin-bottom: 20px;
      }
    }
  }
  @media (max-width: 992px) {
    .page-header {
      padding-top: 100px;
    }
    .content-card {
      transform: translateY(0);
    }
  }
}
</style>
