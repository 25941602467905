<template>
  <div id="video_page" class="pt-5">
    <div class="" v-if="loading">
      <Loader />
    </div>
    <div v-else class="container mt-5 mb-10">
      <div class="row">
        <div class="col-md-4">
            <div class="img_wrapper">
              <img class="img-fluid img-responsive" :src="coach.avatar_url" :alt="coach.name" />
            </div>
        </div>
        <div class="col-md-4">
          <h4>{{coach.name}}</h4>
          <p>{{coach.description}}</p>
        </div>
        <div class="col-md-4 meeting_time">
          <div class="meeting_time_inner">
            <div class="row no-gutters">
              <div class="col-6">
                <span class="day">{{getDayStringLong(booking.start)}}</span>
                <br/>
                <span class="hour"><i class="fas fa-clock" aria-hidden="true"></i> {{getHoursMinutes(booking.start)}} - {{getHoursMinutes(booking.end)}}</span>
              </div>
              <div class="col-6 text-right">
                <span class="date">{{getDay(booking.start)}}</span>
                <br/>
                <span class="month">{{getMonth(booking.start)}}</span>
              </div>
            </div>
          </div>
          <br/>
<!--          <b-button class="cta-button" v-if="$route.params.tests == 'device-tests'" @click="changeView" block :variant="$route.params.tests == 'device-tests' ? 'purple' : 'outline-purple'">-->
<!--            {{$route.params.tests == 'device-tests' ? 'Go to meeting' : 'Go to device test'}}-->
<!--          </b-button>-->
        </div>
      </div>
      <hr/>
      <transition name="fade">
        <div class="mt-5">
          <VideoRoomMeeting :local_video_track="local_video_track" :local_audio_track="local_audio_track" :booking="booking" :coach="coach"
            @terminateVideoAndAudio="destroyAll"
          />
        </div>
      </transition>

    </div>
  </div>
</template>

<script>

import Loader from "@/components/Loader";
import VideoRoomTests from "@/components/TotalMentalHealth/VideoRoomTests";
import VideoRoomMeeting from "@/components/TotalMentalHealth/VideoRoomMeeting";


const { isSupported } = require('twilio-video');
const { LocalVideoTrack, LocalAudioTrack, createLocalAudioTrack, createLocalVideoTrack } = require('twilio-video');

export default {
  name: "BookingVideoRoom",
  components:{Loader, VideoRoomTests, VideoRoomMeeting},
  data(){
    return {
      loading : true,
      view : 'test',
      tests : {
        audio : {
          testing : false,
          passed : false,
          audio_level : 0,
        },
        video : {
          testing : false,
          passed : false,
        },
        network : {
          testing : false,
          passed : false,
          average_download_speed : 0
        }
      },



      video_room_id : false,
      room_sid : false,
      unique_name : false,
      friendly_name : false,
      participants : [],
      token : false,
      booking : false,

      state : {
        remote_video_active : false,
        preview_video_active : false,
        microphone_active : false,
        coach_connected : false,
      },

      local_video_track : false,
      local_audio_track : false,

      coach: false,
      room : false,

      isSupported : isSupported,
      loopIntervalId: null,
    }
  },
  mounted() {
    this.getBooking()
  },
  provide: function () {
    return {
      createLocalAudio : this.createLocalAudio,
      destroyLocalAudio : this.destroyLocalAudio,
      createLocalVideo : this.createLocalVideo,
      destroyLocalVideo : this.destroyLocalVideo
    }
  },
  beforeDestroy() {
    this.destroyLocalVideo()
    this.destroyLocalAudio()
  },
  methods: {
    destroyAll() {
      this.destroyLocalVideo();
      this.destroyLocalAudio();
    },
    changeView(){
      if(this.$route.params.tests === 'device-tests'){
        this.$router.push({
          path: '/booking-video-room/'+this.$route.params.booking_id
        })
      } else {
        this.$router.push({
          path: '/booking-video-room/'+this.$route.params.booking_id+'/device-tests'
        })
      }
    },
    // CREATE LOCAL MEDIA TRACKS
    async createLocalAudio(){
      try {
        if(this.local_audio_track instanceof LocalAudioTrack){
          console.log('Local audio track already created ....')
        } else {
          this.local_audio_track = await createLocalAudioTrack({
            audio: true,
            dominantSpeaker: true,
            maxAudioBitrate: 16000, //For music remove this line
            networkQuality: {
              local:1,
              remote: 1
            },

          });
        }
      }
      catch (exception_var) {
        console.log('create local audio exception', exception_var)
      }
      finally {
        console.log('create local audio track', this.local_audio_track instanceof LocalAudioTrack)
      }
    },

    async createLocalVideo(){
      try {
        if(this.local_video_track instanceof LocalVideoTrack){
          console.log('Local video track already created ....')
        } else {
          this.local_video_track = await createLocalVideoTrack({
            video: { height: 1080, frameRate: 30, width: 1920, aspectRatio: 16/9 },
            bandwidthProfile: {
              video: {
                mode: 'collaboration',
                dominantSpeakerPriority: 'standard'
              }
            },
            dominantSpeaker: true,
            maxAudioBitrate: 16000, //For music remove this line
            preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
            networkQuality: {
              local:1,
              remote: 1
            }
          });
        }
      }
      catch (exception_var) {
        console.log('create local video exception', exception_var)
      }
      finally {
        console.log('create local video track', this.local_video_track instanceof LocalVideoTrack)
      }
    },


    // DESTROY LOCAL MEDIA TRACKS
    async destroyLocalAudio(){
      try {
        if(this.local_audio_track instanceof LocalAudioTrack){
          this.local_audio_track.stop()
          this.local_audio_track.detach()
          this.local_audio_track = false
        } else {
          console.log('Local audio track not here ....')
        }
      }
      catch (exception_var) {
        console.log('destroy audio video exception: ', exception_var)
      }
      finally {
        console.log('destroy audio video track res: ', this.local_video_track instanceof LocalAudioTrack)
      }
    },

    async destroyLocalVideo(){
      try {
        if(this.local_video_track instanceof LocalVideoTrack){
          this.local_video_track.stop()
          this.local_video_track.detach()
          this.local_video_track = false
        } else {
          console.log('Local video track not here ....')
        }
      }
      catch (exception_var) {
        console.log('destroy video video exception: ', exception_var)
      }
      finally {
        console.log('destroy video video track res: ', this.local_video_track instanceof LocalVideoTrack)
      }
    },
    async getBooking(){
	    await this.createLocalVideo()
	    await this.createLocalAudio()
	    console.log('created')
	    console.log(this.local_video_track)
      let params = {
        path : "api/u/coaching/get-booking",
        booking_id : this.$route.params.booking_id
      }
      let res = await this.api(params)
      if (res.success === true) {
				console.log('get booking is back')
        this.coach = res.coach
        this.booking = res.booking
        this.loading = false
      }
    },

  }
};
</script>

<style scoped lang="less">
  #video_page {
    font-size: 1.6rem;
    background-color: white;
    .img_wrapper {
      text-align: center;
      padding: 0px 20px;
      img {
        border-radius: 50%;
        border : 7px solid #eee;
        box-shadow: 0px 5px 10px rgba(0,0,0,0.09);
      }
    }
    .meeting_time {
      padding: 30px;
      .meeting_time_inner {
        box-shadow: 0px 5px 5px rgba(0,0,0,0.2);
        background-color: #403D56;
        color: white;
        div {
          padding: 6px 10px;
        }
        margin-bottom: 15px;
        border-radius: 15px 15px 0px 15px;
        .day {
          font-size: 17px;
        }
        .hour {
          font-weight: lighter;
          font-size: 14px;
        }
        .date{
          font-size: 30px;
        }
        .month {
          font-size: 17px;
          font-weight: lighter;
        }
        .start_booking {
          position: relative;
          padding: 0px;
          min-height: 25px;
          .start_booking_inner {
            position: absolute;
            right: 0px;
            bottom: 0px;
            background-color: #E83B75;
            padding: 7px 25px;
            border-radius: 15px 0px 0px 0px;
            &:hover {
              cursor : pointer;
            }
          }
        }
      }
    }
    .clock_icon {
      text-align: center;
      font-size: 50px;
    }
  }
</style>
