<template>
  <div id="MyList" class="container">
    <h1>My List</h1>
    <div class="filters" v-if="showFilters">
      <div class="filter">
        <h5>Filter</h5>
        <div class="filterRow">
          <div
            class="filterRowBtn"
            v-for="type in product_info"
            :key="type.product_id"
            @click="updateProductId(type.product_id)"
            :class="{ selected: selected_product_id === type.product_id }"
          >
            {{ type.alias }}
          </div>
        </div>
      </div>
      <!--
      <div class="filter">
        <h5>Product</h5>
        <b-form-select v-model="selected_product_id">
          <b-form-select-option v-for="prod in product_info" :value="prod.product_id">
            {{prod.alias}}
          </b-form-select-option>
        </b-form-select>
      </div>
      -->
    </div>
    <div v-if="isMyListDataLoading">
      <Loader />
    </div>
    <div v-else>
      <div v-if="total_list_items > 0">
        <div class="listHolder">
          <div
            v-for="(item, i) in my_list"
            :key="item.id"
            class="myListItem"
            @mouseover="selected_item = i"
            @mouseleave="selected_item = -1"
            :class="{ selected: selected_item === i }"
          >
            <MyListCard
              :item="item"
              :selected="selected_item === i"
              @deleteMyListItem="deleteMyListItem"
            />
          </div>
        </div>
        <div class="loadMoreHolder">
          <div v-if="areMoreItemsLoading">
            <Loader />
          </div>
          <div
            v-else-if="isLoadMoreBtnShown"
            class="loadMoreBtn"
            @click="loadMoreMyListItems"
          >
            <span>Load More</span>
          </div>
        </div>
      </div>
      <div v-else>
        <p class="defaultMsg">
          {{
            selected_product_id === "0"
              ? "You currently do not have any items in My List"
              : "You currently do not have any items in the selected category"
          }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import MyListCard from "@/components/MyList/MyListCard.vue";
import Loader from "@/components/Loader.vue";

export default {
  name: "MyList",
  components: { MyListCard, Loader },
  data() {
    return {
      my_list: [],
      selected_media_type: "All",
      selected_product_id: "0",
      product_info: [],
      selected_item: -1,
      page: 1,
      total_pages: 1,
      is_my_list_data_loading: true
    };
  },
  mounted() {
    this.getProductInfo();
    this.getMyListItems();
  },
  computed: {
    total_list_items() {
      return this.my_list.length;
    },
    showFilters() {
      return this.total_list_items > 0 || this.selected_product_id !== "0";
    },
    isMyListDataLoading() {
      return this.is_my_list_data_loading && this.total_list_items === 0;
    },
    areMoreItemsLoading() {
      return this.is_my_list_data_loading && this.total_list_items > 0;
    },
    isLoadMoreBtnShown() {
      return this.total_pages > this.page && !this.is_my_list_data_loading;
    }
  },
  watch: {
    selected_product_id() {
      this.page = 1;
      this.getMyListItems();
    }
  },
  methods: {
    deleteMyListItem() {
      this.page = 1;
      this.getMyListItems();
    },
    async getProductInfo() {
      const res = await this.api({ path: "api/u/my-list/product-info-web" });
      if (res.success) {
        this.product_info = [
          { slug: "", product_id: "0", alias: "All" },
          ...res.data
        ];
      }
    },
    async getMyListItems(getNextPage = false) {
      this.is_my_list_data_loading = true;
      if (!getNextPage) this.my_list = [];
      const params = { path: "api/u/my-list/items" };
      if (this.selected_product_id !== "0") {
        params.product_id = this.selected_product_id;
      }
      if (this.selected_media_type && this.selected_media_type !== "All") {
        params.media_type = this.selected_media_type.toLowerCase();
      }

      if (getNextPage) {
        if (this.page >= this.total_pages) {
          this.is_my_list_data_loading = false;
          return;
        }
        params.page = ++this.page;
      } else {
        this.page = 1;
      }

      const res = await this.api(params);
      if (res.success) {
        this.my_list = getNextPage ? this.my_list.concat(res.data) : res.data;
        this.total_pages = res.meta.total_pages;
      }
      this.is_my_list_data_loading = false;
    },
    async loadMoreMyListItems() {
      if (this.is_my_list_data_loading) return;
      this.getMyListItems(true);
    },
    updateProductId(newProductId) {
      this.selected_product_id = newProductId;
    }
  }
};
</script>

<style lang="less">
#MyList {
  h1 {
    font-family: FilsonProBold, Arial, Helvetica, sans-serif;
    font-size: 3rem;
    margin-top: 10px;
    margin-bottom: 15px;
  }
  h5 {
    font-size: 1.45rem;
  }
  .loadMoreHolder {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin-bottom: 150px;
    .button-loading::after {
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      border: 4px solid transparent;
      border-radius: 50%;
      border-top-color: grey;
      border-bottom-color: grey;
      animation: button-loading-spinner 1s ease infinite;
    }
    .loadMoreBtn {
      width: 120px;
      height: 40px;
      padding: 10px 20px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px grey solid;
      border-radius: 10px;
      cursor: pointer;
      position: relative;
      font-family: FilsonProBold, Arial, Helvetica, sans-serif;
      font-size: 1.4rem;
    }
  }
  .filters {
    display: grid;
    grid-template-columns: 1fr;
    .filter {
      margin-bottom: 10px;
      .custom-select {
        font-size: 1.4rem;
      }
      .filterRow {
        display: flex;
        align-items: center;
        justify-content: start;
        .filterRowBtn {
          padding: 6px 12px;
          text-align: center;
          min-width: 45px;
          font-size: 1.6rem;
          cursor: pointer;
          margin-right: 15px;
          background-color: #3a3a3a;
          color: white;
          border-radius: 10px;
          &.selected {
            background-color: white;
            border: 1px solid darkgray;
            color: black;
          }
        }
      }
    }
  }
  .listHolder {
    width: 100%;
    padding: 20px;
    margin-bottom: 25px;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: 15px;
    flex-wrap: wrap;
    .myListItem {
      width: 100%;
      margin-bottom: 20px;
      margin-right: 15px;
    }
  }
  .defaultMsg {
    font-size: 1.5rem;
    margin-top: 12px;
    text-align: center;
  }
  @media only screen and (max-width: 992px) {
    .listHolder {
      grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    }
  }
  @media only screen and (max-width: 750px) {
    .filters {
      grid-template-columns: 1fr;
    }
  }
}
</style>
