<template>
    <div class="row">
        <div class="col-md-3">
            <br/>
            <br/>
            <p>
                Test your gear before the meeting, nulla scelerisque feugiat felis adipiscing ut at non per donec felis est adipiscing nascetur nostra eros massa litora vestibulum imperdiet potenti in.
            </p>
        </div>
        <div class="col-md-3 test">
            <div class="test_result" :class="tests.audio.passed ? 'passed' : 'not_passed'" >
                <transition name="fade">
                    <div v-if="tests.audio.passed">
                        <div class="animated fadeIn infinite">
                            <i class="far fa-check-circle"></i>
                        </div>
                    </div>
                </transition>
            </div>
            <b-button v-if="!tests.audio.testing" @click="testAudio" variant="outline-purple" block>Test Audio</b-button>
            <b-button v-else @click="stopAudioTest" variant="outline-purple" block>Stop Testing</b-button>
            <br/>
            <br/>
            <transition name="fade">
                <div v-if="tests.audio.testing" class="testing">
                    <p>Below bar should indicate if sound detected</p>
                    <br/>
                    <br/>
                    <b-progress height="30px" :value="tests.audio.audio_level" :max="10" animated></b-progress>
                </div>
            </transition>
        </div>
        <div class="col-md-3 test">
            <div class="test_result" :class="tests.video.passed ? 'passed' : 'not_passed'">
                <transition name="fade">
                    <div v-if="tests.video.passed">
                        <i class="far fa-check-circle"></i>
                    </div>
                </transition>
            </div>
            <b-button v-if="!tests.video.testing" @click="testVideo" variant="outline-purple" block>Test Video</b-button>
            <b-button v-else @click="stopVideoTest" variant="outline-purple" block>Stop Testing</b-button>
            <br/>
            <br/>
            <div v-if="tests.video.testing" class="testing">
                <p>You should see the image from your camera below</p>
                <div id="testing_video_container"></div>
            </div>
        </div>
        <div class="col-md-3 test">
            <div class="test_result" :class="tests.network.average_download_speed > 1.2 ? 'passed' : 'not_passed'">
                <transition name="fade">
                    <div v-if="tests.network.average_download_speed > 1.2">
                        <i class="far fa-check-circle"></i>
                    </div>
                </transition>
            </div>
            <b-button @click="testNetworkSpeed" variant="outline-purple" block>Test Network</b-button>
            <br/>
            <br/>
            <div class="text-center testing" v-if="tests.network.average_download_speed">
                <p>Network average speed</p>
                <br/>
                <br/>
                <br/>
                <h2>
                    {{tests.network.average_download_speed}}
                </h2>
                <span>Mbps</span>
            </div>
        </div>
    </div>
</template>

<script>
    const pollAudioLevel = require('@/assets/js/pollaudiolevel');
    const { LocalVideoTrack, LocalAudioTrack } = require('twilio-video');
    const { isSupported } = require('twilio-video');

    export default {
        name: "VideoRoomsTests",
        data(){
            return {
                tests : {
                    audio : {
                        testing : false,
                        passed : false,
                        audio_level : 0,
                    },
                    video : {
                        testing : false,
                        passed : false,
                    },
                    network : {
                        testing : false,
                        passed : false,
                        average_download_speed : 0
                    }
                },
                isVideoSupported : isSupported,
            }
        },
        mounted() {

        },
        beforeDestroy() {

        },
        inject: ['createLocalAudio', 'destroyLocalAudio', 'createLocalVideo', 'destroyLocalVideo'],
        props: ['local_video_track', 'local_audio_track'],
        methods: {
            async testAudio(){
                this.tests.audio.testing = true
                await this.createLocalAudio();
                if(this.local_audio_track instanceof LocalAudioTrack){
                    const t = this
                    pollAudioLevel(this.local_audio_track, level => {
                        t.tests.audio.audio_level = level
                        if(level > 5){
                            t.tests.audio.passed = true
                        }
                    });
                } else {
                    console.log('wtf audio')
                }
            },
            async stopAudioTest(){
                this.tests.audio.testing = false
                this.destroyLocalAudio()
            },

            async testVideo(){
                this.tests.video.testing = true
                await this.createLocalVideo();
                if(this.local_video_track instanceof LocalVideoTrack){
                    const divContainer = document.getElementById('testing_video_container');
                    const videoElement = this.local_video_track.attach();
                    divContainer.appendChild(videoElement);
                    this.tests.video.passed = true
                } else {
                    console.log('wtf video')
                }
            },

            async stopVideoTest(){
                const divContainer = document.getElementById('testing_video_container');
                divContainer.innerHTML = '';
                this.destroyLocalVideo()
                this.tests.video.testing = false
            },

            async testNetworkSpeed(){
                const navigator = window.navigator
                this.tests.network.average_download_speed = navigator.connection.downlink
                this.tests.network.testing = true
            },
        }
    };
</script>

<style lang="less">
    .test {
        text-align: center;
        .test_result {
            font-size: 30px;
            color: green;
            min-height: 50px;
            text-align: center;

        }
        .testing {
            padding: 15px;
        }
        h2 {
            font-size: 60px !important;
            font-weight: bold !important;
            display: inline !important;
        }
        #testing_video_container {
            padding: 15px;
            video {
                width: 80%;
                max-width: 80%;
                border-radius: 5px;
                box-shadow: 0px 5px 15px rgba(0,0,0,0.25);
            }
        }
    }
</style>
