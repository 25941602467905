<template>
    <div class="LandGHomeCard">
        <div class="row d-flex flex-row align-items-center shadow">
            <div class="col-12 d-lg-none px-0">
                <img class="img-fluid content-fit" :src="this.image" />
            </div>
            <div class="col-lg-3">
                <div class="pl-lg-3 py-5">
                    <h1 class="banner-title brand-secondary">{{ this.title }}</h1>
                    <p class="banner-text">
                        {{ this.text }}
                    </p>
                    <b-button variant="cta" @click="goTo()">Learn More</b-button>
                </div>
            </div>
            <div class="image-container px-0 position-relative col-9 d-none d-lg-flex">
                <img class="img-fluid" :class="{ 'align-self-start': type === 'eap' || type === 'studio' }"
                    :src="this.image" />
                <!-- <div class="triangle-bottom-left"></div> -->
                <div class="triangle-bottom-left-2"></div>

            </div>
        </div>
    </div>
</template>
<script>

export default {
    components: {

    },
    name: "LandGHomeCard",
    props: ['type'],
    data() {
        return {
            image: null,
            title: null,
            text: null,
            link: null,
            color: null,
        };
    },
    mounted() {
        if (this.type === "wellbeing") {
            this.image = "/img/landg/banner1.jpg";
            this.title = "Your Wellbeing";
            this.text = "A hub where you'll find useful tools and resources to help maintain your physical, mental, financial and social wellbeing including access to shopping offers and discounts.";
            this.link = '/wellbeing';
            this.color = 'blue';
        } else if (this.type === "eap") {
            this.image = "/img/landg/banner2.jpg";
            this.title = "Confidential Helpline";
            this.text = "Click here to view details of your 24/7 Employee Assistance Programme to help you manage issues in your work or personal life";
            this.link = '/eap';
            this.color = 'green';
        } else if (this.type === "digital-gym") {
            this.image = "/img/landg/banner4.jpg";
            this.title = "Digital Gym";
            this.text = "Classes are tailored for everyone, from beginners to advanced level. Choose from a variety of weekly live and on-demand classes including Pilates, HIIT, yoga, combat and kettlebells.";
            this.link = '/digital-gym';
            this.color = 'red';
        } else {
            this.image = "/img/landg/banner3.jpg";
            this.title = "Wellbeing Series";
            this.text = "Access a series of webinars covering the hot topics in the wellbeing space, including access to a suite of live and pre-recorded podcasts to support your health and wellbeing.";
            this.link = '/wellbeing-studio';
            this.color = 'yellow';
        }
    },
    beforeDestroy() {

    },
    methods: {
        goTo() {
            this.$router.push(this.link);
        },
    },

};
</script>
<style lang="less">
.LandGHomeCard {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 80px;
    margin-left: -15px;
    margin-right: -15px;

    .image-container {
        max-height: 300px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        .img-fluid {
            position: relative;
            width: fit-content;
        }
    }

    .triangle-bottom-left {
        position: absolute;
        top: 0;
        left: 5px;
        width: 0;
        height: 100%;
        z-index: 10;
    }

    .triangle-bottom-left {
        border-style: solid;
        border-width: 350px 150px 0 0;
        border-color: transparent transparent transparent transparent;
    }

    .triangle-bottom-left-2 {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        border-style: solid;
        border-width: 350px 150px 0 0;
        z-index: 100;
        border-color: #fff transparent transparent transparent;
    }

    .banner-title {
        font-size: 2rem;
        font-family: 'FilsonProBold', sans-serif;
        font-weight: bold;
        padding-bottom: 20px;
    }

    .banner-text {
        font-size: 1.4rem;
        padding-bottom: 20px;
    }

    .content-fit {
        object-fit: fill;
        min-width: 100% !important;
    }
}
</style>