<template>
  <div id="ChallengeYourselfDashboard" style="position: relative">
    <i class="fa-regular fa-arrow-left-long challengesBack" style="font-size: 1.5rem" @click="$emit('back')"></i>
    <div class="challengeSelector" v-if="challengeSelected === -1">
      <div class="title">Challenge yourself</div>
      <div class="description topDescription">Great! You’ve taken the first step. What type of challenge do you want to do?</div>
      <div v-for="(challenge,i) in challenges" class="my-5">
        <div class="challengeTitle">{{challenge.title}}</div>
        <div class="challengeDescription">{{challenge.description}}</div>
        <div class="challengeBtn" @click="challengeSelected = i">{{challenge.buttonText}}</div>
      </div>
    </div>
    <DistanceChallenge v-if="challengeSelected === 0" @back="challengeSelected = -1" :activity_types="activity_types"/>
    <ActivityChallenge v-if="challengeSelected === 1" @back="challengeSelected = -1"/>
    <StepsChallenge v-if="challengeSelected === 2" @back="challengeSelected = -1" />
  </div>
</template>

<script>

import DistanceChallenge from "@/components/Challenges/NewChallenges/DistanceChallenge.vue";
import ActivityChallenge from "@/components/Challenges/NewChallenges/ActivityChallenge.vue";
import StepsChallenge from "@/components/Challenges/NewChallenges/StepsChallenge.vue";

export default {
  name: "ChallengeYourselfDashboard",
  components: {
    ActivityChallenge,
    DistanceChallenge,
    StepsChallenge
  },
  props: ['activity_types'],
  data() {
    return {
      challengeSelected: -1,
      challenges: [
        {title: 'Distance Challenge', description: 'Achieve a set distance within your target timeframe', buttonText: "Let's go with a Distance Challenge"},
        {title: 'Activity Challenge', description: 'Achieve a target of minutes per week for any fitness activities that you do.', buttonText: "Let's go with an Activity Challenge"},
        {title: 'Step Challenge', description: 'Achieve a step goal within your target timeframe', buttonText: "Let's go with a Step Challenge"}
      ]
    }
  },
  methods: {
    async createChallenge(data) {
      let params = {
        path: "api/u/challenges/create",
        start_date_time: data.start_date_time,
        end_date_time: data.end_date_time,
      }
      params.target_distance_meters = data.distance_meters?? null;
      params.target_time_seconds = data.elapsed_time_seconds ?? null;
      params.frequency = data.frequency ?? null;
      params.name = 'Challenge';
      if(data.activity_types) {
        params.activity_types = data.activity_types.map(activity => activity.value);
      } else if(data.steps) {
        params.activity_types = [ 'walking','running'];
      } else {
        params.activity_types = this.activity_types.map(activity => activity.value);
      }
      params.target_steps = data.steps ?? null;
      let res = await this.api(params);
      if(res.success) {
        this.challengeSelected = -1;
        this.$emit('created');
      }
    }
  }
}
</script>

<style lang="less">
#ChallengeYourselfDashboard {
  width: 100%;
  color: white;
  padding: 10px 25px;
  .title {
    font-size: 2rem;
    margin-bottom: 10px;
    text-align: center;
  }
  .description {
    font-size: 1.4rem;
    margin-bottom: 15px;
  }
  .topDescription {
    margin-bottom: 45px;
  }
  .challengeTitle {
    font-family: FilsonProBold, Arial, Helvetica, sans-serif;
    font-size: 1.4rem;
    margin-bottom: 5px;
    text-align: left;
  }
  .challengeDescription {
    font-size: 1.4rem;
    margin-bottom: 5px;
  }
  .challengeBtn {
    text-align: center;
    font-size: 1.4rem;
    padding: 10px 0;
    border-radius: 15px;
    background: linear-gradient(90deg, #ED3A75 0%, #6A356A 100%);
    cursor: pointer;
    &:hover {
      background: linear-gradient(90deg, #6A356A 0%, #ED3A75 100%);
    }
  }
}
</style>