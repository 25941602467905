<template>
    <div id="DigitalClinicsBookings" class="bg-white py-5" ref="digital_clinics">

        <div class="row d-flex justify-content-center mb-3" v-if="page===0">
            <b-button variant="cta-outline" class="mx-3" @click="$router.push('/digital-clinics/')">Digital Experts</b-button>
            <b-button variant="cta" class="mx-3" >My Bookings</b-button>
        </div>
        <NewViewBookings></NewViewBookings>
    </div>
</template>
<script>
    import NewViewBookings from "../components/DigitalClinics/NewViewBookings";
   // import { setOptions, bootstrap } from 'vue-gtag'

    export default {
        name: "DigitalClinics",
        components:{
            NewViewBookings,
        },
        data(){
            return{
                page: 0,
                selectedCategory: null,
                selectedBooking: null
            }
        },
        mounted(){
            this.initGoogleAnalytics()
            if(window.zE){
                zE('webWidget', 'show');
            }
            this.enableZendeskWidget();
            //this.enablePlugin();
            this.track();
            this.selectedBooking = event.timeslot
            this.sendBookingAnalyticsEvent(event.category)
        },
        destroyed(){
            zE('webWidget', 'hide');
        },
        watch: {
            page: function (newPage, oldPage) {
                if (newPage === 3) {
                    this.gtag('pageview', {
                        page_path: '/bookings',
                    })
                } else if (newPage === 0) {
                    this.gtag('pageview',{
                        page_path: '/digital-clinics',
                    })
                }
            }
        },
        methods:{
            initGoogleAnalytics(){
                if(!document.getElementById('gtag-script')){
                    let script = document.createElement('script')
                    script.type = 'text/javascript'
                    script.src = 'https://www.googletagmanager.com/gtag/js?id=' + process.env.VUE_APP_GA_TRACKING_ID
                    script.id = 'gtag-script'
                    script.async = true
                    document.head.appendChild(script)
                    window.dataLayer = window.dataLayer || [];
                    this.gtag('js', new Date());
                    this.gtag('config', process.env.VUE_APP_GA_TRACKING_ID);
                }
            },
            gtag(){
                window.dataLayer.push(arguments);
            },
            sendCategoryAnalyticsEvent (category) {
                this.gtag('pageview',{
                    page_path: '/category/' + category.c_label,
                })
            },
            sendBookingAnalyticsEvent (category) {
                this.gtag('event', 'booking-click', {
                    'event_category': 'Booking',
                    'event_label': category.c_label + ' booking',
                    'value': 0
                })
            },
            track() {
                this.gtag('pageview',{
                    page_path: '/digital-clinics',
                })
            },
            /*
             * changed on 27/10/19 to facilitate sanusX
            enablePlugin () {
                setOptions({
                    config: { id: process.env.VUE_APP_GA_TRACKING_ID },
                    params: {
                        send_page_view: false
                    }
                })

                bootstrap().then(() => {
                    // all done!
                })
            }, */
            selectCategory(c_id){
                this.selectedCategory = c_id
                this.page = 1
            },
            viewBookings(event = null){
                this.$emit('scroll-to', -100)
                //this.$refs.logged_in.scrollTop = 0
                //console.log(this.$refs.logged_in.scrollTop)
                this.selectedBooking = event.timeslot
                this.sendBookingAnalyticsEvent(event.category)
                this.page = 3
            },
            enableZendeskWidget(){
                window.$zE||(function(d,s){
                    var z = window.$zE = function(c){
                            z._.push(c)
                        },
                        $ = z.s = d.createElement(s), e = d.getElementsByTagName(s)[0];
                    z.set = function(o){
                        z.set._.push(o)
                    };
                    z._ = [];
                    z.set._ = [];
                    $.async=!0;
                    $.setAttribute("charset","utf-8");
                    $.setAttribute("id","ze-snippet");
                    $.src="https://static.zdassets.com/ekr/snippet.js?key=a93757db-398a-41d0-a483-ef6719ccc5c0";
                    z.t=+new Date;
                    $.type="text/javascript";
                    e.parentNode.insertBefore($,e)
                })(document,"script");
            }
        }
    }
</script>

<style scoped lang="less">
    #DigitalClinics{
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            //IE style
            min-height: 100vh;
            .filter-buttons{
                //justify center hack
                margin-right: 50%;
                transform: translateX(50%);
            }
        }
        .filter-buttons{
            display: flex;
            justify-content: center;
            .filter-button{
                max-width: 250px;
                width: 100%;
                background-color: #fff;
                color: #d60570;
                border: 1px solid #d60570;
                &.active{
                    background-color: #d60570;
                    color: #fff;
                }
                &:hover{
                    border-color: #b1045c;
                    background-color: #b1045c;
                    color: #fff;
                }
            }
        }
    }
</style>