<template>
  <section class="pulse-container" id="coaching-survey-modal">
    <div class="pulse-form-container content-container">
      <div class="pulse-form-header bg-brand-secondary">
        <h4>A question before you go</h4>
      </div>
      <div class="content-container p-4 d-flex flex-column justify-content-center align-items-center" v-if="!loading">
        <b-row v-if="survey_valid && !complete">
          <b-col cols="10" offset="1" class="mb-4">
            <p class="text-center brand-secondary questionText">{{questions[0].text}}</p>
          </b-col>
          <b-col cols="10" offset="1">
            <div ref="slider-holder">
              <output ref="tooltip" for="distance" :value="sliderAnswer" class="range-slider-tooltip" :style="{'left':left}">{{ sliderAnswer }}</output>
              <input
                  ref="slider"
                  type="range"
                  id="pulse-slider"
                  class="pulse-answer-slider w-100"
                  :min="1"
                  :max="10"
                  :value="sliderAnswer"
                  @input="changeSliderAnswer()"
                  style="padding: 0px 0px 0px 0px; height: 30px"
              />
            </div>

            <b-col cols="12" offset="0" class="d-flex justify-content-between p-0">
              <div class="scaleNumber">1</div>
              <div class="scaleNumber">10</div>
            </b-col>
            <b-col cols="12" offset="0" class="d-flex justify-content-between p-0">
              <div class="scaleText">(Extremely <br> Unlikely)</div>
              <div class="scaleText text-right">(Extremely <br> Likely)</div>
            </b-col>
          </b-col>
          <b-col cols="12" v-if="sliderMoved" class="d-flex flex-column justify-content-center align-items-center mt-3">
<!--            <p class="scaleNumber text-center" v-if="sliderMoved">Your Answer: &nbsp;&nbsp;&nbsp; {{sliderAnswer}}</p>-->
            <p class="text-center brand-secondary questionText" v-if="sliderAnswer < 9">
              <span v-if="sliderAnswer < 7">We're sorry to hear that.<br></span>
              <span v-else>Thank you for your feedback.<br></span>
              How could we have improved your experience?
            </p>
            <p>

            </p>
            <p class="text-center brand-secondary questionText" v-if="sliderAnswer >= 9">
              Thank you for your feedback, we're glad you had a positive experience
            </p>
            <input
                type="text"
                id="pulse-text-input"
                class="pulse-text-input mx-auto"
                v-model="textAnswer"
                v-if="sliderAnswer < 9"
            />
          </b-col>
          <div class="mb-3 col-12 d-flex justify-content-end align-items-center">
            <b-button  class="cta-button coach-survey-submit-btn" :disabled="!submitEnabled" @click="submitAnswers">Submit Feedback</b-button>
          </div>
        </b-row>
        <b-row v-if="!survey_valid || complete">
          <b-col cols="10" offset="1">
            <p class="text-center brand-secondary questionText" v-if="!survey_valid">Sorry, but this link is no longer valid!</p>
            <p class="text-center brand-secondary questionText" v-if="survey_valid && complete">Thank you for submitting your feedback!</p>
          </b-col>
          <div class="mb-3 col-12 d-flex justify-content-center align-items-center">
            <b-button  class="cta-button my-4" href="/">Sign in</b-button>
          </div>
        </b-row>

      </div>
      <div class="content-container p-4" v-if="loading">
        <div class="d-flex flex-row align-items-center question-spinner">
          <div class="mx-auto my-auto">
            <b-spinner class="brand-primary"></b-spinner>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>

import axios from "axios";
import config from "@/config/constants";

export default {
  name: "CoachingSurvey",
  data() {
    return {
      survey_key: "",
      questions: [],
      loading: true,
      survey_valid: true,
      sliderAnswer: 0,
      sliderMoved: false,
      textAnswer: "",
      complete: false,
      left: '15px'
    }
  },
  mounted() {
      this.isSurveyValid();
  },
  computed: {
    submitEnabled() {
      return this.sliderMoved
              && (this.textAnswer || this.sliderAnswer >= 9)
    }
  },
  watch: {
    'sliderAnswer': function() {
      this.setToolTip();
    }
  },
  methods: {
    setToolTip() {
      if(this.sliderAnswer === "1") {
        this.left = '15px'
        return
      };
      if(this.sliderAnswer === "10") {
        this.left = (this.$refs.slider.clientWidth - 15) + 'px'
        return;
      };
      let percentage = 100 / 9 * this.sliderAnswer;
      let sliderWidth = this.$refs.slider.clientWidth;
      let tooltipWidth = this.$refs.tooltip.clientWidth;

      let calc = sliderWidth - tooltipWidth;
      let positionCalc = ((percentage / 100) * calc) -(36 / 512 * this.$refs.slider.clientWidth );

      this.left = positionCalc + 'px';
    },
    async submitAnswers() {
      const question1 = this.questions[0];
      question1.answer = this.sliderAnswer;
      const question2 = this.questions[1];
      question2.answer = this.textAnswer;
      let payload = {
        survey_key: this.$route.query.key,
        question1: question1,
        question2: question2
      };
      await axios.post(config.api_env + '/application/api/e/survey/answer-coaching-survey',
          payload,
          config.options)
      this.complete = true;
    },
    async isSurveyValid() {
      let payload = {
        survey_key: this.$route.query.key,
      };
      try {
        const res = await axios.post(config.api_env + '/application/api/e/survey/validate-survey',
          payload,
          config.options)
        if(res.data.survey_valid) {
          this.survey_valid = true;
          this.survey_key = this.$route.query.key;
          this.questions = res.data.questions;
          this.currentQuestion = res.data.questions[0];
          this.loading = false;
          this.$bvModal.show('coaching-survey-modal');
        } else {
          this.survey_valid = false;
          this.loading = false;
          this.$bvModal.show('coaching-survey-modal');
        }
      } catch (e) {
        this.survey_valid = false;
        this.loading = false;
        this.$bvModal.show('coaching-survey-modal');
      }

    },
    changeSliderAnswer() {
      this.sliderMoved = true,
      this.sliderAnswer = document.getElementById("pulse-slider").value;
    },
  }
}
</script>

<style lang="less">
#coaching-survey-modal{
  width: 90%;
  max-width: 650px;
  margin: auto;
  background-color: white;
  border-radius: 15px;
  .my-information {
    font-family: FilsonProBold;
  }
  .cta-button {
    padding: 10px 40px;
  }
  .sub-header {
    padding: 15px;
    font-size: 1.8rem;
    font-weight: bold;
  }
  .pulse-form-header {
    border-radius: 15px 15px 0px 0px;
    font-weight: bold;
    padding: 15px;
    text-align: center;
    color: white;
  }
  .pulse-container {
    background-color: #f8f8f8;
    min-height: 300px;
    border-radius: 300px;
  }
  .form-control {
    font-family: "DMSans", sans-serif;
    border: 0;
    background: transparent;
    border-bottom: 1px solid #E6E6E6;
    font-size: 14px;
    height: auto !important;
  }

  .form-control:focus {
    outline: none !important;
    border: unset;
    border-bottom: 1px solid #E83B75 !important;
    box-shadow: unset;
  }

  .form-control:disabled {
    color: #d6d6d6;
    height: auto !important;
  }
  .custom-select {
    font-family: "DMSans", sans-serif;
    border: 0;
    background: transparent;
    border-bottom: 1px solid #E6E6E6;
    font-size: 14px;
  }
  label, .details-heading {
    color: #322B5F;
    font-size: 1.6rem;
    font-family: "DMSans", sans-serif;
  }
  .custom-select:focus {
    outline: none !important;
    border: unset;
    border-bottom: 1px solid #E83B75 !important;
    box-shadow: unset;
  }
  input[type="range"] {
    height: 36px;
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
  }
  input[type="range"]:focus {
    outline: none;
  }
  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 30px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #eee;
    border-radius: 15px;
    border: 0px solid #000000;
  }
  input[type="range"]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 0px solid #000000;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    background: #aaa;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: 0px;
  }
  input[type="range"]:focus::-webkit-slider-runnable-track {
    background: #eee;
  }
  input[type="range"]::-moz-range-track {
    width: 100%;
    height: 30px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #eee;
    border-radius: 15px;
    border: 0px solid #000000;
  }
  input[type="range"]::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 0px solid #000000;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    background: #aaa;
    cursor: pointer;
  }
  input[type="range"]::-ms-track {
    width: 100%;
    height: 30px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type="range"]::-ms-fill-lower {
    background: #eee;
    border: 0px solid #000000;
    border-radius: 30px;
    box-shadow: 0px 0px 0px #000000;
  }
  input[type="range"]::-ms-fill-upper {
    background: #eee;
    border: 0px solid #000000;
    border-radius: 30px;
    box-shadow: 0px 0px 0px #000000;
  }
  input[type="range"]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 0px #000000;
    border: 0px solid #000000;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    background: #aaa;
    cursor: pointer;
  }
  input[type="range"]:focus::-ms-fill-lower {
    background: #eee;
  }
  input[type="range"]:focus::-ms-fill-upper {
    background: #eee;
  }
  .flex-grow {
    flex-grow: 1;
  }
  .scaleNumber {
    font-family: "FilsonProBold";
    font-size: 1.5rem;
  }
  .questionText {
    font-size: 1.5rem;
  }
  .pulse-text-input {
    border: rgb(101, 116, 130) 1px solid !important;
    width: 80%;
    margin: auto;
    text-align: center;
    font-size: 1.2rem;
    color: #666;
    padding: 10px;
    min-height: 50px;
    :focus {
      border: rgb(101, 116, 130) 1px solid !important;
    }
    :focus-visible {
      border: rgb(101, 116, 130) 1px solid !important;
      outline: none !important;
    }
  }
  .content-container {
    min-height: 150px;
  }
  .question-spinner {
    border-radius: 16px 16px 0 0;
    margin-top: 0;
    padding-top: 0;
    background-color: white;
    min-height: 320px;
  }
  .range-slider-tooltip {
    position: absolute;
    top: -25px;
    margin: 0;
    padding: 2px 10px;
    padding-bottom: 5px;
    background: rgb(18, 44, 53);
    color: white;
    font-size: 1.4rem;
    font-weight: 600;
  }
  .range-slider-tooltip {
    clip-path: polygon(
        0% 0%, // Top left point
        100% 0%, // Top right point
        100% calc(100% - 5px), // Bottom right point
        calc(50% + 5px) calc(100% - 5px), // Center right of the triangle
        50% 100%, // Tip of the triangle
        calc(50% - 5px) calc(100% - 5px), // Center left of the triangle
        0% calc(100% - 5px) // Bottom left point
    );
  }
  .coach-survey-submit-btn {
    margin-top: 30px;
    margin-bottom: 10px;
  }

}
</style>