<template>
    <div id="ExerciseSearch" class="bg-grey py-5">
        <div class="content-container row py-5">
            <div class="search-bar col-12">
                <b-form-input
                        v-model="searchQuery"
                        type="text"
                        placeholder="Search">
                </b-form-input>
                <b-button-group class="filter-buttons my-5 row" fluid>
                    <b-button @click="listExercises('upper_body')" :class="{active: selectedCategory==='upper_body'}" class="filter-button px-5 px-md-5 py-3 mx-2 mx-md-3 my-2">{{componentText.upperBody}}</b-button>
                    <b-button @click="listExercises('lower_body')" :class="{active: selectedCategory==='lower_body'}" class="filter-button px-5 px-md-5 py-3 mx-2 mx-md-3 my-2">{{componentText.lowerBody}}</b-button>
                    <b-button @click="listExercises('cardio')" :class="{active: selectedCategory==='cardio'}" class="filter-button  px-5 px-md-5 py-3 mx-2 mx-md-3 my-2">{{componentText.cardio}}</b-button>
                    <b-button @click="listExercises('body_weight')" :class="{active: selectedCategory==='body_weight'}" class="filter-button  px-5 px-md-5 py-3 mx-2 mx-md-3 my-2">{{componentText.bodyWeight}}</b-button>
                    <b-button @click="listExercises('all')" :class="{active: selectedCategory==='all'}" class="filter-button  px-5 px-md-5 py-3 mx-2 mx-md-3 my-2">{{componentText.all}}</b-button>
                </b-button-group>
            </div>
            <div class="col-12">
                <div class="section-header">
                    <div class="section-header-divider"></div>
                    <h3 class="section-header-title">{{resultCount}} {{componentText.results}}</h3>
                </div>
            </div>
            <div class="col-12 page-filter" v-if="pages > 0">
                <b-button-group class="cta-button d-inline-flex mb-5 px-3">
                    <b-button v-if="page" @click="previousPage" class="cta-button mr-1"><font-awesome-icon icon="chevron-left" ></font-awesome-icon></b-button>
                    {{page + 1}} {{componentText.of}} {{pages}}
                    <b-button v-if="page +1 < pages" @click="nextPage" class="cta-button ml-1"><font-awesome-icon icon="chevron-right" ></font-awesome-icon></b-button>
                </b-button-group>
            </div>
            <div v-for="exercise in exercises" @click="selectExercise(exercise.exercise_id)" class="col-lg-2 col-md-4 col-6 mb-4 exercise-slide">
                <div class="slide-image">
                    <img :src="exercise.exercise_img"/>
                </div>
                <div class="slide-content bg-brand-secondary py-4">
                    <p>{{exercise.exercise_name}}</p>
                    <p class="mx-3" v-if="exercise.exercise_plan_type ==='time'">{{exercise.exercise_plan_time_secs}} {{componentText.seconds}}</p>
                </div>
            </div>
        </div>
        <ExercisePopup v-if="showPopup" :currentExercise="currentExercise" v-on:close="hidePopup"></ExercisePopup>
    </div>
</template>

<script>
    // @ is an alias to /src
    import axios from "axios";
    import config from "../../config/constants";
    import ExercisePopup from "./ExercisePopup.vue"
    export default {
        name: "ExerciseSearch",
        components: {
            ExercisePopup
        },
        data() {
            return {
                searchQuery: '',
                selectedCategory: 'all',
                exercises: {},
                resultCount: '',
                page: 0,
                pages: '',
                showPopup: false,
                currentExercise: '',
            };
        },
        mounted() {
            this.listExercises('all', '')
        },
        methods: {
            async listExercises(category){
                this.selectedCategory = category
                const payload = {
                    user_id: this.$store.getters.user_id,
                    category: category,
                    keywords: this.searchQuery,
                    page: this.page,
                    page_size: 12
                }
                let res = await axios.post(config.api_env + "/application/api-hc/get-surge-exercises", payload, config.options)
                if(res.data.success){
                    this.exercises = res.data.exercises
                    this.resultCount = res.data.count
                    this.pages = Math.ceil(res.data.count / 12)
                }
            },
            async selectExercise(id){
                const payload = {
                    user_id: this.$store.getters.user_id,
                    exercise_id: id
                }
                let res = await axios.post(config.api_env + "/application/api-hc/get-single-exercise", payload, config.options)
                if(res.data.success) {
                    this.currentExercise = res.data.exercise
                    this.showPopup = true;
                }

            },
            previousPage(){
                this.page = this.page -1
                this.listExercises(this.selectedCategory)
            },
            nextPage(){
                this.page = this.page +1
                this.listExercises(this.selectedCategory)
            },
            hidePopup(){
                this.showPopup = false
            }
        },
        watch:{
            searchQuery(){
                this.listExercises(this.selectedCategory)
            },
        },
        computed:{
            componentText() {
                return this.getText.loggedIn.fitness.exerciseSearch;
            },
        }
    };
</script>
<style lang="less">
    #ExerciseSearch{
        .search-bar{
            input {
                max-width: 670px;
                margin: auto;
                border-radius: 25px;
                height: 50px;
                font-size: 2.4rem;
                font-family: "DMSans", sans-serif;
                padding: 0 30px 5px 30px;
            }
        }
        .filter-buttons{
            width: 100%;
            margin: auto;
            font-family: DMSans;
            align-items: center;
            justify-content: center;
            .filter-button{
                flex-grow: 0;
            }

        }
        .section-header-title{
            font-family: DMSans;
            font-size: 1.8rem;
        }
        .page-filter{
            text-align: right;
            .btn-group{
                justify-content: center;
                align-items: center;
                height: 35px;
                border-radius: 25px;
                font-size: 2rem;
                font-family: DMSans
            }
        }
        .exercise-slide{
            width: 170px;
            cursor: pointer;
            height: 100%;

            .slide-image {
                img {
                    width: 100%;
                }
                border-bottom-width: 5px;
                border-bottom-style: solid;
            }
            .slide-content{
                text-align: center;
                height: 88px;
                display: flex;
                align-items: center;
                justify-content: center;
                p{
                    font-family: 'DMSans', sans-serif;
                    font-size: 1.6rem;
                    color: #ffffff;
                }
            }
        }
        .exercise-pop-up{
            display: flex;
            align-items: center;
            z-index: 500;
            position:fixed;
            top:0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            text-align: center;
            .pop-up-content{
                max-width: 1200px;
                width: 100%;
                margin: auto;
                border-radius: 25px;
                font-size: 1.6rem;
                text-align: left;
                z-index: 10;
                color: #fff;
                position: relative;

                .workout-info{
                    h4 {
                        font-size: 2.1rem;
                        font-family: 'FilsonProBold', sans-serif;
                    }
                    h5{
                        font-size: 1.9rem;
                        font-family: 'FilsonProBold', sans-serif;
                        img{
                            width: 30px;
                        }
                    }
                    p{
                        font-size: 1.6rem;
                        font-family: 'DMSans', sans-serif;
                    }
                    .exercise-equipment{
                        border-width: 1px 0 1px 0;
                        border-color: #c1c1c1;
                        border-style: solid;
                    }
                }
                .close-popup{
                    position: absolute;
                    top: 0;
                    right: 0;
                    background-color: transparent;
                    width: 50px;
                    height:50px;
                    svg{
                        width: 30px;
                        height: 30px;
                    }
                }
            }
            .pop-up-background{
                width: 100vw;
                height: 100vh;
                position: absolute;
                left: 0;
                right: 0;
                z-index: 5;
                background: transparent;
            }
        }
        @media only screen and (max-width: 768px){
            .filter-button{
                letter-spacing: 0;
            }
        }
    }
</style>
