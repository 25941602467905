<template>
    <div class="slider__wrapper"
        @mousedown="onSliderClick"
        @touchstart="onSliderClick">
        <div class="slider__content" ref="sliderContent">
            <div class="slider__deliver"></div>
            <div class="slider__thumb" ref="sliderThumb"></div>
        </div>
        <div class="slider__value-container">
            <div class="slider__value" v-for="(value, index) in options" :key="index">
            {{value.label}}
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "InsightsSlider",

        data() {
            return {
                min: 1,
                step: 1,
                stepValue: []
            }
        },
        mounted: function () {
            this.initSlider();
        },
        beforeDestroy: function () {
            const self = this;
            document.removeEventListener("mousemove", self.setCurrentValueOnMouseMove, true);
            document.removeEventListener("mouseup", self.onMouseUp);
            document.removeEventListener("touchmove", self.setCurrentValueOnMouseMove, true);
            document.removeEventListener("touchend", self.onMouseUp);
        },
        props: {
            value: 0,
            options: '',
            prev: '',
            callback: null
        },
        computed: {
            max: function () {
                const self = this;
                return self.options.length;
            },
        },
        methods: {
            initSlider: function () {
                if(typeof this.value === 'string'){
                    const val = parseInt(this.value.split('-')[1])
                    this.setThumbPosition(val);
                }else {
                    this.setThumbPosition(this.value);
                    //emit 3 incase the user doesn't move the slider
                    this.$emit("value", 3)
                }
            },
            onSliderClick: function (event) {
                const self = this;
                const sliderElement = self.$refs.sliderContent;

                if (sliderElement) {
                    self.setCurrentValueOnMouseMove(event);
                    document.addEventListener("mousemove", self.setCurrentValueOnMouseMove, true);
                    document.addEventListener("mouseup", self.onMouseUp);

                    document.addEventListener("touchmove", self.setCurrentValueOnMouseMove, true);
                    document.addEventListener("touchend", self.onMouseUp);
                }
            },
            onMouseUp: function () {
                const self = this;
                document.removeEventListener('mousemove', self.setCurrentValueOnMouseMove, true)
            },
            setCurrentValueOnMouseMove: function (event) {
                const clientRect = this.$refs.sliderContent.getBoundingClientRect();
                const sliderContentWidth = clientRect.width;
                const sliderContentLeft = clientRect.left;

                let currentSliderPosition = 0;

                if (event.type === 'touchmove') {
                    currentSliderPosition = event.changedTouches[0].clientX - sliderContentLeft;
                } else {
                    currentSliderPosition = event.clientX - sliderContentLeft;
                }

                const currentValue = Math.round((this.max - this.min  ) * (currentSliderPosition / sliderContentWidth)) + this.min;

                if (this.callback) {
                    this.callback(currentValue)
                }
                this.$emit('value', currentValue)
                this.setThumbPosition(currentValue);
            },
            setThumbPosition: function (value) {
                if (value >= this.min && value <= this.max) {
                    this.$refs.sliderThumb.style.left = (value - +this.min) / (+this.max - +this.min) * 100 + '%';
                }
            },
        },

    }
</script>
<style lang="less">
    .slider__content {
        position: relative;
        margin: 0 12px 0 5px;
        height: 1px;
        background: repeating-linear-gradient(
                to right,
                rgba(255, 255, 255, 0.5),
                rgba(255, 255, 255, 0.5) 6px,
                transparent 5px,
                transparent 10px
        );

    }

    .slider__value-container {
        font-family: DMSans;
        font-size: 21px;
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 25px 0;
        text-align: center;
        color: #fff;
    }

    .slider__content::before,
    .slider__content::after {
        content: "";
        position: absolute;
        top: 20%;
        transform: translateY(-50%);
        width: 15px;
        height: 15px;
        border-radius: 50%;
    }

    .slider__content::before {
        left: 0;
        transform: translate(-70%, -50%);
    }

    .slider__content::after {
        right: 0;
        transform: translate(70%, -50%);
    }

    .slider__deliver {
        position: absolute;
        left: 50%;
        top: 0;
        height: 24px;
        width: 2px;
        transform: translateY(-50%);
        background: #88D2DC;
    }

    .slider__thumb {
        position: absolute;
        left: 50%;
        top: 20%;
        transform: translate(-50%, -50%);
        z-index: 1;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        background: #ED3A75;
        transition: left 0.3s;
    }

    .slider__thumb:hover {
        cursor: pointer;
    }

    @media all and (max-width: 575px){
        .slider__thumb {
            width: 30px;
            height: 30px;
        }

        .slider__content {
            margin: 0 10px 0 5px;
        }
    }
</style>