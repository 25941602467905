<script>
    import {HorizontalBar, mixins} from 'vue-chartjs'
    const { reactiveProp } = mixins

    export default {
        extends: HorizontalBar,
        mixins: [reactiveProp],
        props: {
            chartData: {
                type: Object,
                default: null
            },
            options: {
                type: Object,
                default: null
            }
        },
        mounted(){
            this.renderChart(this.chartData, this.options)
        },
        watch:{
            chartData(data){
                console.log(data)
            }
        }
    }
</script>