<template>
    <div id="view-event">
        <div class="page-header bg-brand-secondary">
            <div class="content-container row">
                <div class="page-title col-12">
                    <b-button class="return p-0 pb-4" @click="$router.back()" alt="return to all events">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.13 35.13"><defs></defs><title>arrow-left</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><circle class="cls-1" cx="17.56" cy="17.56" r="17.56"/><rect class="cls-2" x="9.82" y="16.6" width="15.95" height="2.13"/><polygon class="cls-2" points="14.22 23.47 8.05 17.5 14.22 11.53 15.7 13.06 11.11 17.5 15.7 21.94 14.22 23.47"/></g></g></svg>
                    </b-button>
                    <h2>{{$route.meta.title ? $route.meta.title : componentText.title}}</h2>
                </div>
            </div>
        </div>
        <transition name="fade">
            <div v-if="loading">
                <b-spinner type="grow" label="Loading..."></b-spinner>
            </div>
            <div v-else>
                <div class="bg-white">
                    <div class="content-container row">
                        <div class="col-md-6">
                            <div class="event-card">
                                <h3>{{ event.e_title }}</h3>
                                <div class="event-description" v-html="event.e_description"></div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <EventInfo :event="event"></EventInfo>
                        </div>
                        <div class="col-12">
                            <LargeBookingButton :event="event"></LargeBookingButton>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    // @ is an alias to /src
    import config from "../config/constants";
    import LargeBookingButton from "../components/Event/LargeBookingButton";
    import EventInfo from "../components/Event/EventInfo";
    export default {
        name: "viewEvent",
        components: {
            LargeBookingButton,
            EventInfo
        },
        data() {
            return {
                event: {},
                loading : true
            };
        },
        mounted() {
            this.getEventDate();
            if(this.$route.query.session_id){
                if(!this.$route.query.session_id.includes('cancelled')){
                    this.showMsg();
                }
                //delete the session ID query, in case user goes in and out of stripe
                let query = Object.assign({}, this.$route.query);
                delete query.session_id;
                this.$router.replace({ query });
            }
        },
        methods: {
            async showMsg() {
                if(await this.showMsgModal('Booking Successful')){
                    this.$router.push({name: 'events', query:{page: 'bookings'}, params:{booking_id: this.event.e_id}})
                }
            },
            getEventDate(){
                const url = config.api_env + "/application/api/u/events/get-date";
                const payload = {
                    user_id: this.$store.getters.user_id,
                    event_date_id: this.$route.params.id
                };
                const opts = {
                    'method' : 'post',
                    'url' : url,
                    'data' : payload
                }
                const _this = this;
                this.axios(opts)
                    .then(function (res) {
                        _this.event = res.data.event_date;
                        _this.loading = false
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
        },
        computed:{
            componentText() {
                return this.getText.loggedIn.event;
            },
        }
    };
</script>
<style lang="less">
    #view-event {
        .page-header {
            padding: 60px 0;
        }
        .page-title {
            text-align: left;
            h2 {
                text-align: left;
                font-size: 3.8rem;
                letter-spacing: 0.7rem;
                font-family: "FilsonProBold", sans-serif;
                color: #fff;
            }
            .return{
                background-color: transparent;
                position: absolute;
                bottom: -70px;
                left: 10px;
                svg{
                    width: 35px;
                    .cls-1{
                        fill:#ea3975;
                    }
                    .cls-2{
                        fill:#fff;
                    }
                }
                &:hover{
                    .cls-1{
                        fill: darken(#ea3975, 10%);
                    }
                }
            }
        }
        .content-container {
            padding: 20px 0;
        }
        .event-card {
            position: relative;
            border-left: 1px solid #ccc;
            border-top: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
            margin-right: 50px;
            background-color: #f4f4f4;
            padding: 45px 0 55px 30px;
            box-shadow: 6px -2px 8px 0 rgba(0, 0, 0, 0.25);
            &:before {
                content: "";
                position: absolute;
                top: -1px;
                right: 0;
                height: 60px;
                width: 50px;
                display: inline-block;
                border-right: 1px solid #ccc;
                border-top: 1px solid #ccc;
                box-shadow: 9px 0 5px -4px rgba(0, 0, 0, 0.25);
                transform: skew(40deg) translateX(50%);
                background-color: #f4f4f4;
                z-index: 1;
            }
            &:after {
                content: "";
                position: absolute;
                right: 0;
                bottom: -1px;
                height: calc(100% - 58px);
                width: 50px;
                border-right: 1px solid #ccc;
                border-bottom: 1px solid #ccc;
                background-color: #f4f4f4;
                transform: translateX(100%);
                box-shadow: 4px -4px 5px 0 rgba(0, 0, 0, 0.25);
            }
            h3 {
                font-size: 3.1rem;
                font-family: FilsonProBold;
                width: 70%;
                text-transform: capitalize;
                margin-bottom: 25px;
                letter-spacing: 0;
                padding-top: 0;
            }
            .event-description {
                font-size: 1.7rem;
                color: #394961;
            }
        }
        .waiting-list{
            font-size: 1.7rem;
            color: #394961;
        }
        .event-info {
            font-size: 1.7rem;
            border: 1px solid #ced1d2;
            padding: 38px 38px 30px 38px;
            border-radius: 20px;
            .past-event{
                background-color: #989898;
                margin: 0 -38px;
                padding-left: 25px;
                padding-right: 23px;
                &:first-child {
                    margin: -38px -38px 0 -38px;
                    border-radius: 20px 20px 0 0;
                }
                .disabled{
                    &:hover {
                        background-color: #fff !important;
                    }
                }
            }
            .event-info-item-wrap {
                font-size: 17px;
                display: flex;
                align-items: center;
                .event-info-icon {
                    width: 26px;
                    display: inline-flex;
                    justify-content: center;
                    & > i {
                        font-size: 22px;
                        color: #7e4377;
                    }
                }
                .event-info-item {
                    margin-left: 20px;
                }
            }
        }
        .time-slot {
            text-align: center;
            .btn {
                border-width: 1px;
                background-color: #fff;
                width: 80%;
            }
        }
        .event-register {
            font-family: "DMSans", sans-serif;
            font-size: 1.6rem;
            a {
                color: #212529;
                text-decoration: underline;
            }
        }
        .no-slots{
            font-size: 1.7rem;
            border: 1px solid #CED1D2;
            border-radius: 20px;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            h4 {
                color: red;
                margin-bottom: 0;
            }
        }
        .event-pop-up{
            display: flex;
            align-items: center;
            z-index: 500;
            position:fixed;
            top:0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            text-align: center;
            .pop-up-content{
                background: #fff;
                max-width: 400px;
                width: 100%;
                margin: auto;
                border-radius: 25px;
                padding: 30px 20px;
                font-size: 4rem;
                text-align: center;
                z-index: 10;
                .event-details {
                    border-top-width: 1px !important;
                    border-color: #dee2e6;
                    position: relative;
                    h4, a {
                        font-size: 2.5rem;
                        font-family: 'FilsonProBold';
                        line-height: 0;
                        white-space: nowrap;
                    }
                    .event-title {
                        height: 40px;
                        text-align: left;
                        position: relative;
                        transform: translateY(-50%);
                    }
                    .event-info {
                        font-size: 1.2rem;
                        margin: auto;
                    }
                }
            }
            .pop-up-background{
                width: 100vw;
                height: 100vh;
                position: absolute;
                left: 0;
                right: 0;
                z-index: 5;
                background: transparent;
            }
        }
    }
</style>
