<template>
  <div class="HomeRecipeCard " @click="goToRecipe">
    <div class="recipeCardContainer">
      <div class="row mx-0 w-100">
        <div class="col-12 px-0 recipe-image-wrap" :style="{}">
          <img :src="item.thumbnail">
        </div>
      </div>
    </div>
    <div class="title brand-text">{{item.title}}</div>
    <div class="subtitle brand-text">{{item.subtitle}}</div>
  </div>
</template>

<script>

export default {
  name: "HomeRecipeCard",
  props: ['item', 'type','swiping'],
  data() {
    return {
    };
  },
  mounted() {

  },
  computed: {
    componentText() {
      return this.getText.dfd;
    },
  },
  methods: {
    goToRecipe() {
      if(this.swiping) return;
      this.$router.push('/personal/nutrition?filter=' + this.item.title)
    }
  }
};
</script>

<style lang="less">
@keyframes fadeIn {
  from {
    display: none;
  }

  to {
    display: block;
  }
}

@keyframes fadeOut {
  from {
    display: block;
  }

  to {
    display: none;
  }
}

.HomeRecipeCard {
  &:hover {
    cursor: pointer;
  }
  .bg-black {
    background-color: black;
  }
  .title {
     margin-top: 10px;
     font-family: "FilsonProBold", sans-serif;
     font-size: 1.5rem;
   }
  .subtitle {
    font-size: 1.2rem;
  }
  .recipeCardContainer {
    height: 100%;
    aspect-ratio: 16/9 !important;
    font-family: "FilsonProBold", sans-serif;
    overflow: hidden;
    margin-bottom: 10px;
    border-radius: 10px;
    .divider {
      border-bottom: 3px white solid;
      width: 10%;
      margin-top: 20%;
    }

    .calories-container {
      margin-top: 20%;
      font-family: "FilsonProBold", sans-serif;
      font-size: 1.1rem;
      width: 80%;
    }
    .recipe-image-wrap {
      max-height: 100%;
      overflow: hidden;
      img {
        width: 100%;
        object-fit: cover;
        object-position: center;
        margin-top: -16%;
      }
    }
  }
  @media only screen and (max-width: 1066px) {
    .calories-container {
      font-size: 1.4rem !important;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
  @media only screen and (max-width: 951px) {
    .title {
      font-size: 2.2rem !important;
    }
    .calories-container {
      font-size: 1.8rem !important;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
  @media only screen and (max-width: 767px) {
    .title {
      font-size: 4rem !important;
    }
  }
}
</style>
