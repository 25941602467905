<template>
  <div id="StepsChallenge">
    <i class="fa-regular fa-arrow-left-long challengesBack" style="font-size: 1.5rem" @click="$emit('back')"></i>
    <div class="title">Step Challenge</div>
    <div class="description">What is the goal?</div>
    <div class="stepsHolder">
      <b-input type="text" v-model="steps" @keydown="addCommasToNumber" autocomplete="off"></b-input>
    </div>
    <ChallengeDatePicker ref="datePicker"/>
    <div class="challengesButton mt-5" :class="{invalid: !passedValidation}" @click="create">Create Challenge</div>
  </div>
</template>

<script>
import ChallengeDatePicker from "@/components/Challenges/NewChallenges/ChallengeDatePicker.vue";

export default {
  name: "StepsChallenge",
  components: {ChallengeDatePicker},
  data() {
    return {
      steps: ''
    }
  },
  computed: {
    startDate() {
      return this.$refs.datePicker.startDate;
    },
    endDate() {
      return this.$refs.datePicker.endDate;
    },
    stepsNumber() {
      return Number.parseInt(this.steps.replaceAll(',',''));
    },
    passedValidation() {
      return this.stepsNumber && this.startDate && this.endDate;
    }
  },
  methods: {
    create() {
      this.$parent.createChallenge({
        steps: this.stepsNumber,
        start_date_time: this.startDate + ' 00:00:00',
        end_date_time: this.endDate + ' 00:00:00'
      })
    },
    addCommasToNumber(evt) {
      evt.preventDefault();
      if(isNaN(Number.parseInt(evt.key)) && evt.key !== 'Backspace') return
      let numberString;
      if(evt.key === 'Backspace') {
        numberString = this.steps.replaceAll(',','').slice(0, -1)
      } else {
        numberString = this.steps.replaceAll(',','') + evt.key;
      }
      this.steps = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  }
}
</script>

<style scoped lang="less">
#StepsChallenge {
  .stepsHolder {
    display: flex;
    align-items: center;
    justify-content: center;
    .unit {
      font-size: 1.5rem;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input {
      background-color: transparent !important;
      padding: 15px 10px;
      width: 100%;
      color: white;
      font-size: 1.4rem;
      margin-right: 25px;
    }
  }
}
</style>