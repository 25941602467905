<template>
  <div id="Customise">
    <div class="content-container p-3">
      <div class="customiseHeader">
        <h3>Customise</h3>
        <p>Customise your dashboard! The dashboard is where your employees have access to fitness workouts, recipes, wellbeing plans and more. Choose a colour scheme and logo based on your company's brand guidelines. You can also add pages to your dashboard to keep employees informed of any great events or clubs in your company!</p>
      </div>
      <h4 class="mt-5">Company Logo</h4>
      <div class="logoSelectSection">
        <div class="logoBox">
          <div class="logoContainer"><img :src="loaded_logo" alt="your logo"/></div>
          <div class="btnContainer">
            <label class="imageSelect" for="imgInp">
              Select Image
            </label>
            <div style="position: relative; height: 30px; width: 100%;" v-if="updating_logo">
              <span class="button-loading"></span>
            </div>
            <p class="uploadSuccessMsg" v-if="fileIsValid" style="color: #0BDA51">Click the button below to assign this logo to your organisation</p>
            <p class="successMsg" v-if="display_success_msg" style="color: #0BDA51">
              Your logo has been updated.
            </p>
            <div v-if="file_errors.length">
              <p v-for="error in file_errors" style="color: darkred" class="error_msg">{{error}}</p>
            </div>
            <input type="file" id="imgInp" ref="file" style="display: none;" @change="handleUpload" accept="image/jpg,image/png,image/jpeg">
            <b-button class="btn cta-button" :disabled="!fileIsValid" @click="updateLogo">Save Logo</b-button>
          </div>
        </div>
      </div>
      <h4 class="mt-5" v-if="current_skin < 7">Website Theme</h4>
      <div class="col-12 locations" v-if="current_skin < 7">
        <p>Choose Theme for Your Organisation</p>
        <b-form-select v-model="skin_selected" class="select-location">
          <option v-for="(skin, i) in skin_options" :key="i" :value="i">{{ i }} / {{ skin }} - Dark Mode Available</option>
        </b-form-select>
      </div>
      <p v-if="skin_success_msg" class="mt-2 ml-4" style="color: #0BDA51; font-size: 1.9rem">Your theme has been updated</p>
      <p v-if="skin_error_msg" class="mt-2 ml-4" style="color: darkred; font-size: 1.9rem">An error occurred: {{skin_error_msg}}</p>
      <b-button class="btn cta-button mt-4 ml-4" :disabled="!themeValid" @click="updateSkin">Update Theme</b-button>
    </div>


  </div>
</template>

<script>
export default {
  name: "Customise",
  data() {
    return {
      skin_options: ["Base", "Blue", "Green", "Gold", "Red", "Grey", "Purple"],
      skin_selected: null,
      current_skin: null,
      page_parent_pages: [],
      table_items: [],
      loaded_logo: null,
      file: null,
      binary_file: false,
      file_errors: [],
      display_success_msg: false,
      updating_logo: false,
      skin_success_msg: false,
      skin_error_msg: null
    }
  },
  mounted() {
    this.getPageData();
    this.skin_selected = this.$store.getters.skin;
  },
  computed: {
    themeValid() {
      return this.skin_selected !== null && this.skin_selected !== this.current_skin;
    },
    fileIsValid() {
      try {
        return this.file.length && this.file_errors.length === 0;
      } catch (e) {
        return false;
      }
    }
  },
  methods: {
    updateSkin(){
      this.skin_error_msg = null;
      this.skin_success_msg = false;
      const url = process.env.VUE_APP_API_PATH + '/application/api/c/customisations/update-client-skin-by-skin-number';
      const opts = {
        method: 'post',
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
        },
        body: JSON.stringify({
          "user_id": this.$store.getters.user.user_token,
          "skin": this.skin_selected
        })
      }
      fetch(url, opts).then(response => {
            response.json().then(data => {
              if(data.success == true){
                this.getPageData();
                if(data.skin_updated === true){
                  this.skin_success_msg = true;
                  setTimeout(()=> {
                    this.skin_success_msg = false;
                  }, 4000)
                } else {
                  this.skin_error_msg = data.result;
                  setTimeout(()=> {
                    this.skin_error_msg = null;
                  }, 8000)
                }
              } else {
                this.skin_error_msg = data.err;
                setTimeout(()=> {
                  this.skin_error_msg = null;
                }, 8000)
              }
            });
          }
      )
    },
    handleUpload: function(event) {
      // Reference to the DOM input element
      this.display_success_msg = false;
      let file_errors = [];
      this.file_errors = file_errors;
      let input = event.target;
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        // create a new FileReader to read this image and convert to base64 format
        let img = new Image();
        this.binary_file = input.files[0];
        img.src = window.URL.createObjectURL( input.files[0] );
        img.onload = function() {
          let width = img.naturalWidth,
              height = img.naturalHeight;
          if( width > 600 || height > 400 ){
            file_errors.push('The logo dimensions should be less than 600px in width and 400px in height.');
          }
          window.URL.revokeObjectURL(img.src);
        }
        // Start the reader job - read file as a data url (base64 format)
        if(file_errors.length === 0){
          let reader = new FileReader();
          // Define a callback function to run, when FileReader finishes its job
          reader.onload = (e) => {
            // Read image as base64 and set to file
            if(e.target.result.size > 5120000){
              file_errors.push('File size should less then 5MB.');
            } else {
              this.file = e.target.result;
            }
          }
          reader.readAsDataURL(input.files[0]);
        }
        this.file_errors = file_errors;
      }
    },
    updateLogo(){
      if(!this.binary_file) {
        return;
      }
      this.updating_logo = true;
      let formData = new FormData();
      formData.append('file', this.binary_file);
      formData.append('user_id', this.$store.getters.user.user_token);
      const url = process.env.VUE_APP_API_PATH + '/application/api/c/customisations/update-client-logo';
      const opts = {
        method: 'post',
        body: formData
      }
      fetch(url, opts).then(response => {
            response.json().then(data => {
              this.updating_logo = false;
              if(data.success == true){
                this.display_success_msg = true;
                setTimeout(()=> {this.display_success_msg = false}, 4000);
                this.getPageData();
              } else {
                this.file_errors = ['Sorry, an error occurred: ' + data.err]
              }
            });
          }
      )
    },
    getPageData () {
      const url = process.env.VUE_APP_API_PATH + '/application/api/c/customisations/get-customise-page-data';
      const opts = {
        method: 'post',
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
        },
        body: JSON.stringify({
          "user_id": this.$store.getters.user.user_token
        })
      }
      fetch(url, opts).then(response => {
            response.json().then(data => {
              if(data.success == true){
                this.table_items = data.pages;

                // Builds parent page select input
                this.page_parent_pages = data.pages.map((obj) => {
                  return {
                    text: obj.title,
                    value: obj.post_id
                  }
                });
                this.current_skin = data.client_branding.skin;
                this.loaded_logo = data.client_branding.client_logo;
                this.loading = false;
              }
            });
          }
      )
    },
  },
  watch: {
    skin_selected(newVal) {
      this.$store.commit("setSkin", newVal);
    },
  },
  destroyed() {
    this.$store.commit("setSkin", this.current_skin);
  }
}
</script>

<style lang="less">
#Customise {
  width: 100%;
  min-height: calc(100vh - 200px);
  background-color: white;
  font-family: "FilsonPro", sans-serif;
  h3 {
    font-family: FilsonProBold, Arial, Helvetica, sans-serif !important;
  }
  h4 {
    font-family: FilsonProBold, Arial, Helvetica, sans-serif !important;
  }
  p {
    font-size: 1.4rem;
  }
  .locations{
    font-size: 1.6rem;
    font-family: "DMSans", sans-serif;
    .select-location{
      font-size: 1.6rem;
      font-family: "DMSans", sans-serif;
      height: 40px;
    }
  }
  .logoSelectSection {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    .logoBox {
      width: 100%;
      max-width: 600px;
      border-radius: 20px;
      border: 1px grey dashed;
      display: grid;
      grid-template-columns: 2fr 1fr;
      .logoContainer {
        background-color: #fafafa;
        padding: 8px;
        border-radius: 20px 0 0 20px;
      }
      img {
        width: 100%;
      }
    }
    .btnContainer {
      .imageSelect {
        cursor: pointer;
      }
      padding: 8px;
      font-size: 1.7rem;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      text-align: center;
      background-color: white;
      border-radius: 20px;
    }
  }
  .button-loading::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 4px solid transparent;
    border-radius: 50%;
    border-top-color: black;
    border-bottom-color: black;
    animation: button-loading-spinner 1s ease infinite;
  }
}
</style>