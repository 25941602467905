<template>
  <div id="AdminMyOrg">
    <div class="row grey">
      <div class="col-md-1"></div>
      <div class="col-md-10">
        <br/>
        <h3 class="heading">Add Custom Company Pages</h3>
        <br/>
        <br/>
        <b-table bordered id="grey-bg" :items="table_items" :fields="table_fields">
          <template v-slot:cell(status)="data">
            <i v-on:click="changePostStatus(data.item.post_id, 1)" v-if="data.item.status === 'draft'" class="fa fa-eye-slash"></i>
            <i v-on:click="changePostStatus(data.item.post_id, 0)" v-else class="fa fa-eye"></i>
          </template>
          <template v-slot:cell(action_1)="data">
            <i v-on:click="confirmChangePostStatus(data.item, -1)" class="fa fa-trash"></i>
          </template>
          <template v-slot:cell(action_3)="data">
            <i v-on:click="openPageModal('update', data.item.post_id)" class="fa fa-edit"></i>
          </template>
        </b-table>
        <br/>
        <div class="row">
          <div class="col text-center">
            <b-button variant="purple" v-on:click="openPageModal('create')">Add new Page</b-button>
          </div>
        </div>
        <br/>
        <br/>
      </div>
      <div class="col-md-1"></div>
    </div>
    <!-- MODAL BELOW -->
    <b-modal id="my-org-edit-page-modal"
             :title="page_modal.post_id === 0 ? '+ Add New Company Page':'+ Edit Company Page'"
             size="xl">
      <div class="row">
        <div class="col-md-6">
          <div class="mb-1">
            <b-form-text id="">
              Title
            </b-form-text>
            <b-form-input
                id="input-room"
                v-model="page_modal.title"
                aria-describedby="input-room-help input-live-help input-live-feedback"
                placeholder="Page Title"
                trim
            ></b-form-input>
          </div>
          <div class="mt-3">
            <b-form-text id="">
              Category
            </b-form-text>
            <b-form-select class="rounded form-control" v-model="page_modal.category" :options="page_categories"></b-form-select><br/>
          </div>
          <div class="mt-3">
            <b-form-text id="">
              Parent Page
            </b-form-text>
            <b-form-select class="rounded form-control" v-model="page_modal.parent_page" :options="page_parent_pages"></b-form-select><br/>
          </div>
        </div>
        <div class="col-md-6">
          <b-form-text id="">
            Description
          </b-form-text>
          <textarea placeholder="Page description"
                    id="site-specific-info"
                    rows="7"
                    v-model="page_modal.description"
                    class="form-control">

                    </textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <br/>
          <b-form-text id="">
            Content
          </b-form-text>
          <vue-editor v-model="page_modal.content" :editor-toolbar="customToolbar" />
        </div>
      </div>
      <template v-slot:modal-footer>
        <div>
                    <span id="tooltip-target-1" class="d-inline-block">
                      <b-button
                          style="min-width: 140px"
                          variant="purple"
                          size="sm"
                          class="float-right"
                          @click="processModal"
                          :disabled="newPageModalInvalid"
                      >
                        <span v-if="loading">
                          <b-spinner label="Small Spinner" type="grow" style="width: 1.2rem; height: 1.2rem;"></b-spinner>
                        </span>
                        <span v-else>{{page_modal.mode === 'create' ? 'Add' : 'Update'}} Page</span>
                      </b-button>
                    </span>
          <b-tooltip target="tooltip-target-1" triggers="hover" v-if="newPageModalInvalid">
            {{modalErrorMessage}}
          </b-tooltip>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>

import { VueEditor } from "vue2-editor";
export default {

  name: "AdminMyOrg",
  components: {VueEditor},
  data() {
    return {
      table_items: [],
      table_fields: fields,
      loading: false,
      page_modal: {
        title : '',
        description : '',
        content : '',
        category : '',
        mode : 'create',
        parent_page: '',
        post_id : 0
      },
      page_categories : [
        {
          value : 1,
          text : 'Fitness'
        },
        {
          value : 2,
          text : 'Nutrition'
        },
        {
          value : 8,
          text : 'Wellbeing'
        },
        {
          value : 9,
          text : 'General'
        }
      ],
      page_parent_pages: [],
      page_to_delete: 0,
      customToolbar: [
        [{ 'header': [false, 1, 2, 3, 4, 5, 6, ] }],
        ['bold', 'italic', 'underline', 'strike'],
        // [{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
        // [{ 'color': [] }, { 'background': [] }],
        ['link'],
      ]
    }
  },
  mounted() {
    this.getPageData();
  },
  computed: {
    modalErrorMessage() {
      if(!this.page_modal.title && !this.page_modal.content) return "Title and Content cannot be blank!";
      if(!this.page_modal.title) return "Title cannot be blank!";
      return "Content cannot be blank!"
    },
    newPageModalInvalid() {
      return !(this.page_modal.title && this.page_modal.content);
    }
  },
  methods: {
    deleteAction(){
      this.changePostStatus(this.page_to_delete, -1);
      this.page_to_delete = 0;
    },
    createPage () {
      this.loading = true;
      const url = process.env.VUE_APP_API_PATH + '/application/api/c/customisations/create-company-page';
      const opts = {
        method: 'post',
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
        },
        body: JSON.stringify({
          "user_id": this.$store.getters.user_id,
          "title": this.page_modal.title,
          "description": this.page_modal.description,
          "category": this.page_modal.category,
          "parent_page": this.page_modal.parent_page,
          "content": encodeURIComponent(this.page_modal.content)
        })
      }
      fetch(url, opts).then(response => {
            response.json().then(data => {
              this.loading = false;
              if(data.saved == true){
                this.getPageData();
                this.$bvModal.hide('my-org-edit-page-modal');
              }
            });
          }
      )
    },
    updatePage () {
      this.loading = true;
      const url = process.env.VUE_APP_API_PATH + '/application/api/c/customisations/update-company-page';
      const opts = {
        method: 'post',
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
        },
        body: JSON.stringify({
          "user_id": this.$store.getters.user_id,
          "title": this.page_modal.title,
          "description": this.page_modal.description,
          "category": this.page_modal.category,
          "content": this.page_modal.content,
          "parent_page": this.page_modal.parent_page,
          "post_id" : this.page_modal.post_id
        })
      }
      fetch(url, opts).then(response => {
            response.json().then(data => {
              this.loading = false;
              if(data.success == true){
                this.getPageData();
                this.$bvModal.hide('my-org-edit-page-modal');
              }
            });
          }
      )
    },
    processModal(){
      if(this.loading) return;
      if(this.page_modal.mode === 'create'){
        this.createPage()
      } else {
        this.updatePage('update', this.page_modal.post_id)
      }
    },
    getPageData () {
      const url = process.env.VUE_APP_API_PATH + '/application/api/c/customisations/get-customise-page-data';
      const opts = {
        method: 'post',
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
        },
        body: JSON.stringify({
          "user_id": this.$store.getters.user_id
        })
      }
      fetch(url, opts).then(response => {
            response.json().then(data => {
              if(data.success == true){
                this.table_items = data.pages;

                // Builds parent page select input
                this.page_parent_pages = data.pages.map((obj) => {
                  return {
                    text: obj.title,
                    value: obj.post_id
                  }
                });

                this.loading = false;
              }
            });
          }
      )
    },
    changePostStatus(id, status){
      const url = process.env.VUE_APP_API_PATH + '/application/api/c/customisations/set-company-page-status';
      const opts = {
        method: 'post',
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
        },
        body: JSON.stringify({
          "user_id": this.$store.getters.user_id,
          "post_id": id,
          "status": status
        })
      }
      fetch(url, opts).then(response => {
            response.json().then(data => {
              if(data.success == true){
                this.getPageData();
              } else {
                this.$store.commit('setAlert', {
                  visible : true,
                  text: data.err,
                  color: 'danger'
                });
              }
            });
          }
      )
    },
    async confirmChangePostStatus(post){
      this.page_to_delete = post.post_id;
      const answer = await this.showConfirmModal("Are you sure you want to delete this page");
      if(answer) this.deleteAction();
    },
    openPageModal(mode, id = 0){
      if(mode === 'create'){
        this.page_modal.title = '';
        this.page_modal.description = '';
        this.page_modal.content = '';
        this.page_modal.category = '';
        this.page_modal.post_id = id;
        this.page_modal.parent_page = '';
        this.page_modal.mode = 'create';
      } else {
        this.table_items.map(post => {
          if(post.post_id === id){
            let cat_value = 0;
            this.page_categories.map(cat => {
              if(cat.text === post.category_id){ // this is bullshit on API level
                cat_value = cat.value
              }
            });
            this.page_modal.title = post.title;
            this.page_modal.description = post.content.content_description;
            this.page_modal.content = post.content.full_content;
            this.page_modal.category = cat_value;
            this.page_modal.post_id = post.post_id;
            this.page_modal.parent_page = post.parent_page;
            this.page_modal.mode = 'update';
          }
        })
      }
      this.$bvModal.show('my-org-edit-page-modal')
    },
  }

}
let fields = [
  {
    key : 'title',
    label: 'Title',
    sortable: true
  },
  {
    key : 'category_id',
    label: 'Category',
    sortable: true
  },
  {
    key: 'status',
    label: 'Visibility',
    class: 'text-center'
  },
  {
    key: 'parent_page_title',
    label: 'Parent Page',
  },
  {
    key: 'action_1',
    label: 'Delete',
    class: 'text-center'
  },
  {
    key: 'action_3',
    label: 'Edit',
    class: 'text-center'
  }
]

</script>

<style lang="less">
#AdminMyOrg {
  font-size: 1.4rem;
  i {
    font-size: 1.8rem;
  }
  .fa-trash {
    color: red;
  }
  .fa-edit {
    color: #0BDA51;
  }
}
</style>