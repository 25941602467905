<template>
  <div id="ClassesSearch" class="content-container pt-5" v-if="!initialLoad">
    <div class="classes-filter-text-input-container">
      <input
        type="text"
        class="classes-filter-text-input shadow"
        v-model="selectedTitleInput"
        :placeholder="componentText.searchPlaceholder"
      />
    </div>
    <div class="classes-filters-container">
      <div class="classes-filters-text brand-text">
        {{ componentText.filters }}:
      </div>
      <div class="classes-filters d-flex row flex-wrap">
        <div
          class="classes-filter col-lg-3 col-md-6 col-sm-12 mb-2"
          :class="{'col-lg-4': $store.getters.user.access_code_user}"
          v-if="$store.getters.skin !== 20"
        >
          <v-select
            :class="{ active: selectedInstructor.value !== false }"
            v-model="selectedInstructor"
            :options="instructorSelectionOptions"
            :closeOnSelect="true"
            label="text"
          >
          </v-select>
        </div>
        <div class="classes-filter col-lg-3 col-md-6 col-sm-12 mb-2"
        :class="{'col-lg-4': $store.getters.user.access_code_user}"
        >
          <v-select
            :class="{ active: selectedType.value !== false }"
            v-model="selectedType"
            :options="typeSelectionOptions"
            :closeOnSelect="true"
            label="text"
          >
          </v-select>
        </div>
        <div class="classes-filter col-lg-3 col-md-6 col-sm-12 mb-2"
        :class="{'col-lg-4': $store.getters.user.access_code_user}"
        >
          <v-select
            :class="{ active: selectedLevel.value !== false }"
            v-model="selectedLevel"
            :options="levelSelectionOptions"
            :closeOnSelect="true"
            label="text"
          >
          </v-select>
        </div>
        <div
          class="classes-filter col-lg-3 col-md-6 col-sm-12 mb-2"
          v-if="$store.getters.skin !== 20 && !$store.getters.user.access_code_user"
        >
          <button
            :class="{ active: favourited }"
            class="btn classes-filter-button py-3 mx-2 my-2 w-100 shadow"
            @click="toggleFavouriteFilter"
          >
            {{ componentText.favourited }}
          </button>
        </div>
      </div>
    </div>
    <div class="classes-table-container">
      <div class="table-header-container row d-flex flex-row bg-brand-primary">
        <div class="table-header-item row-title">
          <span
            class="clickable"
            v-if="sortBy === 'title'"
            @click="toggleAscending()"
            >{{ componentText.className
            }}<span class="pl-2" v-if="!sortAscending"
              ><i class="fas fa-caret-down"></i></span
            ><span class="pl-2" v-else><i class="fas fa-caret-up"></i></span
          ></span>
          <span class="clickable" @click="selectSortOption('title')" v-else>
            {{ componentText.className }}
          </span>
        </div>
        <div class="table-header-item row-date"
        :class="{'extra-flex': $store.getters.user.access_code_user}">
          <span
            class="clickable"
            v-if="sortBy === 'start_date_time'"
            @click="toggleAscending()"
            >{{ componentText.date
            }}<span class="pl-2" v-if="!sortAscending"
              ><i class="fas fa-caret-down"></i></span
            ><span class="pl-2" v-else><i class="fas fa-caret-up"></i></span
          ></span>
          <span
            class="clickable"
            @click="selectSortOption('start_date_time')"
            v-else
          >
            {{ componentText.date }}
          </span>
        </div>
        <div class="table-header-item row-instructor">
          <span
            class="clickable"
            v-if="sortBy === 'instructor'"
            @click="toggleAscending()"
            >{{ componentText.instructor
            }}<span class="pl-2" v-if="!sortAscending"
              ><i class="fas fa-caret-down"></i></span
            ><span class="pl-2" v-else><i class="fas fa-caret-up"></i></span
          ></span>
          <span
            class="clickable"
            @click="selectSortOption('instructor')"
            v-else
          >
            {{ componentText.instructor }}
          </span>
        </div>
        <div class="table-header-item row-attended text-center" v-if="!$store.getters.user.access_code_user">
          <span>{{ componentText.attended }}</span>
        </div>
      </div>
      <div class="table-row-container" v-if="classes.length !== 0">
        <div
          class="table-row row d-flex flex-row align-items-center"
          v-for="event in classes"
          :key="event.id"
          @click="eventNav(event)"
        >
          <div class="table-row-item row-title brand-highlight-1 px-1">
            {{ event.title }}
            <i class="fa fa-star gold" v-if="event.favourited"></i>
          </div>
          <div class="table-row-item row-date px-1"
          :class="{'extra-flex': $store.getters.user.access_code_user}">
            <i class="fas gray-text fa-calendar-check pr-3"></i>
            <span v-if="$store.getters.skin !== 20">{{
              getFormatedDate(event.start_date_time, "dddd DD MMMM 'YY")
            }}</span>
            <span v-else>{{
              getFormatedDate(event.start_date_time, "dddd DD MMMM")
            }}</span>
          </div>
          <div class="table-row-item row-instructor px-1">
            <i class="fas gray-text fa-user-check pr-3"></i>
            {{ event.instructor }}
          </div>
          <div class="table-row-item row-attended text-center" v-if="!$store.getters.user.access_code_user">
            <i
              :class="[event.viewed ? 'brand-highlight-1' : 'gray-text']"
              class="fas fa-check-circle fa-2x"
            ></i>
          </div>
        </div>
      </div>
      <div
        class="
          table-no-items
          row
          d-flex
          justify-content-center
          align-items-center
        "
        v-else
      >
        <div class="table-no-items-text">
          No classes available for this search query.
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-between pb-5" v-if="totalPages > 1">
      <a @click="previousPage()"
        ><i
          class="
            fas
            fa-arrow-alt-circle-left fa-4x
            brand-highlight-1
            pagination-button
          "
          :class="{ disabled: currentPageNumber === 1 }"
          v-if="$store.getters.skin !== 20"
        ></i
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          viewBox="0 0 50 50"
          v-else
        >
          <g>
            <g>
              <g>
                <path
                  class="circle"
                  :class="{ disabled: currentPageNumber === 1 }"
                  fill="#d60570"
                  d="M24.805 0c13.698 0 24.804 11.105 24.804 24.804 0 13.699-11.106 24.804-24.804 24.804C11.105 49.608 0 38.503 0 24.804 0 11.105 11.106 0 24.805 0z"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke="#f1f1f1"
                  stroke-linecap="round"
                  stroke-miterlimit="20"
                  stroke-width="2.83"
                  d="M23.137 13.934v0l-8.908 10.028v0l7.793 10.033v0"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke="#f1f1f1"
                  stroke-linecap="round"
                  stroke-miterlimit="20"
                  stroke-width="2.83"
                  d="M14.711 23.906h26.893"
                />
              </g>
            </g>
          </g>
        </svg>
      </a>
      <span class="brand-text pagination-text"
        >Page {{ currentPageNumber }} of {{ totalPages }}</span
      >
      <a @click="nextPage()"
        ><i
          class="
            fas
            fa-arrow-alt-circle-right fa-4x
            brand-highlight-1
            pagination-button
          "
          :class="{ disabled: currentPageNumber === totalPages }"
          v-if="$store.getters.skin !== 20"
        ></i
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          viewBox="0 0 50 50"
          class="flip"
          v-else
        >
          <g>
            <g>
              <g>
                <path
                  class="circle"
                  :class="{ disabled: currentPageNumber === totalPages }"
                  fill="#d60570"
                  d="M24.805 0c13.698 0 24.804 11.105 24.804 24.804 0 13.699-11.106 24.804-24.804 24.804C11.105 49.608 0 38.503 0 24.804 0 11.105 11.106 0 24.805 0z"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke="#f1f1f1"
                  stroke-linecap="round"
                  stroke-miterlimit="20"
                  stroke-width="2.83"
                  d="M23.137 13.934v0l-8.908 10.028v0l7.793 10.033v0"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke="#f1f1f1"
                  stroke-linecap="round"
                  stroke-miterlimit="20"
                  stroke-width="2.83"
                  d="M14.711 23.906h26.893"
                />
              </g>
            </g>
          </g></svg
      ></a>
    </div>
  </div>
  <div class="d-flex flex-row align-items-center mt-5" v-else>
    <div class="dg-spinner-container mx-auto my-auto">
      <b-spinner class="brand-primary"></b-spinner>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import config from "../../config/constants";
import { debounce } from "../../helpers/debounce.js";

export default {
  name: "ClassesSearch",
  components: {},
  data() {
    return {
      classes: [],
      allClasses: [],
      filteredClasses: [],
      sortedFilteredClasses: [],
      isLoadingClasses: true,
      isLoadingFilterOptions: true,
      initialLoad: true,
      sortBy: "start_date_time",
      sortAscending: false,
      selectedTitle: "",
      selectedTitleInput: "",
      selectedInstructor: { text: "All Instructors", value: false },
      selectedType: { text: "All Types", value: false },
      selectedLevel: { text: "All Levels", value: false },
      favourited: false,
      instructorSelectionOptions: [{ text: "All Instructors", value: false }],
      typeSelectionOptions: [{ text: "All Types", value: false }],
      levelSelectionOptions: [{ text: " All Levels", value: false }],
      currentPageNumber: 1,
      totalPages: 10,
    };
  },
  mounted() {
    this.getClassFilterOptions();
    this.getClasses();
  },
  methods: {
    async getClassFilterOptions() {
      const payload = {
        user_id: this.$store.getters.user_id,
      };
      let res = await axios.post(
        config.api_env + "/application/api/u/studio/get-search-options",
        payload,
        config.options
      );
      if (res.data.success) {
        let instructorOptions = [],
          levelOptions = [],
          typeOptions = [];

        res.data.instructors.forEach(function (instructor) {
          instructorOptions.push({ text: instructor, value: instructor });
        });
        res.data.levels.forEach(function (level) {
          levelOptions.push({ text: level, value: level });
        });
        res.data.types.forEach(function (type) {
          typeOptions.push({ text: type, value: type });
        });

        instructorOptions.unshift({ text: "All Instructors", value: false });
        if(! levelOptions.some(level => level.text.toLowerCase() === "all levels")){
          levelOptions.unshift({ text: "All Levels", value: false });
        }
        typeOptions.unshift({ text: "All Types", value: false });
        this.instructorSelectionOptions = instructorOptions;
        this.levelSelectionOptions = levelOptions;
        this.typeSelectionOptions = typeOptions;
        this.isLoadingFilterOptions = false;
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login").catch((err) => {});
      }
    },
    async getClasses() {
      this.isLoadingClasses = true;
      let payload = {};
      let level = this.initialLoad ? false : this.selectedLevel.value;
      if(this.selectedLevel.value === 'All levels') level = false;
      if (this.favourited === false) {
        payload = {
          user_id: this.$store.getters.user_id,
          page: this.currentPageNumber,
          sortBy: this.sortBy,
          orderAsc: this.sortAscending,
          filter: {
            title: this.initialLoad ? false : this.selectedTitle,
            instructor: this.initialLoad
              ? false
              : this.selectedInstructor.value,
            level: level,
            type: this.initialLoad ? false : this.selectedType.value,
          },
        };
      } else {
        payload = {
          user_id: this.$store.getters.user_id,
          page: this.currentPageNumber,
          sortBy: this.sortBy,
          orderAsc: this.sortAscending,
          filter: {
            title: this.initialLoad ? false : this.selectedTitle,
            instructor: this.initialLoad
              ? false
              : this.selectedInstructor.value,
            level: this.initialLoad ? false : this.selectedLevel.value,
            type: this.initialLoad ? false : this.selectedType.value,
            favourite: this.initialLoad ? false : this.favourited,
          },
        };
      }

      let res = await this.axios.post(
        process.env.VUE_APP_API_PATH +
          "/application/api/u/studio/search-gym-events",
        payload, config.options
      );
      if (res.data.success) {
        if (this.initialLoad) {
          this.initialLoad = false;
        }
        this.totalPages = res.data.total_pages;
        this.classes = res.data.gym_events;
        this.isLoadingClasses = false;
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      }
    },
    async favourite(id) {
      const payload = {
        user_id: this.$store.getters.user_id,
        event_id: id,
      };
      let res = await this.axios.post(
        process.env.VUE_APP_API_PATH +
          "/application/api/u/studio/toggle-favourite-gym-event",
        payload, config.options
      );
      if (res.data.success) {
        console.log(res.data);
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      }
    },
    //this can go as it gets moved in to request
    sortClasses(classes, param, ascending) {
      let classesCopy = JSON.parse(JSON.stringify(classes));
      let sorted = [];

      function compareString(a, b) {
        if (a[param] < b[param]) return -1;
        if (a[param] > b[param]) return 1;
        return 0;
      }
      function compareDate(a, b) {
        var keyA = new Date(a.start_date_time),
          keyB = new Date(b.start_date_time);
        // Compare the 2 dates
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      }
      if (param !== "date") {
        sorted = classesCopy.sort(compareString);
      } else {
        sorted = classesCopy.sort(compareDate);
      }
      this.sortedFilteredClasses = ascending ? sorted : sorted.reverse();
    },
    previousPage() {
      if (this.currentPageNumber !== 1) {
        this.currentPageNumber--;
      }
    },
    nextPage() {
      if (this.currentPageNumber !== this.totalPages) {
        this.currentPageNumber++;
      }
    },
    eventNav(event) {
      this.$router.push("/digital-gym/" + event.id + "?ref=cs");
    },
    selectSortOption(val) {
      this.sortBy = val;
    },
    toggleAscending() {
      this.sortAscending = !this.sortAscending;
    },
    toggleFavouriteFilter() {
      this.favourited = !this.favourited;
    },
    setSelectedTitle(value) {
      this.selectedTitle = this.selectedTitleInput;
    },
  },
  computed: {
    componentText() {
      return this.getText.digitalGym;
    },
  },
  watch: {
    currentPageNumber: function () {
      this.getClasses();
    },
    selectedTitle: function () {
      this.currentPageNumber = 1;
      this.getClasses();
    },
    selectedInstructor: function () {
      this.currentPageNumber = 1;
      this.getClasses();
    },
    selectedType: function () {
      this.currentPageNumber = 1;
      this.getClasses();
    },
    selectedLevel: function () {
      this.currentPageNumber = 1;
      this.getClasses();
    },
    favourited: function () {
      this.currentPageNumber = 1;
      this.getClasses();
    },
    selectedTitleInput: debounce(function (newVal) {
      this.selectedTitle = newVal;
    }, 500),
    sortAscending: function () {
      this.getClasses();
    },
    sortBy: function () {
      this.getClasses();
    },
  },
};
</script>

<style lang="less">
#ClassesSearch {
  .gray-text{
    color: #c4c4c4;
  }
  .extra-flex{
    flex-basis: 35% !important;
  }
  .classes-filter-text-input-container {
    padding-bottom: 15px;
    .classes-filter-text-input {
      height: 50px;
      width: 100%;
      text-align: left;
      padding-left: 15px;
      font-size: 1.8rem;
      color: #666;
      border: 2px #c4c4c4 solid;
      border-radius: 32px;
      background-color: white;
      outline: none;
      :focus {
        border: 0px;
      }
      :focus-visible {
        border: 0px;
        outline: none !important;
      }
    }
  }
  .classes-filters-text {
    padding-left: 5px;
    padding-bottom: 15px;
    font-size: 1.6rem;
    font-weight: bold;
  }
  .classes-filter-button {
    padding-left: 20px;
    padding-right: 20px;
    letter-spacing: 0.5px;
    text-align: left;
    margin: 0 !important;
    color: #c4c4c4;
    flex-grow: 1;
    min-height: 50px !important;
    border: 2px solid #c4c4c4;
    padding: 15px 20px;
  }
  .classes-table-container {
    padding: 30px 0;
    .table-header-container {
      font-size: 1.8rem;
      font-weight: bold;
      color: white;
      padding: 20px 15px;
      border-radius: 16px 16px 0 0;
      flex-wrap: nowrap;
    }
    .table-row-container {
      .table-row:last-child {
        border-radius: 0 0 16px 16px;
      }
      .table-row {
        cursor: pointer;
        padding: 15px 15px;
        font-size: 1.8rem;
        border-bottom: 2px solid #c4c4c4;
        border-left: 2px solid #c4c4c4;
        border-right: 2px solid #c4c4c4;

        .table-row-item {
        }
        .table-row-item.row-title {
          font-weight: bold;
          .gold {
            color: gold;
          }
        }
      }
      .table-row:hover {
        background-color: #f8f8f8;
      }
    }
    .table-no-items {
      min-height: 200px;
      border-radius: 0 0 16px 16px;
      font-size: 2rem;
      font-weight: bold;
      text-align: center;
      border: 2px #c4c4c4 solid;
    }

    .row-title {
      flex-basis: 40%;
    }
    .row-date {
      flex-basis: 25%;
    }
    .row-instructor {
      flex-basis: 25%;
    }
    .row-attended {
      overflow: hidden;
      text-overflow: ellipsis;
      flex-basis: auto;
      flex-grow: 1;
      justify-self: center;
    }
  }
  .pagination-button.disabled {
    color: #c4c4c4;
  }
  .pagination-text {
    font-size: 1.8rem;
    font-weight: bold;
  }

  .v-select {
    text-transform: capitalize !important;
    margin: 0 auto;
    cursor: pointer !important;
    .vs__dropdown-toggle {
      -webkit-box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
      border-radius: 36px;
      border: 2px #c4c4c4 solid;
      font-family: filsonProBold, sans-serif;
      background: white;
      color: #666;
      font-size: 1.6rem;
      position: relative;
      padding: 5px 10px 10px 10px;
      min-height: 50px;
      .vs__selected-options,
      .vs__selected,
      .vs__actions,
      svg {
        color: #c4c4c4;
        fill: #aaa;
      }
    }
    &.vs--single {
      .vs__clear {
        display: none;
      }
    }
    &.vs--open {
      .vs__selected-options {
        padding-bottom: 10px;
      }
    }
    .vs__dropdown-menu {
      text-align: left;
      padding-left: 0;
      list-style-type: none;
      max-height: 200px;
      font-size: 2rem;
      color: #4e555b;
      li {
        border-bottom: 1px solid #aaa;
        padding: 10px;
        &:last-child {
          border-bottom-width: 0;
        }
      }
      ::-webkit-scrollbar {
        width: 10px;
      }
      ::-webkit-scrollbar-track {
        border-radius: 10px;
      }
      ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #aaa;
        height: 50px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
      }
    }
    .vs__dropdown-option--selected {
      position: relative;
      &:after {
        content: " ";
        position: absolute;
        color: #aaa;
        background-image: url("/img/insights/tick-blue.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 25px;
        width: 28px;
        height: 20px;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .vs__selected-options {
      .vs__selected {
        background-color: white;
      }
    }
    .vs__search {
      margin-top: 0px;
    }
  }
  .select {
    border-radius: 20px;
    border: 1px solid #fff;
    font-family: DMSans, sans-serif;
    background: transparent;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ddd;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #ddd;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #ddd;
  }
  .clickable {
    cursor: pointer;
  }
  .flip {
    transform: scaleX(-1);
  }
}
.dg-spinner-container {
  height: 300px;
}
</style>
