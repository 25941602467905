<template>
  <div id="Store">
    <div class="store-banner">
      <div class="content-container row">
        <div class="fitbit-logo col-4 col-xl-6">
          <img src="/img/store/fitbit.png" />
        </div>
        <div class="col-8 col-xl-6 banner-image">
          <img class="fitbit-banner-image" src="/img/store/banner-fitbit.png" />
        </div>
      </div>
    </div>
    <div class="bg-white">
      <div v-if="products[selectedProduct]" class="content-container row">
        <div class="col-md-8 product-carousel-wrap order-md-last">
          <slick
            ref="slick"
            :options="slickOptions"
            class="product-carousel"
            :class="{ 'single-item-carousel': products.length <= 3 }"
          >
            <div
              class="product-image"
              v-for="(product, i) in products"
              :key="i"
            >
              <img class="img-fluid mx-auto" :src="productImage(product)" />
            </div>
          </slick>

          <div @click="prevProduct" class="product-carousel-prev"></div>
          <div @click="nextProduct" class="product-carousel-next"></div>
        </div>
        <div class="col-md-4 product-info order-md-first">
          <h3 class="brand-secondary">{{ products[selectedProduct].name }}</h3>
          <div class="product-description">
            {{ products[selectedProduct].description }}
          </div>
          <div class="product-price row pt-4">
            <div class="col-8">
              <h4>{{ componentText.retail }}</h4>
            </div>
            <div class="col-4 brand-secondary right">
              {{ retailPrice(products[selectedProduct].price) }}
            </div>
            <div class="col-8">{{ componentText.discount }}</div>
            <div class="col-4 right">{{ discount }}%</div>
            <div class="col-8" v-if="employerContribution > 0">
              {{ componentText.employerContribution }}
            </div>
            <div
              class="col-4 brand-secondary right"
              v-if="employerContribution > 0"
            >
              {{ retailPrice(employerContribution) }}
            </div>
          </div>
          <div class="your-price brand-secondary py-2 my-4">
            <p>{{ componentText.price }}</p>
            <p>{{ yourPrice }}</p>
            <p>
              <span class="shipping">( {{ componentText.shipping }} )</span>
            </p>
          </div>
          <h4 class="mb-3">{{ componentText.options }}</h4>
          <b-select class="options mb-5" v-model="selectedOption">
            <option
              v-for="option in products[selectedProduct].options"
              :value="option.product_id"
            >
              {{ option.option }}
            </option>
          </b-select>
        </div>
      </div>
    </div>
    <div class="bg-grey">
      <div class="content-container row">
        <div class="section-header section-header">
          <div class="section-header-divider"></div>
          <h3 class="section-header-title">{{ componentText.address }}</h3>
        </div>
        <b-form class="px-5 pb-5 delivery-form">
          <b-form-group
            id="input-group-1"
            :label="componentText.street1"
            label-for="street-address-1"
          >
            <b-form-input
              type="text"
              id="street-address-1"
              v-model="$v.delivery.streetAddress1.$model"
              :state="
                $v.delivery.streetAddress1.$dirty
                  ? !$v.delivery.streetAddress1.$error
                  : null
              "
              aria-describedby="address-error"
            ></b-form-input>
            <b-form-invalid-feedback id="address-error">
              {{ componentText.streetError }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            id="input-group-2"
            :label="componentText.street2"
            label-for="street-address-2"
          >
            <b-form-input
              type="text"
              id="street-address-2"
              v-model="$v.delivery.streetAddress2.$model"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-3"
            :label="componentText.town"
            label-for="town"
          >
            <b-form-input
              type="text"
              id="town"
              v-model="$v.delivery.town.$model"
              :state="$v.delivery.town.$dirty ? !$v.delivery.town.$error : null"
              aria-describedby="town-error"
            ></b-form-input>
            <b-form-invalid-feedback id="town-error">
              {{ componentText.townError }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            id="input-group-4"
            :label="componentText.postcode"
            label-for="postcode"
          >
            <b-form-input
              type="text"
              id="postcode"
              v-model="$v.delivery.postcode.$model"
              :state="
                $v.delivery.postcode.$dirty
                  ? !$v.delivery.postcode.$error
                  : null
              "
              aria-describedby="postcode-error"
            ></b-form-input>
            <b-form-invalid-feedback id="postcode-error">
              {{ componentText.postcodeError }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            id="input-group-5"
            :label="componentText.country"
            label-for="Country"
          >
            <b-form-select
              id="Country"
              v-model="$v.delivery.country.$model"
              :state="
                $v.delivery.country.$dirty ? !$v.delivery.country.$error : null
              "
              aria-describedby="country-error"
            >
              <option value="ie">{{ componentText.ie }}</option>
              <option value="uk">{{ componentText.uk }}</option>
            </b-form-select>
            <b-form-invalid-feedback id="country-error">
              {{ componentText.countryError }}.
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            id="input-group-6"
            :label="componentText.phone"
            label-for="phone"
          >
            <b-form-input
              type="number"
              id="phone"
              v-model="$v.delivery.phone.$model"
              :state="
                $v.delivery.phone.$dirty ? !$v.delivery.phone.$error : null
              "
              aria-describedby="phone-error"
            ></b-form-input>
            <b-form-invalid-feedback id="phone-error">
              {{ componentText.phoneError }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-form>
      </div>
    </div>
    <div class="bg-white pb-5">
      <div class="content-container row">
        <div class="section-header section-header">
          <div class="section-header-divider"></div>
          <h3 class="section-header-title">{{ componentText.card }}</h3>
        </div>
        <div class="stripe-wrap col-12 px-0">
          <form id="payment-form">
            <div class="form-row">
              <div id="card-element">
                <!-- A Stripe Element will be inserted here. -->
              </div>
              <!-- Used to display form errors. -->
              <div id="card-errors" role="alert"></div>
              <!--<b-button class="brand-secondary bg-brand-highlight-1 px-5 py-3 my-5">PAY</b-button>-->
            </div>
            <div class="submit-box">
              <input
                type="submit"
                :value="componentText.submit"
                :disabled="formDisabled"
                class="btn button cta-button px-5 py-3 my-5"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import config from "../config/constants";
import Slick from "vue-slick";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  name: "Store",
  mixins: [validationMixin],
  components: {
    Slick,
  },
  data() {
    return {
      formDisabled: false,
      products: [],
      selectedProduct: 0,
      employerContribution: "",
      discount: 15,
      selectedOption: 0,
      selectedOptionIndex: 0,
      delivery: {
        streetAddress1: "",
        streetAddress2: "",
        town: "",
        country: "",
        postcode: "",
        phone: "",
      },
      slickOptions: {
        infinite: true,
        autoplay: false,
        dots: false,
        speed: 300,
        slidesToScroll: 1,
        slidesToShow: 3,
        centerMode: true,
        swipe: false,
        accessibility: false,
        draggable: false,
        swipeToSlide: false,
        touchMove: false,
        centerPadding: "20px",
        variableWidth: false,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              centerPadding: "60px",
            },
          },
          {
            breakpoint: 480,
            settings: {
              centerPadding: "30px",
              slidesToShow: 1,
            },
          },
        ],
      },
    };
  },
  validations: {
    delivery: {
      streetAddress1: {
        required,
      },
      streetAddress2: {},
      town: {
        required,
      },
      postcode: {
        required,
      },
      country: {
        required,
      },
      phone: {
        required,
      },
    },
  },
  created() {
    this.loadStripeScript();
  },
  mounted() {
    this.getFitbits();
  },
  methods: {
    loadStripeScript() {
      if (!document.getElementById("stripe-script")) {
        let script = document.createElement("script");
        script.type = "text/javascript";
        script.src = "https://js.stripe.com/v3/";
        script.id = "stripe-script";
        document.head.appendChild(script);
      }
      this.$nextTick().then(() => {
        this.checkStripe();
      });
    },
    checkStripe() {
      //check every two seconds to see if the stripe script has loaded and form can be mounted
      if (window.Stripe) {
        this.initStripeForm();
      } else {
        setTimeout(() => this.checkStripe(), 2000);
      }
    },
    async getFitbits() {
      const payload = {
        user_id: this.$store.getters.user_id,
      };
      let res = await axios.post(
        config.api_env + "/application/api/store/get-fitbits",
        payload,
        config.options
      );
      if (res.data.products.length <= 3) {
        this.slickOptions.slidesToShow = 1;
      }
      this.products = res.data.products;
      this.selectedOption = this.products[0].options[0].product_id;
      this.employerContribution = res.data.client_contribution;
    },
    prevProduct() {
      this.$refs.slick.prev();
      if (this.selectedProduct > 0) {
        this.selectedProduct--;
      } else {
        this.selectedProduct = this.products.length - 1;
      }
      this.selectedOption = this.products[
        this.selectedProduct
      ].options[0].product_id;
    },
    nextProduct() {
      this.$refs.slick.next();
      if (this.selectedProduct < this.products.length - 1) {
        this.selectedProduct++;
      } else {
        this.selectedProduct = 0;
      }
      this.selectedOption = this.products[
        this.selectedProduct
      ].options[0].product_id;
    },
    productImage(product) {
      if (product.selectedOption) {
        return product.options[product.selectedOption].image_url;
      } else {
        return product.options[0].image_url;
      }
    },
    async initStripeForm() {
      //create a Stripe client - stripe script is loaded in index.html
      const stripe = window.Stripe(config.stripe_key);
      //create an instance of Stripe elements
      const elements = stripe.elements();
      const style = {
        base: {
          color: "#32325d",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
        },
      };
      // Create an instance of the card Element.
      let card = elements.create("card", { style: style });
      // Add an instance of the card Element into the `card-element` <div>.
      card.mount("#card-element");
      // Handle real-time validation errors from the card Element.
      card.addEventListener("change", function (event) {
        let displayError = document.getElementById("card-errors");
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = "";
        }
      });
      // Handle form submission.
      let form = document.getElementById("payment-form");
      let _this = this;
      form.addEventListener("submit", function (event) {
        event.preventDefault();
        _this.$v.$touch();
        if (!_this.formDisabled) {
          _this.formDisabled = true;
          stripe.createToken(card).then(function (result) {
            if (result.error || _this.$v.$invalid) {
              // Inform the user if there was an error.
              let errorElement = document.getElementById("card-errors");
              if (result.error) {
                errorElement.textContent = result.error.message;
              }
              _this.formDisabled = false;
            } else {
              // Send the token to your server.
              _this.submitForm(result.token);
            }
          });
        }
      });
    },
    retailPrice(price) {
      const str = price.toString();
      const euros = str.substring(0, str.length - 2);
      const cents = str.substr(-2);
      const currency_symbols = { eur: "\u20AC", gbp: "\u00A3", usd: "\u0024" };
      return (
        currency_symbols[this.$store.getters.currency] +
        " " +
        euros +
        "." +
        cents
      );
    },
    submitForm(token) {
      this.pay(token);
    },
    async pay(stripeToken) {
      const data = {
        address1: this.delivery.streetAddress1,
        address2: this.delivery.streetAddress2,
        city: this.delivery.town,
        post_code: this.delivery.postcode,
        country: this.delivery.country,
        phone: this.delivery.phone,
        token: stripeToken.id,
        product_id: this.selectedOption,
      };
      const payload = {
        user_id: this.$store.getters.user_id,
        data: btoa(JSON.stringify(data)),
      };
      let res = await axios.post(
        config.api_env + "/application/api/store/buy-fitbit",
        payload,
        config.options
      );
      if (res.data.success) {
        this.$router.push("/marketplace/confirmation");
      } else {
        this.formDisabled = false;
      }
    },
  },
  watch: {
    selectedOption(newOption) {
      // console.log(newOption, this.selectedProduct)
      this.products[this.selectedProduct].selectedOption = this.products[
        this.selectedProduct
      ].options.findIndex((option) => option.product_id === newOption);
    },
    stripeReady(newValue) {},
  },
  computed: {
    yourPrice() {
      let price =
        this.products[this.selectedProduct].price - this.employerContribution;
      price = price - Math.ceil(price / 100) * this.discount;
      return this.retailPrice(price);
    },
    componentText() {
      return this.getText.loggedIn.store;
    },
  },
};
</script>
<style lang="less">
#Store {
  .store-banner {
    background-color: #394962;
    height: 340px;
    overflow: hidden;
    position: relative;
    .fitbit-logo {
      justify-content: flex-start;
      align-items: center;
      height: 340px;
      display: flex;
      z-index: 5;
      &:before {
        position: absolute;
        content: "";
        left: calc(100% - 10px);
        width: 0;
        height: 0;
        border-top: 340px solid #394962;
        border-right: 340px solid transparent;
        z-index: 5;
      }
      &:after {
        position: absolute;
        content: "";
        left: calc(100% + 160px);
        background-color: #d1366c;
        height: 100%;
        width: 14px;
        transform: skew(-45deg);
        z-index: 5;
      }
      img {
        z-index: 6;
      }
    }
    .banner-image {
      z-index: 0;
      width: 100%;
      height: 340px;
      position: absolute;
      right: 0;
      z-index: 0;
      overflow: hidden;
      img {
        height: 100%;
        position: absolute;
        right: 0;
        z-index: -10;
        overflow: hidden;
      }
    }
  }
  .product-info {
    font-size: 1.6rem;
    color: #3b4a61;
    h3 {
      font-family: FilsonProBold, sans-serif;
      font-size: 3.2rem;
      line-height: 1.1;
      text-transform: uppercase;
      text-align: center;
    }
    h4 {
      font-family: FilsonProBold;
    }
    .product-description {
      padding: 15px 20px;
      margin: 8px auto 0;
      font-family: "DMSans", sans-serif;
      font-size: 14px;
      line-height: 1.2;
      border: 1px solid #eff0f2;
      text-align: center;
    }
    .product-price {
      .right {
        text-align: right;
      }
    }
    .your-price {
      border-width: 1px 0;
      border-style: solid;
      border-color: rgba(135, 138, 140, 0.5);
      text-align: center;
      font-size: 2rem;
      font-family: FilsonProBold;
      p {
        margin: 0;
      }
      .shipping {
        font-size: 1.4rem;
        font-family: DMSans, sans-serif;
        color: #3b4a61;
      }
    }
    .options {
      font-size: 1.6rem;
      max-width: 200px;
    }
  }
  .product-carousel-wrap {
    position: relative;

    .test-flex-container {
      display: flex;
      width: inherit;
      height: inherit;
    }
    .test-flex-container {
      display: block;
      width: inherit;
      height: inherit;
    }
    .product-carousel-prev,
    .product-carousel-next {
      position: absolute;
      top: 50%;
      width: 0;
      height: 0;
      cursor: pointer;
      border: 20px solid transparent;
      border-bottom: 20px solid #3a4a61;
      z-index: 1;
    }
    .product-carousel-prev {
      left: 0;
      transform: translateY(-50%) rotate(-90deg);
    }
    .product-carousel-next {
      right: 0;
      transform: translateY(-50%) rotate(90deg);
    }
    .slick-arrow {
      display: none !important;
    }

    .single-item-carousel {
      .slick-list {
        display: block !important;
        transform: translateY(25%) !important;
        @media only screen and (max-width: 768px) {
          display: block;
          transform: translateY(10%) !important;
        }
      }
      img {
        max-width: 250px;
      }
    }

    .product-carousel {
      height: 100%;
      max-height: 570px;
      .img-fluid {
        object-fit: contain;
        padding: 10px;
      }

      .slick-slide {
        opacity: 0.6;
      }
      .slick-list {
        height: 100%;
        transform: translateY(25%);
        @media only screen and (max-width: 768px) {
          display: block;
          transform: translateY(10%);
        }
        @media only screen and (max-width: 600px) {
          display: block;
          transform: translateY(0%);
        }
      }
      .slick-center {
        transform: scale(1.2);
        transition: 0.25s;
        opacity: 1;
      }
    }
  }
  .delivery-form {
    font-size: 2rem;
    color: #848588;
    input,
    select {
      border: none;
      border-radius: 10px;
      height: 52px;
      width: 350px;
      font-size: 1.6rem;
    }
  }
  .StripeElement {
    box-sizing: border-box;

    height: 40px;
    width: 500px;
    padding: 10px 12px;

    border: 1px solid transparent;
    border-radius: 4px;
    background-color: white;

    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
  }

  .StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
  }

  .StripeElement--invalid {
    border-color: #fa755a;
  }

  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }
  .submit-box {
    float: left;
    display: block;
  }
}
</style>
