<template>
<div id="UserManagement">
  <div id="users-page" class="content">
    <br/>
    <transition name="fade" mode="out-in">
      <div class="text-left">
        <div class="row page-content">
          <div class="col-md-1"></div>
          <div class="col-md-10">

            <h3 class="heading">Invite Users</h3>

            <div>
              <b-button v-b-toggle.collapse-1 class="cta-button">Invite Users</b-button>
              <b-collapse id="collapse-1" class="mt-2">
                <div class="col-12">
                  <div class="row">
                    <div class="col-md-8"><input disabled class="form-control disabled" type="text" v-model="invite_code"></div>
                  </div>
                  <br/>
                  <label>Email Addresses (comma seperated):</label>
                  <b-form-input
                      id="input-1"
                      v-model="invite_link.email"
                      type="email"
                      required
                      placeholder="Enter email"
                  ></b-form-input>
                  <br/>
                  <label>Message:</label>
                  <b-form-textarea
                      id="textarea"
                      v-model="invite_link.message"
                      placeholder="(Optional)"
                      rows="4"
                      max-rows="4"
                  ></b-form-textarea>
                  <br/>
                  <b-button v-on:click="sendInviteLink()" id="invite-user" variant="purple" :disabled="!emailsValid" style="position: relative; width: 170px; height: 30px">
                    <span v-if="inviteSent">Invite Sent</span>
                    <span v-else-if="!sendingInvite">Send Invite Link</span>
                    <span v-else class="button-loading"></span>
                    <b-popover
                        target="invite-user"
                        placement="top"
                        triggers="hover"
                        :delay="{ show: 700, hide: 600 }">
                      Send invite link to user
                    </b-popover>
                  </b-button>
                </div>
              </b-collapse>
            </div>

            <div class="table-box">
              <h3 class="heading">User Management</h3>
              <p>
                Total Registered Users: {{registered_users}}<br/>
                Total Active Users: {{active_users}}
              </p>
              <br/>
              <br/>
              <b-table
                  bordered
                  outlined
                  responsive="true"
                  hover
                  :items=items
                  :fields=fields
              >
                <template v-slot:cell(actions)="row">
                  <div v-if="row.item.activated === 1" class="text-center">
                    <button v-on:click="toggleUserActivated(row.item.user_id)" class="btn btn-light">
                      Deactivate
                    </button>
                  </div>
                  <div v-else class="text-center">
                    <button v-on:click="toggleUserActivated(row.item.user_id)" class="btn btn-purple">
                      Activate
                    </button>
                  </div>
                </template>
              </b-table>
            </div>
            <div class="row mt-4">
              <div class="col-3">
                <b-button class="btn-purple mb-5" @click="downloadUsers">Download User Details</b-button>
              </div>
            </div>
          </div>
          <div class="col-md-10">
            <div class="row page-content-spacer"></div>
            <div class="row page-content-spacer"></div>
          </div>
          <div class="col-md-1"></div>
        </div>
        <div class="row page-content-spacer"></div>
      </div>
    </transition>
  </div>
</div>
</template>

<script>

const sample_csv = [
  {
    firstname: 'Jane',
    lastname: 'Doe',
    email: 'jane.doe@example.com'
  },
  {
    firstname: 'John',
    lastname: 'Doe',
    email: 'john.doe@example.com'
  }
];

export default {
  name: 'UserManagement',
  data() {
    return {
      items: [],
      file: {},
      parsed: [],
      fields: [
        {key:'name', label:'Name'},
        {key:'email', label:'Email'},
        {key:'last_active', label:'Last Active'},
        {key: 'location_label', label: 'Site Location'},
        {key:'actions', label:'Actions'}
      ],
      registered_users: Number,
      active_users: Number,
      loading: true,
      toggle_loading: false,
      invite_link: {
        email: '',
        message: ''
      },
      portal_domain : '',
      invite_code: '',
      sendingInvite: false,
      inviteSent: false
    }
  },
  mounted () {
    this.getClientUsers();
  },
  watch: {
    file: function() {
      if(this.file.type === 'text/csv') {
        this.parseFile();
      } else {
        this.$store.commit('setAlert', {
          visible : true,
          text: "File needs to be in CSV format .. ",
          color: 'danger'
        });
      }
    }
  },
  computed: {
    emailsValid() {
      if(this.invite_link.email === "") return false;
      let validity = true;
      const emails = this.invite_link.email.split(",");
      emails.forEach(email => {
        if(!this.validateEmail(email.trim())) validity = false;
      });
      return validity;
    }
  },
  methods: {
    downloadUsers() {
      const userData = JSON.parse(JSON.stringify(this.items));
      let csvData = new Blob([this.$papa.unparse({
        header: false,
        columns: ['email','name','location_id','location_label','last_active','user_id'],
        data: userData,
      })], {type: 'text/csv;charset=utf-8;'});
      let file_name = 'user_data.csv';
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(csvData, file_name);
      } else {
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(csvData);
        link.setAttribute('download', file_name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    validateEmail(email) {
      if(email === "") return true
      return String(email)
          .toLowerCase()
          .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    },
    registerFromCsv(items){
      console.log(items);
      const url = process.env.VUE_APP_API_PATH + '/application/api/c/users/bulk-register-users';
      const opts = {
        method: 'post',
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
        },
        body: JSON.stringify({
          "user_id": this.$store.getters.user.user_token,
          "users": items
        })
      }

      fetch(url, opts).then(response => {
            response.json().then(data => {
              if(data.success == true){
                this.getClientUsers();
                let items = [];
                let result_string = '';
                data.result.map(r => {
                  items.push({
                    email : r.email,
                    result : r.result,
                    errors : r.errors
                  });
                  let registered = r.result ? '<span class="text-success">Successfully Registered</span>' : '<span class="text-danger">Errors: '+r.errors+'</span>'
                  result_string+= r.email+' - '+registered+ '<br/>';
                })

                this.$store.commit('setModal', {
                  visible : true,
                  heading: 'Result',
                  text : result_string,
                  icon : '',
                  color : 'success',
                  action: 'bulk_register_users',
                  size: 'lg',
                  options:[
                    {
                      color: 'purple',
                      text: 'Close',
                      exec: false
                    }
                  ]
                });
              } else {
                // emit alert
                this.getClientUsers();
                this.$store.commit('setAlert', {
                  visible : true,
                  text: data.err,
                  color: 'danger'
                });
              }
            });
          }
      )
    },
    toggleUserActivated(target_user_id){
      this.toggle_loading = true;
      const url = process.env.VUE_APP_API_PATH + '/application/api/c/users/toggle-user-activated';
      const opts = {
        method: 'post',
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
        },
        body: JSON.stringify({
          "user_id": this.$store.getters.user.user_token,
          "target_user_id" : target_user_id
        })
      }

      fetch(url, opts).then(response => {
            response.json().then(data => {
              if(data.success == true){
                this.getClientUsers();
              } else {
                // emit alert
                this.getClientUsers();
                this.$store.commit('setAlert', {
                  visible : true,
                  text: data.err,
                  color: 'danger'
                });
              }
            });
          }
      )
    },
    sendInviteLink(){
      this.sendingInvite = true;
      const url = process.env.VUE_APP_API_PATH + '/application/api/c/users/send-invite-link';
      const opts = {
        method: 'post',
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
        },
        body: JSON.stringify({
          "user_id": this.$store.getters.user.user_token,
          "message" : this.invite_link.message,
          "e_mail" : this.invite_link.email,
          "link" : this.portal_domain
        })
      }

      fetch(url, opts).then(response => {
            response.json().then(data => {
              console.log(data);
              if(data.success == true){
                this.sendingInvite = false;
                this.inviteSent = true;
                setTimeout(()=> {
                  this.inviteSent = false
                }, 4000);
              } else {
                // emit alert
                this.sendingInvite = false;
              }
            });
          }
      )
    },
    getClientUsers(){
      const url = process.env.VUE_APP_API_PATH + '/application/api/c/users/get-client-users';
      const opts = {
        method: 'post',
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
        },
        body: JSON.stringify({
          "user_id": this.$store.getters.user.user_token
        })
      }

      fetch(url, opts).then(response => {
            response.json().then(data => {
              if(data.success == true){
                this.items = data.users;
                this.registered_users = data.user_count;
                this.active_users = data.active_user_count;
                this.loading = false;
                this.portal_domain = data.client_domain;
                this.toggle_loading = false;
                this.invite_code = data.signup_link
              } else {
                // emit alert
                this.loading = false;
                this.$store.commit('setAlert', {
                  visible : true,
                  text: data.err,
                  color: 'danger'
                });
              }
            });
          }
      )
    },
    downloadTemplate () {
      let csvData = new Blob([this.$papa.unparse(sample_csv)], {type: 'text/csv;charset=utf-8;'});
      //IE11 & Edge
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(csvData, 'invite-template.csv');
      } else {
        //In FF link must be added to DOM to be clicked
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(csvData);
        link.setAttribute('download', 'invite-template.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    parseFile () {
      const config = {
        complete: (results) => {
          this.registerFromCsv(results.data);
        },
        header: true,
        delimitersToGuess: [',', '\t', '|', ';']
      };
      this.$papa.parse(this.file, config);
    },
    onCopySuccess(){
      this.$store.commit('setAlert', {
        visible : true,
        text: 'Invite link copied to your clipboard',
        color: 'success'
      });
    }
  }
}
</script>

<style lang="less">
#UserManagement {
  font-family: "DMSans", sans-serif;
  background-color: white;
  input, label, textarea,  p {
    font-size: 1.5rem;
  }

  td, th {
    font-size: 1.3rem;
  }
  .tab-content {
    margin: 0px;
    border: 0px solid #ddd;
    border-top: none;
  }

  .nav-tabs {
    border-bottom: 0px solid #dee2e6;
  }

  .form-group.csv-import-file input.form-control-file {
    border-radius: 30px;

  }

  .nav-tabs .nav-item {
    margin-bottom: 0px;
  }

  .tabs .nav-tabs .nav-link.active, .tabs .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: transparent !important;
  }

  .tabs .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
  }

  a.nav-link.active {
    //border: none !important;
  }
  .button-loading::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 4px solid transparent;
    border-radius: 50%;
    border-top-color: black;
    border-bottom-color: black;
    animation: button-loading-spinner 1s ease infinite;
  }
}
</style>