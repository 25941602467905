<template>
    <HomeCardCarousel title="Featured" type="featured-event"></HomeCardCarousel>
</template>
<script>
import HomeCardCarousel from "@/components/Home/New/HomeCardCarousel.vue";

export default {
    components: {
        HomeCardCarousel,
    },
    name: "HomeEvents",
    data() {
        return {
        };
    },
    mounted() {
    },
    beforeDestroy() {

    },
    methods: {
    },
    computed: {
      componentText() {
        return this.getText.dfd;
      },
    }
};
</script>
<style lang="less">

</style>