<template>
	<div id="wallet-promo-banner">
		<div class="wallet-promo-banner-text">
			<div class="promo-top">Promoted</div>
			<h3>Busy Life, <br/> Healthy Balance ?</h3>
			<p>Struggling to stay on top of a healthy diet? Book a session with one of our amazing Dietitians</p>
		</div>
		<div class="wallet-banner-featured-image">
			<img
				class="wallet-promo-image"
				src="/img/digitalClinics/WalletPromoBanner.png"
			/>
		</div>
	</div>
</template>
<script>

export default {
	components: {
	
	},
	name: "WalletPromoBanner",
	data() {
		return {
		
		};
	},
	mounted() {
	},
	methods: {

	},
	props : [],

};
</script>
<style lang="less">
#wallet-promo-banner {
	background-color: white;
	position: relative;
	overflow: hidden;
	padding: 15px;
	.wallet-promo-banner-text {

	}
	.promo-top {
		position: absolute;
		color: white;
		background-color: #2bb673;
		padding: 2px 20px 2px 15px;
		top: 0;
		left: 0;
	}
	h3 {
		letter-spacing: normal;
		margin: 0;
		padding: 40px 0px 0px;
	}
	p {
		max-width: 30%;
		font-size: 13px;
	}
	.wallet-banner-featured-image {
		position: absolute;
		right: 0;
		top: 0;
		max-width: 60%;
		img {
			max-width: 100%;
		}
	}
}
</style>
