<template>
    <div id="all-courses">
        <div class="page-header bg-transparent" >
            <div class="content-container row">
                <div class="page-title col-12">
                    <h2>{{$route.meta.title ? $route.meta.title : componentText.title}}</h2>
                </div>
                <div class="search-bar col-12">
                    <b-form-input
                            v-model="searchQuery"
                            type="text"
                            placeholder="Search">
                    </b-form-input>
                    <b-button-group class="filter-buttons row my-5" fluid>
                        <b-button @click="filterCourses('body')" :class="{active: filter==='body'}" class="filter-button filter-button-white px-2 px-md-5 py-3 mx-2 mx-md-3 my-2">{{componentText.body}}</b-button>
                        <b-button @click="filterCourses('life')" :class="{active: filter==='life'}" class="filter-button filter-button-white px-2 px-md-5 py-3 mx-2 mx-md-3 my-2">{{componentText.life}}</b-button>
                        <b-button @click="filterCourses('mind')" :class="{active: filter==='mind'}" class="filter-button filter-button-white px-2 px-md-5 py-3 mx-2 mx-md-3 my-2">{{componentText.mind}}</b-button>
                        <b-button v-if="!$store.state.accessCode && $store.state.subdomain !== 'laya-wellbeing'" @click="filterCourses('remote_working')" :class="{active: filter==='remote_working'}" class="filter-button filter-button-white px-2 px-md-5 py-3 mx-2 mx-md-3 my-2 remote">{{componentText.remote}}</b-button>
                        <b-button v-if="!$store.state.user.access_code_user && $store.state.subdomain !== 'laya-wellbeing'" @click="filterCourses('active')" :class="{active: filter==='active'}" class="filter-button filter-button-white px-2 px-md-5 py-3 mx-2 mx-md-3 my-2">{{componentText.active}}</b-button>
                        <b-button v-if="!$store.state.user.access_code_user && $store.state.subdomain !== 'laya-wellbeing'" @click="filterCourses('recommended')" :class="{active: filter==='recommended'}" class="filter-button filter-button-white px-2 px-md-5 py-3 mx-2 mx-md-3 my-2" >{{componentText.recommended}}</b-button>
                        <b-button @click="filterCourses('all')" :class="{active: filter==='all'}" class="filter-button filter-button-white px-2 px-md-5 py-3 mx-2 mx-md-3 my-2">{{componentText.all}}</b-button>
                    </b-button-group>
                </div>
            </div>
        </div>
        <div class="articles bg-white pb-5">
            <div class="content-container row">
                <div class="section-header section-header-right">
                    <div class="section-header-divider"></div>
                    <h3 class="section-header-title">{{componentText.paths}}</h3>
                </div>
                <div v-for="(course, index) in filteredCourses" :key="index" class="col-12 col-sm-6 col-md-3 article-wrap">
                    <a class="card" @click="selectCourse(course.course_id)">
                        <div class="card-header bg-brand-primary">
                            <img class="card-logo" :src="course.course_image_url" />
                        </div>
                        <div class="card-body" ref="cardBody">
                            <p class="card-title" ref="cardTitle">{{ course.course_category.replace("_", " ") }}</p>
                            <div class="card-text text-eclipse">{{ course.course_title }}</div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import axios from "axios";
    import config from "../config/constants";
    import WellbeingCarousel from "../components/Wellbeing/WellbeingCarousel.vue"
    export default {
        name: "AllCourses",
        components: {

        },
        data() {
            return {
                searchQuery: '',
                filter: 'all',
                enrolled:0,
                reccommended: 0,
                articles:{},
                resources: {},
                img_type: {
                    video: "video-white.svg",
                    text: "blog-white.svg",
                    image: "far fa-image",
                    audio: "podcast-white.svg"
                },
                articlePayload:{
                    user_id: this.$store.getters.user_id,
                    search_key: '',
                    tag: 'all',
                    filter: '',
                    fav_only: '',
                    page: 0,
                    page_size: 12,
                    surge_only: 1
                },
                listSurgePayload:{
                    user_id: this.$store.getters.user_id,
                    search_key: "",
                    channel:"surge",
                    limit: -1,
                    page_size:9999,
                    page: 0,
                },
                pages: '',
                filteredCourses: '',
                loading: true,
            };
        },
        mounted() {
            this.filterCourses('all')
        },
        methods: {
            async filterCourses(filter){
                this.filter = filter
                this.loading = true
                if(filter === 'all' && this.listSurgePayload.category){
                    delete this.listSurgePayload.category
                }
                else if(filter === 'body' ||filter === 'life' || filter === 'mind' || filter === 'remote_working'){
                    this.enrolled = 0
                    this.reccommended = 0
                    this.listSurgePayload.category = filter
                }else if(filter === 'active' || filter === 'recommended'){
                    this.listCourses(filter)
                    return
                }
                let res = await axios.post(config.api_env + '/application/api-hc/list-surge-courses', this.listSurgePayload, config.options)
                this.courses = res.data.courses;
                if(res.data.courses) {
                    this.filteredCourses = this.courses.filter((course) => {
                        return course.course_title.toLowerCase().includes(this.searchQuery.toLowerCase())
                    })
                    console.log(this.filteredCourses)
                    this.loading = false
                    this.pages = Math.ceil(res.data.courses.length / this.listSurgePayload.page_size)
                }
            },
            async listCourses(filter){
                let payload = {
                    user_id: this.$store.getters.user_id,
                    search_key: "",
                    tag: "all",
                    filter: "",
                    limit: -1,
                    rec_only: 0,
                    channel: "surge",
                    page: 0,
                    page_size: 9999,
                    enrolled_only: 0
                }
                if(filter === 'active'){
                    payload.enrolled_only = 1
                }else if(filter === 'recommended'){
                    payload.rec_only = 1
                }
                let res = await axios.post(config.api_env + '/application/api-hc/list-courses', payload, config.options)
                this.courses = res.data.courses;
                if(res.data.courses) {
                    this.filteredCourses = this.courses.filter((course) => {
                        return course.course_title.toLowerCase().includes(this.searchQuery.toLowerCase())
                    })
                    this.loading = false
                    //destroy and recreate slick carousel
                }
            },
            selectCourse(courseId){
                this.$router.push('/wellbeing/'+ courseId)
            },
            previousPage(){
                this.page --
            },
            nextPage(){
                this.page ++
            }
        },
        watch:{
            searchQuery(newQuery){
                this.filteredCourses = this.courses.filter(course => {
                    return course.course_title.toLowerCase().includes(newQuery.toLowerCase())
                })
            }
        },
        computed:{
            skin(){
                return this.$store.getters.skin
            },
            componentText() {
                return this.getText.loggedIn.wellbeing;
            },
        }
    };
</script>
<style lang="less">
    #all-courses{
        .page-header {
            padding: 60px 0 0 0 ;
        }
        .page-title {
            text-align: left;
            padding-bottom: 80px;
            h2 {
                text-align: left;
                font-size: 3.8rem;
                letter-spacing: 0.7rem;
                font-family: "FilsonProBold", sans-serif;
                color: #fff;
            }
        }
        .search-bar{
            input {
                border-radius: 25px;
                height: 50px;
                font-size: 2.4rem;
                font-family: "DMSans", sans-serif;
                padding: 0 30px 5px 30px;
            }
        }
        .filter-buttons{
            display: flex;
            margin: auto;
            align-items: center;
            justify-content: center;
            .filter-button{
                flex-grow: 0;
            }
        }
        .page-filter{
            text-align: right;
            .btn-group{
                border-radius: 25px;
                font-size: 2rem;
                font-family: DMSans
            }
        }
        .paths{
            .section-header-title{
                color: #fff;
            }
            .section-header-divider{
                opacity: 0.5;
            }
        }
        .articles{
            .article-wrap{
                position: relative;
                .card-heart{
                    background-image: url('/img/heart-line_white.svg');
                    position: absolute;
                    width: 30px;
                    height: 26px;
                    top: 15px;
                    right: 30px;
                    background-size: cover;
                    cursor: pointer;
                    &.is-active{
                        background-image: url('/img/heart_white.svg');
                    }
                }
            }
            .card{
                position: relative;
                box-sizing: border-box;
                margin-bottom: 36px;
                transition: .3s ease-out;
                border: none;
                box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
                font-family: 'FilsonProLight', sans-serif;
                border-radius: 10px;
                &:hover{
                    cursor: pointer;
                    text-decoration: none;
                    box-shadow:2px 2px 15px rgba(0, 0, 0, 0.6);
                }
                .card-header{
                    border-radius: 2px 2px 0 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 150px;
                    border-radius: 10px 10px 0 0;
                    .card-logo{
                        max-height: 60%;
                        max-width: 55%;
                    }
                }
                .card-body{
                    width: 100%;
                    height: 110px;
                    box-sizing: border-box;
                    padding: 10px 10px 20px;
                    background: #ffffff;
                    text-align: center;
                    border-radius: 0 0 10px 10px;
                    .card-title{
                        font-family: FilsonProLight, sans-serif;
                        margin: 0;
                        padding: 0 0 10px;
                        font-size: 1.6rem;
                        text-align: center;
                        text-transform: uppercase;
                        opacity: 0.5;
                    }
                    .card-text{
                        margin: 0;
                        padding: 0;
                        font-family: FilsonProLight, sans-serif;
                        font-size: 1.8rem;
                        line-height: 1;
                        text-align: center;
                    }
                }
            }
        }
    }
</style>
