<template>
    <div class="pulse-form-container content-container">
      <div class="pulse-form-header bg-brand-secondary">
        <h4 class="heading-text">A question before you go</h4>
        <div class="icon-holder">
          <img src="https://healthcoach-media.com/res/DigitalClinics/rocket.png" class="rocket-icon"/>
        </div>

      </div>
      <div class="question-container">
        <div v-if="!surveyComplete">
          <p class="text-center brand-secondary">{{questions[currentQuestion - 1].text}}</p>
          <b-row class="mt-5">
            <b-col cols="10" offset="1">
              <!--Slider-->
              <div v-if="questions[currentQuestion - 1].layout === 'scale'">
                <div ref="slider-holder">
                  <output ref="tooltip" for="distance" :value="sliderAnswer" class="range-slider-tooltip" :style="{'left':left}">{{ sliderAnswer }}</output>
                  <input
                      ref="slider"
                      type="range"
                      id="pulse-slider"
                      class="pulse-answer-slider w-100"
                      :min="questions[currentQuestion - 1].min_value"
                      :max="questions[currentQuestion - 1].max_value"
                      :value="sliderAnswer"
                      @input="changeSliderAnswer()"
                      style="padding: 0px 0px 0px 0px; height: 30px"
                  />
                </div>
                <b-col cols="12" offset="0" class="d-flex justify-content-between p-0">
                  <div class="scaleNumber">{{questions[currentQuestion - 1].min_value}}</div>
                  <div class="scaleNumber">{{questions[currentQuestion - 1].max_value}}</div>
                </b-col>
                <b-col cols="12" offset="0" class="d-flex justify-content-between p-0">
                  <div class="scaleText">(Extremely <br> Unlikely)</div>
                  <div class="scaleText text-right">(Extremely <br> Likely)</div>
                </b-col>
              </div>
              <div v-if="questions[currentQuestion - 1].layout === 'other' && questions[currentQuestion - 1].type === 'text'"
                   class="d-flex justify-content-center align-items-center"
              >
                <b-form-textarea
                    type="text"
                    id="pulse-text-input"
                    class="pulse-text-input mx-auto"
                    v-model="currentAnswer"
                />
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col cols="3" offset="9" class="d-flex justify-content-end px-5">
              <b-button class="cta-button px-5" @click="answerQuestion">{{currentQuestion === questions.length ? 'Submit' : 'Next'}}</b-button>
            </b-col>
          </b-row>
        </div>
        <div v-else>
          <p class="text-center brand-secondary">Thank you for completing this survey</p>
        </div>
      </div>
    </div>
</template>

<script>
import axios from "axios";
import config from "@/config/constants";

export default {
  name: "dc-feedback-modal",
  data() {
    return {
      questions: [],
      currentQuestion: 1,
      sliderAnswer: 0,
      left: '15px',
      surveyComplete: false,
      currentAnswer: '',
    }
  },
  mounted() {
    this.getSurvey();
  },
  watch: {
    'sliderAnswer': function() {
      this.setToolTip();
    }
  },
  methods: {
    async answerQuestion() {
      const q = this.questions[this.currentQuestion - 1];
      const payload = {
        user_id: this.$store.getters.user_id,
        question_id: q.id,
        answer: [{answer: this.currentAnswer}],
      };
      let res = await axios.post(
          config.api_env +
          "/application/api/u/surveys/answer-question",
          payload,
          config.options
      );
      if(res.data.success) {
        if(this.currentQuestion === this.questions.length) {
          this.surveyComplete = true;
        } else {
          this.currentQuestion += 1;
          this.currentAnswer = "";
          if(this.questions[this.currentQuestion - 1].layout === 'scale') {
            this.sliderAnswer = this.questions[this.currentQuestion - 1].min_value;
            this.currentAnswer = this.questions[this.currentQuestion - 1].min_value;
          }
        }
      }
    },
    setToolTip() {
      const q = this.questions[this.currentQuestion - 1];
      let currentAnswer = this.sliderAnswer
      if(q.min_value !== 0) currentAnswer = this.sliderAnswer - 1;
      const calc =
          (100 / (q.max_value - q.min_value) * currentAnswer) /100 *
          (this.$refs.slider.clientWidth - this.$refs.tooltip.clientWidth);
      //the 15px added to the calc is due to the amount of padding at the left end of the slider
      this.left = (calc + 15) + 'px';
    },
    changeSliderAnswer() {
      this.sliderAnswer = document.getElementById("pulse-slider").value;
      this.currentAnswer = this.sliderAnswer;
    },
    async getSurvey() {
      const payload = {
        user_id: this.$store.getters.user_id,
        name: 'dc_coaching_feedback',
      };
      let res = await axios.post(
          config.api_env + "/application/api/u/surveys/get-fixed-survey",
          payload,
          config.options
      );
      if(res.data.success) {
        const qs = [];
        res.data.survey.sections.forEach(section => {
          section.questions.forEach(question => {
            qs.push(question);
          })
        });
        this.questions = qs;
        if(qs[0].layout === 'scale') {
          this.sliderAnswer = qs[0].min_value;
        }
      }
    }
  }
}
</script>

<style lang="less">
#dc-feedback-modal{
  width: 100%;
  .modal-body {
    padding: 0;
    border-radius: 0px !important;
  }
  .modal-content {
    border-radius: 0px !important;
  }
  .pulse-form-header {

    font-weight: bold;
    padding: 30px;
    text-align: center;
    color: white;
    .heading-text {
      margin-bottom: 2.5rem;
    }
  }
  p {
    font-size: 1.5rem;
    font-family: "FilsonProBold", sans-serif;
  }
  .pulse-container {
    background-color: #f8f8f8;
    min-height: 300px;
    border-radius: 300px;
  }
  .icon-holder {
    background-color: white !important;
    width: 75px;
    height: 75px;
    margin: auto;
    -webkit-border-radius: 38px;
    -moz-border-radius: 38px;
    border-radius: 38px;
    .rocket-icon {
      width: 100%;
      height: 100%;
      object-fit: cover;
      overflow: hidden;
    }
  }
  .question-container {
    padding: 35px
  }
  input[type="range"] {
    height: 36px;
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
  }
  input[type="range"]:focus {
    outline: none;
  }
  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 30px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #eee;
    border-radius: 15px;
    border: 0px solid #000000;
  }
  input[type="range"]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 0px solid #000000;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    background: #aaa;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: 0px;
  }
  input[type="range"]:focus::-webkit-slider-runnable-track {
    background: #eee;
  }
  input[type="range"]::-moz-range-track {
    width: 100%;
    height: 30px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #eee;
    border-radius: 15px;
    border: 0px solid #000000;
  }
  input[type="range"]::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 0px solid #000000;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    background: #aaa;
    cursor: pointer;
  }
  input[type="range"]::-ms-track {
    width: 100%;
    height: 30px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type="range"]::-ms-fill-lower {
    background: #eee;
    border: 0px solid #000000;
    border-radius: 30px;
    box-shadow: 0px 0px 0px #000000;
  }
  input[type="range"]::-ms-fill-upper {
    background: #eee;
    border: 0px solid #000000;
    border-radius: 30px;
    box-shadow: 0px 0px 0px #000000;
  }
  input[type="range"]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 0px #000000;
    border: 0px solid #000000;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    background: #aaa;
    cursor: pointer;
  }
  input[type="range"]:focus::-ms-fill-lower {
    background: #eee;
  }
  input[type="range"]:focus::-ms-fill-upper {
    background: #eee;
  }
  .flex-grow {
    flex-grow: 1;
  }
  .scaleNumber {
    font-family: "FilsonProBold";
    font-size: 1.5rem;
  }
  .questionText {
    font-size: 1.5rem;
  }
  .pulse-text-input {
    border: rgb(101, 116, 130) 1px solid !important;
    width: 80%;
    margin: auto;
    text-align: center;
    font-size: 1.2rem;
    color: #666;
    padding: 10px;
    min-height: 50px;
    :focus {
      border: rgb(101, 116, 130) 1px solid !important;
    }
    :focus-visible {
      border: rgb(101, 116, 130) 1px solid !important;
      outline: none !important;
    }
  }
  .content-container {
    min-height: 150px;
  }
  .question-spinner {
    border-radius: 16px 16px 0 0;
    margin-top: 0;
    padding-top: 0;
    background-color: white;
    min-height: 320px;
  }
  .range-slider-tooltip {
    position: absolute;
    top: -25px;
    margin: 0;
    padding: 2px 10px;
    padding-bottom: 5px;
    background: rgb(18, 44, 53);
    color: white;
    font-size: 1.4rem;
    font-weight: 600;
  }
  .range-slider-tooltip {
    clip-path: polygon(
        0% 0%, // Top left point
        100% 0%, // Top right point
        100% calc(100% - 5px), // Bottom right point
        calc(50% + 5px) calc(100% - 5px), // Center right of the triangle
        50% 100%, // Tip of the triangle
        calc(50% - 5px) calc(100% - 5px), // Center left of the triangle
        0% calc(100% - 5px) // Bottom left point
    );
  }
}
</style>