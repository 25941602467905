<template>
  <div id="ThirdPartyLogin" :class="{'justify-content-between': showMsSso && showGoogleSso, 'justify-content-center': showMsSso + showGoogleSso < 2}">
    <div class="tpBtn" v-if="showGoogleSso && google_auth_url">
      <a style="text-decoration: none; color: inherit;" 
        :href="google_auth_url">
        <img src="/img/logos/ic_google%20logo.png" alt="google logo"/> Google
      </a>
    </div>
    
    <div class="tpBtn" v-if="showMsSso">
      <img src="/img/logos/ms.png" alt="microsoft logo"/>Microsoft
    </div>

  </div>
</template>

<script>
export default {
  name: 'ThirdPartyLogin',
  props: ['showGoogleSso','showMsSso'],
  data() {
    return {
      google_auth_url: null
    }
  },
  mounted() {
    this.getGoogleAuthUrl();
  },
  methods: {
    async getGoogleAuthUrl() {
      if(!this.showGoogleSso) return;
      const res = await this.api({path: 'api/u/oauth/google/get-auth-url'});
      if(res.success) this.google_auth_url = res.data.auth_url;
    },
  },
  watch: {
    showGoogleSso(val) {
      if(val) this.getGoogleAuthUrl();
    }
  }
}
</script>

<style lang="less">
#ThirdPartyLogin {
  width: 100%;
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .tpBtn {
    width: 46%;
    height: 50px;
    border: 1px #DCDBDD solid;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 1.6rem;
    img {
      margin-right: 15px;
    }
    &:hover {
      background: #efefef;
    }
  }
}
</style>