<template>
    <div id="Recipe">
        <div class="recipe-header" :style="{backgroundImage: 'url(' + recipe.featured_img +')'}">
            <div class="content-container header-inner">
                <div class="recipe-title">
                    <h2>{{recipe.recipe_name}}</h2>
                </div>
                <div class="play-video" @click="playVideo">
                    <font-awesome-icon icon="play" color="#fff"></font-awesome-icon>
                </div>
            </div>
        </div>
        <div class="recipe-info" :class="{'bg-transparent': skin === 0, 'bg-brand-secondary': skin !==0}">
            <div class="content-container row py-5">
                <div class="col-12 col-md-6 allergens pt-5">
                    <div class="allergen-images">
                        <img v-for="(allergen,i) in allergens"
                             :key="i"
                             :src="allergenImageThemed(allergen.allergen.split(' ')[0].toLowerCase())"
                             @error="allergenImageDefault($event, allergen.allergen)"
                             class="pr-2"
                        >
                    </div>
                    <p v-if="allergens.length">{{componentText.contains}} <span v-for="(allergen,i) in allergens">{{allergen.allergen_label}}<span v-if="i < allergens.length - 1">,</span> </span></p>
                </div>
                <div class="col-12 col-md-6 wellness-points pt-5">
                    <h4 v-if="!$store.getters.user.access_code_user">+{{points}} {{componentText.points}}</h4>
                    <p>{{componentText.delicious}}</p>
                </div>
            </div>
        </div>
        <div class="bg-white">
            <div class="content-container row nutrition-stats py-5">
                <div class="col-6 return-link">
                    <b-link :to="{name: 'nutrition'}"><font-awesome-icon icon="chevron-left"></font-awesome-icon> {{componentText.back}}</b-link>
                </div>
                <div class="col-6 d-flex align-items-center justify-content-end">
                  <i id='myList' class="fa-solid myListBtn mr-4" :class="isItemInMyList ? 'fa-circle-check plus':'fa-circle-plus plus'" @click="watchLater"></i>
                  <b-tooltip target="myList" triggers="hover" placement="top" custom-class="mylist-tooltip">
                    {{isItemInMyList ? 'Remove from My List' : 'Add to My List'}}
                  </b-tooltip>
                </div>
                <div class="stats-wrap row mt-5">
                    <div class="col-4 stats border-brand-secondary">
                        <div class="stat-name brand-secondary">{{componentText.ingredients}} </div>
                        <div class="stat brand-secondary"> {{ingredients.length}}</div>
                    </div>
                    <div class="col-4 stats border-brand-secondary">
                        <div class="stat-name brand-secondary">{{componentText.calories}} </div>
                        <div class="stat brand-secondary"> {{recipe.calories}}</div>
                    </div>
                    <div class="col-4 stats ">
                        <div class="stat-name brand-secondary">{{componentText.minutes}} </div>
                        <div class="stat brand-secondary"> {{recipe.prep_time_mins}}</div>
                    </div>
                </div>
                <div class="stats-wrap row pt-5 mb-5">
                    <div class="col-12 col-sm-4 py-3 py-sm-0">
                        <div class="macros border-brand-secondary">
                            <div class="macro-name border-brand-secondary">
                                {{componentText.fat}}
                            </div>
                            <div class="macro-stat">
                                <div class="weight">{{recipe.fat}}g</div>
                                <div class="percentage">{{recipe.fat_perc}}%</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-4 py-3 py-sm-0">
                        <div class="macros border-brand-secondary">
                            <div class="macro-name border-brand-secondary">
                                {{componentText.protein}}
                            </div>
                            <div class="macro-stat">
                                <div class="weight">{{recipe.protein}}g</div>
                                <div class="percentage">{{recipe.protein_perc}}%</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-4 py- py-sm-0">
                        <div class="macros border-brand-secondary">
                            <div class="macro-name border-brand-secondary">
                                {{componentText.carbs}}
                            </div>
                            <div class="macro-stat">
                                <div class="weight">{{recipe.carbs}}g</div>
                                <div class="percentage">{{recipe.carbs_perc}}%</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-grey pb-5">
            <div class="content-container row instructions py-5">
                <div class="col-12 col-lg-7 order-2 order-lg-1">
                        <div class="section-header">
                            <div class="section-header-divider"></div>
                            <h3 class="section-header-title">{{componentText.how}}</h3>
                        </div>
                    <div class="steps" v-html="recipe.method"></div>
                    <b-button @click="logRecipe" class="cta-button py-3 px-5" v-if="$store.getters.subdomain !== 'pwcire' && !$store.getters.user.access_code_user">{{componentText.log}}</b-button>
                </div>
                <div class="col-12 col-lg-5 order-1 order-lg-2 ingredients my-5">
                    <div class="ingredients-card bg-white py-5 pl-5">
                        <h4 class="brand-secondary">{{componentText.what}}</h4>
                        <div v-for="ingredient in ingredients" class="ingredient-row row ml-5 py-3 border-brand-secondary">
                            <span class="ingredient-name col-8">• {{ingredient.ingredient_id}}</span>
                            <span class="ingredient-quantity col-4">{{ingredient.quantity}}</span>
                        </div>
                        <div @click="likeRecipe" class="recipe-heart" v-if="!$store.getters.user.access_code_user">
                            <img v-if="!recipe.favourite" :src="'/img/nutrition/food/' + $store.getters.skin + '/heart-line.svg'" onerror="javascript:this.src='/img/nutrition/food/heart-line.svg'">
                            <img :src="'/img/nutrition/food/' + $store.getters.skin + '/heart.svg'" onerror="javascript:this.src='/img/nutrition/food/heart.svg'" v-else>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showPopup" class="recipe-pop-up">
            <div class="pop-up-background" @click="hidePopup"></div>
            <div class="pop-up-content row">
                <div class="col-12 px-0" v-if="recipe.video_url">
                    <GAVideoPlayer :src="recipe.video_url +'#t=2'" :video-class="'video'"/>
                </div>
                <b-button class="close-popup" @click="hidePopup"><font-awesome-icon class="brand-secondary" icon="times-circle"></font-awesome-icon></b-button>
            </div>
        </div>
        <div v-if="recipeLogged" class="log-confirmation">
            {{componentText.logged}} <i class="fas fa-check"></i>
            <p class="points-confirmation brand-highlight-1">+ {{points}} {{componentText.points}}</p>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import axios from "axios";
    import config from "../config/constants";
    import GAVideoPlayer from "../components/GAVideoPlayer.vue";
   export default {
        name: "Recipe",
        components: {
          GAVideoPlayer
        },
        data() {
            return {
                recipe:{},
                allergens:{},
                ingredients:[],
                points: '',
                recipeLogged: false,
                showPopup: false
            };
        },
        mounted() {
            this.getRecipe()
        },
        methods: {
          async watchLater() {
            if(!this.isItemInMyList) {
              await this.$store.dispatch('addMyListItem',({media_type: 'recipe', product_id: this.product_id, content_id: this.recipe.recipe_id}));
            } else {
              const myListRecord = this.$store.getters.myList.filter(item => item.product_id === this.product_id && item.content_id === this.recipe.recipe_id);
              await this.$store.dispatch('deleteMyListItem',({id: myListRecord[0].id}));
            }
          },
           async getRecipe(){
                const payload= {
                    user_id: this.$store.getters.user_id,
                    recipe_id: this.$route.query.rec
                }
                let res = await axios.post(config.api_env + '/application/api-hc/get-recipe', payload, config.options)
               console.log(res.data)
               this.recipe = res.data.recipe
               this.allergens = res.data.allergens
               this.points = res.data.w_points
               this.ingredients = res.data.ingredients
            },
            playVideo(){
                this.showPopup = true
            },
            hidePopup(){
              this.showPopup = false
            },
            recipeBookNav(){
                this.$router.push('/personal/nutrition')
            },
            async logRecipe(){
                const payload = {
                    user_id: this.$store.getters.user_id,
                    recipe_id: this.$route.query.rec
                }

                let res = await axios.post(config.api_env + '/application/api-hc/log-recipe', payload, config.options)
                if(res.data.success){
                    this.recipeLogged = true
                    setTimeout(() => this.recipeLogged = false, 5000)
                }
            },
            async likeRecipe(){
                const payload = {
                    user_id: this.$store.getters.user_id,
                    type: "recipe",
                    resource_id: this.$route.query.rec
                }
                let res
                if(this.recipe.favourite === 1){
                    this.recipe.favourite = 0
                    res = await axios.post(config.api_env + "/application/api-hc/unlike-item", payload, config.options)
                }else{
                    this.recipe.favourite = 1
                    res = await axios.post(config.api_env + "/application/api-hc/like-item", payload, config.options)
                }
            },
            allergenImageThemed(allergen){
                return '/img/nutrition/food/' + this.skin + '/' + allergen.toLowerCase() + '.svg'
            },
            allergenImageDefault(event, allergen){
                event.target.src = '/img/nutrition/food/' + allergen.split(" ")[0].toLowerCase() + '.svg'
            },
        },
        watch:{

        },
        computed:{
            isVersionTwo() {
              return this.$store.getters.navType === 'versionTwo';
            },
            product_id () {
              return 3;
            },
            isItemInMyList() {
              const isInList = this.$store.getters.myList.filter(item => item.product_id === this.product_id && item.content_id === this.recipe.recipe_id);
              return isInList.length > 0;
            },
            skin(){
                return this.$store.getters.skin
            },
            componentText() {
                return this.getText.loggedIn.recipe;
            },
        }
    };
</script>
<style lang="less">
    #Recipe {
        .recipe-header {
            height: 320px;
            width: 100%;
            background-position: center;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: row;
            background-size: cover;
            background-repeat: no-repeat;
        }
        .header-inner{
            width: 100%;
            &:before{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: rgba(0, 0, 0, 0.5);
            }
            .recipe-title{
                text-align: left;
                display: flex;
                align-items: flex-start;
                height: 100%;
                position: relative;
                h2{
                    text-align: left;
                    font-family: FilsonProBlack;
                    font-size: 5rem;
                    line-height: 1;
                    letter-spacing: 2px;
                    z-index: 1;
                    max-width: 380px;
                    color: #fff;
                    &:before{
                        content: "";
                        position: absolute;
                        top: -40px;
                        left: 0;
                        height: 12px;
                        width: 64px;
                    }
                }
            }
            .play-video{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: #fff;
                font-size: 60px;
                opacity: 0.8;
                cursor: pointer;
                z-index: 1;
                &:hover{
                    opacity: 1;
                }
            }
        }
        .allergens{
            img{
                width: 50px;
                height: 50px;
            }
            p{
                font-family: DMSans;
                font-size: 2rem;
                color: #fff;
            }
        }
        .wellness-points{
            text-align: right;
            h4 {
                font-family: FilsonProBold;
                font-size: 21px;
                color: #fff;
            }
            p{
                font-family: DMSans;
                font-size: 2rem;
                color: #fff;
            }
        }
        .nutrition-stats{
            .return-link{
                a {
                    font-family: 'DMSans', sans-serif;
                    font-size: 1.8rem;
                }
            }
            .stats-wrap{
                margin: auto;
                max-width: 770px;
                width: 100%;
                .stats{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-right-width: 1px;
                    border-right-style: solid;
                    text-align: center;
                    &:last-child{
                        border-right-width: 0px;
                    }
                    .stat-name{
                        font-size: 2.2rem;
                        padding-top: 10px;
                        margin-right: 10px;
                    }
                    .stat{
                        font-family: 'FilsonProBlack', sans-serif;
                        font-size: 6.5rem;
                    }
                }
                .macros{
                    display: flex;
                    border-style: solid;
                    justify-content: center;
                    align-items: center;
                    border-width: 1px;
                    font-size: 2rem;
                    text-align: center;
                    .macro-name{
                        width: 50%;
                    }
                    .macro-stat{
                        width: 50%;
                        border-left-style: solid;
                        border-left-width: 1px;
                        padding: 15px;
                        .percentage{
                            font-family: 'FilsonProBlack', sans-serif;
                        }
                    }
                }
            }
        }
        .instructions{
            .steps{
                font-size: 1.6rem;
                font-family: 'DMSans', sans-serif;
                li{
                    padding: 10px 0;
                }
            }
            .ingredients{
                .ingredients-card{
                    position: relative;
                    margin-right: 50px;
                    &:before {
                        top: 0;
                        height: 50px;
                        width: 50px;
                        transform: skew(40deg) translateX(50%);
                    }
                    &:before, &:after{
                        content: '';
                        position: absolute;
                        right: 0;
                        display: inline-block;
                        background-color: #fff;
                        z-index: 1;
                    }
                    &:after{
                        bottom: 0;
                        height: calc(100% - 50px);
                        width: 47px;
                        transform: translateX(100%);
                    }
                    h4{
                        text-align: center;
                        font-family: FilsonProBold;
                        font-size: 1.7rem;
                        text-transform: uppercase;
                    }
                    .ingredient-row {
                        font-family: 'DMSans', sans-serif;
                        font-size: 1.6rem;
                        border-bottom-style: solid;
                        border-bottom-width: 1px;
                        &:last-child{
                            border-bottom-width: 0;
                        }
                        .ingredient-quantity{
                            text-align: right;
                        }
                    }
                }
                .recipe-heart{
                    position: absolute;
                    top: 0;
                    width: 30px;
                    cursor: pointer;
                    img{
                        width: 30px;
                    }
                    right: -50px;
                }
            }
        }
        .log-confirmation{
            font-size: 3.3rem;
            font-family: 'FilsonProBold', sans-serif;
            color: #fff;
            background-color: rgba(0, 0, 0, 0.8);
            position: fixed;
            top: 0;
            width: 100%;
            text-align: center;
            z-index: 9999;
            padding: 80px 0;
        }
        .recipe-pop-up{
            display: flex;
            align-items: center;
            z-index: 500;
            position:fixed;
            top:0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            text-align: center;
            .pop-up-content{
                max-width: 600px;
                width: 100%;
                margin: auto;
                border-radius: 25px;
                font-size: 1.6rem;
                text-align: left;
                z-index: 10;
                color: #fff;
                position: relative;
                .video{
                    border-radius: 20px;
                    &:focus{
                        outline: none;
                    }
                }
                .workout-info{
                    h4 {
                        font-size: 2.1rem;
                        font-family: 'FilsonProBold', sans-serif;
                    }
                    h5{
                        font-size: 1.9rem;
                        font-family: 'FilsonProBold', sans-serif;
                        img{
                            width: 30px;
                        }
                    }
                    p{
                        font-size: 1.6rem;
                        font-family: 'FilsonProBold', sans-serif;
                    }
                    .exercise-equipment{
                        border-width: 1px 0 1px 0;
                        border-color: #c1c1c1;
                        border-style: solid;
                    }
                }
                .close-popup{
                    position: absolute;
                    top: 0;
                    right: 0;
                    background-color: transparent;
                    width: 50px;
                    height:50px;
                    svg{
                        width: 30px;
                        height: 30px;
                    }
                }
            }
            .pop-up-background{
                width: 100vw;
                height: 100vh;
                position: absolute;
                left: 0;
                right: 0;
                z-index: 5;
                background: transparent;
            }
        }
        @media only screen and (max-width: 768px){
            .stats-wrap{
                .stats {
                    display: flex;
                    flex-direction: column;
                    .stat-name {
                        display: block;
                        width: 100%;
                    }
                    .stat {
                        width: 100%;
                    }
                }
            }
        }
        @media only screen and (max-width: 576px){
            .nutrition-stats {
                .stats-wrap {
                    .stats {
                        .stat-name {
                            font-size: 2rem;
                        }
                        .stat {
                            font-size: 4rem;
                            line-height: 1;
                        }
                    }
                }
            }
        }
      .myListBtn {
        font-size: 3rem;
        color: grey !important;
        cursor: pointer;
      }
    }
</style>
