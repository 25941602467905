<template>
  <div id="TotalMentalHealthSelectCoach">
    <div class="top_buttons" v-if="false">
      <div class="content-container row">
        <div class="col-12 py-5">
          <b-button
            @click="$router.push('/total-mental-health')"
            class="fixed_width"
            variant="purple"
            >Your Coach</b-button
          >
          <!-- <b-button class="fixed_width" variant="outline-purple">Self Care</b-button> -->
        </div>
      </div>
    </div>

    <div class="content-container row">
      <div class="col-12 py-5 d-flex align-items-center">
        <h3 class="py-0 mr-3 cat_title brand-text">{{cat_title}}</h3>
        <div class="horizontal-divider"></div>
      </div>
    </div>

    <div class="coach_filters row content-container">
      <div class="col-12 mb-2">
        <div class="d-flex row align-items-center">
          <div class="col-2"><h5 class="brand-text">Gender Preference:</h5></div>
          <div class="col-10">
            <b-button
              class="fixed_width shadow filson-book"
              v-for="(gender, g_i) in gender_options"
              :key="g_i"
              :variant="
                filter.gender === gender ? 'cta' : 'cta-outline'
              "
              @click="changeFilter('gender', gender)"
              >{{ gender }}</b-button
            >
          </div>
        </div>
      </div>

      <div class="col-12 mb-2">
        <div class="d-flex row align-items-center">
          <div class="col-2"><h5 class="brand-text">Availability:</h5></div>
          <div class="col-10">
            <b-button
              class="fixed_width shadow filson-book"
              v-for="(av, av_i) in availability_options"
              :key="av_i"
              :variant="
                filter.availability === av ? 'cta' : 'cta-outline'
              "
              @click="changeFilter('availability', av)"
              >{{ av }}</b-button
            >
          </div>
        </div>
      </div>
    </div>

    <div class="spacer">
      <br />
      <br />
    </div>
    <div v-if="loading" class="content-container row">
      <div class="col-12">
        <Loader />
      </div>
    </div>
    <div v-else class="content-container row animate__animated animate__fadeIn">
      <div class="pl-3" v-if="filtered_coaches.length === 0">
	      <br/>
        <h1 class="filson-light brand-text">
          &nbsp;Sorry, no clinicians match for these options, try adjusting your search!
        </h1>
      </div>
      <div
        class="col-md-6 col-sm-12 coach mb-5"
        v-for="(coach, c_i) in filtered_coaches"
        :key="c_i"
        v-else
      >
        <div class="coach_inner">
          <div class="row h-100">
            <div class="col-5 left_box">
              <div class="avatar">
                <img class="avatar_img" :src="coach.avatar_url" />
              </div>
              <div class="coach_details">
	              <div><i class="fas fa-check-circle brand-highlight-1"></i> {{ coach.qualification }}</div>
	              <div><i class="fa-solid fa-calendar-check brand-highlight-1"></i> {{ coach.degree }} </div>
                <div><i class="fas fa-graduation-cap brand-highlight-1"></i> {{ coach.institution }}</div>
              </div>
            </div>
            <div class="col-7 right_box d-flex flex-column">
<!--              <div class="recommended_wrapper">-->
<!--                <div v-if="coach.recommended" class="recommended">-->
<!--                  <span>Recommended</span>-->
<!--                </div>-->
<!--              </div>-->
              <h4 class="mt-5 clinician_title">{{ coach.name }}</h4>
	            <h6 class="availability"><div class="ball"></div>Available {{getAvailability(coach.availability)}}</h6>
              <div class="pr-3 pb-3 brand-text" v-html="coach.description"></div>
              <div class="mx-auto mt-auto mb-4">
                <b-button
                  class="fixed_width shadow filson-book"
                  variant="cta-outline"
                  @click="serviceProviderModal(coach.id, coach.name)"
                  >Learn More</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="spacer">
      <br />
      <br />
    </div>
  </div>
</template>

<script>
import Loader from "../components/Loader";
import DigitalClinicsSingleClinician from "../components/DigitalClinics/DigitalClinicsSingleClinician";

export default {
  name: "TotalMentalHealthSelectCoach",
  components: { Loader },
  params: ['category_id'],
  data() {
    return {
      loading: true,
      filter: {
        gender: "Any Gender",
        age: "Any Age",
        availability: "Anytime",
      },
      gender_options: ["Male", "Female", "Any Gender"],
      age_options: ["25-35", "35-45", "45+", "Any Age"],
      availability_options: ["Today", "Tomorrow", "Anytime"],
      coaches: [],
	    cat_title: ''
    };
  },
  mounted() {
    this.logAction('DC Generic Coach Select Page Hit');
    this.getServiceProviders();
    this.$root.$on('bookingFail', ()=> {
      this.showMsgModal('Sorry, the session you attempted to book has already been taken.  Please try again.');
    })
	  // this.serviceProviderModal(7)
  },
  computed: {
    filtered_coaches() {
      let cs = this.coaches.filter((coach) => {
        if (
          (this.filter.age === "Any Age" || this.filter.age === coach.age) &&
          (this.filter.availability === "Anytime" ||
              coach.availability.includes(this.filter.availability)) &&
          (this.filter.gender === "Any Gender" ||
            this.filter.gender === coach.gender)
        ) {
          return true;
        } else {
          return false;
        }
      });
      return cs;
    },
  },
  methods: {
	  getAvailability(a){
			if(a.includes('Today')){
				return 'Today'
			}
		  if(a.includes('Tomorrow')){
			  return 'Tomorrow'
		  }
		  if(a.includes('Anytime')){
			  return ''
			  // return 'After Tomorrow'
		  }
			return a
	  },
    async getServiceProviders() {
      let params = {
        path: "api/u/digital-bookings/get-category-service-providers",
        category_id: this.$route.query.category_id
      };
      let res = await this.api(params);
      if (res.success) {
		this.cat_title = res.category
        this.coaches = res.service_providers;
        this.loading = false;
      }else{
        await this.showMsgModal('You have reached your booking capacity for this service. Please wait until one of your bookings for this service has passed, or reach out to digitalclinics@spectrum.life for cancellations or reschedules.');
        this.$router.push('/clinics/digital');
      }
    },
    changeFilter(f, value) {
      this.filter[f] = value;
    },
	  async serviceProviderModal(c_id, c_name){
      this.logAction('DC Generic Coach Select Page: Selected ' + c_name)
		  const content = this.$createElement( DigitalClinicsSingleClinician, { props: {coach_id: c_id, category_id: this.$route.query.category_id}} )
		  const skin = this.$store.getters.skin;
		  let opts = {
			  title: "Choose "+this.cat_title,
			  hideHeader: false,
			  hideFooter: true,
			  modalClass: "clinician_modal theme-" + skin,
			  headerClass: "clinician_modal_header d-none",
			  footerClass: "d-none",
			  size: "xl",
			  buttonSize: "sm",
			  centered: true,
			  contentClass: "clinician_modal",
			  id : 'clinician_modal',
		  }
		  return this.$bvModal.msgBoxOk(content, opts);
	  },
  },
};
</script>
<style lang="less">
#TotalMentalHealthSelectCoach {
	h3.cat_title {
		font-family: "FilsonProLight", sans-serif;
		letter-spacing: normal;
		font-size: 28px;
		padding-right: 30px;
	}
  .top_buttons {
    background-color: #eee;
  }
  background-color: #fff !important;
  .not-selected {
    color: #aaa;
    border: #bbb;
    background-color: white;
  }
  .btn.fixed_width {
    min-width: 100px;
    margin-right: 0.5em;
    margin-bottom: 0.5em;
    padding-top: 12.5px;
    padding-bottom: 12.5px;
    border-width: 2px;
  }
  .coach {
	  font-family: 'FilsonProLight', sans-serif;
    display: flex;
    .coach_inner {
      flex-grow: 1;
      font-size: 1.4rem;
      border-radius: 20px;
      overflow: hidden;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
      .left_box {
        background-color: whitesmoke;
        .avatar {
          padding: 25px 5px 15px;
          text-align: center;
          img.avatar_img {
            margin: auto;
            border-radius: 50%;
            max-width: 60%;
            box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
          }
        }
        .coach_details {
          padding: 15px;
	        div {
		        margin-bottom: 5px;
	        }
        }
      }
      .right_box {
        background-color: white;
				.clinician_title {
					font-family: 'FilsonProLight', sans-serif;
					font-size: 25px;
					margin-bottom: 5px;
				}
	      .availability {
		      font-size: 14px;
		      margin-bottom: 15px;
		      .ball {
			      width: 10px;
			      height: 10px;
			      border-radius: 50%;
			      background: #0BDA51;
			      display: inline-block;
			      margin-right: 10px;
		      }
	      }
	      
        .recommended_wrapper {
          min-height: 30px;
          margin-bottom: 5px;
          position: relative;
          .recommended {
            position: absolute;
            right: -1px;
            top: 0px;
            color: white;
            background-color: goldenrod;
            border-radius: 0px 0px 0px 20px;
            padding: 8px 15px;
          }
        }
        .coach_info {
          margin-bottom: 20px;
        }
      }
    }
  }
  .horizontal-divider {
    background-color: #ccc;
    padding: 1px;
    flex-grow: 1;
  }
}
</style>
