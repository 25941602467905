<template>
    <div id="FoodSearch" class="bg-brand-secondary py-5">
        <div class="content-container row pl-3">
            <div class="search-bar col-12 py-5">
                <b-form-input
                        v-model="searchQuery"
                        type="text"
                        @change="searchFood"
                        :placeholder="componentText.search">
                </b-form-input>
            </div>
            <div class="col-12 no-results" v-if="noResults">
                {{componentText.noMatch}}
            </div>
            <div v-if="searchResults"
                     class="col-12 col-md-6 search-results px-0"
                     :class="{hidden: smallScreen && selectedFood}">
                <div class="results-box">
                    <div v-for="result in searchResults"
                             @click="getFood(result.food_id)"
                             class="food-result py-4 px-4"
                             :class="{active: selectedFood.food_id === result.food_id}"
                            v-html="result.food_label"
                    >
                    </div>
                </div>
            </div>
            <div class="back-to-search col-12 brand-highlight-1 pb-4"
                 v-if="smallScreen && selectedFood"
                 @click="backToSearch"
            >
                <font-awesome-icon icon="chevron-left"></font-awesome-icon> {{componentText.back}}
            </div>
            <div v-if="selectedFood" class="col-12 col-md-6 food " :class="{hidden: smallScreen && !selectedFood}">
                <div class="food-card bg-white py-5 pl-5 pr-4">
                    <h4 class="brand-secondary">{{selectedFood.food_label}}</h4>
                    <div  class="food-row row ml-5 py-4 border-brand-secondary">
                        <span class="macro-name col-8">{{componentText.fat}}</span>
                        <span class="macro-value col-4">{{selectedFood.portions[portionIndex].fat}}</span>
                    </div>
                    <div  class="food-row row ml-5 py-4 border-brand-secondary">
                        <span class="macro-name col-8">{{componentText.protein}}</span>
                        <span class="macro-value col-4">{{selectedFood.portions[portionIndex].protein}}</span>
                    </div>
                    <div  class="food-row row ml-5 py-4 border-brand-secondary">
                        <span class="macro-name col-8">{{componentText.carbs}}</span>
                        <span class="macro-value col-4">{{selectedFood.portions[portionIndex].carbohydrate}}</span>
                    </div>
                    <div  class="food-row row ml-5 py-4 border-brand-secondary">
                        <span class="macro-name col-8">{{componentText.cals}}</span>
                        <span class="macro-value col-4">{{selectedFood.portions[portionIndex].energyKcal}}</span>
                    </div>
                    <div  class="food-row row ml-5 py-4 border-brand-secondary">
                        <span class="macro-name col-12">{{componentText.selectPortion}}</span>
                        <a v-for="(portion,i) in selectedFood.portions"
                           :key="i"
                           @click="selectPortion(i)"
                           class="col-12 portion"
                           :class="{active: portionIndex === i}"
                        >
                            {{portion.portion_label}}
                            <font-awesome-icon icon="check" class="brand-highlight-1"></font-awesome-icon>
                        </a>
                    </div>
                    <div  class="food-row row ml-5 py-3 border-brand-secondary">
                        <span class="macro-name col-8">{{componentText.servings}}</span>
                        <b-input type="number" class="col-4 servings-input border-brand-secondary" v-model="servings" placeholder="e.g 1"></b-input>
                    </div>
                    <div class="col-12 log-food my-5">
                        <b-button @click="logFood"
                                  :disabled="!servings >0 "
                                  class="cta-button px-5 py-3">{{componentText.log}}</b-button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="foodLogged" class="log-confirmation">
            {{componentText.logged}}  <font-awesome-icon icon="check" class="brand-highlight-1"></font-awesome-icon>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import axios from "axios";
    import config from "../../config/constants";
    export default {
        name: "FoodSearch",
        components: {

        },
        data() {
            return {
                searchQuery: "",
                searchResults:"",
                selectedFood: "",
                portionIndex: 0,
                servings: "",
                foodLogged: false,
                smallScreen: false,
                noResults: false
            }
        },
        created() {
            window.addEventListener('resize', this.handleResize)
            this.handleResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize)
        },
        methods: {
          async searchFood(query){
              const payload = {
                  user_id: this.$store.getters.user_id,
                  limit: 30,
                  source: "nutritics",
                  search_string: query
              }
              let res = await axios.post(config.api_env + "/application/api-hc/search-food-database", payload, config.options )
              console.log(res.data)
              if(res.data.success){
                  this.noResults = false
                  this.searchResults = res.data.foods
              }else{
                  this.noResults = true
              }
          },
            async getFood(id){
                const payload = {
                    user_id: this.$store.getters.user_id,
                    food_id: id,
                    source: "nutritics",
                }
                let res = await axios.post(config.api_env + "/application/api-hc/get-food", payload, config.options )
                console.log(res.data)
                if(res.data.success){
                    this.selectedFood = res.data.food
                    this.portionIndex = 0
                }
            },
            selectPortion(index){
                this.portionIndex = index
            },
            async logFood(){
                const payload = {
                    user_id: this.$store.getters.user_id,
                    title: this.selectedFood.food_label,
                    calories: this.selectedFood.portions[this.portionIndex].energyKcal,
                    fat: this.selectedFood.portions[this.portionIndex].fat,
                    protein: this.selectedFood.portions[this.portionIndex].protein,
                    carbs: this.selectedFood.portions[this.portionIndex].carbohydrate,
                    serving_size: this.servings
                }
                let res = await axios.post(config.api_env + "/application/api-hc/log-food", payload, config.options )
                if(res.data.success){
                    this.foodLogged = true
                    setTimeout(() => this.foodLogged = false, 5000)
                }

            },
            backToSearch(){
                this.selectedFood = ''
            },
            handleResize(){
                if(window.innerWidth < 768){
                    this.smallScreen = true
                }else{
                    this.smallScreen = false
                }
            }
        },
        watch:{
        },
        computed:{
            componentText() {
                return this.getText.loggedIn.nutrition.foodSearch;
            },
        }
    };
</script>
<style lang="less">
    #FoodSearch {
        .search-bar{
            input {
                max-width: 670px;
                margin: auto;
                border-radius: 25px;
                height: 50px;
                font-size: 2.4rem;
                font-family: "DMSans", sans-serif;
                padding: 0 30px 5px 30px;
            }
        }
        .hidden{
            display: none;
        }
        .back-to-search{
            font-size: 1.8rem;
            cursor: pointer;
        }
        .no-results{
            font-size: 1.8rem;
            color: #fff;
            text-align: center;
        }
        .search-results{

            .food-result{
                border-width: 0 1px 1px 1px;
                border-color: #fff;
                border-style: solid;
                cursor: pointer;
                &:hover, &.active{
                    font-family: FilsonProBold;
                }
            }
            .results-box{
                max-height: 650px;
                height: 100%;
                overflow-y: scroll;
                font-family: 'DMSans', sans-serif;
                color: #fff;
                font-size: 1.7rem;
                position: relative;
            }
            &:before,&:after{
                position: absolute;
                left: 0;
                content: '';
                width: calc(100% - 10px);
                height: 1px;
                background-color: #fff;
            }
            &:before{
                top:0;
            }
            &:after{
                bottom: 0;
            }
        }
        ::-webkit-scrollbar {
            width: 10px;
        }
        ::-webkit-scrollbar-track {
             border-radius: 10px;
        }
        ::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: #fff;
            height: 50px;
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
        }
        .food-card{
            position: relative;
            z-index: 5;
            overflow-x: visible;
            margin-right: 90px;
            &:before {
                top: 0;
                height: 90px;
                width: 90px;
                transform: skew(40deg) translateX(50%)
            }
            &:before, &:after{
                content: '';
                position: absolute;
                right: 0;
                display: inline-block;
                background-color: #fff;
                z-index: -10;

            }
            &:after{
                bottom: 0;
                height: calc(100% - 90px);
                width: 82px;
                transform: translateX(100%);
            }
            h4{
                text-align: center;
                font-family: FilsonProBold;
                font-size: 2.8rem;
                text-transform: uppercase;
            }
            .food-row {
                z-index: 5;
                font-family: 'DMSans', sans-serif;
                font-size: 1.6rem;
                border-bottom-style: solid;
                border-bottom-width: 1px;
                overflow-x: visible;
                &:last-child{
                    border-bottom-width: 0;
                }
                .macro-value{
                    text-align: right;
                }
                .portion{
                    font-family: FilsonProLight;
                    svg{
                        text-align: right;
                        visibility: hidden;
                    }
                    &.active{
                        svg{
                            visibility: visible;
                        }
                    }
                }
                .servings-input{
                    font-size: 19px;
                    border-style: solid;
                    border-width: 1px;
                    padding: 10px 20px;
                    border-radius: 30px;
                    text-align: right;
                }
                ::-webkit-outer-spin-button,
                ::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
            }
            .log-food{
                text-align: center;
            }
        }
        .log-confirmation{
            font-size: 3.3rem;
            font-family: 'FilsonProBold', sans-serif;
            color: #fff;
            background-color: rgba(0, 0, 0, 0.8);
            position: fixed;
            top: 0;
            width: 100%;
            text-align: center;
            padding: 80px 0;
            z-index: 9999;
        }
    }
</style>
