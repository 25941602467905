<template>
    <div id="CustomFoodLog" class="bg-brand-secondary py-5">
        <div class="content-container row">
            <b-form-group
                    id="food-log"
                    :label="componentText.whatFood"
                    label-for="food"
                    class="col-12 col-md-6 mb-0"
            >
                <b-form-input
                        type="text"
                        id="food"
                        v-model="foodLog">
                </b-form-input>
            </b-form-group>
            <b-form-group
                    id="calories"
                    :label="componentText.howManyCals"
                    label-for="calory-log"
                    class="col-12 col-md-6 col-lg-3 mb-0 "
            >
                <b-form-input
                        type="number"
                        id="calory"
                        v-model="calories">
                </b-form-input>
            </b-form-group>
            <div class="col-12 col-sm-3 py-5 py-lg-0 text-center button-wrap d-flex align-items-end">
                <b-button @click="logFood" class="cta-button">{{componentText.save}}</b-button>
            </div>
        </div>
        <div v-if="foodLogged" class="log-confirmation">
            {{componentText.logged}}  <font-awesome-icon icon="check" class="brand-highlight-1"></font-awesome-icon>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import axios from "axios";
    import config from "../../config/constants";
    export default {
        name: "CustomFoodLog",
        components: {
        },
        props:{
        },
        data() {
            return {
                foodLog: null,
                calories: null,
                foodLogged: false
            };
        },
        mounted() {
        },
        methods: {
            async logFood(){
                const payload = {
                    user_id: this.$store.getters.user_id,
                    title: this.foodLog,
                    calories: this.calories,
                    fat: 0,
                    protein: 0,
                    carbs: 0,
                    serving_size: 1
                }
                let res = await axios.post(config.api_env + "/application/api-hc/log-food", payload, config.options )
                if(res.data.success){
                    this.foodLogged = true
                    setTimeout(() => this.foodLogged = false, 5000)
                    this.$emit('log-update')
                }

            }
        },
        computed:{
            componentText() {
                return this.getText.loggedIn.nutrition.customFoodLog;
            },
        }
    };
</script>
<style lang="less">
    #CustomFoodLog{
        display: flex;
        justify-content: center;
        .content-container{
            width: 100%;
            //IE styles
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                margin: 0 !important;
            }
        }
        label{
            margin: 0 0 24px 10px;
            font-family: 'DMSans', sans-serif;
            font-size: 24px;
            line-height: 1.1;
            color: white;
        }
        input, .btn{
            font-size: 24px;
            width: 100%;
            height: 53px;
            padding: 0 30px;
            border-radius: 25px;
            border: none;
        }
        .btn{
            font-family: FilsonProBold;
            font-size: 1.8rem;
        }
        .log-confirmation{
            font-size: 3.3rem;
            font-family: 'FilsonProBold', sans-serif;
            color: #fff;
            background-color: rgba(0, 0, 0, 0.8);
            position: fixed;
            top: 0;
            width: 100%;
            text-align: center;
            padding: 80px 0;
            z-index: 9999;
        }
    }
</style>
