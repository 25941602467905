<template>
  <div class="HomeClinicCard " @click="goToClinician">
    <div class="recipeCardContainer" style="position: relative">
      <div class="row mx-0 h-100 bg-black">
        <div class="col-12 px-0 recipe-image-wrap">
          <img :src="item.featured_img">
        </div>
<!--        <div class="text-white card-details" style="position: absolute; left: 45%; width: 55%">-->
<!--          <div class="d-flex flex-column h-100 d-flex flex-column justify-content-around">-->
<!--            <div class="title pl-3 text-white">{{ item.title }}</div>-->
<!--            <div class="priceHolder">-->
<!--              <div v-if="settings === 'wallet'" class="dc-cat-price">-->
<!--                  <h5 class="dc-price ml-4">-->
<!--                      Price:-->
<!--                      <span class="dc-amount">-->
<!--                         {{getCurrencyPriceString(item.currency, item.price, true)}}-->
<!--                      </span>-->
<!--                      <br />-->
<!--                      <span class="dc-tax" v-if="item.tax !== 0">-->
<!--                         (+{{getCurrencyPriceString(item.currency, item.tax, true)}} VAT)-->
<!--                      </span>-->
<!--                  </h5>-->
<!--                  <div class="dc-cat-price-triangle" :class="{ 'large' : item.tax > 0}"></div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

<!--        </div>-->
      </div>

    </div>
    <div class="video-card-meta-container">
      <div class="video-card-series"></div>
      <div class="d-flex flex-row flex-nowrap justify-content-between align-items-center">

        <div class="card-title brand-text clickable">{{ advertMode ? "Book a " + item.title  : item.title }}</div>
        <!--                <VideoCardTags :video="video" :type="type"/>-->
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: "HomeClinicCard",
  props: ['item', 'type','swiping','settings','advertMode'],
  data() {
    return {
    };
  },
  mounted() {

  },
  computed: {
    componentText() {
      return this.getText.dfd;
    },
  },
  methods: {
      goToClinician() {
      if(this.swiping) return;
      this.$router.push('/clinics/digital?category_id=' + this.item.id)
    }
  }
};
</script>

<style lang="less">
@keyframes fadeIn {
  from {
    display: none;
  }

  to {
    display: block;
  }
}

@keyframes fadeOut {
  from {
    display: block;
  }

  to {
    display: none;
  }
}

.HomeClinicCard {
  .card-title {
    font-family: FilsonProBold, Arial, Helvetica, sans-serif;
    font-size: 1.6rem;
  }
  .card-details {
    z-index: 999;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .dc-cat-price {
    position: relative;
    justify-self: flex-end;
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(242, 241, 244, 1) 50%);
    padding: 10px 30px 1px 0px;
    width: 90%;
    max-width: 24rem;
    margin: 7px 0px;

    .dc-price {
      font-size: 1.4rem;
      font-weight: 300;
      font-family: "FilsonProLight", sans-serif;
      color: rgba(0, 0, 0, 0.4);

      .dc-amount {
        color: rgba(0, 0, 0, 0.7);
        font-family: "FilsonProBold", sans-serif;
      }

      .dc-tax {
        font-size: 1.2rem;
        color: grey;
      }
    }
  }


  &:hover {
    cursor: pointer;
  }
  .bg-black {
    background-color: #F5F5F5;
  }
  .recipeCardContainer {
    height: 100%;
    aspect-ratio: 16/9 !important;
    font-family: "FilsonProBold", sans-serif;
    overflow: hidden;
    margin-bottom: 10px;
    border-radius: 10px;
    .divider {
      border-bottom: 3px white solid;
      width: 10%;
      margin-top: 20%;
    }
    .title {
      margin-top: 10%;
      font-family: "FilsonProBold", sans-serif;
      font-size: 1.8em;
    }
    .calories-container {
      margin-top: 20%;
      font-family: "FilsonProBold", sans-serif;
      font-size: 1.1rem;
      width: 80%;
    }
    .recipe-image-wrap {
      max-height: 100%;
      img {
        width: 100%;
      }
    }
  }
  @media only screen and (max-width: 450px) {

  }
  @media only screen and (min-width: 451px) and (max-width: 576px) {
    .title {
      font-size: 2.4rem !important;
    }
    .dc-price {
      font-size: 2.2rem !important;
    }
    .dc-tax {
      font-size: 1.8rem !important;
    }
  }
  @media only screen and (min-width: 577px) and (max-width: 767px) {
    .title {
      font-size: 3rem !important;
    }
    .dc-price {
      font-size: 2.2rem !important;
    }
    .dc-tax {
      font-size: 1.8rem !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 950px) {

  }
  @media only screen and (min-width: 768px) and (max-width: 950px) {

  }
  @media only screen and (min-width: 1315px) and (max-width: 1510px) {
    .title {
      font-size: 1.6rem !important;
    }
    .dc-price {
      font-size: 1.3rem !important;
    }
    .dc-tax {
      font-size: 1.2rem !important;
    }
  }
}
</style>
