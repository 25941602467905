<template>
  <div id="LanguageSelector">
    <div>{{ title }}</div>
    <b-form-select class="selector" v-model="selectedLanguage">
      <b-form-select-option :key="'langSel-'+language.code" v-for="language in languages" :value="language.code">{{ language.label }}</b-form-select-option>
    </b-form-select>
  </div>
</template>

<script>
  export default {
    name: "LanguageSelector",
    props: ['title'],
    data() {
      return {
        selectedLanguage: null,
        languages: [
          {label: "English", code: "en"},
          {label: "Français", code: "fr"},
          {label: "Deutsch", code: "de"},
          {label: "Italiano", code: "it"},
          {label: "Español", code: "es"},
          {label: "Português", code: "pt"},
          {label: "中国人", code: "zh"},
          {label: "Русский", code: "ru"}
        ]
      }
    },
    mounted() {
      this.selectedLanguage = this.$store.getters.lang;
    },
    watch: {
      selectedLanguage(newLang,oldLang) {
        if(!oldLang) return;
        this.$store.dispatch('selectLanguage', newLang)
      }
    }
  }
</script>


<style lang="less">
#LanguageSelector {
  margin-top: 30px;
  font-size: 14px;
  width: 100%;
  display: flex;
  padding-left: 20px;
  padding-right: 12px;
  margin-bottom: 30px;
  flex-direction: column;
  .selector {
    font-size: 14px;
  }
}
</style>