<template>
  <div id="CustomContent">
    <AdminCompanyBoard v-if="useComapanyBoard"/>
    <AdminMyOrg v-else/>
  </div>
</template>

<script>

import AdminCompanyBoard from "@/components/admin/AdminCompanyBoard.vue";
import AdminMyOrg from "@/components/admin/AdminMyOrg.vue";
export default {
  name: "CustomContent",
  components: {AdminMyOrg, AdminCompanyBoard},
  data() {
    return {

    }
  },
  computed: {
    useComapanyBoard() {
      return this.$store.getters.companyBoard;
    }
  }
}
</script>
<style lang="less">
#CustomContent {
  width: 100%;
  min-height: calc(100vh - 200px);
  background-color: white;
  font-family: "FilsonPro", sans-serif;
}
</style>