<template>
  <div id="digital_booking_payment" class="row">
    <div v-if="loading" class="col-12 coach-modal-loader">
      <Loader class="my-auto" />
    </div>
    <div class="col-12" v-else>
	    <div class="payment animate__animated animate__fadeIn">
		    <div class="row no-gutters">
			    <div class="col-lg-12 col-md-12 left_col">
				    <div class="buttons-container">
					    <b-button @click="closeModal()" variant="cta-outline shadow filson-book" block>Back</b-button>
				    </div>
				    <div class="avatar_wrapper mb-5">
					    <div class="avatar_box">
						    <img
							    :src="booking.sp_avatar"
							    title="Coach Avatar"
							    :alt="booking.sp_display_name"
						    />
					    </div>
					    <div class="text">
						    <h3 class="slot_start">
							    {{getHoursMinutes(booking.t_start)}}<br/>
							    {{getDate(booking.t_start)}}
						    </h3>
						    <h1>
							    <span class="session">Video Session with</span><br/>
							    <span class="name brand-highlight-1">{{ booking.sp_display_name }}</span>
						    </h1>
					    </div>
				    </div>
				    <hr/>
				    <div class="payment_box">
					    <div class="price">
						    <p>Total</p>
						    <h1 class="booking_price">
							    <b class="brand-highlight-1">{{getCurrencyPriceString(booking.booking_price_currency, display_price, true)}}</b>
							    <span v-if="booking.booking_price_tax > 0" class="dc-tax"> inc. VAT</span>
						    </h1>
						    <p v-if="dc_setting === 'wallet'" class="wallet">
							    Wallet Funds: <b>
								    <animated-number
									    :value="wallet.amount"
									    :formatValue="formatToPrice"
									    :duration="300"
								    />
						      </b>
						    </p>
					    </div>
					    <br/>
					    <br/>
					    <div v-if="!paid" class="options">
						    <div v-if="status === 'input'" class="wallet_option">
<!--									WALLET-->
							    <div class="row">
								    <div class="col-md-1"></div>
								    <div class="col-md-10">
									    <div class="row">
                        <div class="col-md-6">
                          <b-button :disabled="status==='card_payment'" variant="grey" @click="payWithCard" block class="block shadow filson-book">
                            Pay using Card
                          </b-button>
                        </div>
                        <div class="col-md-6">
                          <b-button :disabled="wallet.amount <= 0 || status==='card_payment'" @click="payWithWallet" block variant="cta" class="btn-block shadow filson-book">Pay using Wallet</b-button>
                        </div>
										    <div class="col-12 text-center">
											    <span class="or">or</span>
										    </div>
										    <div class="col-12 voucher_option">
											    <div id="form-container" class="input-group mb-4">
												    <input type="text"
												           class="form-control"
												           id="voucher-input"
												           :placeholder="examplePlaceholder"
												           :aria-label="examplePlaceholder"
												           v-model="voucherInput"
												           :disabled="voucher_status==='loading' || status==='card_payment'"
												    >
												    <div @click="redeemVoucher" class="input-group-append">
													    <b-button variant="cta" class="filson-book" :disabled="!canBeClicked">Redeem Voucher</b-button>
												    </div>
											    </div>
											    <div v-if="voucher_status === 'loading'" key="loading"  class="res_message animate__animated animate__fadeIn">
												    <p><i class="fas fa-spinner-third fa-spin"></i></p>
											    </div>
											    <div v-else key="message" class="res_message animate__animated animate__fadeIn">
												    <p v-html="message"></p>
											    </div>
										    </div>
									    </div>
									    <div class="col-md-1"></div>
								    </div>
							    </div>
						    </div>
						    <StripePayWithCard v-else-if="status==='card_payment'" :is_cmh="is_cmh" :timeslot_id="booking.t_id" :price="display_price" :wallet_amount="charge_wallet_amount" :card_amount="charge_card_amount" :currency="booking.booking_price_currency"/>
						    <StripePayWithSavedCard v-else-if="status==='saved_card_payment'" :is_cmh="is_cmh" :timeslot_id="booking.t_id" :price="display_price" :wallet_amount="charge_wallet_amount" :card_amount="charge_card_amount"/>
					    </div>
					    <div v-else>
						    <br/>
						    <p>Your payment has been successful. <br/>You will get confirmation email soon. <br/>Thanks</p>
						    <br/>
						    <br/>
					    </div>
				    </div>
			    </div>
		    </div>
	    </div>
    </div>
  </div>
</template>
<script>
import Loader from "../Loader";
import axios from "axios";
import config from "../../config/constants";
import AnimatedNumber from "animated-number-vue";
import StripePayWithCard from "./StripePayWithCard";
import StripePayWithSavedCard from "./StripePayWithSavedCard";

export default {
  name: "DigitalClinicsBookingPayment",
  components: { Loader, AnimatedNumber, StripePayWithCard, StripePayWithSavedCard},
  props: {
    timeslot_id: [Number,String],
    is_cmh: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: true,
	    status: 'input',
	    dc_setting: '',
	    display_price: 0,
	    charge_card_amount : 0,
	    charge_wallet_amount: 0,
	    booking: {
		    booking_price_amount: 6000,
		    booking_price_currency: "EUR",
		    booking_price_vat_percent: 0,
		    sp_avatar: "https://randomuser.me/api/portraits/men/33.jpg",
		    sp_display_name: "John Doe",
		    sp_title: "Qualified Physiotherapist",
		    t_end: "2022-08-20 09:30:00",
		    t_id: 49,
		    t_start: "2022-08-20 09:00:00",
		    title: "Default Title"
	    },
	    wallet : {
		    amount : 0,
		    currency : 'EUR',
		    saved_card : false
	    },
	    examplePlaceholder : 'Eg. 436E01Y2V5AV',
	    voucherInput: '',
	    voucher_status: 'input', // [success, failed, loading, input],
	    message: '',
	    paid: false
    };
  },
  mounted() {
    this.getBookingOptions();
  },
	computed: {
		canBeClicked() {
			return this.voucherInput.length >= 12;
		},
	},
  methods: {
		async payWithCard(){
			this.charge_card_amount = this.display_price
			this.status = this.wallet.saved_card ? 'saved_card_payment' : 'card_payment'
		},
		async payWithWallet(){
			if(this.wallet.amount >= this.display_price){
				// user has more in the wallet then the total price of the booking
				let res = await this.api({
					path: "api/u/digital-bookings/pay-with-wallet",
					slot_id: this.timeslot_id,
				});
				if (res.success) {
					this.paid = true
					this.wallet = {...res.wallet}
					setTimeout(()=>{
						this.$bvModal.hide("clinician_modal");
						setTimeout(()=>{
							this.$router.push({name :'digitalClinicsGeneric'})
						}, 500)
					}, 3000)
				} else {
					this.message = 'Something went wrong'
				}
			} else {
				// co payment, some with wallet and some with card
				this.display_price-=this.wallet.amount
				this.charge_wallet_amount = this.wallet.amount
				this.wallet.amount = 0
				this.charge_card_amount = this.display_price
			}
		},
	  async redeemVoucher() {
		  if(!this.canBeClicked){
			  return
		  }
		  this.voucher_status = 'loading'
		  const payload = {
			  user_id: this.$store.getters.user_id,
			  voucher_code: this.voucherInput
		  };
		  let res = await axios.post(
			  config.api_env + "/application/api/u/wallet/redeem-voucher",
			  payload,
			  config.options
		  );
		  if (res.data.success) {
			  this.message = 'Voucher has been redeemed<br/> and '+this.getCurrencyPriceString(res.data.currency, res.data.amount, true)+' has been added to your wallet'
			  this.voucher_status = 'success'
			  await this.getBookingOptions()
		  } else {
			  this.message = res.data.message
			  this.voucher_status = 'failed'
		  }
	  },
	  formatToPrice(value) {
		  return this.getCurrencyPriceString(this.wallet.currency, value, true);
	  },
    closeModal() {
      this.$bvModal.hide("clinician_modal");
    },
    async getBookingOptions() {
      let path = "api/u/digital-bookings/get-options";
      let p = {
        path: path,
        slot_id: this.timeslot_id,
      };
      let res = await this.api(p);
      if (res.success) {
	      this.dc_setting = res.dc_setting
	      if(res.dc_setting === 'wallet'){
		      this.wallet = {...res.wallet}
	      } else {
					// sort it out later
		      this.message = res.message
	      }
	      this.booking = {
		      booking_price_amount: res.booking_price_amount,
		      booking_price_currency: res.booking_price_currency,
		      booking_price_tax: res.booking_price_tax,
		      sp_avatar: res.sp_avatar,
		      sp_display_name: res.sp_display_name,
		      sp_title: res.sp_title,
		      t_end: res.t_end,
		      t_id: res.t_id,
		      t_start: res.t_start,
		      title: res.title
	      }
				this.display_price = res.booking_price_amount + res.booking_price_tax
				this.loading = false
      } else {
				// there is some issues here boom
      }
    }
  },
};
</script>
<style lang="less">
#digital_booking_payment {
	font-family: "DMSans", Arial, Helvetica, sans-serif;
	b {
		font-family: "FilsonProBold", sans-serif;
	}
	h3.slot_start {
		margin-top: 1em;
		font-size: 19px;
	}
	h1{
		font-size: 26px;
		.session{}
	}
	.payment_box {
		.price {
			text-align: left;
			//width: 60%;
			margin: auto;
			display: inline-block;
			p {
				margin: 0;
			}
			h1 {
				font-size: 40px;
				margin-bottom: 0px;
			}
			.dc-tax {
				text-align: right;
				font-size: 12px;
				color: black;
			}
		}
		.voucher_option{
			padding: 0px 0px 15px;
			#form-container {
				padding: 15px 15px;
				.form-control, .input-group-text {
					font-size: 14px;
					background-color: #e9ecef;
				}
				input.form-control {
					padding: 20px;
					text-transform: uppercase;
				}
				.input-group-append {
					.input-group-text {
						padding-left: 20px;
						padding-right: 20px;
						border-radius: 0px 15px 15px 0px;
						transition: all 0.1s ease-in-out;
					}
				}
				
				.input-group-append.can-be-clicked {
					.input-group-text {
						color: white;
					}
					&:hover {
						cursor: pointer;
					}
				}
			}
			.res_message p {
				text-align: center;
				min-height: 1.5em;
			}
		}
	}
	.buttons-container {
		text-align: left;
		position: absolute;
		left: 30px;
		top: 30px;
		width: 100px;
	}
	font-size: 1.6rem;
	.left_col {
		padding: 30px;
		transition: all ease-in-out 0.5s;
		.avatar_wrapper {
			margin-top: 30px;
			.avatar_box {
				text-align: center;
				img {
					border-radius: 50%;
					max-width: 60%;
					box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
				}
			}
		}
		.text {
			h1 {
				margin: 30px 15px 15px;
				font-size: 30px;
			}
		}
		.info_wrapper {
			margin-bottom: 100px;
		}
		.buttons-container {
			position: absolute;
			right: 30px;
			top: 30px;
			max-height: 50px;
		}
	}
	//
	//.right_col {
	//	position: relative;
	//	background-color: #f7f7f7;
	//	overflow: hidden;
	//	.blur_overlay {
	//		position: absolute;
	//		left: 0;
	//		top: 0;
	//		width: 100%;
	//		height: 100%;
	//		background: url("/img/digitalClinics/CardForm.png") no-repeat center top;
	//		//background: greenyellow;
	//		filter: blur(1rem);
	//		z-index: 100;
	//	}
	//}
}
</style>
