<template>
    <div id="HomeItemCard">
        <div class="item-card bg-white brand-text mb-3">
            <div class="d-flex flex-row flex-nowrap justify-content-start align-items-center position-relative w-100">
                <div class="item-card-icon-wrapper border-brand-highlight-1">
                    <div class="item-card-icon-container">
                        <i class="fas fa-person fa-3x brand-highlight-1"></i>
                    </div>
                </div>
                <div class="item-card-text-container d-flex flex-column align-items-start py-3">
                    <div class="item-card-title">Coaching</div>
                    <div class="item-card-text">Lorem ipsum dolor sit amet consectetur adipisicing elit, ipsum dolor
                        sit amet consectetur adipisicing elit.</div>
                    <b-button variant="white" class="align-self-end py-1">Start Now  <i class="fas fa-chevron-right"></i></b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";

export default {
    name: "ItemCard",
    data() {
        return {
        };
    },
    mounted() {

    },
};
</script>

<style lang="less">
#HomeItemCard {
    margin-top: 20px;

    .item-card {
        border-radius: 4px;
        min-height: 50px;
        display: flex;
        align-items: center;
        padding: 15px 10px;
        border: 1px solid #c8c8c8;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

        .item-card-icon-wrapper {
            position: absolute;
            top: -30px;
            left: -20px;

            .item-card-icon-container {
                background-color: white;
                border-radius: 64px;
                margin-right: 15px;
                height: 60px;
                width: 60px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid;
                border-color: inherit;
            }
        }

        .item-card-text-container {
            margin-left: 55px;
            width: 100%;
            flex-grow: 1;
            .item-card-title {
                font-family: "FilsonProBold", sans-serif;
                font-size: 1.8rem;
            }

            .item-card-text {
                font-size: 1.6rem;
            }
        }



    }
    .btn-white{
        font-size: 1.6rem;
        letter-spacing: 0 !important;
    }
}
</style>
