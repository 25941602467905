<template>
  <div class="container sign-up py-5">
    <div class="row page-content">
      <div class="col-md-6 img-part px-0">
        <div
          class="login-bg"
          :class="{
            'bg-brand-secondary': skin > 0,
            'bg-grad-image-purple': skin === 0
          }"
        >
          <div class="heart-placeholder">
            <img
              v-if="hardcoded.spectrum_heart"
              class="heart-img"
              :src="hardcoded.spectrum_heart"
            />
            <img
              class="heart-img"
              src="/img/default-asset.svg"
              v-else
            />
          </div>
          <div class="sign-up-image"></div>
        </div>
      </div>
      <div class="col-md-6 form min-height">
        <div v-if="hardcoded.login_logos" class="logos d-flex pt-5">
          <div
            v-for="(logo, i) in hardcoded.login_logos"
            :key="i"
            class="d-flex logo px-1"
          >
            <img :src="logo" />
          </div>
        </div>
        <div class="form-content">
          <div
            :class="[
              ($store.getters.client.client_logo.toLowerCase().includes('spectrum-life-logo') ||  $store.getters.client.client_logo.toLowerCase().includes('lloydwhyte')) && skin === 15
                ? 'lw-form-logo'
                : 'form-logo',
            ]"
          >
            <img
              :src="getLogo"
              onerror="javascript:this.src='/img/logo-spectrum-life.svg'"
            />
          </div>
          <h2 class="form-title">{{ componentText.title }}</h2>
          <b-form>
            <div class="row">
              <b-form-group
                class="col-md-6"
                id="input-group-1"
                :label="componentText.firstName"
                label-for="signupform-firstname"
                ><b-form-input
                  type="text"
                  id="signupform-firstname"
                  v-model="newUser.firstname"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                class="col-md-6"
                :label="componentText.lastName"
                id="input-group-2"
                label-for="signupform-lastname"
              >
                <b-form-input
                  type="text"
                  id="signupform-lastname"
                  v-model="newUser.lastname"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                    class="col-md-6"
                    id="input-group-3"
                    :label="componentText.email"
                    label-for="signupform-username"
                ><b-form-input
                    type="text"
                    id="signupform-username"
                    v-model="newUser.username"
                    required
                ></b-form-input>
                </b-form-group>
              <b-form-group
                  class="col-md-6"
                  id="input-group-4"
                  :label="componentText.orgCode"
                  label-for="signupform-org_code"
              >
                <b-form-input
                    type="text"
                    id="signupform-org_code"
                    v-model="newUser.org_code"
                    @change="getOrgLocations"
                    required
                    :disabled="fixedCode"
                ></b-form-input>
              </b-form-group>
              <b-row class="col-12 mobileRow" v-if="twoFactorEnabled">
                <b-form-group
                    class="col-5"
                    id="countryCodeGroup"
                    label="Country Code"
                    label-for="countryCodeSelect"
                >
                  <b-form-select
                      class="form-control"
                      type="text"
                      id="countryCodeSelect"
                      v-model="countryCode"
                  >
                    <b-form-select-option v-for="item in countryCodes" :value="item.dial_code">{{item.name}} - {{item.dial_code}}</b-form-select-option>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  class="col-7"
                  id="mobileGroup"
                  label="Mobile Number"
                  label-for="Mobile"
                >
                  <b-form-input
                    id="Mobile"
                    type="text"
                    v-model="mobile"
                    @keydown="editPhone"
                  ></b-form-input>
                </b-form-group>
              </b-row>


              <b-form-group
                class="col-md-6"
                id="input-group-5"
                :label="componentText.password"
                label-for="newPassword"
              >
                <div class="password-wrap">
                  <b-form-input
                    :type="passwordFieldType"
                    id="newPassword"
                    v-model="$v.newPassword.$model"
                    :state="$v.newPassword.$dirty ? !$v.newPassword.$error : null"
                    @focus="pw_flag = true"
                    @blur="pw_flag = false"
                    required
                  ></b-form-input>
                  <div class="eye" @click="switchVisibility">
                    <font-awesome-icon
                      v-if="passwordFieldType === 'password'"
                      icon="eye"
                    ></font-awesome-icon>
                    <font-awesome-icon
                      v-else
                      icon="eye-slash"
                    ></font-awesome-icon>
                  </div>
                </div>
              </b-form-group>
              <b-form-group
                class="col-md-6"
                id="input-group-6"
                :label="componentText.repeat"
                label-for="repeatPassword"
              >
                <div class="password-wrap">
                  <b-form-input
                    :type="passwordFieldType"
                    id="repeatPassword"
                    v-model="$v.repeatPassword.$model"
                    :state="$v.repeatPassword.$dirty ? !$v.repeatPassword.$error : null"
                    required
                  ></b-form-input>
                  <div class="eye" @click="switchVisibility">
                    <font-awesome-icon
                      v-if="passwordFieldType === 'password'"
                      icon="eye"
                    ></font-awesome-icon>
                    <font-awesome-icon
                      v-else
                      icon="eye-slash"
                    ></font-awesome-icon>
                  </div>
                </div>
              </b-form-group>
              <b-form-group class="col-md-12 validationBox" v-if="pw_flag">
                <span :class="[tenChars?'passed':'failed']"><i class="fa-light fa-xmark" v-if="!tenChars"></i><i class="fa-light fa-check" v-else></i> At least 10 characters</span><br>
                <span :class="[upperCase?'passed':'failed']"><i class="fa-light fa-xmark" v-if="!upperCase"></i><i class="fa-light fa-check" v-else></i> At least 1 uppercase letter</span><br>
                <span :class="[lowerCase?'passed':'failed']"><i class="fa-light fa-xmark" v-if="!lowerCase"></i><i class="fa-light fa-check" v-else></i> At least 1 lower letter</span><br>
                <span :class="[contNumber?'passed':'failed']"><i class="fa-light fa-xmark" v-if="!contNumber"></i><i class="fa-light fa-check" v-else></i> At least 1 number</span><br>
                <span :class="[specChar?'passed':'failed']"><i class="fa-light fa-xmark" v-if="!specChar"></i><i class="fa-light fa-check" v-else></i> At least 1 special character</span>
              </b-form-group>
              <b-form-group
                class="col-md-6"
                v-if="organisation && organisation.locations.length > 1"
                id="input-group-7"
                :label="componentText.location"
                label-for="signupform-locations"
                ><b-form-select
                  id="signupform-locations"
                  v-model="newUser.location"
                  class="form-control"
                  :state="locationValid"
                  required
                >
                  <option :value="null" disabled>Select a location... </option>
                  <option
                    v-for="location in organisation.locations"
                    :value="location.location_id"
                    >{{ location.location_name }}</option
                  >
                </b-form-select>
              </b-form-group>
              <b-form-group
                class="col-md-6"
                v-if="
                  organisation &&
                    organisation.member_levels &&
                    organisation.member_levels !== 'null' &&
                    organisation.member_levels.length > 1
                "
                id="input-group-8"
                :label="componentText.memberLevel"
                label-for="signupform-member_level"
              >
                <b-form-select
                  id="signupform-member_level"
                  class="form-control"
                  v-model="newUser.member_level"
                  :options="organisation.member_levels"
                  required
                ></b-form-select>
              </b-form-group>
              <div class="col-12 row">
                <b-form-checkbox
                  class="col-6"
                  id="checkbox-1"
                  name="privacy-check"
                  v-model="newUser.privacy_check"
                  value="accepted"
                  required
                >
                  {{ componentText.agreePrivacy }}
                  <a target="_blank" href="/datenschutzrichtlinie" v-if="skin===13">
                    {{ componentText.privacy }}</a
                  ><a target="_blank" href="/privacy-policy" v-else>
                    {{ componentText.privacy }}</a
                  >
                </b-form-checkbox>
                <b-form-checkbox
                  class="col-6"
                  id="checkbox-2"
                  name="terms-check"
                  v-model="newUser.terms"
                  value="accepted"
                  required
                >
                  {{ componentText.agreeTerms }}
                  <a
                    target="_blank"
                    href="/nutzungsbedingungen"
                    v-if="skin === 13"
                  >
                    {{ componentText.tcs }}
                  </a>
                  <a target="_blank" href="/terms-and-conditions" v-else>
                    {{ componentText.tcs }}
                  </a>
                </b-form-checkbox>
              </div>
            </div>
            <div
              id="signup-button"
              class="submit-form d-flex align-items-center justify-content-center"
            >
              <b-button
                @click="signUp"
                class="py-3 cta-button"
                :disabled="signUpDisabled"
                >{{ componentText.buttonText }}</b-button
              >
            </div>
            <b-tooltip
              v-if="signUpDisabled"
              target="signup-button"
              triggers="hover"
              custom-class="stats-tooltip"
              >{{ componentText.validate }}</b-tooltip
            >
            <div class="col-sm-12 new-here">
              <a @click="$emit('sign-in')" class=" text-link">{{
                componentText.hasAccount
              }}</a>
            </div>
            <div class="col-12 text-center">
              <LanguageSelect class="border-bottom-0"></LanguageSelect>
            </div>
          </b-form>
        </div>
      </div>
    </div>
    <popup
      v-if="signUpError"
      :content="popUpContent"
      v-on:close="hidePopUp"
    ></popup>
  </div>
</template>

<script>
import axios from "axios";
import config from "../../config/constants";
import Popup from "../Popup.vue";
import { validationMixin } from "vuelidate";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import LanguageSelect from "../LanguageSelect";
import country_codes from '../../assets/country_codes.json'

export default {
  components: { Popup, LanguageSelect },
  name: "SignUp",
  mixins: [validationMixin],
  data() {
    return {
      user: {
        email: ""
      },
      newUser: {
          org_code: "",
          location: null
      },
      isSignUp: false,
      organisation: false,
      fixedCode: false,
      locations: [],
      signUpError: false,
      popUpContent: {},
      passwordFieldType: "password",
      newPassword: '',
      repeatPassword: '',
      tenChars: false,
      upperCase: false,
      lowerCase: false,
      contNumber: false,
      specChar: false,
      pw_flag: false,
      locationValid: true,
      mobile: null,
      countryCode: "+353",
      countryCodes: []
    };
  },
  validations: {
    newPassword: {
      required,
      minLength: function(value) {
        this.tenChars = value.length > 9;
        return value.length > 9;
      },
      containsUppercase: function(value) {
        this.upperCase = /[A-Z]/.test(value);
        return /[A-Z]/.test(value);
      },
      containsLowercase: function(value) {
        this.lowerCase = /[a-z]/.test(value);
        return /[a-z]/.test(value);
      },
      containsNumber: function(value) {
        this.contNumber = /[0-9]/.test(value);
        return /[0-9]/.test(value);
      },
      containsSpecial: function(value) {
        this.specChar = /[^a-zA-Z0-9\-\/]/.test(value);
        return /[^a-zA-Z0-9\-\/]/.test(value);
      }
    },
    repeatPassword: {
      sameAsPassword: sameAs("newPassword"),
    },
  },
  mounted() {
    this.countryCodes = country_codes;
    if (this.$route.query.org) {
      //if the org param is in the url, prefill the form and make api call
      this.newUser.org_code = this.$route.query.org;
      this.fixedCode = true;
      this.getOrgLocations();
    } else if (
      this.$store.getters.hardcoded &&
      this.$store.getters.hardcoded.org_code
    ) {
      this.newUser.org_code = this.$store.getters.hardcoded.org_code;
      this.fixedCode = true;
      this.getOrgLocations();
    }
  },
  methods: {
    editPhone(evt) {
      if(isNaN(evt.key) && evt.key !== 'Backspace') {
        evt.preventDefault();
      }
      if(evt.key !== 'Backspace' && this.mobile.length > 11) {
        evt.preventDefault();
      }
    },
    switchForm() {
      this.isSignUp = !this.isSignUp;
    },
    async signUp() {
      const payload = {
        username: this.newUser.username,
        firstname: this.newUser.firstname,
        lastname: this.newUser.lastname,
        password: this.newPassword,
        password_repeat: this.repeatPassword,
        organisation_code: this.newUser.org_code,
        location_id: this.newUser.location,
        member_level: this.newUser.member_level,
        platform: "web",
        lang: this.$store.state.lang
      };
      if(this.mobile) {
        payload['phone'] = String(this.countryCode) + String(this.mobile);
      }
      let res = await axios.post(
        config.api_env + "/application/api-hc/signup",
        payload,
        config.options
      );
      if (res.data.success) {
        //this.$router.push('/verify').catch(err => {})
        //Removed verify redirect on 17.06.19 - log user in and go to home route
        const user = {
          user_token: res.data.user_id,
          user_info: res.data.log_in_res
        };
        await this.$store.dispatch("setNavType",res.data.log_in_res.home_page);
        const home = await this.$store.dispatch("loginAs", user);
        //for sanusx push straight to insights after registration
        this.$store.getters.skin === 13 ? this.$router.push({name: 'insights'}) : this.$router.push(home);
      } else {
        this.popUpContent = {
          header: "Oops!",
          message: this.componentText.errorMessage + "<br><br>Current Error:<br>" + res.data.err,
          buttonText: "OK"
        };
        this.signUpError = true;
      }
    },
    async getOrgLocations() {
      let payload = {
        org_code: this.newUser.org_code,
        lang: this.$store.state.lang
      };
      try {
        let res = await axios.post(
          config.api_env + "/application/api-hc/get-client-signup-options",
          payload,
          config.options
        );
        if (res.data.success) {
          this.organisation = res.data;
            if (this.organisation.locations.length > 1) {
                this.newUser.location = null;
                this.locationValid = false;
            } else if (this.organisation.locations.length === 1) {
            this.newUser.location = this.organisation.locations[0].location_id;
            this.locationValid = true;
          }
          if(this.organisation.member_levels.length > 0) {
            this.newUser.member_level = this.organisation.member_levels[0];
          }
          this.$store.dispatch('setTwoFactorEnabled',this.organisation.two_factor_enabled);
        } else {
          this.popUpContent = {
            header: "Oops!",
            message: res.data.err,
            buttonText: "OK"
          };
          this.signUpError = true;
        }
      } catch (e) {
        console.log(e);
      }
    },
    hidePopUp() {
      this.signUpError = false;
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    }
  },
  computed: {
    ...mapGetters(["hardcoded", "skin","client"]),
    twoFactorEnabled() {
      return this.client.two_factor_enabled;
    },
    signUpDisabled() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return true;
      }
      if (this.newUser.privacy_check && this.newUser.terms) {
        if(!this.newUser.org_code) return true;
        if(!this.locationValid) return true
        if (
          this.organisation &&
          this.organisation.member_levels &&
          this.organisation.member_levels.length > 1 &&
          !this.newUser.member_level
        ) {
          //member level must be selected if there's more than one level to choose
          return true;
        }
        return false;
      } else return true;
    },
    getLogo() {
      if (this.hardcoded && this.hardcoded.logo) {
        return this.hardcoded.logo;
      } else return this.$store.getters.client.client_logo;
    },
    privacy() {
      if (this.$store.getters.skin === 8) {
        return "/img/boi/BOI-Wellbeing-Privacy-Policy.pdf";
      } else {
        return "/privacy-policy";
      }
    },
    terms() {
      if (this.$store.getters.skin === 8) {
        return "/img/boi/BOI-Wellbeing-TCs.pdf";
      } else {
        return "/terms-and-conditions";
      }
    },
    componentText() {
      return this.getText.notLoggedIn.signUp;
    }
  },
  watch: {
      'newUser.location': function(value) {
          this.locationValid = this.organisation.locations.length < 1 || value !== null;
      }
  }
};
</script>
<style lang="less">
#NotLoggedIn {
  .validationBox {
    line-height: 12px;
    padding-left: 20px !important;
  }
  .passed {
    color: green;
  }
  .failed {
    color: darkred;
  }

  .mobileRow {
    padding-right: 0 !important;
    #countryCodeGroup {
      padding-right: 5px !important;
      select {
        font-size: 1.4rem !important;
        text-align: center;
        padding-right: 5px !important;
        border-radius: 25px 0 0 25px !important;
      }
    }


    #mobileGroup {
      padding-right: 0 !important;
      padding-left: 5px !important;
      input {
        border-radius: 0 25px 25px 0 !important;
      }
    }
  }
}
</style>
