<template>
  <div id="SlidoPoll">
    <div id="poll-container" ref="poll-container" style="height: 100%" v-if="code">
        <iframe
           ref="slido"
           id="slido-container"
           :src="'https://app.sli.do/event/'+code"
           height="100%"
           width="100%"
           frameBorder="0"
           title="Slido"
        ></iframe>
    </div>
  </div>
</template>

<script>
  export default {
      name: 'SlidoPoll',
      props: ['code'],
  }

</script>

<style lang="less">
#SlidoPoll {
  width: 350px;
  height: 100%;
  @media only screen and (max-width: 992px) {
    height: 450px;
  }
}
</style>