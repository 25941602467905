<template>
    <div>
        <div v-if="event.e_type == 'event_series'" class="event-info">
            <div class="event row py-3 justify-content-center" v-for="ed in event.e_dates" :class="{'past-event': isPast(ed.ed_start_date_time)}">
                <div class="col-2 event-date">
                    <div class="event-day">{{getDay(ed.ed_start_date_time)}}</div>
                    <div class="event-month">{{getMonth(ed.ed_start_date_time)}}</div>
                </div>
                <div class="col-6 event-location text-center">
                    <div class="col-12">
                        {{event.e_locations}}
                    </div>
                    <div class="col-12" v-if="event.e_room_info">
                        {{componentText.room}} {{event.e_room_info}}
                    </div>
                </div>
                <div class="col-4 event-time">
                    {{ getHoursMinutes( ed.ed_start_date_time ) }} - {{ getHoursMinutes( ed.ed_end_date_time ) }}
                </div>
                <div class="col-4 event-price d-flex align-items-center" v-if="event.e_employee_slot_cost > 0">Price: {{currencyString(event.e_employee_slot_cost, event.e_currency)}}  </div>
                <div class="col-8 d-flex justify-content-start" :class="{'d-flex justify-content-center': event.e_employee_slot_cost === 0}">
                    <BookDateButton :event_date="ed" :pay_flag="event.e_employee_pay_flag"/>
                </div>
            </div>
        </div>
        <div v-else class="event-info">
            <div class="event-info-item-wrap my-3">
                <div class="event-info-icon">
                    <font-awesome-icon icon="clock" />
                </div>
                <div class=" event-info-item">
                    {{ getDateHours(event.ed_start_date_time) }} - {{ getHoursMinutes(event.ed_end_date_time) }}
                </div>
            </div>
            <div class="event-info-item-wrap my-3">
                <div class="event-info-icon">
                    <font-awesome-icon icon="map-marker-alt" />
                </div>
                <div class=" event-info-item">
                    {{ event.e_locations }}
                </div>
            </div>
            <div class="event-info-item-wrap my-3" v-if="event.e_room_info">
                <div class="event-info-icon">
                </div>
                <div class=" event-info-item">
                    {{componentText.room}} {{ event.e_room_info }}
                </div>
            </div>
            <div class="event-info-item-wrap my-3" v-if="event.e_service_provider_name">
                <div class="event-info-icon">
                    <font-awesome-icon icon="user" />
                </div>
                <div class=" event-info-item">
                    {{ event.e_service_provider_name }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BookDateButton from "./BookDateButton";
    export default {
        name: "EventInfo",
        components : {
            BookDateButton
        },
        props: [
            'event',
        ],
        data() {
            return {

            };
        },
        mounted() {

        },
        methods: {
            reloadEvent(){
                this.$parent.getEventDate()
            }
        },
        computed:{
            componentText(){
                return this.getText.loggedIn.event.eventInfo
            }
        }
    };
</script>
<style lang="less">

</style>
