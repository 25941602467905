<template>
  <div id="TherapySignUp">
    <h2 class="mt-3 mb-3">Sign Up</h2>
    <p>Sign up to access online therapy sessions <br><a href="#" @click="$emit('changeMode')">Already have an account? Login here</a></p>
    <hr />
    <b-form class="my-3">
      <b-form-group
          id="firstnameGroup"
          label="First Name"
          label-for="firstname"
          style="width: 47%; display: inline-block"
      >
        <b-form-input
            type="text"
            id="firstname"
            v-model="firstname"
        />
      </b-form-group>
      <b-form-group
          id="lastnameGroup"
          label="Last Name"
          label-for="lastname"
          style="width: 47%; display: inline-block; margin-left: 3%"
      >
        <b-form-input
            type="text"
            id="lastname"
            v-model="lastname"
        />
      </b-form-group>
<!--      <b-form-group-->
<!--          id="codeGroup"-->
<!--          label="Therapy Code"-->
<!--          label-for="code"-->
<!--      >-->
<!--        <b-form-input-->
<!--            type="text"-->
<!--            id="code"-->
<!--            @input="handleCodeInput"-->
<!--            v-model="code"-->
<!--        />-->
<!--      </b-form-group>-->
    </b-form>
    <hr />
    <b-form>
      <b-form-group
          id="emailGroup"
          label="Email"
          label-for="email"
      >
        <b-form-input
            type="text"
            id="email"
            v-model="email"
            :state="isValidEmail || !emailTouched"
        />
      </b-form-group>
      <b-form-group
          id="passwordGroup"
          label="Password"
          label-for="password"
      >
        <b-form-input
            type="password"
            id="password"
            :state="!passwordTouched || passwordIsValid.valid"
            v-model="password"
            @focus="pw_flag = true"
            @blur="pw_flag = false"
        />
      </b-form-group>
      <b-form-group class="col-md-12 validationBox" v-if="pw_flag && !passwordIsValid.valid">
        <span :class="[passwordIsValid.pw_length?'passed':'failed']"><i class="fa-light fa-xmark" v-if="!passwordIsValid.pw_length"></i><i class="fa-light fa-check" v-else></i> At least 10 characters</span><br>
        <span :class="[passwordIsValid.upper_case?'passed':'failed']"><i class="fa-light fa-xmark" v-if="!passwordIsValid.upper_case"></i><i class="fa-light fa-check" v-else></i> At least 1 uppercase letter</span><br>
        <span :class="[passwordIsValid.lower_case?'passed':'failed']"><i class="fa-light fa-xmark" v-if="!passwordIsValid.lower_case"></i><i class="fa-light fa-check" v-else></i> At least 1 lower letter</span><br>
        <span :class="[passwordIsValid.number?'passed':'failed']"><i class="fa-light fa-xmark" v-if="!passwordIsValid.number"></i><i class="fa-light fa-check" v-else></i> At least 1 number</span><br>
        <span :class="[passwordIsValid.special?'passed':'failed']"><i class="fa-light fa-xmark" v-if="!passwordIsValid.special"></i><i class="fa-light fa-check" v-else></i> At least 1 special character</span>
      </b-form-group>
      <b-form-group
          id="passwordRepeatGroup"
          label="Repeat Password"
          label-for="repeatPassword"
      >
        <b-form-input
            type="password"
            id="repeatPassword"
            v-model="repeatPassword"
            :state="passwordIsValid.match"
        />
      </b-form-group>
    </b-form>
    <div class="row p-3">
      <div class="col-6 mt-4 termsAcceptance">
        <div class="checkBoxBtn mr-4" :class="{selected: acceptedPrivacy}" @click="acceptedPrivacy = !acceptedPrivacy">
          <i class="fa-solid fa-check"></i>
        </div>
        <span>I agree to the<a href="/privacy-policy/" target="_blank"> privacy statement</a></span>
      </div>
      <div class="col-6 mt-4 termsAcceptance">
        <div class="checkBoxBtn mr-4" :class="{selected: acceptedTandCs}" @click="acceptedTandCs = !acceptedTandCs">
          <i class="fa-solid fa-check"></i>
        </div>
        <span>I agree to the<a href="/terms-and-conditions/" target="_blank"> terms and conditions</a></span>
      </div>
    </div>
    <div class="accountMayExistBox px-4" v-if="accountMayAlreadyExist || invalidTherapyCode">
      <p v-if="accountMayAlreadyExist">User creation failed. It might be that you already have an account on our system. Please reset your password by clicking the below link:</p>
      <p v-else>That therapy code is not valid.  Please check the code and try again.</p>
      <button class="therapyBtnOutline mt-4 mx-auto" @click="resetPw" v-if="accountMayAlreadyExist">Send Password Reset</button>
      <span v-if="passwordResetMsg" v-html="passwordResetMsg"></span>
    </div>
    <button class="therapyBtn mt-4 mx-auto" :disabled="buttonDisabled" @click="signUp">Sign Up</button>
  </div>
</template>

<script>
export default {
  name: "TherapySignUp",
  data() {
    return {
      code: null,
      email: '',
      firstname: '',
      lastname: '',
      accountMayAlreadyExist: false,
      password: '',
      repeatPassword: '',
      pw_flag: false,
      passwordTouched: false,
      emailTouched: false,
      invalidTherapyCode: false,
      acceptedTandCs: false,
      acceptedPrivacy: false,
      passwordResetMsg: null
    }
  },
  methods: {
    async resetPw() {
      this.message = null;
      const path = process.env.NODE_ENV === 'production' ? '.spectrum.life/password-reset' : '.surge-qa.com/password-reset';
      const res = await this.api({
        path: "api-hc/send-reset",
        email: this.email,
        return_domain: this.connectedMentalHealthType() + path
      });
      this.passwordResetMsg = '<p>Your request has been acknowledged. An email will be sent shortly with further instructions to reset your password.  You should note that it may take up to 5 minutes for your email to arrive in your inbox.</p>' +
          '<p>Tip: If you don’t see the email in your inbox, check your junk mail.</p>'
      setTimeout(()=> {
        this.passwordResetMsg = null;
      },10000)

    },
    handleCodeInput() {
      this.code = this.code.toUpperCase();
    },
    async signUp() {
      this.accountMayAlreadyExist = false;
      this.invalidTherapyCode = false;
      const res = await this.api({
        path: 'api/u/cmh/sign-up',
        email: this.email,
        password: this.password,
        password_repeat: this.repeatPassword,
        firstname: this.firstname,
        lastname: this.lastname
      })
      if(res.authenticated) {
        this.m('sign-up',{status: 'successful'})
        await this.$store.dispatch('setTherapyUser', {
          user: res.user
        })
      } else {
        if(res.error.includes('Invalid therapy code')) {
          this.invalidTherapyCode = true;
        } else {
          this.accountMayAlreadyExist = true;
        }

      }
    }
  },
  computed: {
    isValidEmail() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(this.email);
    },
    passwordIsValid() {
      const length = this.password.length >= 10;
      const uppercase = /.*[A-Z].*/.test(this.password);
      const lowercase = /.*[a-z].*/.test(this.password);
      const number = /\d/.test(this.password);
      const special = /[^\w\d]/.test(this.password);
      return {
        pw_length: length,
        upper_case: uppercase,
        lower_case: lowercase,
        number: number,
        special: special,
        valid: length && uppercase && lowercase && number && special,
        match: this.password === this.repeatPassword
      }
    },
    buttonDisabled() {
      return !this.acceptedTandCs || !this.acceptedPrivacy || !this.firstname || !this.lastname || !this.isValidEmail || !this.passwordIsValid.valid || !this.passwordIsValid.match;
    }
  },
  watch: {
    pw_flag() {
      if(!this.passwordTouched) this.passwordTouched = true;
    },
    email() {
      if(!this.emailTouched) this.emailTouched = true;
    }
  },
  mounted() {
    this.m('sign-up', {status: 'start'})
  },
}
</script>

<style lang="less">
#TherapySignUp {
  .termsAcceptance {
    display: grid;
    grid-template-columns: fit-content(100%) 1fr;
    span {
      font-size: 1.3rem;
    }
  }
  .accountMayExistBox {
    background-color: #FFD6D6;
    border: 2px solid #9D0000;
    padding-bottom: 10px;
  }
  .validationBox {
    font-size: 1.1rem;
    font-family: FilsonProBold, Arial, Helvetica, sans-serif;
    .passed {
      color: #0BDA51;
    }
    .failed {
      color: darkred;
    }
  }
}
</style>