<template>
    <div class="view-bookings">
        <div class="content-container row" v-if="loading">
            <div class="col-12 d-flex justify-content-center">
                <b-spinner></b-spinner>
            </div>
        </div>
        <div class="content-container row px-0" v-else-if="sortedBookings.length < 1">
            <div class="col-12 col-md-6">
                <h3 class="brand-secondary">You have no upcoming bookings</h3>
            </div>
            <div class="d-none d-md-block col-md-6">
                <img src="/img/placeholder-bookings-generic.svg" />
            </div>
        </div>
        <div class="content-container row" v-else-if="bookings">
            <div class="col-md-5 bookings-wrap">
                <div v-for="(booking, i) in sortedBookings" :key="i" class="booking pl-3 py-4 my-3 brand-text"
                    @click="selectBooking(booking)">
                    <a>
                        {{ formatDate(booking.booking.start) }} -
                        Session with {{ booking.service_provider.name }}</a>
                </div>
            </div>
            <div class="col-md-7">
                <div id="booking-anchor" class="d-block d-md-none"></div>
                <div class="booking-card p-5 mx-md-5 mt-3 mb-5" v-if="selectedBooking">
                    <h3 class="pt-3 pb-5">{{ selectedBooking.booking.title }}</h3>
                    <div class="booking-description" v-html="selectedBooking.service_provider.description"></div>
                    <div class="booking-info py-5">
                        <p class="mb-0">
                            Appointment with: {{ selectedBooking.service_provider.name }}
                        </p>
                        <p class="mb-0">
                            Date: {{ formatDate(selectedBooking.booking.start) }}
                        </p>
                        <p>Time: {{ parseTime(selectedBooking.booking.start) }}</p>
                        <p>
                            If you have any issues or questions, please use our live chat,
                            call <a href="tel:015180273">01 518 0273</a> or email
                            <a href="mailto:digitalclinics@layawellness.ie">digitalclinics@layawellness.ie</a>
                            for support.
                        </p>
                    </div>
                    <div class="py-5 row">
                        <b-button class="px-5 mx-auto" variant="cta"
                            @click="openModal(selectedBooking.booking, selectedBooking.service_provider)">
                            Join Booking</b-button>
                    </div>
                    <div class="button-wrap py-5">
                        <b-button variant="cta-outline" class="px-5 py-3" @click="cancelTimeslotBooking(selectedBooking.b_id)"
                            :disabled="!allowCancel(selectedBooking.ts_start_date_time)">Cancel Booking</b-button>
                    </div>
                    <p v-if="!allowCancel(selectedBooking.ts_start_date_time)" class="cancel-text">
                        To change your appointment, please call
                        <a href="tel:015180273">01 518 0273</a>.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import config from "@/config/constants";
import moment from "moment";
import MeetingRoomModal from "./MeetingRoomModal.vue";
import EarlyModal from "./EarlyModal.vue";

export default {
    name: "ViewBookings",
    props: ["selectedBookingID"],
    data() {
        return {
            loading: true,
            bookings: null,
            selectedBooking: null,
            selectedBookingIDCopy: null,
        };
    },
    mounted() {
        this.getBookings();
    },
    methods: {
        openModal(booking, sp) {
            console.log(booking, sp);
            if (moment(booking.start).diff(moment(), 'days') >= 7) {
                this.openEarlyModal(booking, sp);
            } else {
                this.openBookingModal(booking, sp);
            }
        },
        openBookingModal(booking, sp) {
            const content = this.$createElement(MeetingRoomModal, { props: { booking: booking, serviceProvider: sp } })
            const skin = this.$store.getters.skin;
            let opts = {
                title: "My Coaching Session",
                hideHeader: true,
                hideFooter: true,
                modalClass: "coach_modal theme-" + skin,
                headerClass: "coach_modal_header bg-brand-secondary d-flex justify-content-center",
                size: "lg",
                buttonSize: "sm",
                centered: true,
                contentClass: "coach_modal",
                id: 'digital_clinics_modal'
            }
            return this.$bvModal.msgBoxOk(content, opts);
        },
        openEarlyModal(booking, sp) {
            const content = this.$createElement(EarlyModal, { props: { booking: booking, serviceProvider: sp } })
            const skin = this.$store.getters.skin;
            let opts = {
                title: "My Session",
                hideHeader: false,
                hideFooter: true,
                modalClass: "coach_modal theme-" + skin,
                headerClass: "coach_modal_header bg-brand-secondary d-flex justify-content-center",
                size: "md",
                buttonSize: "sm",
                centered: true,
                contentClass: "coach_modal",
                id: 'digital_clinics_early_modal'
            }
            return this.$bvModal.msgBoxOk(content, opts);
        },
        async getBookings() {
            const payload = {
                user_id: this.$store.getters.user_id,
            };
            let res = await this.axios.post(
                config.api_env + "/application/api/u/digital-bookings/get-bookings",
                payload,
                config.options
            );
            if (res.data.success) {
                this.bookings = res.data.upcoming;
                this.selectedBooking = this.sortedBookings[0];
                this.loading = false;
            } else if (res.data.err && res.data.err.includes("3994")) {
                if (
                    await this.showMsgModal(
                        "You have been logged out due to inactivity, please login and try again"
                    )
                ) {
                    this.$store.dispatch("logout");
                    this.$router.push("/login");
                }
            } else {
                this.showMsgModal("Something went wrong, please try again later");
            }
        },
        formatDate(date) {
            return moment(date).format("DD/MM/YY");
        },
        parseTime(time) {
            return moment(time).format("hh:mm A");
        },
        selectBooking(booking) {
            this.selectedBooking = booking;
        },
        async cancelTimeslotBooking(timeslot) {
            const payload = {
                user_id: this.$store.getters.user_id,
                booking_id: timeslot,
            };
            let res = await this.axios.post(
                config.api_env + "/application/api/g/global-events/cancel-booking",
                payload,
                config.options
            );
            if (res.data.success) {
                await this.showMsgModal("Appointment cancelled successfully");
                this.loading = true;
                this.getBookings();
            } else if (res.data.err && res.data.err.includes("3994")) {
                this.$store.dispatch("logout");
                this.$router.push("/login");
            }
        },
        sortBookingsByDate(a, b) {
            let comparison = 0;
            if (a.ts_start_date_time > b.ts_start_date_time) {
                comparison = 1;
            } else if (a.ts_start_date_time < b.ts_start_date_time) {
                comparison = -1;
            }
            return comparison;
        },
        allowCancel(bookingTime) {
            const today = moment();
            const tomorrow = moment().add(1, "day");
            const currentTime = today.format("HH");
            const bookingDay = moment(bookingTime);
            //can't cancel same day bookings, or next day bookings if the current time is after 1pm
            return today.isSame(bookingDay, "day") ||
                (currentTime >= 13 && tomorrow.isSame(bookingDay, "day"))
                ? false
                : true;
        },
    },
    computed: {
        sortedBookings() {
            return this.bookings
                .sort(this.sortBookingsByDate);
        },
        cancelledBookings() {
            return this.bookings
                .filter(
                    (x) => x.ts_time_status === "upcoming" && x.b_status === "cancelled"
                )
                .sort(this.sortBookingsByDate);
        },
        pastBookings() {
            return this.bookings
                .filter((x) => x.ts_time_status === "past")
                .sort(this.sortBookingsByDate);
        },
    },
};
</script>

<style scoped lang="less">
.bookings-wrap {
    width: 100%;

    .booking {
        width: 100%;
        border-left-style: solid;
        border-left-width: 10px;
        font-size: 2rem;
        cursor: pointer;

        &:hover {
            box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
        }

        a {
            width: 100%;
            height: 100%;

            &:hover {
                text-decoration: none;
            }

            &:focus {
                outline: none;
            }
        }
    }
}

.booking-card {
    h3 {
        text-align: center;
    }

    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    font-size: 1.4rem;

    p {
        font-size: 1.4rem;
    }

    .button-wrap {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .cancel-text {
        text-align: center;
    }
}
</style>