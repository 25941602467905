<template>
  <div class="serviceCard" :class="{cmhAssessment: slug === 'cmh_assessment'}">
    <img src="/img/therapy/neurocard.png">
    <div class="textHolder">
      <div class="bottomStrip" v-if="slug === 'cmh_assessment'"></div>
      <div class="textHolderInner">
        <div class="title py-1">
          {{this.name}}<br>
          <span>With a {{sp_text}}</span>
        </div>
        <button v-if="showLearnMore" class="therapyBtn2 learnMoreBtn" @click="learnMore">Learn More</button>
        <div  class="priceFlag">
          <template>
            Price: <span> {{ this.service_price }}</span>
          </template>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "serviceCard",
  props: {
    name: {}, price: {}, currency: {}, slug: {}, showLearnMore: {default: true}
  },
  methods: {
    learnMore() {
      this.m('service-learn-more-button-clicked',{service: this.name});
      this.$router.push('service-dashboard/'+this.slug);
    }
  },
  computed: {
    sp_text() {
      return this.slug.includes('psychology') ? "senior clinical psychologist" : "senior clinician";
    },
    service_price() {
      return this.getCurrencyPriceString(this.currency,this.price)
    }
  }
}
</script>

<style lang="less">
.serviceCard {
  width: 100%;
  max-width: 450px;
  aspect-ratio: 564 / 260;
  border-radius: 20px 0 0 20px;
  overflow: hidden;
  margin: auto;
  position: relative;
  box-shadow: 0 4px 4px 0 #00000040;
  &.cmhAssessment {
    .textHolderInner {
      justify-content: space-between;
    }
  }
  .bottomStrip {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 35%;
    background: linear-gradient(270deg, #F3F3F3 39.74%, rgba(243, 243, 243, 0) 101.99%);
    z-index: -1;
  }
  .cmhAssessmentText {
    font-size: 1.25rem;
    span {
      font-family: FilsonProBold, Arial, Helvetica, sans-serif
    }
  }
  .assessment_text {
    font-family: FilsonProBold, Arial, Helvetica, sans-serif;
    font-size: 1.2rem;
  }
  .textHolder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 2;
    clip-path: polygon(0 0, 75% 0, 55% 100%, 0 100%);
    color: #3A4A61;
    .textHolderInner {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      width: 100%;
      padding: 15px 20px;
      position: relative;
      border: 1px solid white;
    }
    .title {
      font-size: 1.8rem;
      line-height: 2.75rem;
      //border-top: 1px solid #F1F1F1;
      //border-bottom: 1px solid #F1F1F1;
      width: 65%;
      &:not(span) {
        color: #3A4A61;
      }
      span {
        font-size: 1.5rem;
        font-family: "DMSans", sans-serif;
      }
    }
    .learnMoreBtn {
      height: 30px !important;
      max-width: 200px !important;
      box-shadow: none !important;
      font-size: 1.3rem !important;
    }
    .priceFlag {
      width: 165px;
      height: 35px;
      background: linear-gradient(270deg, #F3F3F3 39.74%, rgba(243, 243, 243, 0) 101.99%);
      clip-path: polygon(0% 0%, 0% 99%, 99% 99%, 82% 49%, 99% 0%);
      display: flex;
      align-items: center;
      justify-content: start;
      padding-left: 20px;
      font-size: 1.3rem;
      span {
        margin-left: 5px;
        font-family: FilsonProBold, Arial, Helvetica, sans-serif;
      }
    }

  }
  img {
    position: absolute;
    top: 1px;
    height: calc(100% - 1px);
    right: 0;
    z-index: 1;
  }
}

</style>