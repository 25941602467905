import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import hardcodedBranding from "../hardcodedBranding";
import createPersistedState from "vuex-persistedstate";
import config from "../config/constants";
import router from "../router"
import moment from "moment";
import EventBus from "../helpers/eventbus";
Vue.use(Vuex);

const defaultUser = {
  authenticated: false,
  success: false,
  up_domain: "up",
  insights_completed: false,
  user_client_id: Number,
  user_id: String,
  user_role: "cl",
  user_token: String,
  username: String,
  user_pp_slug: "generic_pp",
  user_tc_slug: "generic_tc",
  v3_ready: true,
  home_page: 'old_default',
  access_code_user: false,
  therapy: {
    area: null,
    age_group: null,
    membership_id: null
  },
  user: {
    first_name: String,
    last_name: String,
    email: String,
    employer_admin: Boolean,
    course_progress: [],
    my_list: [],
    country: "Ireland"
  },
  user_profile: {
    image_url: String,
    bio: String,
    dob: String,
    gender: String,
    phone: String,
    education_level: String,
    place_of_birth: String,
    relationship_status: String,
    dependants: Number,
    employment_status: String,
    job_type: String,
    occupation: String,
    address: String,
    city: String,
    county: String,
    postal_code: String,
    disability: Boolean,
    disability_specify: String,
    next_of_the_kin_full_name: String,
    next_of_the_kin_phone: String,
    gp_name: String,
    gp_phone: String,

  },
  user_settings: {
    email_notifications: Boolean,
    push_notifications: Boolean,
    inactive_pushes_sent: Number,

  }
};
const defaultClient = {
  client_logo: "/img/spectrum_life_logo._light.png",
  client_name: "Default Client",
  currency: "eur",
  eap_active: false,
  logo:
    "/img/spectrum_life_logo._light.png",
  skin: 0,
  success: true,
  two_factor_enabled: false,
  number_of_signups: 0,
  google_sso_enabled: 0,
  ms_sso_enabled: 0
};
export default new Vuex.Store({
  state: {
    user: defaultUser,
    client: defaultClient,
    warning: null,
    cutOffCounter: 10,
    event: {},
    isIE: true,
    isSaml: false,
    home_page: "old_default",
    nav: [],
    sideNav: [],
    navElements: [],
    homeRoute: "/",
    subdomain: "app",
    accessCode: 0,
    lang: 'en',
    pageTitle: '',
    previousRoute: null,
    podcastQueue: [],
    podcastQueuePosition: 0,
    darkMode: false,
    navType: "standard",
    bookingReminderLastSeen: "",
    show_theme_select_in_nav: false,
    companyBoard: false,
    default_nav_elements: {
      choose_language: "Choose Language",
      favourites: "Favourites",
      home: "Home",
      info: "Info",
      logout: "Logout",
      my_progress: "My Progress",
      my_list: "My List",
      profile: "Profile",
      what_are_you_looking_for: "What are you looking for...",
      dark_mode: "Dark Mode"
    },
    admin_nav_elements: {
        data_analytics: "Data Analytics",
        branding_customise: "Branding Customise",
        custom_content: "Custom Content",
        user_management: "User Management",
        exit_admin_view: "Exit Admin View",
        enter_admin_view: "Enter Admin View"
    },
    distanceUnit: 'Kilometers',
    temporary_token: null
  },
  getters: {
    //TODO:: use mapState appropriately instead of getters
    user: state => state.user,
    user_id: state => state.user.user_id,
    country: state => state.user.user.country,
    client: state => state.client,
    coach: state => state.user.coach,
    warning: state => state.warning,
    skin: state => state.client.skin,
    event: state => state.event,
    currency: state => state.client.currency,
    isIE: state => state.isIE,
    isSaml: state => state.isSaml,
    nav: state => state.nav,
    sideNav: state => state.sideNav,
    navElements: state => state.navElements,
    homeRoute: state => state.homeRoute,
    subdomain: state => state.subdomain,
    hardcoded: state => {
      if (hardcodedBranding[state.subdomain]) {
        return hardcodedBranding[state.subdomain];
      } else return false;
    },
    podcastQueue: state => state.podcastQueue,
    podcastQueuePosition: state => state.podcastQueuePosition,
    darkMode: state => state.darkMode,
    navType: state => state.navType,
    bookingReminderLastSeen: state => state.bookingReminderLastSeen,
    lang: state => state.lang,
    default_nav_elements: state => state.default_nav_elements,
    courseProgress: state => state.user.user.course_progress,
    myList: state => state.user.user.my_list,
    admin_nav_elements: state => state.admin_nav_elements,
    companyBoard: state => state.companyBoard,
    distanceUnit: state => state.distanceUnit,
    temporaryToken: state => state.temporary_token,
    showThemeSelectInNav: state => state.show_theme_select_in_nav,
  },
  mutations: {
    setUser(state, user) {
      state.user.user_token = user.user_token;
      state.user.user_role = user.user_role;
      state.user.username = user.username;
      state.user.v3_ready = user.v3_ready;
      state.user.user_id = user.user_id;
      state.user.user_client_id = user.user_client_id;
      state.user.up_domain = user.up_domain;
      state.user.authenticated = user.authenticated;
      state.user.user = user.user;
      state.user.user_pp_slug = user.pp_slug;
      state.user.user_tc_slug = user.tc_slug;
      state.user.coach = user.coach;
      state.user.home_page = user.home_page;
      state.user.access_code_user = user.access_code_user;
      state.companyBoard = user.company_board;
      state.course_progress = user.course_progress;
      state.user.my_list = user.my_list;
    },
    setTherapyType(state, therapy) {
        state.user.therapy.area = therapy.area;
        state.user.therapy.age_group = therapy.age_group;
        state.user.therapy.membership_id = therapy.membership_id;
    },
    clearTherapyDetails(state, therapy) {
      state.user.therapy.area = null;
      state.user.therapy.age_group = null;
      state.user.therapy.membership_id = null;
    },
    setUserProfile(state, user_profile) {
      state.user.user_profile.image_url = user_profile.image_url
      state.user.user_profile.bio = user_profile.bio
      state.user.user_profile.gender = user_profile.gender
      state.user.user_profile.phone = user_profile.phone
      state.user.user_profile.dob = user_profile.dob
      state.user.user_profile.education_level = user_profile.education_level
      state.user.user_profile.place_of_birth = user_profile.place_of_birth
      state.user.user_profile.relationship_status = user_profile.relationship_status
      state.user.user_profile.dependants = user_profile.dependants
      state.user.user_profile.employment_status = user_profile.employment_status
      state.user.user_profile.job_type = user_profile.job_type
      state.user.user_profile.occupation = user_profile.occupation
      state.user.user_profile.relationship_status = user_profile.relationship_status
      state.user.user_profile.address = user_profile.address
      state.user.user_profile.city = user_profile.city
      state.user.user_profile.county = user_profile.county
      state.user.user_profile.postal_code = user_profile.postal_code
      state.user.user_profile.disability = user_profile.disability
      state.user.user_profile.disability_specify = user_profile.disability_specify
      state.user.user_profile.next_of_the_kin_full_name = user_profile.next_of_the_kin_full_name
      state.user.user_profile.next_of_the_kin_phone = user_profile.next_of_the_kin_phone
      state.user.user_profile.gp_name = user_profile.gp_name
      state.user.user_profile.gp_phone = user_profile.gp_phone
    },
    setUserSettings(state, user_settings) {
      state.user.user_settings.email_notifications = user_settings.email_notifications
      state.user.user_settings.push_notifications = user_settings.push_notifications
      state.user.user_settings.inactive_pushes_sent = user_settings.inactive_pushes_sent
    },
    setUserInsightsStatus(state, insights_completed) {
      state.user.insights_completed = insights_completed;
    },
    setClientBranding(state, client) {
      state.client.client_name = client.client_name;
      state.client.client_logo = client.client_logo;
      state.client.eap_active = client.eap_active;
      state.client.currency = client.currency;
      state.client.skin = client.skin;
      state.client.logo = client.logo;
      state.accessCode = client.access_code;
      state.client.pp_slug = client.pp_slug;
      state.client.tc_slug = client.tc_slug;
      state.client.two_factor_enabled = client.two_factor_enabled;
      state.client.google_sso_enabled = client.google_sso_enabled;
      state.client.ms_sso_enabled = client.ms_sso_enabled;
      if(!state.user.authenticated) {
          state.user.home_page = client.home_page;
      }
      if(client.hasOwnProperty('number_of_signups')) {
        state.client.number_of_signups = client.number_of_signups;
      } else {
        state.client.number_of_signups = 0;
      }
    },
    setSkin(state, skin) {
      state.client.skin = skin;
    },
    setTwoFactorEnabled(state, twoFactorEnabled) {
      state.client.two_factor_enabled = twoFactorEnabled;
    },
    setBrowserIE(state, isIE) {
      state.isIE = isIE;
    },
    setSamlTrue(state){
      state.isSaml = true;
    },
    setWarning(state, warning) {
      state.warning = warning;
    },
    acceptWarning(state) {
      state.warning = null;
    },
    logout(state) {
      state.user = defaultUser;
      state.isSaml = false;
      //state.client = defaultClient;
    },
    viewEvent(state, event) {
      state.event = event;
    },
    setNav(state, nav) {
      state.nav = nav;
    },
    setSideNav(state, sideNav) {
        state.sideNav = sideNav;
    },
    setNavElements(state, navElements) {
        state.navElements = navElements;
    },
    setHomeRoute(state, home) {
      state.homeRoute = home;
    },
    setDomain(state, domain) {
      state.subdomain = domain;
    },
    setAccessCode(state, hasCode) {
      state.accessCode = hasCode;
    },
    setLanguage(state, language){
      const availableLanguages = ['en','de','fr','it','es','pt','zh','ru']
      if(availableLanguages.includes(language)) {
        state.lang = language
      }
    },
    setPageTitle(state, title){
      state.pageTitle = title
    },
    setPreviousRoute(state, route){
      state.previousRoute = route
    },
    setPodcastQueue(state, podcastQueue) {
      state.podcastQueue = podcastQueue;
    },
    setPodcastQueuePosition(state, podcastQueuePosition) {
      state.podcastQueuePosition = podcastQueuePosition;
    },
    setUserTermsAgreementTrue(state) {
      state.user.user.tc_accepted = true;
    },
    setUserPPAgreementTrue(state) {
      state.user.user.pp_accepted = true;
    },
    setCoach(state, coach){
      state.user.coach = coach;
    },
    setUserCookiesAgreementTrue(state) {
      state.user.user.cookies_accepted = true;
    },
    setDarkMode(state, darkMode) {
        state.darkMode = darkMode
    },
    setNavType(state, navType) {
       const layoutsThatUseVersionTwoNav = ["default"];
       let homePage = navType;
       if(state.user.authenticated) {
           homePage = state.user.home_page;
       }
       if(layoutsThatUseVersionTwoNav.includes(homePage)) {
           state.navType = "versionTwo";
       } else {
           state.navType = "standard";
       }
    },
    setBookingReminderLastSeen(state) {
        state.bookingReminderLastSeen = moment().format("YYYY-MM-DD");
    },
    setInitialCourseProgress(state) {
      if(state.user.user.hasOwnProperty('course_progress')) return;
      state.user.user.course_progress = [];
    },
    setCourseProgress(state,course) {
        let exists = false;
        if(!course.id || course.percent === 0) return;
        if(course.percent === 1) {
            state.user.user.course_progress = state.user.user.course_progress.filter(item => {
                return item.channel !== course.channel && Number.parseInt(item.id) !== Number.parseInt(course.id);
            });
        } else {
            if(!state.user.user.course_progress.length) {
                state.user.user.course_progress = [course];
            }
            state.user.user.course_progress.map((item,i) => {
                if(item.channel === course.channel && Number.parseInt(item.id) === Number.parseInt(course.id)) {
                    exists = true;
                    state.user.user.course_progress[i] = course;
                }
            });
            if(!exists) state.user.user.course_progress.push(course);
        }
    },
    clearCourseProgress(state) {
        state.user.user.course_progress = [];
    },
    setMyList(state,list) {
        state.user.user.my_list = list;
    },
    toggleDistanceUnit(state) {
        if(state.distanceUnit === 'Kilometers') {
            state.distanceUnit = 'Miles';
        } else {
            state.distanceUnit = 'Kilometers';
        }
    },
    setTemporaryToken(state, token) {
        state.temporary_token = token;
    },
    clearTemporaryToken(state) {
        state.temporary_token = null;
    },
    toggleShowThemeSelectInNav(state) {
        state.show_theme_select_in_nav = !state.show_theme_select_in_nav;
    }
  },
  actions: {
    setTherapyUser(context, payload) {
      context.commit('setUser',{
          user: payload.user,
          user_id: payload.user.user_token
      });
      context.commit('setTherapyType',{
          age_group: payload.therapy.age_group,
          area: payload.therapy.area,
          membership_id: payload.therapy.membership_id
      })
    },
    setTwoFactorEnabled(context,payload) {
      context.commit('setTwoFactorEnabled', payload)
    },
    setUserProfile(context, payload) {
      context.commit('setUserProfile', payload)
    },
    setTemporaryToken(context,payload) {
      context.commit('setTemporaryToken',payload)
    },
    setUserSettings(context, payload) {
      context.commit('setUserSettings', payload)
    },
    async twoFactorSignIn(context, payload) {
      try {
        let res = await axios.post(
          config.api_env + "/application/api-hc/two-factor-sign-in",
          payload.payload,
          config.options
        );
        if (res.data.success === true) {
          context.commit("setUser", res.data);
          context.commit("setLanguage", res.data.lang);
          //check if user is on v3 portal - if yes continue - if find their organisation
          if (res.data.v3_ready) {
            //if the subdomain is laya wellbeing don't get the clients branding
            if(context.state.subdomain !== 'laya-wellbeing') {
              context.dispatch("getClientBranding", res.data.user_token);
              context.dispatch("getClientInsights", res.data.user_token);
            }
            const homeroute = await context.dispatch(
              "getNav",
              res.data.user_token
            );
            context.state.previousRoute ? payload.router.push(context.state.previousRoute.path) : homeroute ? payload.router.push(homeroute) : payload.router.push("/")
            return true;
          } else return false;
        } else {
          await context.commit("setWarning", res.data.err);
        }
      } catch (e) {
        //console.log(e);
      }
    },
    setWarning(context,payload) {
      context.commit('setWarning',payload)
    },

    async googleAuthenticate(context, payload) {
      let res = await axios.post(
        config.api_env + "/application/api/u/oauth/google/authenticate",
        payload.payload,
        config.options
      );
      if (res.data.success) {
        if(res.data.hasOwnProperty('data')) {
            if(res.data.data.hasOwnProperty('oAuthAccessToken')) {
                context.commit("setTemporaryToken",res.data.data.oAuthAccessToken);
                return;
            }
        }
        context.commit("setUser", res.data);
        context.commit('setInitialCourseProgress');
        context.commit("setLanguage", res.data.lang);
        context.commit("setNavType",res.data.home_page);

        if (res.data.v3_ready) {
          if (context.state.subdomain !== 'laya-wellbeing') {
            context.dispatch("getClientBranding", res.data.user_token);
            context.dispatch("getClientInsights", res.data.user_token);
          }

          const homeroute = await context.dispatch("getNav", res.data.user_token);
          context.state.previousRoute ?
            payload.router.push(context.state.previousRoute.path) :
            homeroute ?
              payload.router.push(homeroute) :
              payload.router.push("/")

          return true;
        }

        return false;
      } else {
        await context.commit("setWarning", res.data.err);
      }
    },
    async oauthSignIn(context,payload) {
      try {
          context.commit("setUser", payload);
          context.commit('setInitialCourseProgress');
          context.commit("setLanguage", payload.lang);
          context.commit("setNavType",payload.home_page);
          //check if user is on v3 portal - if yes continue - if find their organisation
          if (payload.v3_ready) {
            //if the subdomain is laya wellbeing don't get the clients branding
            if(context.state.subdomain !== 'laya-wellbeing') {
              context.dispatch("getClientBranding", payload.user_token);
              context.dispatch("getClientInsights", payload.user_token);
            }
            const homeroute = await context.dispatch(
              "getNav",
              payload.user_token
            );
            return true;
          }
      }  catch (e) {

      }
    },
    async signInV2(context, payload) {
          try {
            const timeoutPromise = new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve('timed-out')
                }, 30000);
            });
            let api = axios.post(
              config.api_env + "/application/api-hc/authenticate",
              payload.payload,
              config.options
            );
            const res = await Promise.race([api,timeoutPromise]);
            if(res === 'timed-out') {
                EventBus.$emit('login-time-out')
                return false;
            }
            if(res.data.hasOwnProperty('two_factor')) {
                if(res.data.two_factor) {
                    payload.router.push('/two-factor?oauth='+res.data.token)
                }
            }
            if (res.data.success === true) {
              context.commit("setUser", res.data);
              context.commit('setInitialCourseProgress');
              context.commit("setLanguage", res.data.lang);
              context.commit("setNavType",res.data.home_page);
              //check if user is on v3 portal - if yes continue - if find their organisation
              if (res.data.v3_ready) {
                //if the subdomain is laya wellbeing don't get the clients branding
                if(context.state.subdomain !== 'laya-wellbeing') {
                  context.dispatch("getClientBranding", res.data.user_token);
                  context.dispatch("getClientInsights", res.data.user_token);
                }
                const homeroute = await context.dispatch(
                  "getNav",
                  res.data.user_token
                );
                context.state.previousRoute ? payload.router.push(context.state.previousRoute.path) : homeroute ? payload.router.push(homeroute) : payload.router.push("/")
                return true;
              } else return false;
            } else {
              console.log(res.data);
              EventBus.$emit('login-unsuccessful');
              await context.commit("setWarning", res.data.err);
            }
          } catch (e) {
            //console.log(e);
          }
        },
    async signIn(context, payload) {
      try {
        const timeoutPromise = new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve({ timedOut: true });
          }, 15000);
        });

        const apiPromise = axios.post(
          config.api_env + "/application/api-hc/authenticate",
          payload.payload,
          config.options
        );

        const res = await Promise.race([apiPromise, timeoutPromise]);

        // Handle the case where the timeout occurred
        if (res.timedOut) {
          EventBus.$emit('login-time-out');
          return false;
        }

        if (res.data.hasOwnProperty('two_factor')) {
          if (res.data.two_factor) {
            payload.router.push('/two-factor?oauth=' + res.data.token);
          }
        }

        if (res.data.success === true) {
          context.commit("setUser", res.data);
          context.commit('setInitialCourseProgress');
          context.commit("setLanguage", res.data.lang);
          context.commit("setNavType", res.data.home_page);

          // Check if user is on v3 portal - if yes continue - if find their organisation
          if (res.data.v3_ready) {
            if (context.state.subdomain !== 'laya-wellbeing') {
              context.dispatch("getClientBranding", res.data.user_token);
              context.dispatch("getClientInsights", res.data.user_token);
            }
            const homeroute = await context.dispatch("getNav", res.data.user_token);
            context.state.previousRoute
              ? payload.router.push(context.state.previousRoute.path)
              : homeroute
                ? payload.router.push(homeroute)
                : payload.router.push("/");
            return true;
          } else {
            EventBus.$emit('login-unsuccessful');
            return false;
          }
        } else {
          EventBus.$emit('login-unsuccessful');
          await context.commit("setWarning", res.data.err);
          return false;
        }
      } catch (e) {
        if(e.response.status === 408) {
            EventBus.$emit('login-time-out');
        } else {
            EventBus.$emit('login-unsuccessful');
        }
        return false;
      }
    },
    async accessCodeLogin(context, payload) {
      //try to get the nav for a user with the access code, if it returns then it's a valid code.
      const homeroute = await context.dispatch("getNav", payload.accessCode);
      if (homeroute) {
        const user = {
          authenticated: true,
          success: true,
          up_domain: "up",
          insights_completed: true,
          user_client_id: Number,
          user_id: payload.accessCode,
          user_role: "cl",
          user_token: payload.accessCode,
          username: payload.accessCode,
          v3_ready: true
        }
        context.commit("setUser", user);
        context.commit('setInitialCourseProgress');
        context.dispatch("getClientBranding", payload.accessCode);
        payload.router.push(homeroute);
      }else {
        await context.commit("setWarning", 'Sorry that access code is not recognised');
      }
    },
    async newCodeLogin(context, payload){
      console.log(payload);
      try {
        let res = await axios.post(
          config.api_env + "/application/api/u/user/access-code-signup",
          payload.payload,
          config.options
        );
        if (res.data.success === true) {
          context.commit("setUser", res.data);
          context.commit('setInitialCourseProgress');
          context.commit("setLanguage", res.data.lang);
          //check if user is on v3 portal - if yes continue - if find their organisation
          if (res.data.v3_ready) {
            //if the subdomain is laya wellbeing don't get the clients branding
            if(context.state.subdomain !== 'laya-wellbeing') {
              context.dispatch("getClientBranding", res.data.user_token);
              context.dispatch("getClientInsights", res.data.user_token);
            }
            const homeroute = await context.dispatch(
              "getNav",
              res.data.user_token
            );
            context.state.previousRoute ? payload.router.push(context.state.previousRoute.path) : homeroute ? payload.router.push(homeroute) : payload.router.push("/")
            return true;
          } else return false;
        } else {
          await context.commit("setWarning", res.data.msg);
        }
      } catch (e) {
        //console.log(e);
      }
    },
    async getNav(context, token) {
      const payload = {
        user_id: token,
        platform: 'web',
        language: context.getters.lang
      };
      let res = await axios.post(
        config.api_env + "/application/api/u/settings/get-nav",
        payload,
        config.options
      );
      if (res.data.success) {
        if(res.data.side_nav) {
            context.commit("setSideNav", res.data.side_nav)
        }
        if(res.data.nav_elements) {
            context.commit("setNavElements", res.data.nav_elements)
        }
        if(res.data.nav){
          context.commit("setNav", res.data.nav);
          return "/";
        }
      }else return 0;
    },
    async loginAs(context, user) {
      context.commit("setUser", user.user_info);
      await context.dispatch("getClientBranding", user.user_token);
      await context.dispatch("getClientInsights", user.user_token);
      const nav = await context.dispatch("getNav", user.user_token);
      context.commit('setInitialCourseProgress');
      if(nav){
        return nav
      } else {
        context.commit("setWarning", 'User not valid')
        return "/login"
      }
      //return "/";
    },
    async getClientBranding(context, token) {
      let payload = {
        user_id: token
      };
      try {
        let clientOptions = await axios.post(
          config.api_env +
            "/application/api/customisations/get-client-branding",
          payload,
          config.options
        );
        if (clientOptions.data.success) {
          let client = clientOptions.data
          if(context.getters.hardcoded && context.getters.hardcoded.skin){
            client.skin = context.getters.hardcoded.skin
          }
          context.commit("setClientBranding", client);
          context.commit("setNavType",clientOptions.data.home_page);
        }
      } catch (e) {
        //console.log(e);
      }
    },
    async getClientInsights(context, token) {
      let payload = {
        user_id: token
      };
      try {
        let clientInsights = await axios.post(
          config.api_env + "/application/api-hc/completed-insights",
          payload,
          config.options
        );
        context.commit("setUserInsightsStatus", clientInsights.data.completed);
      } catch (e) {
        //console.log(e);
      }
    },
    acceptWarning(context) {
      context.commit("acceptWarning");
    },
    async logout(context, previousRoute = null) {
      let payload = {
        "user_id":   context.state.user.user_token
      };
      let res = await axios.post(
        config.api_env + "/application/api/u/user/logout",
        payload,
        config.options
      );
      if(context.getters.skin === 13){
        window.Intercom('shutdown')
      }
      if(previousRoute){
        context.commit("setPreviousRoute", previousRoute)
      }
      context.commit('clearTherapyDetails');
      context.commit("setUser", defaultUser);
    },
    viewEvent(context, event) {
      context.commit("viewEvent", event);
    },
    setPodcastQueue(context, podcastQueue) {
      context.commit("setPodcastQueue", podcastQueue.queue);
      context.commit("setPodcastQueuePosition", podcastQueue.position);
    },
    setUserTermsAgreementTrue(context){
      context.commit("setUserTermsAgreementTrue");
    },
    setUserPPAgreementTrue(context){
      context.commit("setUserPPAgreementTrue");
    },
    setCoach(context, coach){
      context.commit("setCoach", coach);
    },
    setUserCookiesAgreementTrue(context){
      context.commit("setUserCookiesAgreementTrue");
    },
    setDarkMode(context, value) {
      context.commit("setDarkMode", value);
    },
    setNavType(context,value) {
       context.commit("setNavType", value);
    },
    setBookingReminderLastSeen(context) {
        context.commit("setBookingReminderLastSeen")
    },
    setSamlTrue(context){
      context.commit("setSamlTrue");
    },
    toggleDistanceUnit(context) {
      context.commit("toggleDistanceUnit")
    },
    async selectLanguage(context, language){
      context.commit('setLanguage', language)
      if(context.state.user.authenticated) {
        const payload = {
          user_id: context.getters.user_id,
          lang: language
        }
        await axios.post(config.api_env + '/application/api/u/settings/set-user-language', payload, config.options)
        let nav = await context.dispatch('getNav', context.getters.user_id)
        //location.reload();
      }
    },
    clearNav(context){
      context.commit('setNav', [])
    },
    clearTemporaryToken(context) {
      context.commit('clearTemporaryToken');
    },
    setInitialCourseProgress(context) {
      context.commit('setInitialCourseProgress')
    },
    setCourseProgress(context, course) {
        context.commit('setCourseProgress', course);
    },
    clearCourseProgress(context) {
        context.commit('clearCourseProgress');
    },
    async setMyList(context) {
        let payload = {
            "user_id": context.state.user.user_token
        }
        try {
            let res = await axios.post(
                config.api_env + "/application/api/u/my-list",
                payload,
                config.options);
            context.commit('setMyList',res.data.data);
            return res.data.success;
        } catch (e) {
            return false;
        }
    },
    async addMyListItem(context,data) {
        let payload = {...data, "user_id": context.state.user.user_token}
        try {
            let res = await axios.post(
                            config.api_env + "/application/api/u/my-list/create",
                            payload,
                            config.options);
            if(res.data.success) context.commit('setMyList',res.data.data);
            return res.data.success;
        } catch (e) {
            return false;
        }
    },
    async deleteMyListItem(context,data) {
        let payload = {...data, "user_id": context.state.user.user_token}
        try {
            let res = await axios.post(
                            config.api_env + "/application/api/u/my-list/delete",
                            payload,
                            config.options);
            if(res.data.success) context.commit('setMyList',res.data.data);
            return res.data.success;
        } catch (e) {
            return false;
        }
    },
    toggleShowThemeSelectInNav(context) {
        context.commit('toggleShowThemeSelectInNav');
    }
  },
  plugins: [
    createPersistedState({
      paths: [
        "user",
        "client",
        "isIE",
        "isSaml",
        "home_page",
        "cutOffCounter",
        "nav",
        "sideNav",
        "navElements",
        "homeRoute",
        "subdomain",
        "eventsOnly",
        "accessCode",
        "darkMode",
        "navType",
        "bookingReminderLastSeen",
        "lang",
        "pageTitle",
        "companyBoard",
        "distanceUnit",
        "show_theme_select_in_nav"
      ]
    })
  ],
  modules: {}
});
