<template>
    <b-dropdown id="LanguageSelect" dropup :text="getText.global.changeLanguage" class="text-link">
        <b-dropdown-item v-for="(language, i) in allLanguages" :key="i" @click="selectLanguage(i)">{{language}}</b-dropdown-item>
    </b-dropdown>
</template>

<script>
    import axios from "axios";
    import config from "@/config/constants"
    export default {
        name: "LanguageSelect",
        methods: {
           async selectLanguage(language){
                this.$store.dispatch('selectLanguage', language)
            }
        },
    }
</script>

<style lang="less" scoped>
    #LanguageSelect{
        display: none;
        .dropdown-menu{
            width: 100%;
        }
    }
</style>