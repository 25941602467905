<template>
    <div id="home-rewards" class="page-bg bg-white px-md-5">
        <div class="content-container row">
            <div class="section-header">
                <div class="section-header-divider"></div>
                <h3 class="section-header-title">{{componentText.title}}</h3>
            </div>
            <div v-for="(reward, i) in rewards"
                 :key="i"
                 v-if="reward.engagement_level == activeLevel.level_id && i < displayLength"
                 class="col-12 col-md-6 col-lg-4 my-3 px-5"
            >
                <div class="reward-card row"
                     :class="{achieved: activeLevel.achieved}"
                     @click="viewReward(reward)">
                    <div class="col-4 reward-logo px-4 py-4">
                        <img :src="reward.reward_logo_url" />
                    </div>
                    <div class="col-8 py-4">
                        <h5 class="mb-4">{{reward.company_name}}</h5>
                        <p>{{reward.reward_title}}</p>
                    </div>
                    <div class="locked">
                        <font-awesome-icon icon="lock"></font-awesome-icon>
                        L{{reward.engagement_level}}
                    </div>
                </div>
            </div>
            <div class="col-12 pt-4 pl-0 pb-5">
                <a class="home-link link-highlight" :class="{'border-brand-secondary': $store.getters.skin === 8 }" @click="seeAll">{{componentText.homeRewards}}</a>
            </div>
        </div>
    </div>
</template>
<script>
    // @ is an alias to /src
    import axios from "axios";
    import config from "../../config/constants";
    export default {
        name: "HomeRewards",
        components: {},
        data() {
            return {
                screenWidth: 0,
                displayLength: 4,
                levels:[],
                achievedLevel: '',
                activeLevel:{},
                rewards:[],
            };
        },
        created() {
            window.addEventListener('resize', this.handleResize)
            this.handleResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize)
        },
        mounted() {
            this.getEngagementLevels()
        },
        methods: {
            handleResize() {
                this.screenWidth = window.innerWidth;
            },
            async getEngagementLevels(){
                const payload = {
                    user_id: this.$store.getters.user_id
                }
                let res = await axios.post(config.api_env + '/application/api-hc/get-engagement-levels', payload, config.options)
                console.log(res.data)
                if(res.data.success){
                    this.levels = res.data.levels
                    this.achievedLevel = res.data.achieved_level_id
                    this.activeLevel = this.levels[this.achievedLevel -1]
                }
            },
            async getRewards(level){
                const payload = {
                    user_id: this.$store.getters.user_id,
                    level: level,
                    limit: 3,
                }
                let res = await axios.post(config.api_env + '/application/api-hc/list-surge-rewards', payload, config.options)
                console.log(res.data)
                if(res.data.success){
                    this.rewards = res.data.rewards
                }
            },
            viewReward(reward){
                this.$router.push({name: 'rewards', params:{reward: reward}})
            },
            seeAll(){
                this.$router.push({name: 'rewards'})
            }
        },
        computed:{
            componentText() {
                return this.getText.loggedIn.home.homeRewards;
            },
        },
        watch:{
            screenWidth(newVal){
                if(newVal > 991){
                    this.displayLength = 3
                }else{
                    this.displayLength = 2
                }
            },
            activeLevel(newLevel){
                this.getRewards(newLevel.level_id)
            },
        }
    };
</script>
<style lang="less">
    .reward-card{
        cursor: pointer;
        border-radius: 20px;
        background: #f6f6f6;
        font-size: 1.6rem;
        filter: grayscale(100%);
        &.achieved{
            filter: none;
            .locked {
                display: none;
            }
        }
        &:hover{
            box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
        }
        .reward-logo{
            border-radius: 20px;
            img{
                width: 100%;
            }
        }
        h5{
            font-family: 'FilsonProBold', sans-serif;
            font-size: 1.7rem;
        }
        .locked{
            position: absolute;
            right: 10px;
            top: 5px;
        }
    }
</style>
