<template>
    <div id="HomeStats" v-if="$store.getters.subdomain !== 'pwcire' && $store.getters.subdomain !== 'talknow' && !$store.getters.user.access_code_user" class="py-4" :class="{'bg-transparent': skin === 0, 'bg-brand-secondary-dark': skin ===8, 'bg-brand-secondary': skin > 0 && skin < 7}">
        <div v-if="stats.success" class="content-container pt-5 row">
            <div class="col-6 col-md-6 col-lg-3">
                <div id="card-1" class="stats-card row steps" >
                    <div class="col-12 col-sm-6 px-0 circle-wrap">
                        <ProgressCircle :percentage="getPercentage(stats.g_step, stats.c_step)"></ProgressCircle>
                        <img :src="'/img/home/'+ skin +'/home-steps.svg'" onerror="javascript:this.src='/img/home/home-steps.svg'">
                    </div>
                    <div class="col-12 col-sm-6 card-info">
                        <p><span class="card-value">{{stats.c_step}}</span></p>
                        <p>{{componentText.steps}}</p>
                    </div>
                </div>
                <b-tooltip target="card-1" triggers="hover" placement="bottom" custom-class="d-none d-md-block stats-tooltip">
                    <h4>{{componentText.steps}}</h4>
                    <p>{{componentText.stepsMessage}}</p>
                </b-tooltip>
            </div>
            <div class="col-6 col-md-6 col-lg-3">
                <div id="card-2" class="stats-card row" >
                    <div class="col-12 col-sm-6 px-0 circle-wrap">
                        <ProgressCircle :percentage="getPercentage(stats.g_distance, stats.c_distance)"></ProgressCircle>
                        <img :src="'/img/home/'+ skin +'/home-km.svg'" onerror="javascript:this.src='/img/home/home-km.svg'">
                    </div>
                    <div class="col-12 col-sm-6 card-info">
                        <p><span class="card-value">{{stats.c_distance}}</span></p>
                        <p>{{componentText.distance}}</p>
                    </div>
                </div>
                <b-tooltip target="card-2" triggers="hover" placement="bottom" custom-class="d-none d-md-block stats-tooltip">
                    <h4>{{componentText.distance}}</h4>
                    <p>{{componentText.distanceMessage}}</p>
                </b-tooltip>
            </div>
            <div class="col-6 col-md-6 col-lg-3">
                <div id="card-3" class="stats-card row" >
                    <div class="col-12 col-sm-6 px-0 circle-wrap">
                        <ProgressCircle :percentage="getPercentage(stats.g_calorie, stats.c_calorie)"></ProgressCircle>
                        <img :src="'/img/home/'+ skin +'/home-nutrition.svg'" onerror="javascript:this.src='/img/home/home-nutrition.svg'">
                    </div>
                    <div class="col-12 col-sm-6 card-info">
                        <p><span class="card-value">{{stats.c_calorie}}</span></p>
                        <p>{{componentText.calories}}</p>
                    </div>
                </div>
                <b-tooltip target="card-3" triggers="hover" placement="bottom" custom-class="d-none d-md-block stats-tooltip">
                    <h4>{{componentText.calories}}</h4>
                    <p>{{componentText.caloriesMessage}} </p>
                </b-tooltip>
            </div>
            <div class="col-6 col-md-6 col-lg-3 rewards">
                <div id="card-4" class="stats-card row" >
                    <div class="col-12 col-sm-6 px-0 circle-wrap">
                        <ProgressCircle :percentage="getPercentage(stats.g_points, stats.c_points)"></ProgressCircle>
                        <img :src="'/img/home/'+ skin +'/home-wellness-points.svg'" onerror="javascript:this.src='/img/home/home-wellness-points.svg'">
                    </div>
                    <div class="col-12 col-sm-6 card-info">
                        <p><span class="card-value">{{stats.c_points}}</span></p>
                        <p>{{componentText.points}}</p>
                    </div>
                </div>
                <b-tooltip target="card-4" triggers="hover" placement="bottom" custom-class="d-none d-md-block stats-tooltip">
                    <h4>{{componentText.points}}</h4>
                    <p> {{componentText.pointsMessage}} </p>
                </b-tooltip>
            </div>
            <div class="info-button cta-button d-block d-md-none" @click="$emit('stats-info')">i</div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    import config from "../../config/constants";
    import moment from "moment";
    import ProgressCircle from '../ProgressCircle.vue'
    export default {
        name: "HomeStats",
        components: {
            ProgressCircle
        },
        data() {
            return {
                stats:{},
            };
        },
        mounted() {
            this.getHomeStats()
        },
        methods: {
            async getHomeStats(){
                const date = moment().format('YYYY-MM-DD')
                const payload = {
                    user_id: this.$store.getters.user_id,
                    date: date
                }
                let res = await axios.post(config.api_env + '/application/api-hc/get-home-stats', payload, config.options)
                if(res.data.success){
                    this.stats = res.data
                }
            },
            getPercentage(goal, current){
                return (current / goal) * 100
            }
        },
        computed: {
            skin(){
                return this.$store.getters.skin
            },
            componentText(){
                return this.getText.loggedIn.home.homeStats
            },
            statsPopupContent(){
                return "<h4>"+ this.componentText.steps +"</h4><p>"+ this.componentText.stepsMessage +"</p>" +
                "<h4>"+ this.componentText.distance +"</h4><p> "+ this.componentText.distanceMessage +"</p>" +
                "<h4>"+ this.componentText.calories +"</h4><p>"+ this.componentText.caloriesMessage +" </p>" +
                "<h4>"+ this.componentText.points +"</h4><p> "+ this.componentText.pointsMessage +" </p>"
            }
        }
    };
</script>
<style lang="less">
    #HomeStats {
        position: relative;
        .content-container{
            justify-content: center;
        }
        .stats-card {
            font-family: DMSans;
            font-size: 1.8rem;
            color: #fff;
            cursor: pointer;
            .circle-wrap {
                text-align: center;
                position: relative;
                .ProgressCircle {
                    transform: rotate(-90deg);
                    transform-origin: center;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 150px;
                    height: 150px;
                    margin: auto;
                    .circle__background {
                        fill: rgba(255, 255, 255, 0.19);
                        stroke: #cfcfcf;
                        stroke-dasharray: 2;
                        transform: rotate(-90deg);
                        transform-origin: center;
                    }
                    .circle {
                        fill: none;
                        stroke-linecap: round;
                    }
                }
                img {
                    position: absolute;
                    top: 50%;
                    width: 75px;
                    max-height: 45%;
                    height: auto;
                    transform: translate(-50%, -50%);
                    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                        height: 75px;
                    }
                }
            }
            .card-info {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                transform: translateY(40px);
                p {
                    color: #fff;
                    margin-bottom: 0;
                }
                .card-value {
                    font-family: FilsonProBold;
                    font-size: 3.8rem;
                    line-height: 1;
                }
            }
        }
        .info-button{
            font-size: 2.3rem;
            width: 40px;
            height: 40px;
            text-align: center;
            border-radius: 50%;
            position: absolute;
            top: 20px;
            right: 30px;
            font-family: FilsonProBold;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        @media only screen and (min-width: 992px){
            .stats-card {
                img{
                    left:75px;
                }
            }
        }
        @media only screen and (max-width: 992px){
            .stats-card {
                img{
                    left:50%;
                }
            }
        }
        @media only screen and (max-width: 576px){
            .stats-card {
                .ProgressCircle {
                    width: 130px;
                    height: 130px;
                }
                img{
                    left:50%;
                }
                .card-info{
                    text-align: center;
                    transform: none;
                }
            }
        }
    }
    .stats-tooltip{
        .arrow{
            display: none !important;
        }
        .tooltip-inner {
            background-color: rgba(200, 200, 200, 1);
            border-radius: 15px;
            width: 170px;
            padding: 10px;
            color: #3a4a61;
            font-size: 1.6rem;
            opacity: 1;
            line-height: 1.2;
            h4 {
                font-size: 1.8rem;
            }
            p{
                text-align: center;
            }
        }
    }
</style>
