<template>
	<div id="achievements" class="h-100">
		<div id="streak_info">
			<div id="streak_info_top">
				<br/>
				<span>Current Streak</span>
				<h2>{{getDaysString()}}</h2>
			</div>
			<div id="border_div"></div>
			<div id="streak_info_bottom">
				<span id="total_digit">{{badges.length}}</span>
				<span id="total_text">Total Badges</span>
			</div>
		</div>
		<div id="badges">
			<div class="col-12" id="badge-holder">
				<Badge v-for="(b, i) in badges" :badge="b" :key="i" style="margin-right: 20px"/>
			</div>
		</div>
		<div class="bottom_button">
			<b-button @click="showAllAchievements()" class="cta-button shadow">View All</b-button>
		</div>
	</div>
</template>
<script>
import Badge from "./Badge.vue";
import AllAchievementsModal from "./AllAchievementsModal";
export default {
	components: {
		Badge
	},
	name: "AchievementsColumn",
	props : ['badges', 'day_streaks'],
	mounted() {
		// this.showAllAchievements()
	},
	methods: {
		getDaysString(){
			return this.day_streaks + ' Day' + (this.day_streaks === 1 ? '' : 's')
		},
		showAllAchievements(){
			const content = this.$createElement( AllAchievementsModal, {} )
			const skin = this.$store.getters.skin;
			let opts = {
				hideHeader: true,
				hideFooter: true,
				modalClass: "all_achievements_modal theme_" + skin,
				okTitle : 'Done',
				okOnly : true,
				size: "lg",
				static : true,
				buttonSize: "sm",
				centered: true,
				contentClass: "all_achievements_modal_content",
				id : 'all_achievements_modal'
			}
			return this.$bvModal.msgBoxOk(content, opts);
		}
	},
};
</script>
<style lang="less">
#achievements {
  @media only screen and (min-width: 769px) and (max-width: 970px) {
    #badges {
      padding: 10px !important;
    }
    #badge-holder {
      padding: 5px;
      width: 100%;
    }
    .achievement-badge {
      width: 100%;
      margin-right: 5px !important;
      margin-left: -5px !important;
      .collected-column {
        padding: 5px !important;
        padding-top: 15px !important;
      }
      .img_wrapper{
        justify-content: flex-start;
        align-items: flex-start;
        padding-top: 15px;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    #badge-holder {
      display: flex;
      overflow: scroll;
      overflow-y: hidden;
      .achievement-badge {
        min-width: 260px;
        min-height: 120px;
        .title {
          font-size: 1.8em;
        }
        .collected-column {
          padding: 5px !important;
          padding-top: 15px !important;
        }
        .img_wrapper{
          justify-content: flex-start;
          align-items: flex-start;
          padding-top: 15px;
        }
      }
    }
  }
	background: url('/img/totalMentalHealth/achievements/Blob.png') no-repeat center center;
	background-size: 70%;
	padding-bottom: 80px;
	position: relative;
  .cta-button{
    background: linear-gradient(to right, #985582, #d50870);
    width: 80%;
    max-width: 200px;
    border-radius: 15px;
  }
	#streak_info {
		padding: 15px;
		min-height: 250px;
		position: relative;
		h2 {
			font-size: 40px;
			text-align: left;
			font-weight: 600;
			font-family: FilsonProBold, sans-serif;
			color: white;
		}
		background: url('/img/totalMentalHealth/achievements/Rocket.png') no-repeat right top;
		background-size: contain;
		#border_div {
			position: absolute;
			bottom: 17px;
			width: 90%;
			right: 15px;
			border-bottom: 2px solid rgba(255,255,255,0.07);
		}
		#streak_info_bottom {
			position: absolute;
			bottom: 0px;
			left: 15px;
			height: 50px;
			width: 90%;
			background: url('/img/totalMentalHealth/achievements/Total-Badge-Banner.png') no-repeat left center;
			background-size: contain;
			#total_digit {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 45px;
        height: 48px;
        font-size: 20px;
			}
			#total_text {
				position: absolute;
				left: 50px;
				top: 15px;
				z-index: 100;
			}
		}
	}
	#badges {
		padding: 15px 30px;
	}
}
</style>