<template>
  <div id="JournalEntryQuestion">
    <div class="d-flex flex-row justify-content-between align-items-center">
      <div
        class="journal-entry-question-chart-container bg-brand-secondary mx-3"
      >
        <LineChart
          :chart-data="datacollection"
          :options="chartoptions"
        ></LineChart>
      </div>
      <div class="journal-entry-question px-3 py-5">
        <h3 class="journal-entry-question-title brand-secondary">
          Rate the intensity of emotion on a scale of 0-100%
        </h3>
        <p class="journal-entry-question-description">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam sunt
          facere ipsum quas non molestiae iusto nemo eum!
        </p>
        <input
          type="range"
          :id="'question-slider-' + 1"
          class="question-slider w-100 my-3"
          max="100"
          value="0"
          @change="onChange()"
          style="padding: 0px 0px 0px 0px; height: 40px"
        />
        <div class="d-flex flex-row justify-content-between">
          <div class="d-flex flex-column align-items-center">
            <div><i class="fa fa-frown fa-3x"></i></div>
            <div class="journal-entry-percentage">0%</div>
          </div>
          <div class="d-flex flex-column align-items-center">
            <div><i class="far fa-smile fa-3x"></i></div>
            <div class="journal-entry-percentage">100%</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from "../LineChart.vue";

export default {
  components: {
    LineChart,
  },
  name: "JournalModal",
  data() {
    return {
      content: "",
      datacollection: {
        labels: ["Data1", "Data2", "Data3", "Data4", "Data5"],
        datasets: [
          {
            label: "Data One",
            fill: false,
            backgroundColor: "#ffffff",
            borderColor: "#6b8d9d",
            data: [0, 3, 2, 5, 7],
          },
        ],
      },
      chartoptions: {
        height: 100,
        responsive: true,
        layout: {
          padding: 20,
        },
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                drawBorder: null,
                display: null,
              },
              ticks: {
                drawOnChartArea: null,
                beginAtZero: null,
                display: null,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                drawBorder: null,
                display: null,
              },
              ticks: {
                beginAtZero: null,
                display: null,
                suggestedMax: null,
              },
            },
          ],
        },
        plugins: {
          title: {
            display: false,
          },
        },
      },
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="less">
#JournalEntryQuestion {
  .journal-entry-question-chart-container {
    margin: 10px;
    width: 300px;
    border-radius: 16px;
    overflow: hidden;
  }
  .journal-entry-question-title {
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 2.2rem;
  }
  .journal-entry-question-description {
    font-size: 1.6rem;
  }
  .journal-entry-percentage {
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 10px;
  }

  // Track styling
  input[type="range"]::-moz-range-progress {
    background: #ee3b76;
  }
  input[type="range"]::-webkit-progress-value {
    background: black;
    color: black;
  }

  input[type="range"] {
    height: 39px;
    -webkit-appearance: none;
    width: 100%;
  }
  input[type="range"]:focus {
    outline: none;
  }
  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #ee3b76;
    border-radius: 0px;
    border: 0px solid #010101;
  }
  input[type="range"]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000031;
    border: 3px solid #ee3b76;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    background: #ffffff;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -11.5px;
  }
  input[type="range"]:focus::-webkit-slider-runnable-track {
    background: #ee3b76;
  }
  input[type="range"]::-moz-range-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #ee3b76;
    border-radius: 0px;
    border: 0px solid #010101;
  }
  input[type="range"]::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000031;
    border: 3px solid #ee3b76;
    height: 30px;
    width: 30px;
    border-radius: 30px;
    background: #ffffff;
    cursor: pointer;
  }
  input[type="range"]::-ms-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type="range"]::-ms-fill-lower {
    background: #ee3b76;
    border: 0px solid #010101;
    border-radius: 0px;
    box-shadow: 0px 0px 0px #000000;
  }
  input[type="range"]::-ms-fill-upper {
    background: #ee3b76;
    border: 0px solid #010101;
    border-radius: 0px;
    box-shadow: 0px 0px 0px #000000;
  }
  input[type="range"]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 0px #000031;
    border: 3px solid #ee3b76;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    background: #ffffff;
    cursor: pointer;
  }
  input[type="range"]:focus::-ms-fill-lower {
    background: #ee3b76;
  }
  input[type="range"]:focus::-ms-fill-upper {
    background: #ee3b76;
  }
}
</style>

