<template>
  <div id="Integrations">
    <div class="integrationIntro">We currently use Google Fit for syncing fitness activities. Google Fit is the premiere choice as it supports a seamless cross-platform integration, and unmatched versatility. </div>

    <div v-for="integration in integrations" class="integration" @click="syncActivities">
      <div v-if="integration.image" class="imageHolder">
        <img :src="integration.image" :alt="integration.text"/> Connect
      </div>
      <div v-else class="integrationText">{{integration.text}}</div>
    </div>

    <div class="integrationIntro">By using Google Fit, you can synchronise your fitness data from any major fitness tracker including:</div>
    <div class="logoGrid">
      <div> <img src="/img/challenges/fitbit.png" alt="fitbit"/></div>
      <div> <img src="/img/challenges/garmin.png" alt="garmin"/></div>
      <div> <img src="/img/challenges/apple-watch.png" alt="apple watch"/></div>
      <div> <img src="/img/challenges/strava.png" alt="strava"/></div>
      <div> <img src="/img/challenges/my-fitness.png" alt="my fitness" class="squareImg"/></div>
      <div> <img src="/img/challenges/samsung.png" alt="samsung"/></div>
    </div>
  </div>
</template>

<script>
import Eventbus from "@/helpers/eventbus";

export default {
  name: "Integrations",
  data() {
    return {
      integrations: [
        {
          slug: "google",
          image: null,
          text: "Connect Google Fit"
        },
      ],
    }

  },
  methods: {
    async syncActivities() {
      Eventbus.$emit('syncChallengesData')
    },
  }
}
</script>

<style lang="less">
#Integrations {
  padding: 15px 0;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  display: flex;
  .integrationIntro {
    color: white;
    font-size: 1.4rem;
    margin: 20px 15px 20px 30px;
  }
  .integration {
    background: linear-gradient(90deg, #3AC2ED 0%, #377495 67.19%, #354D6A 100%);
    &:hover {
      background: linear-gradient(90deg, #354D6A 0%, #377495 67.19%, #3AC2ED 100%);
    }
    font-size: 14px;
    width: 75%;
    display: flex;
    height: 38px;
    border-radius: 10px;
    align-items: center;
    margin-top: 10px;
    line-height: 10px;
    justify-content: center;
    cursor: pointer;
    color: white;

    .imageHolder {
      display: flex;
      align-items: flex-end;
      img {
        height: 15px;
        margin-right: 5px;
      }
    }
  }
  .logoGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    width: 100%;
    padding: 10px;
    div {
      img {
        width: 80%;
        display: block;
      }
      .squareImg {
        height: 100%;
        width: auto;
      }
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>