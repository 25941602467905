<template>
    <div id="HomeDigitalClinicsBanner" v-if="categories.length !== 0">
        <div class="dc-banner px-0 mx-0 shadow row d-flex flex-row">
            <div class="d-flex flex-column justify-content-center bg-white col-lg-4 col-md-12 py-4 pr-0">
                <h2 class="dc-banner-header text-left">Explore <b>Digital Clinics</b></h2>
                <p class="dc-banner-text text-left">Get support from the comfort of your own home. Schedule a video
                    consultation
                    today with one of our many experts.</p>
            </div>
            <div
                class="col-lg-8 col-md-12 px-0 d-flex flex-row align-items-center position-relative" :class="[ dfdPage ? 'dfdBackground' : 'bg-brand-secondary']">
                <div class="d-none d-lg-block triangle"></div>
                <div class="dc-items-container w-100">

                    <slick ref="slick" :options="slickOptions" class="w-100" v-if="!loading">
                        <div v-for="(cat, index) in categories" :key="index">
                            <div class="dc-home-cat py-2 px-4">
                                <div class="dc-inner-cat row mx-0"
                                    @click="logAction('DC Banner Click: ' + cat.title, $router.push({name: 'digitalClinicsGeneric', query: {category_id: cat.id}}))">
                                    <div
                                        class="px-4 d-flex flex-column justify-content-center col-12 col-md-6 my-auto">
                                        <h4 class="dc-cat-title">
                                            {{cat.title}}
                                        </h4>
                                        <div class="dc-cat-divider">
                                            <div class="dcd"></div>
                                        </div>
                                        <div v-if="settings === 'wallet'" class="dc-cat-price">
                                            <h5 class="dc-price">
                                                Price:
                                                <span class="dc-amount">
                                                    {{getCurrencyPriceString(cat.currency, cat.price, true)}}
                                                </span>
                                                <br />
                                                <span class="dc-tax" v-if="cat.tax !== 0">
                                                    (+{{getCurrencyPriceString(cat.currency, cat.tax, true)}} VAT)
                                                </span>
                                            </h5>
                                            <div class="dc-cat-price-triangle" :class="{ 'large' : cat.tax > 0}"></div>
                                        </div>
                                    </div>
                                    <div class="dc-featured-image col-12 col-md-6 px-0">
                                        <div class="img-triangle-overlay d-none d-md-block"></div>
                                        <img class="dc-cat-image img-fluid" :src="cat.featured_img" />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </slick>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import slick from "vue-slick";
import EventBus from "@/helpers/eventbus";

export default {
    name: "HomeDigitalClinicsBanner",
    props: ['dfdPage'],
    components: {
        slick
    },
    data() {
        return {
            loading: true,
            settings: 'wallet',
            categories: [],
            slickOptions: {
                arrows: true,
                nextArrow:
                    '<div class="slick-arrow-next">' +
                    '<a class="bg-brand-highlight-1 slick-arrow-container"><i class="fas fa-chevron-right text-white fa-2xs"></i></a>' +
                    '</div>',
                prevArrow:
                    '<div class="slick-arrow-prev d-lg-none">' +
                    '<a class="bg-brand-highlight-1 slick-arrow-container"><i class="fas fa-chevron-left text-white fa-2xs"></i></a>' +
                    '</div>',
                slidesToScroll: 1,
                slidesToShow: 2,
                adaptiveHeight: true,
                infinite: true,
                responsive: [
                    {
                        breakpoint: 520,
                        settings: {
                            slidesToShow: 1,
                        },
                    },
                ],
            },
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData() {
            let res = await this.api({
                path: "api/u/home/get-dc-banner",
            });
            if (res.success) {
                this.categories = res.categories
                this.loading = false;
                this.settings = res.dc_setting;
                if(res.dc_setting === 'wallet') EventBus.$emit('wallet_user');
            }
        },
    },
};
</script>

<style lang="less">
#HomeDigitalClinicsBanner {
    background-color: transparent;
    .dfdBackground {
      background: url('/img/home/new/eapBannerBg.png');
      background-size: cover;
    }


    .dc-banner {
        position: relative;
        display: flex;
        align-items: stretch;
        overflow: hidden;

        .dc-banner-header {
            font-size: 3rem;

            b {
                font-family: 'FilsonProBold', sans-serif;
            }
        }

        .dc-banner-text {
            font-size: 1.4rem;
            padding-bottom: 0;
            margin-bottom: 0;
        }

        .triangle {
            position: absolute;
            top: 0;
            width: 0;
            height: 0;
            z-index: 10;
            border-style: solid;
            border-width: 250px 60px 0 0;
            border-color: white transparent transparent transparent;
        }


        .slick-slide {
            height: 170px;
            transform: scale(1) !important;
        }

        .slick-arrow-container {
            pointer-events: revert-layer;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100px;
            height: 100%;
            z-index: 100;
        }

        .slick-arrow-next {
            right: 10px;
        }

        .slick-arrow-prev {
            left: -5px;
        }

        .slick-list {
            padding-left: 50px;
            padding-right: 40px;
        }

        .slick-disabled {
            opacity: 0;
            pointer-events: none;
        }



        .dc-items-container {
            overflow: hidden;
        }


        .dc-home-cat {
            cursor: pointer;
            position: relative;
            padding-top: 5px !important;
            padding-bottom: 5px !important;

            .dc-inner-cat {
                position: relative;
                background-color: white;
                height: 160px;
                overflow: hidden;
                border-radius: 20px 0px 0px 20px;
                box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.12);

                .dc-cat-divider {
                    position: relative;
                    padding: 7px 0px;

                    .dcd {
                        width: 40%;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
                    }
                }

                .dc-cat-title {
                    font-size: 1.6rem;
                    margin: 7px 0px 0px;
                    position: relative;
                    z-index: 3;
                    background-color: white;
                    border-radius: 20px;
                    text-overflow: wrap;
                    white-space: wrap;
                }

                .dc-duration {
                    color: rgba(0, 0, 0, 0.5);
                    font-weight: 300;
                    font-family: "FilsonProLight", sans-serif;
                }

                .dc-cat-price {
                    position: relative;
                    justify-self: flex-end;
                    background: rgb(255, 255, 255);
                    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(242, 241, 244, 1) 50%);
                    padding: 10px 30px 1px 0px;
                    max-width: 24rem;
                    margin: 7px 0px;

                    .dc-price {
                        font-size: 1.4rem;
                        font-weight: 300;
                        font-family: "FilsonProLight", sans-serif;
                        color: rgba(0, 0, 0, 0.4);

                        .dc-amount {
                            color: rgba(0, 0, 0, 0.7);
                            font-family: "FilsonProBold", sans-serif;
                        }

                        .dc-tax {
                            font-size: 1.2rem;
                            color: grey;
                        }
                    }
                }

                .dc-cat-price-triangle {
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    width: 0;
                    height: 0;
                    border-left: 16px solid transparent;
                    border-right: 10px solid white;
                    border-bottom: 16px solid transparent;
                    border-top: 16px solid transparent;
                    z-index: 78;
                }
                .dc-cat-price-triangle.large {
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    width: 0;
                    height: 0;
                    border-left: 24px solid transparent;
                    border-right: 10px solid white;
                    border-bottom: 24px solid transparent;
                    border-top: 24px solid transparent;
                    z-index: 78;
                }

                .dc-featured-image {
                    position: relative;
                    display: flex;
                    align-self: stretch;
                    flex-grow: 1;
                    overflow: hidden;

                    .img-triangle-overlay {
                        background-color: white;
                        position: absolute;
                        top: -50px;
                        left: -35px;
                        transform: rotate(10deg);
                        height: 150%;
                        width: 40%;
                    }

                    img {
                        height: 100% !important;
                        object-fit: cover;
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 768px) {
        .slick-slide {
            height: 300px !important;
        }
        .dc-inner-cat {
            height: 300px !important;
            border-radius: 20px 20px 0 0 !important;
        }
    }
    @media only screen and (max-width: 768px) {
        .home-container {
            max-width: calc(100vw - 40px) !important;
        }
    }

}
</style>
