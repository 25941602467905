<template>
    <div id="RedirectModal">
        <div class="brand-text">
            Sorry, your secure session expired, please start your journey again.
        </div>
    </div>
</template>
<script>
export default {
    props: [],
    components: {
    },
    name: "RedirectModal",
    data() {
        return {
        };
    },
    mounted() {
        self = this;
        setTimeout(() => {
            window.open(self.getBupaTouchLink, '_self')
        }, 3000)
    },
    methods: {
        closeModal() {
            this.$bvModal.hide("redirect-modal");
        }
    },
    computed: {
        getBupaTouchLink() {
            if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'staging') {
                return 'https://scsmoke3.np.bupa.co.uk/touchdashboard/dashboard/gp-options'
            } else {
                return 'https://www.bupa.co.uk/touchdashboard/dashboard/gp-options'
            }
        },
    },
    watch: {

    }
};
</script>
<style lang="less">
#RedirectModal {
    font-size: 1.8rem;
    font-family: "FilsonProBold", serif;
    padding: 60px 20px;
    text-align: center;
}
</style>