<template>
  <div id="Cmh">
    <component :is="mappings[routeName]"/>
  </div>
</template>

<script>
  import TherapyNDLandingPage from "../TherapyPortal/TherapyNDLandingPage.vue";
  import TherapyDashboard from "../TherapyPortal/TherapyDashboard.vue";
  import TherapyQuestionnaire from "../TherapyPortal/TherapyQuestionnaire.vue";
  import TherapySendSchoolQuestionnaire from "../TherapyPortal/TherapySendSchoolQuestionnaire.vue";
  import therapyTerms from "../TherapyPortal/TherapyTerms.vue";
  import TherapyDateSelect from "../TherapyPortal/TherapyDateSelect.vue";

  export default {
    name: "Cmh",
    components: {TherapyNDLandingPage},
    data() {
      return {
        mappings: {
          cmh: TherapyNDLandingPage,
          start: TherapyNDLandingPage,
          serviceDashboard: TherapyDashboard,
          select: TherapyDateSelect,
          therapyQuestionnaire: TherapyQuestionnaire,
          sendSchoolQuestionnaire: TherapySendSchoolQuestionnaire,
          therapyAgreement: therapyTerms
        }
      }
    },
    computed: {
      routeName() {
        return this.$route.name;
      }
    }
  }
</script>

<style lang="less">
#Cmh {
      .button-loading::after {
        content: "";
        position: absolute;
        width: 20px;
        height: 20px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        border: 4px solid transparent;
        border-radius: 50%;
        border-top-color: white;
        border-bottom-color: white;
        animation: button-loading-spinner 1s ease infinite;
      }
      .question-text{
        font-size: 1.7rem;
        text-align: center;
      }
      textarea {
        font-size: 1.35rem;
        &.incomplete {
          border-color: darkred;
        }
      }
      .checkBoxBtn {
        width: 20px;
        height: 20px;
        border: 1px solid #ED3A75;
        border-radius: 5px;
        background-color: white;
        cursor: pointer;
        position: relative;
        i {
          display: none;
          position: absolute;
          font-size: 15px;
          top: 2px;
          left: 2px;
          margin: auto;
          color: white;
        }
        &.selected {
          background-color: #ED3A75;
          i {
            display: inline-block;
          }
        }
      }
      .toggleBtn {
        width: 24px;
        height: 24px;
        border: 1px solid lightgray;
        border-radius: 50%;
        background-color: white;
        margin: auto;
        cursor: pointer;
        position: relative;
        &.selected {
          &::after {
            content: '';
            position: absolute;
            top: 4px;
            left: 4px;
            width: 14px;
            height: 14px;
            display: inline-block;
            background-color: #ED3A75;
            border-radius: 50%;
          }
        }
      }
      .formSection {
        margin-top: 30px;
        padding-bottom: 10px;
        border-bottom: 1px solid #e5e4e4;
      }
      p {
        font-family: DMSans, sans-serif;
        max-width: 500px;
        margin-top: 20px;
        margin-bottom: 15px;
        font-size: 1.5rem;
      }
      ol {
        font-size: 1.5rem;
        max-width: 500px;
        li {
          margin-top: 10px;
        }
      }
      .TherapyChoiceBox {
        .form-group {
          >div {
            width: fit-content;
          }
        }
      }
      .TherapyChoiceBox {
        .form-group {
          >div {width: 100%}
        }
        .incomplete {
          >div {
            border: 1px solid darkred;
            border-radius: 5px;
          }
        }
      }
      .form-group {
        margin-top: 20px;
        max-width: 500px;
        input, select {
          height: 35px;
          max-width: 500px;
          border-radius: 10px;
          font-size: 1.35rem;
          &.disabled {
            background-color: #e7e6e6;
          }
          &.incomplete {
            border-color: darkred !important;
          }
        }
        .is-invalid {
          border: 1px solid darkred;
        }
        &#phoneGroup {
          div {
            display: flex;
            select {
              width: 150px;
              margin-right: 10px;
            }
            input {
              width: 340px;
            }
          }
        }
        &#dobGroup {
          div {
            display: flex;
            input {
              width: 70px;
              margin-right: 10px;
              text-align: center;
            }
          }
        }
      }
      .addFamilyMemberBox {
        i {
          font-size: 1.6rem;
          margin-right: 15px;
        }
        cursor: pointer;
        font-size: 1.6rem;
        color: #ED3A75;
        font-family: FilsonProBold, Arial, Helvetica, sans-serif;
      }
      h3 {
        letter-spacing: initial !important;
      }
      h4 {
        font-size: 1.8rem !important;
        font-family: "FilsonProBold", sans-serif !important;
        margin-top: 25px !important;
        margin-bottom: 10px !important;
      }
      h5 {
        font-size: 1.65rem !important;
        font-family: "FilsonProBold", sans-serif !important;
        margin-top: 25px !important;
        margin-bottom: 5px !important;
      }
      ul {
        margin-top: 10px;
        li {
          font-size: 1.5rem;
        }
      }
      label {
        margin-bottom: 2px;
        font-family: FilsonProBold, Arial, Helvetica, sans-serif;
        font-size: 1.5rem;
      }
      .custom-radio {
        padding-left: 3.2rem;
        height: fit-content !important;
        .custom-control-label::before, .custom-control-label::after {
          width: 2.25rem;
          height: 2.25rem;
          left: -3rem !important;
        }
        label {
          padding-top: 3px;
          padding-left: 3px;
        }
        input {
          display: block;
          width: 3.5rem;
          height: 3rem;
        }
      }
      .therapyBtn {
        all: unset;
        font-size: 1.4rem;
        color: white;
        text-align: center;
        width: 100%;
        max-width: 300px;
        height: 45px;
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        border-radius: 70px;
        border: 1px solid white;
        box-shadow: 0 4px 4px 0 #00000040;
        background: #ED3A75;
        cursor: pointer;
        &.no-border {
          border: none !important;
        }
        &:disabled {
          background: #D9D9D9 !important;
        }
        &:hover {
          background: darken(#ED3A75, 5%);
        }
        &:active:not(:disabled) {
          transform: translateY(2px);
        }
      }
      .therapyBtn2 {
        all: unset;
        font-size: 1.4rem;
        color: white;
        text-align: center;
        width: 100%;
        max-width: 300px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 70px;
        box-shadow: 0 4px 4px 0 #00000040;
        background: #D60570;
        cursor: pointer;
        &:disabled {
          background: #D9D9D9 !important;
        }
        &:hover {
          background: darken(#D60570, 5%);
        }
        &:active:not(:disabled) {
          transform: translateY(2px);
        }
      }
      .therapyBtnOutline {
        all: unset;
        font-size: 1.4rem;
        color: #ED3A75;
        text-align: center;
        width: 100%;
        max-width: 300px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 70px;
        border: 1px solid #ED3A75;
        box-shadow: 0 4px 4px 0 #00000040;
        background: white;
        cursor: pointer;
        &:disabled {
          background: #D9D9D9 !important;
        }
        &:hover {
          background: #ED3A75;
          color: white;
        }
        &:active:not(:disabled) {
          transform: translateY(2px);
        }
      }
      .formHolder {
        border: 1px solid #BBBBBB;
        padding: 12px 18px;
        background-color: white;
        border-radius: 10px;
        color: #3A4A61;
        display: grid;
        grid-template-columns: 5fr 3fr;
        font-size: 1.4rem;
        margin: auto;
        max-width: 425px;
        .innerText {

          font-family: FilsonProBold, Arial, Helvetica, sans-serif;
          span {
            font-size: 1.2rem;
            text-decoration: underline;
            cursor: pointer;
            line-height: 1.3rem;
            font-family: DMSans, Arial, Helvetica, sans-serif;
          }
        }
        .checkHolder {
          color: #64B811;
          display: flex;
          align-items: center;
          justify-content: space-around;
          i {
            font-size: 2rem;
            color: #64B811;
          }
          &.incomplete {
            color: darkgoldenrod;
            i {
              color: darkgoldenrod;
            }
          }
        }
      }
      .bookingDescriptionBox {
        max-width: 600px;
        background-color: white;
        font-size: 1.4rem;
        span {
          font-family: FilsonProBold, Arial, Helvetica, sans-serif
        }
      }










  @media only screen and (max-width: 768px) {
    #local_video_wrapper:not(.test-local-video) {
      border-radius: 5px !important;
      width: 30%;
      #local_video {
        video {
        }
      }
    }
    .coach_modal {
      width: 100%;
      .modal-body {
        padding: 0!important;
      }
    }
    #VideoRoomContainer {
      .container {
        padding: 0 2px !important;
      }
    }
  }
}
</style>