var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view-clinic"},[(_vm.loading)?_c('div',{staticClass:"content-container row"},[_c('div',{staticClass:"col-12 d-flex justify-content-center"},[_c('b-spinner')],1)]):_c('div',{staticClass:"content-container row pb-5 mb-5"},[_c('div',{staticClass:"d-none d-md-block col-md-4"},[_c('div',{staticClass:"category-card"},[(_vm.category.c_featured_img)?_c('div',{staticClass:"category-image mb-3",style:('background-image: url(' + _vm.category.c_featured_img + ')')}):_vm._e(),_c('div',{staticClass:"card-content px-4 py-3"},[_c('h3',{staticClass:"brand-primary py-2"},[_vm._v(_vm._s(_vm.category.c_label))]),(_vm.category.c_description)?_c('p',[_vm._v(_vm._s(_vm.category.c_description))]):_vm._e()])])]),_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"options-card p-4"},[_c('h3',{staticClass:"d-block d-md-none brand-primary py-3"},[_vm._v(" "+_vm._s(_vm.category.c_label)+" ")]),(!_vm.dateCarousel || _vm.dateCarousel.length < 1)?_c('div',{staticClass:"card-row"},[_c('h3',{staticClass:"text-center"},[_vm._v(" Apologies, there are no "+_vm._s(_vm.category.c_label)+" clinics available at the moment. ")])]):_c('div',{staticClass:"event-date card-row"},[_c('h4',[_vm._v("Choose Date:")]),_c('b-button-group',{staticClass:"button-carousel px-0 px-sm-5 py-3"},[(_vm.showPrevDate)?_c('a',{staticClass:"prev-date",on:{"click":function($event){return _vm.scrollCarousel('dateCarouselIndex', -1)}}},[_c('i',{staticClass:"brand-highlight-1 fa fa-caret-left fa-4x"})]):_vm._e(),_vm._l((_vm.dateCarousel),function(date,index){return _c('b-button',{key:index,staticClass:"filter-button py-3 col-6 col-md-4 mx-3",class:{
                active: _vm.selectedDate && _vm.selectedDate.date === date.date,
              },on:{"click":function($event){return _vm.selectDate(date, index)}}},[_vm._v(_vm._s(_vm.formatDate(date.date)))])}),(_vm.showNextDate)?_c('a',{staticClass:"next-date",on:{"click":function($event){return _vm.scrollCarousel('dateCarouselIndex', 1)}}},[_c('i',{staticClass:"brand-highlight-1 fa fa-caret-right fa-4x"})]):_vm._e()],2)],1),(_vm.selectedDate)?_c('div',{staticClass:"event-provider card-row"},[_c('h4',[_vm._v("Choose Expert:")]),_c('b-button-group',{staticClass:"button-carousel px-sm-5 py-3"},[(_vm.showPrevEvent)?_c('a',{staticClass:"prev-date",on:{"click":function($event){return _vm.scrollCarousel('eventCarouselIndex', -1)}}},[_c('i',{staticClass:"brand-highlight-1 fa fa-caret-left fa-4x"})]):_vm._e(),_vm._l((_vm.eventCarousel),function(event,index){return _c('b-button',{key:index,staticClass:"filter-button col-6 col-md-4 py-3 mx-3",class:{
                active: _vm.selectedEvent && _vm.selectedEvent.ed_id === event.ed_id,
              },on:{"click":function($event){return _vm.getDate(event.ed_id)}}},[_vm._v(_vm._s(event.e_service_provider ? event.e_service_provider : event.e_title))])}),(_vm.showNextEvent)?_c('a',{staticClass:"next-date",on:{"click":function($event){return _vm.scrollCarousel('eventCarouselIndex', 1)}}},[_c('i',{staticClass:"brand-highlight-1 fa fa-caret-right fa-4x"})]):_vm._e()],2)],1):_vm._e(),(_vm.timeSlots)?_c('div',{staticClass:"event-time card-row"},[(_vm.timeSlots.length > 0)?_c('div',[_c('h4',[_vm._v("Choose Time:")]),_c('div',{staticClass:"px-md-5 py-3 row time-slots mx-0"},_vm._l((_vm.timeSlots.filter(
                  function (x) { return !x.ts_fully_booked; }
                )),function(timeSlot,index){return _c('div',{key:index,staticClass:"col-6 col-md-4 px-0 d-flex my-3"},[_c('b-button',{staticClass:"filter-button py-3 mx-3",class:{
                    active:
                      _vm.selectedSlot && _vm.selectedSlot.ts_id === timeSlot.ts_id,
                  },on:{"click":function($event){return _vm.slotSelect(timeSlot)}}},[_vm._v(_vm._s(_vm.parseTime(timeSlot.ts_start_date_time)))])],1)}),0)]):_c('div',[_c('h4',[_vm._v(" Sorry, there's no available slots for the selected provider on this date. ")])])]):_vm._e(),(_vm.selectedSlot)?_c('div',{staticClass:"phone-input pb-5"},[_c('h4',[_vm._v("Phone Number:")]),_c('b-form-group',{staticClass:"px-5 pt-3"},[_c('b-input',{staticClass:"py-3 pl-4",attrs:{"type":"number"},on:{"blur":_vm.phoneInput,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return $event.target.blur()}},model:{value:(_vm.phoneModel),callback:function ($$v) {_vm.phoneModel=$$v},expression:"phoneModel"}}),(_vm.showError)?_c('p',{staticClass:"text-pink"},[_vm._v(" * You must enter a valid phone number ")]):_vm._e()],1)],1):_vm._e(),(_vm.phoneNumber && _vm.selectedEvent)?_c('div',{staticClass:"event-questions pb-5"},_vm._l((_vm.selectedEvent.e_event_questions),function(question,i){return _c('div',{key:i,staticClass:"question",attrs:{"id":'question' + '-' + i}},[_c('h4',[_vm._v(_vm._s(question.question_label))]),(
                question.question_label
                  .toLowerCase()
                  .includes('is this an initial or follow-up appointment?')
              )?_c('div',{staticClass:"mobile-tooltip"},[_vm._m(0,true),_vm._m(1,true)]):_vm._e(),_c('v-select',{staticClass:"px-5 py-3",attrs:{"options":question.options,"label":"label"},on:{"input":function($event){return _vm.questionInput($event, question.question_label, i)}}}),(
                question.question_label
                  .toLowerCase()
                  .includes('is this an initial or follow-up appointment?')
              )?_c('b-tooltip',{attrs:{"custom-class":"clinics-questions-tooltip","target":'question' + '-' + i,"triggers":"hover","placement":"top"}},[_c('p',[_vm._v(" Use "),_c('span',{staticClass:"italic"},[_vm._v("Initial")]),_vm._v(" if this is the first consultation for your current concern. ")]),_c('p',[_vm._v(" Use "),_c('span',{staticClass:"italic"},[_vm._v("Follow Up")]),_vm._v(" if you have already had your Initial Consultation on this specific concern ")])]):_vm._e()],1)}),0):_vm._e(),(_vm.showBookingButton)?_c('div',{staticClass:"selected-info px-md-5 mx-md-3"},[_c('p',[_vm._v(" You have selected "),_c('strong',[_vm._v(_vm._s(_vm.formatDate(_vm.selectedSlot.ts_start_date_time)))]),_vm._v(" at "),_c('strong',[_vm._v(_vm._s(_vm.parseTime(_vm.selectedSlot.ts_start_date_time)))]),_vm._v(" with "),_c('strong',[_vm._v(_vm._s(_vm.selectedEvent.e_service_provider))])]),_vm._m(2),_vm._m(3),_c('p',[_vm._v(" If you have any issues or questions, please use our live chat. ")]),_c('div',{staticClass:"button-wrap"},[_c('b-button',{staticClass:"cta-button px-5 py-3 mt-3 mb-5",attrs:{"disabled":_vm.category.c_user_registered || !_vm.phoneNumber},on:{"click":function($event){return _vm.bookNow(_vm.selectedSlot.ts_id)}}},[_vm._v("Book Now")])],1)]):_vm._e(),(_vm.category.c_user_registered)?_c('div',{staticClass:"already-registered col-12 py-2"},[_c('p',{staticClass:"mb-0"},[_vm._v(" You already have reached the booking limit for this service. You can only have three upcoming bookings per service, if you wish to cancel an existing booking to book a new one, you can do so in "),_c('a',{on:{"click":function($event){return _vm.$emit('booked-event')}}},[_vm._v("My Bookings")])])]):_vm._e()])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v(" Use "),_c('span',{staticClass:"italic"},[_vm._v("Initial")]),_vm._v(" if this is the first consultation for your current concern. ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v(" Use "),_c('span',{staticClass:"italic"},[_vm._v("Follow Up")]),_vm._v(" if you have already had your Initial Consultation on this specific concern ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v(" By booking an appointment, you agree to the following "),_c('a',{attrs:{"href":"/laya/Digital_Wellbeing_Experts_TOS_Privacy.pdf","target":"_blank"}},[_vm._v("terms & conditions")]),_vm._v(". ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v(" What happens next?"),_c('br'),_vm._v(" 1. You'll receive an email booking confirmation and a link to manage your booking."),_c('br'),_vm._v(" 2. You will receive your unique video consultation link 1-2 days before your appointment. Please ensure to check your junk or spam inbox."),_c('br'),_vm._v(" 3. 5 minutes before your appointment, you should visit your link to make sure everything is working correctly. ")])}]

export { render, staticRenderFns }