<template>
  <div
    class="message flex flex-column"
    :class="
      author === getUsernameBySha1Identity(message.state.author)
        ? 'sent-message justify-content-end align-items-end ml-5'
        : 'received-message align-items-start mr-5'
    "
    v-if="!message.state.attributes.system"
  >
    <div
      v-if="message.state.type === 'text' || message.state.type === 'media'"
      class="text_message"
    >
      <span class="brand-text pl-3">{{ message.state.author === "ChatBot" ? "Automated Coach Response" : getUsernameBySha1Identity(message.state.author) }}</span>
      <div v-if="hasAttachedFiles()" class="message-body mt-1">
        <div
          v-if="message.is_marked"
          class="read-flag"
          title="message has been read"
        >
          <i class="fa fa-check-circle"></i>
        </div>
        <FileChatMessage :message="message" />
      </div>
      <div
        v-else
        class="message-body mt-1"
        :class="message.state.attributes.hasOwnProperty('tags') ? 'mb-2' : ''"
      >
        <div
          v-if="message.is_marked"
          class="read-flag"
          title="message has been read"
        >
          <i class="fas fa-check-circle"></i>
        </div>
        {{ message.state.body }}
        <div
          v-if="message.state.attributes.hasOwnProperty('tags')"
          class="ui_icons"
        >
          <div
            v-for="(tag, tag_i) in message.state.attributes.tags"
            :key="tag_i"
            :style="getStyle(tag_i)"
            class="ui_icon_outer"
          >
            <div>
              <div class="ui_icon_inner animated fadeInRight">
                <i
                  :id="'icon_' + message.state.sid + '_' + tag_i"
                  class="fa fa-thumbs-up"
                  aria-hidden="true"
                ></i>
                <b-tooltip
                  :target="'icon_' + message.state.sid + '_' + tag_i"
                  >{{ getUsernameBySha1Identity(tag.author) }}</b-tooltip
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="float-right">
        <label class="very_small clickable" @click="flagMessage"
          ><span v-if="message.state.attributes.tags ? message.state.attributes.tags.length > 0 : false"
            >Unlike</span
          ><span v-else>Like</span></label
        >
        |
        <label class="very_small">
          Sent at {{ getChatDate(message.state.timestamp) }}</label
        >
      </div>
    </div>
    <!-- These buttons need to go under the message and then be listed as in the docs -->
    <div
      v-if="isOptionsVisible()"
      class="
        btn-section
        align-self-end
        d-flex
        flex-column
        border-brand-highlight-1
        brand-highlight-1
      "
    >
      <b-button
        v-for="(option, option_key) in this.message.state.attributes.options"
        :key="option_key"
        size="lg"
        class="btn-option border-brand-highlight-1 brand-highlight-1 mb-4"
        @click="sendMessage"
        >{{ option.text }}
      </b-button>
    </div>
  </div>
  <div class="system-message-container d-flex justify-content-center" v-else>
    <div class="system-message" v-html="message.state.body"></div>
  </div>
</template>
<script>
import axios from "axios";
import FileChatMessage from "@/components/TotalMentalHealth/FileChatMessage";
import config from "../../config/constants";

export default {
  name: "ChatMessage",
  components: { FileChatMessage },
  props: ["message", "author", "totalMessages", "is_chat_bot_conversation","getUsernameBySha1Identity"],
  mounted() {
    if(this.message.state.author === "ChatBot") {
      this.message.state.author = "Automated Coach Response"
    }
  },
  methods: {
    getStyle(i) {
      let offset = 12 * (i + 1);
      return "right: " + offset + "px";
    },
    isOptionsVisible() {
      return (
        this.is_chat_bot_conversation &&
        this.message.state.attributes.hasOwnProperty("options") &&
        this.message.state.index === this.totalMessages - 1
      );
    },
    hasAttachedFiles() {
      return this.message.state.type === "media";
    },
    getMessageStyles() {
      return undefined !== this.message.state.attributes.styles
        ? this.message.state.attributes.styles
        : {};
    },
    isSystemMessage() {
      return (
        undefined !== this.message.state.attributes.system &&
        this.message.state.attributes.system === true
      );
    },
    sendMessage(event) {
      this.$parent.$emit("send-message-via-btn", event);
    },
    async downloadFile(message) {
      message.state.media.getContentTemporaryUrl().then(function (url) {
        window.location = url;
      });
    },
    async flagMessage() {
      let value = "liked";
      if (this.message.state.attributes.hasOwnProperty("tags")) {
        let user_tag = this.message.state.attributes.tags.filter((tag) => {
          if (tag.user_id === this.$store.state.user.user.user_id) {
            return true;
          }
        });
        if (user_tag.length === 1) {
          value = "not_liked";
        }
      }
      let payload = {
        user_id: this.$store.getters.user_id,
        conversation_sid: this.message.conversation.sid,
        message_sid: this.message.state.sid,
        author: this.message.state.author,
        value: value,
      };
      let res = await axios.post(
        config.api_env + "/application/api/u/chat/flag-message",
        payload,
        config.options
      );
      if (res.data.success == true) {
        console.log("message flagged");
      } else {
        console.log(res);
      }
    },
  },
};
</script>
<style lang="less">
.message {
  display: flex;
  position: relative;
  .very_small {
    font-weight: bold;
    padding-top: 5px;
    color: #a7a7a7;
  }
  .clickable {
    cursor: pointer;
  }
  .message-body {
    background-color: white;
    padding: 10px 15px;
    border-radius: 16px;
    font-size: 1.4rem;
    position: relative;
    flex-shrink: 1;
    cursor: pointer;

    .read-flag {
      position: absolute;
      width: 16px;
      height: 16px;
      bottom: -6px;
      right: 0px;
      i {
        z-index: 10000;
        height: 16px;
        width: 16px;
        color: #ddd;
      }
    }

    // .read-flag::before {
    //   position: absolute;
    //   left: 0;
    //   top: 50%;
    //   height: 50%;
    //   width: 3px;
    //   background-color: #2c6dad;
    //   content: "";
    //   transform: translateX(10px) rotate(-45deg);
    //   transform-origin: left bottom;
    // }

    // .read-flag::after {
    //   position: absolute;
    //   left: 0;
    //   bottom: 0;
    //   height: 3px;
    //   width: 100%;
    //   background-color: #336699;
    //   content: "";
    //   transform: translateX(10px) rotate(-45deg);
    //   transform-origin: left bottom;
    // }
  }
  .my_message {
    justify-content: flex-end;
  }
  .btn-section .btn {
    margin: 0 10px;
  }
  .btn-option {
    background-color: inherit;
    padding: 30px 30px;
    min-width: 200px;
    border-radius: 8px !important;
    border-style: solid;
    border-color: inherit;
    border-width: 2px !important;
    font-weight: bold;
  }
  .btn-option:hover {
    background-color: #eee;
    border-color: inherit;
    color: inherit !important;
  }
  .ui_icons {
    text-align: right;
    position: absolute;
    right: 5px;
    width: 100px;
    height: 20px;
    overflow: hidden;
    .ui_icon_outer {
      display: inline-block;
      position: absolute;
      .ui_icon_inner {
        position: relative;
        i {
          font-size: 2rem;
          color: green;
          //text-shadow: white 0px 0px 3px;
        }
      }
    }
  }
}
.system-message-container {
  width: 100%;
  padding: 20px;
  font-size: 1.4rem;
  text-align: center;
  .system-message {
    max-width: 400px;
  }
}
</style>
