<template>
  <b-form-group
      :id="id + '-group'"
      :label="label"
      :label-for="id"
      :class="{required: required}"
  >
    <b-form-select
      v-model="answer"
      :id="id"
    >
      <b-form-select-option v-for="opt in options" :value="opt">{{opt}}</b-form-select-option>
    </b-form-select>
  </b-form-group>
</template>

<script>

export default {
  name: "TherapySelectQuestion",
  props: ["label","item","options","required"],
  data() {
    let id = this.genRandonString(10);
    let ans = this.item;
    return {
      id: id,
      answer: ans
    }
  },
  watch: {
    answer(val) {
      this.$emit('update',val)
    }
  }
}
</script>

<style lang="less">

</style>