<template>
  <div>
    <div id="CustomHomeSlider" @mouseleave="clearSelection" ref="CustomHomeSlider">
      <div id="viewer" ref="viewer" :style="{marginLeft: viewerLeftMargin}">
        <div id="track" ref="track">
          <div
              class="tile invisibleCard"
              :style="{
              width:  invisibleCardWidth + 'px',
              height: tileHeight + 'px',
            }"
          >
          </div>
          <div
              @mouseover="selectTile(i)"
              class="tile"
              :class="tileSelected === i && tilesToShow > 2 ? 'selected': null"
              :style="{
            width:  getTileWidth(i),
            height: tileHeight + 'px',
            paddingLeft: tilePaddingLeft + 'px',
            paddingRight: tilePaddingRight + 'px'
          }"
              v-for="(item,i) in items" :key="'slider-card-' + i"
          >
            <div class="innerTile" :class="{hiddenCard: hideCards}">
              <ServicesCard
                  v-if="tileType === 'services'"
                  :item="item"
                  :stop-expand="stopExpand"
                  :expanded="tileSelected === i && tilesToShow"
                  :tile-height="tileHeight"
                  :increase-factor="expandedTileStretch"
                  :standard-tile-width="tileWidth"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="arrowLeft arrow" :style="{top: (tileHeight / 2 - 20) + 'px'}" :class="{fadeOut: page <= 1}">
        <i class="fa-solid fa-chevron-left" @click="goBack" v-if="page > 1"></i>
      </div>
      <div class="arrowRight arrow" :style="{top: (tileHeight / 2 - 20) + 'px'}" :class="{fadeOut: totalPages <= page}">
        <i class="fa-solid fa-chevron-right" @click="goForward" v-if="totalPages > page"></i>
      </div>
    </div>
  </div>
</template>

<script>

import ServicesCard from "@/components/Home/New/ServicesCard.vue";
import EventBus from "@/helpers/eventbus";


export default {
  name: "CustomHomeSlider",
  components: {
    ServicesCard
  },
  props: {
    items: {
      type: Array
    },
    tileType: {
      type: String
    },
  },
  data() {
    return {
      sliderWidth: 0,
      screenWidth: 0,
      tileSelected: null,
      sideNavWidth: 0,
      expandedTileStretch: 2.5,
      expandedTileStretchSmallScreen: 1.75,
      scrolling: false,
      invisibleCardWidth: 0,
      hideCards: true,
      aspectRatio: {
        width: 138,
        height: 200
      },
      page: 1,
      showOptions: {
        tilesToShow: 5,
        cardsStopExpandingPoint: 2,
        responsive: [
          {max: 680, tilesToShow: 2},
          {max: 880, tilesToShow: 3},
          {max: 1400, tilesToShow: 4}
        ]
      }
    }
  },
  methods: {
    goBack() {
      if(this.pages === 1 || this.scrolling) return;
      this.scrolling = true;
      this.$refs.viewer.scrollLeft -= this.sliderWidth;
      this.page = Math.max(1, this.page - 1);
      setTimeout(()=> {
        this.scrolling = false;
      }, 500)
    },
    goForward() {
      if(this.page === this.totalPages || this.scrolling) return;
      this.scrolling = true;
      this.$refs.viewer.scrollLeft += this.sliderWidth;
      this.page = Math.min(this.page + 1, this.totalPages);
      setTimeout(()=> {
        this.scrolling = false;
      }, 500)
    },
    getTileWidth(i) {
      if(this.tilesToShow <= this.showOptions.cardsStopExpandingPoint) return this.tileWidth + 'px';
      return (this.tileWidth * (this.tileSelected === i ? this.expandedTileStretch : 1)) + 'px'
    },
    selectTile(i) {
      this.tileSelected = i;
    },
    clearSelection() {
      this.tileSelected = -1;
      this.$refs.viewer.scrollLeft = (this.tileWidth * this.expandedTileStretch) + this.additionalScroll;
      this.setInvisibleCardWith();
    },
    updateTiles() {
      this.screenWidth = document.documentElement.clientWidth - this.sideNavWidth;
      this.sliderWidth = Math.min(2000, this.screenWidth);
      this.page = 1;
      setTimeout(()=> {
        if(this.$refs.viewer && this.$refs.viewer.hasOwnProperty('scrollLeft')) {
          this.$refs.viewer.scrollLeft = (this.tileWidth * this.expandedTileStretch) + this.additionalScroll;
        }
        this.clearSelection();
      },500)
    },
    setInvisibleCardWith(newTile,oldTile) {
      this.invisibleCardWidth = this.invisibleCardWidthCalulator(newTile,oldTile);
    },
    invisibleCardWidthCalulator(newTile, oldTile) {
      if(this.tilesToShow === this.showOptions.cardsStopExpandingPoint) return (this.tileWidth * this.expandedTileStretch);
      if(this.tileSelected === -1) return this.tileWidth * this.expandedTileStretch;
      const pos = newTile - ((this.page - 1) * this.tilesToShow);
      const expanse = this.expandedTileStretch * this.tileWidth;
      if(this.tilesToShow === 5 || this.tilesToShow === 6) {
        if(newTile > oldTile) {
          if(pos === 0) {
            return expanse
          } else if (pos === 1) {
            return expanse * 0.9;
          } else if (pos === 2) {
            return (expanse * 0.8);
          } else if (pos === 3) {
            return (this.tileWidth * 1.5);
          } else {
            return  this.tileWidth;
          }
        } else {
          if(pos === 0) {
            return (expanse);
          } else if (pos === 1) {
            return (expanse * 0.8);
          } else if (pos === 2) {
            return (expanse * 0.6);
          } else if (pos === 3) {
            return (this.tileWidth * 1.25);
          } else {
            return  this.tileWidth;
          }
        }

      } else if(this.tilesToShow === 4) {
        if(newTile > oldTile) {
          if(pos === 0) {
            return (expanse);
          } else if (pos === 1) {
            return (expanse * 0.8);
          } else if (pos === 2) {
            return this.tileWidth * 1.5;
          } else if (pos === 3) {
            return this.tileWidth;
          }
        } else {
          if(pos === 0) {
            return (expanse);
          } else if (pos === 1) {
            return (expanse * 0.75);
          } else if (pos === 2) {
            return this.tileWidth * 1.35;
          } else if (pos === 3) {
            return this.tileWidth;
          }
        }
      } else if (this.tilesToShow === 3) {
        if(pos + 1 === 1) {
          return (this.expandedTileStretch * this.tileWidth)
        } else if(pos + 1 === 2) {
          return (this.expandedTileStretchSmallScreen * this.tileWidth);
        } else {
          return this.tileWidth
        }
      }
    },
  },
  computed: {
    stopExpand() {
      return this.tilesToShow <= this.showOptions.cardsStopExpandingPoint;
    },
    viewerLeftMargin() {
      if(this.sliderWidth < this.screenWidth) return ((this.screenWidth - this.sliderWidth) / 2) + 'px';
      return 0;
    },
    tileHeight() {
      const aspectRatio = this.aspectRatio.width / this.aspectRatio.height
      return Math.floor((this.tileWidth - this.tilePaddingLeft - this.tilePaddingRight) / aspectRatio);
    },
    tilePaddingRight() {
      return Math.floor(this.tileWidth * 0.055);
    },
    tilePaddingLeft() {
      return Math.floor(this.tileWidth * 0.025);
    },
    tileWidth() {
      return Math.floor(this.sliderWidth / this.tilesToShow);
    },
    tilesToShow() {
      let max = 10000;
      let tilesToShow = this.showOptions.tilesToShow;
      this.showOptions.responsive.forEach(option => {
        if(option.max > this.sliderWidth && option.max < max) {
          max = option.max;
          tilesToShow = option.tilesToShow
        }
      })
      return tilesToShow;
    },
    totalPages() {
      return Math.ceil(this.items.length / this.tilesToShow)
    },
    additionalScroll() {
      if(this.page === 1) return 0;
      return (this.page - 1) * (this.sliderWidth);
    }
  },
  mounted() {
    this.updateTiles();
    EventBus.$on("sideNavExpanded",(expanded) => {
      if(!this.$refs.CustomHomeSlider) {
        return;
      }
      this.sideNavWidth = expanded ? 250 : 0;
      this.updateTiles();
    })
    window.addEventListener('resize', this.updateTiles);
    this.setInvisibleCardWith(-1,null);
    setTimeout(()=> {
      this.hideCards = false;
      //this.selectTile(0)
    },1000)
  },
  destroyed() {
    window.removeEventListener('resize', this.updateTiles);
  },
  watch: {
    tileSelected(newValue,oldValue) {
      this.setInvisibleCardWith(newValue, oldValue);
    },
    items() {
      this.updateTiles()
    },
    tileWidth() {
      this.$refs.viewer.scrollLeft = (this.tileWidth * this.expandedTileStretch);
    }
  }
}
</script>

<style lang="less">
#CustomHomeSlider {
  width: 100%;
  padding-top: 25px;
  margin-bottom: 25px;
  position: relative;
  #viewer {
    max-width: 2000px;
    width: 100%;
    overflow: hidden;
    scroll-behavior: smooth;
    height: 100%;
  }
  #track {
    display: flex;
    height: 100%;
    width: 50000px;
  }
  .invisibleCard {
    visibility: hidden;
  }
  .tile {
    transition: width 1s ease-in-out;
    //transition-delay: 0.25s;
    .innerTile {
      border-radius: 10px;
      height: 100%;
      width: 100%;
      transition: opacity 1s ease-out;
      opacity: 1;
      &.hiddenCard {
        opacity: 0 !important;
      }
    }
  }
  .arrow {
    position: absolute;
    height: 100px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    z-index: 100;
    transition: opacity 0.5s linear;
    background-color: rgba(0,0,0,0.5);
    &.fadeOut {
      opacity: 0;
    }
    i {
      cursor: pointer;
      color: white;
      font-size: 20px;
    }
  }
  .arrowLeft {
    left: 0;
    border-radius: 0 5px 5px 0;
  }
  .arrowRight {
    right: 0;
    border-radius: 5px 0 0 5px;
  }
}
</style>