<template>
  <div id="TherapyBookingCard">
    <div class="HomeBookingCard">
      <div class="booking-card bg-brand-secondary text-white">
        <div class="booking-card-content-container">
          <div class="booking-card-heading d-flex flex-row justify-content-between mx-4 py-3">
            <div class="booking-card-event-date">
              <span class="booking-card-event-day">{{ getDay(booking.start) }}</span>
              <div class="booking-card-event-month">{{ getMonth(booking.start) }}</div>
            </div>
            <div class="booking-card-icon-container">
              <div v-bind:style="{ backgroundImage: 'url(' + booking.featured_image + ')' }" class="img-fluid" v-if="booking.featured_image" />
            </div>
          </div>
          <div class="booking-card-details mx-4 mb-3 py-3">
            <div class="booking-card-title">
              <b>{{ booking.title }}</b><br>
<!--              <span v-if="booking.position" class="booking-card-position">{{booking.position}}</span>-->
            </div>
            <div class="booking-card-date-details">
              <div class="booking-card-day">{{ getDateLineText(booking.start) }}</div>
              <div class="booking-card-hours">
                <i class="fas fa-clock" aria-hidden="true"></i>
                {{ getFormatedDate(booking.start, 'h:mm a') }} - {{ getFormatedDate(booking.end, 'h:mm a') }}
              </div>
            </div>
          </div>
          <div class="booking-card-button-container">
            <b-button variant="cta" size="md" class="booking-card-button w-100" @click="openModal(booking, serviceProvider)">
              {{ componentText.cards.join }} <i class="fa-solid fa-video"></i>
            </b-button>
          </div>
        </div>

        <div class="booking-card-blob-container">
          <img class="img-fluid" src="/img/home/booking-blob.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import MeetingRoomModal from "@/components/DigitalClinics/MeetingRoomModal.vue";
import EarlyModal from "@/components/DigitalClinics/EarlyModal.vue";


export default {
  name: "TherapyBookingCard",
  props: {
    booking: Object,
    serviceProvider: Object,
    isEarliest: Boolean,
  },
  data() {
    return {
      shouldShowLink: false,
    };
  },
  mounted() {
    let self = this;
    let now = moment();
    let bookingStart = moment(this.booking.start);
    if (this.isEarliest) {
      if (now.isSameOrAfter(bookingStart.subtract(10, "minute"))) {
        this.shouldShowLink = true;
      } else {
        let offset = bookingStart.diff(now);
        if (offset < 2147483647) {
          setTimeout(() => {
            self.shouldShowLink = true;
          }, offset);
        }
      }
    }
    if(this.$route.query.join) {
      this.openModal(this.booking, this.serviceProvider)
    }
  },
  computed: {
    eventStarted() {
      return moment() > moment(this.booking.start);
    },
    componentText() {
      return this.getText.dfd;
    },
  },
  methods: {
    openModal(booking, sp) {
      if (moment(booking.start).diff(moment(), 'days') >= 1) {
        // this.openEarlyModal(booking, sp);
        this.openBookingModal(booking, sp);
      } else {
        this.openBookingModal(booking, sp);
      }
    },
    openBookingModal(booking, sp) {
      const content = this.$createElement(MeetingRoomModal, { props: { booking: booking, serviceProvider: sp } })
      const skin = this.$store.getters.skin;
      let opts = {
        title: "My Coaching Session",
        hideHeader: true,
        hideFooter: true,
        modalClass: "coach_modal theme-" + skin,
        headerClass: "coach_modal_header bg-brand-secondary d-flex justify-content-center",
        size: "lg",
        buttonSize: "sm",
        centered: true,
        contentClass: "coach_modal",
        id: 'digital_clinics_modal',
        noCloseOnBackdrop: true,
      }
      return this.$bvModal.msgBoxOk(content, opts);
    },
    openEarlyModal(booking, sp) {
      const content = this.$createElement(EarlyModal, { props: { booking: booking, serviceProvider: sp } })
      const skin = this.$store.getters.skin;
      let opts = {
        title: "My Session",
        hideHeader: true,
        hideFooter: true,
        modalClass: "coach_modal theme-" + skin,
        headerClass: "coach_modal_header bg-brand-secondary d-flex justify-content-center",
        size: "md",
        buttonSize: "sm",
        centered: true,
        contentClass: "coach_modal",
        id: 'digital_clinics_early_modal',
        noCloseOnBackdrop: true,
      }
      return this.$bvModal.msgBoxOk(content, opts);
    },

  },
};
</script>

<style lang="less">
.HomeBookingCard {
  margin: auto;
  max-width: 240px;
  .booking-card {
    position: relative;
    border-radius: 24px 24px 30px 30px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 1;
    .booking-card-content-container{
      position: relative;
      z-index: 3;
    }
    .booking-card-date-details {
      min-height: 60px;
      .booking-card-day,.booking-card-hours {
        font-size: 1.35rem;
      }
    }
    .booking-card-heading {
      border-bottom: 1px solid white;

      .booking-card-event-date {
        margin-top: 8px;
        .booking-card-event-day {
          line-height: 1;
          font-size: 3.2rem;
          padding-bottom: 10px;
        }

        .booking-card-event-month {
          line-height: 1;
          font-size: 2.0rem;
          margin-bottom: 10px;
        }
      }

      .booking-card-icon-container {
        margin-top: 10px;
        height: 60px;
        width: 60px;
        border-radius: 50% !important;
        .img-fluid {
          border-radius: 50% !important;
          height: 100%;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
        }
      }
    }

    .booking-card-details {
      font-size: 1.1rem;

      .booking-card-title {
        font-size: 1.4rem;
        font-family: "FilsonProBold", sans-serif;
        margin-bottom: 15px;
        min-height: 50px;
        .booking-card-position {
          font-family: DMSans, Arial, Helvetica, sans-serif !important;
        }
      }
    }

    .booking-card-button {
      font-size: 1.3rem;
      letter-spacing: normal;
      border-radius: 0 0 24px 24px !important;
    }

    .booking-card-blob-container {
      position: absolute;
      top: 0;
      z-index: 2;
      img{
        z-index: 2;
      }
    }

  }
}
</style>
