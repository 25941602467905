<template>
  <div id="ResetPasswordV2">
    <div>
      <div class="form min-height">
        <div v-if="hardcoded.login_logos" class="logos d-flex pt-5">
          <div
            v-for="(logo, i) in hardcoded.login_logos"
            :key="i"
            class="d-flex logo px-1"
          >
            <img :src="logo" />
          </div>
        </div>
        <div class="form-content">
          <div
            :class="[
             ( $store.getters.client.client_logo.toLowerCase().includes('spectrum-life-logo') ||  $store.getters.client.client_logo.toLowerCase().includes('lloydwhyte') ) && skin === 15
                ? 'lw-form-logo'
                : 'form-logo',
            ]"
          >
            <img
              :src="getLogo"
              onerror="javascript:this.src='/img/logo-spectrum-life.svg'"
            />
          </div>
          <h2 class="form-title">
            {{ componentText.title }}
          </h2>
          <b-form-group
            id="input-group"
            :label="componentText.password"
            label-for="newPassword"
          >
            <div class="password-wrap">
              <b-form-input
                :type="passwordFieldType"
                v-model="$v.newPassword.$model"
                id="newPassword"
                required
              ></b-form-input>
              <div class="eye" @click="switchVisibility">
                <font-awesome-icon
                  v-if="passwordFieldType === 'password'"
                  icon="eye"
                ></font-awesome-icon>
                <font-awesome-icon v-else icon="eye-slash"></font-awesome-icon>
              </div>
            </div>
            <b-form-invalid-feedback id="password-error">
              {{ componentText.passInvalid }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group class="col-md-12 validationBox">
            <span :class="[tenChars?'passed':'failed']"><i class="fa-light fa-xmark" v-if="!tenChars"></i><i class="fa-light fa-check" v-else></i> At least 10 characters</span><br>
            <span :class="[upperCase?'passed':'failed']"><i class="fa-light fa-xmark" v-if="!upperCase"></i><i class="fa-light fa-check" v-else></i> At least 1 uppercase letter</span><br>
            <span :class="[lowerCase?'passed':'failed']"><i class="fa-light fa-xmark" v-if="!lowerCase"></i><i class="fa-light fa-check" v-else></i> At least 1 lowercase letter</span><br>
            <span :class="[contNumber?'passed':'failed']"><i class="fa-light fa-xmark" v-if="!contNumber"></i><i class="fa-light fa-check" v-else></i> At least 1 number</span><br>
            <span :class="[specChar?'passed':'failed']"><i class="fa-light fa-xmark" v-if="!specChar"></i><i class="fa-light fa-check" v-else></i> At least 1 special character</span>
          </b-form-group>
          <b-form-group
            id="input-group-repeat"
            :label="componentText.repeat"
            label-for="repeatPassword"
          >
            <div class="password-wrap">
              <b-form-input
                :type="passwordFieldType"
                v-model="$v.repeatPassword.$model"
                id="repeatPassword"
                required
                @keyup.enter.native="submitForm"
              ></b-form-input>
              <div class="eye" @click="switchVisibility">
                <font-awesome-icon
                  v-if="passwordFieldType === 'password'"
                  icon="eye"
                ></font-awesome-icon>
                <font-awesome-icon v-else icon="eye-slash"></font-awesome-icon>
              </div>
            </div>
          </b-form-group>
          <b-form-group class="col-md-12 validationBox">
            <span :class="[$v.repeatPassword.sameAsPassword?'passed':'failed']"><i class="fa-light fa-xmark" v-if="!$v.repeatPassword.sameAsPassword"></i><i class="fa-light fa-check" v-else></i> Passwords match</span>
          </b-form-group>
          <div
            col-sm-12
            class="submit-form d-flex align-items-center justify-content-center"
          >
            <b-button
                :disabled="buttonDisabled"
                class="cta-button py-3"
                type="button"
                @click.stop.prevent="submitForm"
                block
              >{{ componentText.buttonText }}</b-button
            >
          </div>
        </div>
      </div>
    </div>
    <div class="back" @click="$router.push('/')">Back to Login</div>
    <popup
      v-if="showPopup"
      :content="popupContent"
      class="popupV2"
      v-on:close="hidePopup"
    ></popup>
  </div>
</template>

<script>
import axios from "axios";
import config from "../../config/constants";
import popup from "../Popup.vue";
import { validationMixin } from "vuelidate";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
export default {
  components: {
    popup,
  },
  name: "ResetPasswordV2",
  mixins: [validationMixin],
  data() {
    return {
      newPassword: "",
      repeatPassword: "",
      showPopup: false,
      popupContent: {},
      passwordFieldType: "password",
      tenChars: false,
      upperCase: false,
      lowerCase: false,
      contNumber: false,
      specChar: false,
      pw_flag: false
    };
  },
  validations: {
    newPassword: {
      required,
      minLength: function(value) {
        this.tenChars = value.length > 9;
        return value.length > 9;
      },
      containsUppercase: function(value) {
        this.upperCase = /[A-Z]/.test(value);
        return /[A-Z]/.test(value);
      },
      containsLowercase: function(value) {
        this.lowerCase = /[a-z]/.test(value);
        return /[a-z]/.test(value);
      },
      containsNumber: function(value) {
        this.contNumber = /[0-9]/.test(value);
        return /[0-9]/.test(value);
      },
      containsSpecial: function(value) {
        this.specChar = /[^a-zA-Z0-9\-\/]/.test(value);
        return /[^a-zA-Z0-9\-\/]/.test(value);
      }
    },
    repeatPassword: {
      sameAsPassword: sameAs("newPassword"),
    },
  },
  methods: {
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    submitForm() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      } else {
        this.sendReset();
      }
    },
    async sendReset() {
      let params = this.$route.query;

      const payload = {
        email: params.id,
        auth_key: params.auth_key,
        password: this.newPassword,
        password_repeat: this.repeatPassword,
        lang: this.$store.state.lang,
      };
      const options = {
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=utf-8",
        },
      };
      let res = await axios.post(
        config.api_env + "/application/api-hc/reset-password",
        payload,
        options
      );
      if (res.data.success) {
        this.popupContent = {
          header: this.componentText.successTitle,
          message: this.componentText.success,
        };
        setTimeout(() =>{
          this.$emit("reset-pass");
        }, 3000)

      } else {
        this.popupContent = {
          header: this.componentText.error,
          message: this.passwordErrorText + '<br><br>Current Error:<br>' + res.data.err,
        };
      }
      this.showPopup = true;
    },
    hidePopup(){
      this.showPopup = false;
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
  },
  computed: {
    ...mapGetters(["hardcoded", "skin"]),
    buttonDisabled() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return true;
      }
      return false;
    },
    getLogo() {
      if (this.hardcoded && this.hardcoded.logo) {
        return this.hardcoded.logo;
      } else return this.$store.getters.client.client_logo;
    },
    componentText() {
      return this.getText.notLoggedIn.resetPassword;
    },
    passwordErrorText(){
      return this.getText.notLoggedIn.signUp.errorMessage
    }
  },
};
</script>
<style lang="less">
#ResetPasswordV2 {
  width: 100%;
  padding: 30px 60px;
  height: 100%;
  div, img {
    width: 100%;
  }
  .form-logo {
    width: 50%;
    margin: auto;
    display: block;
    margin-bottom: 30px;
  }
  .eye {
    width: 30px;
    position: absolute;
    bottom: 5px;
    right: 15px;
    font-size: 20px;
  }
  .form-group {
    position: relative;
  }
  label {
    font-size: 1.4rem;
  }
  input, .form-control {
    all: unset;
    padding: 5px;
    height: 25px;
    width: 100%;
    position: relative;
    margin: 0;
    font-family: 'DMSans', sans-serif;
    font-size: 1.4rem;
    background-color: transparent !important;
    border-bottom: #DCDBDD 1px solid;

    &:active, &:focus {
      border-image: linear-gradient(90deg, #ED3A75 0%, #6A356A 100%) 100 !important;
      //border-bottom: transparent;
      border-width: 1px;
      border-style: solid;
      border-top: none;
      border-left: none;
      border-right: none;
    }
  }
  .cta-button {
    width: 100%;
    border-radius: 13px !important;
    padding: 15px 0 !important;
    font-family: "FilsonPro", sans-serif;
    font-size: 1.75rem;
    background: linear-gradient(90deg, #ED3A75 0%, #6A356A 100%);
    &.disabled {
      background: grey !important;
    }
  }
  .back {
    margin-top: 40px;
    text-align: center;
    font-size: 2rem;
    cursor: pointer;
    &:hover {
      background: linear-gradient(90deg, #ED3A75 0%, #6A356A 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .pop-up-content {
    background-color: #4b4949;
    button:hover {
      background: linear-gradient(90deg, #ED3A75 0%, #6A356A 100%);
    }
    .close-popup {
      padding: 5px !important;
    }
  }
}
</style>
