<template>
    <OauthAdditionalDetails v-if="$store.getters.temporaryToken"/>
</template>

<script>
import OauthAdditionalDetails from "@/components/OauthAdditionalDetails.vue";

export default {
    name: "OAuthGoogle",
  components: {OauthAdditionalDetails},
    data() {
        return {};
    },
    mounted() {
        const cookieDomainName = this.getCookie('slDomainName');
        if(cookieDomainName && !this.$route.query.redirected) {
          window.location.href='https://'+cookieDomainName+this.$route.fullPath+"&redirected=true";
        } else {
          this.authenticate();
        }
    },
    computed: {
      cookieDomain() {
        const domainName = window.location.hostname;
        let startIndex = process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'development' ? domainName.indexOf("spectrum") : domainName.indexOf("surge-qa");
        return domainName.substring(startIndex);
      },
      accessDenied() {
        return this.$route.query.error === 'access_denied';
      },
    },
    methods: {
        getCookie(cookieName) {
          const name = `${cookieName}=`;
          const decodedCookie = decodeURIComponent(document.cookie);
          const cookieArray = decodedCookie.split(';');

          for (let i = 0; i < cookieArray.length; i++) {
            let cookie = cookieArray[i].trim();
            if (cookie.indexOf(name) === 0) {
              return cookie.substring(name.length, cookie.length);
            }
          }

          // Return null if the cookie with the given name is not found
          return null;
        },
        async authenticate() {
            let payload = {
                code: this.getParameterFromURL("code"),
                scope: this.getParameterFromURL("scope"),
            };

            let v3_ready = await this.$store.dispatch("googleAuthenticate", {
                router: this.$router,
                payload,
                newLogin: true
            });
            if (v3_ready === false) {
                this.$emit("not-v3");
            }
        },
        getParameterFromURL(paramName) {
            const params = new URLSearchParams(window.location.search);
            return params.get(paramName);
        }
    }
};
</script>