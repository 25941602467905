<template>
  <div id="BupaDemoCard">
    <div class="textContainer">
      <div class="bupaTitle">Mindset Coaching</div>
      <div class="desc">Our Mindset coaches help you to recognise, question & revise your existing beliefs and patterns of thinking.</div>
      <div class="btnWrap">
        <div class="bupaBtn">Book Now</div>
      </div>

    </div>
    <img :src="img" class="bupaImg"/>
  </div>
</template>

<script>
export default {
  name: "BupaDemoCard",
  data() {
    return {
      img: '/img/bupa-test-img.png'
    }
  }
}
</script>

<style lang="less">
#BupaDemoCard {
  width: 600px;
  margin-top: 20px;
  aspect-ratio: 16 / 7;
  overflow: hidden;
  position: relative;
  box-shadow: 1px 8px 15px rgba(0, 0, 0, 0.4);
  .bupaImg {
    position: absolute;
    aspect-ratio: 265 / 260;
    z-index: 1;
    height: 120%;
    margin-top: -20px;
    top: 0;
    right: 0;
  }
  .textContainer {
    padding-top: 20px;
    padding-left: 30px;
    position: absolute;
    top: 0;
    left: 0;
    clip-path: polygon(0 0, 100% 0, 79% 100%, 0% 100%);
    background-color: white;
    height: 100%;
    width: 60%;
    color: #3A4A61;
    z-index: 2;
    .bupaTitle {
      font-size: 2.75rem;
      margin-top: 20px;
      font-family: FilsonProBold, Arial, Helvetica, sans-serif;
    }
    .desc {
      margin-top: 15px;
      font-size: 1.5rem;
      line-height: 1.9rem;
      width: 85%;
    }
    .bupaBtn {

      padding: 7px 15px;
      width: 175px;
      color: white;
      text-align: center;
      background: #0079C8;
      border-radius: 20px;
      border: white 1px solid;
      cursor: pointer;
    }
    .btnWrap {
      margin-top: 10px;
      padding: 2px;
      //border: #d2d1d1 1px solid;
      width: fit-content;
      border-radius: 20px;
      box-shadow: 1px 8px 15px rgba(0, 0, 0, 0.4);
    }
  }
}
</style>