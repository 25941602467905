<template>
    <div id="RecipeBook">
        <div class="content-container row py-5">
            <div class="search-bar col-12">
                <b-form-input
                        v-model="searchQuery"
                        type="text"
                        :placeholder="componentText.search">
                </b-form-input>
                <b-button-group class="filter-buttons row my-5" fluid>
                    <b-button @click="filterType('breakfast')" :class="{active: selectedCategory==='breakfast'}" class=" filter-button px-5 px-md-5 py-3 mx-2 mx-md-3 my-2">{{componentText.breakfast}}</b-button>
                    <b-button @click="filterType('lunch')" :class="{active: selectedCategory==='lunch'}" class=" filter-button px-5 px-md-5 py-3 mx-2 mx-md-3 my-2">{{componentText.lunch}}</b-button>
                    <b-button @click="filterType('dinner')" :class="{active: selectedCategory==='dinner'}" class=" filter-button px-5 px-md-5 py-3 mx-2 mx-md-3 my-2">{{componentText.dinner}}</b-button>
                    <b-button @click="filterType('snack')" :class="{active: selectedCategory==='snack'}" class=" filter-button px-5 px-md-5 py-3 mx-2 mx-md-3 my-2">{{componentText.snack}}</b-button>

                    <b-button @click="filterDiet('gluten_free')" :class="{active: dietFilter==='gluten_free'}" class=" filter-button px-5 px-md-5 py-3 mx-2 mx-md-3 my-2">{{componentText.gluten}}</b-button>
                    <b-button @click="filterDiet('vegetarian')" :class="{active: dietFilter==='vegetarian'}" class=" filter-button px-5 px-md-5 py-3 mx-2 mx-md-3 my-2">{{componentText.vegetarian}}</b-button>
                    <b-button @click="filterDiet('vegan')" :class="{active: dietFilter==='vegan'}" class=" filter-button px-5 px-md-5 py-3 mx-2 mx-md-3 my-2">{{componentText.vegan}}</b-button>

                    <b-button @click="filterFavourite() " :class="{active: favouriteOnly}" class=" filter-button px-5 px-md-5 py-3 mx-2 mx-md-3 my-2" v-if="!$store.getters.user.access_code_user">{{componentText.favourites}}</b-button>
                    <b-button @click="filterRecommended()" :class="{active: recOnly}" class="filter-button px-5 px-md-5 py-3 mx-2 mx-md-3 my-2" v-if="!$store.getters.user.access_code_user"
                    >{{componentText.recommended}}</b-button>
                    <b-button @click="filterReset('all')" :class="{active: showAll}" class=" filter-button px-5 px-md-5 py-3 mx-2 mx-md-3 my-2">{{componentText.all}}</b-button>
                </b-button-group>
            </div>
            <div class="col-12">
                <div class="section-header" ref="recipeSectionHeader">
                    <div class="section-header-divider"></div>
                    <h3 class="section-header-title">{{resultCount}} {{componentText.results}}</h3>
                </div>
            </div>
            <div class="col-12 page-filter">
                <b-button-group class="cta-button d-inline-flex mb-5 py-2 px-3">
                    <b-button v-if="page" @click="previousPage" class="cta-button"><font-awesome-icon icon="chevron-left" ></font-awesome-icon></b-button>
                    <span class="btn">{{page + 1}} {{componentText.of}} {{pages}}</span>
                    <b-button v-if="page +1 < pages" @click="nextPage" class="cta-button"><font-awesome-icon icon="chevron-right" ></font-awesome-icon></b-button>
                </b-button-group>
            </div>
            <div v-for="(recipe, i) in recipes" :key="i"
                 class="col-lg-4 col-md-6 col-sm-12 recipe-wrap">
                <div @click="recipeNav(recipe.recipe_id)" class="recipe-card bg-brand-secondary">
                    <b-row class="mx-0 h-100">
                        <div class="col-6 px-0 recipe-image-wrap">
                            <div v-show="recipe.recommended" class="nutrition__card-recommended-wrap">
                                <img src="/img/recommended.svg" alt="1" class="nutrition__card-recommended">
                            </div>
                            <img :src="recipe.image_url">
                        </div>
                        <div class="col-6 px-0 recipe-card-content">
                            <div class="quarantine">
                                <img v-for="(allergen,i) in recipe.allergens"
                                     :key="i"
                                     :src="allergenImageThemed(allergen.allergen.split(' ')[0].toLowerCase())"
                                     @error="allergenImageDefault($event, allergen.allergen.split(' ')[0].toLowerCase())"
                                >
                            </div>
                            <div class="recipe-title" v-html="recipe.recipe_name">
                            </div>
                            <div class="recipe-calories mb-4">
                                {{componentText.cals}}: {{recipe.calories}}
                            </div>
                        </div>
                    </b-row>
                </div>
                <a @click="favouriteRecipe(recipe.recipe_id, recipe.favourite, i)" v-if="!$store.getters.user.access_code_user" class="recipe-heart">
                    <img v-if="!recipe.favourite" :src="'/img/nutrition/food/' + $store.getters.skin + '/heart-line.svg'" onerror="javascript:this.src='/img/nutrition/food/heart-line.svg'">
                    <img :src="'/img/nutrition/food/' + $store.getters.skin + '/heart.svg'" onerror="javascript:this.src='/img/nutrition/food/heart.svg'" v-else>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import axios from "axios";
    import config from "../../config/constants";
    import { mapGetters } from "vuex"
    export default {
        name: "RecipeBook",
        components: {

        },
        data() {
            return {
                searchQuery: '',
                selectedCategory: 'all',
                dietFilter: 'all',
                showAll: true,
                favouriteOnly: 0,
                recOnly: 0,
                recipes: {},
                resultCount: '',
                page: 0,
                pages: '',
                showPopup: false,
            };
        },
        mounted() {
            if(this.$route.query.filter) {
              this.setFilter();
            } else {
              this.getRecipeList();
            }
        },
        methods: {
            setFilter() {
              switch (this.$route.query.filter.toLowerCase()) {
                case 'breakfast':
                  this.filterType('breakfast');
                  break;
                case 'lunch':
                  this.filterType('lunch');
                  break;
                case 'dinner':
                  this.filterType('dinner');
                  break;
                case 'snack':
                  this.filterType('snack');
                  break;
                case 'gluten-free':
                  this.filterDiet('gluten_free');
                  break;
                case 'vegetarian':
                  this.filterDiet('vegetarian');
                  break;
                case 'vegan':
                  this.filterDiet('vegan');
                  break;
              }
              this.getRecipeList();
            },
            async getRecipeList(){
                const payload = {
                    user_id: this.$store.getters.user_id,
                    channel: "surge",
                    search_key: this.searchQuery,
                    types: this.selectedCategory,
                    diet_filter: this.dietFilter,
                    rec_only: this.recOnly,
                    fav_only: this.favouriteOnly,
                    page: this.page,
                    page_size: 12,
                }
                let res = await axios.post(config.api_env + "/application/api-hc/get-recipe-list", payload, config.options)
                this.recipes = res.data.recipes
                let recommended = this.recipes.filter( recipe => recipe.recommended)
                this.resultCount = res.data.count
                this.pages = Math.ceil(res.data.count / 12);
                if(this.$route.query.filter) this.scrollToRef('recipeSectionHeader');
            },
            async favouriteRecipe(id, favourite, i){
                const payload = {
                    user_id: this.$store.getters.user_id,
                    type: "recipe",
                    resource_id: id
                }
                let res
                if(favourite === 1){
                    this.recipes[i].favourite = 0
                    res = await axios.post(config.api_env + "/application/api-hc/unlike-item", payload, config.options)
                }else{
                    this.recipes[i].favourite = 1
                    res = await axios.post(config.api_env + "/application/api-hc/like-item", payload, config.options)
                }
            },
            previousPage(){
                this.page = this.page -1
                this.getRecipeList()
            },
            nextPage(){
                this.page = this.page +1
                this.getRecipeList()
            },
            filterType(type){
                this.selectedCategory = type
                this.dietFilter = 'all'
                this.showAll = false
                this.favouriteOnly = 0
                this.recOnly = 0
                this.getRecipeList()
            },
            filterDiet(diet){
                this.dietFilter = diet
                this.selectedCategory = 'all'
                this.showAll = false
                this.favouriteOnly = 0
                this.recOnly = 0
                this.getRecipeList()
            },
            filterRecommended(){
                this.recOnly = 1
                this.dietFilter = 'all'
                this.selectedCategory = 'all'
                this.favouriteOnly = 0
                this.showAll = false
                this.getRecipeList()
            },
            filterFavourite(){
                this.favouriteOnly = 1
                this.recOnly = 0
                this.dietFilter = 'all'
                this.selectedCategory = 'all'
                this.showAll = false
                this.getRecipeList()
            },
            filterReset(){
                this.favouriteOnly = 0
                this.recOnly = 0
                this.dietFilter = 'all'
                this.selectedCategory = 'all'
                this.showAll = true
                this.getRecipeList()
            },
            recipeNav(id){
                this.$router.push('/recipe?rec='+id)
            },
            allergenImageThemed(allergen){
                return '/img/nutrition/food/' + this.skin + '/' + allergen.toLowerCase() + '.svg'
            },
            allergenImageDefault(event, allergen){
                event.target.src = '/img/nutrition/food/' + allergen.toLowerCase() + '.svg'
            },

        },
        watch:{
            searchQuery(){
                this.getRecipeList()
            }
        },
        computed:{
            ...mapGetters(['hardcoded', 'skin']),
            heartLine(){
                if(this.hardcoded.heart_line){
                    return this.hardcoded.heart_line
                }else return "/img/heart-line.svg"
            },
            heart(){
                if(this.hardcoded.heart){
                    return this.hardcoded.heart
                }else return "/img/heart.svg"
            },
            componentText() {
                return this.getText.loggedIn.nutrition.recipeBook;
            },
        },
    };
</script>
<style lang="less">
    #RecipeBook {
        .search-bar{
            input {
                max-width: 670px;
                margin: auto;
                border-radius: 25px;
                height: 50px;
                font-size: 2.4rem;
                font-family: "DMSans", sans-serif;
                padding: 0 30px 5px 30px;
            }
        }
        .filter-buttons{
            width: 100%;
            margin: auto;
            font-family: DMSans;
            justify-content: center;
            .filter-button{
                font-family: DMSans;
                border-width: 1px;
                border-style: solid;
                flex-grow: 0;
                &.active{
                    font-family: FilsonProBold;
                }
            }
            .filter-button:hover {
                font-family: FilsonProBold;
            }
        }
        .page-filter{
            text-align: right;
            .btn-group{
                border-radius: 25px;
                font-family: DMSans;
                display: flex;
                align-items: center;
                span{
                    font-size: 2rem;
                    color: #fff;
                    font-family: DMSans, sans-serif;
                }
            }
        }
        .section-header-title{
            font-family: DMSans, sans-serif;
            font-size: 1.8rem;
        }
        .recipe-wrap {
            position: relative;
            cursor: pointer;
            .recipe-heart {
                top: 15px;
                right: 40px;
                width: 21px;
                height: 18px;
                z-index: 10;
                position: absolute;
                img {
                    width: 21px;
                    height: 18px;
                }
            }
        }
        .recipe-card{
            font-family: FilsonProBlack;
            color: #fff;
            position: relative;
            margin-right: 30px;
            margin-bottom: 25px;
            height: 210px;
            border-bottom-left-radius: 10px;
            border-top-left-radius: 10px;
            z-index: 0;
            &:before{
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                height: 37px;
                width: 35px;
                display: inline-block;
                transform: skew(40deg) translateX(50%);
                z-index: 1;
                border-bottom-right-radius: 10px;
                border-top-right-radius: 5px;
            }
            &:after{
                content: "";
                position: absolute;
                right: 1px;
                bottom: 0;
                height: calc(100% - 30px);
                width: 29px;
                transform: translateX(100%);
                border-bottom-right-radius: 10px;
                border-top-right-radius: 5px;
            }
            .recipe-image-wrap {
                max-height: 100%;
                overflow: hidden;
                img {
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    border-bottom-left-radius: 10px;
                    border-top-left-radius: 10px;
                }
            }
            .recipe-card-content{
                position:relative;
                .quarantine {
                    position: absolute;
                    top: 15px;
                    right: 25px;
                    min-width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    height: 15px;
                    img, object{
                        display: block;
                        max-width: 22px;
                        max-height: 22px;
                        width: 100%;
                        height: 22px;
                        margin-left: 4px;
                        margin-top: -2px;
                    }
                }
                .recipe-title{
                    font-family: 'FilsonProBlack', sans-serif;
                    font-size: 1.6rem;
                    position: relative;
                    padding-top: 10px;
                    margin: 60px 0 15px 15px;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    &:before{
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 15px;
                        height: 1px;
                        background: #fff;
                    }
                }
                .recipe-calories{
                    font-family: FilsonProBold, sans-serif;
                    font-size: 1.5rem;
                    letter-spacing: 1px;
                    width: 100%;
                    padding: 6px 15px;
                    position: absolute;
                    bottom: 0;
                }
            }
        }
        .nutrition__card-recommended-wrap {
            position: absolute;
            left: -1px;
            padding: 0;
            width: 50px;
            height: 40%;
            border-top-left-radius: 10px !important;
        }
        .nutrition__card-recommended {
            position: relative;
            height: 100% !important;
            border-bottom-left-radius: 0 !important;
        }
    }
</style>
