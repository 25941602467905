<template>
  <div id="TherapyDashboard">
    <div ref="top"></div>
    <div class="content-container">
      <div class="my-2">
        <button class="therapyBtn2 mb-4 ml-2" style="max-width: 170px" @click="$router.push('/start')">Start</button>
        <div>
          <div v-if="loading" style="display: flex; justify-content: center; align-items: center">
            <b-spinner label="Spinning" style="width: 5rem; height: 5rem; font-size: 25px"/>
          </div>
          <div v-else-if="$route.params.slug === 'cmh_assessment'">
            <TherapyLandingPage :service="service"/>
          </div>
          <div v-else-if="agreementView">
            <TherapyTerms :age_group="service.age_group" :therapy_type="service.pathway_type" :service_name="service.name"/>
          </div>
          <div v-else-if="view_intake_form">
            <TherapyForm @formSaved="service.intake_form_completed = true"
                :age_group="service.age_group" :therapy_type="service.pathway_type" form_type="intake" :service_name="service.name"/>
          </div>
          <div v-else-if="service.pathway_type === 'Therapy'">
            <TherapyLandingPage :service="service"/>
          </div>
          <div v-else-if="service.pathway_type === 'Neurodiversity'">
            <NeurodiversityLandingPage :age_group="service.age_group" :therapy_type="service.pathway_type" :service="service"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TherapyTerms from "@/components/TherapyPortal/TherapyTerms.vue";
import TherapyForm from "@/components/TherapyPortal/TherapyForm.vue";
import TherapyLandingPage from "@/components/TherapyPortal/TherapyLandingPage.vue";
import NeurodiversityLandingPage from "@/components/TherapyPortal/Neurodiversity/NeurodiversityLandingPage.vue";
import Eventbus from "@/helpers/eventbus";

export default {
  name: "TherapyDashboard",
  components: {NeurodiversityLandingPage, TherapyLandingPage, TherapyForm, TherapyTerms},
  data() {
    return {
      loading: true,
      view_intake_form: false,
      viewAgreement: false,
      service: {
        name: null,
        slug: null,
        agreement_complete: false,
        intake_form_completed: false,
        initial_appointment_date: null,
        no_of_additional_forms_required: 0,
        no_of_additional_forms_completed: 0,
        date_of_next_appointment: null,
        service_complete_date: null,
        pathway_type: null,
        age_group: null,
        appointments: {
          past: [],
          upcoming: []
        }
      }
    }
  },
  computed: {
    agreementView() {
      return this.viewAgreement || !this.service.agreement_complete;
    },
    slug() {
      return this.$route.params.slug;
    }
  },
  mounted() {
    Eventbus.$on('toggleViewAgreement', (isComplete = false, close = false)=> {
      if(close) {
        this.viewAgreement = false;
      } else {
        this.viewAgreement = !this.viewAgreement
      }
      this.service.agreement_complete = isComplete;
    });
    Eventbus.$on('toggleIntakeForm', ()=> {this.view_intake_form = !this.view_intake_form});
    this.getData();
  },
  methods: {
    async getAgreement() {
      const res = await this.api({
        path: 'api/u/cmh/get-agreement'
      })
      if(res.success) {
        if(res.data.length) {
          res.data.forEach(agreement => {
            if (agreement.service_category_slug === this.$route.params.slug) {
              this.service.agreement_complete = true;
            }
          })
        }
      }
      this.loading = false
    },
    async getData() {
      const res = await this.api({
        path: 'api/u/cmh/get-service',
        gl_category_slug: this.$route.params.slug
      })
      this.service = res;
      await this.getAgreement();
    }
  },
  watch: {
    '$route.params.service': {
      handler: function (val) {
        this.viewAgreement = false;
        this.getData();
      }
    },
    view_intake_form() {
      this.$refs.top.scrollIntoView({behavior: 'smooth'});
    }
  }
}
</script>
<style lang="less">
#TherapyDashboard {

}
</style>