<template>
    <div id="HomeFilters">
        <div v-if="loading" class="pb-3 container d-flex flex-row justify-space-between align-items-center">
          <HomeLoading/>
        </div>
        <div v-else>
          <div class="pb-3 container d-flex flex-row justify-space-between align-items-center">
              <div>
                  <h2 class="home-title">Filter</h2>
                  <div class="home-title-line bg-brand-secondary"></div>
              </div>

          </div>
          <div class="container my-3">
            <div class="row position-relative">
              <b-input class="filterSearchBar mx-auto brand-text" v-model="searchTerm" placeholder="Search..." />
              <i class="fa-solid fa-magnifying-glass position-absolute" style="top: 15px; right: 30px; font-size: 2.0rem" v-if="!searchTerm.length"></i>
              <span @click="searchTerm = ''" class="position-absolute brand-highlight-1" style="cursor: pointer; top: 15px; right: 30px; font-size: 1.5rem; font-family: FilsonProBold, Arial, Helvetica, sans-serif; text-decoration: underline" v-else>Clear Search</span>
            </div>

          </div>
          <div class="container mb-5">
              <div class="row">
                  <div class="tagContainer">
                      <span v-if="searchTerm" class="brand-text suggested">Suggested:</span>
                      <b-button class="mx-2 my-2 btn" :variant="selectedFilter.id === null ? 'cta' : 'cta-outline'" @click="clearFilter()" v-if="!searchTerm">All</b-button>
                      <b-button :key="'tag'+i" class="mx-2 my-2 btn" :variant="selectedFilter.id === tag.id ? 'cta' : 'cta-outline'" @click="selectFilter(tag)" v-for="(tag,i) in tags_to_display">{{tag.tag}}</b-button>
                      <span v-if="filteredTags.length > unexpandedNumber" class="seeMoreBtn brand-highlight-1 m-2" @click="expanded = !expanded">{{expanded ? "...See Less" : "See More..."}}</span>
                  </div>
              </div>
          </div>
        </div>
    </div>
</template>

<script>
import EventBus from "@/helpers/eventbus";
import HomeLoading from "@/components/Home/New/HomeLoading.vue";

export default {
    name: "HomeFilters",
    components: {
      HomeLoading
    },
    data() {
        return {
            selectedFilter: {id: null, tag: 'All'},
            tags: [],
            excludedTags: ['Training Plans','Courses','Surge','Draft','Ignite','Healthcoach','Try One Of These'],
            hardCodedTags: ["Family", "Parenting","Awareness","Female Health","Mental Health","Meditation","Mindfulness","Neurodiversity","Sleep","Stress","Work life Balance","Yoga","Pilates","Cardio"],
            loading: true,
            expanded: false,
            unexpandedNumber: 14,
            searchTerm: ""
        };
    },
    mounted() {
        this.getTags();
        this.getContent();
    },
    computed: {
        filteredTags() {
          if(!this.searchTerm) return this.tags;
          return this.tags.filter(item => item.tag.toLowerCase().includes(this.searchTerm.toLowerCase()))
        },
        tags_to_display() {
          if(this.expanded) return this.filteredTags;
          const tags = this.filteredTags.slice(0,this.unexpandedNumber);
          if(this.selectedFilter.id) {
            let isSelectedFilterInTags = false;
            tags.forEach(t => {
              if(t.id === this.selectedFilter.id) isSelectedFilterInTags = true;
            })
            if(!isSelectedFilterInTags) {
              tags.pop();
              tags.push(this.selectedFilter)
            };
          }
          return tags;
        }
    },
    methods: {
        async getTags(){
            let res = await this.api({
                path: "api/u/content/tags",
            });
            if (res.success) {
                this.tags = [];
                let t = Array.isArray(res.data) ? res.data : Object.values(res.data);
                const tags = this.hardCodedTags.concat(t)
                tags.forEach((tag,i) => {
                    if(!this.excludedTags.includes(tag)) {
                      let include = true;
                      this.tags.forEach(item => {
                        if(item.tag.toLowerCase() === tag.toLowerCase()) include = false
                      })
                      if(include) this.tags.push({id: i+1 , tag:tag})
                    }
                });
            }
            this.loading = false;
        },
        clearFilter(){
            this.selectedFilter = {id: null, tag: 'All'}
            EventBus.$emit('setTag', null)
        },
        selectFilter(tag){
            this.selectedFilter = tag;
            EventBus.$emit('setTag', tag);
        },
    }
};
</script>

<style lang="less">
#HomeFilters{
  .btn, button {
      min-width: 150px !important;
      font-size: 1.5em;
  }
  .filterSearchBar {
    all: unset;
    width: 98%;
    height: 40px;
    background-color: rgba(250, 250, 250, 1);
    border: 1px solid rgba(211, 211, 211, 1);
    border-radius: 15px;
    padding: 5px 20px;
    font-size: 1.5rem;
    font-family: FilsonProBold, Arial, Helvetica, sans-serif !important;
  }
  .seeMoreBtn {
    background-color: transparent;
    width: 118px;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 1.5rem;
    text-align: center;
    height: 35px;
    font-family: FilsonProBold, Arial, Helvetica, sans-serif;
  }
  .suggested {
    padding: 10px 15px;
    font-size: 1.5rem;
    height: 35px;
    font-family: FilsonProBold, Arial, Helvetica, sans-serif;
  }
}
</style>
