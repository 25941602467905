<template>
  <div class="container login">
    <div class="row page-content">
      <div class="col-md-7 login-bg img-part px-0" :class="{'bg-brand-secondary' : skin > 0, 'bg-grad-image-purple' : skin === 0}">
        <div class="login-bg">
          <div class="heart-placeholder">
            <img v-if="hardcoded.spectrum_heart" class="heart-img" :src="hardcoded.spectrum_heart" />
            <img class="heart-img" src="/img/default-asset.svg" v-else/>
          </div>
          <div class="forgot-pass-image"></div>
        </div>
      </div>
      <div class="col-md-5 form min-height">
        <div v-if="hardcoded.login_logos" class="logos d-flex pt-5">
          <div v-for="(logo, i) in hardcoded.login_logos" :key="i" class="d-flex logo px-1">
            <img :src="logo" />
          </div>
        </div>
        <div class="form-content">
          <div
            :class="[
              ($store.getters.client.client_logo.toLowerCase().includes('spectrum-life-logo') ||  $store.getters.client.client_logo.toLowerCase().includes('lloydwhyte')) && skin === 15
                ? 'lw-form-logo'
                : 'form-logo',
            ]"
          >
            <img
              :src="getLogo"
              onerror="javascript:this.src='/img/logo-spectrum-life.svg'"
            />
          </div>
          <h2 class="form-title">
            {{componentText.title}}
          </h2>
            <b-form-group
              id="input-group-1"
              :label="componentText.email"
              label-for="inputEmail"
            >
              <b-form-input
                type="email"
                v-model="$v.user.email.$model"
                :state="$v.user.email.$dirty ? !$v.user.email.$error : null"
                id="inputEmail"
                required
                @keyup.enter.native="submitForm"
              ></b-form-input>
              <b-form-invalid-feedback id="user-name-error">
                {{componentText.emailError}}
              </b-form-invalid-feedback>
            </b-form-group>
            <div col-sm-12 class="submit-form d-flex align-items-center justify-content-center">
              <b-button
                      class="cta-button py-3 "
                      type="button"
                      @click="submitForm"
                >{{componentText.buttonText}}</b-button
              >
            </div>
            <div class="col-12 new-here">
              <a class="text-link" @click="$emit('sign-in')">{{componentText.back}}</a>
            </div>
        </div>
      </div>
    </div>
    <popup v-if=showPopup :content="popupContent" v-on:close="hidePopup"></popup>
  </div>
</template>

<script>
import axios from "axios";
import config from "../../config/constants";
import Popup from "../Popup.vue";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import { mapGetters } from "vuex"
export default {
    components: {Popup},
    name: "login",
    mixins: [validationMixin],
  data() {
    return {
      user:{
        email: ""
      },
        showPopup: false,
        popupContent: {},
    };
  },
    validations: {
        user: {
            email: {
                required,
                email
            },
        }
    },
  methods: {
      submitForm() {
          this.$v.user.$touch();
          if (this.$v.user.$anyError) {
              return;
          }
          this.sendReset();
      },
    async sendReset() {
        //TODO:: setup return domain
      const payload = {
        email: this.user.email,
        // return_domain: process.env.VUE_APP_API_PATH,
        lang: this.$store.state.lang
      };

      let res = await axios.post(
        config.api_env + "/application/api-hc/send-reset",
        payload,
          config.options
      );
      if(res.data.success){
          this.popupContent = {
              header: this.componentText.successTitle,
              message: res.data.message,
              buttonText: 'OK'
          }
      }else{
          this.popupContent = {
              header: this.componentText.errorTitle,
              message: this.componentText.errorMessage,
          }
      }
      this.showPopup = true
    },
      hidePopup(){
          this.showPopup = false
          this.$emit('reset-failed')
      }
  },
    computed:{
        ...mapGetters(['hardcoded', 'skin']),
      getLogo(){
        if(this.hardcoded && this.hardcoded.logo){
          return this.hardcoded.logo
        }else return this.$store.getters.client.client_logo
      },
      componentText(){
        return this.getText.notLoggedIn.forgotPassword
      }
    }
};
</script>
<style lang="less"></style>
