<template>
  <div id="VisualAudioDisplay" ref="VisualAudioDisplay" @mouseover="hovering = true" @mouseleave="hovering = false">
    <div v-if="includeMarkCompleteBtn" @click="ended" class="completeBtn" :class="{hovering: hovering}">Mark as Complete</div>
    <LiveStatsDemo v-if="isDemo"/>
    <ParticipantsDemo v-if="isDemo"/>
    <HighFiveDemo v-if="isDemo" />
    <div id="vl-inner-container">
      <div class="video-container" v-if="type === 'video'">
          <GAVideoPlayer
            :disable-picture-in-picture="true"
            :video-class="'video'"
            :src="url"
            :progress="nonVimeoVideoProgressHandler"
            @ended="ended"
            ref="video"
          />
      </div>
      <div class="audio-container" v-if="type === 'audio'">
          <CustomAudioPlayer :audio-src="url"
               :disable-next="isLastItem"
               :disable-previous="isFirstItem"
               @next="next"
               @previous="previous"
               @ended="ended"
          />
      </div>
      <div v-if="type === 'embedded_code'" class="video-container" ref="videoContainer">
          <div ref="player" v-if="renderVimeo"></div>
          <div v-else v-html="video_code" id="alt_player" style="width: 100%"></div>
      </div>
    </div>
  </div>
</template>
<script>

  import CustomAudioPlayer from "@/components/NewStudioCourseLayout/Content/CustomAudioPlayer.vue";
  import Vimeo from "@vimeo/player";
  import LiveStatsDemo from "@/components/NewStudioCourseLayout/Content/LiveStatsDemo.vue";
  import ParticipantsDemo from "@/components/NewStudioCourseLayout/Content/ParticipantsDemo.vue";
  import HighFiveDemo from "@/components/NewStudioCourseLayout/Content/HighFiveDemo.vue";
  import {mapGetters} from "vuex";
  import GAVideoPlayer from "../../GAVideoPlayer.vue";
  export default {
      name: 'VideoAudioDisplay',
      components: {
        GAVideoPlayer,
          CustomAudioPlayer,
          LiveStatsDemo,
          ParticipantsDemo,
          HighFiveDemo
      },
      props: {
          url: String,
          id: {
              default: null
          },
          type: {
              type: String,
              default: 'video'
          },
          isFirstItem: {
              type: Boolean,
              default: false
          },
          isLastItem: {
              type: Boolean,
              default: false
          },
          embeddedCode: {
              type: String,
              default: null
          },
          title: {
              type: String,
              default: ''
          },
          narrowDeviceMode: {
              type: Boolean,
              default: false
          }

      },
      data() {
          return {
            vimeoId: 0,
            navWidth: 0,
            renderVimeo: true,
            hovering: false
          }
      },
      watch: {
        embeddedCode() {
            if(this.type === 'embedded_code') {
                this.extractVimeoId();
                this.initVideo();
            }
        }
      },
      mounted() {
        this.navWidth = window.innerWidth - this.$refs.VisualAudioDisplay.clientWidth;
        window.addEventListener('resize',this.resizeVimeo);
        if(this.type === 'embedded_code') {
          this.extractVimeoId();
        }
      },
      destroyed() {
        if(this.player) this.player.destroy();
      },
    computed: {
        ...mapGetters(['user']),
        includeMarkCompleteBtn() {
          return this.$route.name === "course" || this.$route.name === "beCalmCourse" || this.$route.name === "trainingPlan";
        },
        courseProgress() {
          return this.user.user.course_progress;
        },
        isDemo() {
          return process.env.NODE_ENV !== "production" && this.$route.fullPath.includes("digital-gym/3605")
        },
        video_code() {
            return atob(this.embeddedCode)
        },
        videoContainerWidth() {
          return this.$refs.videoContainer.clientWidth;
        },
        channel() {
          if(this.$route.fullPath.includes("training-plan")) return "tp";
          if(this.$route.fullPath.includes("digital-gym")) return "dg";
          if(this.$route.fullPath.includes("wellbeing-studio")) return "ds";
          return 'wb'
        }
      },
      methods: {
          resizeVimeo() {
            let width = window.innerWidth - this.navWidth;
            if(this.narrowDeviceMode) width = window.innerWidth;
            this.player.ready().then(()=> {
                  this.player.element.style.width = `${width}px`;
                  this.player.width = width;
                  this.player.height = "auto";
              });
          },
          initVideo() {
              let width = this.videoContainerWidth;
              if(this.narrowDeviceMode) width = window.innerWidth;
              if(this.player) this.player.destroy();
              if(this.vimeoId === 0) {
                  this.renderVimeo = false;
                  return;
              }
              this.player = new Vimeo(this.$refs.player, {
                  id: this.vimeoId,
                  width: width,
                  controls: true,
                  byline: false,
                  title: false,
                  autoplay: true,
                  portrait: false,
                  pictureInPictureEnabled: false
              });
              const courseProgressIndex = this.courseProgress.findIndex(item => item.channel === this.channel && item.id === this.id);
              this.player.ready().then(()=> {
                if(courseProgressIndex !== -1) {
                  if(this.courseProgress[courseProgressIndex].percent < 0.98) {
                    this.player.setCurrentTime(this.courseProgress[courseProgressIndex].seconds);
                  } else {
                    this.player.setCurrentTime(0);
                  }
                }
                this.player.play();
                this.player.on('ended', ()=> {this.ended()})
                this.player.on('timeupdate', (evt) => this.progressHandler(evt))
              });
          },
          nonVimeoVideoProgressHandler() {
            let progress = {
              channel: this.channel,
              id: this.id,
              seconds: this.$refs.video.currentTime,
              duration: this.$refs.video.duration,
              percent: this.$refs.video.currentTime / this.$refs.video.duration
            }
            this.$store.dispatch('setCourseProgress',progress)
          },
          async progressHandler(evt) {
            let progress = {
              channel: this.channel,
              id: this.id,
              seconds: evt.seconds,
              duration: evt.duration,
              percent: evt.seconds / evt.duration
            }
            await this.$store.dispatch('setCourseProgress',progress);
          },
          extractVimeoId() { // Check if URL is a Vimeo URL
              if(this.video_code && !this.video_code.includes('vimeo')) {
                  this.vimeoId = 0;
              } else {
                  if (!/^https?:\/\/(?:www\.)?vimeo\.com\/\d+(?:\?|$)/i.test(this.video_code)) this.vimeoId = 0;
                  const match = /[0-9]+/.exec(this.video_code);
                  if (!match) {
                      // this.vimeoId = 0;
                  }
                  this.vimeoId = match[0];
              }
              this.initVideo();
          },
          next() {
              this.$emit('ended');
          },
          previous() {
              this.$emit('previous');
          },
          back() {
              if(this.backRoute) this.$router.push(this.backRoute);
          },
          ended() {
            try {
              let progress = {
                channel: this.channel,
                id: this.id,
                seconds: 0,
                duration: 0,
                percent: 1
              }
              this.$store.dispatch('setCourseProgress',progress)
              this.$emit('ended')
            } catch (e) {
              this.$emit('ended')
            }
          }
      }
  }
</script>
<style lang="less">
 #VisualAudioDisplay {
   width: 100% !important;
   position: relative;
   min-width: 400px;
   display: flex;
   align-items: center;
   justify-content: center;
   font-family: DMSans, Arial, Helvetica, sans-serif;
   background-color: black;
   .completeBtn {
     display: none;
     position: absolute;
     top: 15px;
     right: 15px;
     z-index: 1;
     height: fit-content;
     padding: 5px;
     color: white;
     border-radius: 5px;
     font-size: 1.25rem;
     cursor: pointer;
     border: 1px solid white;
     &.hovering {
        display: block;
     }
     &:hover {
       background-color: white;
       color: black;
     }
   }


   #vl-inner-container {
     width: 100%;
     height: auto;
     max-width: 1300px;
     padding: 0 0;
     .back {
       cursor: pointer;
     }
     .top-row {
       display: flex;
       color: #ffffff;

     }
     .video-container {
       display: flex;
       justify-content: center;
       align-items: center;
       height: auto;
       width: 100%;
       max-width: 1300px;
       .video {
          max-height: 100%;
          max-width: 100%;
       }
     }
     .video-container , .audio-container {
        margin: auto;
     }
     .audio-container {
       padding-top: 40px;
       padding-bottom: 60px;
     }
   }
   @media only screen and (max-width: 994px) {
     .completeBtn {
       display: block;
     }
   }
   @media only screen and (max-width: 411px) {
     min-width: 100%;
     .video-container {
       display: block !important;
     }
   }
 }
</style>