<template>
  <div class="neuroCard">
    <img src="/img/therapy/neurocard.png">
    <div class="textHolder">
      <div class="textHolderInner">
        <div class="title py-5">
          {{label}}
        </div>
        <div class="priceFlag">
          Price: <span> {{ this.service_price }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AssessmentCard",
  props: ["label","price","currency"],
  computed: {
    slug() {
      return this.$route.params.slug
    },
    service_price() {
      return this.getCurrencyPriceString(this.currency,this.price)
    }
  }
}
</script>

<style lang="less">
.neuroCard {
  width: 100%;
  max-width: 564px;
  aspect-ratio: 564 / 260;
  border-radius: 20px 0 0 20px;
  overflow: hidden;
  margin: auto;
  position: relative;
  box-shadow: 0 4px 4px 0 #00000040;
  .textHolder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 2;
    clip-path: polygon(0 0, 75% 0, 55% 100%, 0 100%);
    .textHolderInner {
      height: 100%;
      width: 100%;
      padding: 20px;
      position: relative;
    }
    .title {
      font-size: 2rem;
      line-height: 2.75rem;
      width: 65%;
      border-top: 1px solid #F1F1F1;
      border-bottom: 1px solid #F1F1F1;
    }
    .priceFlag {
      position: absolute;
      bottom: 20px;
      left: 0;
      width: 165px;
      height: 40px;
      background: linear-gradient(270deg, #F3F3F3 39.74%, rgba(243, 243, 243, 0) 101.99%);
      clip-path: polygon(0% 0%, 0% 99%, 99% 99%, 82% 49%, 99% 0%);
      display: flex;
      align-items: center;
      justify-content: start;
      padding-left: 20px;
      font-size: 1.4rem;
      span {
        margin-left: 5px;
        font-family: FilsonProBold, Arial, Helvetica, sans-serif;
      }
    }

  }
  img {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 1;
  }
}
@media only screen and (max-width: 992px) {
  .neuroCard {
    max-width: 420px;
  }
}
</style>