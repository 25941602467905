<template>
<div id="NavAppointmentHolder">
  <div class="apptHolderInner">
    <div class="apptTabs" style="position: relative">
      <div class="apptTab" :class="selectedView === 'upcoming'? 'selected':null" @click="selectedView = 'upcoming'">Upcoming</div>
      <div class="apptTab" :class="selectedView !== 'upcoming'? 'selected':null" @click="selectedView = 'past'">Past</div>
      <div style="position: absolute; font-size: 14px; right: 0; top: 7px; cursor: pointer" @click="$emit('close')">X</div>
    </div>

    <div class="listings">
      <div v-if="bookingsToDisplay.length === 0" class="appt">
        <div class="apptTitle py-5" style="border-bottom: none">{{'You have no ' +selectedView+ ' bookings!'}}</div>
      </div>

      <div class="appt" v-for="booking in bookingsToDisplay">
        <div class="apptTitle" @click="goToBooking(booking)">{{getTitle(booking)}}</div>
        
        <div class="apptContent">

          <div class="apptImgHolder img-fluid" v-bind:style="{ backgroundImage: 'url(' + booking.featured_image + ')' }">
          </div>

          <div class="apptTimeAndDate apptText ml-2">{{getDate(booking.start)}} at {{getHoursMinutes(booking.start)}}</div>
          <div v-if="selectedView === 'upcoming'" class="apptStatus apptText ml-2" style="color: green">
            <i class="fa-solid fa-check mr-1 apptIcon" style="color: green"></i>
            Confirmed
          </div>
          <div v-if="selectedView === 'upcoming' && canCancel(booking)" class="apptCancelHolder apptText">
            <div class="cancelBtn" style="position: relative;" @click="cancel(booking)">
              <span v-if="booking.type === bookingBeingCancelled.type && booking.id === bookingBeingCancelled.id" class="button-loading"></span>
              <span v-else>Cancel</span>
            </div>
          </div>
          <div  v-if="selectedView !== 'upcoming'" class="apptText pastBookingText text-center">Past Booking</div>
        </div>
      </div>
    </div>

  </div>
</div>
</template>

<script>
import moment from "moment/moment";
import config from "@/config/constants";

export default {
  name: "NavAppointmentHolder",
  props: ["bookings"],
  data() {
    return {
      selectedView: 'upcoming',
      bookingBeingCancelled: {
        type: null,
        id: null
      }
    }
  },
  methods: {
    goToBooking(booking){
      switch(booking.type){
        case 'dg':
          this.$router.push('/digital-gym/' + booking.id + '?back=home')
          break;
        case 'dc':
          if(booking.is_cmh) {
            this.$router.push('/service-dashboard/'+booking.slug);
          } else {
            this.$router.push('/clinics/digital');
          }
          break;
        case 'tmh':
          this.$router.push('/total-mental-health');
          break;
        case 'corporate':
          this.$router.push('marketplace/events/view-event/'+ booking.event_date_id + '?back=home');
          break;
        case 'ws':
          this.$router.push('/wellbeing-studio/' + booking.id + '?back=home');
          break;
      }
      this.$emit('close');
    },
    async cancel(booking) {
      this.bookingBeingCancelled = {type: booking.type, id: booking.id};
      let path;
      let payload = {
        user_id: this.$store.getters.user_id
      };
      switch (booking.type) {
        case "dg":
          path = "/application/api/u/studio/cancel-reminder";
          payload.event_id = booking.id;
          break;
        case "ws":
          path = "/application/api/u/studio/cancel-reminder";
          payload.event_id = booking.id;
          break;
        case "corporate":
          path = "/application/api/u/events/cancel-date-booking";
          payload.event_date_id = booking.event_date_id;
          payload.all_series = false;
      }
      let res = await this.axios.post(
          process.env.VUE_APP_API_PATH +
          path,
          payload, config.options
      );
      if(res.data.success) {
        this.bookingBeingCancelled = {type: null, id: null}
        this.$emit('reload');
      }
    },
    canCancel(booking) {
      return booking.type === 'dg' || booking.type === 'corporate' || booking.type === 'ws'
    },
    getTitle(booking) {
      if(booking.type === 'tmh') return 'Coaching Session with ' + booking.title;
      if(booking.type === 'dc') return 'Appointment with ' + booking.title;
      return booking.title;
    },
    getDate(str) {
      const now = moment();
      const end = moment(str)
      if(now.format("ddd DD MMM Y") === end.format("ddd DD MMM Y")) return "Today"
      return moment(str).locale(this.$store.state.lang).format("ddd DD MMM Y");
    },
    getShortDate(str) {
      return moment(str).locale(this.$store.state.lang).format("DD MMM YY");
    },
    getHoursMinutes(str, isTwelveHour = false) {
      if (isTwelveHour) return moment(str.replace(" ", "T")).format("h:mm A");
      return moment(str.replace(" ", "T")).format("HH:mm");
    },
  },
  computed: {
    bookingsToDisplay() {
      if(this.selectedView === 'upcoming') {
        return this.futureEvents;
      } else {
        return this.pastEvents;
      }
    },
    pastEvents() {
      return this.bookings.filter(booking => {
        const end = moment(booking.end);
        const now = moment();
        return Boolean(end < now);
      })
    },
    futureEvents() {
      return this.bookings.filter(booking => {
        const end = moment(booking.end);
        const now = moment();
        return Boolean(end > now);
      })
    },
  }
}
</script>

<style lang="less">
#NavAppointmentHolder {
  cursor: default;
  .apptHolderInner {
    background-color: #292824;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 15px;
  }
  .apptTabs {
    display: flex;
    width: 100%;
    color: white;
    border-bottom: 1px white solid;
    .apptTab {
      font-size: 1.4rem;
      margin-right: 15px;
      padding-bottom: 8px;
      &.selected {
        border-bottom: 1px white solid;
        font-family: FilsonProBold, Arial, Helvetica, sans-serif;
      }
    }
  }
  .listings {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px 0;
  }
  .appt {
    flex-direction: column;
    display: flex;
    width: 100%;
    align-items: center;
    padding: 5px;
    font-size: 1.2rem;
    border: 1px #878787 solid;
    .apptTitle {
      width: 100%;
      color: white;
      font-family: FilsonProBold, Arial, Helvetica, sans-serif;
      font-size: 1.4rem;
      line-height: 1.6rem;
      border-bottom: 1px white solid;
      padding: 10px 0;
      cursor: pointer;
    }
  }
  .apptContent {
    display: grid;
    grid-template-row: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 7px 5px;
    width: 100%;
    color: white;
    .apptText {
      display: flex;
      justify-content: start;
      align-items: center;
      width: 100% !important;
    }
    .apptImgHolder {
      grid-row: 1  / span 2;
      grid-column: 1;
      height: 50px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
    .apptTimeAndDate {
      grid-column: 2 / span 2;
      grid-row: 1;
      width: 100%;
      text-align: left;
    }
    .apptStatus {
      grid-column: 2;
      grid-row: 2;
      .apptIcon {
        font-size: 1.2rem !important;
      }
    }
    .apptCancelHolder {
      grid-column: 3;
      grid-row: 2;
      justify-content: center;

      .cancelBtn {
        color: #EF5DA8;
        padding: 5px 15px;
        width: 70px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #CED6E140;
        cursor: pointer;
      }
    }
    .pastBookingText {
      grid-column: 2 / span 2;
      grid-row: 2;
      justify-content: space-evenly
    }
  }
  .button-loading::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 4px solid transparent;
    border-radius: 50%;
    border-top-color: white;
    border-bottom-color: white;
    animation: button-loading-spinner 1s ease infinite;
  }
}
</style>