<template>
<div id="ProgressSliderCard" :class="{selected: selected}">
  <div class="innerTile" @click="$router.push(path)" style="position: relative" :style="{height: tileHeight + 'px'}">
    <div class="tileImg">
      <img :src="course_image_url"/>
    </div>
    <div class="vimeo-wrapper img-fluid video-class bg-img" style="width: 100%; height: 100%; z-index: 200" :class="{showVideo: selected}">
      <div ref="player" style="width: 100%; height: 100%;"></div>
    </div>
    <div class="progress-bar-container">
      <b-progress :value="course_progress" :max="100" class="progressBar w-75">
      </b-progress>
    </div>
  </div>
  <div class="title">{{course_title}}</div>
  <div class="completed">{{Number.parseInt(course_progress)}}% completed</div>
</div>
</template>

<script>
import Vimeo from "@vimeo/player";

export default {
  name: 'ProgressSliderCard',
  props: ['path','tileHeight','course_image_url','course_title','course_progress','embed_code','selected', 'seconds'],
  data() {
    return {
      vimeoId: 0,
    }
  },
  mounted() {
    this.extractVimeoId();
  },
  computed: {
    embed_video() {
      return atob(this.embed_code);
    }
  },
  watch: {
    selected() {
      if(!this.videoIsPlaying) {
        this.playVideo()
      } else {
        this.resetVideo()
      }
    }
  },
  methods: {
    extractVimeoId() { // Check if URL is a Vimeo URL
      if(this.embed_video && !this.embed_video.includes('vimeo')) {
        this.vimeoId = 0;
      } else {
        if (!/^https?:\/\/(?:www\.)?vimeo\.com\/\d+(?:\?|$)/i.test(this.embed_video)) this.vimeoId = 0;
        const match = /[0-9]+/.exec(this.embed_video);
        if (!match) {
          // this.vimeoId = 0;
        }
        this.vimeoId = match[0];
      }
    },
    playVideo() {
      if(this.vimeoId === 0 || this.status === 'not_started' || this.status === 'live') return;
      if(!this.player) {
        this.initVideo()
      }
      this.player.setCurrentTime(this.seconds ?? 0);
      this.player.setMuted(true);
      this.player.play();
    },
    initVideo() {
      this.player = new Vimeo(this.$refs.player, {
        id: this.vimeoId,
        autoplay: false,
        controls: false,
        byline: false,
        title: false,
        portrait: false,
      });
      this.player.on('ready',()=> {
        this.player.setCurrentTime(this.seconds ?? 0);
        this.player.setMuted(true);
      })
    },
    resetVideo() {
      if(this.player) {
        this.player.pause();
      }
    }
  }
}
</script>

<style lang="less">
#ProgressSliderCard {
  .vimeo-wrapper {
    visibility: hidden;
    position: relative;
    * {
      border: none !important;
    }
    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      pointer-events: none;
      border: none !important;
    }
    &.showVideo {
      visibility: visible !important;
    }
  }
  .progress-bar-container {
    bottom: 20px;
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .innerTile {
    border-radius: 10px;
    height: 100%;
    width: 100%;
    transition: opacity 1s ease-out;
    overflow: hidden;
    cursor: pointer;
    background-color: #f1efef;
    opacity: 1;
    &.hiddenCard {
      opacity: 0 !important;
    }
  }
  .tileImg {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    img {
      height: 100%;
    }
  }
  .title {
    margin-top: 5px;
    font-size: 1.4rem;
    font-family: FilsonProBold, Arial, Helvetica, sans-serif;
    text-align: center;
  }
  .completed {
    font-size: 1.2rem;
    text-align: center;
    font-family: FilsonProBold, Arial, Helvetica, sans-serif;
  }
  .progressBar {
    height: 1rem;
    z-index: 100;
    border-radius: 10px !important;
    background: grey;
    div {
      background-color: #0BDA51;
      border-radius: 0 4px 4px 0;
    }
  }
}
</style>