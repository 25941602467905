<template>
    <div id="GpSelect" v-if="true">
        <div class="content-container back-button-container brand-primary animate__animated animate__fadeIn"
            v-if="selectedView !== 'bookingConfirm'">
            <span class="back-button" @click="goBack()"><i class="fa-solid fa-chevron-left"></i> Back </span>
        </div>
        <div class="gp-select-container mb-5 pb-5" ref="gpSelectTop">

            <div class="content-container warning-container d-flex flex-column align-items-start animate__animated animate__fadeIn"
                v-if="selectedView === 'warning'">
                <h3 class="pb-5 brand-text align-self-center">Book a digital appointment</h3>
                <p class="pb-5">Before you start your booking, please read the following information.</p>
                <h4 class="brand-text mb-0 pb-2"><b>Privacy Policy</b></h4>
                <p class="pb-5">
                    We'll use any personal information you provide in line with our <a target="_blank"
                        href="https://www.bupa.co.uk/legal-notices/privacy-and-cookies" class="d-inline">privacy policy</a>.
                </p>
                <h4 class="brand-text mb-0 pb-2"><b>Booking for a child?</b></h4>
                <p class="pb-5">
                    To arrange an appointment for someone under 16, please give a call and we'll be happy to help.<br><br>
                    Lines are open Monday to Friday 8am to 8pm, Saturday 9am to 5pm.
                </p>
                <div class="pb-5 brand-text"><i class="fa-regular fa-phone"></i> <b><a class="phone-text" href="tel:03456040537">Call 0345 604 0537</a></b></div>
                <div class="warning-info-container d-flex flex-row justify-content-start align-items-center mt-3">
                    <i class="fa-solid fa-circle-exclamation fa-3x mr-4"></i>
                    <p class="mb-0">If you have a medical emergency, please don't wait for an appointment. You should call
                        999 or go to your nearest A&amp;E.</p>
                </div>
                <div class="align-self-center warning-button-container"><b-button variant="cta" size="lg"
                        @click="agreeToWarning()" squared>Get started <i class="fa-regular fa-chevron-right"></i></b-button>
                </div>
            </div>
            <div class="d-flex flex-column align-items-center content-container container-width w-100 animate__animated animate__fadeIn"
                v-if="selectedView === 'appointmentType'">
                <h3 class="pb-5 mb-3 brand-text align-self-center">What type of appointment would you like?</h3>
                <div class="type-select-button shadow" @click="selectDeliveryType('video')">
                    <i class="fa-solid fa-circle-check pr-5"></i> Video Call
                </div>
                <div class="type-select-button shadow" @click="selectDeliveryType('phone')">
                    <i class="fa-solid fa-circle-check pr-5"></i> Audio Call
                </div>
            </div>
            <div class="d-flex flex-column align-items-center content-container container-width w-100 animate__animated animate__fadeIn"
                v-if="selectedView === 'genderType'">
                <h3 class="pb-5 mb-3 brand-text">Do you have a preference for who you see?</h3>
                <div class="type-select-button shadow" @click="selectGenderType('a')">
                    <i class="fa-solid fa-circle-check pr-5"></i> No preference
                </div>
                <div class="type-select-button shadow" @click="selectGenderType('f')">
                    <i class="fa-solid fa-circle-check pr-5"></i> Female {{ $route.params.appt_type === 'gp' ?
                        "GP"
                        : "Physio" }}
                </div>
                <div class="type-select-button shadow" @click="selectGenderType('m')">
                    <i class="fa-solid fa-circle-check pr-5"></i> Male {{ $route.params.appt_type === 'gp' ?
                        "GP"
                        : "Physio" }}
                </div>
            </div>
            <div class="d-flex flex-column align-items-center content-container container-width animate__animated animate__fadeIn"
                v-if="selectedView === 'appointmentSelect'">
                <h3 class="pb-5 mb-3 brand-text" v-if="!noDates">Choose your appointment</h3>
                <h4 class="pb-5 mb-3 brand-text" v-if="!noDates">Select a date</h4>
                <div class="slick-container pb-3 animate__animated animate__fadeIn" v-if="dates.length > 0">
                    <slick ref="slick" :options="slickOptions" class="w-100">
                        <div v-for="(date, i) in dates" :key="i" class="px-3">
                            <b-button class="slick-button"
                                :variant="selectedDate ? selectedDate.id == date.id ? 'cta' : 'white' : 'white'"
                                @click="selectDate(date)">{{ date.label }}</b-button>
                        </div>
                    </slick>
                </div>
                <div class="text-center" v-else-if="noDates">
                    <b class="no-appointments">
                        No appointments available that match your preferences.
                    </b>
                    <p class="no-appointments-text">Try changing your appointment type or who you'd prefer to see</p>
                    <b-button variant="cta" @click="goBack()" squared class="mt-3">Change appointment preferences</b-button>
                </div>
                <div v-else>
                    <b-spinner class="brand-primary" />
                </div>
                <div class="content-container container-width w-100 animate__animated animate__fadeIn" v-if="selectedDate">
                    <div v-if="clinicians.length > 0" class="pb-2">
                        <h4 class="brand-text pt-5 pb-4 mb-0 text-center">Select a {{ $route.params.appt_type === 'gp' ?
                            "GP"
                            : "Physio" }}</h4>

                        <div v-for="(clinician, i) in clinicians"
                            class="type-select-button shadow brand-primary animate__animated animate__fadeIn"
                            @click="selectExpert(clinician)">
                            <span
                                :class="[selectedClinician ? selectedClinician.id === clinician.id ? 'brand-secondary' : '' : '']">{{
                                    clinician.full_name }}</span>
                        </div>
                    </div>
                    <div class="d-flex flex-row justify-content-center pt-5" v-else>
                        <b-spinner class="brand-primary" />
                    </div>
                </div>
                <div class="d-flex flex-column align-items-center animate__animated animate__fadeIn" ref="timeslots"
                    v-if="selectedClinician">
                    <h4 class="brand-text py-5 mb-0">Choose time</h4>
                    <div class="animate__animated animate__fadeIn" v-if="timeslots.length > 0">
                        <div class="time-button-containers row pb-5 mb-5">
                            <div v-for="(timeslot, i) in timeslots" class="col-4">
                                <b-button @click="selectTimeslot(timeslot)"
                                    :variant="selectedTimeslot ? timeslot.id === selectedTimeslot.id ? 'cta' : 'white' : 'white'"
                                    class="w-100" squared>{{ timeslot.label }}</b-button>
                            </div>
                        </div>
                        <div class="pb-5 mb-5 text-center">
                            <b-button size="lg" variant="cta" :disabled="!selectedTimeslot" squared
                                @click="viewPreview()">Next</b-button>
                        </div>
                    </div>
                    <div v-else>
                        <b-spinner class="brand-primary" />
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column align-items-center content-container container-width brand-text text-center animate__animated animate__fadeIn"
                v-if="selectedView === 'preview'">
                <h3 class="brand-text pb-5">Book Appointment</h3>
                <h4 class="brand-text pb-5 mb-0">Your appointment details:</h4>
                <div class="pb-5">
                    <div><b>Date:</b> {{ getFormatedDate(selectedDate.id, 'DD MMMM YYYY') }}</div>
                    <div><b>Time:</b> {{ getFormatedDate(selectedTimeslot.id, 'h.mma') }}</div>
                    <div><b>{{ $route.params.appt_type === 'gp' ? 'GP' : 'Physio' }}:</b> {{ selectedClinician.full_name }}
                    </div>
                    <div><b>Format:</b> {{ selectedDeliveryType === 'video' ? 'Video' : 'Phone' }} call</div>
                </div>
                <div class="pb-5">
                    By booking an appointment, you agree to the following <a target="_blank"
                        href="https://www.bupa.co.uk/legal-notices/bupa-touch-terms-and-conditions/" class="tc-link">terms
                        and conditions</a>.
                </div>
                <div>
                    <b-button variant="cta" size="lg" class="booking-button" squared @click="createAppointment()">
                        <span v-if="!appointmentLoading">Book Now</span>
                        <span class="d-flex flex-row justify-content-center align-items-center" v-else><b-spinner
                                style="width: 1.4rem; height: 1.4rem;" /></span>
                    </b-button>
                </div>
            </div>
            <div class="d-flex flex-column align-items-center content-container container-width brand-text text-center animate__animated animate__fadeIn booking-confirm-container"
                v-if="selectedView === 'bookingConfirm'">
                <i class="fa-solid fa-circle-check fa-4x pb-5"></i>
                <h3 class="brand-text pb-5 mb-0">Booking Complete</h3>
                <h4 class="brand-text pb-5 mb-0">What happens next</h4>
                <ol class="pb-4">
                    <li class="pb-3">You'll get an email confirmation with a link to a questionnaire. Please complete this
                        before your appointment.</li>
                    <li class="pb-3">Sign in to Bupa Touch to join your appointment. Go to Access digital GP services to go
                        to your appointments area.</li>
                    <li class="pb-3">We recommend you join five minutes before your appointment, and wait for the session to
                        start.</li>
                </ol>
                <div>
                    <b-button variant="cta" size="lg" squared @click="$router.push('/gp')">View your appointments</b-button>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import axios from "axios";
import config from "../config/constants";
import slick from "vue-slick";
import ErrorModal from "../components/GP/ErrorModal.vue";


export default {
    name: "GpHome",
    components: {
        slick,
        ErrorModal,
    },
    mounted() { },
    data() {
        return {
            selectedView: "warning",
            selectedDeliveryType: null,
            selectedGenderType: null,
            selectedDate: null,
            selectedClinician: null,
            selectedTimeslot: null,
            dates: [],
            clinicians: [],
            timeslots: [],
            appointmentLoading: false,
            noDates: false,
            genderOptions: [
                { value: "m", text: "Male" },
                { value: "f", text: "Female" },
                { value: "a", text: "Any" }
            ],
            slickOptions: {
                infinite: false,
                autoplay: false,
                dots: false,
                nextArrow:
                    '<button class="slick-next" type="button" style=""><i class="fas fa-caret-right brand-highlight-1"></i></button>',
                prevArrow:
                    '<button class="slick-prev" type="button" style=""><i class="fas fa-caret-left brand-highlight-1"></button>',
                slidesToScroll: 3,
                slidesToShow: 3,
                centerPadding: "5",
                adaptiveHeight: true,
                responsive: [
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        },
                    },
                ]
            },
        };
    },
    mounted() {
        this.getBookings();
    },
    methods: {
        goBack() {
            if (this.selectedView === 'warning') {
                this.$router.push('/gp');
            }
            if (this.selectedView === 'appointmentType') {
                this.selectedView = 'warning';
            }
            if (this.selectedView === 'genderType') {
                this.selectedDeliveryType = null,
                    this.selectedView = 'appointmentType'
            }
            if (this.selectedView === 'appointmentSelect') {
                this.selectedDate = null;
                this.selectedClinician = null;
                this.selectedTimeslot = null;
                this.selectedGenderType = null;
                this.dates = [];
                this.clinicians = [];
                this.timeslots = [];
                this.noDates = false;
                this.selectedView = 'genderType'
            }
            if (this.selectedView === 'preview') {
                this.selectedView = 'appointmentSelect'
            }
        },
        agreeToWarning() {
            this.selectedView = 'appointmentType'
            this.scrollToRef();
        },
        selectDeliveryType(type) {
            this.selectedDeliveryType = type;
            this.selectedView = 'genderType'
            this.scrollToRef();
        },
        selectGenderType(type) {
            this.selectedGenderType = type;
            this.getDates();
            this.selectedView = 'appointmentSelect'
            this.scrollToRef();
        },
        selectExpert(expert) {
            this.selectedClinician = expert;
            this.selectedTimeslot = null;
            this.timeslots = [];
            this.getTimeslots();
        },
        selectDate(date) {
            this.selectedDate = date;
            this.selectedClinician = null;
            this.clinicians = [];
            this.timeslots = []
            this.selectedTimeslot = null;
            this.getClinicians();
        },
        selectTimeslot(time) {
            this.selectedTimeslot = time;
        },
        viewPreview() {
            this.scrollToRef();
            this.selectedView = 'preview'
        },
        async getBookings() {
            let self = this;
            const payload = {
                user_id: this.$store.getters.user_id,
            };
            let res = await axios.post(
                config.api_env + "/application/api/u/gp/get-bookings",
                payload,
                config.options
            );
            if (res.data.success) {
                res.data.bookings.forEach((booking) => {
                    if (booking.appt_type === self.$route.params.appt_type) self.openErrorModal("You're already booked in", "Go to your appointments area to manage your bookings", false, true);
                })
            }
        },
        async getDates() {
            const payload = {
                user_id: this.$store.getters.user_id,
                appt_type: this.$route.params.appt_type,
                appt_delivery: this.selectedDeliveryType,
                appt_clinician_sex: this.selectedGenderType
            };
            let res = await axios.post(
                config.api_env + "/application/api/u/gp/get-dates",
                payload,
                config.options
            );
            if (res.data.success) {
                if (res.data.dates.length === 0) {
                    this.noDates = true;
                }
                this.dates = res.data.dates;
            } else if (res.data.start_sso_login) {
                this.openErrorModal('Sorry, your secure session expired, please start your journey again.', true);
            } else if (res.data.err && res.data.err.includes("3994")) {
                this.$store.dispatch("logout");
                this.$router.push("/login").catch((err) => { });
            } else {
                this.openErrorModal("Sorry, we've had a problem", "Please try again. <br /> <br /> If the problem persists please call us on <b>0345 608 0898</b>. We’re here to help Mon-Fri 8am-6pm, Sat 8am-4pm");
            }
        },
        async getClinicians() {
            const payload = {
                user_id: this.$store.getters.user_id,
                appt_type: this.$route.params.appt_type,
                appt_delivery: this.selectedDeliveryType,
                appt_clinician_sex: this.selectedGenderType,
                date_id: this.selectedDate.id
            };
            let res = await axios.post(
                config.api_env + "/application/api/u/gp/get-clinicians",
                payload,
                config.options
            );
            if (res.data.success) {
                this.clinicians = res.data.clinicians;
            } else if (res.data.start_sso_login) {
                this.openErrorModal('Your session has expired', "Please log in and try again", true);
            } else if (res.data.err && res.data.err.includes("3994")) {
                this.openErrorModal('Your session has expired', "Please log in and try again", true);
            } else {
                this.openErrorModal("Sorry, we've had a problem", "Please try again. <br /> <br /> If the problem persists please call us on <b>0345 608 0898</b>. We’re here to help Mon-Fri 8am-6pm, Sat 8am-4pm");
            }
        },
        async getTimeslots() {
            const payload = {
                user_id: this.$store.getters.user_id,
                appt_type: this.$route.params.appt_type,
                appt_delivery: this.selectedDeliveryType,
                appt_clinician_sex: this.selectedGenderType,
                clinician_id: this.selectedClinician.id,
                date_id: this.selectedDate.id
            };
            let res = await axios.post(
                config.api_env + "/application/api/u/gp/get-time-slots",
                payload,
                config.options
            );
            if (res.data.success) {
                this.timeslots = res.data.timeslots;
                this.scrollToRef('timeslots');

            } else if (res.data.start_sso_login) {
                this.openErrorModal('Your session has expired', "Please log in and try again", true);
            } else if (res.data.err && res.data.err.includes("3994")) {
                this.openErrorModal('Your session has expired', "Please log in and try again", true);
            } else {
                this.openErrorModal("Sorry, we've had a problem", "Please try again. <br /> <br /> If the problem persists please call us on <b>0345 608 0898</b>. We’re here to help Mon-Fri 8am-6pm, Sat 8am-4pm");
            }
        },
        async createAppointment() {
            this.appointmentLoading = true;
            const payload = {
                user_id: this.$store.getters.user_id,
                appointment: this.selectedTimeslot.appointment
            };
            let res = await axios.post(
                config.api_env + "/application/api/u/gp/book-appointment",
                payload,
                config.options
            );
            if (res.data.success) {
                this.appointmentLoading = false;
                this.selectedView = 'bookingConfirm';
            } else if (res.data.start_sso_login) {
                this.openErrorModal('Your session has expired', "Please log in and try again", true);
            } else if (res.data.err && res.data.err.includes("3994")) {
                this.openErrorModal('Your session has expired', "Please log in and try again", true);
            } else {
                this.openErrorModal("Sorry, this appointment is no longer available", "Please choose another appointment");
                this.goBack();
            }
        },
        openErrorModal(title, error, redirect, goBack) {
            const content = this.$createElement(ErrorModal, { props: { title, error, redirect, goBack } })
            const skin = this.$store.getters.skin;
            let opts = {
                hideHeader: true,
                hideFooter: true,
                modalClass: "coach_modal theme-" + skin,
                size: "md",
                buttonSize: "sm",
                centered: true,
                contentClass: "coach_modal",
                id: 'error-modal'
            }
            return this.$bvModal.msgBoxOk(content, opts);
        },
    },
    computed: {
    },
};
</script>
<style lang="less">
#GpSelect {
    font-family: "Montserrat", serif !important;
    background-color: #eee;

    .back-button-container {
        padding-top: 80px;
        font-size: 1.4rem;
        font-weight: bold;
        font-family: 'Montserrat';
        font-weight: 700;

        .back-button {
            cursor: pointer;
        }
    }

    .shadow {
        box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
    }

    .gp-select-container {
        min-height: 90vh;
    }

    .type-select-button {
        cursor: pointer;
        background-color: white;
        display: flex;
        flex-grow: 1;
        width: 100%;
        padding: 20px 40px;
        margin-top: 20px;
        font-size: 1.6rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-family: "Montserrat", serif;
        font-weight: 700;
        color: #ccc;
        border: 2px solid white;
        border-radius: 6px;

        .fa-solid {
            font-size: 6rem;
            color: #ddd;
        }

        &:hover {
            color: #0079c8;
            border: 2px solid #0079c8;

            i {
                color: #0079c8;
            }
        }
    }

    .warning-container {
        max-width: 600px;
        font-size: 1.6rem;

        h3 {
            padding-top: 0;
            margin-top: 0;
            letter-spacing: normal;
            font-size: 1.8rem;
        }

        .warning-info-container {
            font-family: "Montserrat", serif;
            font-weight: 700;
            background-color: white;
            padding: 20px;
            border: 2px solid #D60023;
            border-radius: 16px;
            margin-bottom: 25px;

            i {
                color: #D60023;
            }
        }

        .btn {
            margin-top: 30px;
            margin-bottom: 100px;
        }
    }

    .booking-confirm-container {
        padding-top: 100px;
    }

    h3 {
        padding-top: 0;
        margin-top: 0;
        letter-spacing: normal;
        font-size: 1.8rem;
    }

    .slick-container {
        width: 100%;
    }

    .slick-button {
        width: 100%;
    }

    .booking-button {
        min-width: 200px !important;
    }

    .btn {
        border: 2px solid lightgrey;
    }

    .btn.btn-cta {
        &:hover {
            background-color: #0079c8 !important;
        }
    }

    .slick-next {
        right: -30px;
        top: 23px;
    }

    .slick-prev {
        left: -25px;
        top: 23px;
    }

    .slick-slide {
        transform: scale(1) !important;
    }

    .container-width {
        max-width: 450px;
    }

    .btn {
        min-width: 100px;
        margin-bottom: 10px;
    }

    .brand-text {
        font-size: 1.6rem;
    }

    .tc-link {
        color: #0079c8;
        text-decoration: underline;
    }

    b {
        font-family: 'Montserrat', serif;
        font-weight: 700;
    }

    h3,
    h4 {
        color: #21272A !important;
    }

    .no-appointments {
        font-size: 1.6rem;
    }

    .no-appointments-text {
        padding-top: 25px;
        font-size: 1.6rem;
    }
    .phone-text{
        color: inherit !important;
        font-family: 'Montserrat', serif !important;
        font-weight: 700 !important;
    }
    .fa-4x {
        color: green;
    }

}
</style>
  