<template>
  <div id="CourseText">
    <div class="col-md-12" :style="{ height: contentHeight - 100 + 'px' }">
      <div class="chapters-text" v-html="text"></div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "CourseText",
  components: {},
  props: [
    'content',
    'contentHeight'
  ],
  data() {
    return {
      text: ''
    };
  },
  mounted(){
    this.translateText(this.content, 'en')
  },
  methods: {
    async translateText(text, target) {
      /*const payload = {
        "target": target,
        "q": this.content,
      }
      let translation = await this.axios.post('https://translation.googleapis.com/language/translate/v2?key=AIzaSyAKRf9PEhBW4uAsoo35SkH7Et5rxI4YO7s&?target=de', null, {params: payload});
      this.text =  translation.data.data.translations[0].translatedText */
      this.text =  text
    }
  },
  computed:{
  },
  watch: {
  }
};
</script>
<style lang="less">
#CourseText {
}
</style>
