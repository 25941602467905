<template>
  <div id="ChallengeYourselfCard">
    <div class="backgroundImage"></div>
    <div class="textContainer">
      <div class="innerTextContainer">
        <div class="title">Challenge Yourself</div>
        <div class="content">Create a challenge for yourself to push yourself towards your fitness goals!</div>
        <div class="challengesButton" @click="$emit('createNewChallenge')">Let's Go</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChallengeYourselfCard"
}
</script>

<style lang="less">
#ChallengeYourselfCard {
  width: 100%;
  aspect-ratio: 347 / 171;
  border-radius: 25px;
  overflow: hidden;
  position: relative;
  margin-bottom: 10px;
  margin-top: 10px;
  .backgroundImage {
    width: 70%;
    position: absolute;
    z-index: 201;
    background-image: url("/img/challenges/challenge-yourself.png");
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .textContainer {
    position: absolute;
    width: 90%;
    height: 100%;
    right: 0;
    z-index: 202;
    background: linear-gradient(269.71deg, #292824 0.28%, #292824 69.21%, rgba(217, 217, 217, 0) 98.17%);
    color: white;
    justify-content: end;
    display: flex;
    padding-right: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
    .innerTextContainer {
      width: 70%;
      display: flex;
      align-content: center;
      justify-content: space-between;
      flex-direction: column;
      .title {
        font-size: 1.7rem;
      }
      .content {
        font-size: 1.2rem;
      }
    }
  }
}
</style>