<template>
  <div id="HomeWellbeingStudio">
    <div class="content-container">
      <div class="row justify-content-center mb-3">
        <div class="img-container"><img src="/img/wellbeing-studio/wellbeing-studio-logo.png"></div>
      </div>
      <div class="row align-items-start mt-5">
        <div class="col-md-6 col-sm-12 img-container">
          <img class="img-fluid" src="/img/home/homewellbeingstudio.png" />
        </div>
        <div class="col-md-6 col-sm-12 d-flex align-items-center">
          <div class="home-card shadow mb-4">
            <h3>{{title}}</h3>
            <p>
              {{description}}
            </p>
            <b-button class="cta-button mt-4" @click="$router.push('wellbeing-studio')">View Wellbeing Studio</b-button>
          </div>
        </div>
        
      </div>
    </div>
    <div class="circle-1"></div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import config from "@/config/constants";

export default {
  name: "HomeTMH",
  components: {},
  data() {
    return {
      title: "",
      description: ''
    };
  },
  mounted() {
    this.getSeries();
  },
  computed: {
    ...mapGetters(["skin", "user_id"]),
  },
  methods: {
    async getSeries(seriesID = null) {
      const payload = {
        user_id: this.user_id,
        series_id: seriesID,
      };
      let res = await this.axios.post(
          process.env.VUE_APP_API_PATH +
          "/application/api/u/studio/get-studio-series",
          payload, config.options
      );
      if (res.data.success) {
        this.title = res.data.series.title;
        this.description = res.data.series.description;
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        seriesID ? this.showError() : (this.noSeries = true);
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="less">
#HomeWellbeingStudio {
  padding-top: 45px;
  background-color: white;
  position: relative;
  overflow: hidden;
  .img-container{
    z-index: 3;
  }
  .home-card {
    background-color: white;
    z-index: 3;
    padding: 30px;
    font-size: 1.6rem;
    border-radius: 16px;
    h3 {
      letter-spacing: 1px;
      padding-top: 15px;
      font-family: "DMSans", Arial, Helvetica, sans-serif;
    }
    .newbuttoncolours {
      background-color: #651242;
      color: white;
    }
  }
  .img-fluid{
    margin-top: 75px;
  }
  .circle-1 {
    z-index: 2;
    width: 600px;
    height: 600px;
    border-radius: 700px;
    position: absolute;
    background-color: #758d8c;
    top: -80px;
    right: -80px;
  }
}
</style>
