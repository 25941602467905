<template>
    <div id="InsightsQuestions" :class="{'bg-brand-secondary': skin === 8, 'bg-transparent': skin !==8 }">
        <div class="content-container row align-items-center my-5 py-5">
            <div class="col-12 question-card my-5 py-5" v-if="question">
                <div class="insights-logo mb-5 pb-5 align-content-center">
                    <h2><i class="fa fa-plus mr-2"></i>{{$route.meta.title ? $route.meta.title : componentText.title}}.</h2>
                </div>
                <div class="question">
                    <h2 :class="{'mb-0': question.question_description && question.question_description !== ' '}">{{question.question_label}}</h2>
                    <p v-if="question.question_description">{{question.question_description}}</p>
                    <div class="input-wrap" v-if="question.type === 'Number'">
                        <b-input class="number-input px-5 my-5"
                                 type="number"
                                 v-model="answer[0]"
                                 @keypress="isWholeNumber($event)"
                                 min="0"
                                 :max="question.question_label.includes('What is your age') ? 120 : 9999"
                        >
                        </b-input>
                    </div>
                    <div class="input-wrap boolean-input mb-5" v-if="question.sur_type === 'bool'">
                        <b-form-radio-group v-model="answer[0]">
                            <label class="mr-5">
                                <b-radio :value="question.question_id + '-1'"></b-radio>
                                <div v-if="answer[0] && answer[0].includes('-1')" class="tick-filled"></div>
                                <div  class="tick-outline" v-else></div>
                                <div>{{question.options[0].label}}</div>
                            </label>
                            <label class="ml-5">
                                <b-radio :value="question.question_id + '-2'"></b-radio>
                                <div v-if="answer[0] && answer[0].includes('-2')" class="x-filled"></div>
                                <div  class="x-out" v-else ></div>
                                <div>{{question.options[1].label}}</div>
                            </label>
                        </b-form-radio-group>
                    </div>
                    <div class="input-wrap select-input mb-5" v-if="question.sur_type  === 'single' ">
                        <v-select v-model="answer[0]" :options="options" label="label" :key="question.question_id"></v-select>
                    </div>
                    <div class="input-wrap select-input mb-5" v-if="question.sur_type  === 'multiple'" :key="question.question_id">
                        <v-select v-model="answer"
                                  :options="options"
                                  :closeOnSelect="false"
                                  :multiple="true"
                                  label="label"
                                  >
                        </v-select>
                    </div>
                    <div class="input-wrap mb-5 pt-5" v-if="question.sur_type === 'scale'">
                        <InsightsSlider class="pt-5" :value="answer[0]" :options="options" v-on:value="sliderValue" :key="question.question_id"></InsightsSlider>
                    </div>
                </div>
                <div class="row answer-buttons">
                    <div class="col-6 px-2">
                        <b-button
                                :disabled="previousQuestion === -1"
                                @click="getQuestion(previousQuestion)"
                                @mouseover="hover = true"
                                @mouseout="hover=false"
                                :class="{'cta-button': hover}"
                                class="py-3 mr-2">{{componentText.previous}}</b-button>
                    </div>
                    <div class="col-6 px-2">
                        <b-button @click="answerQuestion"
                                  @mouseover="hover = true"
                                  @mouseout="hover=false"
                                  :class="{'cta-button': hover}"
                                  class="py-3 ml-2">{{componentText.next}}</b-button>
                    </div>
                </div>
            </div>
            <div class="progress-wrap px-5">
                <div class="progress">
                    <div class="progress-bar" :style="'width: '+ progress +'%'">
                        <div class="progress-info ">{{ progress }} %</div>
                    </div>
                </div>
            </div>
        </div>
        <div id="pop-up" v-if="showPopup">
            <div class="pop-up-background"></div>
            <div class="pop-up-content row ">
                <div class="col-12 popup-heading pb-5">
                    <h4>{{componentText.completed}}</h4>
                </div>
                <div class="popup-image col-12  px-0">
                    <h2>{{componentText.title}}.</h2>
                </div>
                <p class="popup-image">{{componentText.recommended}} <a @click="$router.push('/personal/fitness')">{{componentText.plans}}</a>, <a @click="$router.push('/personal/nutrition')">{{componentText.recipes}}</a> {{componentText.and}}
                <a @click="$router.push('/wellbeing')">{{componentText.wellbeing}}</a> {{componentText.paths}}</p>
                <b-button @click="$router.push('/')"
                          class="cta-button my-5 py-3 px-5"
                >{{componentText.home}}</b-button>
                <b-button class="close-popup" @click="$router.push('/wellbeing')"><font-awesome-icon icon="times-circle"></font-awesome-icon></b-button>
            </div>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import axios from "axios";
    import config from "../../config/constants";
    import InsightsSlider from "./InsightsSlider.vue"

    export default {
        name: "InsightsQuestions",
        components: {
            InsightsSlider
        },
        data() {
            return {
                question: [],
                answer: [],
                previousQuestion: '',
                options: [],
                showPopup: false,
                progress: 0,
                hover:false
            };
        },
        mounted() {
            //no idea why the first one is 263
            this.getQuestion(263)
        },
        methods: {
            isWholeNumber: function(evt) {
                if(!this.question.question_label.includes('What is your age')) return true;
                let str = '';
                if(this.answer.length) str = this.answer[0];
                str += evt.key;
                const newAge = Number.parseInt(str);
                if (evt.key === "." || isNaN(newAge) || newAge > 120 || newAge === 0) {
                    evt.preventDefault();
                } else {
                    return true;
                }
            },
           async getQuestion(id){
                const payload = {
                    user_id: this.$store.getters.user_id,
                    question_id: id
                }
                let res = await axios.post(config.api_env + '/application/api-hc/get-question', payload, config.options)
               this.question = res.data.question
               if(this.question.sur_type === "single" && res.data.answer.length > 0) {
                    this.answer = this.question.options.filter(option => option.id === res.data.answer[0])
               }else if(this.question.sur_type === "multiple"){
                    //get the full options whose id matches any in the answers array
                   this.answer = this.question.options.filter(option => {
                           let matches = res.data.answer.filter(answer => {
                               if (answer === option.id) {
                                   return true
                               }
                               else return false
                           })
                           if(matches.length > 0){
                               return true
                           }
                       }
                   )
               } else {
                   this.answer = res.data.answer
               }
               this.progress = res.data.progress
               this.previousQuestion = res.data.pre_id
               if(this.question.options){
                    this.options = this.question.options.map(x => {
                        x.label = x.label.replace('&amp;', '&');
                        return x
                    })
               }

            },
            async answerQuestion(){
               let value
               if(this.question.sur_type === 'single'){
                   value = this.answer[0].id
               }else if(this.question.sur_type === 'multiple'){
                   value = this.answer.map(answer => answer.id)
               }else{
                   value = this.answer[0]
               }
               const payload = {
                   user_id: this.$store.getters.user_id,
                   question_id: this.question.question_id,
                   value: value,
                   src: "up"
               }
               let res = await axios.post(config.api_env + '/application/api-hc/answer-question', payload, config.options)
                if(res.data.success && res.data.next_id >= 0){
                   this.getQuestion(res.data.next_id)
                }else if(res.data.success && res.data.next_id === -1){
                    this.finishInsights()
                }
            },
            sliderValue(value){
                this.answer[0] = this.question.question_id + '-'+ value
            },
            finishInsights(){
                this.question = ''
                this.showPopup = true
            }
        },
        watch:{
        },
        computed:{
            skin(){
                return this.$store.getters.skin
            },
            componentText() {
                return this.getText.loggedIn.insights;
            },
        }
    };
</script>
<style lang="less">
    #InsightsQuestions {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: calc(100vh - 200px);
        .question-card {
            position: relative;
            text-align: center;
            .insights-logo {
                text-align: center;
                margin: auto;
                max-width: 350px;
                width: 100%;
                position: relative;
                img {
                    width: 100%;
                }
                h2{
                    display: inline-flex;
                    font-family: FilsonProBold, sans-serif;
                    font-size: 7.6rem;
                    color: #fff;
                    opacity: 0.17;
                    i{
                        font-size: 3rem;
                    }
                }
            }
            .question {
                text-align: center;
                margin: auto;
                h2 {
                    font-family: 'DMSans', sans-serif;
                    font-size: 2.5rem;
                    line-height: 1.2;
                    color: #fff;
                }
                p {
                    font-size: 2.3rem;
                    font-family: 'DMSans', sans-serif;
                    color: #fff;
                }
                .number-input {
                    height: 60px;
                    border: 1px solid #fff;
                    border-radius: 30px;
                    background-color: transparent;
                    font-family: FilsonProBold;
                    font-size: 2.5rem;
                    color: #fff;
                    max-width: 300px;
                    margin: auto;
                }
            }
            .answer-buttons {
                text-align: center;
                margin: auto;
                max-width: 350px;
                .btn {
                    width: 100%;
                    font-family: DMSans;
                    border: 1px solid #fff;
                    color: #fff;
                    background: transparent;
                    text-transform: uppercase;
                }
            }
            .boolean-input {
                label::before, label::after {
                    visibility: hidden;
                }
                label {
                    width: 90px;
                    font-family: 'DMSans', sans-serif;
                    font-size: 2rem;
                    color: #fff;
                }
                img{
                    cursor: pointer;
                }
            }
            .select-input {
                cursor: pointer;
                //TODO:: Redo the v-select stuff as it breaks functionality somehow
                .v-select {
                    .vs__dropdown-toggle {
                        border-radius: 20px;
                        border: 1px solid #fff;
                        font-family: DMSans, sans-serif;
                        background: transparent;
                        color: #fff;
                        font-size: 2rem;
                        position: relative;
                        padding: 10px;
                        min-height: 50px;
                        .vs__selected-options, .vs__selected, .vs__actions, svg{
                            color: #fff;
                            fill: #fff;
                        }
                    }
                    &.vs--single{
                        .vs__clear{
                            display: none;
                        }
                    }
                    &.vs--open {
                        .vs__selected-options {
                            padding-bottom: 10px;
                        }
                    }
                    .vs__dropdown-menu {
                        text-align: left;
                        padding-left: 0;
                        list-style-type: none;
                        max-height: 200px;
                        font-size: 2rem;
                        color: #4e555b;
                        li {
                            border-bottom: 1px solid #fff;
                            padding: 10px;
                            &:last-child {
                                border-bottom-width: 0;
                            }
                        }
                        ::-webkit-scrollbar {
                            width: 10px;
                        }
                        ::-webkit-scrollbar-track {
                            border-radius: 10px;
                        }
                        ::-webkit-scrollbar-thumb {
                            border-radius: 10px;
                            background-color: #fff;
                            height: 50px;
                            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
                        }
                    }
                    .vs__dropdown-option--selected {
                        position: relative;
                        &:after {
                            content: ' ';
                            position: absolute;
                            color: #fff;
                            background-image: url('/img/insights/tick-blue.svg');
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: 25px;
                            width: 28px;
                            height: 20px;
                            right: 10px;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                    .vs__selected-options{
                        .vs__selected{

                        }
                    }
                }
                .select {
                    border-radius: 20px;
                    border: 1px solid #fff;
                    font-family: DMSans, sans-serif;
                    background: transparent;
                }
            }
        }
        .progress-wrap{
            position: absolute;
            top: 80px;
            right: 15%;
        }
        .progress {
            position: relative;
            height: 5px;
            overflow: visible;
            width: 340px;
        }

        .progress-bar {
            position: absolute;
            height: 10px;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 3px;
            overflow: visible;
        }

        .progress-info {
            position: absolute;
            min-width: 50px;
            height: 30px;
            padding-left: 6px;
            padding-right: 6px;
            top: -45px;
            right: 0;
            transform: translateX(50%);
            font-size: 16px;
            line-height: 34px;
            text-align: center;
            border-radius: 5px;
            font-family: 'FilsonProBold', sans-serif;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .progress-info:before {
            position: absolute;
            content: '';
            left: 50%;
            bottom: -5px;
            transform: translateX(-50%);
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
        }
        #pop-up {
            display: flex;
            align-items: center;
            z-index: 500;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.8);
            text-align: center;
            .pop-up-content {
                max-width: 500px;
                width: 100%;
                margin: auto;
                font-size: 1.6rem;
                text-align: center;
                z-index: 10;
                color: #fff;
                position: relative;
                display: flex;
                flex-direction: column;
                //IE styles
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    .col-12{
                        flex: auto;
                    }
                }
                p {
                    color: #fff;
                    text-align: center;
                    margin: auto;
                    font-size: 2rem;
                }
                a{
                    font-family: FilsonProBold;
                    text-decoration: underline;
                }
                h2{
                    font-family: FilsonProBold, sans-serif;
                    font-size: 5rem;
                    color: #fff;
                    i{
                        font-size: 2rem;
                        top: 0;
                        position: absolute;
                        left: 10px;
                    }
                }
                .popup-heading {
                    h4 {
                        font-family: 'DMSans', sans-serif;
                        font-size: 2.6rem;
                        color: #fff;
                        text-align: center;
                        margin: auto;
                    }
                }
                .popup-image{
                    margin: auto;
                    img{
                        margin: auto;
                    }
                }
                .btn{
                    margin: auto;
                }
                .close-popup {
                    position: absolute;
                    top: 0;
                    right: -50px;
                    background-color: transparent;
                    width: 50px;
                    height: 50px;
                    svg {
                        width: 30px;
                        height: 30px;
                    }
                }
            }
            .pop-up-background {
                width: 100vw;
                height: 100vh;
                position: absolute;
                left: 0;
                right: 0;
                z-index: 5;
                background: transparent;
            }
        }
        @media only screen and (max-width: 992px){
            .progress-wrap{
                right: 50%;
                top: 60px;
                transform: translateX(50%);
                display: flex;
                max-width: 340px;
                width: 100%;
                .progress{
                    width: 100%;
                }
            }
        }
        @media only screen and (max-width: 576px){
            .question-card .question h2{
                font-size: 2.8rem;
            }
        }
    }
</style>
