<template>
  <div id="settings" class="container-fluid px-0">
    <div class="main no-side-bar">
      <div class="page-header bg-brand-secondary">
        <div class="content-container container">
          <div class="page-title col-12">
            <h2>{{ content.title }}</h2>
          </div>
        </div>
      </div>
      <div class="content-wrap">
        <div class="content-container row py-5">
          <div class="content-card w-100 p-5">
            <div class="content-inner p-md-5 m-md-5 p-0 m-0">
              <p class="description">{{ content.description }}</p>
              <div class="content-list">
                <div
                  v-for="(person, i) in content.people"
                  :key="i"
                  class="content-item"
                >
                  <p class="item-header">
                    <span class="person-name">
                      <strong>{{ person.name }}</strong>
                    </span>
                    <strong v-if="person.about">{{ person.about }}</strong>
                  </p>
                  <p v-if="person.author_of">
                    <em>Author of:</em> {{ person.author_of }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer v-if="$store.state.user.authenticated"></Footer>
    </div>
  </div>
</template>

<script>
import Footer from "../components/Footer";

export default {
  name: "ContentSources",
  components: {
    Footer
  },
  data() {
    return {
      content: {
        title: "Content Sources",
        description: `All Spectrum Life content and recommendations are created and maintained by our team or
        Clinical & Content experts. We are committed to always providing up to date, relevant and
        accurate information for our users. See below for the list of Clinical Experts and the content
        pieces and sections that they have either created or contributed to on our platform.`,
        people: [
          {
            name: "Michelle Loughlin",
            about: "Registered Dietitian, Msc. in Nutrition",
            author_of: `Boost You Energy, Simple Recipes to Keep your Energy Up, The Secrets to Successful
              Weight Loss, Healthy Living when life gets hectic. Michelle also oversees the calculations of the
              Nutritional & Dietary recommendations provided on our platform`
          },
          {
            name: "David Bergin",
            about:
              "Health Promotion Officer, BSc in Public Health & Health Promotion",
            author_of: `Motivation, Making Changes for a Positive Future, The Fundamentals of Exercise,
              Workplace Wellbeing`
          },
          {
            name: "Deirdre McSwiney",
            about: "Sleep Technologist & Cognitive Behaviour Therapist",
            author_of: `How to Sleep Better`
          },
          {
            name: "Marie Craigie",
            about: "Registered Nurse & CFR Trainer",
            author_of: `Heart Health`
          },
          {
            name: "Anita Fletcher",
            about: "Parenting Coach",
            author_of: `Parenting Top Tips, Positive Parenting Tips for Challenging Times, Family Focus`
          },
          {
            name: "Leisha (Redmond) McGrath",
            about: `Chartered Work & Organisational Psychologist, Transformational
              Coach, Shaman & Guest Speaker C. Psychol. PSI., CPsychol, AFPsSI, AFBPsS, MAC, BA (Intl),
              MSc`,
            author_of: `Practising Self Care`
          },
          {
            name: "Aisling Gough",
            about: `Health Promotion Officer, Masters Degree in Mental Health`,
            author_of: `Disconnect and Reboot`
          },
          {
            name: "Prof. Yvonne O'Meara",
            about: `Systemic Psychotherapist & Psychosocial Oncologist Women’s, Cancer Survivorship Research Coordinator University College Dublin`,
            author_of: `Understanding Cancer`
          },
          {
            name: "Alex Hassett",
            about: `BSc. (Hons) Physiotherapy Degree, University College Dublin`,
            author_of: `Weekly Running Guide`
          },
          {
            name: "James McGoldrick",
            about: `Qualified Financial Advisor`,
            author_of: `Getting your Budget back on track`
          },
          {
            name: "Susannah Healy",
            about: `Spirituality at Work Specialist. Psychologist, Mindfulness Teacher, Author, TEDx Speaker, Corp. Wellness Speaker, 1-1 Coach`,
            author_of: `The Psychology of Behavioural Change`
          },
          {
            name: "Neil Harris",
            about: `Chartered Physiotherapist. CORU registered and ISCP member.`,
            author_of: `Exercises for Remote Working`
          },
          {
            name: "Joe O'Brien",
            about: `Senior Health Psychology Specialist, Professional Doctorate in Health Psychology`,
            author_of: `Emotional Wellbeing, Dealing with Depression, Be more mindful, Dealing with
              Anxiety, Stress Less`
          },
          {
            name: "Didi Zwarte",
            about: `Registered Nutritionist`,
            author_of: `Making sure your are eating right while working from home`
          },
          {
            name: "Steve McCrae",
            about: `Health Promotion Officer, Strength And Conditioning Coach`,
            author_of: `Stay Mobile - Stay Flexible`
          },
          {
            name: "Niamh Devaney",
            about: `Health Coach & Health Promotional Officer`,
            author_of: `Exercise - Getting more active`
          },
          {
            name: "Sinéad Lynch",
            about: `Senior Psychologist`,
            author_of: `Getting more and Better Sleep`
          },
          {
            name: "Dr. Aman Kwatra",
            about: `Consultant Psychologist and Research Associate, Doctorate in
              Counselling Psychology, Counselling Psychology`,
            author_of: `Relationships`
          },
          {
            name: "Aoife Casey",
            about: `Autism Friendly University Coordinator`,
            author_of: `Diversity and Inclusion`
          },
          {
            name:
              "Sarah O’Connor and Ruth Morrow from the Asthma Society of Ireland",
            author_of: `Asthma Awareness`
          },
          {
            name: "Sean Connolly",
            about: `Health Promotion Officer`,
            author_of: `Mens Health`
          },
          {
            name: "Deirdre McSwiney",
            about: `Sleep Technologist & Cognitive Behavior Therapist`,
            author_of: `Sleep Strategies & Solutions`
          },
          {
            name: "Lauren Owens",
            about: `Registered Dietitian, BSc. Human Nutrition & Dietetics`,
            author_of: `Nutrition Survival Guide for Students`
          },
          {
            name: "Emelina Ellis ",
            about: `Director of Clinical Operations & Psychologist - Doctorate in Counselling
              Psychology`,
            author_of: `Managing Your Time, Overcoming Procrastination, Guide to Sleep, Managing Anxiety
              & Stress`
          },
          {
            name: "Roisin Fallon",
            about: `Assistant Psychologist - MSc Applied Psychology Mental Health and
              Psychological Therapies`,
            author_of: ` University Life`
          },
          {
            name: "Dr. Chris Thompson",
            about: `University Lecturer - PhD Mental Fatigue in Football, MRES Exercise
              Physiology`,
            author_of: `Setting yourself up for success`
          }
        ]
      }
    };
  }
};
</script>

<style lang="less">
#settings {
  td {
    border: 1px solid black !important;
    padding: 3px;
  }

  strong {
    font-family: "DMSans Bold", sans-serif;
  }

  font-size: 1.6rem;
  font-family: "DMSans", sans-serif;

  .content-wrap {
    background: #f4f4f4;

    .content-card {
      transform: translateY(-80px);
      background: #ffffff;
      max-width: 1000px;
      margin: auto;
    }
  }

  .description {
    margin-bottom: 44px;
  }

  .content-item:not(:last-child) {
    margin-bottom: 36px;
  }

  .item-header {
    margin-bottom: 4px;
  }

  .person-name {
    display: inline-block;
    margin-right: 24px;
  }

  @media (max-width: 992px) {
    .page-header {
      padding-top: 100px;
    }

    .content-card {
      transform: translateY(0);
    }
  }
}
</style>
