<template>
    <div class="calendar" :class="{hasSteps: calendar.hasSteps}">
        <div id="colorPicker" ref="colorPick" class="bg-brand-primary"></div>
        <div id="colorPicker2" ref="colorPick2" class="bg-brand-primary"></div>
        <div class="calendar-nav col-12 pb-5">
            <div class="col-2">
                <a @click="prevWeek" ><font-awesome-icon icon="chevron-left" class="brand-highlight-1"></font-awesome-icon></a>
            </div>
            <div class="col-8 calendar-week">
                {{calendar.calendarWeek}}
            </div>
            <div class="col-2 calendar-next">
                <a @click="nextWeek" v-if="weekIndex < 0"><font-awesome-icon icon="chevron-right" class="brand-highlight-1"></font-awesome-icon></a>
            </div>
        </div>
        <div class="calendar-body  col-12">
            <div class="col-1"></div>
            <div v-for="(day, i) in calendar.calendarDates" class="calendar-col col px-0 px-lg-3" :key="i">
                <div class="calendar-day pb-3">{{getText.global.days[i]}}</div>
                <div class="calendar-date " :class="{active: calendar.selectedFullDate === calendar.calendarFullDates[i]}"><a  class="text-white" @click="selectDate(i)">{{day}}</a></div>
            </div>
        </div>
        <div class="chart-label" v-if="primaryColor && calendar.hasSteps" :style="{color: primaryColor}">
            {{label}}
        </div>
        <BarChart
                v-if="primaryColor && calendar.hasSteps"
                class="d-flex w-100"
                ref="chart"
                :chartData="chartData"
                :options="chartOptions"
                :class="{noSteps: !calendar.hasSteps}"
                :key="reactiveTarget"
        >
        </BarChart>
        <div v-else-if="!primaryColor">
            <b-spinner></b-spinner>
        </div>
        <div class="nothing-logged py-5 pl-5" v-else>
            <div class="img-wrap">
                <img :src="'/img/nothing-logged-' + $store.getters.skin + '.svg' " onerror="javascript:this.src='/img/nothing-logged.svg'">
            </div>
            <h3>{{getText.loggedIn.fitness.nothingLogged}}</h3>
        </div>
    </div>
</template>
<script>
    import BarChart from "../components/BarChart.vue"
    import moment from "moment"
    export default {
        name: "Fitness",
        components: {
            BarChart
        },
        props:['calendar', 'target', 'label'],
        data() {
            return {
                weekIndex: 0,
                days: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
                primaryColor: '',
            };
        },
        mounted() {
            this.getColor()
        },
        methods: {
            prevWeek(){
                this.weekIndex --
                this.$emit('previous')
            },
            nextWeek(){
                this.weekIndex ++
                this.$emit('next')
            },
            selectDate(i){
                this.$emit('select-date', i)
            },
            getColor(){
                try {
                    let el = document.getElementById('colorPicker');
                    let color = window.getComputedStyle(el).getPropertyValue('background-color');
                    this.primaryColor = color
                }catch(e){
                    this.primaryColor =  '#fff'
                }
            }
        },
        computed:{
            weeklySteps(){
                return this.calendar.weeklySteps
            },
            chartData() {
                return {
                    labels:
                        [this.label, this.label, this.label, this.label, this.label, this.label, this.label],
                    datasets: [
                        {
                            data: this.weeklySteps,
                            backgroundColor: this.primaryColor,
                            showLine: false
                        },
                        {
                            data: this.weeklySteps,
                            backgroundColor: this.secondaryColor,
                            showLine: false
                        },
                    ]
                }
            },
            chartOptions() {
                return {
                    responsive: true,
                    legend: {
                        display: false
                    },
                    layout: {
                        padding: {
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 280
                        },
                    },
                    scales: {
                        xAxes: [{
                            categoryPercentage: 0.5,
                            barPercentage: 1.0,
                            gridLines: {
                                drawBorder: false,
                                display: false,
                            },
                            ticks: {
                                drawOnChartArea: false,
                                beginAtZero: false,
                                display: false
                            }
                        }],
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                display: true,
                                suggestedMax: this.reactiveTarget
                            }
                        }]
                    },
                    annotation:{
                        annotations: [
                            {
                                type: 'line',
                                mode: 'horizontal',
                                scaleID: 'y-axis-0',
                                value: this.reactiveTarget,
                                borderColor: 'grey',
                                borderWidth: 1,
                                borderDash: [3, 3],
                                label: {
                                    enabled: true,
                                    content: this.getText.global.target + this.reactiveTarget,
                                    position: 'right',
                                    fontFamily: 'DMSans',
                                    backgroundColor: 'transparent',
                                    fontColor: this.primaryColor,
                                    yAdjust: 12
                                }
                            }
                        ]
                    }
                }
            },
            secondaryColor(){
                //TODO:: use color picker 2 div
                if(this.$store.getters.skin === 0){
                    return '#5ea0a1'
                }else if(this.$store.getters.skin === 13){
                    return '#65b32e'
                }else if(this.$store.getters.skin === 17){
                    return '#E63F51'
                } else return '#c4c4c4'
            },
            reactiveTarget(){
                return this.target ? this.target : 10000
            }
        },
        watch:{
            weeklySteps(newval){
            },
            $refs(newVal){
                this.getColor()
            }
        }
    }
</script>
<style lang="less">
    .calendar{
        &.hasSteps{
            margin-bottom: -280px;
        }
        .nothing-logged{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .img-wrap{
                height: 80px;
                width: 80px;
            }
            h3{
                font-family: 'FilsonProLight', sans-serif;
            }
        }
        font-size: 1.9rem;
        color: #fff;
        width: 100%;
        text-align: center;
        span{
            text-align: right;
        }
        &>div{
            display: inline-flex;
        }
        .calendar-next{
            text-align: right;
        }
        .calendar-week{
            text-align: center;
        }
        .calendar-body {
            font-size: 1.9rem;
            display: flex;
            .calendar-col {
                flex-direction: column;
                text-align: center;
                .calendar-date{
                    height: 40px;
                    width: 40px;
                    margin: auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    a{
                        font-family: 'Roboto', sans-serif;
                    }
                    &.active{
                        border-radius: 80%;
                        box-shadow: 0 0 3px 2px #d4d4d4;
                        font-family: 'FilsonProBold', sans-serif;
                    }
                }
            }
        }
        .chart-label{
            position: absolute;
            font-size: 1.4rem;
            left: -15px;
            top: 350px;
            transform: rotate(-90deg);
        }
        .noSteps{
            margin: auto;
            align-items: center;
            background-size: 70%;
            background-repeat: no-repeat;
            background-position: center;
            canvas{
                display: none!important;
            }
        }
        @media only screen and (min-width: 769px){
            .noSteps{
                height: 300px;
            }
        }
        @media only screen and (max-width: 768px) {
            .noSteps{
                height: 200px;
                max-width: 320px;
                text-align: center;
                margin:auto;
            }
        }
    }
</style>