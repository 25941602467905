<template>
  <div id="TherapyQuestionnaire" class="content-container p-5">
    <div class="w-100 px-5 d-flex justify-content-end">
      <button class="therapyBtn2" @click="goBack" style="max-width: 150px">Back</button>
    </div>

    <div v-for="q in form.top_questions">
      <TherapyTextQuestion :required="q.required" :label="q.label" :item="q.answer" @update="(data)=> q.answer = data" v-if="q.type === 'text'"/>
      <TherapyDateOfBirth :required="q.required" :label="q.label" :item="q.answer" @update="(data)=> q.answer = data" v-if="q.type === 'dob'"/>
      <TherapyPhoneNo :required="q.required" :label="q.label" :item="q.answer" @update="(data)=> q.answer = data" v-if="q.type === 'phone'"/>
      <TherapyChoiceBox :required="q.required" :label="q.label" :item="q.answer" :options="q.options" @update="(data)=> q.answer = data" v-if="q.type === 'choice'"/>
      <TherapyTextBox :required="q.required" :label="q.label" :item="q.answer" @update="(data)=> q.answer = data" :rows="10" v-if="q.type === 'longText'"/>
      <TherapySelectQuestion :required="q.required" :label="q.label" :item="q.answer" :options="q.options" @update="(data)=> q.answer = data" v-if="q.type === 'select'"/>
    </div>
    <div class="mb-5">
      <label>Date Completed</label>
      <div class="today">{{ form.date_completed ? form.date_completed : today }}</div>
    </div>
    <hr>
    <div>
      <table class="mt-5">
        <col style="width:40%">
        <col style="width:15%">
        <col style="width:15%">
        <col style="width:15%">
        <col style="width:15%">
        <thead>
          <tr>
            <th style="text-align: left; font-size: 1.25rem; line-height: 1.3rem">{{form.questionnaire_explanation}}</th>
            <th>Never</th>
            <th>Sometimes</th>
            <th>Often</th>
            <th>Always</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(q,i) in questions">
            <td>{{i + 1}}. {{ q.question }}</td>
            <td v-for="opt in form.questionnaire_options">
              <div class="toggleBtn" :class="{selected: questions[i].answer === opt}" @click="()=> {questions[i].answer = opt}"></div>
            </td>
<!--            <td><div class="toggleBtn" :class="{selected: questions[i].answer === 'Never'}" @click="()=> {questions[i].answer = 'Never'}"></div></td>-->
<!--            <td><div class="toggleBtn" :class="{selected: questions[i].answer === 'Sometimes'}" @click="()=> {questions[i].answer = 'Sometimes'}"></div></td>-->
<!--            <td><div class="toggleBtn" :class="{selected: questions[i].answer === 'Often'}" @click="()=> {questions[i].answer = 'Often'}"></div></td>-->
<!--            <td><div class="toggleBtn" :class="{selected: questions[i].answer === 'Always'}" @click="()=> {questions[i].answer = 'Always'}"></div></td>-->
          </tr>
        </tbody>
      </table>
    </div>
    <div class="my-5">
      <div v-for="q in form.bottom_questions" class="w-100 bottom-questions">
        <TherapyTextQuestion :required="q.required" :label="q.label" :item="q.answer" @update="(data)=> q.answer = data" v-if="q.type === 'text'"/>
        <TherapyDateOfBirth :required="q.required" :label="q.label" :item="q.answer" @update="(data)=> q.answer = data" v-if="q.type === 'dob'"/>
        <TherapyPhoneNo :required="q.required" :label="q.label" :item="q.answer" @update="(data)=> q.answer = data" v-if="q.type === 'phone'"/>
        <TherapyChoiceBox :required="q.required" :label="q.label" :item="q.answer" :options="q.options" @update="(data)=> q.answer = data" v-if="q.type === 'choice'"/>
        <TherapyTextBox :required="q.required" :label="q.label" :item="q.answer" @update="(data)=> q.answer = data" :rows="10" v-if="q.type === 'longText'"/>
        <TherapySelectQuestion :required="q.required" :label="q.label" :item="q.answer" :options="q.options" @update="(data)=> q.answer = data" v-if="q.type === 'select'"/>
      </div>
    </div>
    <button class="therapyBtn my-5 float-right" :disabled="buttonDisabled" @click="submitForm">
      <span v-if="formSubmitted" class="button-loading"></span>
      <span v-else-if="formSaved">Saved!</span>
      <span v-else>Submit Form</span>
    </button>
  </div>
</template>

<script>
import TherapyTextQuestion from "@/components/TherapyPortal/Questions/TherapyTextQuestion.vue";
import TherapySelectQuestion from "@/components/TherapyPortal/Questions/TherapySelectQuestion.vue";
import moment from "moment";
import forms from '../../assets/therapyForms.json'
import TherapyPhoneNo from "./Questions/TherapyPhoneNo.vue";
import TherapyDateOfBirth from "./Questions/TherapyDateOfBirth.vue";
import TherapyTextBox from "./Questions/TherapyTextBox.vue";
import TherapyChoiceBox from "./Questions/TherapyChoiceBox.vue";
export default {
  name: 'TherapyQuestionnaire',
  components: {
    TherapyChoiceBox,
    TherapyTextBox,
    TherapyDateOfBirth,
    TherapyPhoneNo,
    TherapySelectQuestion, TherapyTextQuestion},
  data() {
      return {
        name: 'Name Here',
        form_exists_in_db: false,
        relationship: null,
        relationship_options: ['Parent','Other'],
        loading: false,
        form: forms[this.$route.params.id],
        questions: [],
        formSubmitted: false,
        formSaved: false
      }
    },
    computed: {
      buttonDisabled() {
        let disabled = false;
        this.questions.forEach(q => {
          if(!q.answer) disabled = true;
        })
        return disabled;
      },
      today() {
        return moment().format('DD/MM/YYYY');
      }
    },
    mounted() {
      this.getData()
    },
    methods: {
      goBack() {
        if(this.$route.query.back) {
          this.$router.push('/service-dashboard/'+this.$route.query.back);
        } else {
          this.$router.push('/start');
        }
      },
      async submitForm() {
        if(this.buttonDisabled) return;
        this.formSubmitted = true;
        const jsonString = JSON.stringify({
          date_completed: this.today,
          questions: this.questions,
          top_questions: this.form.top_questions,
          bottom_questions: this.form.bottom_questions
        });
        const res = await this.api({
          path: this.form_exists_in_db ? 'api/u/cmh/update-intake-form':'api/u/cmh/save-intake-form',
          form_name: this.$route.params.id,
          content: btoa(unescape(encodeURIComponent(jsonString)))
        });
        if(res.success) {
          this.formSaved = true;
          this.formSubmitted = false;
          this.form_exists_in_db = true;
          this.goBack();
        }
      },
      mountFormTemplate(data = false) {
        if(!data) {
          this.questions = this.form.questionnaire;
        } else {
          this.questions = this.form.questionnaire;
          if(data.date_completed) this.form.date_completed = data.date_completed;
          if(this.form.hasOwnProperty('top_questions')) {
            this.form.top_questions.forEach(q => {
              data.top_questions.forEach(a=> {
                if(q.id === a.id) q.answer = a.answer;
              })
            });
          }
          if(this.form.hasOwnProperty('bottom_questions')) {
            this.form.bottom_questions.forEach(q => {
              data.bottom_questions.forEach(a=> {
                if(q.id === a.id) q.answer = a.answer;
              })
            });
          }
          this.questions.forEach(q => {
            data.questions.forEach(a => {
              if(q.id === a.id) {
                q.answer = a.answer;
              }
            })
          })
        }
      },
      async getData() {
        let params = {
          path: 'api/u/cmh/get-intake-form',
          form_name: this.$route.params.id
        }
        const res = await this.api(params)
        if(res.success) {
          this.form_exists_in_db = true;
          this.mountFormTemplate(JSON.parse(atob(res.data.content)));
          this.loading = false;
        } else {
          this.mountFormTemplate();
          this.loading = false;
        }
      }
    },
  watch: {
    formSaved(val) {
      if(val) {
        setTimeout(()=> {this.formSaved=false}, 5000);
      }
    }
  },
}
</script>

<style lang="less">
#TherapyQuestionnaire {
  .bottom-questions {
    .form-group {
      max-width: 900px !important;
      margin: auto;
    }
  }
  .btnBox, table {
    width: 100%;
    max-width: 900px;
    margin: auto;
  }
  table {
    font-size: 1.3rem;
    td {
      padding: 10px 5px;
    }
    th {
      font-size: 1.45rem;
      font-family: FilsonProBold, Arial, Helvetica, sans-serif;
      padding: 10px 5px;
      text-align: center;
    }
    thead {
      background-color: #F5F5F5;
    }
  }

  .today {
    margin-top: 8px;
    font-size: 1.25rem;
  }
}
</style>