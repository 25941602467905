<template>
    <div id="KnowledgeCheck">
        <div class="question">
            <h4>{{question.question_label}}</h4>
        </div>
        <div class="input-wrap">
            <b-form-checkbox-group v-model="userAnswer" stacked v-if="question.sur_type === 'multiple'">
                <label v-for="(option,i) in question.options" :key="i" class="py-2">
                    <b-checkbox :value="option.id" :disabled="showCorrect || showIncorrect"></b-checkbox>
                    <div class="circle">
                        <i class="fa fa-check check" v-if="userAnswer.includes(option.id) && !showIncorrect "></i>
                        <i class="fa fa-check check" v-if="module.correct_answer.split(',').includes(option.id) && showIncorrect "></i>
                        <i class="fa fa-times check incorrect" v-if="showIncorrect && !module.correct_answer.split(',').includes(option.id)"></i>
                    </div>
                    <p class="option">{{option.label}}</p>
                </label>
            </b-form-checkbox-group>
            <b-form-radio-group v-model="userAnswer[0]" stacked v-if="question.sur_type === 'bool'">
                <label v-for="(option,i) in question.options" :key="i" class="py-2">
                    <b-radio :value="option.id" :disabled="showCorrect || showIncorrect"></b-radio>
                    <div class="circle">
                        <i class="fa fa-check check" v-if="userAnswer.includes(option.id) && !showIncorrect "></i>
                        <i class="fa fa-check check" v-if="module.correct_answer.toLowerCase() === option.id.toLowerCase() && showIncorrect "></i>
                        <i class="fa fa-times check incorrect" v-if="showIncorrect && module.correct_answer !== option.id"></i>
                    </div>
                    <p class="option">{{option.label}}</p>
                </label>
            </b-form-radio-group>
        </div>
        <b-button @click="submitAnswer"
                  class="cta-button py-3 px-5 mt-4 mb-5"
                  :disabled="userAnswer.length < 1 || showCorrect || showIncorrect"
        >
            {{componentText.submit}}
        </b-button>
        <div class="display-answer pb-5" v-if="showCorrect || showIncorrect">
            <h3 v-if="showCorrect" class="brand-primary pb-3">{{componentText.correct}} <i class="fa fa-check"></i></h3>
            <h3 class="incorrect" v-else>{{componentText.incorrect}} <i class="fa fa-times"></i></h3>
            <p v-if="showCorrect" v-html="module.output_text_correct"></p>
            <p v-html="module.output_text_incorrect" v-else></p>
            <b-button @click="$emit('next')" class="cta-button py-3 px-5 mt-5">{{componentText.next}}</b-button>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import axios from "axios";
    import config from "../../config/constants";
    export default {
        name: "KnowledgeCheck",
        props:[
            'module'
        ],
        data() {
            return {
                question: {},
                userAnswer: [],
                showCorrect: false,
                showIncorrect: false,
            };
        },
        mounted(){
            this.getQuestion()
        },
        methods:{
            async getQuestion(){
                const payload = {
                    user_id: this.$store.getters.user_id,
                    question_id: this.module.question_id
                }
                let res = await axios.post(config.api_env + '/application/api-hc/get-question', payload, config.options)
                if(res.data.success){
                    this.question = res.data.question
                }
            },
            submitAnswer(){
                if(this.userAnswer.join() === this.module.correct_answer){
                    this.showCorrect = true
                }else this.showIncorrect = true
            }
        },
        computed:{
            componentText() {
                return this.getText.loggedIn.wellbeing.knowledgeCheck;
            },
        },
    };
</script>
<style lang="less">
    #KnowledgeCheck {
        .question{
            h4{
                font-size: 2.2rem;
                color: #4a4d4e;
            }
        }
        .input-wrap{
            label::before, label::after {
                visibility: hidden;
            }
            label{
                width: 100%;
                display: inline-flex;
                align-items: center;
                border-bottom: solid 1px #a0a0a052;
                .custom-control{
                    padding: 0;
                }
            }
            .circle{
                width: 38px;
                height: 38px;
                border-radius: 50%;
                border: 1px solid #4a4d4e;
                background-color: #f4f4f4;
                position: relative;
                margin-right: 20px;
                cursor: pointer;
                .check{
                    position: absolute;
                    font-size: 35px;
                    bottom: 1px;
                    left: 4px;
                    color: #87D2DD;
                    &.incorrect{
                        color: red;
                        left: 6px;
                        bottom: 0;
                    }
                }
            }
            .option{
                font-size: 1.8rem;
                color: #4a4d4e;
            }
        }
        .display-answer{
            h3{
                font-family: DMSans;
                letter-spacing: 0;
                font-size: 2.5rem;
                padding: 0;
                &.incorrect {
                    color: red;
                }
            }
            p{
                font-size: 1.6rem;
                color: #4a4d4e;
            }
        }
    }
</style>
