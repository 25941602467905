<template>
  <div id="Comment" v-bind:class="animationClassObject">
    <div class="comment-container">
      <div class="d-flex flex-row">
        <div class="comment-avatar-container">
          <img class="img-fluid clickable" @click="$router.push('/community/user/' + comment.user_profile_id)" :src="comment.user_avatar"  v-if="comment.user_avatar !== null"/>
          <img
            class="img-fluid shadow clickable"
            @click="$router.push('/community/user/' + comment.user_profile_id)"
            :src="'https://eu.ui-avatars.com/api/?name=' + comment.user_firstname + '+' + comment.user_lastname + '&size=128'"
            v-else
          />
        </div>
        <div
          class="
            brand-secondary
            d-flex
            flex-column
            align-items-start
            justify-centent-start
            flex-grow
          "
        >
          <div class="comment-content-container d-flex flex-column"
            v-b-tooltip.hover :title="getDateHours(comment.created_on)"
          >
            <div class="comment-content-user clickable" @click="$router.push('/community/user/' + comment.user_profile_id)">
              <b>{{comment.user_firstname}} {{comment.user_lastname}} <span v-if="comment.user_title !== null">- {{comment.user_title}}</span></b>
            </div>
            <div class="comment-content" v-html="comment.content"></div>
            <div class="comment-like-value-container" v-if="comment.likes_count !== 0">
              <div class="comment-like-value brand-highlight-1" >
                <i
                  class="fas fa-heart mr-3 clickable"
                  v-if="comment.has_user_liked"
                ></i>
                <i class="far fa-heart mr-3 clickable" v-else></i>
                <span>{{ comment.likes_count }}</span>
              </div>
            </div>
          </div>
          <div class="comment-button-container">
            <span
              class="comment-button"
              v-if="!comment.has_user_liked"
              @click="toggleLikeComment()"
              >Like</span
            >
            <span class="comment-button" v-else @click="toggleLikeComment()"
              >Unlike</span
            >
            <span class="comment-button" v-if="false">Reply</span>
            <span
              class="comment-button"
              v-if="false"
              >Edit</span
            >
            <span class="comment-button" v-if="comment.is_user_owner" @click="deleteComment()">Delete</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import config from "../../config/constants";

export default {
  name: "Comment",
  components: {},
  props: ["comment"],
  data() {
    return {
      content: "",
      isDeleted: false,
    };
  },
  mounted() {},
  methods: {
    async deleteComment() {
      let self = this;
      const payload = {
        user_id: this.$store.getters.user_id,
        comment_id: this.comment.id,
      };
      let res = await axios.post(
        config.api_env + "/application/api/u/community/delete-comment",
        payload,
        config.options
      );
      if (res.data.success) {
        this.isDeleted = true;
        setTimeout(() => {
          self.$parent.removeComment(self.comment.id);
        }, 1000);
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login").catch((err) => {});
      }
    },
    async toggleLikeComment() {
      console.log('hitting this boi?');
      const payload = {
        user_id: this.$store.getters.user_id,
        comment_id: this.comment.id,
      };
      let res = await axios.post(
        config.api_env + "/application/api/u/community/toggle-like-comment",
        payload,
        config.options
      );
      if (res.data.success) {
        this.comment.has_user_liked = res.data.is_liked;
        if (res.data.is_liked) {
          this.comment.likes_count++;
        } else {
          this.comment.likes_count--;
        }
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login").catch((err) => {});
      }
    },
  },
  computed: {
    animationClassObject: function () {
      return {
        "fade-out": this.isDeleted,
        "fade-in": this.comment.isNew,
      };
    },
    componentText() {
      return this.getText.loggedIn;
    },
  },
};
</script>
<style lang="less">
#Comment {
  padding-top: 10px;
  .comment-container {
    padding-bottom: 10px;
    .flex-grow {
      flex-grow: 1;
    }
    .comment-avatar-container {
      min-width: 100px;
      display: flex;
      padding-right: 15px;
      .img-fluid{
        width: 80px;
        height: 80px;
        object-fit: cover;
        border-radius: 100px;
      }
    }
    .comment-content-container {
      position: relative;
      display: inline-flex;
      font-size: 1.6rem;
      padding: 10px 10px 15px 10px;
      background-color: #eee;
      border-radius: 16px;
      word-wrap: break-word;
      flex-shrink: 1;
      .comment-content{
        white-space: pre-line;
      }
      .comment-content-user {
        padding-bottom: 10px;
      }
      .comment-like-value-container{
        position: absolute;
        bottom: -10px;
        right: 5px;
        padding: 0 5px;
        background-color: white;
        border-radius: 12px;
        border: 1px solid;
        min-width: 50px;
        text-align: center;
      }
    }
    .comment-button-container {
      font-family: "FilsonProBold", sans-serif;
      font-size: 1.6rem;
      font-weight: bold;
      padding-left: 15px;
      .comment-button {
        padding: 0 6px;
        padding-bottom: 5px;
        text-decoration: underline;
        border-right: 1px solid;
        cursor: pointer;
      }
      .comment-button:first-child {
        padding-left: 0;
      }
      .comment-button:last-child {
        padding-right: 0;
        border-right: none;
      }
    }
  }
}
</style>
