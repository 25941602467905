<template>
  <div id="ChallengeProgressCard" @mouseover="hover = true" @mouseleave="mouseLeave">
    <div class="deleteOverLay" v-if="deleteMode">
      <div class="deleteWarning">Are you sure you want to delete this Challenge?</div>
      <div class="deleteWarning">This action cannot be undone.</div>
      <div class="btnHolder">
        <div class="challengesButton" @click="deleteItem">Yes</div>
        <div class="challengesButton" @click="deleteMode = false">No</div>
      </div>
    </div>
    <div class="trashBox">
      <i @click="deleteMode = true" v-if="hover && !deleteMode" class="fa-solid fa-trash-can"></i>
    </div>
    <div class="backgroundImage" :style="{backgroundImage: 'url('+imageUrl+')'}"></div>
    <div class="textContainer" :class="{completed: finished}">
      <div class="innerTextContainer" >
        <div class="timeRemaining text-right">{{daysRemaining}}</div>
<!--        <div class="today">{{ cardDetails.today }}</div>-->
        <div class="goal">{{ cardDetails.goal_text }}</div>
        <div class="progressText"><span>{{cardDetails.complete}}</span> / {{cardDetails.complete_by}}</div>
        <b-progress
            v-if="!finished"
            :value="cardDetails.percent" :max="1"
            class="progressBar w-100">
        </b-progress>
        <div v-else class="completedSpacer"></div>
      </div>
    </div>
    <div class="completedContainer" v-if="finished">
      <div class="completedText">
        Way to go! You completed this Challenge!
      </div>
      <div class="completedTick">
        <i class="fa-solid fa-check"></i>
      </div>

    </div>
  </div>
</template>

<script>

import moment from "moment";

export default {
  name: 'ChallengeProgressCard',
  props: ['challenge'],
  data() {
    return {
      hover: false,
      deleteMode: false
    }
  },
  methods: {
    mouseLeave() {
      this.hover = false;
      this.deleteMode = false;
    },
    deleteItem() {
      this.deleteMode = false;
      this.$emit('deleteChallenge',this.challenge.id)
    }
  },
  computed: {
    finished() {
      return Boolean(this.challenge.accomplished_on)
    },
    daysRemaining() {
      const end = moment(this.challenge.end_date_time).startOf('day');
      const start = moment().startOf('day');
      if(end < start) return "Ended"
      const diff = end.diff(start, 'days');
      if(diff === 0) return 'Ends today';
      if(diff === 1) return 'Ends tomorrow';
      return diff + ' days remaining - ' + (end.format('Do MMM'));
    },
    winningStreak() {
      return false
    },
    type() {
      if(this.challenge.target_distance_meters) return 'distance';
      if(this.challenge.target_time_seconds) return 'activity';
      return 'steps';
    },
    cardDetails() {
      switch (this.type) {
        case "steps":
          return {
            goal_text: `Goal: ${this.addCommasToInteger(this.challenge.target_steps)} steps by ${this.getFormatedDate(this.challenge.end_date_time, "DD MMM")}`,
            // today: `Today: ${this.addCommasToInteger(this.challenge.today)} steps`,
            complete_by: this.addCommasToInteger(this.challenge.target_steps),
            complete: this.addCommasToInteger(this.challenge.completed_steps),
            percent: this.challenge.completed_steps / this.challenge.target_steps,
          };
        case "activity":
          return {
            goal_text: `Goal: ${this.addCommasToInteger(this.challenge.target_time_seconds/60)} mins / week`,
            // today: `Today: ${this.challenge.goal} mins`,
            complete_by: 'week',
            complete: this.addCommasToInteger(this.challenge.completed_time_seconds/60),
            percent: this.challenge.completed_time_seconds / this.challenge.target_time_seconds,
          };
        case "distance":
          return {
            goal_text: `Goal: ${this.goalDistance} ${this.unit} by ${this.getFormatedDate(this.challenge.end_date_time, "DD MMM")}`,
            // today: `Today: ${this.challenge.today.toFixed(1)}${this.challenge.unit}`,
            complete_by: `${this.goalDistance} ${this.unit === "Kilometers" ? 'km': 'Miles'}`,
            complete: `${this.completedDistance}`,
            percent: this.challenge.completed_distance_meters / this.challenge.target_distance_meters,
          }
      }
    },
    unit() {
      return this.$store.getters.distanceUnit;
    },
    completedDistance() {
      if(this.type !== 'distance') return null;
      let distance = this.unit === "Miles" ? this.convertMetersToMiles(this.challenge.completed_distance_meters) : this.challenge.completed_distance_meters / 1000;
      return Math.round(distance * 100) / 100;
    },
    goalDistance() {
      if(this.type !== 'distance') return null;
      let distance = this.unit === "Miles" ? this.convertMetersToMiles(this.challenge.target_distance_meters) : this.challenge.target_distance_meters / 1000;
      return Math.round(distance * 100) / 100;
    },
    imageUrl() {
      switch (this.type) {
        case 'steps': return "/img/challenges/types/steps.png";
        case 'distance': return "/img/challenges/types/distance.png";
        case 'activity': return "/img/challenges/types/activity.png";
      }
    }
  }
}
</script>

<style lang="less">
#ChallengeProgressCard {
  width: 100%;
  aspect-ratio: 347 / 171;
  border-radius: 25px;
  overflow: hidden;
  position: relative;
  margin-bottom: 10px;
  margin-top: 10px;
  .backgroundImage {
    height: 100%;
    width: 40%;
    position: absolute;
    z-index: 201;
    left: -15px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .deleteOverLay {
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.85);
    z-index: 205;
    position: absolute;
    top: 0;
    left: 0;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    .deleteWarning {
      text-align: center;
      color: white;
      font-size: 1.3rem;
      margin-bottom: 5px;
    }
    .btnHolder {
      margin-top: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 15px;
      width: 80%;
      .challengesButton {
        color: white;
      }
    }
  }
  .trashBox {
    width: 100%;
    position: absolute;
    z-index: 205;
    top: 8px;
    left: 15px;
    i {
      color: darkred;
      font-size: 20px;
      cursor: pointer !important;
    }
  }
  .textContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    z-index: 202;
    background: linear-gradient(269.71deg, #292824 0.28%, #292824 69.21%, rgba(217, 217, 217, 0) 98.17%);
    &.completed{
      background: linear-gradient(90deg, rgba(78, 160, 222, 0) 0%, #4EA0DE 33.33%, #00528F 100%);
    }
    color: white;
    justify-content: end;
    display: flex;
    padding: 20px 15px 20px 20px;
    font-size: 1.3rem;
    .innerTextContainer {
      width: 70%;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      * {
        margin-bottom: 5px;
      }
      .progressText {
        font-size: 1.4rem;
        span {
          font-size: 3rem;
          font-family: FilsonProBold, Arial, Helvetica, sans-serif;
        }
      }
      .completedSpacer {
        height: 50px;
        width: 100%;
      }
      .progressBar {
        height: 1.3rem;
        border-radius: 10px !important;
        div {
          background: linear-gradient(90deg, #4EA0DE 0%, #4EA0DE 33.33%, #00528F 100%) !important;
          height: 100%;
          border-radius: 0 8px 8px 0;
        }
      }
    }

  }
  .completedContainer {
    clip-path: polygon(0 70%, 75% 70%, 100% 0%, 100% 100%, 100% 100%, 0 100%);
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 65%;
    display: flex;
    justify-content: start;
    align-items: end;
    background-color: #00000047;
    z-index: 203;
    .completedText {
      width: 80%;
      text-align: center;
      height: 30px;
      display: flex;
      align-items: end;
      justify-content: center;
      color: white;
      padding-bottom: 10px;
      font-size: 1.2rem;
    }
    .completedTick {
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 80px;
      i {
        color: #0BDA51;
        font-size: 4.5rem;
      }
    }
  }
}
</style>