/**
 * interface emitEventInterface{
 *    action: string
 *    payload: object
 * }
 */

import { actionPing, actionSetName } from "@/socket/actions";
import {
  EVENT_WS_CONNECTED,
  EVENT_WS_DISCONNECTED,
  EVENT_WS_ERROR
} from "@/socket/consts";

export class Websocket {
  constructor({ vue, appTitle, wsUrl, emitter, pingInterval = 25000 }) {
    this.$vue = vue;
    this.application = appTitle;
    this.websocketUrl = wsUrl;
    this.ws = null;
    this.pingIntervalId = null;
    this.pingInterval = pingInterval;
    this.emitter = emitter;
  }

  /**
   * register user in websocket server
   */
  setName() {
    try {
      this.send(
        actionSetName(this.application, this.$vue.$store.state.user.user_token)
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e);
    }
  }

  disconnect() {
    try {
      this.ws.close();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e);
    }
  }
  send(action) {
    try {
      const payload = JSON.stringify(action);
      this.ws.send(payload);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e);
    }
  }
  ping() {
    try {
      this.send(actionPing());
    } catch (e) {
      clearInterval(this.pingIntervalId);
      // eslint-disable-next-line no-console
      console.warn(e);
    }
  }
  isOpen() {
    try {
      return this.ws.readyState === WebSocket.OPEN;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e);
      return false;
    }
  }

  connect() {
    try {
      if (this.ws === null) {
        this.ws = new WebSocket(this.websocketUrl);
        this.ws.onopen = () => {
          this.emitter.trigger(EVENT_WS_CONNECTED, this);
        };
        this.ws.onerror = () => {
          //ws server is not available ?
          this.emitter.trigger(EVENT_WS_ERROR, this);
        };
        this.ws.onmessage = e => {
          //dataset as emitEventInterface
          const { action, payload } = JSON.parse(e.data);
          if ("undefined" !== typeof action) {
            const dataset = { ...payload, websocketInstance: this}
            this.emitter.trigger(action, dataset);
          }
        };
        this.ws.onclose = () => {
          this.emitter.trigger(EVENT_WS_DISCONNECTED, this);
        };
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e);
    }
  }
}