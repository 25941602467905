<template>
    <div id="HomeCategories" class="my-5 pb-5">
        <div class="mb-3">
            <h2 class="home-title">Previous Categories Watched</h2>
            <div class="home-title-line bg-brand-secondary"></div>
        </div>
        <div class="row pb-5">
            <div class="col-md-12 col-lg-4">
                <CategoryCard></CategoryCard>
            </div>
            <div class="col-md-12 col-lg-4">
                <CategoryCard></CategoryCard>
            </div>
            <div class="col-md-12 col-lg-4">
                <CategoryCard></CategoryCard>
            </div>
            <div class="col-md-12 col-lg-4">
                <CategoryCard></CategoryCard>
            </div>
            <div class="col-md-12 col-lg-4">
                <CategoryCard></CategoryCard>
            </div>
            <div class="col-md-12 col-lg-4">
                <CategoryCard></CategoryCard>
            </div>
        </div>
    </div>
</template>

<script>
import CategoryCard from "@/components/Home/New/CategoryCard.vue"


export default {
    name: "HomeCategories",
    props: {
        isEarliest: Boolean,
    },
    components: {
        CategoryCard,
    },
    data() {
        return {

        };
    },
    mounted() {

    },
};
</script>

<style lang="less">
#HomeCategories {
    .home-title {
        font-size: 2.4rem;
        font-family: "FilsonProBold", sans-serif;
        text-align: left;
        margin-bottom: 10px;
    }

    .home-title-underline {
        margin-left: 5px;
        height: 3px;
        width: 50px;
    }
}
</style>
