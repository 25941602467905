var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"NewMediaPost"},on:{"click":function($event){return _vm.close()}}},[_c('link',{attrs:{"href":"https://cdnjs.cloudflare.com/ajax/libs/cropperjs/1.5.12/cropper.css","rel":"stylesheet"}}),_c('section',{staticClass:"new-media-post-modal fade-in",on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"new-media-post-modal-header bg-brand-secondary"},[(_vm.view === 'preview')?_c('span',[_vm._v("Create Post")]):_c('span',[_vm._v("Crop Image")])]),_c('div',{staticClass:"modal-content-container"},[(_vm.view === 'upload')?_c('div',{staticClass:"flex-row new-media-post-file-upload"},[_c('div',{staticClass:"drop-zone clickable",attrs:{"id":"dropZone"},on:{"drop":function($event){return _vm.dropHandler($event)},"dragenter":function($event){$event.preventDefault();},"dragover":function($event){$event.preventDefault();},"dragleave":function($event){$event.preventDefault();}}},[_c('p',[_vm._v("Drag a file to this drop zone to upload...")])])]):_vm._e(),_c('div',{staticClass:"flex-row new-media-post-file-preview",class:{ invisible: _vm.view === 'upload' }},[_c('div',{staticClass:"new-media-post-content-container",class:{ invisible: _vm.view === 'crop' }},[_c('div',{staticClass:"d-flex flex-row justify-content-end"},[_c('div',{staticClass:"d-flex flex-row new-post-header-container"},[_c('span',{staticClass:"new-post-topic-selector ml-auto"},[_vm._v("posting to "),(this.topics.length !== 0)?_c('v-select',{attrs:{"options":_vm.topicSelectionOptions,"closeOnSelect":true,"label":"label"},model:{value:(_vm.selectedTopic),callback:function ($$v) {_vm.selectedTopic=$$v},expression:"selectedTopic"}}):_c('b-spinner')],1)])]),_c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"new-post-content-container"},[_c('div',[_c('editor',{attrs:{"api-key":"b4gid2ob2kaldj1kqtkuyxxt9jjv4xop73ank75kchgdimcy","init":{
                    height: 300,
                    menubar: false,
                    plugins: 'autolink link paste',
                    toolbar: 'bold autolink italic underline',
                    branding: false,
                    statusbar: false,
                    paste_as_text: true,
                    paste_block_drop: true,
                  }},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}})],1)])])]),_c('div',{staticClass:"\n            d-flex\n            flex-row\n            justify-content-center\n            new-post-image-preview-container\n            pb-3\n          "},[_c('div',{staticClass:"new-post-image-preview",attrs:{"id":"image-preview"}},[(_vm.view === 'preview' && _vm.mediaType === 'image')?_c('div',{staticClass:"\n                image-preview-buttons\n                d-flex\n                flex-column\n                align-items-center\n                justify-content-center\n              "},[_c('button',{staticClass:"btn cta-button px-5 py-3",on:{"click":function($event){return _vm.initializeCropImage()}}},[_vm._v(" Crop ")])]):_vm._e()])]),_c('div',{staticClass:"new-post-media-buttons-container"},[_c('div',{staticClass:"d-flex flex-row justify-content-around align-items-center"},[_c('button',{staticClass:"btn btn-outline border-brand-highlight-1 px-5 py-3",on:{"click":function($event){return _vm.close()}}},[_vm._v(" Close ")]),(_vm.view === 'preview')?_c('button',{staticClass:"btn cta-button px-5 py-3 ml-auto",on:{"click":function($event){return _vm.createPost()}}},[(!_vm.isSending)?_c('span',{staticClass:"fade-in"},[_vm._v("Post")]):_c('span',[(_vm.sendSuccess === null)?_c('b-spinner',{staticClass:"white button-spinner"}):_vm._e(),(_vm.sendSuccess === true)?_c('span',[_vm._v("Sent "),_c('i',{staticClass:"fas fa-check button-icon"})]):_vm._e(),(_vm.sendSuccess === false)?_c('span',[_c('i',{staticClass:"fas fa-times button-icon"})]):_vm._e()],1)]):_vm._e(),(_vm.view === 'crop')?_c('button',{staticClass:"btn cta-button px-5 py-3 ml-auto",on:{"click":function($event){return _vm.cropImage()}}},[_vm._v(" Crop ")]):_vm._e()])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }