<template>
  <div id="MyProgressCourseCard">
    <div class="innerCard" @click="$router.push(item.path)">
      <img :src="item.course_image_url" class="h-100"/>
      <div class="progress-bar-container">
        <b-progress :value="item.course_progress" :max="100" class="progressBar w-75">
        </b-progress>
      </div>

    </div>

    <div class="card-meta">
      <div class="card-title">{{item.course_title}}<br/><span>{{item.course_progress}}% completed</span></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyProgressCourseCard",
  props: ['item',]
}
</script>

<style lang="less">
#MyProgressCourseCard {
  width: 100%;
  padding: 5px;
  margin: 5px;
  .progress-bar-container {
    bottom: 20px;
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .progressBar {
    height: 1rem;
    z-index: 100;
    border-radius: 10px !important;
    background: grey;
    div {
      background-color: #0BDA51;
      border-radius: 0 4px 4px 0;
    }
  }
  .innerCard {
    border-radius: 20px;
    cursor: pointer;
    position: relative;
    width: 100%;
    aspect-ratio: 16 / 9;
    background-color: #0D1846;
    overflow: hidden;
  }
  .card-meta {
    margin-top: 5px;
    .card-title {
      font-size: 1.4rem;
      text-align: center;
      font-family: FilsonProBold, Arial, Helvetica, sans-serif;
      span {
        font-size: 1.2rem;
      }
    }
  }
}
</style>