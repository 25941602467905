<template>
  <video
      @progress="progress"
      @canplay="canPlay"
      :disablePictureInPicture="disablePictureInPicture"
      :autoplay="autoPlay" @ended="ended()"
      class="w-100"
      :poster="poster"
      :class="videoClass"
      :src="src" controls
      :ref="videoRef"
      controlsList="nodownload"
  >
  </video>
</template>
<script>

export default {
  components: {

  },
  name: "GAVideoPlayer",
  props: {
    src: {
      type: String,
    },
    videoClass: {
      type: String,
      default: null
    },
    poster: {
      type: String,
      default: null
    },
    autoPlay: {
      type: Boolean,
      default: false
    },
    disablePictureInPicture: {
      type: Boolean,
      default: false
    },
    videoRef: {
      type: String,
      default: 'videoPlayer'
    }
  },
  data() {
    return {
      isInitialLoad: true,
      isInitialEnd: true,
      isInitial25: true,
      isInitial50: true,
      isInitial75: true,
    };
  },
  mounted() {
    this.sendLoadEvent();
    let self = this;
    var vid = this.$refs[this.videoRef];
    vid.onplay = function () {
      if (self.isInitialLoad) self.sendInitialPlayEvent();
      self.isInitialLoad = false;
    };
    vid.ontimeupdate = function() {
      if (self.isInitial25 && vid.currentTime > vid.duration/4){
        self.isInitial25 = false;
        self.sendProgressEvent('25%');
      }
      if (self.isInitial50 && vid.currentTime > vid.duration/2){
        self.isInitial50 = false;
        self.sendProgressEvent('50%');
      }
      if (self.isInitial75 && vid.currentTime > (vid.duration/4)*3){
        self.isInitial75 = false;
        self.sendProgressEvent('75%');
      }
    }
    vid.onended = function () {
      if (self.isInitialEnd) self.sendEndEvent();
      self.isInitialEnd = false;
    };

  },
  beforeDestroy() {

  },
  methods: {
    progress(data) {
      this.$emit('progress',data)
    },
    canPlay(data) {
      this.$emit('canPlay',data)
    },
    ended() {
      this.$emit('ended');
    },
    gtag() {
      window.dataLayer.push(arguments);
    },
    sendLoadEvent() {
      let self = this;
      this.gtag('event', 'video-load', {
        'page_url': window.location.href,
        'video_src': self.src,
        'user_id': self.$store.getters.user.user.user_id,
      });
    },
    sendInitialPlayEvent() {
      let self = this;
      this.gtag('event', 'video-started', {
        'page_url': window.location.href,
        'video_src': self.src,
        'user_id': self.$store.getters.user.user.user_id,
      });
    },
    sendEndEvent() {
      let self = this;
      this.gtag('event', 'video-ended', {
        'page_url': window.location.href,
        'video_src': self.src,
        'user_id': self.$store.getters.user.user.user_id,
      });
    },
    sendProgressEvent(percentage) {
      let self = this;
      this.gtag('event', 'video-percentage', {
        'page_url': window.location.href,
        'video_src': self.src,
        'progress_percentage': percentage,
        'user_id': self.$store.getters.user.user.user_id,
      });
    },
  },
};
</script>
<style lang="less">

</style>