<template>
    <div id="BupaSso" class="bg-brand-secondary">
        <div class="d-flex flex-row align-items-center justify-content-center mx-auto container m-5">
            <Loader v-if="!errorMessage" />
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import Loader from '@/components/Loader.vue';
import ErrorModal from '@/components/GP/ErrorModal'
import config from "../config/constants";
import { mapState } from 'vuex'
export default {
    name: "LoginAs",
    components: {
        Loader,
        ErrorModal,
    },
    data() {
        return {
            errorMessage: false
        };
    },
    mounted() {
        if (this.$route.query.code) {
            this.resolveToken()
        } else {
            this.redirectWithError()
        }
    },
    methods: {
        async resolveToken() {
            const payload = {
                token: this.$route.query.code,
                source: "web"
            }
            let res = await this.axios.post(config.api_env + "/application/api/p/bupa-sso/security-token", payload, config.options)
            if (res.data.success) {
                const user = {
                    user_token: res.data.user.user_token,
                    user_info: res.data.user
                }
                await this.$store.dispatch('loginAs', user)
                await this.$router.push('/gp')
            } else {
                this.errorMessage = true;
                this.openErrorModal("Sorry, we've had a problem", "Please try again. <br /> <br /> If the problem persists please call us on <b>0345 608 0898</b>. We’re here to help Mon-Fri 8am-6pm, Sat 8am-4pm", true);
            }
        },
        openErrorModal(title, error, redirect) {
            const content = this.$createElement(ErrorModal, { props: { title, error, redirect } })
            const skin = this.$store.getters.skin;
            let opts = {
                hideHeader: true,
                hideFooter: true,
                modalClass: "coach_modal theme-" + skin,
                size: "md",
                buttonSize: "sm",
                centered: true,
                contentClass: "coach_modal",
                id: 'error-modal'
            }
            return this.$bvModal.msgBoxOk(content, opts);
        },
    }
};
</script>
<style lang="less">
#BupaSso {
    height: 100vh;
    display: flex;

    .error-message {
        width: 100%;
        padding: 45vh;
        text-align: center;
        font-size: 2.5rem;
        color: white;
    }
}
</style>
