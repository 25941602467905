<template>
  <div id="DigitalClinicsLanding">
	  <div v-if="loading">
		  <Loader />
	  </div>
	  <div class="animate__animated animate__fadeIn" v-else>
		  <!--	  BOOKINGS SECTION -->
		  <section class="py-5 dc-bookings" style="position: relative; background-color: white">
			  <div class="content-container">
				  <div class="d-flex flex-row flex-wrap pb-5">
					  <div class="col-12 col-lg-4">
						  <h3 class="dc-bookings-title">My Booking Summary</h3>
						  <div v-if="bookings.length < 1" class="no-bookings mt-5">
							  <div class="zero-bookings">
								  <div class="row">
									  <div class="col-4 px-5 pt-2">
										  <h1>0</h1>
										  <h3>Current <br/> Bookings</h3>
									  </div>
									  <div class="col-8">
										  <img class="office-vector" src="/img/digitalClinics/OfficeVector.png" />
									  </div>
								  </div>
							  </div>
						  </div>
						  <div v-else class="bookings">
							  <div v-for="(booking, i) in bookings" :key="'booking'+i">
								  <BookingCard :booking="booking.booking" :key="i" :serviceProvider="booking.service_provider"></BookingCard>
							  </div>
						  </div>
					  </div>
					  <div class="col-12 col-lg-8">
						  <div v-if="dc_setting !== 'wallet'" class="dc-banner"></div>
						  <div v-else>
							  <div class="wallet">
								  <div class="row">
									  <div class="col-sm-12 col-md-6 offset-lg-6">
										  <h3 class="dc-bookings-title">My Wallet</h3>
										  <WalletCard :link="true" />
									  </div>
								  </div>
							  </div>
						  </div>
					  </div>
				  </div>
			  </div>
		  </section>
		  <!--	  CATS SECTION BELOW-->
		  <section
			  class="py-5"
			  style="position: relative; background-color: #f8f8f8"
		  >
<!--			  <div class="blob-container">-->
<!--				  <img class="blob" src="/img/digitalClinics/BlobBackground.png" />-->
<!--			  </div>-->
			  <div class="content-container">
				  <!--        <h4 class="pb-5 text-center dc-cats-title">View Availability</h4>-->
				  <div class="d-flex flex-row flex-wrap pb-5">
					  <Category
						  v-for="(category, i) in categories"
						  :key="i"
						  :category="category"
						  :dc_setting="dc_setting"
					  >
					  </Category>
				  </div>
			  </div>
<!--			  <Reviews/>-->
		  </section>
	  </div>
  </div>
</template>
<script>
import BookingCard from "../components/DigitalClinics/BookingCard.vue";
import CardPayment from "../components/Stripe/CardPayment";
import Reviews from "../components/DigitalClinics/Landing/Reviews";
import Category from "../components/DigitalClinics/Landing/Category";
import WalletCard from "../components/DigitalClinics/Landing/WalletCard";
import DigitalClinicsFeedbackModal from "@/components/DigitalClinics/DigitalClinicsFeedbackModal";
import DigitalClinicsCategoryModal from "../components/DigitalClinics/DigitalClinicsCategoryModal";
import Loader from "../components/Loader";
import EventBus from "@/helpers/eventbus";

export default {
  name: "DigitalClinicsHome",
  components: {
	  Loader,
    BookingCard,
	  CardPayment,
	  Reviews,
	  Category,
	  WalletCard,
    DigitalClinicsFeedbackModal,
	  DigitalClinicsCategoryModal
  },
  data() {
    return {
      showQuestionnaire: false,
      startColour: "#381B51",
      stopColour: "#9A5683",
      categories: [],
      bookings: [],
      pathwayModalImageUrl: "",
	    dc_setting : 'wallet',
	    loading : true,
      npsCalled: false
    };
  },
  mounted() {
    EventBus.$emit('updateDFDReminders');
		this.logAction('DC Generic Landing Page Hit');
		this.getData();
    	this.$root.$on('show_dc_feedback_modal',this.showFeedbackModal);
  },
  methods: {
	  async getData() {
		  let res = await this.api({
			  path: "api/u/digital-bookings/get-front-page-data",
		  });
		  if (res.success) {
				this.bookings = res.bookings.upcoming
				this.categories = res.categories
			  this.dc_setting = res.dc_setting
			  this.loading = false;
		  }
	  },
    async showFeedbackModal() {
      if(this.npsCalled) return;
      this.npsCalled = true;
      const content = this.$createElement(DigitalClinicsFeedbackModal, {});
      const skin = this.$store.getters.skin;
      let opts = {
        hideHeader: false,
        hideFooter: true,
        modalClass: "questionnaire-modal theme-"+skin,
        headerClass: "questionnaire-modal d-none",
        footerClass: "d-none",
        size: "lg",
        buttonSize: "sm",
        centered: true,
        contentClass: "questionnaire-modal theme-" + skin,
        id : 'dc-feedback-modal',
      }
      return this.$bvModal.msgBoxOk(content, opts);
    },
    showPathwayModal(c) {
	    const content = this.$createElement(DigitalClinicsCategoryModal, {props: {selected_category : c, dc_setting : this.dc_setting}});
	    const skin = this.$store.getters.skin;
	    let opts = {
		    hideHeader: false,
		    hideFooter: true,
		    modalClass: "dc-cat-modal theme-"+skin,
		    headerClass: "dc-cat-modal d-none",
		    footerClass: "d-none",
		    size: "md",
		    buttonSize: "sm",
		    centered: true,
		    contentClass: "dc-cat-modal theme-" + skin,
		    id : 'dc-cat-modal',
	    }
	    return this.$bvModal.msgBoxOk(content, opts);
    },
  },
  watch: {
	categories(newCategories){
		let id = this.$route.query.category_id
		if(id){
			let category = newCategories.filter((cat) => {
				return cat.id == id;
			});
			this.showPathwayModal(category[0]);
		}
	}
  },
  computed: {
    text() {
      return this.getText.loggedIn.home.digital_clinics_generic;
    },
  },
	provide: function () {
		return {
			showPathwayModal : this.showPathwayModal
		}
	},
};
</script>
<style lang="less">
#DigitalClinicsLanding {
  .wallet-banner, .booking_inner {
    max-width: 350px;
  }
	.dc-bookings {
		background-image: url('/img/digitalClinics/BlobHeroArea.png');
		background-position: center bottom;
		background-repeat: no-repeat;
		background-size: cover;
		h3.dc-bookings-title {
			letter-spacing: normal;
			font-size: 22px;
			&:after {
				content : ' ';
				display: block;
				width: 60px;
				height: 5px;
				border-bottom: 1px solid;
				border-color: inherit;
			}
		}
		.zero-bookings {
			background: white;
			border-radius: 15px;
			border: 1px solid rgba(0,0,0,0.05);
			position: relative;
			h1 {
				font-family: "FilsonProBold", sans-serif;
				font-size: 90px;
				margin: 0px;
			}
			h3 {
				margin: 0px;
				padding: 0px 0px 15px;
				font-family: "FilsonProBold", sans-serif;
				letter-spacing: normal;
				font-size: 20px;
			}
			.office-vector {
				position: absolute;
				right: 0px;
				bottom: -20px;
				max-width: 100%;
			}
		}
	}
	.dc-cats-title {
		padding-top: 2em;
		font-size: 2.3rem;
		font-family: "FilsonProLight", sans-serif;
	}
	.horizontal-divider {
		flex-grow: 1;
		margin-left: 20px;
		border: 2px solid;
	}
	.banner-overlay {
		position: absolute;
		width: 100%;
		bottom: 100%;
		z-index: 1;
		img {
			width: 100%;
		}
	}
	.pathway-overlay {
		position: absolute;
		width: 100%;
		bottom: 0;
		z-index: 1;
		img {
			width: 100%;
		}
	}
	.blob-container {
		position: absolute;
		display: flex;
		justify-content: center;
		width: 100%;
		top: 100px;
		z-index: 0;
		div {
		}
	}
	.yellow-button {
		background-color: #f1c361;
	}
	.green-button {
		background-color: #65ceac;
	}
	.triangle-div {
		position: absolute;
		right: 70%;
		z-index: 10;
		width: 80px !important;
		max-height: 100% !important;
		padding-left: 10px;
		border-style: solid;
		border-color: white;
		border-width: 80px 80px 0 0;
	}
}
</style>
