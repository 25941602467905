<template>
	<div id="add_card_component" class="content" :class="status">
		<h4>Add Card</h4>
		<div v-if="status === 'input'" :key="status" class="animate__animated animate__fadeIn">
			<div class="bg-white p-5">
				<div class="stripe-wrap col-12 px-0">
					<form id="payment-form">
						<div id="payment-element">
							<!--Stripe.js injects the Payment Element-->
						</div>
						<br/>
						<br/>
						<br/>
						<div v-if="showButton" id="payment_button" class="animate__animated animate__fadeIn">
							<b-button  type="submit" id="submit" variant="cta" block>
								<span id="button-text" class="" v-html="button_text"></span>
							</b-button>
						</div>

<!--						<div id="payment-message" class="hidden"></div>-->
<!--						<div id="error-message"></div>-->
						<div id="payment_form_message"><span>{{message}}</span></div>
					</form>
				</div>
			</div>
		</div>
		<div v-else-if="status === 'success'" :key="status" class="success animate__animated animate__fadeIn">
			<h4>Success</h4>
			<p>Your Card has been saved </p>
			<i class="fas fa-check-circle"></i>
			<br/>
			<b-button variant="cta" class="px-5 mt-5" @click="closeModal">Done</b-button>
		</div>
		<div v-else :key="status" class="animate__animated animate__fadeIn">
		
		</div>
	</div>
</template>
<script>
import axios from "axios";
import config from "../../config/constants";

export default {
	components: {
	
	},
	name: "AddCard",
	data() {
		return {
			status: 'input', // [success, loading, input],
			message: '',
			stripe_intent_secret : '',
			showButton : false,
			button_text : 'Add Card'
		};
	},
	mounted() {
		this.checkStripe();
	},
	beforeDestroy() {
	
	},
	computed: {
		canBeClicked() {
			return this.voucherInput.length >= 12;
		},
	},
	methods: {
		startAgain(){
			this.status = 'input'
		},
		closeModal() {
			let self = this
			setTimeout(() => {
				self.status = 'input';
				self.voucherInput = '';
			}, 100)
			this.$bvModal.hide("add_card_modal");
		},
		getUrlParams(){
		
		},
		checkStripe() {
			if (window.Stripe) {
				this.initStripeForm();
			} else {
				setTimeout(() => this.checkStripe(), 1000);
			}
		},
		async initStripeForm() {
			const payload = {
				user_id: this.$store.getters.user_id,
			};
			let res = await axios.post(
				config.api_env + "/application/api/u/wallet/get-create-customer-intent",
				payload,
				config.options
			);
			if(res.data.success){
				const stripe = window.Stripe(config.stripe_key);
				const elements = stripe.elements({clientSecret : res.data.client_secret});
				const paymentElement = elements.create("payment");
				paymentElement.mount("#payment-element");
				this.showButton = true
				
				const form = document.getElementById('payment-form');
				
				form.addEventListener('submit', async (event) => {
					event.preventDefault();
					this.message = ''
					this.button_text = 'Processing <i class="fa-solid fa-circle-notch fa-spin"></i>'
					let return_url = window.location.href
					
					const {error} = await stripe.confirmSetup({
						//`Elements` instance that was used to create the Payment Element
						elements,
						redirect: "if_required",
						confirmParams: {
							return_url: return_url
						}
					});
					
					if (error) {
						// const messageContainer = document.querySelector('#error-message');
						// messageContainer.textContent = error.message;
						this.message = error.message
						this.button_text = 'Try again'
					} else {
						this.showButton = false
						this.message = 'Card saved successfully.'
						this.button_text = 'Done !'
						setTimeout(()=>{ this.status = 'success' }, 1000)
					}
				});
			} else {
				console.log('stripe secret not loaded')
			}
			console.log(this.status)
		},
	},
	props : ['close_function'],
};
</script>
<style lang="less">
#add_card_modal {
	text-align: center;
	box-shadow: 0px 0px 100px rgba(0,0,0,0.5) inset;
	.modal-body {
		color: rgba(56, 28, 82, 0.8);
	}
}
#add_card_component.content{
	padding: 80px 30px 50px;
	min-height: 36rem;
	h4 {
		text-align: center;
		font-size: 2rem;
		font-family: "FilsonProBold", sans-serif;
	}
	p {
		font-size: 14px;
		padding: 20px 50px;
	}
	.success i {
		font-size: 70px;
	}
	&.success i {
		color: forestgreen;
	}
	#submit {
		font-size: 14px;
		font-family: "FilsonProLight", sans-serif;
	}
	#payment_form_message {
		font-size: 14px;
		margin-top: 2rem;
	}
}
</style>
