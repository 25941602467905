<template>
  <div id="faqs">
    <div
      class="redirecting-container row justify-content-center align-items-center"
    >
      <div>
        <b-spinner class="spinner"></b-spinner>
        <div class="redirecting-text">Redirecting...</div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import config from "../config/constants";
export default {
  name: "faq",
  components: {},
  data() {
    return {
      faqs: [],
      searchQuery: "",
    };
  },
  beforeCreate() {
    setTimeout(function () {
      window.location = "https://layawellness.zendesk.com/hc/en-us/sections/360000416818-FAQ";
    }, 1000);
  },

  computed: {
    filteredFaqs() {
      return this.faqs.filter((faq) => {
        return (
          faq.question.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          faq.answer.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      });
    },
    componentText() {
      return this.getText.loggedIn.faqs;
    },
  },
};
</script>
<style lang="less">
#faqs {
  .redirecting-container {
    height: 100%;
    .spinner {
      display: block;
      margin: auto;
      width: 40px;
      height: 40px;
    }
    .redirecting-text {
      font-size: 2rem;
      margin-top: 30px;
    }
  }
}
</style>
