<template>
    <div id="ErrorModal">
        <h3>{{ title }}</h3>
        <div class="brand-text" v-html="error">
        </div>
        <div class="pt-5" v-if="redirect">
            <b-button variant="cta" @click="closeModalRedirect()" squared>Go back to Bupa Touch</b-button>
        </div>
        <div class="pt-5" v-else>
            <b-button variant="cta" @click="closeModal()" squared>Okay</b-button>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        error: {
            type: String,
            default: "Please try again. <br /> <br /> If the problem persists please call us on <b>0345 608 0898</b>. We’re here to help Mon-Fri 8am-6pm, Sat 8am-4pm",
        },
        redirect: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "Sorry, we've had a problem",
        },
        goBack: {
            type: Boolean,
            default: false,
        }
    },
    components: {
    },
    name: "ErrorModal",
    data() {
        return {
        };
    },
    mounted() {
    },
    beforeDestroy() {
        if (this.redirect) {
            window.open(this.getBupaTouchLink, '_self');
        }
        if (this.goBack) {
            this.$router.push('/gp');
        }
    },
    methods: {
        closeModal() {
            this.$bvModal.hide("error-modal");
            if(this.goBack){
                this.$router.push('/gp');
            }
        },
        closeModalRedirect() {
            window.open(this.getBupaTouchLink, '_self');
        }
    },
    computed: {
        getBupaTouchLink() {
            if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'staging') {
                return 'https://scsmoke3.np.bupa.co.uk/touchdashboard/dashboard/gp-options'
            } else {
                return 'https://www.bupa.co.uk/touchdashboard/dashboard/gp-options'
            }
        },
    },
    watch: {

    }
};
</script>
<style lang="less">
#ErrorModal {
    h3 {
        font-size: 2.4rem;
        font-weight: 700;
        font-family: 'Montserrat', serif;
        padding-bottom: 30px;
    }

    font-size: 1.8rem;
    font-family: "Montserrat",
    serif;
    padding: 60px 20px;
    text-align: center;
}
</style>