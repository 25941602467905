<template>
	<div class="" style="background-color: white">
		<div class="dc-featured-img" :style="{backgroundImage : 'url('+selected_category.featured_img+')'}">
          <span class="close-button" @click="$bvModal.hide('dc-cat-modal')">
	          <i class="fas fa-times text-white fa-2x float-right m-3"></i>
          </span>
		</div>
		<div class="dc-modal-content">
			<div class="row dc-modal-cat-top">
				<div class="col-8 text-left">
					<h2>
						{{ selected_category.title }}
					</h2>
				</div>
				<div v-if="dc_setting !== 'client_subscription'" class="col-4 text-right">
					<h2 class="price">
						{{getCurrencyPriceString(selected_category.currency, selected_category.price, true)}}
					</h2>
					<span v-if="selected_category.tax > 0" class="dc-tax">(+ {{getCurrencyPriceString(selected_category.currency, selected_category.tax, true)}} VAT)</span>
				</div>
			</div>
			<br/>
			<p class="dc-cat-desc">
				{{ selected_category.description }}
			</p>
			<div class="dc-cat-modal-divider"></div>
			<br/>
			<div class="row">
				<div class="col-7 dc-cat-modal-duration">
					<i class="far fa-clock"></i> duration: {{ selected_category.duration }} mins
				</div>
				<div class="col-5 text-right">
					<b-button
						variant="cta px-5"
						class="fixed_width shadow filson-book"
						@click="selectPathway(selected_category.id)"
					>Book Now</b-button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>

export default {
	props: ['selected_category', 'dc_setting'],
	name: "DigitalClinicsCategoryModal",
	methods: {
		selectPathway(id) {
			this.logAction('DC Generic Landing Page Click: ' + this.selected_category.title)
			this.$router.push({
				name: "digitalClinicsSelectClinician",
				query: { category_id: id },
			});
		},
	}
};
</script>
<style lang="less">
#dc-cat-modal {
	.modal-body {
		padding: 0px;
		.dc-featured-img {
			min-height: 120px;
			background-position: center center;
			background-size: cover;
			.close-button:hover {
				cursor: pointer;
			}
		}
		.dc-modal-content {
			padding: 30px;
			.dc-modal-cat-top {
				h2 {
					font-family: "FilsonProLight", sans-serif;
					&.price {
						font-family: "FilsonProBold", sans-serif;
						color: rgb(232, 59, 117);
					}
				}
			}
			.dc-cat-desc {
				font-family: "FilsonProLight", sans-serif;
				font-size: 1.3rem;
				color: rgba(0,0,0,0.6)
			}
			.dc-cat-modal-divider {
				border-bottom: 1px solid rgba(0,0,0,0.05);
			}
			.dc-cat-modal-duration {
				font-size: 1.3rem;
				padding-top: 10px;
				font-family: "FilsonProLight", sans-serif;
				color: rgba(0,0,0,0.4)
			}
		}
	}
}
</style>
