<template>
    <div id="Metrics">
        <div class="page-header" :class="{'bg-transparent': skin === 0, 'bg-brand-secondary': skin !==0}">
            <div class="content-container row ">
                <div class="page-title col-12 pb-5">
                    <h2>{{$route.meta.title ? $route.meta.title : componentText.title}}</h2>
                </div>
                <div
                     v-for="(metric ,i) in metrics"
                     :key="i"
                     class="col-6 col-md-4 col-lg-3 py-2 py-sm-5 metric"
                     v-if="metrics.length > 0 && i !== 6"
                     @click="selectMetric(i)"
                >
                    <div class="metrics-wrap row  align-items-center">
                        <div class="metric-image col-12 col-sm-5">
                            <img :src="'/img/metrics/' + titlesEn[i] + '.svg'" v-if="skin === 0 || skin === 8">
                            <img :src="'/img/metrics/' + titlesEn[i] + '-generic.svg'" v-else>
                        </div>
                        <div class="metric-info col-12 col-sm-7">
                            <p class="metric-title">{{metric.title}}

                                <svg >
                                    <g><g id="Layer_1-2"><path class="cls-1" d="M4.8,11.9C3.9,11,3.1,10.1,2.2,9.2c-0.3-0.3-0.3-0.5,0-0.8c2.7-2.7,5.3-5.3,8-8c0.3-0.5,0.9-0.6,1.4-0.3
                                        c0.1,0.1,0.2,0.2,0.3,0.3c0.5,0.5,1,1,1.5,1.5c0.4,0.4,0.4,1,0.1,1.4l-0.1,0.1C10.6,6.2,7.8,9,5,11.8C5,11.9,4.9,11.8,4.8,11.9z"/>
                                        <path class="cls-1" d="M1.1,9.6l3.1,3L0,13.7L1.1,9.6z"/>
                                    </g></g>
                                </svg>
                            </p>
                            <p v-if="i === 5" class="metric-value">{{metric.value}}/{{metrics[6].value}}</p>
                            <p class="metric-value" v-else>{{metric.value}}{{metric.unit}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="metrics-section" class="bg-grey d-none d-md-block" v-if="selectedMetric.title">
            <div class="content-container">
                <metrics-card :selectedMetric="selectedMetric" :titleEn="selectedTitleEn" :metrics="metrics" @logMetric="logMetricData"></metrics-card>
            </div>
        </div>
        <div id="pop-up" v-if="showPopup" class=" d-md-none px-3">
            <div class="pop-up-background" @click="hidePopup"></div>
            <div class="metrics-section pop-up-content">
                <metrics-card :selectedMetric="selectedMetric" :titleEn="selectedTitleEn" :metrics="metrics" @logMetric="logMetricData"></metrics-card>
                <b-button class="close-popup" @click="hidePopup"><font-awesome-icon icon="times-circle"></font-awesome-icon></b-button>
            </div>
        </div>
        <div v-if="metricLogged" class="log-confirmation">
            {{componentText.logged}} <font-awesome-icon icon="check" class="brand-highlight-1"></font-awesome-icon>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import axios from "axios";
    import config from "../config/constants";
    import MetricsCard from "../components/Metrics/MetricsCard.vue"
    export default {
        name: "metrics",
        components:{
            MetricsCard
        },
        data() {
            return {
                metrics: [],
                selectedMetric: {},
                selectedTitleEn: '',
                metricInput: null,
                metricInput1: null,
                metricLogged: false,
                showPopup: false,
                titlesEn: ['weight', 'body-fat', 'blood-glucose', 'visceral-fat', 'vo2-max', 'blood-pressure', '', 'bmi', 'height']
            };
        },
        mounted() {
            this.getMetrics()
        },
        methods: {
           async getMetrics(){
                const payload = {
                    user_id: this.$store.getters.user_id
                }
                let res = await axios.post(config.api_env + "/application/api-hc/get-metric-range-all", payload, config.options)
               this.metrics = res.data.metrics
               //this.metrics[5].title = 'Blood Pressure'
               this.selectedMetric = this.metrics[0]
               this.selectedTitleEn = this.titlesEn[0]
            },
            selectMetric(metric){
               this.selectedMetric = this.metrics[metric]
                this.selectedTitleEn = this.titlesEn[metric]
                this.showPopup = true
                const scrollTo =  document.getElementById("metrics-section")
                scrollTo.scrollIntoView({behavior: 'smooth'});
            },
            async logMetricData(metric){
                const payload = {
                    user_id: this.$store.getters.user_id,
                    metric: metric.metric,
                    value: metric.value
                }
                let res = await axios.post(config.api_env +"/application/api-hc/log-metric-data", payload, config.options)
                if(metric.metric === 'bp_sys'){
                    this.logMetricData(this.metrics[6])
                }
                if(res.data.success){
                    this.metricLogged = true
                    setTimeout(() => this.metricLogged = false, 5000)
                }
            },
            hidePopup(){
                this.showPopup = false
            }
        },
        computed:{
            skin(){
                return this.$store.getters.skin
            },
            componentText() {
                return this.getText.loggedIn.metrics;
            },
        }
    };
</script>
<style lang="less">
    #Metrics{
        .page-header {
            padding: 60px 0;
        }
        .page-title {
            text-align: left;
            h2 {
                text-align: left;
                font-size: 3.8rem;
                letter-spacing: 0.7rem;
                font-family: "FilsonProBold", sans-serif;
                color: #fff;
            }
        }
        .metric{
            cursor: pointer;
        }
        .metric-image{
            img{
                width: 108px;
                height: 108px;
            }
        }
        .metric-info{
            text-align: left;
            .metric-title{
                font-family: FilsonProBold;
                font-size: 2.2rem;
                text-transform: uppercase;
                color: #fff;
                line-height: 1;
                svg{
                    width: 14px;
                    height: 14px;
                    margin-left: 5px;
                    cursor: pointer;
                    .cls-1{
                        fill: #fff;
                    }
                }

            }
            .metric-value{
                font-size: 1.9rem;
                font-family: 'DMSans', sans-serif;
            }
        }
        #metrics-section {
            padding-top: 90px;
            padding-bottom: 90px;
            position: relative;
            z-index: 5;
            .metrics-card {
                max-width: 620px;
                text-align: center;
                margin: auto;
                .metrics-card-head {
                    position: relative;
                    &:before{
                        position: absolute;
                        content: '';
                        left: 0;
                        top: -60px;
                        height: 60px;
                        width: calc(100% - 74px);
                        border-top-left-radius: 26px;
                        z-index: 0;
                    }
                    &:after{
                        position: absolute;
                        content: '';
                        top: -60px;
                        right: -60px;
                    }
                    h3 {
                        text-align: left;
                        position: relative;
                        margin-bottom: 0;
                        font-size: 3.9rem;
                        color: #ffffff;
                        text-transform: uppercase;
                        font-family: FilsonProBold, sans-serif;
                        &:before{
                            position: absolute;
                            content: '';
                            width: 60px;
                            height: 3px;
                            top: -10px;
                            left: 0;
                            background: #ffffff;
                        }
                    }
                    .image-wrap{
                        text-align: center;
                        margin:auto;
                        position: relative;
                        border-radius: 50%;
                        background: #ffffff;
                        width: 125px;
                        min-width: 125px;
                        height: 125px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        z-index: 1;
                        img{
                            width: 65%;
                            height: 65%;
                        }
                    }
                }
                .metrics-card-body{
                    .metrics-card-input{
                        text-align: center;
                        margin:auto;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        input{
                            width: 122px;
                            padding: 10px;
                            font-size: 28px;
                            color: #919396;
                            line-height: 34px;
                            border: 1px solid #6d6d6f;
                            outline: none;
                            border-radius: 5px;
                            text-align: center;
                        }
                        .metrics-input-unit{
                            font-size: 28px;
                            font-family: 'FilsonProLight', sans-serif;
                            color: #919396;
                        }
                        .metrics-separator-dash{
                            font-size: 4.2rem;
                        }
                    }
                    .metrics-text{
                        text-align:left;
                        font-size: 2.1rem;
                    }
                }
            }
        }
        #pop-up{
            display: flex;
            align-items: center;
            z-index: 500;
            position:fixed;
            top:0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            text-align: center;
            overflow-x: hidden;
            overflow-y: scroll;
            .pop-up-background{
                width: 100vw;
                height: 100vh;
                position: absolute;
                left: 0;
                right: 0;
                background: transparent;
            }
            .metrics-section{
                padding:  0;
                border-radius: 25px;
                .metrics-card-head{
                    border-radius: 25px 25px 0 0;
                    padding-top: 30px;
                    text-align: left;
                }
                img{
                    max-width: 80px;
                    max-height: 80px;
                }

            }
            .close-popup{
                position: absolute;
                top: 0;
                right: 0;
                background-color: transparent;
                width: 50px;
                height:50px;
                svg{
                    width: 30px;
                    height: 30px;
                }
            }
        }
        .log-confirmation{
            font-size: 3.3rem;
            font-family: 'FilsonProBold', sans-serif;
            color: #fff;
            background-color: rgba(0, 0, 0, 0.8);
            position: fixed;
            top: 0;
            width: 100%;
            text-align: center;
            padding: 80px 0;
            z-index: 9999;
        }
        @media only screen and (max-width: 768px){
            .metrics-card-body{
                border-radius:  0 0 26px 26px;
            }
        }
        @media only screen and (max-width: 576px){
            .metric-title{
                margin-top: 10px;
            }
            .metrics-section{
                .metrics-card {
                    .metrics-card-head{
                        h3{
                            font-size: 3rem;
                            padding-top: 20px;
                        }
                        .image-wrap{
                            width: 80px;
                            min-width: 80px;
                            height: 80px;
                        }
                    }
                    .metrics-card-body {
                        .metrics-text {
                            h4{
                                font-size: 1.9rem;
                            }
                            p {
                                font-size: 1.8rem;
                            }
                        }
                    }
                }
            }
        }
        @media only screen and (max-width: 768px){
            .metric-info{
                text-align: center;
            }
        }
    }

</style>
