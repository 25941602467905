<template>
  <div class="video-card-tags">
    <div class="video-card-tag" v-for="(tag, i) in tagsToDisplay" :key="i">#{{ tag }}
    </div>
  </div>
</template>

<script>

import EventBus from "@/helpers/eventbus";
export default {
  name: "VideoCardTags",
  props: ['video','type'],
  data() {
    return {
      tagsToDisplay: []
    }
  },
  mounted() {
    this.tagsToDisplay = this.video.tags.slice(0,2)
    EventBus.$on("setTag", function (tag) {
        if(!tag) this.tagsToDisplay = this.video.tags.slice(0,2)
        const tagsArray = [];
        const excludedTags= ["wellbeing studio","training plans","top events this month", "classes","digital gym"];
        this.video.tags.forEach(t => {
          if(t.toLowerCase() !== this.type && t.toLowerCase() !== tag.tag && !excludedTags.includes(t.toLowerCase())) {
            tagsArray.push(t)
          }
        })
        this.tagsToDisplay = tagsArray.slice(0,2)
    });
  }
}
</script>

<style scoped>

</style>