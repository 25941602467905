<template>
  <div id="NewAvatar" @click="close()">
    <link
      href="https://cdnjs.cloudflare.com/ajax/libs/cropperjs/1.5.12/cropper.css"
      rel="stylesheet"
    />

    <section class="new-media-post-modal fade-in" v-on:click.stop>
      <div class="new-media-post-modal-header bg-brand-secondary">
        <span v-if="view === 'preview'">Create Post</span
        ><span v-else>Crop Image</span>
      </div>
      <div class="modal-content-container">
        <div
          class="flex-row new-media-post-file-upload"
          v-if="view === 'upload'"
        >
          <div
            id="dropZone"
            class="drop-zone clickable"
            @drop="dropHandler($event)"
            @dragenter.prevent
            @dragover.prevent
            @dragleave.prevent
          >
            <p>Drag a file to this drop zone to upload...</p>
          </div>
        </div>
        <div
          class="flex-row new-media-post-file-preview"
          :class="{ invisible: view === 'upload' }"
        >
          <div
            class="
              d-flex
              flex-row
              justify-content-center
              new-post-image-preview-container
              pb-3
            "
          >
            <div id="image-preview" class="new-post-image-preview">
              <div
                class="
                  image-preview-buttons
                  d-flex
                  flex-column
                  align-items-center
                  justify-content-center
                "
                v-if="view === 'preview'"
              >
                <button
                  class="btn cta-button px-5 py-3"
                  @click="initializeCropImage()"
                >
                  Crop
                </button>
              </div>
            </div>
            <!-- Preview image gets appended here -->
          </div>
          <div class="new-post-media-buttons-container">
            <div
              class="d-flex flex-row justify-content-around align-items-center"
            >
              <button
                class="btn btn-outline border-brand-highlight-1 px-5 py-3"
                @click="close()"
              >
                Close
              </button>
              <button
                class="btn cta-button px-5 py-3 ml-auto"
                @click="updateAvatar()"
                v-if="view === 'preview'"
              >
                <span v-if="!isSending" class="fade-in">Update</span
                ><span v-else
                  ><b-spinner
                    class="white button-spinner"
                    v-if="sendSuccess === null"
                  ></b-spinner>
                  <span v-if="sendSuccess === true"
                    >Updated <i class="fas fa-check button-icon"></i>
                  </span>
                  <span v-if="sendSuccess === false">
                    <i class="fas fa-times button-icon"></i>
                  </span>
                </span>
              </button>
              <button
                class="btn cta-button px-5 py-3 ml-auto"
                @click="cropImage()"
                v-if="view === 'crop'"
              >
                Crop
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import config from "../../config/constants";
import Cropper from "cropperjs";

export default {
  name: "NewAvatar",
  props: ["user"],
  components: {},
  data() {
    return {
      modalTitle: "Upload media",
      cropper: null,
      reader: null,
      view: "upload",
      isSending: false,
      sendSuccess: null,
      text: "",
      media: null,
      mediaType: null,
      serverMediaUrl: null,
    };
  },
  mounted() {
    let self = this;
    let dropRegion = document.getElementById("dropZone"),
      fakeInput = document.createElement("input");

    fakeInput.type = "file";
    fakeInput.accept = "image/*";
    fakeInput.multiple = false;
    dropRegion.addEventListener("click", function () {
      fakeInput.click();
    });

    fakeInput.addEventListener("change", function () {
      var files = fakeInput.files;
      self.handleFiles(files);
    });

    dropRegion.addEventListener("drop", self.handleDrop, false);
  },
  methods: {
    handleFiles(files) {
      let self = this;
      for (var i = 0, len = files.length; i < len; i++) {
        this.media = files[i];
        if (self.validateMedia(files[i])) self.previewImage(files[i]);
      }
    },

    handleDrop(e) {
      var data = e.dataTransfer,
        files = data.files;
      this.handleFiles(files);
    },

    validateMedia(media) {
      let validTypes = ["image/jpeg", "image/png"];

      if (validTypes.indexOf(media.type) === -1) {
        alert("Invalid File Type");
        return false;
      }
      var maxSizeInBytes;

      this.mediaType = "image";
      maxSizeInBytes = 2e6; // 2MB
      if (media.size > maxSizeInBytes) {
        alert("File too large");
        return false;
      }
      return true;
    },

    async previewImage(media) {
      let imagePreviewRegion = document.getElementById("image-preview");

      if (this.mediaType === "video") {
        let vidView = document.createElement("div");
        vidView.className = "preview-image-container";
        imagePreviewRegion.appendChild(vidView);

        // previewing image
        let vid = document.createElement("div");
        vid.className = "video-placeholder brand-text";
        vid.innerHTML =
          '<div class="text-center"><i class="fas fa-video fa-3x mx-auto"></i></div>' +
          media.name;
        vidView.appendChild(vid);
      } else {
        let imgView = document.createElement("div");
        imgView.className = "preview-image-container";
        imagePreviewRegion.appendChild(imgView);

        // previewing image
        let img = document.createElement("img");
        img.className = "img-fluid new-post-preview-image";
        img.id = "image-to-be-cropped";
        imgView.appendChild(img);

        var reader = new FileReader();
        reader.onload = function (e) {
          img.src = e.target.result;
        };

        reader.readAsDataURL(media);
      }
      // previewing image

      this.view = "preview";
    },
    dropHandler(e) {
      console.log("File(s) dropped");

      // Prevent default behavior (Prevent file from being opened)
      e.preventDefault();

      if (e.dataTransfer.items) {
        // Use DataTransferItemList interface to access the file(s)
        for (var i = 0; i < e.dataTransfer.items.length; i++) {
          // If dropped items aren't files, reject them
          if (e.dataTransfer.items[i].kind === "file") {
            var file = e.dataTransfer.items[i].getAsFile();
          }
        }
      } else {
        // Use DataTransfer interface to access the file(s)
        for (var i = 0; i < e.dataTransfer.files.length; i++) {
          console.log(
            "... file[" + i + "].name = " + e.dataTransfer.files[i].name
          );
        }
      }
    },
    initializeCropImage() {
      this.view = "crop";
      const image = document.getElementById("image-to-be-cropped");
      this.cropper = new Cropper(image, {
        aspectRatio: 1,
        viewMode: 2,
      });
    },
    cropImage() {
      let self = this;
      let canvas, initialAvatarURL;

      const image = document.getElementById("image-to-be-cropped");

      if (this.cropper) {
        canvas = this.cropper.getCroppedCanvas();
        initialAvatarURL = image.src;
        image.src = canvas.toDataURL();
        this.view = "preview";
        this.cropper.destroy();
        this.cropper = null;
        canvas.toBlob(
          function (blob) {
            self.media = new File([blob], "tmp.jpeg");
          },
          "image/jpeg",
          0.95
        );
      }
    },
    async uploadMedia() {
      let formData = new FormData();
      formData.append("file", this.media);
      formData.append("user_id", this.$store.getters.user_id);
      const url = config.api_env + "/application/api/u/community/upload-media";
      const opts = {
        method: "post",
        body: formData,
      };
      const res = await fetch(url, opts).then((response) => {
        return response.json().then((data) => {
          if (data.success == true) {
            return data.file;
          } else {
            alert("File failed to upload");
          }
        });
      });
      return res;
    },
    async updateAvatar() {
      const self = this;
      this.isSending = true;

      let mediaUrl = await this.uploadMedia();
      const payload = {
        user_id: this.$store.getters.user_id,
        profile: {
          firstname: this.user.firstname,
          lastname: this.user.lastname,
          bio: this.user.bio,
          title: this.user.title,

          image_url: mediaUrl,
        },
      };
      let res = await axios.post(
        config.api_env + "/application/api/u/community/update-user-profile",
        payload,
        config.options
      );
      if (res.data.success) {
        this.sendSuccess = true;
        setTimeout(function () {
          self.text = "";
          self.$parent.getUserProfile();
          self.isSending = false;
        }, 500);
        self.close();
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.isSending = false;
        this.$store.dispatch("logout");
        this.$router.push("/login").catch((err) => {});
      }
    },
    dragOverHandler(event) {
      event.preventDefault();
    },
    close() {
      this.$parent.closeMediaModal();
    },
  },
  computed: {
    componentText() {
      return this.getText.loggedIn;
    },
  },
};
</script>
<style lang="less">
#NewAvatar {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100%;
  min-width: 100%;
  .new-media-post-modal {
    z-index: 200;
    background-color: rgba(255, 255, 255, 1);
    min-height: 200px;
    width: 700px;
    margin-top: 120px;
    margin-left: auto;
    margin-right: auto;
    .new-media-post-modal-header {
      color: white;
      padding: 10px 30px;
      font-size: 1.6rem;
    }
    .spinner-container {
      min-height: 200px;
    }
    .modal-content-container {
      padding: 30px;
      .new-media-post-file-upload {
        display: flex;
        flex-grow: 1;
        height: 100%;
        .drop-zone {
          width: 100%;
          height: 100%;
          font-size: 1.6rem;
          padding: 30px;
          border: 3px dashed lightblue;
          text-align: center;
        }
      }
      .new-post-header-container {
        .new-post-title {
          font-style: "Filson Pro Book", sans-serif;
          font-weight: 600;
          font-size: 2rem;
        }
        .new-post-topic-selector {
          font-size: 1.8rem;
        }
      }
      .new-post-content-container {
        padding-top: 10px;
        width: 100%;
        .new-post-content-input {
          font-size: 1.6rem;
          outline: none;
          border: 1px solid lightsteelblue;
          border-radius: 12px;
          padding: 10px;
          min-height: 160px;
          resize: none;
        }
        .new-post-disclaimer {
          padding: 10px 0;
          font-size: 1.6rem;
          text-align: right;
        }
      }
      .new-post-image-preview-container {
        position: relative;
        .new-post-image-preview {
          display: flex;
          justify-content: space-between;
          padding: 10px;
          .preview-image-container {
            .video-placeholder {
              background-color: #eee;
              font-weight: bold;
              width: 200px;
              border-radius: 16px;
              word-wrap: break-word;
              padding: 60px 20px;
            }
          }
          .new-post-preview-image {
            display: block;
            max-width: 100%;
            min-width: 100%;
            border-radius: 16px;
          }
          .image-preview-buttons {
            height: calc(100% - 10px);
            opacity: 0;
            background-color: rgba(0, 0, 0, 0);
            border-radius: 16px;
            position: absolute;
          }
          .image-preview-buttons:hover {
            visibility: visible;
            display: block;
            opacity: 1;
            background-color: rgba(0, 0, 0, 0.5);
          }
        }
      }
    }
  }
  .spinner-container {
    height: 200px;
    margin-bottom: 20px;
    .posts-spinner {
      height: 50px;
      width: 50px;
    }
  }
  .btn {
    padding-top: 10px !important;
    padding-bottom: 15px !important;
  }

  .invisible {
    height: 0;
    overflow: hidden;
  }
}
</style>
