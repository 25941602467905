<template>
    <div id="OrderConfirmation">
        <div class="page-header bg-brand-secondary">
            <div class="content-container row">
                <div class="page-title col-12">
                    <h2>{{componentText.title}}</h2>
                </div>
            </div>
        </div>
        <div class="bg-white pb-5">
            <div class="content-container row">
                <div class="section-header section-header">
                    <div class="section-header-divider"></div>
                    <h3 class="section-header-title">{{componentText.confirmation}}</h3>
                </div>
                <div class="confirmation-text" v-html="componentText.content">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src

    export default {
        name: "OrderConfirmation",
        data() {
            return {}
        },
        mounted() {

        },
        methods: {

        },
        watch:{

        },
        computed:{
            componentText() {
                return this.getText.loggedIn.orderConfirmation;
            },
        }
    };
</script>
<style lang="less">
    #OrderConfirmation {
        font-family: DMSans;
        font-size: 1.6rem;
        .page-header {
            padding: 60px 0;
        }
        .page-title {
            text-align: left;
            h2 {
                text-align: left;
                font-size: 3.8rem;
                letter-spacing: 0.7rem;
                font-family: "FilsonProBold", sans-serif;
                color: #fff;
            }
        }
    }
</style>
