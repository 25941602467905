<template>
  <div id="GetOrg" class="container">
    <div class="row page-content">
      <div
        class="col-md-7 img-part px-0"
        :class="{
          'bg-brand-secondary': skin > 0,
          'bg-grad-image-purple': skin === 0,
        }"
      >
        <div class="login-bg">
          <div class="heart-placeholder">
            <img
              v-if="hardcoded.spectrum_heart"
              class="heart-img"
              :src="hardcoded.spectrum_heart"
            />
            <img class="heart-img" src="/img/default-asset.svg" v-else />
          </div>
          <div class="forgot-pass-image"></div>
        </div>
      </div>
      <div class="col-md-5 form min-height">
        <div v-if="hardcoded.login_logos" class="logos d-flex pt-5">
          <div
            v-for="(logo, i) in hardcoded.login_logos"
            :key="i"
            class="d-flex logo px-1"
          >
            <img :src="logo" />
          </div>
        </div>
        <div class="form-content">
          <div
            :class="[
              ($store.getters.client.client_logo.toLowerCase().includes('spectrum-life-logo') ||  $store.getters.client.client_logo.toLowerCase().includes('lloydwhyte')) && skin === 15
                ? 'lw-form-logo'
                : 'form-logo',
            ]"
          >
            <img
              :src="getLogo"
              onerror="javascript:this.src='/img/logo-spectrum-life.svg'"
            />
          </div>
          <h2 class="form-title py-5">
            {{ componentText.title }}
          </h2>
          <p class="small">{{ componentText.message }}</p>
          <p class="pb-5">{{ componentText.code }}</p>
          <b-form-group id="input-group-1" label="" label-for="inputEmail">
            <b-form-input
              type="text"
              placeholder="e.g. h44IV9"
              v-model="orgCode"
              id="inputEmail"
              required
              @change="getOrgLocations"
              @keyup.enter.native="getOrgLocations"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            v-if="locations"
            id="input-group-7"
            :label="componentText.location"
            label-for="signupform-locations"
            ><b-form-select
              id="signupform-locations"
              v-model="userLocation"
              class="form-control"
              required
            >
              <option
                v-for="location in locations"
                :value="location.location_id"
              >
                {{ location.location_name }}
              </option>
            </b-form-select>
          </b-form-group>
          <div
            col-sm-12
            class="submit-form d-flex align-items-center justify-content-center"
          >
            <b-button
              v-if="!locations"
              class="cta-button py-3"
              type="button"
              @click="getOrgLocations"
              >{{ componentText.next }}</b-button
            >
            <b-button
              v-if="locations"
              :disabled="disabled"
              class="cta-button py-3"
              type="button"
              @click="setLocation"
              >{{ componentText.proceed }}</b-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import config from "../../config/constants";
import Popup from "../Popup.vue";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
export default {
  components: {},
  name: "GetOrg",
  data() {
    return {
      orgCode: "",
      showPopup: false,
      popupContent: {},
      locations: "",
      userLocation: "",
    };
  },
  methods: {
    async getOrgLocations() {
      let payload = { org_code: this.orgCode, lang: this.$store.state.lang };
      try {
        let res = await axios.post(
          config.api_env + "/application/api-hc/get-client-signup-options",
          payload,
          config.options
        );
        this.locations = res.data.locations;
        console.log(this.locations);
      } catch (e) {
        console.log(e);
      }
    },
    async setLocation() {
      const payload = {
        user_id: this.$store.getters.user_id,
        location_id: this.userLocation.toString(),
        lang: this.$store.state.lang,
      };
      let res = await axios.post(
        config.api_env + "/application/api-hc/update-user-location",
        payload,
        config.options
      );
      if (res.data.success) {
        this.$store.dispatch("getClientBranding", this.$store.getters.user_id);
        this.$store.dispatch("getClientInsights", this.$store.getters.user_id);
        await this.$store.dispatch("getNav", this.$store.getters.user_id);
        this.$router.push({ name: "home" });
      }
    },
    hidePopup() {
      this.showPopup = false;
      this.$emit("reset-failed");
    },
  },
  computed: {
    ...mapGetters(["hardcoded", "skin"]),
    getLogo() {
      if (this.hardcoded && this.hardcoded.logo) {
        return this.hardcoded.logo;
      } else return this.$store.getters.client.client_logo;
    },
    disabled() {
      if (this.userLocation === "") {
        return true;
      } else return false;
    },
    componentText() {
      return this.getText.notLoggedIn.getOrg;
    },
  },
};
</script>
<style lang="less">
#GetOrg {
  .form-content {
    .form-title {
      font-size: 3.5rem;
    }
  }
}
</style>
