<template>
    <div>
        <div v-if="event.e_type == 'service'" class="col-12">
            <div class="row" v-if="Array.isArray(event.ed_timeslots) && event.ed_timeslots.length > 0">
                <div class="section-header col-12" v-if="!event.ed_fully_booked && timeslots && timeslots.length > 0">
                    <div class="section-header-divider"></div>
                    <h3 class="section-header-title">{{componentText.selectTime}}</h3>
                </div>
                <div class="time-slot col-sm-6 col-md-3 my-4" v-for="(slot, i) in timeslots" :key="i">
                    <b-button @click="selectTimeslot(slot)" class="border-brand-secondary brand-secondary py-3" :class="{ active: slot.ts_u_registered || selected_timeslot.ts_id == slot.ts_id }">
                        {{ getHoursMinutes( slot.ts_start_date_time ) }}
                    </b-button>
                </div>
                <div class="py-5 col-12 event-register" id="service-register-button">
                    <h3 v-if="event.e_employee_series_cost > 0">Price: {{currencyString(event.e_employee_slot_cost, event.e_currency)}}</h3>
                    <p>{{componentText.agree}}</p>
                    <p>
                        <a :href="terms" target="_blank">{{componentText.terms}}</a>
                    </p>
                    <div class="py-5" v-if="!event.ed_u_on_waiting_list && event.ed_fully_booked">
                        <p>{{componentText.full}}</p>
                        <p>{{componentText.notified}}</p>
                        <b-button @click="registerWaiting" class="cta-button px-5 py-3 mt-5">{{componentText.waiting}}</b-button>
                    </div>

                    <div class="py-5" v-if="event.ed_u_on_waiting_list && event.ed_fully_booked">
                        <p>{{componentText.onWaiting}}</p>
                        <p>{{componentText.notified}}</p>
                        <!--<b-button @click="registerWaiting" class="brand-secondary bg-brand-highlight-1 px-5 py-3 mt-5">Add to waiting list</b-button>-->
                    </div>
                    <b-button v-if="!event.ed_fully_booked" @click="selected_timeslot ? startBooking() : ('')" class="cta-button px-5 py-3 my-3">
                        {{ selected_timeslot ? event.e_employee_pay_flag ? componentText.payment : componentText.registerSingle : componentText.selectTime }}
                    </b-button>
                </div>
            </div>
        </div>
        <div v-else-if="event.e_type === 'webinar' || event.e_type === 'external'" class="col-12">
            <div id="event-view-button">
                <div class="py-5 event-register">
                    <p>{{componentText.webinarAgree}}</p>
                    <p>
                        <a :href="terms" target="_blank">{{componentText.terms}}</a>
                    </p>
                    <b-button class="cta-button px-5 py-3 " :href="event.e_external_link" target="_blank">{{event.e_type === 'webinar' ? 'View Webinar' : 'View External Event'}}</b-button>
                </div>
            </div>
        </div>
        <div class="col-12 px-0" v-else >
            <div id="event-register-button" class="event-register">
                <div class="py-5" v-if="event.e_type !== 'event_series' && !event.ed_u_on_waiting_list && !event.ed_u_registered && event.ed_fully_booked">
                    <p>{{componentText.full}}</p>
                    <p>{{componentText.notified}}</p>
                    <b-button @click="registerWaiting" class="cta-button px-5 py-3 mt-5">{{componentText.waiting}}</b-button>
                </div>
                <div class="py-5" v-if="event.e_type === 'event_series' && !event.ed_u_registered && event.ed_fully_booked">
                    <p>{{componentText.waitingSlot}}</p>
                    <p>{{componentText.notified}}</p>
                </div>
                <div class="py-5" v-if="event.e_type !== 'event_series' && event.ed_u_on_waiting_list && !event.ed_u_registered && event.ed_fully_booked">
                    <p>{{componentText.onWaiting}}</p>
                    <p>{{componentText.notified}}</p>
                    <!--<b-button @click="registerWaiting" class="brand-secondary bg-brand-highlight-1 px-5 py-3 mt-5">Add to waiting list</b-button>-->
                </div>

                <div class="py-5" v-if="event.ed_u_registered">
                    <p>{{componentText.alreadyRegistered}}</p>
                    <b-button @click="cancelDateBooking" class="cta-button px-5 py-3 mt-5">{{componentText.cancel}}</b-button>
                </div>

                <div class="py-5" v-if="!event.ed_u_registered && !event.ed_fully_booked">
                    <h3 v-if="event.e_employee_series_cost > 0">Price: {{currencyString(event.e_employee_series_cost, event.e_currency)}}</h3>
                    <p>{{componentText.agree}}</p>
                    <p>
                        <a :href="terms" target="_blank">{{componentText.terms}}</a>
                    </p>
                    <b-button @click="startBooking()" class="cta-button test px-5 py-3 my-3">
                        {{ event.e_type === 'event_series' ? componentText.registerAll : event.e_employee_pay_flag ? componentText.payment : componentText.registerSingle }}
                    </b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import config from "../../config/constants";
    export default {
        name: 'LargeBookingButton',
        props: [
            'event',
        ],
        data() {
            return {
                selected_timeslot : false,
                message : ''
            }
        },
        mounted(){

        },
        computed:{
            terms(){
                //TODO move to hardcoded JSON
                if ( this.$store.getters.skin === 8 ){
                    return "/img/boi/BOI-Wellbeing-TCs.pdf"
                } else if(this.$store.getters.skin === 13){
                    return "/nutzungsbedingungen"
                } else {
                    return "/terms-and-conditions"
                }
            },
            timeslots(){
                return this.event.ed_timeslots.filter(x => {
                    if(!x.ts_fully_booked && x.ts_time_status !== 'past'){
                        return x
                    }
                })
            },
            componentText() {
                return this.getText.loggedIn.event.largeBookingButton;
            },
        },
        methods: {
            startBooking() {
                if(this.event.e_employee_pay_flag){
                    //load stripe
                    if(!document.getElementById('stripe-script')){
                        let script = document.createElement('script')
                        script.type = 'text/javascript'
                        script.src = 'https://js.stripe.com/v3/'
                        script.id = 'stripe-script'
                        document.head.appendChild(script)
                    }
                    if( this.event.e_type == 'service' ){
                        this.requestSlotStripeSession()
                    } else if ( this.event.e_type == 'event_series' ){
                        this.requestDateStripeSession(true)
                    } else {
                        this.requestDateStripeSession()
                    }
                } else {
                    if( this.event.e_type == 'service' ){
                        this.bookTimeslot()
                    } else if ( this.event.e_type == 'event_series' ){
                        this.bookDate(true)
                    } else {
                        this.bookDate(false)
                    }
                }
            },
            requestSlotStripeSession(all_series = false){
                const url = config.api_env + "/application/api/u/events/get-event-stripe-session-by-slot";
                const payload = {
                    user_id: this.$store.getters.user_id,
                    timeslot_id: this.selected_timeslot.ts_id,
                    all_series: all_series,
                    referrer: window.location.hostname
                };
                const opts = {
                    'method' : 'post',
                    'url' : url,
                    'data' : payload
                }
                const _this = this;
                this.axios(opts)
                    .then(function (res) {
                        if(res.data.success == true){
                            _this.redirectToStripe(res.data.session)
                        } else {
                            //TODO display some error
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            requestDateStripeSession( all_series = false ){
                const url = config.api_env + "/application/api/u/events/get-event-stripe-session-by-date";
                const payload = {
                    user_id: this.$store.getters.user_id,
                    event_date_id: this.event.ed_id,
                    all_series: all_series,
                    referrer: window.location.hostname
                };
                const opts = {
                    'method' : 'post',
                    'url' : url,
                    'data' : payload
                }
                const _this = this;
                this.axios(opts)
                    .then(function (res) {
                        if(res.data.success == true){
                            _this.redirectToStripe(res.data.session)
                        } else {
                            //TODO display some error
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            async bookDate( all_series = false ) {
                const payload = {
                    user_id: this.$store.getters.user_id,
                    event_date_id: this.event.ed_id,
                    all_series: all_series
                };
                let res = await this.axios.post(
                    config.api_env + "/application/api/u/events/book-date",
                    payload, config.options
                );
                if (res.data.success) {
                    this.$parent.getEventDate()
                    if(await this.showMsgModal(this.componentText.dateSuccessMsg)){
                        this.$router.push({name: 'events', params:{page: 'bookings'}})
                    }
                } else {
                    //TODO display alert
                }
            },
            async bookTimeslot( all_series = false ) {
                const payload = {
                    user_id: this.$store.getters.user_id,
                    timeslot_id: this.selected_timeslot.ts_id,
                    all_series: all_series
                };
                let res = await this.axios.post( config.api_env + "/application/api/u/events/book-timeslot", payload, config.options );
                if (res.data.success) {
                    this.$parent.getEventDate()
                    if(await this.showMsgModal(this.componentText.slotSuccessMsg)){
                        this.$router.push({name: 'events', params:{page: 'bookings'}})
                    }
                } else {
                    void await this.showMsgModal(this.componentText.errorMsg)
                }
            },
            async registerWaiting(){
                const payload = {
                    user_id: this.$store.getters.user_id,
                    event_date_id: this.event.ed_id
                }
                let res = await this.axios.post( config.api_env + "/application/api/u/events/register-waiting-list", payload, config.options)
                if( res.data.success ) {
                    this.$parent.getEventDate()
                    if(await this.showMsgModal(res.data.message)){
                        this.$router.push({name: 'events', params:{page: 'bookings'}})
                    }
                } else {
                    //TODO do something else
                }
            },
            async cancelDateBooking(){
                let all_series = this.event.e_type == 'event_series';
                const payload = {
                    user_id: this.$store.getters.user_id,
                    event_date_id: this.event.ed_id,
                    all_series: all_series
                }
                let res = await this.axios.post(config.api_env + '/application/api/u/events/cancel-date-booking', payload, config.options)
                if( res.data.success ) {
                    const msg = this.event.e_employee_pay_flag ? this.componentText.cancellationMsgPaid + this.currencyString(this.event.e_employee_slot_cost, this.event.b_t_currency) : this.componentText.cancellationMsgFree
                    const modalOptions = {
                        title: this.componentText.cancellationSuccess,
                        hideHeader: false,
                        size: "md",
                        buttonSize: "lg",
                        okTitle: "OK",
                        cancelTitle: "NO",
                        footerClass: "p-2",
                        hideHeaderClose: true,
                        centered: true,
                        contentClass: "pop-up-content"
                    }
                    this.showMsgModal(msg, modalOptions)
                    this.$parent.getEventDate()
                } else {
                    //TODO do something else
                }
            },
            selectTimeslot(slot){
                this.selected_timeslot = slot
            },
            redirectToStripe(session){
                const stripe = Stripe(config.stripe_key)
                stripe.redirectToCheckout({sessionId: session.id}).then(function (result) { console.log(result)});
            },
        }
    }
</script>
<style scoped lang="less">
    #event-register-button {

    }
</style>
