<template>
  <div id="ChallengesHeader">
    <div class="lineAcross"></div>
    <div class="title" :style="{fontSize: fontSize +'rem'}">{{title}}</div>
    <div class="lineAcross"></div>
  </div>
</template>

<script>
export default {
  name: "ChallengesHeader",
  props: [ 'title', 'fontSize']
}
</script>

<style lang="less">
  #ChallengesHeader {
    width: 100%;
    align-items: center;
    display: flex;
    padding: 10px 0;
    .lineAcross {
      width: 17.5%;
      height: 1px;
      background: white;
    }
    .title {
      width: 65%;
      border: 1px white solid;
      text-align: center;
      padding: 6px 0;
      color: white;
    }
  }
</style>