<template>
  <div id="SoundSpace">
    <div v-if="!isLoading">
      <section class="header-container">
        <div
          class="
            flex-row
            d-flex
            justify-content-around
            align-items-center
            h-100
          "
        >
          <div class="header-text">
            <h1
              class="header-title brand-text"
              v-html="componentText.header_title"
            ></h1>
            <div class="row d-lg-none">
              <img
                class="header-small-image"
                src="/img/SpectrumHeartHeadphones.png"
                alt="Headphones icon"
                @error="imageUrlAlt"
              />
            </div>
            <p
              class="header-content brand-text"
              v-html="componentText.header_desc"
            ></p>
          </div>
          <div class="header-image d-none d-lg-block">
            <img
              class="img-fluid"
              src="/img/SpectrumHeartHeadphones.png"
              alt="Headphones"
            />
          </div>
        </div>
      </section>

      <section class="curated-podcasts-container">
        <h1 class="section-title">{{ componentText.curated_podcasts }}</h1>
        <div
          class="
            curated-podcasts
            flex-row
            d-flex
            justify-content-start
            align-items-start
            flex-wrap
          "
          v-if="this.curatedPodcasts.length > 0"
        >
          <div
            class="curated-podcast"
            v-for="(podcast, i) in curatedPodcasts"
            :key="i"
            @click="selectPodcast(podcast.id)"
          >
            <div class="curated-podcast-image-container">
              <img
                class="curated-podcast-image"
                alt="Headphones icon"
                :src="podcast.image_url ? podcast.image_url : '/img/headphones.svg'"
                @error="imageUrlAlt"
              />
            </div>
            <div class="curated-podcast-title brand-text">
              {{ podcast.title }}
            </div>
          </div>
        </div>
        <div v-else>
          <p class="no-podcasts-message">
            {{ componentText.no_curated_podcast_available }}
          </p>
        </div>
      </section>
      <section class="featured-podcasts-container">
        <h1 class="section-title">{{ componentText.featured_podcasts }}</h1>
        <div
            class="flex-row flex-wrap d-flex align-items-stretch mx-0"
            v-if="this.featuredPodcasts.length > 0"
        >
          <div
              class="featured-podcast-container d-flex"
              v-for="(podcast, i) in featuredPodcasts"
              :key="i"
          >
            <div class="featured-podcast d-flex">
              <div class="row mx-0 d-flex h100">
                <div class="col-md-6 col-sm-12 d-flex flex-column">
                  <h2 class="podcast-title brand-text">
                    {{ podcast.title }}
                  </h2>
                  <div class="d-flex justify-content-center d-md-none pb-5">
                    <img class="podcast-image w100"
                         :src="podcast.image_url ? podcast.image_url : '/img/headphones.svg'"
                         @error="imageUrlAlt"
                    />
                  </div>
                  <div class="podcast-metadata flex-row d-flex mx-0">
                    <div class="brand-text">
                      <i class="fas fa-podcast"></i>
                      {{ podcast.episodes }} {{ componentText.episodes }}
                    </div>
                    <!--                    <div class="brand-text">-->
                    <!--                      <i class="fas fa-headphones"></i>-->
                    <!--                      {{ podcast.listeners }} {{ componentText.listeners }}-->
                    <!--                    </div>-->
                  </div>
                  <div class="podcast-blurb brand-text">
                    <read-more
                        :text="podcast.description"
                        :limit="250"
                    ></read-more>
                  </div>
                  <div class="podcast-play-button row center mt-auto pb-3">
                    <button
                        class="btn cta-button px-5 py-3 mx-auto"
                        @click="selectPodcast(podcast.id)"
                    >
                      <span
                      >{{ componentText.listen_now
                        }}<i class="fas fa-play btn-icon"></i
                        ></span>
                    </button>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 d-none d-md-block">
                  <div class="d-flex justify-content-center">
                    <img
                        class="podcast-image img-responsive"
                        :src="podcast.image_url ? podcast.image_url : '/img/headphones.svg'"
                        @error="imageUrlAlt"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <p class="no-podcasts-message">
            {{ componentText.no_featured_podcast_available }}
          </p>
        </div>
      </section>
    </div>
    <div class="d-flex flex-row align-items-center mt-5" v-else>
      <div class="mx-auto my-auto">
        <b-spinner class="brand-primary"></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import config from "../config/constants";
import ReadMore from "@/components/ReadMore.vue";

export default {
  name: "SoundSpace",
  components: {
    ReadMore,
  },
  data() {
    return {
      isLoading: true,
      podcasts: {},
    };
  },
  mounted() {
    this.getPodcasts();
  },
  methods: {
    imageUrlAlt(event) {
      event.target.src = "/img/headphones.svg"
    },
    selectPodcast(id) {
      this.$router.push("/sound-space/" + id).catch((err) => {});
    },
    async getPodcasts() {
      const payload = {
        user_id: this.$store.getters.user_id,
      };
      let res = await axios.post(
        config.api_env + "/application/api/u/podcasts/get-podcasts",
        payload,
        config.options
      );
      if (res.data.success) {
        this.podcasts = res.data.podcasts;
        this.isLoading = false;
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login").catch((err) => {});
      }
    },
  },
  computed: {
    componentText() {
      return this.getText.loggedIn.podcasts;
    },
    curatedPodcasts() {
      return this.podcasts.filter((podcast) => podcast.type === "external");
    },
    featuredPodcasts() {
      return this.podcasts.filter((podcast) => podcast.type === "hosted");
    },
  },
};
</script>
<style lang="less">
#SoundSpace {
  .header-container {
    height: auto;
    background-color: white;
    padding: 50px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    .header-text {
      max-width: 700px;
      padding: 0 30px;
      .header-title {
        font-size: 4.6rem;
        padding-bottom: 20px;
      }
      .header-content {
        font-size: 1.6rem;
      }
      .header-small-image {
        max-width: 70%;
        margin-left: auto;
        margin-right: auto;
        @media screen and (-ms-high-contrast: active),
          (-ms-high-contrast: none) {
          max-height: 550px;
        }
      }
    }
    .header-image {
      height: auto;
      max-width: 450px;
    }
  }
  .featured-podcasts-container {
    background-color: #e0e0e0;
    height: auto;
    padding: 35px 35px 0 35px;
    width: 100%;
    @media only screen and (max-width: 768px) {
      padding: 25px 25px 0 25px;
    }

    .featured-podcast-container {
      display: flex;
      flex-basis: 50%;
      max-width: 50%;
      padding: 0 20px;
      @media only screen and (min-width: 992px) {
        flex-basis: 50%;
        max-width: 50%;
      }
      @media only screen and (max-width: 992px) {
        flex-basis: 100%;
        max-width: 100%;
      }
      .featured-podcast {
        width: 100%;
        padding: 30px;
        margin: 20px 0;
        background-color: white;
        border-radius: 24px;
        overflow: hidden;
        .row {
          width: 100%;
        }

        .podcast-title {
          font-size: 2.8rem;
          font-weight: bold;
          text-align: left;
          font-family: "FilsonProBold", sans-serif;
        }
        .podcast-metadata {
          font-size: 1.6rem;
          div {
            margin-right: 10px;
          }
        }
        .podcast-blurb {
          font-size: 1.6rem;
          padding: 20px 0;
          text-align: left;
        }
        .podcast-play-button {
          .btn {
            font-size: 1.6rem;
            min-width: 200px;
            font-family: "FilsonProBold", sans-serif;
          }
          .button-icon-image {
            display: inline-block;
            margin-left: 15px;
            max-height: 1rem;
            max-width: 1rem;
          }
        }
        .podcast-image {
          width: 100%;
          max-width: 100%;
          height: auto;
          background-color: #ddd;
          @media screen and (-ms-high-contrast: active),
          (-ms-high-contrast: none) {
          max-height: 363px;
        }
        }
      }
    }
  }
  .curated-podcasts-container {
    background-color: #e0e0e0;
    height: auto;
    padding: 35px;
    .curated-podcasts {
      background-color: #ffffff;
      padding: 20px;
      margin: 25px 15px 0 15px;
      border-radius: 24px;
      .curated-podcast {
        cursor: pointer;
        padding: 25px;
        flex-basis: 20%;
        max-width: 20%;
        @media only screen and (max-width: 1200px) {
          flex-basis: 25%;
          max-width: 25%;
        }
        @media only screen and (max-width: 992px) {
          flex-basis: 33.33%;
          max-width: 33.33%;
        }
        @media only screen and (max-width: 768px) {
          flex-basis: 50%;
          max-width: 50%;
        }
        @media only screen and (max-width: 600px) {
          flex-basis: 100%;
          max-width: 100%;
        }
        .curated-podcast-image {
          border-radius: 16px;
          width: 100%;
          height: auto;
        }
        .curated-podcast-title {
          color: #3a4961;
          text-align: left;
          font-weight: bold;
          font-size: 2rem;
          margin-top: 15px;
          font-family: "FilsonProBold", sans-serif;
        }
      }
    }
  }
  .btn-icon {
    display: inline-block;
    margin-left: 15px;
  }
  .section-title {
    text-transform: uppercase;
    font-family: "FilsonProBold", sans-serif;
    color: #707070;
    margin-left: 30px;
  }
  .no-podcasts-message {
    font-weight: bold;
    font-size: 2rem;
    color: #707070;
    padding-left: 30px;
    padding-top: 30px;
  }
}
</style>
