<template>
  <div id="Rewards">
<!--    <div-->
<!--    v-if="!$store.getters.user.access_code_user"-->
<!--      class="page-header py-5"-->
<!--      :class="{-->
<!--        'bg-transparent': skin === 0,-->
<!--        'bg-brand-secondary': skin !== 0,-->
<!--      }"-->
<!--    >-->
<!--      <div class="content-container row py-5" >-->
<!--        <div-->
<!--          class="page-title col-12 col-md-6 pt-0 pt-md-5 text-center text-md-left"-->
<!--        >-->
<!--          <h2 class="text-center text-md-left">-->
<!--            {{ $route.meta.title ? $route.meta.title : componentText.title }}-->
<!--          </h2>-->
<!--          <div class="wellness-points">-->
<!--            <div-->
<!--              class="rewards-title text-white text-center text-md-left d-block d-md-flex"-->
<!--            >-->
<!--              <div class="info-wrap">-->
<!--                <span-->
<!--                  class="rewards-info cta-button"-->
<!--                  @click="showRewardsExplanation"-->
<!--                  v-if="skin === 0 || skin === 8"-->
<!--                >-->
<!--                  i-->
<!--                </span>-->
<!--                <img-->
<!--                  src="/img/i-white.svg"-->
<!--                  class="rewards-info"-->
<!--                  @click="showRewardsExplanation"-->
<!--                  v-else-->
<!--                />-->
<!--              </div>-->
<!--              <div class="points-heading">-->
<!--                <span>{{ componentText.points }}</span>-->
<!--              </div>-->
<!--            </div>-->
<!--            <p class="total-points">-->
<!--              <span class="total-points">{{ totalPoints }}</span>-->
<!--            </p>-->
<!--            <p class="text-white">{{ componentText.pointsMessage }}</p>-->
<!--          </div>-->
<!--        </div>-->
<!--        <RewardsCalendar-->
<!--          class="col-12 col-md-6"-->
<!--          v-on:total-points="updateTotalPoints"-->
<!--        ></RewardsCalendar>-->
<!--      </div>-->
<!--    </div>-->
    <RewardsLevels
      v-if="!selectedReward"
      v-on:view-reward="selectReward"
    ></RewardsLevels>
    <RewardDetails
      v-if="selectedReward"
      v-on:back="allRewards"
      :reward="selectedReward"
    ></RewardDetails>
    <RewardsPopup
      v-if="showExplanationPopup"
      v-on:close="hideExplanationPopup"
    ></RewardsPopup>
    <BenefitHubBanner />
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import config from "../config/constants";
import moment from "moment";
import RewardsCalendar from "../components/Rewards/RewardsCalendar.vue";
import RewardsPopup from "../components/Rewards/RewardsPointsPopup.vue";
import RewardsLevels from "../components/Rewards/RewardsLevels.vue";
import RewardDetails from "../components/Rewards/RewardDetails.vue";
import BenefitHubBanner from "../components/BenefitHubBanner.vue";
export default {
  name: "Rewards",
  components: {
    RewardsCalendar,
    RewardsPopup,
    RewardsLevels,
    RewardDetails,
    BenefitHubBanner,
  },
  data() {
    return {
      totalPoints: 0,
      showExplanationPopup: false,
      engagementExplanation: "",
      selectedReward: "",
    };
  },
  mounted() {
    if (this.$route.params.reward) {
      this.selectedReward = this.$route.params.reward;
    }
  },
  methods: {
    showRewardsExplanation() {
      this.showExplanationPopup = true;
    },
    hideExplanationPopup() {
      this.showExplanationPopup = false;
    },
    updateTotalPoints(newPoints) {
      this.totalPoints = newPoints;
    },
    selectReward(reward) {
      this.selectedReward = reward;
      const scrollTo = document.getElementById("Rewards");
      scrollTo.scrollIntoView({ behavior: "smooth" });
    },
    allRewards() {
      this.selectedReward = "";
    },
  },
  watch: {},
  computed: {
    skin() {
      return this.$store.getters.skin;
    },
    componentText() {
      return this.getText.loggedIn.rewards;
    },
  },
};
</script>
<style lang="less">
#Rewards {
  .page-title {
    text-align: left;
    h2 {
      text-align: left;
      font-size: 3.8rem;
      letter-spacing: 0.7rem;
      font-family: "FilsonProBold", sans-serif;
      color: #fff;
    }
  }
  .wellness-points {
    .rewards-title {
      font-family: FilsonProLight;
      font-size: 2.4rem;
      letter-spacing: 0;
      padding: 20px 0;
      display: flex;
      flex-direction: column;
      .info-wrap {
        display: block;
        width: 100%;
      }
      .rewards-info {
        font-size: 2.3rem;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        cursor: pointer;
        font-family: "Roboto", sans-serif;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .points-heading {
      }
    }
    .total-points {
      font-size: 9.9rem;
      font-family: FilsonProBold;
      line-height: 1;
    }
    p {
      font-family: FilsonProLight, sans-serif;
      font-size: 1.9rem;
    }
  }
  @media only screen and (max-width: 768px) {
    .page-title {
      h2 {
        font-size: 3.4rem;
      }
      .wellness-points {
        p {
          font-size: 1.8rem;
        }
        .rewards-title {
          font-size: 1.8rem;
          padding-bottom: 0;
        }
        .rewards-info {
          position: absolute;
          top: 0;
          right: 10px;
        }
        .total-points {
          font-size: 4rem;
        }
      }
    }
  }
}
</style>
