<template>
    <div id="HomeCoaching" class="my-5 pb-5">
        <div class="row">
            <div class="col-md-12 col-lg-4 d-flex flex-column align-items-start">
                <ItemCard></ItemCard>
            </div>
            <div class="col-md-12 col-lg-4 d-flex flex-column align-items-start">
                <ItemCard></ItemCard>
            </div>
            <div class="col-md-12 col-lg-4 d-flex flex-column align-items-start">
                <ItemCard></ItemCard>
            </div>
        </div>
    </div>
</template>

<script>
import ItemCard from "@/components/Home/New/ItemCard.vue"


export default {
    name: "HomeCoaching",
    props: {
        isEarliest: Boolean,
    },
    components: {
        ItemCard,
    },
    data() {
        return {
            slickOptions: {
                arrows: true,
                slidesToScroll: 1,
                slidesToShow: 3,
                nextArrow:
                    '<div class="slick-arrow-next">' +
                    '<a class="bg-brand-highlight-1 slick-arrow-container"><i class="fas fa-chevron-right text-white fa-2xs"></i></a>' +
                    '</div>',
                prevArrow:
                    '<div class="slick-arrow-prev">' +
                    '<a class="bg-brand-highlight-1 slick-arrow-container"><i class="fas fa-chevron-left text-white fa-2xs"></i></a>' +
                    '</div>',
                adaptiveHeight: true,
                infinite: false,
            },
        };
    },
    mounted() {

    },
};
</script>

<style lang="less">
#HomeCoaching {
    
}
</style>
