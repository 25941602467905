<template>
  <div id="Post" v-bind:class="animationClassObject">
    <div class="post-container">
      <div class="d-flex flex-row">
        <div class="post-avatar-container">
          <img
            class="img-fluid clickable"
            @click="$router.push('/community/user/' + post.user_profile_id)"
            :src="post.user_avatar"
            v-if="post.user_avatar !== null"
          />
          <img
            class="img-fluid user-avatar shadow clickable"
            @click="$router.push('/community/user/' + post.user_profile_id)"
            :src="
              'https://eu.ui-avatars.com/api/?name=' +
              post.user_firstname +
              '+' +
              post.user_lastname +
              '&size=128'
            "
            v-else
          />
        </div>
        <div
          class="
            post-meta-container
            d-flex
            flex-column
            align-items-start
            justify-centent-start
          "
        >
          <div
            class="post-meta-user brand-text clickable"
            @click="$router.push('/community/user/' + post.user_profile_id)"
          >
            <b>{{ post.user_firstname }} {{ post.user_lastname }}</b
            ><span v-if="post.user_title !== null">
              - {{ post.user_title }}</span
            >
          </div>
          <div
            class="post-meta-timestamp clickable"
            @click="$router.push('/community/post/' + post.id)"
            v-b-tooltip.hover
            :title="getDateHours(post.created_on)"
          >
            Posted {{ friendlyTimeString(post.created_on) }} to
            <span>General</span>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column">
        <div class="post-text-content" v-html="decodeData(post.content)">
        </div>
        <div v-if="post.media_url !== null">
          <div class="post-video-content mt-5" v-if="mediaTypeIsVideo">
            <video
              width="100%"
              class="video"
              controls
              controlsList="nodownload"
              :src="post.media_url"
            ></video>
          </div>
          <div class="post-image-content mt-5" v-else>
            <img class="img-fluid" :src="post.media_url" />
          </div>
        </div>
      </div>
      <hr />
      <div
        class="
          post-buttons
          d-flex
          flex-row
          justify-content-between
          align-items-center
          mx-0
        "
      >
        <div class="d-flex flex-row align-items-center brand-highlight-1">
          <i
            class="fas fa-heart fa-2x ml-4 clickable"
            v-if="post.has_user_liked"
            @click="toggleLikePost()"
          ></i>
          <i
            class="far fa-heart fa-2x ml-4 clickable"
            v-else
            @click="toggleLikePost()"
          ></i>

          <span class="post-liked-value ml-3"> {{ post.likes_count }} </span>
        </div>
        <button class="btn cta-button px-5 py-3 mr-4" @click="createComment()">
          Comment
        </button>
      </div>
      <hr />
      <div class="d-flex flex-column post-comments">
        <div
          class="post-comments-more-button brand-secondary pb-3 clickable"
          v-if="post.comments_count > comments.length"
          @click="getPostComments()"
        >
          View {{ post.comments_count - comments.length }} more comments
        </div>
        <div
          v-for="comment in comments"
          :key="comment.id"
          class="comment-container"
        >
          <!-- v-for needs to only use id, but bugged atm -->
          <Comment v-bind:comment="comment"></Comment>
        </div>
      </div>
      <div class="d-flex flex-row align-items-center post-comment-container">
        <div class="post-comment-avatar-container">
          <img
            class="img-fluid"
            :src="user.image_url"
            v-if="user.image_url !== null"
          />
          <img
            class="img-fluid user-avatar shadow"
            :src="
              'https://eu.ui-avatars.com/api/?name=' +
              user.firstname +
              '+' +
              user.lastname +
              '&size=128'
            "
            v-else
          />
        </div>
        <div class="post-comment-input-container">
          <textarea
            placeholder="Write something..."
            type="text"
            v-model="newCommentText"
            class="
              post-content-input
              border-brand-primary
              brand-text
              shadow
              w-100
            "
          ></textarea>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import config from "../../config/constants";
import Comment from "./Comment.vue";

export default {
  name: "Post",
  props: ["post", "user", "topics"],
  components: {
    Comment,
  },
  data() {
    return {
      comments: [],
      newCommentText: "",
      isDeleted: false,
      isLoadingComments: false,
      commentPage: 1,
    };
  },
  mounted() {
    this.comments = this.post.comments.reverse();
  },
  methods: {
    decodeData(data){
      try {
        return atob(data)
      } catch (error){
        return data
      }
    },
    async getPostComments() {
      let self = this;
      this.isLoadingComments = true;
      const payload = {
        user_id: this.$store.getters.user_id,
        post_id: this.post.id,
        page: this.commentPage,
      };
      let res = await axios.post(
        config.api_env + "/application/api/u/community/get-post-comments",
        payload,
        config.options
      );
      if (res.data.success) {
        if (this.commentPage === 1) {
          this.comments = res.data.comments.reverse();
        } else {
          this.comments = this.comments
            .reverse()
            .concat(res.data.comments)
            .reverse();
        }

        this.commentPage++;
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login").catch((err) => {});
      }
    },
    async deletePost() {
      let self = this;
      const payload = {
        user_id: this.$store.getters.user_id,
        post_id: this.post.id,
      };
      let res = await axios.post(
        config.api_env + "/application/api/u/community/delete-post",
        payload,
        config.options
      );
      if (res.data.success) {
        this.isDeleted = true;
        setTimeout(function () {
          self.$parent.removePost(self.index);
        }, 1000);
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login").catch((err) => {});
      }
    },
    async createComment() {
      if (this.newCommentText !== "") {
        const payload = {
          user_id: this.$store.getters.user_id,
          comment: {
            content: this.newCommentText,
            post_id: this.post.id,
          },
        };
        let res = await axios.post(
          config.api_env + "/application/api/u/community/create-comment",
          payload,
          config.options
        );
        if (res.data.success) {
          let self = this;
          let date = new Date();
          this.comments.push({
            id: res.data.comment_id,
            content: self.newCommentText,
            created_on: date.toISOString(),
            likes_count: 0,
            has_user_liked: false,
            isNew: true,
            is_user_owner: true,
            user_firstname: this.user.firstname,
            user_lastname: this.user.lastname,
            user_title: this.user.title,
            user_avatar: this.user.image_url,
          });
          this.newCommentText = "";
          this.post.comments_count = this.post.comments_count + 1;
        } else if (res.data.err && res.data.err.includes("3994")) {
          this.$store.dispatch("logout");
          this.$router.push("/login").catch((err) => {});
        }
      }
    },
    async toggleLikePost() {
      const payload = {
        user_id: this.$store.getters.user_id,
        post_id: this.post.id,
      };
      let res = await axios.post(
        config.api_env + "/application/api/u/community/toggle-like-post",
        payload,
        config.options
      );
      if (res.data.success) {
        this.post.has_user_liked = res.data.is_liked;
        if (res.data.is_liked) {
          this.post.likes_count++;
        } else {
          this.post.likes_count--;
        }
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login").catch((err) => {});
      }
    },
    removeComment(id) {
      this.post.comments_count = this.post.comments_count - 1;
      this.comments = this.comments.filter((comment) => comment.id !== id);
    },
  },
  computed: {
    mediaTypeIsVideo() {
      let extension = this.post.media_url.split(".").pop();
      if (extension === "mp4" || extension === "mov") {
        return true;
      } else {
        return false;
      }
    },
    animationClassObject: function () {
      return {
        "fade-out": this.isDeleted,
      };
    },
    componentText() {
      return this.getText.loggedIn;
    },
  },
};
</script>
<style lang="less">
#Post {
  padding-bottom: 10px;
  .post-container {
    background-color: white;
    border-radius: 32px;
    height: auto;
    padding: 20px;
    .post-header-container {
      display: flex;
      flex-grow: 1;
      .post-title {
        font-style: "Filson Pro Book", sans-serif;
        font-weight: 600;
        font-size: 2rem;
      }
      .post-topic-selector {
        font-size: 1.8rem;
      }
    }
    .post-avatar-container {
      width: 120px;
      display: flex;
      padding-right: 20px;
      .img-fluid {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 100px;
      }
    }
    .post-meta-container {
      display: flex;
      flex-grow: 1;
      .post-meta-user {
        font-size: 1.8rem;
        padding-top: 10px;
      }
      .post-meta-timestamp {
        font-size: 1.4rem;
      }
      .post-meta-timestamp:hover {
        text-decoration: underline;
      }
    }
    .post-text-content {
      word-wrap: break-word;
      padding-top: 10px;
      font-size: 1.6rem;
    }
    .post-image-content {
      width: 100%;
      .img-fluid {
        width: 100%;
      }
    }
    .post-buttons {
      .post-liked-value {
        position: relative;
        top: -2px;
        font-size: 1.4rem;
        font-weight: bold;
      }
      .btn {
        min-width: 200px !important;
      }
    }
    .post-comments {
      .post-comments-more-button {
        display: flex;
        flex-grow: 1;
        padding-left: 10px;
        font-size: 1.4rem;
        font-weight: bold;
        text-decoration: underline;
      }
    }
    .post-comment-container {
      .post-comment-avatar-container {
        max-width: 100px;
        display: flex;
        padding-right: 20px;
        .img-fluid {
          width: 80px;
          height: 80px;
          object-fit: cover;
          border-radius: 100px;
        }
      }
      .post-comment-input-container {
        max-width: 85%;
        width: 100%;
        .post-content-input {
          overflow: auto;
          flex-shrink: 1;
          width: 100%;
          font-size: 1.6rem;
          outline: none;
          height: 50px;
          border: 1px solid lightsteelblue;
          border-radius: 12px;
          padding: 10px 10px 10px 10px;
          resize: none;
        }
      }
    }
  }
  .clickable {
    cursor: pointer;
  }
}
</style>
