<template>
	<div
		id="DigitalClinicsReviews"
		class="digital-clinics-reviews-container content-container my-5"
	>
		<h2 class="pb-5">Customer Reviews</h2>
		<div class="row mx-5 px-5">
			<div class="col-md-4 col-sm-12 mt-3">
				<div class="digital-clinics-review bg-white shadow">
					<div
						class="
                  d-flex
                  flex-column
                  align-items-center
                  justify-content-center
                "
					>
						<div
							class="
                    digital-clinics-review-stars
                    mb-4
                    flex-row
                    justify-content-center
                    brand-primary
                  "
						>
							<i class="fas fa-star fa-2x px-1"></i>
							<i class="fas fa-star fa-2x px-1"></i>
							<i class="fas fa-star fa-2x px-1"></i>
							<i class="fas fa-star fa-2x px-1"></i>
							<i class="fas fa-star fa-2x px-1"></i>
						</div>
					</div>
					<p class="digital-clinics-review-content text-center">
						Digital Clinics has given me much greater ownership of my own
						mental health. The blend of self-directed elearning with the
						support of a coach as needed allowed me to access the support
						most fitting my needs at the right time.
					</p>
					<h4
						class="
                  digital-clinics-review-name
                  brand-highlight-1
                  mx-auto
                  text-center
                  mt-5
                  mb-1
                "
					>
						JAMES, FINANCIAL ADVISER
					</h4>
					<div class="digital-clinics-review-location text-center">
						London, UK
					</div>
				</div>
			</div>
			<div class="col-md-4 col-sm-12 mt-3">
				<div class="digital-clinics-review bg-white shadow">
					<div
						class="
                  d-flex
                  flex-column
                  align-items-center
                  justify-content-center
                "
					>
						<div
							class="
                    digital-clinics-review-stars
                    mb-4
                    flex-row
                    justify-content-center
                    brand-primary
                  "
						>
							<i class="fas fa-star fa-2x px-1"></i>
							<i class="fas fa-star fa-2x px-1"></i>
							<i class="fas fa-star fa-2x px-1"></i>
							<i class="fas fa-star fa-2x px-1"></i>
							<i class="fas fa-star fa-2x px-1"></i>
						</div>
					</div>
					<p class="digital-clinics-review-content text-center">
						I was initially hesitant about reaching out for support, but
						from the moment I booked into the digital clinics I
						knew I had done the right thing. Overall a professional and
						beneficial experience.
					</p>
          <br/>
					<h4
						class="
                  digital-clinics-review-name
                  brand-highlight-1
                  mx-auto
                  text-center
                  mt-5
                  mb-1
                "
					>
						ANDREA, RETAIL MANAGER
					</h4>
					<div class="digital-clinics-review-location text-center">
						Belfast, UK
					</div>
				</div>
			</div>
			<div class="col-md-4 col-sm-12 mt-3">
				<div class="digital-clinics-review bg-white shadow">
					<div
						class="
                  d-flex
                  flex-column
                  align-items-center
                  justify-content-center
                "
					>
						<div
							class="
                    digital-clinics-review-stars
                    mb-4
                    flex-row
                    justify-content-center
                    brand-primary
                  "
						>
							<i class="fas fa-star fa-2x px-1"></i>
							<i class="fas fa-star fa-2x px-1"></i>
							<i class="fas fa-star fa-2x px-1"></i>
							<i class="fas fa-star fa-2x px-1"></i>
							<i class="fas fa-star fa-2x px-1"></i>
						</div>
					</div>
					<p class="digital-clinics-review-content text-center brand-text">
						Digital Clinics is truly a massive leap forward in the employee
						mental health and wellbeing space. I had been looking for
						something more than EAP to offer to my team and believe that
						I’ve found it in this service.
					</p>
					<h4
						class="
                  digital-clinics-review-name
                  brand-highlight-1
                  mx-auto
                  text-center
                  mt-5
                  mb-1
                "
					>
						JOHN, SME OWNER
					</h4>
					<div class="digital-clinics-review-location text-center">
						Cardiff, UK
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "Reviews"
}
</script>
<style lang="less">
.digital-clinics-reviews-container {
	z-index: 10;
	padding-bottom: 30px;
	.digital-clinics-review {
		padding: 20px;
		.digital-clinics-review-content {
			font-size: 1.6rem;
		}
		.digital-clinics-review-name {
			font-size: 1.6rem;
			font-weight: bold;
			text-transform: uppercase;
		}
		.digital-clinics-review-location {
			font-size: 1.4rem;
		}
	}
}
</style>
