<template>
  <div id="DistanceChallenge">
    <i class="fa-regular fa-arrow-left-long challengesBack" style="font-size: 1.5rem" @click="$emit('back')"></i>
    <div class="title">Distance Challenge</div>
    <div class="description">Let’s setup your distance challenge. What activities do you want to allow to be tracked: What is the goal?</div>
    <div class="typeHolder">
      <div v-for="(type,i) in activity_types" class="type" >
        <div class="typeBtn" @click="toggleType(i)" :class="{active: selectedType.includes(i)}">
          {{type.label}}
        </div>
      </div>
    </div>
    <div class="description">What is the goal?</div>
    <div class="distanceHolder">
      <b-input type="number" v-model="distance" @keydown="handleDistanceInput" autocomplete="off" min="1"></b-input>
      <div class="unit">{{unit}}</div>
      <div class="toggleUnit" @click="toggleUnit"><i class="fa-solid fa-gear" :class="{change: changeUnit}"></i></div>
    </div>
    <ChallengeDatePicker ref="datePicker"/>
    <div @click="create" class="challengesButton mt-5" :class="{invalid: !passedValidation}">Create Challenge</div>
  </div>
</template>

<script>

import ChallengeDatePicker from "@/components/Challenges/NewChallenges/ChallengeDatePicker.vue";
export default {
  name: 'DistanceChallenge',
  components: {
    ChallengeDatePicker
  },
  props: ['activity_types'],
  data() {
    return {
      selectedType: [],
      distance: null,
      changeUnit: false
    }
  },
  computed: {
    unit() {
      return this.$store.getters.distanceUnit;
    },
    startDate() {
      return this.$refs.datePicker.startDate;
    },
    endDate() {
      return this.$refs.datePicker.endDate;
    },
    passedValidation() {
      return this.distance >= 1 && this.startDate && this.endDate && this.selectedType.length;
    },
    distanceInMeters() {
      if(this.unit === 'Kilometers') return Math.floor(this.distance * 1000);
      return this.convertMilesToMeters(this.distance);
    },
    selectedActivities() {
      return this.activity_types.filter((activity, i) => this.selectedType.includes(i))
    },
  },
  methods: {
    create() {
      if(!this.passedValidation) return;
      this.$parent.createChallenge({
        activity_types: this.selectedActivities,
        distance_meters: this.distanceInMeters,
        start_date_time: this.startDate + ' 00:00:00',
        end_date_time: this.endDate + ' 00:00:00'
      })
    },
    handleDistanceInput(evt) {
      if((!this.distance && Number.parseInt(evt.key) === 0) || evt.key === '-') {
        evt.preventDefault();
        return;
      }
      if(this.distance % 1 !== 0 && !isNaN(Number.parseInt(evt.key))) {
        const distance = this.distance.toString();
        if(distance.substr(distance.indexOf('.'),distance.length).length >= 3) {
          evt.preventDefault();
        }
      }
    },
    toggleUnit() {
      this.$store.dispatch('toggleDistanceUnit');
      this.changeUnit = true;
      setTimeout(()=> { this.changeUnit = false },1000)
    },
    toggleType(i) {
      if(this.selectedType.includes(i)) {
        const index = this.selectedType.indexOf(i);
        this.selectedType.splice(index,1)
      } else {
        this.selectedType.push(i)
      }
    }
  }
}
</script>

<style lang="less">
#DistanceChallenge {
  .typeHolder {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 10px;

    .type {
      padding: 5px;
      .typeBtn {
        font-size: 1.3rem;
        text-align: center;
        height: 50px;
        border-radius: 15px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px white solid;
        &:hover {
          background: white;
          color: black;
        }
        &.active {
          background: linear-gradient(90deg, #ED3A75 0%, #6A356A 100%);
          color: white;
        }
      }
    }
  }
  .change {
    transform: rotate(360deg);
    transition: transform 1s;
  }
  .distanceHolder {
    input {
      background-color: transparent !important;
      padding: 15px 10px;
      width: 75px;
      color: white;
      font-size: 1.4rem;
    }
    .unit {
      font-size: 1.8rem;
    }
    .toggleUnit {
      i {
        font-size: 1.75rem;
      }
      width: 25%;
      font-size: 1.1rem;
      cursor: pointer;
      text-align: center;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>