<template>
    <div class="HomeFrontDoorCard h-100">
        <div class="home-front-door-card d-flex flex-column h-100 shadow">
            <div class="row no-gutters">
                <img class="w-100 img" :src="item.featured_img" />
            </div>
            <div class="p-3 py-4 px-5 text-center d-flex flex-column bg-white">
                <h3 class="home-front-door-card-title">{{item.label}}</h3>
                <p class="home-front-door-card-p brand-text">{{item.description}}</p>
<!--                <div class="mt-auto">-->
<!--                    <b-button variant="cta" class="mt-auto px-5" @click="$router.push({name: item.item_slug})">View More</b-button>-->
<!--                </div>-->
            </div>
            <div class="p-2 bg-brand-highlight-1 learn-more-box" @click="$router.push({name: item.item_slug})">
              <div class="learn-more-text">{{ linkText(item.item_slug) }}</div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "HomeFrontDoor",
    props: {
        item: Object,
    },
    components: {
    },
    data() {
        return {
        };
    },
    computed: {
      componentText() {
        return this.getText.dfd;
      },
    },
    methods: {
      linkText(slug) {
        switch (slug) {
          case 'digitalClinicsGeneric':
            return this.componentText.homeFrontDoor.bookNow;
          case 'eap':
            return this.componentText.homeFrontDoor.callNow;
          case 'totalMentalHealth':
            return this.componentText.homeFrontDoor.bookNow;
          default:
            return this.componentText.homeFrontDoor.learnMore;
        }
      }

    },
    mounted() {

    },
};
</script>

<style lang="less">
.HomeFrontDoor {
    .HomeFrontDoorCard {
      box-shadow: 2px 3px 2px 1px #c9c8c8;;
    }
    .learn-more-box {
      width: 100% !important;
      .learn-more-text {
        color: white;
        text-align: center;
        font-family: "FilsonProBold", sans-serif;
        font-size: 1.5em;
      }
      &:hover {
        cursor: pointer;
      }
    }

    .img{
        min-width: 100%;
        object-fit: fill;
    }
    .home-title-line {
        height: 3px;
        max-width: 140px;
    }
    .home-front-door-card-title{
        letter-spacing: normal;
        font-size: 1.8rem;
        margin-top: 0;
        margin-bottom: 10px;
        padding: 0;
    }
    .home-front-door-card-p{
        font-size: 1.6rem;
    }
    .text-center{
        flex-grow: 1;
    }
}
</style>
