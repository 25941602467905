<template>
  <div @click.prevent="downloadFile(message)" class="file-icon">
    <div
        :class="classObject"
    ></div>
    <div>{{getFileName()}}</div>
  </div>
</template>

<script>
export default {
  name: "FileChatMessage",
  props: [
      "message"
  ],
  computed: {
    classObject: function () {
      const ext = this.message.state.attributes.files[0].type
      return {
        'fas fa-file-audio': ext === 'audio',
        'fas fa-file-video': ext === 'video',
        'fas fa-file-alt': ext === 'text',
        'fas fa-file-archive': ext === 'archive',
        'fas fa-file-word': ext === 'word',
        'fas fa-file-excel': ext === 'excel',
        'fas fa-file-pdf': ext === 'pdf',
        'fas fa-file-image': ext === 'image',
        'fas fa-file': ext !== 'audio'
            && ext !=='video'
            && ext !== 'text'
            && ext !=='archive'
            && ext !== 'word'
            && ext !== 'excel'
            && ext !== 'pdf'
            && ext !== 'image',
        'icon': true
      }
    }
  },
  methods: {
    async downloadFile(message) {
      message.state.media.getContentTemporaryUrl().then(async url=>{
        //download file with correct name
        const file = await fetch(url);
        const fileBlog = await file.blob();
        const fileURL = URL.createObjectURL(fileBlog);
        const anchor = document.createElement("a");
        anchor.href = fileURL;
        anchor.download = this.getFileName();
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        URL.revokeObjectURL(fileURL);
      });
    },
    getFileName() {
      if (
        typeof this.message.state.attributes.files[0].friendly_name ===
        "undefined"
      ) {
        return "noname";
      }
      return this.message.state.attributes.files[0].friendly_name;
    },
    getAuthor() {
      return this.message.state.author;
    }
  }
};
</script>

<style lang="less">
.file-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .icon {
    font-size: 30px;
    color: rebeccapurple;
  }
}

</style>
