<template>
    <div id="settings" class="container-fluid px-0">
        <div class="main no-side-bar">
            <div v-if="$store.state.user.authenticated">
                <TopNav></TopNav>
                <MobileNav></MobileNav>
            </div>
            <div class="page-header bg-brand-secondary">
                <div class="content-container container">
                    <div class="page-title col-12">
                        <h2>Nutzungsbedingungen</h2>
                    </div>
                </div>
            </div>
            <div class="content-wrap">
                <div class="content-container row py-5">
                    <div class="content-card p-5">
                        <div class="content-inner p-md-5 m-md-5 p-0 m-0" >
                            <h4>
                                1 Allgemeine Bestimmungen
                            </h4>
                            <p>
                                1.1 Die Sanus X GmbH, Kundmanngasse 21, 1030 Wien (<strong>"Betreiber", "wir",
                                "uns"</strong>) ist Betreiber der Sanus X-Plattform (<strong>"Plattform"</strong>) einer Wellbeing-
                                und Gesundheitsplattform. Die Nutzung der Plattform wird Ihnen durch Ihren
                                Arbeitgeber ermöglicht, um einen gesunden Lebensstil zu fördern. Über eine
                                Teilnahme entscheiden Sie stets freiwillig.
                            </p>
                            <p>
                                1.2 Über die Plattform können Sie von uns oder von Dritten angebotene
                                Dienstleistungen buchen. Wenn in der Plattform bei der konkreten
                                Dienstleistung nicht auf uns als Anbieter hingewiesen wird, stammen die
                                eingestellten Dienstleistungen, Leistungsbeschreibungen, Bedingungen und
                                sonstigen Inhalte nicht von uns. Wir haben auf diese Dritten keinen
                                Einfluss, haften nicht dafür und übernehmen auch keine Verpflichtung, diese
                                zu überwachen oder auf Rechtsmäßigkeit zu prüfen. Wir werden die Inhalte
                                und Angebote Dritter nicht als unsere eigenen ausgegeben.
                            </p>
                            <h4>
                                2 Geltung der Nutzungsbedingungen
                            </h4>
                            <p>
                                Die Nutzung der Plattform unterliegen diesen Nutzungsbedingungen. Diese
                                Nutzungsbedingungen regeln lediglich das Verhältnis zwischen Ihnen als
                                Nutzer (<strong>"Nutzer", "Sie", "Ihnen"</strong>) und uns als Betreiber der Plattform. Wenn
                                Sie diesen Bedingungen nicht zustimmen, sind Sie nicht berechtigt, diese
                                Plattform zu verwenden.
                            </p>
                            <h4>
                                3 Verwendung der Plattform
                            </h4>
                            <p>
                                3.1 Eine Nutzung der Plattform ist nur erlaubt, wenn Sie für den Abschluss
                                dieses Vertrages geschäftsfähig und zumindest 18 Jahre alt sind.
                            </p>
                            <p>
                                3.2 Um die Plattform nutzen zu können müssen Sie sich vorerst registrieren
                                (dazu siehe unten Punkt 4).
                            </p>
                            <h4>
                                4 Registrierung
                            </h4>
                            <p>
                                4.1 Eine Registrierung erfolgt unter Angabe der zwingend erforderlichen
                                nachfolgenden Daten: Vorname, Nachname, Email-Adresse, Organisationscode
                                und Passwort. Nach Bestätigung der Nutzungsbedingungen und der
                                Datenschutzerklärung mittels Checkboxen wird Ihr Profil im System angelegt.
                            </p>
                            <p>
                                4.2 Sie sind dafür verantwortlich, dass die im Registrierungsprozess
                                angegebenen Daten zutreffend sind. Aufgrund der möglichen Sensibilität der
                                auf der Plattform
                            </p>
                            <p>
                                verarbeiteten Daten ist die Registrierung nur im eigenen Namen erlaubt. Die
                                Weitergabe des Passworts an Dritte oder die Nutzung durch Dritte ist
                                ebenfalls untersagt. Sie haften dafür, dass Sie Ihr Passwort sicher
                                verwahren und geheim halten.
                            </p>
                            <p>
                                4.3 Wir haften nicht für Verluste oder Schäden, die entstehen, weil Sie uns
                                unzutreffende Informationen übermitteln oder Sie Ihre Kontoinformationen
                                nicht schützen. Bei Missbrauch oder Verdacht auf Missbrauch Ihrer
                                Kontoinformationen müssen Sie uns unter folgender Emailadresse
                                benachrichtigen: support@spectrum.life
                            </p>
                            <h4>
                                5 Vertragsdauer
                            </h4>
                            <p>
                                5.1 Der Vertrag über die Nutzung der Plattform beginnt mit der
                                Registrierung und wird auf unbestimmte Zeit abgeschlossen.
                            </p>
                            <p>
                                5.2 Wir sind berechtigt auch ohne vorherige Benachrichtigung, den Zugriff
                                auf die Plattform, Ihr Konto und Ihre Inhalte vorübergehend auszusetzen,
                                wenn begründeter Verdacht besteht, dass Sie oder ein Dritter, der Ihre
                                Kontoinformationen benutzt, gegen diese Bedingungen oder gegen geltendes
                                Recht im Zusammenhang mit der Nutzung dieser Plattform verstößt oder wenn
                                technische Umstände oder Sicherheitsprobleme dies notwendig machen. Wir
                                werden Sie verständigen, wenn der begründete Verdacht eines Verstoßes gegen
                                diese Bedingungen oder geltendes Recht vorliegt und Ihnen diesbezüglich
                                Möglichkeit zur Rechtfertigung und Aufklärung geben. Sollte sich der
                                Verdacht nicht ausräumen lassen, so sind wir berechtigt, Ihr Konto
                                unwiderruflich zu schließen, wenn wir dies im Hinblick auf die Schwere des
                                Verstoßes für angemessen halten.
                            </p>
                            <p>
                                5.3 Wir können den Betrieb der Plattform einstellen oder auch nur Ihr
                                Nutzungsrecht kündigen. Beides ist jederzeit und ohne Angabe von Gründen
                                möglich. Wir werden Ihnen einen solchen Schritt jedoch mit angemessener
                                Vorankündigungsfrist mitteilen und Ihnen die Möglichkeit geben,
                                entsprechende Vorkehrungen zu treffen, dass Ihnen daraus kein
                                unrechtmäßiger Schaden entsteht.
                            </p>
                            <h4>
                                6 Nutzungsrechte
                            </h4>
                            <p>
                                6.1 Ihnen stehen ausschließlich die nach diesen Nutzungsbedingungen
                                eingeräumten Rechte an der Plattform zu.
                            </p>
                            <p>
                                6.2 Sie verpflichten sich, die Dienste nur unter Einhaltung der
                                vertraglichen und gesetzlichen Bestimmungen in Anspruch zu nehmen und
                                jedwede missbräuchliche Inanspruchnahme zu unterlassen.
                            </p>
                            <h4>
                                7 Leistungsfristen
                            </h4>
                            <p>
                                7.1 Unsere in der Plattform angegebenen Leistungsfristen sind generell
                                unverbindlich.
                            </p>
                            <p>
                                7.2 Wir haben keine Leistungsverzögerungen zu vertreten, wenn diese
                                aufgrund von höherer Gewalt oder unvorhergesehenen Vorfällen geschehen, die
                                die Leistung wesentlich erschweren oder unmöglich machen, z.B.
                                Naturkatastrophen, Pandemien, Unfälle, Krieg, Terror, Verkehrs- oder
                                Betriebsstörungen, Arbeitskämpfe, behördliche Anordnungen und Maßnahmen.
                                Wir behalten uns vor, aufgrund o.g. Behinderungen die Leistung um die Dauer
                                der Behinderung aufzuschieben oder ggf. ganz vom Vertrag zurückzutreten.
                            </p>
                            <h4>
                                8 Dienstleistungen Dritter
                            </h4>
                            <p>
                                8.1 Als Betreiber der Plattform erbringen, besitzen oder kontrollieren wir
                                keine der von Dritten angebotenen Dienstleistungen
                                (<strong>"Drittdienstleistungen"</strong>), auf die Sie über unsere Plattform Zugriff haben.
                                Die Drittdienstleistungen gehören bzw. werden von einer dritten Partei
                                (<strong>"Drittanbieter"</strong>) direkt angeboten und werden von dieser kontrolliert und
                                zur Verfügung gestellt. Die Verantwortung für die Drittdienstleistungen
                                liegt beim jeweiligen Drittanbieter. Wenn Sie über unsere Plattform eine
                                Drittdienstleistung buchen, kommt ein Vertrag über diese
                                Drittdienstleistung zwischen Ihnen und dem jeweiligen Drittanbieter
                                zustande. Wir sind und werden weder Partei oder ein sonstiger Beteiligter
                                an dem Vertragsverhältnis zwischen Ihnen und Drittanbietern. Diesbezüglich
                                übernehmen wir keine wie immer geartete Verantwortung oder Haftung.
                            </p>
                            <p>
                                8.2 Für Ihre Buchung von Drittdienstleistung gelten die in Anlage ./A
                                angeschlossenen Vertragsbedingungen des jeweiligen Drittanbieters und Sie
                                müssen diese vor der Buchung verstanden haben und ihnen zustimmen. Die
                                Buchung bei einem Drittanbieter, den Sie über unsere Plattform gefunden
                                haben, erfolgt auf Ihre eigene Gefahr und wir übernehmen keine wie auch
                                immer geartete Haftung oder Verantwortung. Die Anzeige von
                                Drittdienstleistungen auf unserer Plattform ist keine Empfehlung von uns
                                für diese Drittdienstleistungen. Sollten wir Kenntnis von Rechtsverstößen
                                der Drittanbieter haben, die dazu führen, dass wir diese Angebote entfernen
                                müssen, werden wir entsprechende Schritte setzen.
                            </p>
                            <p>
                                8.3 Wenn in der Plattform bei der jeweiligen Drittdienstleistung nicht auf
                                einen anderen Drittanbieter hingewiesen wird, stammen Drittdienstleistungen
                                von Spectrum Wellness Holdings Limited (616774), 95 Merrion Square, Dublin
                                2, D02 KT67, Irland.
                            </p>
                            <h4>
                                9 Gesetzliche Widerrufsbelehrung
                            </h4>
                            <p>
                                9.1 Widerrufsrecht
                            </p>
                            <p>
                                Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen
                                Vertrag zu widerrufen. Achtung: dieser Widerruf gilt für diese
                                Nutzungsbedingungen und nicht automatisch auch für von Ihnen gebuchte
                                Drittdienstleistungen.
                            </p>
                            <p>
                                Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des
                                Vertragsabschlusses.
                            </p>
                            <p>
                                Um Ihr Widerrufsrecht auszuüben, müssen Sie uns, Sanus X GmbH,
                                Kundmanngasse 21, 1030 Wien, kontakt@sanusx.com, mittels einer eindeutigen
                                Erklärung (z.B. ein mit der Post versandter Brief oder E-Mail) über Ihren
                                Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das
                                beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht
                                vorgeschrieben ist. Der Widerruf kann auch formlos per E-Mail an
                                kontakt@sanusx.com erfolgen.
                            </p>
                            <p>
                                Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über
                                die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
                            </p>
                            <p>
                                9.2 Folgen des Widerrufs
                            </p>
                            <p>
                                Wenn Sie diesen Vertrag widerrufen, werden wir Ihr Account und Ihre im
                                Rahmen des Registrierungsprozesses zur Verfügung gestellte Daten umgehend
                                löschen.
                            </p>
                            <p>
                                9.3 Muster-Widerrufsformular:
                            </p>
                            <p>
                                Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses
                                Formular aus und senden Sie es an uns.
                            </p>
                            <p>
                                An Sanus X GmbH, Kundmanngasse 21, 1030 Wien, kontakt@sanusx.com
                            </p>
                            <p>
                                Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen
                                Vertrag über die Erbringung der folgenden Dienstleistung: Nutzung der
                                Plattform.
                            </p>
                            <p>
                                Registriert am (*)
                            </p>
                            <p>
                                Name des/der Verbraucher(s)
                            </p>
                            <p>
                                Anschrift des/der Verbraucher(s)
                            </p>
                            <p>
                                Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)
                            </p>
                            <p>
                                Datum
                            </p>
                            <h4>
                                10 Zugriff auf die Plattform
                            </h4>
                            <p>
                                10.1 Aufgrund der Beschaffenheit des Internets können wir nicht die
                                durchgehende und ununterbrochene Verfügbarkeit und Erreichbarkeit der
                                Plattform zusagen. Wir können die Verfügbarkeit der Plattform oder
                                bestimmte Bereiche oder Funktionen der Plattform jederzeit ohne
                                Vorankündigung einschränken, insbesondere auch dann, wenn dies im Hinblick
                                auf Kapazitätsgrenzen, die Sicherheit oder Integrität unserer Server oder
                                zur Durchführung von Wartungsmaßnahmen, die die ordnungsgemäße oder
                                verbesserte Funktion der Plattform gewährleisten, notwendig ist.
                            </p>
                            <p>
                                10.2 Wir können die Plattform verbessern, weiterentwickeln und verändern
                                und von Zeit zu Zeit neue Dienste einführen.
                            </p>
                            <p>
                                10.3 Wir sind berechtigt, jederzeit nach eigenem Ermessen neue Elemente als
                                Bestandteil und/oder als Ergänzung der Plattform zu implementieren sowie
                                die Art der Nutzbarkeit unserer Plattform auch grundlegend zu verändern.
                                Wir sind auch berechtigt, die Plattform zur Gänze abzuschalten und unser
                                Service einzustellen. Bevor wir die Plattform abschalten und unseren
                                Service einstellen, werden wir Sie mit angemessener Vorlaufzeit unter der
                                von Ihnen bekannt gegebenen Emailadresse diesbezüglich kontaktieren und
                                Ihnen mitteilen, ab wann die Plattform nicht mehr verfügbar sein wird. Nach
                                Ablauf dieser Frist können Sie auf die Plattform nicht mehr zugreifen und
                                Ihre Inhalte nicht mehr abrufen. Wenn Sie auf Ihre auf der Plattform
                                gespeicherten Daten zugreifen wollen, müssen Sie uns unter folgender
                                Emailadresse benachrichtigen: support@spectrum.life
                            </p>
                            <h4>
                                11 Änderungen der Bedingungen
                            </h4>
                            <p>
                                11.1 Aufgrund von Änderungen auf unserer Plattform und der geltenden
                                Gesetze oder aus sonstigen betrieblichen Gründen wird es immer wieder die
                                Notwendigkeit geben, diese Bedingungen anzupassen. Geänderte Bedingungen
                                werden wir Ihnen auf der Plattform bekanntgeben. Sollten Sie diesen
                                Bedingungen nicht zustimmen, sind Sie nicht berechtigt, die Plattform
                                weiter zu verwenden. Wenn Sie auf Ihre auf der Plattform gespeicherten
                                Daten zugreifen wollen, müssen Sie uns unter folgender Emailadresse
                                benachrichtigen: support@spectrum.life
                            </p>
                            <h4>
                                12 Gewährleistung und Haftung
                            </h4>
                            <p>
                                12.1 Wir stellen unsere Plattform kostenlos und ohne Mängelgewähr "wie
                                gesehen" zur Verfügung. <strong>Sie bestätigen ausdrücklich, folgende Bestimmungen
                                zu verstehen und mit ihnen einverstanden zu sein:</strong>
                            </p>
                            <p>
                                a) Wir übernehmen für die durch die Plattform generierten Informationen,
                                Dienstleistungen und Services keine Gewähr auf Richtigkeit, Aktualität,
                                Vollständigkeit und Funktionalität.
                            </p>
                            <p>
                                b) Die von Drittanbietern erstellten Inhalte sind nicht Teil unserer
                                Leistung. Wir überprüfen diese Inhalte in keiner Form und sind zu einer
                                Überprüfung auch nicht verpflichtet. Wir übernehmen für diese Inhalte keine
                                wie auch immer geartete Haftung oder Verantwortung.
                            </p>
                            <p>
                                c) Es ist nicht Teil unserer Leistung, dass (i) die Plattform jederzeit
                                abrufbar, sicher oder fehlerfrei ist, oder (ii) unwesentliche Fehler in der
                                Software oder im Service behoben werden. Diesbezüglich schließen wir daher
                                jede Gewährleistung aus.
                            </p>
                            <p>
                                d) Sie nehmen zur Kenntnis, dass die von uns angebotenen Dienste auch unter
                                Einbeziehung dritter Netzbetreiber angeboten werden. Die Verfügbarkeit der
                                Dienste ist deshalb von der technischen Bereitstellung fremder Dienste
                                abhängig, auf die wir keinen Einfluss haben. Wir haften Ihnen daher nicht
                                für
                            </p>
                            <p>
                                eine allfällige Unterbrechung, Störung oder Fehlerübertragung im
                                Zusammenhang mit der Bereitstellung des kostenlosen Services.
                            </p>
                            <p>
                                e) Wir sind berechtigt, die Dienste aus internen Gründen, etwa zu
                                Wartungszwecken, für eine kurze, angemessene Zeit zu unterbrechen. Sie
                                können daraus keine Ansprüche ableiten.
                            </p>
                            <p>
                                f) Als Betreiber der Plattform erbringen, besitzen oder kontrollieren wir
                                keine der von Dritten angebotenen Drittdienstleistungen, auf die Sie über
                                unsere Plattform Zugriff haben. Die Drittdienstleistungen gehören bzw.
                                werden von Drittanbietern direkt angeboten und werden von dieser
                                kontrolliert und zur Verfügung gestellt. Die Verantwortung für die
                                Drittdienstleistungen liegt beim jeweiligen Drittanbieter. Wenn Sie über
                                unsere Plattform eine Drittdienstleistung buchen, kommt ein Vertrag über
                                diese Drittdienstleistung zwischen Ihnen und dem jeweiligen Drittanbieter
                                zustande. Wir sind und werden weder Partei oder ein sonstiger Beteiligter
                                an dem Vertragsverhältnis zwischen Ihnen und Drittanbietern. Diesbezüglich
                                übernehmen wir keine wie immer geartete Verantwortung oder Haftung.
                            </p>
                            <p>
                                12.2 Wir haften nicht für nur leicht fahrlässig verursachte Schäden. Dieser
                                Haftungsausschluss gilt nicht für Personenschäden, für Schäden an Sachen,
                                die wir zur Bearbeitung übernommen haben oder für Schäden nach dem PHG.
                            </p>
                            <h4>
                                13 Angebote anderer Unternehmen, Verweise und Links
                            </h4>
                            <p>
                                Die Plattform kann Angebote und Dienste anderer Unternehmen sowie Links zu
                                externen Websites enthalten, für deren Inhalte wir jedoch keine
                                Verantwortung oder Haftung übernehmen. Links zu solchen Diensten Dritter
                                stellen keine Empfehlung dieser Dienste durch uns dar. Wir werden solche
                                Angebote und Dienste entsprechend kennzeichnen und nicht als unsere eigenen
                                Angebote oder Dienste darstellen.
                            </p>
                            <h4>
                                14 Copyright
                            </h4>
                            <p>
                                Die auf der Plattform enthaltenen Elemente wie z.B. Texte, Bilder, Grafiken
                                und Video-Dateien unterliegen dem Urheberschutz und dem Schutz des
                                geistigen Eigentums. Diese Elemente dürfen nicht ohne ausdrückliche
                                schriftliche Zustimmung von uns vervielfältigt oder anderwärtig bearbeitet
                                oder verarbeitet werden.
                            </p>
                            <h4>
                                15 Unsere Kontaktinformationen
                            </h4>
                            <p>
                                Unsere Kontaktinformationen sind:
                            </p>
                            <p>
                                Sanus X GmbH
                            </p>
                            <p>
                                Kundmanngasse 21, 1030 Wien
                            </p>
                            <p>
                                Firmenbuchnummer 500059g
                            </p>
                            <p>
                                kontakt@sanusx.com
                            </p>
                            <h4>
                                16 Informationen für Verbraucher
                            </h4>
                            <p>
                                16.1 Ein außergerichtliches Beschwerde- oder Rechtsbehelfsverfahren, dem
                                wir unterworfen sind, gibt es nicht.
                            </p>
                            <p>
                                16.2 Sie können den Vertragstext jederzeit auf der Plattform abrufen. Wir
                                selbst speichern die Vertragstexte und machen Ihnen diese auf Wunsch per
                                Email zugänglich.
                            </p>
                            <p>
                                16.3 Ihre Eingaben können Sie während der Registrierung jederzeit abbrechen
                                und korrigieren.
                            </p>
                            <p>
                                16.4 Die für den Vertragsabschluss zur Verfügung stehende Sprache ist
                                ausschließlich Deutsch.
                            </p>
                            <p>
                                16.5 Wir haben uns keinem besonderen Verhaltenskodex (Regelwerk)
                                unterworfen.
                            </p>
                            <h4>
                                17 Anwendbares Recht
                            </h4>
                            <p>
                                Diese Bedingungen und die Geschäftsbeziehung zwischen Ihnen und uns,
                                einschließlich etwaiger Fragen über das gültige Zustandekommen eines
                                Vertragsverhältnisses und einschließlich etwaiger Streitigkeiten
                                unterliegen den Gesetzen der Republik Österreich unter Ausschluss des
                                internationalen Privatrechts und Kollisionsnormen. Diese Rechtswahl führt
                                nicht dazu, dass Ihnen der Schutz entzogen wird, der Ihnen durch diejenigen
                                Bestimmungen gewährt wird, von denen nach dem Recht des Staates, in dem Sie
                                Ihren gewöhnlichen Aufenthalt haben, nicht durch Vereinbarung abgewichen
                                werden darf (Günstigkeitsprinzip).
                            </p>
                            <h4>
                                18 Alternative Streitbeilegung
                            </h4>
                            <p>
                                Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung
                                bereit, die unter http://ec.europa.eu/consumers/odr/ zu finden ist. Wir
                                sind nicht dazu verpflichtet, an Streitbeilegungsverfahren vor einer
                                Verbraucherschlichtungsstelle teilzunehmen und nehmen auch nicht freiwillig
                                daran teil.
                            </p>
                            <h4>
                                19 Schlussbestimmungen
                            </h4>
                            <p>
                                19.1 Sollten einzelne Bestimmungen dieser allgemeinen Geschäftsbedingungen
                                einschließlich dieser Bestimmung ganz oder teilweise unwirksam sein oder
                                werden, bleibt die Wirksamkeit der übrigen Regelungen unberührt.
                            </p>
                            <p>
                                19.2 Änderungen und Ergänzungen der Nutzungsbedingungen bedürfen der
                                Schriftform. Dies gilt auch für das Abgehen von dem Schriftformerfordernis.
                                Erklärungen per E-Mail entsprechen der Schriftform, dies gilt auch für das
                                Klicken auf einen entsprechenden Button.
                            </p>
                            <p>
                                19.3 Sollten in diesen Nutzungsbedingungen auf natürliche personenbezogene
                                Bezeichnungen nur in männlicher Form angeführt sein, beziehen sie sich auf
                                Frauen und Männer in gleicher Weise. Bei der Anwendung der Bezeichnung auf
                                bestimmte natürliche Personen ist die jeweils geschlechtsspezifische Form
                                zu verwenden.
                            </p>
                            <p>
                                19.4 Wenn wir ein Recht oder eine Bestimmung dieser Nutzungsbedingungen
                                nicht durchsetzen, stellt dies keinen Verzicht auf dieses Recht oder diese
                                Bestimmung dar, sofern dies nicht schriftlich von uns anerkannt wurde.
                            </p>
                            <h4 style="text-align: right">
                                Stand Oktober 2020
                            </h4>
                            <h4 style="text-align: right">
                                Anlage ./A – Bedingungen für Drittdienstleistungen
                            </h4>
                            <h4>
                                1 Allgemeine Bestimmungen
                            </h4>
                            <p>
                                1.1 Auf der Sanus X-Plattform (<strong>"Plattform"</strong>) ist, wenn kein Dienstleister
                                bei der jeweiligen Wellbeing- oder Gesundheitsdienstleistung
                                (<strong>"Dienstleistung"</strong>) als Anbieter angegeben ist, Spectrum Wellness Holdings
                                Limited (616774), 95 Merrion Square, Dublin 2, D02 KT67, Ireland (<strong>"SWHL"</strong>),
                                Anbieter.
                            </p>
                            <p>
                                1.2 Die in dieser Anlage ./A enthaltenen Bedingungen regeln das Verhältnis
                                zwischen Ihnen und SHWL oder dem ausdrücklich bei der Dienstleistung
                                angegebenen dritten Anbieter (jeweils <strong>"Drittdienstleister"</strong>) als Anbieter
                                der Dienstleistungen, sofern der Drittdienstleister nicht abweichende
                                Bedingungen mit Ihnen vereinbart. Wenn Sie diesen Bedingungen nicht
                                zustimmen, sind Sie nicht berechtigt, die vom Drittdienstleister angebotene
                                Dienstleistungen zu buchen. Wenn Sie die vom Drittdienstleister angebotene
                                Dienstleistungen buchen sind Sie Vertragspartei eines verbindlichen
                                Vertrags.
                            </p>
                            <h4>
                                2 Vertragsabschluss
                            </h4>
                            <p>
                                2.1 Um eine Dienstleistung buchen zu können, müssen Sie der Anleitung in
                                der Plattform folgen.
                            </p>
                            <p>
                                2.2 Eine Buchung der Dienstleistungen ist nur erlaubt, wenn Sie für den
                                Abschluss dieses Vertrages geschäftsfähig und zumindest 18 Jahre alt sind.
                            </p>
                            <h4>
                                3 Gesetzliche Widerrufsbelehrung
                            </h4>
                            <p>
                                3.1 Widerrufsrecht
                            </p>
                            <p>
                                Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen
                                Vertrag zu widerrufen.
                            </p>
                            <p>
                                Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des
                                Vertragsabschlusses.
                            </p>
                            <p>
                                Um Ihr Widerrufsrecht auszuüben, müssen Sie uns [Name, Adresse und
                                E-Mail-Adresse des Drittdienstleisters], mittels einer eindeutigen
                                Erklärung (z.B. ein mit der Post versandter Brief oder E-Mail) über Ihren
                                Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das
                                beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht
                                vorgeschrieben ist. Der Widerruf kann auch formlos per E-Mail an
                                kontakt@sanusx.com erfolgen.
                            </p>
                            <p>
                                Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über
                                die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
                            </p>
                            <p>
                                3.2 Folgen des Widerrufs
                            </p>
                            <p>
                                Wenn Sie diesen Vertrag widerrufen, werden wir Ihre im Rahmen des
                                Buchungsvorganges zur Verfügung gestellte Daten umgehend löschen.
                            </p>
                            <p>
                                3.3 Muster-Widerrufsformular:
                            </p>
                            <p>
                                Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses
                                Formular aus und senden Sie es an uns.
                            </p>
                            <p>
                                An [Name und Adresse des Drittdienstleisters und gegebenenfalls die
                                E-Mail-Adresse einzufügen]:1
                            </p>
                            <p>
                                Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen
                                Vertrag über die Erbringung der folgenden Dienstleistung: (*)
                            </p>
                            <p>
                                Gebucht am (*)
                            </p>
                            <p>
                                Name des/der Verbraucher(s)
                            </p>
                            <p>
                                Anschrift des/der Verbraucher(s)
                            </p>
                            <p>
                                Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)
                            </p>
                            <p>
                                Datum
                            </p>
                            <h4>
                                4 Keine medizinische Beratung
                            </h4>
                            <p>
                                Der Drittdienstleister führt keine Diagnosen, Therapien und Behandlungen im
                                medizinisch-wissenschaftlichem Sinne oder bietet solche an. Die gebuchten
                                Dienstleistungen ersetzen keine ärztliche Untersuchung und Behandlung. Es
                                handelt sich um reine Informationen über Möglichkeiten und persönliche
                                Erfahrungen, aus denen keine Heilversprechen irgendwelcher Art abgeleitet
                                werden können. Die erhaltenen Informationen dürfen daher nicht zur Diagnose
                                eines gesundheitlichen oder medizinischen Problems oder einer Krankheit
                                verwendet werden. In solchen Fällen müssen Sie stets einen Arzt
                                konsultieren bzw medizinischen Rat einholen.
                            </p>
                            <h4>
                                5 Informationen für Verbraucher
                            </h4>
                            <p>
                                5.1 Ein außergerichtliches Beschwerde- oder Rechtsbehelfsverfahren, dem der
                                Drittdienstleister unterworfen sind, gibt es nicht.
                            </p>
                            <p>
                                5.2 Sie können den Vertragstext jederzeit auf der Plattform abrufen. Der
                                Drittdienstleister selbst speichert die Vertragstexte und macht Ihnen diese
                                auf Wunsch per E-Mail zugänglich.
                            </p>
                            <p>
                                5.3 Ihre Eingaben können Sie während der Buchung jederzeit abbrechen und
                                korrigieren.
                            </p>
                            <p>
                                5.4 Die für den Vertragsabschluss zur Verfügung stehende Sprache ist
                                ausschließlich Deutsch.
                            </p>
                            <p>
                                5.5 Der Drittdienstleister hat sich keinem besonderen Verhaltenskodex
                                (Regelwerk) unterworfen.
                            </p>
                            <h4>
                                6 Anwendbares Recht
                            </h4>
                            <p>
                                Diese Bedingungen und die Geschäftsbeziehung zwischen Ihnen und dem
                                Drittdienstleister, einschließlich etwaiger Fragen über das gültige
                                Zustandekommen eines Vertragsverhältnisses und einschließlich etwaiger
                                Streitigkeiten unterliegen den Gesetzen der Republik Österreich unter
                                Ausschluss des internationalen Privatrechts und Kollisionsnormen. Diese
                                Rechtswahl führt nicht dazu, dass Ihnen der Schutz entzogen wird, der Ihnen
                                durch diejenigen Bestimmungen gewährt wird, von denen nach dem Recht des
                                Staates, in dem Sie Ihren gewöhnlichen Aufenthalt haben, nicht durch
                                Vereinbarung abgewichen werden darf (Günstigkeitsprinzip).
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer v-if="$store.state.user.authenticated"></Footer>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import TopNav from "@/components/TopNav.vue";
    import MobileNav from "@/components/MobileNav.vue";
    import Footer from "../components/Footer";
    export default {
        name: "TCs",
        components: {
            Footer,
            TopNav,
            MobileNav,
        },
        data() {
            return {

            };
        },
        mounted() {
            if(this.$route.query.lang) {
                this.$store.commit('setLanguage', this.$route.query.lang)
            }
        },
        methods: {
        },
        computed: {
            componentText() {
                return this.getText.terms;
            },
        },
        watch:{
            skin(newVal){
                this.$store.commit("setSkin", newVal)
            }
        }
    };
</script>
<style lang="less" scoped>
    #settings {
        font-size: 1.6rem;
        font-family: "DMSans", sans-serif;
        .content-wrap{
            background: #f4f4f4;
            .content-card{
                transform: translateY(-80px);
                background: #ffffff;
                max-width: 1000px;
                margin: auto;
            }
        }
        h4, strong{
            font-family: FilsonProBold, sans-serif;
        }
        h4{
            margin: 20px 0 10px 0;
        }
        @media (max-width: 992px){
            .page-header{
                padding-top: 100px;
            }
            .content-card {
                transform: translateY(0);
            }
        }
        @media (max-width: 540px){
            h2{
                font-size: 1.7rem;
            }
        }
    }
</style>
