<template>
    <div class="container-fluid px-0">
        <center>
            <table align="center" border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;height:100%;margin-top:0;margin-bottom:0;margin-right:0;margin-left:0;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0;width:100%;background-color:#f5f5f5;">
                <tr>
                    <td align="center" valign="top" id="bodyCell" style="mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;height:100%;margin-top:0;margin-bottom:0;margin-right:0;margin-left:0;width:100%;padding-top:10px;padding-bottom:10px;padding-right:10px;padding-left:10px;border-top-width:0;">
                        <table border="0" cellpadding="0" cellspacing="0" width="100%" class="templateContainer" style="border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;max-width:600px !important;border-width:0;">
                            <tr>
                                <td valign="top" id="templatePreheader" style="mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;background-color:#f5f5f5;background-image:none;background-repeat:no-repeat;background-position:center;background-size:cover;border-top-width:0;border-bottom-width:0;padding-top:9px;padding-bottom:9px;"></td>
                            </tr>
                            <tr>
                                <td valign="top" id="templateHeader" style="mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;background-color:#ffffff;background-image:none;background-repeat:no-repeat;background-position:center;background-size:cover;border-top-width:0;border-bottom-width:0;padding-top:9px;padding-bottom:0;">
                                    <table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageBlock" style="min-width:100%;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;">
                                        <tbody class="mcnImageBlockOuter">
                                            <tr>
                                                <td valign="top"  class="mcnImageBlockInner" style="padding-top:0px;padding-bottom:0px;padding-right:0px;padding-left:0px;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;">
                                                    <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer" style="min-width:100%;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;">
                                                        <tbody>
                                                            <tr>
                                                                <td class="mcnImageContent" valign="top" style="padding-right:0px;padding-left:0px;padding-top:0;padding-bottom:0;text-align:center;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;"></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td valign="top" id="templateBody" style="mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;background-color:#ffffff;background-image:none;background-repeat:no-repeat;background-position:center;background-size:cover;border-top-width:0;border-bottom-width:2px;border-bottom-style:solid;border-bottom-color:#EAEAEA;padding-top:0px;padding-bottom:10px;">
                                    <table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width:100%;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;">
                                        <tbody class="mcnTextBlockOuter">
                                            <tr>
                                                <td valign="top" class="mcnTextBlockInner" style="padding-top:9px;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;">
                                                    <table align="left" border="0" cellpadding="0" cellspacing="0"  width="100%" class="mcnTextContentContainer" style="max-width:100%;min-width:100%;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;">
                                                        <tbody>
                                                            <tr>
                                                                <td valign="top" class="mcnTextContent" style="padding-top:0px;padding-bottom:9px;padding-right:18px;padding-left:18px;color:#3a4a61;font-family:'Helvetica Neue', Helvetica, Arial, Verdana, sans-serif;font-size:24px;font-style:normal;font-weight:bold;text-align:center;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;word-break:break-word;line-height:150%;"></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnBoxedTextBlock" style="min-width:100%;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;">
                                        <tbody class="mcnBoxedTextBlockOuter">
                                            <tr>
                                                <td valign="top" class="mcnBoxedTextBlockInner" style="mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;">
                                                    <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%"  class="mcnBoxedTextContentContainer" style="min-width:100%;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;">
                                                        <tbody>
                                                            <tr>
                                                                <td style="padding-top:9px;padding-left:18px;padding-bottom:9px;padding-right:18px;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;">
                                                                    <table border="0" cellpadding="18" cellspacing="0" class="mcnTextContentContainer" width="100%" style="min-width:100% !important;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td valign="top" class="mcnTextContent" style="color:#3a4a61;font-family:Helvetica;font-size:18px;font-weight:normal;text-align:left;mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;word-break:break-word;line-height:150%;">
                                                                                    <img align="left" alt="" src="https://healthcoach-media.com/res/surge/emails/bupa-company-logo.png" width="80" class="mcnImage" style="max-width:80px; width:80px; padding-bottom:0; display:inline !important; vertical-align:bottom; -ms-interpolation-mode:bicubic; border-width:0; height:auto; outline-style:none; text-decoration:none;"><br><br><br><br>
                                                                                    <div><br><span style="font-size:18px;"><div v-html="email" /></span></div>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td valign="top" id="templateFooter" style="mso-line-height-rule:exactly;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;background-color:#f5f5f5;background-image:none;background-repeat:no-repeat;background-position:center;background-size:cover;border-top-width:0;border-bottom-width:0;padding-top:9px;padding-bottom:0px;"></td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
        </center>
    </div>
</template>
  
<script>
  export default {
    name: "OpenEmailBrowser",
    components: {},
    data() {
        return {
            email: null,
        };
    },
    mounted() {
        this.email = atob(this.$route.query.data);
    },
    methods: {},
    computed: {},
  };
</script>
<style lang="less">
p{
    margin:10px 0;
    padding:0;
}
table{
    border-collapse:collapse;
}
h1,h2,h3,h4,h5,h6{
    display:block;
    margin:0;
    padding:0;
}
img,a img{
    border:0;
    height:auto;
    outline:none;
    text-decoration:none;
}
body,#bodyTable,#bodyCell{
    height:100%;
    margin:0;
    padding:0;
    width:100%;
}
.mcnPreviewText{
    display:none !important;
}
#outlook a{
    padding:0;
}
img{
    -ms-interpolation-mode:bicubic;
}
table{
    mso-table-lspace:0pt;
    mso-table-rspace:0pt;
}
.ReadMsgBody{
    width:100%;
}
.ExternalClass{
    width:100%;
}
p,a,li,td,blockquote{
    mso-line-height-rule:exactly;
}
a[href^=tel],a[href^=sms]{
    color:inherit;
    cursor:default;
    text-decoration:none;
}
p,a,li,td,body,table,blockquote{
    -ms-text-size-adjust:100%;
    -webkit-text-size-adjust:100%;
}
.ExternalClass,.ExternalClass p,.ExternalClass td,.ExternalClass div,.ExternalClass span,.ExternalClass font{
    line-height:100%;
}
a[x-apple-data-detectors]{
    color:inherit !important;
    text-decoration:none !important;
    font-size:inherit !important;
    font-family:inherit !important;
    font-weight:inherit !important;
    line-height:inherit !important;
}
#bodyCell{
    padding:10px;
}
.templateContainer{
    max-width:600px !important;
    border:0;
}
a.mcnButton{
    display:block;
}
.mcnImage{
    vertical-align:bottom;
}
.mcnTextContent{
    word-break:break-word;
}
.mcnTextContent img{
    height:auto !important;
}
.mcnDividerBlock{
    table-layout:fixed !important;
}

body,#bodyTable{
    background-color:#f5f5f5;
}

#bodyCell{
    border-top:0;
}

.templateContainer{
    border:0;
}

h1{
    color:#3a4a61;
    font-family:Helvetica;
    font-size:36px;
    font-style:normal;
    font-weight:normal;
    line-height:125%;
    letter-spacing:normal;
    text-align:center;
}

h2{
    color:#3a4a61;
    font-family:Helvetica;
    font-size:22px;
    font-style:normal;
    font-weight:bold;
    line-height:125%;
    letter-spacing:normal;
    text-align:left;
}

h3{
    color:#3a4a61;
    font-family:Helvetica;
    font-size:18px;
    font-style:normal;
    font-weight:bold;
    line-height:125%;
    letter-spacing:normal;
    text-align:left;
}

h4{
    color:#3a4a61;
    font-family:Helvetica;
    font-size:18px;
    font-style:normal;
    font-weight:bold;
    line-height:125%;
    letter-spacing:normal;
    text-align:left;
}

#templatePreheader{
    background-color:#f5f5f5;
    background-image:none;
    background-repeat:no-repeat;
    background-position:center;
    background-size:cover;
    border-top:0;
    border-bottom:0;
    padding-top:9px;
    padding-bottom:9px;
}

#templatePreheader .mcnTextContent,#templatePreheader .mcnTextContent p{
    color:#3a4a61;
    font-family:Helvetica;
    font-size:12px;
    line-height:150%;
    text-align:left;
}

#templatePreheader .mcnTextContent a,#templatePreheader .mcnTextContent p a{
    color:#3a4a61;
    font-weight:normal;
    text-decoration:underline;
}

#templateHeader{
    background-color:#ffffff;
    background-image:none;
    background-repeat:no-repeat;
    background-position:center;
    background-size:cover;
    border-top:0;
    border-bottom:0;
    padding-top:9px;
    padding-bottom:0;
}

#templateHeader .mcnTextContent,#templateHeader .mcnTextContent p{
    color:#3a4a61;
    font-family:Helvetica;
    font-size:24px;
    line-height:150%;
    text-align:left;
}

#templateHeader .mcnTextContent a,#templateHeader .mcnTextContent p a{
    color:#3a4a61;
    font-weight:normal;
    text-decoration:underline;
}

#templateBody{
    background-color:#ffffff;
    background-image:none;
    background-repeat:no-repeat;
    background-position:center;
    background-size:cover;
    border-top:0;
    border-bottom:2px solid #EAEAEA;
    padding-top:0px;
    padding-bottom:10px;
}

#templateBody .mcnTextContent,#templateBody .mcnTextContent p{
    color:#3a4a61;
    font-family:Helvetica;
    font-size:18px;
    line-height:150%;
    text-align:left;
}

#templateBody .mcnTextContent a,#templateBody .mcnTextContent p a{
    color:#3a4a61;
    font-weight:normal;
    text-decoration:underline;
}

#templateFooter{
    background-color:#f5f5f5;
    background-image:none;
    background-repeat:no-repeat;
    background-position:center;
    background-size:cover;
    border-top:0;
    border-bottom:0;
    padding-top:9px;
    padding-bottom:0px;
}

#templateFooter .mcnTextContent,#templateFooter .mcnTextContent p{
    color:#3a4a61;
    font-family:Helvetica;
    font-size:12px;
    line-height:150%;
    text-align:center;
}

#templateFooter .mcnTextContent a,#templateFooter .mcnTextContent p a{
    color:#3a4a61;
    font-weight:normal;
    text-decoration:underline;
}
@media only screen and (min-width:768px){
    .templateContainer{
    width:600px !important;
    }

}	@media only screen and (max-width: 480px){
    body,table,td,p,a,li,blockquote{
    -webkit-text-size-adjust:none !important;
    }

}	@media only screen and (max-width: 480px){
    body{
    width:100% !important;
    min-width:100% !important;
    }

}	@media only screen and (max-width: 480px){
    #bodyCell{
    padding-top:10px !important;
    }

}	@media only screen and (max-width: 480px){
    .mcnImage{
    width:100% !important;
    }

}	@media only screen and (max-width: 480px){
    .mcnCartContainer,.mcnCaptionTopContent,.mcnRecContentContainer,.mcnCaptionBottomContent,.mcnTextContentContainer,.mcnBoxedTextContentContainer,.mcnImageGroupContentContainer,.mcnCaptionLeftTextContentContainer,.mcnCaptionRightTextContentContainer,.mcnCaptionLeftImageContentContainer,.mcnCaptionRightImageContentContainer,.mcnImageCardLeftTextContentContainer,.mcnImageCardRightTextContentContainer{
    max-width:100% !important;
    width:100% !important;
    }

}	@media only screen and (max-width: 480px){
    .mcnBoxedTextContentContainer{
    min-width:100% !important;
    }

}	@media only screen and (max-width: 480px){
    .mcnImageGroupContent{
    padding:9px !important;
    }

}	@media only screen and (max-width: 480px){
    .mcnCaptionLeftContentOuter .mcnTextContent,.mcnCaptionRightContentOuter .mcnTextContent{
    padding-top:9px !important;
    }

}	@media only screen and (max-width: 480px){
    .mcnImageCardTopImageContent,.mcnCaptionBlockInner .mcnCaptionTopContent:last-child .mcnTextContent{
    padding-top:18px !important;
    }

}	@media only screen and (max-width: 480px){
    .mcnImageCardBottomImageContent{
    padding-bottom:9px !important;
    }

}	@media only screen and (max-width: 480px){
    .mcnImageGroupBlockInner{
    padding-top:0 !important;
    padding-bottom:0 !important;
    }

}	@media only screen and (max-width: 480px){
    .mcnImageGroupBlockOuter{
    padding-top:9px !important;
    padding-bottom:9px !important;
    }

}	@media only screen and (max-width: 480px){
    .mcnTextContent,.mcnBoxedTextContentColumn{
    padding-right:18px !important;
    padding-left:18px !important;
    }

}	@media only screen and (max-width: 480px){
    .mcnImageCardLeftImageContent,.mcnImageCardRightImageContent{
    padding-right:18px !important;
    padding-bottom:0 !important;
    padding-left:18px !important;
    }

}	@media only screen and (max-width: 480px){
    .mcpreview-image-uploader{
    display:none !important;
    width:100% !important;
    }

}	@media only screen and (max-width: 480px){

    h1{
    font-size:22px !important;
    line-height:125% !important;
    }

}	@media only screen and (max-width: 480px){

    h2{
    font-size:20px !important;
    line-height:125% !important;
    }

}	@media only screen and (max-width: 480px){

    h3{
    font-size:18px !important;
    line-height:125% !important;
    }

}	@media only screen and (max-width: 480px){

    h4{
    font-size:16px !important;
    line-height:150% !important;
    }

}	@media only screen and (max-width: 480px){

    .mcnBoxedTextContentContainer .mcnTextContent,.mcnBoxedTextContentContainer .mcnTextContent p{
    font-size:18px !important;
    line-height:150% !important;
    }

}	@media only screen and (max-width: 480px){

    #templatePreheader{
    display:block !important;
    }

}	@media only screen and (max-width: 480px){

    #templatePreheader .mcnTextContent,#templatePreheader .mcnTextContent p{
    font-size:14px !important;
    line-height:150% !important;
    }

}	@media only screen and (max-width: 480px){

    #templateHeader .mcnTextContent,#templateHeader .mcnTextContent p{
    font-size:18px !important;
    line-height:150% !important;
    }

}	@media only screen and (max-width: 480px){

    #templateBody .mcnTextContent,#templateBody .mcnTextContent p{
    font-size:24px !important;
    line-height:150% !important;
    }

}	@media only screen and (max-width: 480px){

    #templateFooter .mcnTextContent,#templateFooter .mcnTextContent p{
    font-size:14px !important;
    line-height:150% !important;
    }
}
</style>
  