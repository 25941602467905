<template>
  <div id="TherapySelectModal">
    <div class="therapySelectModal">
      <div class="therapySelectBox">
        <i class="fa-xmark fa-solid boxClose" style="color: white" ></i>
        <img src="/img/therapy/tp-therapy-picture.png" class="therapyImg">
        <div class="innerBox d-flex flex-column justify-content-between">
          <div>
            <div class="d-flex justify-content-between">
              <div class="title">Adults Therapy</div>
              <div class="price brand-highlight-1">€85</div>
            </div>
            <p style="max-width: 100%">You have been recommended for Adults Therapy which will help you establish a healthy, autonomous and happy adulthood. To schedule a session with one of our trained professionals click the “Book Now” button below. </p>
          </div>
          <div class="boxFooter">
            <div style="height: 1px; width: 100%; background-color: #E9E9E9"></div>
            <div class="d-flex justify-content-between align-items-center mt-4">
              <div class="d-flex align-items-center">
                <i class="fa-regular fa-clock mr-3"></i>
                Duration: <span class="ml-2">30 minutes</span>
              </div>
              <button class="therapyBtn2" @click="$emit('book')" style="width: 30%">Book Now</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TherapySelectModal"
}
</script>

<style lang="less">
#TherapySelectModal {
  .therapySelectModal {
    position: fixed;
    z-index: 900;
    left: 0;
    top: 0;
    padding-top: 5%;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    background-color: rgba(0,0,0,0.8);
  }
  .therapySelectBox {
    width: 600px;
    max-width: 90%;
    aspect-ratio: 600 / 478;
    background-color: white;
    margin: auto;
    position: relative;
    display: grid;
    grid-template-rows: fit-content(100%) 1fr;
    .innerBox {
      padding: 6%;
      position: relative;
      .title {
        font-size: 2rem;
        font-weight: bold;
      }
      .price {
        font-size: 2rem;
        font-family: FilsonProBold, Arial, Helvetica, sans-serif;
      }
      p {
        font-family: "FilsonPro", sans-serif;
        text-align: justify;
      }
      .boxFooter {
        width: 100%;
        font-size: 1.4rem;
        span {
          font-weight: bold;
        }
      }
    }
    .boxClose {
      position: absolute;
      cursor: pointer;
      top: 8px;
      right: 8px;
      z-index: 901;
      font-size: 1.5rem;
    }
    .therapyImg {
      filter: brightness(50%);
    }
  }
}
</style>