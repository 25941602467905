<template>
    <div id="StudioEvent">
        <div class="course-content">
            <div class="course-nav" v-if="!isNarrowDevice">
                <SlidoPoll :code="event.slido_hash_code" v-if="event.slido_hash_code && event.embed_code"/>
                <NewStudioSideBar
                    :header="event.title"
                    :title="'You May Also Like'"
                    :nav-items="navItems"
                    :default-image="'/img/purple-video.svg'"
                    module-name="Event"
                    :stay-on-track="false"
                    @change-selection="changeSelectedModule"
                    :show-progress="false"
                    :show-availability="true"
                    :extend-side-bar-at-start="true"
                    v-else
                ></NewStudioSideBar>

            </div >
            <div class="course-main-content" ref="content">
                <div class="back-container" v-if="!isDemo">
                    <img src="/img/back-arrow.png" @click="back" alt="back arrow"/>
                </div>
                <div v-if="status === 'not_started' && !event.embed_code" class="not-started-container">
                    <div><i class="fa-solid fa-calendar-check fa-8x"></i></div>
                    <div class="not-started-title">This event has not started yet</div>
                    <div class="not-started-text">This event will begin at {{eventStartText}}</div>
                </div>
                <VisualAudioDisplay
                        v-else-if="event.embed_code"
                        :type="'embedded_code'"
                        :id="event.id"
                        :url="null"
                        :is-first-item="true"
                        :is-last-item="true"
                        :content="null"
                        :title="event.title"
                        :narrow-device-mode="isNarrowDevice"
                        :embedded-code="event.embed_code"
                        @previous="()=>{}"
                        @next="()=>{}"
                        @ended="recordGymEvent"
                ></VisualAudioDisplay>
                <div class="course-details">
                    <div class="course-details-nav pt-3">
                        <div class="course-details-nav-item pb-2"
                             :class="detailsNavSelection === 0 ? 'brand-highlight-1':'brand-text'"
                             @click="detailsNavSelection = 0"
                        >
                            <div class="nav-inner">
                                <i class="fa-regular fa-address-card"></i>
                                Overview
                            </div>
                            <div class="line" :class="detailsNavSelection === 0 ? 'bg-brand-highlight-1':'dark-line'"/>
                        </div>
                        <!--                        <div class="course-details-nav-item pb-2"-->
                        <!--                             :class="detailsNavSelection === 1 ? 'brand-highlight-1':'brand-text'"-->
                        <!--                             @click="detailsNavSelection = 1">-->
                        <!--                            <div class="nav-inner">-->
                        <!--                                <i class="fa-solid fa-pen"></i>-->
                        <!--                                My Notes-->
                        <!--                            </div>-->
                        <!--                            <div class="line" :class="detailsNavSelection === 1 ? 'bg-brand-highlight-1':'dark-line'"/>-->
                        <!--                        </div>-->
<!--                        <div  v-if="!isNarrowDevice"-->
<!--                              class="course-details-nav-item pb-2"-->
<!--                             :class="detailsNavSelection === 2 ? 'brand-highlight-1':'brand-text'"-->
<!--                             @click="detailsNavSelection = 2">-->
<!--                            <div class="nav-inner">-->
<!--                                <i class="fa-regular fa-video"></i>-->
<!--                                Similar Courses-->
<!--                            </div>-->
<!--                            <div class="line" :class="detailsNavSelection === 2 ? 'bg-brand-highlight-1':'dark-line'"/>-->
<!--                        </div>-->
                        <div class="course-details-nav-item pb-2"
                           :class="detailsNavSelection === 3 ? 'brand-highlight-1':'brand-text'"
                           @click="detailsNavSelection = 3" v-if="isNarrowDevice">
                        <div class="nav-inner">
                          <i class="fa-sharp fa-solid fa-check-to-slot" v-if="event.slido_hash_code && event.embed_code"></i>
                          <i class="fa-regular fa-video" v-else></i>
                          {{event.slido_hash_code && event.embed_code ? "Polls and Q&A" : "You may also like..."}}
                        </div>
                        <div class="line" :class="detailsNavSelection === 3 ? 'bg-brand-highlight-1':'dark-line'"/>
                      </div>
                    </div>
                </div>
                <div class="course-details h-100">
                    <div class="course-details-inner">
                        <div>
                            <div class="selection-container">
                                <div class="overview-section" v-if="detailsNavSelection === 0">
                                    <div class="author-details" v-if="author.name">
                                        <div class="top-row">
                                            <div class="featuring-box">
                                                <div class="top">FEATURING</div>
                                                <div class="sp-box mt-1">
                                                    <div class="img-outer-holder">
                                                        <div v-if="author.profile_img" class="img-holder" v-bind:style="{ backgroundImage: 'url(' + author.profile_img + ')' }"></div>
                                                    </div>
                                                    <div class="d-flex flex-column ml-4">
                                                        <div class="sp-name brand-text">{{ author.name }}</div>
                                                        <div class="sp-title brand-text" v-if="author.title">{{ author.title }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="main-details col-12 col-md-8">
                                        <div class="course-name-container">
                                            <div class="label">COURSE NAME</div>
                                            <div class="title brand-text">{{event.title}}</div>
                                        </div>
                                        <div class="course-desc-container mt-4">
                                            <div class="label">COURSE SUMMARY</div>
                                            <div class="brand-text">{{event.description}}</div>
                                        </div>
                                        <BupaDemoCard v-if="isDemo"/>
                                    </div>
                                        <div class="actions col-12 col-md-4">
                                        <div class="divider"></div>
                                        <div class="actions-inner">
                                            <div class="label">
                                                ACTIONS
                                            </div>
                                            <div v-if="status !== 'not_started'" class="actions-row">
                                                <div class="share-btn border-brand-highlight-1 brand-highlight-1" @click="showShareModal">
                                                    <i class="fa-solid fa-share brand-highlight-1 mr-4 fa-lg"></i>
                                                    <span>Share</span>
                                                </div>
                                                <div class="like-container">
                                                    <div class="like">
                                                        <i v-if="event.is_favourited" class="fa-solid fa-heart fa-xl brand-highlight-1" @click="like"></i>
                                                        <i v-else class="fa-regular fa-heart fa-xl brand-highlight-1" @click="like"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else class="actions-row not-started">
                                                <div class="row-of-btns">
                                                    <a class="share-btn cal-item border-brand-highlight-1 brand-highlight-1" :href="event.google_cal_link" target="_blank">
                                                      <i class="fa-brands fa-google ml-2"></i><span>Add to Calendar</span>
                                                    </a>
                                                    <a class="share-btn cal-item border-brand-highlight-1 brand-highlight-1" :href="event.ical_link_without_reminder" target="_blank">
                                                      <i class="fa-solid fa-plus ml-2"></i><span>Add to Outlook</span>
                                                    </a>
                                                    <div class="share-btn border-brand-highlight-1 brand-highlight-1" @click="remindMe" style="position: relative">
                                                        <span v-if="!changingReminderStatus">{{event.reminder_registered ? 'Cancel Reminder' : 'Remind Me'}}</span>
                                                        <span v-else class="button--loading" style="color: black"></span>
                                                    </div>
                                                </div>

                                                <div class="like-container">
                                                    <div class="like">
                                                        <i v-if="event.is_favourited" class="fa-solid fa-heart fa-xl brand-highlight-1" @click="like"></i>
                                                        <i v-else class="fa-regular fa-heart fa-xl brand-highlight-1" @click="like"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="myListRow">
                                              <div class="myListBtn" @click="watchLater">
                                                <i class="fa-solid iconBtn" :class="isItemInMyList ? 'fa-check plus':'fa-circle-plus plus'"></i>
                                                <div class="myListText">{{isItemInMyList ? 'My List' : 'Watch Later'}}</div>
                                              </div>
                                            </div>
                                            <div class="ptHolder" v-if="event.schedule_type === 'gym' && this.showPTAdvert">
                                              <HomeClinicCard :item="personalTrainer" :advert-mode="true"/>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
<!--                                <div class="similar-courses section" v-if="detailsNavSelection === 2">-->
<!--                                    <div class="headerContainer">-->
<!--                                        <div class="title brand-text">Similar Courses</div><div class="divline"></div>-->
<!--                                    </div>-->

<!--                                    <div class="slider-container">-->
<!--                                        <SimilarCourses type="exercise" class="car" title=""/>-->
<!--                                    </div>-->

<!--                                </div>-->
                                <div class="similar-courses section" v-if="detailsNavSelection === 3 && isNarrowDevice">
                                <div class="headerContainer">
<!--                                  <div class="title brand-text">Similar Courses</div><div class="divline"></div>-->
                                </div>

                                <div class="slider-container">
                                  <SlidoPoll :code="event.slido_hash_code" v-if="event.slido_hash_code && event.embed_code"/>
                                  <NewStudioSideBar
                                      :narrow-device-mode="true"
                                      :header="event.title"
                                      :title="'You May Also Like'"
                                      :nav-items="navItems"
                                      :default-image="'/img/purple-video.svg'"
                                      module-name="Event"
                                      :stay-on-track="false"
                                      @change-selection="changeSelectedModule"
                                      :show-progress="false"
                                      :show-availability="true"
                                      :extend-side-bar-at-start="true"
                                      v-else
                                  ></NewStudioSideBar>
                                </div>

                              </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>


<script>
import axios from "axios";
import config from "@/config/constants";
import VisualAudioDisplay from "@/components/NewStudioCourseLayout/Content/VisualAudioDisplay.vue";
import ShareModal from "@/components/NewStudioCourseLayout/Modals/ShareModal.vue";
import SimilarCourses from "@/components/NewStudioCourseLayout/Content/SimilarCourses.vue";
import HomeCardCarousel from "@/components/Home/New/HomeCardCarousel.vue";
import SlidoPoll from "@/components/NewStudioCourseLayout/Content/SlidoPoll.vue";
import NewStudioSideBar from "@/components/NewStudioCourseLayout/Sidebar/NewStudioSideBar.vue";
import moment from "moment/moment";
import EventBus from "@/helpers/eventbus";
import HomeClinicCard from "@/components/Home/New/HomeClinicCard.vue";
import BupaDemoCard from "@/components/NewStudioCourseLayout/Content/BupaDemoCard.vue";

export default {
    name: 'StudioEvent',
    components: {
        HomeClinicCard,
        BupaDemoCard,
        NewStudioSideBar,
        HomeCardCarousel,
        VisualAudioDisplay,
        ShareModal,
        SimilarCourses,
        SlidoPoll
    },
    data() {
        return {
            author: {},
            event: {
                description: "",
                embed_code: "",
                end_date_time: "2023-04-05 13:55:00",
                id: 3464,
                instructor: "",
                is_favourited: false,
                reminder_registered: false,
                schedule_type: "studio",
                start_date_time: "",
                title: "",
                slido_hash_code: ""
            },
            similarCourses: [],
            showSlidoBar: false,
            modules: [],
            progress: "",
            loading: true,
            sidebarExtended: false,
            selectedModule: '',
            detailsNavSelection: 0,
            liked: false,
            type: 'studio',
            changingReminderStatus: false,
            windowWidth: 1000,
            showPTAdvert: false,
            personalTrainer: {},
        }
    },
    mounted() {
        this.getEvent();
        this.onResize();
        this.$nextTick(() => {
          window.addEventListener('resize', this.onResize);
        });
    },
    watch: {
      selectedModule() {
          this.$refs["content"].scrollIntoView({ behavior: "smooth" })
      },
      '$route.params.id': {
        handler: function(id) {
          this.changeSelectedModule(id);
        },
        deep: true,
        immediate: true
      }
    },
    computed: {
        product_id () {
          return this.event.schedule_type === 'studio' ? 21 : 22;
        },
        isItemInMyList() {
          const isInList = this.$store.getters.myList.filter(item => item.product_id === this.product_id && item.content_id === this.event.id);
          return isInList.length > 0;
        },
        isDemo() {
          return process.env.NODE_ENV !== "production" && this.$route.fullPath.includes("digital-gym/3605")
        },
        darkMode() {
          return this.$store.getters.darkMode;
        },
        isNarrowDevice() {
            return this.windowWidth <= 992;
        },
        eventStartText() {
            return moment(this.event.start_date_time).format("h:mm a on dddd, Do MMMM")
        },
        status() {
            const start = moment(this.event.start_date_time);
            const end = moment(this.event.end_date_time);
            const now = moment();
            if(now.isBefore(start) || !this.event.start_date_time || !this.event.end_date_time) return 'not_started';
            if(now.isAfter(end)) return 'ended';
            return 'live';
        },
        backRoute() {
            if(this.$route.query.back === 'home') return '/';
            if(this.$route.query.back) return '/' + this.$route.query.back;
            if(this.event.schedule_type === 'gym') return '/digital-gym/'
            return '/wellbeing-studio';
        },
        isFirstItem() {
            const i = this.modules.findIndex(mod => mod.id === this.selectedModule);
            return i === 0
        },
        isLastItem() {
            const i = this.modules.findIndex(mod => mod.id === this.selectedModule);
            return i === this.modules.length - 1
        },
        link() {
            return window.location.href;
        },
        currentModule() {
            const m = this.modules.filter(mod => mod.id === this.selectedModule);
            return m[0];
        },
        navItems() {
            //const items = this.similarCourses.filter(i => Number.parseInt(i.id) !== Number.parseInt(this.$route.params.id))
            return this.similarCourses.map(item => {
                return {
                    id: item.id,
                    title: item.title,
                    description: item.description,
                    complete: 0,
                    img: item.thumbnail,
                    selected: Boolean(item.id === this.event.id),
                    start: item.start,
                    end: item.end,
                }
            })
        }
    },
    methods: {
        async recordGymEvent() {
          if(this.event.schedule_type === 'gym') {
            await this.api({
              path: "api/u/studio/log-event-as-activity",
              event_id: this.event.id
            });
          }
        },
        async watchLater() {
          if(!this.isItemInMyList) {
            await this.$store.dispatch('addMyListItem',({media_type: 'video', product_id: this.product_id, content_id: this.event.id}));
          } else {
            const myListRecord = this.$store.getters.myList.filter(item => item.product_id === this.product_id && item.content_id === this.event.id);
            await this.$store.dispatch('deleteMyListItem',({id: myListRecord[0].id}));
          }
        },
        onResize() {
          this.windowWidth = window.innerWidth;
          this.detailsNavSelection = 0;
        },
        back() {
            this.$router.push(this.backRoute);
        },
        showShareModal() {
            const skin = this.$store.getters.skin;
            const content = this.$createElement(ShareModal, {
                props: {
                    title: 'Share this course',
                    instruction: 'Share this course with other by copying the link below',
                    link: this.link
                }
            });
            let modalClass = "share-modal theme-"+skin;
            if(this.darkMode) modalClass += ' dark-mode-modal';
            let opts = {
                hideHeader: false,
                hideFooter: true,
                modalClass: modalClass,
                headerClass: "share-modal d-none",
                footerClass: "d-none",
                size: "lg",
                buttonSize: "sm",
                centered: true,
                contentClass: "share-modal theme-" + skin,
                id : 'share-modal',
            }
            return this.$bvModal.msgBoxOk(content, opts);
        },
        async like() {
            const payload = {
                user_id: this.$store.getters.user_id,
                event_id: this.$route.params.id,
            };
            let res = await this.axios.post(
                process.env.VUE_APP_API_PATH +
                "/application/api/u/studio/toggle-favourite-gym-event",
                payload, config.options
            );
            if (res.data.success) {
                this.event.is_favourited = res.data.is_favourite;
            } else if (res.data.err && res.data.err.includes("3994")) {
                this.$store.dispatch("logout");
                this.$router.push("/login");
            }
        },
        changeSelectedModule(id) {
            if(this.loading) return;
            let url = '';
            if(this.event.schedule_type === 'gym') {
                url = '/digital-gym/'+ id;
            } else {
                url = '/wellbeing-studio/'+ id;
            }
            if(this.$route.query.back === 'home') url += '?back=home';
            this.$router.push(url);
            this.getEvent();
        },
        async getEvent(resubmit = false ,e_id = 0) {
            if(resubmit && e_id !== Number.parseInt(this.$route.params.id)) return
            this.event = {};
            this.author = {};
            const payload = {
                user_id: this.$store.getters.user_id,
                event_id: this.$route.params.id,
                access_code_user: this.$store.getters.user.access_code_user
            };
            let res = await this.axios.post(
                process.env.VUE_APP_API_PATH +
                "/application/api/u/studio/get-virtual-event",
                payload, config.options
            );
            if (res.data.success) {
                this.loading = false;
                this.event = res.data.event;
                if(!this.similarCourses.length) {
                    await this.getSimilarCourses();
                }
                if(!this.event.embed_code) this.setRefreshTimer();
              let name = null;
              if(res.data.event.author){
                if(res.data.event.author.firstname) name = res.data.event.author.firstname + " " + res.data.event.author.lastname;
                this.author = {
                  name: name,
                  profile_img: res.data.event.author.image,
                  title: res.data.event.author.title
                }
              }

              if(this.event.schedule_type === 'gym') await this.getPersonalTrainer();

            } else if (res.data.err && res.data.err.includes("3994")) {
            this.$store.dispatch("logout");
            this.$router.push("/login");
          } else {
            this.loading = false;
            }
        },
        async getPersonalTrainer() {
          let contentPath ="/application/api/u/home/get-dc-banner";
          const payload = {
            user_id: this.$store.getters.user_id
          };
          let res = await this.axios.post(
              process.env.VUE_APP_API_PATH +
              contentPath,
              payload, config.options
          );
          if (res.data.success) {
              let showPTAdvert = false;
              res.data.categories.forEach(item => {
                if(item.title === 'Personal Trainer') {
                  showPTAdvert = true;
                  this.personalTrainer = item;
                }
              });
              this.showPTAdvert = showPTAdvert;
          }
        },
        async getSimilarCourses() {
            let contentPath = '';
            switch(this.event.schedule_type) {
                case 'gym':
                    contentPath = "/application/api/u/studio/events";
                    break;
                case 'studio':
                    contentPath = "/application/api/u/studio/events";
                    break;
                default:
                    contentPath = "/application/api/u/studio/events";
            }
            const payload = {
                user_id: this.$store.getters.user_id,
                tags: [this.event.schedule_type],
                page: 1,
                pageSize: 10,
            };
            let res = await this.axios.post(
                process.env.VUE_APP_API_PATH +
                contentPath,
                payload, config.options
            );
            if (res.data.success) {
                this.similarCourses = res.data.data;
            } else if (res.data.err && res.data.err.includes("3994")) {
                this.$store.dispatch("logout");
                this.$router.push("/login");
            }

        },
        async remindMe() {
            this.changingReminderStatus = true;
            const payload = {
                user_id: this.$store.getters.user_id,
                event_id: this.$route.params.id,
            };
            if (!this.event.reminder_registered) {
                let res = await this.axios.post(
                    process.env.VUE_APP_API_PATH +
                    "/application/api/u/studio/register-reminder",
                    payload, config.options
                );
                if (res.data.success) {
                    this.event.reminder_registered = true;
                    EventBus.$emit('updateDFDReminders');
                }
                this.changingReminderStatus = false;
                if(!this.isItemInMyList) this.watchLater();
            } else {
                let res = await this.axios.post(
                    process.env.VUE_APP_API_PATH +
                    "/application/api/u/studio/cancel-reminder",
                    payload, config.options
                );
                if (res.data.success) {
                    this.event.reminder_registered = false;
                    EventBus.$emit('updateDFDReminders');
                }
                this.changingReminderStatus = false;
            }
        },
        setRefreshTimer() {
            let self = this;
            if (this.event.embed_code === false) {
                const eventStartTime = moment(this.event.start_date_time)
                    .subtract(1, "minutes")
                    .format("X");
                const now = moment().format("X");
                const diff = eventStartTime - now;
                if (now < eventStartTime && diff * 1000 < 2147483647) {
                    const e_id = JSON.parse(JSON.stringify(self.$route.params.id));
                    setTimeout(function () {
                        self.getEvent(true, Number.parseInt(e_id));
                    },diff * 1000)
                }
            } else {
                this.shouldRenderIFrame = true;
            }
        },
    }
}
</script>

<style lang="less">
#StudioEvent {
    .author-details {
        .top-row {
            display: flex;
            padding: 2px;
            color: #C9C6C6;
        }
        .featuring-box {
            padding-top: 5px;
            flex-direction: column;
            padding-bottom: 5px;
            display: flex;
            .top {
                font-size: 1.2rem;
            }
            .sp-box {
                display: flex;
                .sp-name{
                    font-size: 2rem;
                    font-family: FilsonProBold, Arial, Helvetica, sans-serif;
                }
                .sp-title {
                    font-size: 1.6rem;
                }
                .img-outer-holder {
                    width: 68px;
                    height: 68px;
                    border-radius: 50%;
                    border: cyan 2px solid;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .img-holder {
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;

                }
            }
        }
    }
    .ptHolder {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 300px;
      padding: 5px;
      margin-top: 20px;
    }
    .course-content {
        width: 100%;
        height: 100%;
        font-family: "FilsonPro", sans-serif;
        display: flex;
        background-color: black;

        .course-nav {
            width: 350px;
        }
        .course-main-content {
            position: relative;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            .back-container {
                position: absolute;
                top: 10px;
                left: 10px;
                cursor: pointer;
                z-index: 9999;
            }
        }
        .myListRow {
          padding: 15px 0px;
          display: flex;
          cursor: pointer;
          align-items: center;
          justify-content: start;
          .myListBtn {
            width: 150px;
            background-color: #333232;
            color: white;
            padding: 7px 5px;
            align-items: center;
            border-radius: 10px;
            border: #a6a5a5 1px solid;
            display: flex;
            i {
              color: white;
              margin-right: 15px;
              font-size: 25px;
            }
            .myListText {
              font-family: FilsonProBold, Arial, Helvetica, sans-serif;
            }
          }
        }
        .not-started-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            padding-top: 50px;
            padding-bottom: 25px;
            min-height: 400px;
            i {
                color: #0BDA51;
            }
            .not-started-title {
                font-size: 2.5rem;
                color: white;
                font-family: FilsonProBold, Arial, Helvetica, sans-serif;
            }
            .not-started-text {
                font-size: 1.8rem;
                color: white;
                font-family: DMSans, Arial, Helvetica, sans-serif;
            }
        }
        .course-details {
            width: 100%;

            font-family: DMSans, Arial, Helvetica, sans-serif;
            font-size: 1.5rem;
            background-color: white;
            .course-details-inner {
                width: 100%;
                max-width: 1300px;
            }
            .course-details-nav {
                display: flex;
                cursor: pointer;
                background-color: #FAFAFA;
                .course-details-nav-item {
                    width: 25%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-width: 120px;
                    max-width: 200px;
                    position: relative;
                    .nav-inner {
                        padding: 10px 15px;
                        i {
                            margin-right: 8px;
                        }
                    }
                    .line {
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        height: 1px;
                        margin: 0 !important;
                    }
                    .dark-line {
                        background-color: #c4c4c4;
                    }
                }
            }
            .selection-container {
                width: 100%;
                padding: 20px 30px 60px 30px;
                .divider {
                    position: absolute;
                    left: 0;
                    top: 20px;
                    width: 1px;
                    height: 100%;
                    background-color: #f6f5f5;
                }
                .label {
                    color: #C9C6C6;
                    font-size: 1.2rem;
                    margin-bottom: 10px;
                }
                .overview-section {
                    flex-direction: column;
                    display: flex;
                    .main-details, .actions-inner {
                        padding: 10px;
                    }
                    .actions {
                        position: relative;
                        .actions-row {
                            display: flex;
                            a, .share-btn {
                              max-width: 250px;
                            }
                            &.not-started {
                                display: grid;
                                grid-template-columns: 3fr 1fr;
                            }
                            .share-btn {
                                border-width: 1px;
                                border-style: solid;
                                padding: 5px 8px;
                                border-radius: 20px;
                                cursor: pointer;
                                min-width: 118px;
                                min-height: 30px;

                            }
                            .like-container {
                              margin-left: 10px;
                                width: 50%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                            .like {
                                cursor: pointer;
                            }
                            .row-of-btns {
                                .share-btn {
                                    font-size: 1.3rem;
                                    width: 100%;
                                    margin-bottom: 5px;
                                    font-family: FilsonProBold, Arial, Helvetica, sans-serif;
                                    display: flex;
                                    justify-content: space-around;
                                    align-items: center;

                                }
                                .cal-item {
                                  i {
                                    margin-right: 10px;
                                  }
                                  justify-content: center !important;
                                }
                            }
                        }
                    }
                    .title {
                        font-family: FilsonProBold, Arial, Helvetica, sans-serif;
                        font-size: 2rem;
                    }
                }
            }
        }
    }
    .similar-courses {

        overflow: hidden;
        .headerContainer {
            align-items: center;
            justify-content: start;
            display: flex;
            .title {
                font-family: FilsonProBold, Arial, Helvetica, sans-serif;
                font-size: 2rem;
                margin-right: 15px;
            }
            .divline {
                width: 200px;
                height: 1px;
                background-color: #e1e1e1;
            }
        }
    }
    .button--loading::after {
        content: "";
        position: absolute;
        width: 20px;
        height: 20px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        border: 4px solid transparent;
        border-radius: 50%;
        border-top-color: #0D1846;
        border-bottom-color: #0D1846;
        animation: button-loading-spinner 1s ease infinite;
    }

  @media only screen and (max-width: 767px) {
    .course-details-nav-item {
      width: 100% !important;
      justify-content: flex-start !important;
      .nav-inner {
        margin-left: 10px;
      }
    }
    .row {
      margin: 0 !important;
    }
    .actions-inner {
      padding: 0 !important;
    }
    .nav-inner {
      padding: 5px !important;
      text-align: center;
    }
    .not-started-title {
      font-size: 2rem !important;
      margin-top: 10px !important;
      margin-bottom: 10px;
    }
    .not-started-text {
      font-size: 1.4rem !important;
    }
    .selection-container {
      padding: 0 !important;
    }
    .not-started-container {
      i {
        font-size: 40px !important;
      }
      text-align: center !important;
      min-height: fit-content !important;
      padding-top: 20px !important;
      padding-bottom: 15px !important;
      width: 80% !important;
      margin: auto !important;
    }
  }
}
</style>