<template>
    <div id="AllWorkoutPlans" class="bg-grey">
        <div class="content-container">
            <b-carousel v-if="myFitnessPlans.length > 0" class="fitness-carousel border-brand-secondary" :interval="0" controls>
                <b-carousel-slide v-for="(plan, i) in myFitnessPlans" :key="i">
                    <div class="slide-content row align-items-center mx-auto">
                        <div class="col-12 col-sm-4 slide-image align-items-right">
                            <img :src="plan.title_img" />
                        </div>
                        <div class="col-12 col-sm-8 text-center text-sm-left">
                            <h3 class="py-2 brand-secondary">{{plan.title}}</h3>
                            <h4  class="brand-secondary">{{plan.description}}</h4>
                            <a @click="selectPlan(plan.plan_id)" class="pb-2 border-brand-highlight-1 brand-secondary">{{componentText.explore}}</a>
                        </div>
                    </div>
                </b-carousel-slide>
            </b-carousel>
            <div class="row all-plans py-5">
                <div v-for="(plan, i) in allFitnessPlans" :key="i" @click="selectPlan(plan.plan_id)" class="col-md-4 col-lg-3 col-6 py-4 fitness-plan-card">
                    <div class="image-wrap">
                        <div v-if="plan.rec == true" class="recommended-badge"></div>
                        <img class="title-image" :src="plan.title_img" />
                        <img class="level-image" :src="'/img/fitness/level-' + plan.fitness_level + '.svg'"/>
                    </div>
                    <div class="card-content brand-secondary">
                        {{plan.title}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import axios from "axios";
    import config from "../../config/constants";
    import VideoPlayer from "../VideoPlayer.vue"
    import AudioPlayer from "../AudioPlayer.vue"
    import moment from "moment"
    export default {
        name: "AllWorkoutPlans",
        components: {

        },
        data() {
            return {
                myFitnessPlans: {},
                allFitnessPlans: {},
            };
        },
        mounted() {
            this.getMyFitnessPlans()
            this.getFitnessPlans()
        },
        methods: {
            async getMyFitnessPlans(){
                const payload = {
                    user_id: this.$store.getters.user_id,
                    channel: "surge"
                }
                let res = await axios.post(config.api_env + '/application/api-hc/get-my-fitness-plans', payload, config.options)
                if(res.data.success) {
                    this.myFitnessPlans = res.data.plans
                }
            },
            async getFitnessPlans(){
                const payload = {
                    user_id: this.$store.getters.user_id,
                    channel: "surge"
                }
                let res = await axios.post(config.api_env + '/application/api/u/fitness/get-plans', payload, config.options)
                if(res.data.success) {
                    this.allFitnessPlans = res.data.data
                }
            },
            selectPlan(id){
                this.$emit('select-plan', id)
            }
        },
        watch:{

        },
        computed:{
            componentText() {
                return this.getText.loggedIn.fitness.allWorkoutPlans;
            },
        }
    };
</script>
<style lang="less">
    #AllWorkoutPlans{
        .fitness-carousel{
            border-bottom-width:1px;
            border-bottom-style: solid;
        }
        .carousel-control-prev, .carousel-control-next{
            span {
                color: #0000FF;
            }
        }
            .carousel-item {
                min-height: 330px;
                .carousel-caption {
                    width: 100%;
                    position: relative;
                    padding-top: 60px;
                    left: 0;
                    right: 0;
                }
                .carousel-title {
                    font-family: DMSans, sans-serif;
                    font-size: 4.3rem;
                    font-weight: 800;
                }
                .carousel-text {
                    font-family: FilsonProLight, sans-serif;
                    font-size: 1.8rem;
                    letter-spacing: 2px;
                }
                .slide-content{
                    max-width: 900px;
                    text-align: left;
                    h3{
                        font-size: 3.6rem;
                        font-family: 'FilsonProBold', sans-serif;
                        color: #fff;
                    }
                    h4{
                        font-size: 2.4rem;
                        font-family: 'FilsonProBold', sans-serif;
                    }
                    a{
                        font-size: 1.6rem;
                        color: #fff;
                    }
                }
                .slide-image {
                    text-align: center;
                    img {
                        height: 195px;
                        width: 195px;
                    }
                }
            }
        .all-plans{
            text-align: center;
            .image-wrap {
                position: relative;
                cursor: pointer;
                .recommended-badge {
                    background-image: url(/img/recommended.svg);
                    background-position: top;
                    background-size: contain;
                    background-repeat: no-repeat;
                    position: absolute;
                    top: 10px;
                    left: 60px;
                    width: 20px;
                    height: 50px;
                }
                .title-image {
                    height: 175px;
                    width: 175px;
                    border-radius: 50%;
                }
                .level-image {
                    width: 37px;
                    position: absolute;
                    right: 45px;
                    bottom: 2px;
                }
            }
            .card-content{
                font-size: 20px;
                text-align: center;
                color: #fff;
                cursor: pointer;
            }
        }
        @media only screen and (max-width: 576px){
            .all-plans {
                .image-wrap {
                    .title-image {
                        height: 135px;
                        width: 135px;
                        border-radius: 50%;
                    }
                }
            }
        }
    }
</style>
