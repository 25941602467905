<template>
  <b-modal
    id="questionnaire-modal"
    v-bind:modal-class="'questionnaire-modal ' + getTheme() + ' ' + $store.getters.navType"
    size="lg"
    hide-footer
    hide-header
  >
    <div id="QuestionnaireModal" v-if="!isLoading && welcomeMessageRead">
      <section class="pulse-container" v-if="step !== 'PROMPT'">
        <div class="pulse-form-container content-container">
          <div class="pulse-form" id="pulse-form">
            <div class="pulse-form-header-container">
              <div v-bind:class="getHeaderClasses()">
                We just have a few questions to ask you
                <br />
                (Question {{ currentQuestionNumber }}/{{ numOfQuestions }})
                <div class="pulse-form-header-steps">
                  <div
                    class="header-step"
                    v-for="i in numOfQuestions"
                    :key="i"
                    :class="
                      i <= currentQuestionNumber ? 'bg-brand-primary' : ''
                    "
                  ></div>
                </div>
              </div>
            </div>
            <div v-if="loadingQuestion">
              <div class="d-flex flex-row align-items-center question-spinner">
                <div class="mx-auto my-auto">
                  <b-spinner class="brand-primary"></b-spinner>
                </div>
              </div>
            </div>
            <div class="pulse-form-question-container" v-else>
              <div class="pulse-form-question">
                <div
                  class="
                    pulse-form-question-text
                    d-flex
                    flex-row
                    justify-content-center
                  "
                >
                  <span class="question-text brand-secondary text-center">{{
                    currentQuestion.text
                  }}</span>
                </div>
                <div class="pulse-form-question-answer">
                  <div
                    class="d-flex justify-content between"
                    v-if="currentQuestion.type === 'numeric'"
                  >
                    <input
                      type="number"
                      id="pulse-number-input"
                      class="pulse-number-input mx-auto"
                      placeholder="Enter Here..."
                      :min="currentQuestion.min_value"
                      :max="currentQuestion.max_value"
                      @change="setNumericAnswer()"
                    />
                  </div>
                  <div
                    class="d-flex justify-content between"
                    v-if="currentQuestion.type === 'text'"
                  >
                    <input
                      type="text"
                      id="pulse-text-input"
                      class="pulse-text-input mx-auto"
                      placeholder="Enter Here..."
                      @change="setTextAnswer()"
                    />
                  </div>
                  <div v-if="currentQuestion.type === 'single_choice'">
                    <div v-if="currentQuestion.layout === 'scale'">
                      <input
                        type="range"
                        id="pulse-slider"
                        class="pulse-answer-slider w-100"
                        min="0"
                        :max="currentQuestionOptions.length - 1"
                        :value="currentSliderValue"
                        @change="changeSliderAnswer()"
                        style="padding: 0px 0px 0px 0px; height: 30px"
                      />
                      <div
                        class="
                          pulse-steps-row
                          flex-row
                          d-flex
                          justify-content-between
                        "
                        v-if="currentQuestionOptions.length > 0"
                      >
                        <div
                       :class="option.text === currentAnswer[0].answer ? 'step-container text-center current-answer':'step-container text-center'"
                          v-for="(option, i) in currentQuestionOptions"
                          :key="i"
                          @click="changeSliderAnswer()"
                        >
                          {{ option.text }}
                        </div>
                      </div>
                    </div>
                    <div v-if="currentQuestion.layout === 'dropdown'">
                      <v-select
                        v-model="dropdownAnswer"
                        :options="currentQuestionOptions"
                        :closeOnSelect="true"
                        :multiple="false"
                        label="text"
                      >
                      </v-select>
                    </div>
                    <div
                      class="
                        options-button-container
                        row
                        d-flex
                        flex-row
                        justify-content-between
                      "
                      v-if="currentQuestion.layout === 'other'"
                    >
                      <div
                        class="
                          d-flex
                          flex-grow flex-row flex-wrap
                          options-button
                        "
                        v-for="(option, i) in currentQuestionOptions"
                        :key="i"
                      >
                        <button
                          @click="toggleOption(option.id, true)"
                          :class="[
                            option.selected
                              ? 'selected brand-highlight-1 border-brand-highlight-1'
                              : 'not-selected',
                            'mx-5 mb-3 flex-grow shadow btn btn-outline answer-button',
                          ]"
                        >
                          {{ option.text }}
                          <span v-if="option.selected"
                            ><i class="fas fa-check float-right mr-3"></i
                          ></span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div v-if="currentQuestion.type === 'multi_choice'">
                    <div v-if="currentQuestion.layout === 'dropdown'">
                      <v-select
                        v-model="dropdownAnswer"
                        :options="currentQuestionOptions"
                        :closeOnSelect="false"
                        :multiple="true"
                        label="text"
                      >
                      </v-select>
                    </div>
                    <div
                      class="
                        options-button-container
                        row
                        d-flex
                        flex-row
                        justify-content-between
                      "
                      v-if="currentQuestion.layout === 'other'"
                    >
                      <div
                        class="
                          d-flex
                          flex-grow flex-row flex-wrap
                          options-button
                        "
                        v-for="(option, i) in currentQuestionOptions"
                        :key="i"
                      >
                        <button
                          @click="toggleOption(option.id)"
                          :class="[
                            option.selected
                              ? 'selected brand-highlight-1 border-brand-highlight-1'
                              : 'not-selected',
                            'mx-5 mb-3 flex-grow shadow btn btn-outline answer-button',
                          ]"
                        >
                          {{ option.text }}
                          <span v-if="option.selected"
                            ><i class="fas fa-check float-right mr-3"></i
                          ></span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- <div>The value you've picked is: {{ currentAnswer }}</div> -->
                  <div class="row d-flex justify-content-around">
                    <div
                      v-for="(error, i) in errors"
                      :key="i"
                      class="pulse-error"
                    >
                      {{ error }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="pulse-form-navigation-buttons d-flex flex-row flex-wrap"
                :class="[
                  onFirstQuestion
                    ? 'justify-content-end'
                    : 'justify-content-between',
                ]"
              >
                <b-button
                  class="form-btn outline-button shadow py-3 px-5 mb-3"
                  @click="previousQuestion()"
                  v-if="!onFirstQuestion"
                >
                  <i class="fas fa-angle-left mr-3"></i><span>Previous</span>
                </b-button>
                <b-button
                  class="form-btn cta-button shadow py-3 px-5 mb-3"
                  @click="answerQuestion(currentQuestion.id)"
                >
                  <span v-if="currentQuestionNumber === numOfQuestions"
                    >Complete Question Set</span
                  >
                  <span v-else>Next</span>
                  <i class="fas fa-angle-right ml-3"></i>
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pulse-container" v-else>
        <div class="pulse-form-container content-container">
          <div class="pulse-form" id="pulse-form">
            <div class="pulse-form-header-container">
              <div class="pulse-form-header bg-brand-secondary py-5">
              </div>
            </div>
<!--            <div v-if="promptAction==='SelectCoach' && ! coachingDataComplete">-->
<!--              <CoachingProfileDetails-->
<!--                  :user="user"-->
<!--                  @updateSuccess="updateSuccess"-->
<!--              />-->
<!--            </div>-->
            <div
              class="
                pulse-form-question-container
                d-flex
                flex-column
                align-items-center
              "
            >
              <p class="prompt-text" v-html="promptText"></p>
              <p v-if="promptAction === 'EAP' && !callBackSuccess" class="prompt-text">Enter Phone Number</p>
              <input type="number" class="form-control" v-if="promptAction === 'EAP' && !callBackSuccess" v-model="phoneNumber" @keydown="testPhoneInput"/>
              <p v-if="callBackSuccess" class="prompt-text">Thank you, please expect a call back from us shortly.</p>
              <b-button
                  v-if="!callBackSuccess"
                class="cta-button my-5 px-5 shadow"
                @click="acceptPrompt()"
                  :disabled="promptAction==='EAP'&&!eapNumberValidated"
                >{{ promptButtonText }}</b-button
              >
              <div class="prompt-text-eap pt-3" v-if="promptAction === 'EAP'">
                <div class="text-center">
                  IE: <a href="tel:+1800903542" class="telephone-link brand-text"><b>1800 903 542</b></a><br/>
                  UK: <a href="tel:+08081962016" class="telephone-link brand-text"><b>0808 196 2016</b></a><br/>
                  International: <a href="tel:+0035315180277" class="telephone-link brand-text"><b>00353 1 518 0277</b></a><br/>
                </div>
                <hr class="horizontal-divider my-5"></hr>
                <div class="text-center pb-4">or text/whatapp us:</div>
                <div class="row no-gutters pb-5 justify-content-center">
                  <div class="col-6 text-center pb-3">IE SMS:<br> <a href="tel:+353871452056" class="telephone-link brand-text"><b>+353 87 145 2056</b></a></div>
                  <div class="col-6 text-center pb-3">IE Whatsapp:<br> <a href="tel:+353873690010" class="telephone-link brand-text"><b>+353 87 369 0010</b></a></div>
                  <div class="col-6 text-center pb-3">UK SMS &amp; Whatsapp:<br>  <a href="tel:+447418360780" class="telephone-link brand-text"><b>+44 74 1836 0780</b></a></div>
                </div>
                <div class="d-flex flex-row justify-content-center">
                  <b-button class="cta-button px-5 py-4 mx-auto" @click="$bvModal.hide('questionnaire-modal')">Done</b-button>
                </div>
              </div>


              <!-- <b-button class="outline-button my-3 prompt-button shadow" @click="$router.push('/total-mental-health/select-coach')">I would rath talk to someone right now</b-button> -->
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="d-flex flex-row align-items-center my-5" v-else-if="isLoading">
      <div class="mx-auto my-auto">
        <b-spinner class="brand-primary"></b-spinner>
      </div>
    </div>
    <div id="WelcomeMessage" class="" v-else>
      <section class="pulse-container">
        <div class="pulse-form-container content-container">
          <div class="pulse-form" id="pulse-form-1">
            <div class="pulse-form-header-container">
              <div class="pulse-form-header navy-header welcomeTop">
                <h2>WHO 5 Questionnaire</h2>
              </div>
            </div>
          </div>
        </div>
        <div class="welcomeMessageContainer">
          <h2 class="welcomeHeader" v-html="welcomeHeader"></h2>
          <p class="welcomeMessage" v-html="welcomeMessage"></p>
          <input type="number" class="form-control" v-if="promptAction === 'EAP' && !callBackSuccess" v-model="phoneNumber" />
          <b-button

              class="cta-button my-5 px-5 shadow"
              @click="readWelcomeMessage()"
          >Begin</b-button
          >
        </div>
      </section>
    </div>
  </b-modal>
</template>
<script>
import axios from "axios";
import config from "../../config/constants";
import CoachingProfileDetails from "@/components/TotalMentalHealth/CoachingProfileDetails";

export default {
  name: "QuestionnaireModal",
  components: { CoachingProfileDetails },
  props: ["isVisible", "user", "coachingDataComplete"],
  data() {
    return {
      answers: [],

      isLoading: true,
      welcomeMessageRead: false,
      loadingQuestion: false,
      promptText: "",
      promptButtonText: "",
      promptAction: "",
      numOfQuestions: 1,
      phoneNumber: "",
      currentQuestionNumber: 1,
      pulseSections: [],
      currentQuestion: null,
      currentQuestionOptions: [],
      currentAnswer: [{}],
      dropdownAnswer: [],
      currentSliderValue: 0,
      previousQuestionId: null,
      callBackSuccess: false,
      allQuestions: [],
      errors: [],
      introText: "",
      step: "Question",
      currentSurvey: "WHO-5",
      welcomeMessage: "Before proceeding, we need to ask you a few questions to better understand your current needs. This will help us in exploring whether connecting you to our Mental Health Coaches or to our broader 24/7 Mental Health Support Team would be the most effective way ahead in your journey to wellbeing.",
      welcomeHeader: "Well done on taking your first steps! "
    };
  },
  mounted() {
    let promptKey = "PROMPT-" + this.$store.getters.user.user.user_id;
    const promptValue = localStorage.getItem(promptKey);
    if (promptValue) {
      this.welcomeMessageRead = true;
      if (promptValue === "EAP") {
        this.promptText =
          "Thank you for completing the questionnaire<br><br>At this time we feel that <b>Mental Health Coaching</b> would not be suitable for you, and that you should reach out to our <b>24x7 Mental Health Support</b> team directly";
        this.promptButtonText = "Request Callback";
        this.promptAction = "EAP";
      } else if (promptValue === "SelectCoach") {
        this.promptText =
          "Thank you for answering these questions<br><br>You can now proceed to select a coach that will help guide you on your journey";
        this.promptButtonText = "Choose your coach!";
        this.promptAction = "SelectCoach";
      } else if (promptValue === "SurveryCORE10") {
        this.promptText =
          "Thank you for completing the questionnaire<br><br>There are just a few more questions we feel that we need to ask you. (This will be the final set of questions)";
        this.promptButtonText = "Proceed to Survey";
        this.promptAction = "SurveyCORE10";
      }
      this.step = "PROMPT";
      this.isLoading = false;
      this.loadingQuestion = false;
    } else {
      this.getSurvey("WHO-5");
    }
  },
  methods: {
    testPhoneInput(evt) {
      if(this.phoneNumber.toString().length > 14 && evt.key !== 'Backspace') {
        evt.preventDefault();
      }
    },
    readWelcomeMessage() {
      this.welcomeMessageRead = true;
    },
    getHeaderClasses() {
      if (this.getTheme() === "theme-0") return "pulse-form-header navy-header";
      return "pulse-form-header bg-brand-secondary";
    },
    updateSuccess() {
      this.coachingDataComplete = true;
    },
    async getSurvey(survey) {
      let self = this;
      this.isLoading = true;
      const payload = {
        user_id: this.$store.getters.user_id,
        name: survey,
      };
      let res = await axios.post(
        config.api_env + "/application/api/u/surveys/get-fixed-survey",
        payload,
        config.options
      );
      if (res.data.success) {
        this.currentSurvey = survey;
        this.pulseSections = res.data.survey.sections;
        this.introText = res.data.survey.intro_text;
        this.numOfQuestions = res.data.survey.questions_count;
        this.reports = [];
        let count = 1;
        let i = 0;

        this.pulseSections.forEach(function (section) {
          self.reports.push({
            name: section.name,
            reports: section.outputs,
            selected: count === 1 ? true : false,
            previous: i === 0 ? null : self.pulseSections[i - 1].name,
            next:
              i === self.pulseSections.length - 1
                ? null
                : self.pulseSections[i + 1].name,
          });
          section.activeQuestions = {
            lower: count,
            upper: count + section.questions.length - 1,
          };
          count = count + section.questions.length;
          section.questions.forEach(function (q) {
            self.allQuestions.push(q);
          });
          i = i + 1;
        });
        this.currentQuestion = this.pulseSections[0].questions[0];
        this.handleInputValues();
        this.isLoading = false;
        this.step = "QUESTION";
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login").catch((err) => {});
      }
    },
    async acceptPrompt() {
      let action = this.promptAction;
      if (action === "SelectCoach") {
        this.$router.push("/total-mental-health/select-coach");
      } else if (action === "SurveyCORE10") {
        this.currentQuestionNumber = 1;
        this.getSurvey("CORE-10");
      } else if (action === "EAP") {
        if (this.phoneNumber === "") return;
        let payload = {
          user_id: this.$store.getters.user_id,
          phone: this.phoneNumber,
          service_id: 0,
        };
        let res = await axios.post(
          config.api_env + "/application/api-hc/send-eap-request",
          payload,
          config.options
        );
        if (res.data.sent) {
          this.callBackSuccess = true;
        }
      }
    },
    addToAnswers(qid,answer) {
      const index = this.answers.findIndex(item => item.qid === qid);
      if(index === -1) {
        this.answers.push({qid:qid,answer: answer[0].answer});
      } else {
        this.answers[index].answer = answer[0].answer;
      }
    },
    async answerQuestion(qid) {
      this.errors = [];
      this.loadingQuestion = true;
      if (typeof this.currentAnswer[0].answer === "undefined") {
        this.errors.push("You must enter a valid option");
      } else {
        this.currentQuestionOptions = [];
        const payload = {
          user_id: this.$store.getters.user_id,
          question_id: qid,
          answer: this.currentAnswer,
        };
        let res = await axios.post(
          config.api_env +
            "/application/api/u/surveys/answer-fixed-survey-question",
          payload,
          config.options
        );
        if (res.data.success) {
          this.addToAnswers(qid,this.currentAnswer);
          if (res.data.next_question === false) {
            let isEAP = false;
            res.data.results.forEach(function (result) {
              if (result === "Refer User to EAP") isEAP = true;
            });
            const promptKey = "PROMPT-" + this.$store.getters.user.user.user_id;
            if (isEAP) {
              localStorage.setItem(promptKey, "EAP");
              this.promptText =
                "<b>Thank you for answering these questions.</b><br><br><br> We really appreciate your time and patience. Based on your answers, we feel that our 24/7 Mental Health Support Team would be better suited to support you on your wellbeing journey at this time than our Mental Health Coaching Team.<br><br>A member of our 24/7 Mental Health Support Team would be glad to connect with you and provide you with in the moment support. They would also be keen to better understand your current wellbeing needs and can further review whether our Coaching Service or our Open-Ended Therapy Services would be best placed to help you meet such needs.<br><br>Please enter your phone number below to request a call-back from our 24/7 Mental Health support team or you can reach directly on the below numbers.<br><br>";
              this.promptButtonText = "Request Callback";
              this.promptAction = "EAP";
            } else if (res.data.results[0] === "Commence Coaching Program") {
              localStorage.setItem(promptKey, "SelectCoach");
              this.promptText =
                "<b>And we're done!</b><br><br>Thank you for answering these questions. Now it’s time to introduce you to our Mental Health Coaches. We wish you all the very best as you start your journey towards achieving your wellbeing goals.";
              this.promptButtonText = "Choose your coach!";
              this.promptAction = "SelectCoach";
            } else if (res.data.results[0] === "Administer CORE-10") {
              localStorage.setItem(promptKey, "SurveryCORE10");
              this.promptText =
                "Thank you for completing the questionnaire<br><br>There are just a few more questions we feel that we need to ask you. (This will be the final set of questions)";
              this.promptButtonText = "Proceed to Survey";
              this.promptAction = "SurveyCORE10";
            }
            this.step = "PROMPT";
            this.loadingQuestion = false;
          } else {
            this.previousQuestionId = qid;
            this.currentQuestionNumber++;
            this.currentQuestion = this.allQuestions.find(
              (x) => x.id === res.data.next_question.id
            );
          }
          this.handleInputValues();
          this.loadingQuestion = false;
          this.currentSliderValue = document.getElementById("pulse-slider").value;
        } else if (res.data.err && res.data.err.includes("3994")) {
          this.$store.dispatch("logout");
          this.$router.push("/login").catch((err) => {});
        }
      }
    },
    clearInputs() {
      if (document.getElementById("pulse-text-input")) {
        document.getElementById("pulse-text-input").value = "";
      }
      if (document.getElementById("pulse-number-input")) {
        document.getElementById("pulse-number-input").value = "";
      }
      this.dropdownAnswer = [];
    },
    toggleOption(id, radio) {
      if (radio) {
        this.currentQuestionOptions.forEach(function (o) {
          o.selected = false;
        });
      }
      let selectedOption = this.currentQuestionOptions.find((x) => x.id === id);
      selectedOption.selected = !selectedOption.selected;
      this.setButtonAnswer();
    },
    previousQuestion() {
      let self = this;
      if (this.currentQuestion.id !== this.allQuestions[0].id) {
        this.loadingQuestion = true;
        let backQuestionIndex = this.currentSurvey === "WHO-5" ? -2 : 3;
        /*backQuestionIndex === -2 because -1 for actual array index and -1 for previous entry;
        +3 for the CORE-10 (i.e -2 plus 5 so that it does not go back to the WHO-5 questions
         */
        this.currentQuestion =
          this.allQuestions[this.currentQuestionNumber + backQuestionIndex];
        this.handleInputValues();
        this.currentQuestionNumber--;
        this.loadingQuestion = false;
      }
    },
    setFirstQuestion() {
      this.currentQuestion = this.pulseSections[0].questions[0];
      this.handleInputValues();
      this.currentQuestionNumber = 1;
    },
    handleInputValues() {
      let self = this;
      if (
        this.currentQuestion.type === "single_choice" ||
        this.currentQuestion.type === "multi_choice"
      ) {
        this.currentQuestionOptions = [];
        this.currentQuestion.options.forEach(function (option) {
          self.currentQuestionOptions.push({
            id: option.id,
            text: option.text,
            selected: false,
          });
        });
      }
      this.currentAnswer = [{}];
      if (this.currentQuestion.layout === "scale") {
        const index = this.answers.findIndex(item => item.qid === this.currentQuestion.id);
        let pos = Math.floor(this.currentQuestionOptions.length / 2)
        if(index !== -1) {
           pos = this.currentQuestion.options.findIndex(q => q.text === this.answers[index].answer);
           this.currentSliderValue = pos;
        }
        self.setSliderAnswer(pos);
        this.currentSliderValue = pos;
      } else {
        this.clearInputs();
      }
    },
    setTextAnswer() {
      this.currentAnswer = [
        {
          id: false,
          answer: document.getElementById("pulse-text-input").value,
        },
      ];
    },
    setNumericAnswer() {
      let value = document.getElementById("pulse-number-input").value;
      this.errors = [];
      if (
        value > this.currentQuestion.max_value ||
        value < this.currentQuestion.min_value
      ) {
        this.errors.push(
          "Your answer must fall within the minimum and maximum values. Min: " +
            this.currentQuestion.min_value +
            ", Max: " +
            this.currentQuestion.max_value
        );
        if (value > this.currentQuestion.max_value) {
          document.getElementById("pulse-number-input").value =
            this.currentQuestion.max_value;
          this.currentAnswer = [
            {
              id: false,
              answer: document.getElementById("pulse-number-input").value,
            },
          ];
        } else if (value < this.currentQuestion.min_value) {
          document.getElementById("pulse-number-input").value =
            this.currentQuestion.min_value;
        }
      } else {
        this.currentAnswer = [
          {
            id: false,
            answer: value,
          },
        ];
      }
    },
    setButtonAnswer() {
      let answer = [];
      this.currentQuestionOptions.forEach(function (option) {
        if (option.selected) {
          answer.push({ id: option.id, answer: option.text });
        }
      });
      this.currentAnswer = answer;
    },
    changeSliderAnswer() {
      let option =
          this.currentQuestionOptions[document.getElementById("pulse-slider").value];
      this.currentAnswer = [
        {
          id: option.id,
          answer: option.text,
        },
      ];
      const index = this.currentQuestion.options.findIndex(item => option.id === item.id);
      this.currentSliderValue = index;
      this.addToAnswers(this.currentQuestion.id,option.text);
    },
    setSliderAnswer(position) {
      this.loadingQuestion = false;
      const qid = this.currentQuestion.id;
      const ansIndex = this.answers.findIndex(item => item.qid === qid);
      if(ansIndex !== -1) {
        const optionIndex = this.currentQuestionOptions.findIndex(item => item.text === this.answers[ansIndex].answer);
        this.currentAnswer = [
          {
            id: this.currentQuestionOptions[optionIndex].id,
            answer: this.answers[ansIndex].answer
          }];
        const pos = this.currentQuestion.options.findIndex(item => item.text === this.answers[ansIndex].answer);
        this.currentSliderValue = pos;
      } else {
        let option =
          this.currentQuestionOptions[
            position ? position : this.currentSliderValue
          ];
        this.currentAnswer = [
          {
            id: option.id,
            answer: option.text,
          },
        ];
        this.currentSliderValue = position;
      }
    },
    selectCourse(course_id) {
      this.$router.push("/wellbeing/" + course_id).catch((err) => {});
    },
    scrollTo(id) {
      const scrollTo = document.getElementById(id);
      scrollTo.scrollIntoView({ behavior: "smooth" });
    },
  },
  watch: {
    dropdownAnswer: function (val) {
      if (val) {
        let answer = [];
        if (val.length > 0) {
          val.forEach(function (a) {
            answer.push({ id: a.id, answer: a.text });
          });
          this.currentAnswer = answer;
        } else {
          this.currentAnswer = [{ id: val.id, answer: val.text }];
        }
      }
    },
  },
  computed: {
    eapNumberValidated() {
      return this.phoneNumber.length > 7;
    },
    onFirstQuestion() {
      return this.currentQuestionNumber === 1;
    },
    skin() {
      return this.$store.getters.skin;
    },
    componentText() {
      return this.getText.loggedIn.insights;
    },
    selectedReport() {
      let reports = this.reports.filter(function (report) {
        return report.selected;
      })[0];
      return reports ? reports : [];
    },
  },
};
</script>
<style lang="less">
.questionnaire-modal {
  .modal-content {
    border-radius: 24px;
    overflow: hidden;
  }
  .modal-body {
    padding: 0;
  }
}
#QuestionnaireModal, #WelcomeMessage {
  .navy-header {
    background-color: #403d56;
  }
  .form-control {
    height: 30px;
    border-radius: 5px;
    font-size: 1.6rem;
    max-width: 300px;
  }
  .current-answer {
    color: #e83b75;
  }
  .pulse-container {
    background-color: #f8f8f8;
    min-height: 300px;
    border-radius: 300px;
    .pulse-steps-container {
      max-width: 900px;
      padding: 60px 0 40px 0;
      margin: 0 auto;
      .pulse-step {
        display: flex;
        .pulse-step-title {
          font-size: 1.6rem;
        }
      }
    }
    .pulse-counter {
      font-size: 1.6rem;
    }
    .pulse-form {
      .pulse-form-header-container {
        .pulse-form-header {
          font-size: 1.6rem;
          font-weight: bold;
          padding: 15px;
          text-align: center;
          color: white;
          .skip-button {
            text-decoration: underline;
            cursor: pointer;
          }
        }
        .pulse-form-header-steps {
          display: flex;
          flex-direction: row;
          flex-wrap: 1;
          justify-content: center;
          margin: 20px 10px 0 10px;
          .header-step {
            width: 100px;
            border-radius: 10px;
            margin: 0 3px;
            height: 10px;
            border: 1px solid white;
          }
        }
      }
      .pulse-form-question-container {
        background-color: white;
        border-radius: 0 0 16px 16px;
        padding: 30px 30px 30px 45px;
        min-height: 320px;
        .prompt-text {
          font-family: "DMSans", serif;
          max-width: 600px;
          text-align: center;
          font-size: 2rem;
        }
        .prompt-text-eap {
          font-family: "DMSans", serif;
          max-width: 600px;
          text-align: left;
          font-size: 2rem;
        }
        .pulse-form-question-text {
          padding-bottom: 30px;
          .question-text {
            font-size: 2.2rem;
            font-weight: bold;
          }
        }
        .pulse-form-question-answer {
          max-width: 1060px;
          padding: 30px 30px;
          margin: 0 auto;
          .answer-button {
            border: 3px #bbb solid;
            color: #bbb;
            i {
              position: relative;
              top: 3px;
            }
          }
          .answer-button.selected {
            border: 3px solid;
          }
          .pulse-number-input {
            height: 50px;
            width: 100%;
            max-width: 260px;
            text-align: center;
            font-size: 1.8rem;
            color: #666;
            border: 0px;
            outline: none !important;
            border-radius: 16px;
            background-color: #eeeeee;

            :focus {
              border: 0px;
            }
            :focus-visible {
              border: 0px;
              outline: none !important;
            }
          }
          .pulse-text-input {
            height: 50px;
            width: 100%;
            max-width: 360px;
            text-align: center;
            font-size: 1.8rem;
            color: #666;
            border: 0px;
            outline: none !important;
            border-radius: 16px;
            background-color: #eeeeee;

            :focus {
              border: 0px;
            }
            :focus-visible {
              border: 0px;
              outline: none !important;
            }
          }
          .pulse-button {
          }
        }
        .options-button-container {
          .options-button {
            flex-basis: 50%;
            max-width: 50%;
            @media only screen and (max-width: 769px) {
              flex-basis: 100%;
              max-width: 100%;
            }
          }
        }
        .pulse-form-navigation-buttons {
          padding-top: 30px;
          button {
            i {
              position: relative;
              top: 1px;
            }
          }
        }
      }
    }
    .start-over-button {
      background-color: #997c9c;
      font-size: 1.4rem;
      font-weight: 400;
      border-radius: 6px;
      padding: 12.5px 15px;
      font-family: "DMSans";
      color: white;
    }
  }
  .pulse-error {
    color: red;
    font-size: 1.6rem;
    margin-top: 10px;
  }

  .cta-button {
    padding-top: 12.5px;
    padding-bottom: 12.5px;
  }
  .outline-button {
    padding-top: 12.5px;
    padding-bottom: 12.5px;
  }
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #bbb;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #bbb;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #bbb;
  }
  .cta-button {
  }

  .not-active {
    color: lightgrey;
  }
  .not-active.completed {
    color: darkslategrey; //this will need to go in to theme
  }
  .form-btn {
    min-width: 170px;
  }
  .question-spinner {
    border-radius: 16px 16px 0 0;
    margin-top: 0;
    padding-top: 0;
    background-color: white;
    min-height: 320px;
  }

  //select styling
  .v-select {
    margin: 0 auto;
    max-width: 400px;
    cursor: pointer !important;

    .vs__dropdown-toggle {
      border-radius: 20px;
      border: 0px #eee solid;
      font-family: DMSans, sans-serif;
      background: #eee;
      color: #666;
      font-size: 2rem;
      position: relative;
      padding: 12.5px 10px;
      min-height: 50px;
      .vs__selected-options,
      .vs__selected,
      .vs__actions,
      svg {
        color: #666;
        fill: #aaa;
      }
    }
    &.vs--single {
      .vs__clear {
        display: none;
      }
    }
    &.vs--open {
      .vs__selected-options {
        padding-bottom: 10px;
      }
    }
    .vs__dropdown-menu {
      text-align: left;
      padding-left: 0;
      list-style-type: none;
      max-height: 200px;
      font-size: 2rem;
      color: #4e555b;
      li {
        border-bottom: 1px solid #aaa;
        padding: 10px;
        &:last-child {
          border-bottom-width: 0;
        }
      }
      ::-webkit-scrollbar {
        width: 10px;
      }
      ::-webkit-scrollbar-track {
        border-radius: 10px;
      }
      ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #aaa;
        height: 50px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
      }
    }
    .vs__dropdown-option--selected {
      position: relative;
      &:after {
        content: " ";
        position: absolute;
        color: #aaa;
        background-image: url("/img/insights/tick-blue.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 25px;
        width: 28px;
        height: 20px;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .vs__selected-options {
      .vs__selected {
        background-color: white;
      }
    }
  }
  .select {
    border-radius: 20px;
    border: 1px solid #fff;
    font-family: DMSans, sans-serif;
    background: transparent;
  }

  // track styling
  input[type="range"] {
    height: 36px;
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
  }
  input[type="range"]:focus {
    outline: none;
  }
  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 30px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #eee;
    border-radius: 15px;
    border: 0px solid #000000;
  }
  input[type="range"]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 0px solid #000000;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    background: #aaa;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: 0px;
  }
  input[type="range"]:focus::-webkit-slider-runnable-track {
    background: #eee;
  }
  input[type="range"]::-moz-range-track {
    width: 100%;
    height: 30px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #eee;
    border-radius: 15px;
    border: 0px solid #000000;
  }
  input[type="range"]::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 0px solid #000000;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    background: #aaa;
    cursor: pointer;
  }
  input[type="range"]::-ms-track {
    width: 100%;
    height: 30px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type="range"]::-ms-fill-lower {
    background: #eee;
    border: 0px solid #000000;
    border-radius: 30px;
    box-shadow: 0px 0px 0px #000000;
  }
  input[type="range"]::-ms-fill-upper {
    background: #eee;
    border: 0px solid #000000;
    border-radius: 30px;
    box-shadow: 0px 0px 0px #000000;
  }
  input[type="range"]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 0px #000000;
    border: 0px solid #000000;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    background: #aaa;
    cursor: pointer;
  }
  input[type="range"]:focus::-ms-fill-lower {
    background: #eee;
  }
  input[type="range"]:focus::-ms-fill-upper {
    background: #eee;
  }
  .flex-grow {
    flex-grow: 1;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
  .welcomeMessageContainer {
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .welcomeHeader {
    margin-bottom: 3rem;
    font-family: FilsonProBold, sans-serif;
  }
  .welcomeMessage {
    font-size: 1.6rem;
    line-height: 3rem;
    text-align: center;
  }
  .welcomeTop {
    padding: 30px !important;
  }
}
</style>
