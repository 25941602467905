<template>
  <div class="warning warningV2">
    <div class="warning-background" @click="acceptWarning"></div>
    <div class="warning-card">
     <!--  <font-awesome-icon icon="times-circle" size="8x" /> -->
      <h2>{{getText.notLoggedIn.login.warning}}</h2>
      <p>{{ warning }}</p>
      <div class="button-wrap d-flex justify-content-center">
        <b-button @click="acceptWarning" class="cta-button ">OK</b-button>
      </div>
<!--      <b-button class="close-popup" @click="acceptWarning"><font-awesome-icon icon="times-circle"></font-awesome-icon></b-button>-->
    </div>
    </div>
</template>

<script>
export default {
  name: "warningV2",
  props: {
    warning: {}
  },
  data() {
    return {};
  },
  mounted() {

  },
  methods: {
    acceptWarning() {
      this.$store.dispatch("acceptWarning");
    }
  }
};
</script>
<style lang="less">

.warning {
  &.warningV2 {
    display: flex;
    align-items: center;
    .warning-background {
      width: 100vw;
      height: 100vh;
      position: absolute;
      left: 0;
      right: 0;
      z-index: 5;
      background: transparent;
    }
    .warning-card {
      background-color: #4b4949;
      z-index: 10;
      position: relative;
      max-width: 480px;
      width: 100%;
      margin: auto;
      text-align: center;
      border-radius: 10px;
      padding: 30px 20px;
      color: #f27474;
      h2 {
        font-size: 2.7rem;
        font-weight: 600;
        font-family: "FilsonProBlack", sans-serif;
        margin: 30px auto 20px auto;
        color: white !important;
      }
      p {
        font-size: 1.6rem;
        font-family: "DMSans", sans-serif;
        color: white !important;
      }
      .button-wrap {
        width: 100%;
        text-align: center;
        margin-top: 20px;
        button {
          padding: 8px 20px;
          width: 100px;
          border-radius: 10px !important;
          background: linear-gradient(90deg, #ED3A75 0%, #6A356A 100%);
          &:hover {
            background: linear-gradient(90deg, #6A356A 0%, #ED3A75 100%);
          }
          &:active {
            transform: translateY(2px);
          }
        }
      }
    }
    .close-popup{
      position: absolute;
      top: 0;
      right: 0;
      background-color: transparent;
      width: 50px;
      height:50px;
      svg{
        width: 30px;
        height: 30px;
      }
    }
  }
}
</style>
