<template>
  <div class="view-clinic">
    <div class="content-container row" v-if="loading">
      <div class="col-12 d-flex justify-content-center">
        <b-spinner></b-spinner>
      </div>
    </div>
    <div class="content-container row pb-5 mb-5" v-else>
      <div class="d-none d-md-block col-md-4">
        <div class="category-card">
          <div
            v-if="category.c_featured_img"
            class="category-image mb-3"
            :style="'background-image: url(' + category.c_featured_img + ')'"
          ></div>
          <div class="card-content px-4 py-3">
            <h3 class="brand-primary py-2">{{ category.c_label }}</h3>
            <p v-if="category.c_description">{{ category.c_description }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="options-card p-4">
          <h3 class="d-block d-md-none brand-primary py-3">
            {{ category.c_label }}
          </h3>
          <div class="card-row" v-if="!dateCarousel || dateCarousel.length < 1">
            <h3 class="text-center">
              Apologies, there are no {{ category.c_label }} clinics available
              at the moment.
            </h3>
          </div>
          <div class="event-date card-row" v-else>
            <h4>Choose Date:</h4>
            <b-button-group class="button-carousel px-0 px-sm-5 py-3">
              <a
                v-if="showPrevDate"
                class="prev-date"
                @click="scrollCarousel('dateCarouselIndex', -1)"
                ><i class="brand-highlight-1 fa fa-caret-left fa-4x"></i
              ></a>
              <b-button
                v-for="(date, index) in dateCarousel"
                :key="index"
                @click="selectDate(date, index)"
                class="filter-button py-3 col-6 col-md-4 mx-3"
                :class="{
                  active: selectedDate && selectedDate.date === date.date,
                }"
                >{{ formatDate(date.date) }}</b-button
              >
              <a
                v-if="showNextDate"
                class="next-date"
                @click="scrollCarousel('dateCarouselIndex', 1)"
                ><i class="brand-highlight-1 fa fa-caret-right fa-4x"></i
              ></a>
            </b-button-group>
          </div>
          <div class="event-provider card-row" v-if="selectedDate">
            <h4>Choose Expert:</h4>
            <b-button-group class="button-carousel px-sm-5 py-3">
              <a
                v-if="showPrevEvent"
                class="prev-date"
                @click="scrollCarousel('eventCarouselIndex', -1)"
                ><i class="brand-highlight-1 fa fa-caret-left fa-4x"></i
              ></a>
              <b-button
                v-for="(event, index) in eventCarousel"
                :key="index"
                @click="getDate(event.ed_id)"
                class="filter-button col-6 col-md-4 py-3 mx-3"
                :class="{
                  active: selectedEvent && selectedEvent.ed_id === event.ed_id,
                }"
                >{{
                  event.e_service_provider
                    ? event.e_service_provider
                    : event.e_title
                }}</b-button
              >
              <a
                v-if="showNextEvent"
                class="next-date"
                @click="scrollCarousel('eventCarouselIndex', 1)"
                ><i class="brand-highlight-1 fa fa-caret-right fa-4x"></i
              ></a>
            </b-button-group>
          </div>
          <div class="event-time card-row" v-if="timeSlots">
            <div v-if="timeSlots.length > 0">
              <h4>Choose Time:</h4>
              <div class="px-md-5 py-3 row time-slots mx-0">
                <div
                  class="col-6 col-md-4 px-0 d-flex my-3"
                  v-for="(timeSlot, index) in timeSlots.filter(
                    (x) => !x.ts_fully_booked
                  )"
                  :key="index"
                >
                  <b-button
                    class="filter-button py-3 mx-3"
                    :class="{
                      active:
                        selectedSlot && selectedSlot.ts_id === timeSlot.ts_id,
                    }"
                    @click="slotSelect(timeSlot)"
                    >{{ parseTime(timeSlot.ts_start_date_time) }}</b-button
                  >
                </div>
              </div>
            </div>
            <div v-else>
              <h4>
                Sorry, there's no available slots for the selected provider on
                this date.
              </h4>
            </div>
          </div>
          <div class="phone-input pb-5" v-if="selectedSlot">
            <h4>Phone Number:</h4>
            <b-form-group class="px-5 pt-3">
              <b-input
                type="number"
                v-model="phoneModel"
                @blur="phoneInput"
                v-on:keyup.enter="$event.target.blur()"
                class="py-3 pl-4"
              ></b-input>
              <p v-if="showError" class="text-pink">
                * You must enter a valid phone number
              </p>
            </b-form-group>
          </div>
          <div class="event-questions pb-5" v-if="phoneNumber && selectedEvent">
            <div
              class="question"
              v-for="(question, i) in selectedEvent.e_event_questions"
              :key="i"
              :id="'question' + '-' + i"
            >
              <h4>{{ question.question_label }}</h4>
              <div
                v-if="
                  question.question_label
                    .toLowerCase()
                    .includes('is this an initial or follow-up appointment?')
                "
                class="mobile-tooltip"
              >
                <p>
                  Use <span class="italic">Initial</span> if this is the first
                  consultation for your current concern.
                </p>
                <p>
                  Use <span class="italic">Follow Up</span> if you have already
                  had your Initial Consultation on this specific concern
                </p>
              </div>
              <v-select
                class="px-5 py-3"
                @input="questionInput($event, question.question_label, i)"
                :options="question.options"
                label="label"
              >
              </v-select>
              <b-tooltip
                custom-class="clinics-questions-tooltip"
                v-if="
                  question.question_label
                    .toLowerCase()
                    .includes('is this an initial or follow-up appointment?')
                "
                :target="'question' + '-' + i"
                triggers="hover"
                placement="top"
              >
                <p>
                  Use <span class="italic">Initial</span> if this is the first
                  consultation for your current concern.
                </p>
                <p>
                  Use <span class="italic">Follow Up</span> if you have already
                  had your Initial Consultation on this specific concern
                </p>
              </b-tooltip>
            </div>
          </div>
          <div class="selected-info px-md-5 mx-md-3" v-if="showBookingButton">
            <p>
              You have selected
              <strong>{{ formatDate(selectedSlot.ts_start_date_time) }}</strong>
              at
              <strong>{{ parseTime(selectedSlot.ts_start_date_time) }}</strong>
              with <strong>{{ selectedEvent.e_service_provider }}</strong>
            </p>
            <p>
              By booking an appointment, you agree to the following
              <a
                href="/laya/Digital_Wellbeing_Experts_TOS_Privacy.pdf"
                target="_blank"
                >terms & conditions</a
              >.
            </p>
            <p>
              What happens next?<br />
              1. You'll receive an email booking confirmation and a link to
              manage your booking.<br />
              2. You will receive your unique video consultation link 1-2 days
              before your appointment. Please ensure to check your junk or spam
              inbox.<br />
              3. 5 minutes before your appointment, you should visit your link
              to make sure everything is working correctly.
            </p>
            <p>
              If you have any issues or questions, please use our live chat.
            </p>
            <div class="button-wrap">
              <b-button
                @click="bookNow(selectedSlot.ts_id)"
                class="cta-button px-5 py-3 mt-3 mb-5"
                :disabled="category.c_user_registered || !phoneNumber"
                >Book Now</b-button
              >
            </div>
          </div>
          <div
            class="already-registered col-12 py-2"
            v-if="category.c_user_registered"
          >
            <p class="mb-0">
              You already have reached the booking limit for this service. You
              can only have three upcoming bookings per service, if you wish to
              cancel an existing booking to book a new one, you can do so in
              <a @click="$emit('booked-event')">My Bookings</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/config/constants";
import moment from "moment";
export default {
  name: "ViewClinic",
  data() {
    return {
      loading: true,
      category: null,
      eventDates: null,
      dateCarouselIndex: 0,
      eventCarouselIndex: 0,
      showNextDate: false,
      showPrevDate: false,
      selectedDate: null,
      selectedDateIndex: null,
      showNextEvent: false,
      showPrevEvent: false,
      selectedEvent: null,
      selectedEventIndex: null,
      timeSlots: null,
      selectedSlot: null,
      screenWidth: null,
      phoneNumber: null,
      phoneModel: null,
      showError: false,
      eventQuestions: [],
    };
  },
  props: ["categoryId"],
  mounted() {
    this.getCategory();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    async getCategory() {
      const fromDate = moment().add(1, "days").format("YYYY-MM-DD");
      let payload = {
        user_id: this.$store.getters.user_id,
        category_id: this.categoryId,
        from_date: fromDate,
        days_limit: 60,
      };
      let res = await this.axios.post(
        config.api_env + "/application/api/g/global-events/get-category",
        payload,
        config.options
      );
      if (res.data.success) {
        this.category = res.data.category;
        this.eventDates = res.data.event_dates;
        if (this.eventDates.length > this.carouselSize) {
          this.showNextDate = true;
        }
        this.loading = false;
        // Send selected category to parent to use as a function
        // parameter on sendCategoryAnalyticsEvent()
        this.$emit("selected-category", this.category);
      } else if (res.data.err && res.data.err.includes("3994")) {
        if (
          await this.showMsgModal("Invalid user, please login and try again")
        ) {
          this.$store.dispatch("logout");
          this.$router.push("/login");
        }
      } else {
        this.showMsgModal("Something went wrong, please try again later");
      }
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YY");
    },
    scrollCarousel(carousel, direction) {
      this[carousel] = this[carousel] + direction;
    },
    selectDate(date, index) {
      this.selectedDate = date;
      this.selectedDateIndex = index;
      if (date.event_dates.length > this.carouselSize) {
        this.showNextEvent = true;
      }
      this.selectedEvent = null;
      this.selectedSlot = null;
      this.timeSlots = null;
    },
    async getDate(eventId) {
      const payload = {
        event_date_id: eventId,
        user_id: this.$store.getters.user_id,
      };
      let res = await this.axios.post(
        config.api_env + "/application/api/g/global-events/get-date",
        payload,
        config.options
      );
      if (res.data.success) {
        this.timeSlots = res.data.event_date.ed_timeslots;
        this.selectedEvent = res.data.event_date;
      }
    },
    parseTime(time) {
      return moment(time).format("HH:mm");
    },
    slotSelect(slot) {
      this.selectedSlot = slot;
    },
    async bookNow(timeslot) {
      const payload = {
        timeslot_id: timeslot,
        user_id: this.$store.getters.user_id,
        phone_number: this.phoneNumber,
        questions: this.eventQuestions,
      };
      let res = await this.axios.post(
        config.api_env + "/application/api/g/global-events/book-timeslot",
        payload,
        config.options
      );
      if (res.data.success) {
        if (await this.showMsgModal("Booking Confirmed")) {
          // Send selected category to parent to use as a function
          // parameter on viewBookings()
          this.$emit("booked-event", {
            timeslot: timeslot,
            category: this.category,
          });
        }
      } else if (res.data.err && res.data.err.includes("3994")) {
        if (
          await this.showMsgModal("Invalid user, please login and try again")
        ) {
          this.$store.dispatch("logout");
          this.$router.push("/login");
        }
      } else {
        this.showMsgModal("Something went wrong, please try again later");
      }
    },
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
    phoneInput() {
      if (this.phoneModel.length > 6) {
        this.phoneNumber = this.phoneModel;
        this.showError = false;
      } else {
        this.showError = true;
        this.phoneNumber = null;
      }
    },
    questionInput(val, question, index) {
      this.$set(this.eventQuestions, index, {
        question_label: question,
        answer: val,
      });
    },
  },
  computed: {
    dateCarousel() {
      return this.eventDates.filter((x) => !x.date_fully_booked).length <=
        this.carouselSize
        ? this.eventDates.filter((x) => !x.date_fully_booked)
        : this.eventDates
            .filter((x) => !x.date_fully_booked)
            .slice(
              this.dateCarouselIndex,
              this.dateCarouselIndex + this.carouselSize
            );
    },
    eventCarousel() {
      return this.selectedDate.event_dates.filter((x) => !x.ed_fully_booked)
        .length <= this.carouselSize
        ? this.selectedDate.event_dates.filter((x) => !x.ed_fully_booked)
        : this.selectedDate.event_dates
            .filter((x) => !x.ed_fully_booked)
            .slice(
              this.eventCarouselIndex,
              this.eventCarouselIndex + this.carouselSize
            );
    },
    carouselSize() {
      return this.screenWidth < 576 ? 2 : 3;
    },
    showBookingButton() {
      if (this.selectedEvent && this.selectedSlot) {
        const questionsLength = this.selectedEvent.e_event_questions.length;
        if (questionsLength > 0) {
          for (let i = 0; i < questionsLength; i++) {
            if (
              this.eventQuestions[i] === undefined ||
              !this.eventQuestions[i].answer
            ) {
              return false;
            }
          }
          return true;
        } else if (this.phoneNumber) {
          return true;
        } else return false;
      }
      return false;
    },
  },
  watch: {
    dateCarouselIndex(newIndex) {
      newIndex > 0 ? (this.showPrevDate = true) : (this.showPrevDate = false);
      newIndex + this.carouselSize >= this.eventDates.length
        ? (this.showNextDate = false)
        : (this.showNextDate = true);
    },
    eventCarouselIndex(newIndex) {
      newIndex > 0 ? (this.showPrevEvent = true) : (this.showPrevEvent = false);
      newIndex + this.carouselSize >= this.selectedDate.event_dates.length
        ? (this.showNextEvent = false)
        : (this.showNextEvent = true);
    },
  },
};
</script>

<style lang="less">
.view-clinic {
  .category-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    transition: 0.3s ease-out;
    .category-image {
      height: 180px;
      width: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
    .card-content {
      width: 100%;
      h3 {
        font-size: 1.9rem;
      }
      p {
        text-align: left;
        font-size: 1.3rem;
      }
    }
  }
  .options-card {
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    h4 {
      margin-bottom: 0;
    }
    .card-row {
      padding-bottom: 40px;
    }
    .event-provider {
      .button-carousel {
        justify-content: flex-start;
      }
    }
    .button-carousel {
      width: 100%;
      justify-content: flex-start;
      position: relative;
      .btn {
      }
      .next-date,
      .prev-date {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
      }
      .next-date {
        right: 0;
      }
      .prev-date {
        left: 0;
      }
      @media (max-width: 576px) {
        .next-date {
          right: -15px;
        }
        .prev-date {
          left: -15px;
        }
      }
    }
    .time-slots {
      width: 100%;
      display: flex;
      .filter-button {
        max-width: 100%;
        flex-grow: 1;
      }
    }
    .selected-info {
      font-size: 1.4rem;
      text-align: left;
    }
    .button-wrap {
      width: 100%;
      text-align: center;
      .disabled:hover {
        background-color: #6c757d;
      }
    }
    .already-registered {
      background-color: #b4ecb4;
      font-size: 1.4rem;
      border-radius: 10px;
      text-align: center;
      a {
        text-decoration: underline;
      }
    }
    .phone-input {
      input {
        font-size: 1.6rem;
        border-radius: 20px;
        border-color: #d60570;
      }
    }
    .text-pink {
      color: #d60570 !important;
    }
    .event-questions {
      .mobile-tooltip {
        display: none;
        padding: 5px 0;
        p {
          font-size: 1.2rem;
          font-family: "Open Sans";
          margin-bottom: 0;
        }
        .italic {
          font-style: italic;
        }

        @media (max-width: 576px) {
          display: block;
        }
      }
      .v-select {
        font-size: 1.6rem;
        .vs__dropdown-toggle {
          border-color: #d60570;
          border-radius: 20px;
        }
        .vs__dropdown-menu {
        }
      }
    }
  }
}
.clinics-questions-tooltip {
  max-width: 700px;
  width: 100%;
  .tooltip-inner {
    padding: 15px;
    width: 100%;
    max-width: 700px;
    background-color: rgba(0, 0, 0, 0.98);
    font-size: 1.4rem;
    font-family: "Open Sans";
    .italic {
      font-style: italic;
    }
  }
  @media (max-width: 576px) {
    display: none !important;
  }
}
</style>
