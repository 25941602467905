<template>
    <div class="exercise-pop-up">
        <div class="pop-up-background" @click="hidePopup"></div>
        <div class="pop-up-content bg-white row" v-if="currentExercise.exercise_name">
            <div class="col-12 col-md-6 px-0"  v-if="screen === 0 || !info ">
                <GAVideoPlayer v-if="currentExercise.exercise_video_sq" width="100%" :video-class="'video'" :src="currentExercise.exercise_video_sq +'#t=2'"/>
            </div>
            <div v-if="screen === 0 || info " class="col-12 col-md-6 workout-info p-5">
                <b-row class="mx-0 px-5 py-5">
                    <div class="col-12">
                        <h4 class="brand-secondary">{{currentExercise.exercise_name}}</h4>
                    </div>
                    <div class="col-12 exercise-equipment my-5 py-4" v-if="displayEquipment">
                        <h5 class="brand-secondary"><img src="/img/fitness/equipment.svg" class="mr-4">{{componentText.equipment}} {{currentExercise.exercise_equipment}}</h5>
                    </div>
                    <div class="col-12 mb-5">
                        <h5 class="brand-secondary">{{componentText.how}}</h5>
                        <p>{{currentExercise.exercise_how_to}}</p>
                    </div>
                    <div class="col-12">
                        <h5 class="brand-secondary">{{componentText.where}}</h5>
                        <p>{{currentExercise.exercise_where_feel}}</p>
                    </div>
                </b-row>
            </div>
            <b-button class="exercise-info cta-button d-block d-sm-none" @click="showInfo">i</b-button>
            <b-button class="close-popup" @click="hidePopup"><font-awesome-icon class="brand-secondary" icon="times-circle"></font-awesome-icon></b-button>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import GAVideoPlayer from "../GAVideoPlayer.vue";

    export default {
        name: "ExercisePopup",
      components: {GAVideoPlayer},
        props:{
            currentExercise: {}
        },
        data() {
            return {
                history:{},
                screen: 0,
                info: false
            };
        },
        created() {
            window.addEventListener("resize", this.checkScreenSize);
        },
        destroyed() {
            window.removeEventListener("resize", this.checkScreenSize);
        },
        methods: {
            hidePopup(){
                this.$emit('close')
            },
            showInfo(){
                this.info = !this.info
            },
            checkScreenSize(){
                if(window.innerWidth < 577 ){
                    this.screen = 1
                }else{
                    this.screen = 0
                }
            }
        },
        computed:{
            componentText() {
                return this.getText.loggedIn.fitness.exercisePopup;
            },
            displayEquipment(){
                if(this.currentExercise.exercise_equipment.toLowerCase() === 'body weight' || this.currentExercise.exercise_equipment.toLowerCase() === 'no equipment required'){
                    return false
                }else return true
            }
        }
    };
</script>
<style lang="less">
    .exercise-pop-up{
        display: flex;
        align-items: center;
        z-index: 500;
        position:fixed;
        overflow-y:scroll;
        overflow-x: hidden;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        text-align: center;
        .pop-up-content{
            max-width: 1200px;
            width: 100%;
            margin: auto;
            border-radius: 25px;
            font-size: 1.6rem;
            text-align: left;
            z-index: 10;
            color: #fff;
            position: relative;
            .video{
                border-radius: 20px;
                &:focus{
                    outline: none;
                }
            }
            .workout-info{
                h4 {
                    font-size: 2.1rem;
                    font-family: 'FilsonProBold', sans-serif;
                }
                h5{
                    font-size: 1.9rem;
                    font-family: 'FilsonProBold', sans-serif;
                    img{
                        width: 30px;
                    }
                }
                p{
                    font-size: 1.6rem;
                    font-family: 'FilsonProBold', sans-serif;
                }
                .exercise-equipment{
                    border-width: 1px 0 1px 0;
                    border-color: #c1c1c1;
                    border-style: solid;
                }
            }
            .close-popup{
                position: absolute;
                top: 0;
                right: 0;
                background-color: transparent;
                width: 50px;
                height:50px;
                svg{
                    width: 30px;
                    height: 30px;
                }
            }
            .exercise-info{
                position: absolute;
                top: 10px;
                left: 10px;
                width: 30px;
                height:30px;
            }
        }
        .pop-up-background{
            width: 100vw;
            height: 100vh;
            position: absolute;
            left: 0;
            right: 0;
            z-index: 5;
            background: transparent;
        }
    }
</style>
