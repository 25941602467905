<template>
  <div class="container login">
    <div class="row page-content">
      <div class="col-md-7 img-part px-0">
        <div
          class="login-bg d-flex justify-content-center align-items-center"
          :class="{
            'bg-brand-secondary': skin > 0,
            'bg-grad-image-purple': skin === 0,
          }"
        >
          <div class="heart-placeholder">
            <img
              v-if="hardcoded.spectrum_heart"
              class="heart-img"
              :src="hardcoded.spectrum_heart"
            />
            <img class="heart-img" src="/img/default-asset.svg" v-else />
          </div>
          <div class="login-image"></div>
        </div>
      </div>
      <div class="col-md-5 form min-height" v-if="$store.getters.subdomain == 'benendenhealth' || $store.getters.subdomain === 'benendenhealthuat'">
        <div class="d-flex flex-column align-items-center justify-content-center h-100">
          <p class="cta-text brand-secondary px-5 text-center mb-5">You’ve been signed out of the Wellbeing Classes.<br><br> To sign back in, simply log in to your My Benenden account and click on Wellbeing Classes.</p>
          <a href="https://www.benenden.co.uk/login/"><b-button class="cta-button px-5 pt-2 pb-3">Go to MyBenenden</b-button></a>
        </div>
      </div>
      <div class="col-md-5 form min-height" v-else>
        <div v-if="hardcoded.login_logos" class="logos d-flex pt-5">
          <div
            v-for="(logo, i) in hardcoded.login_logos"
            :key="i"
            class="d-flex logo px-1"
          >
            <img :src="logo" />
          </div>
        </div>
        <div class="form-content">
          <div
            :class="[
              ($store.getters.client.client_logo
                .toLowerCase()
                .includes('spectrum-life-logo') ||
                $store.getters.client.client_logo
                  .toLowerCase()
                  .includes('lloydwhyte')) &&
              skin === 15
                ? 'lw-form-logo'
                : 'form-logo',
            ]"
          >
            <img
              :src="getLogo"
              onerror="javascript:this.src='/img/logo-spectrum-life.svg'"
            />
          </div>
          <h2 class="form-title">{{ getText.notLoggedIn.login.title }}</h2>
          <b-form class="px-5">
            <b-form-group
              id="input-group-1"
              :label="getText.notLoggedIn.login.username"
              label-for="inputEmail"
            >
              <b-form-input
                type="email"
                id="inputEmail"
                v-model="user.email"
                :state="$v.user.email.$dirty ? !$v.user.email.$error : null"
                aria-describedby="user-name-error"
              ></b-form-input>
              <b-form-invalid-feedback id="user-name-error">
                {{ getText.notLoggedIn.login.usernameWarning }}
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              id="input-group-2"
              :label="getText.notLoggedIn.login.password"
              label-for="inputPass"
            >
              <div class="password-wrap">
                <b-form-input
                  :type="passwordFieldType"
                  id="inputPass"
                  v-model="user.password"
                  :state="
                    $v.user.password.$dirty ? !$v.user.password.$error : null
                  "
                  aria-describedby="password-error"
                  @keyup.enter.native="submitForm"
                ></b-form-input>
                <div class="eye" @click="switchVisibility">
                  <font-awesome-icon
                    v-if="passwordFieldType === 'password'"
                    icon="eye"
                  ></font-awesome-icon>
                  <font-awesome-icon
                    v-else
                    icon="eye-slash"
                  ></font-awesome-icon>
                </div>
              </div>
              <b-form-invalid-feedback id="password-error">
                {{ getText.notLoggedIn.login.passwordWarning }}
              </b-form-invalid-feedback>
            </b-form-group>
            <a class="forgot-password" @click="$emit('forgot-pass')">{{
              getText.notLoggedIn.login.forgotPassword
            }}</a>
            <div
              col-sm-12
              class="
                submit-form
                text-center
                d-flex
                align-items-center
                justify-content-center
              "
            >
              <b-button
                type="button"
                @click="submitForm"
                class="py-3"
                variant="cta"
                >{{ getText.notLoggedIn.login.buttonText }}</b-button
              >
            </div>
            <div col-sm-12 class="new-here">
              <a @click="$emit('sign-up')" class="text-link">{{
                getText.notLoggedIn.login.signUp
              }}</a>
            </div>
            <div col-sm-12 class="new-here" v-if="$store.getters.subdomain === 'landg'">
              <a @click="$parent.toggleAccessLogin()" class="text-link">Sign In with Access Code</a>
            </div>
            <div col-sm-12 class="new-here">
              <a
                href="/faqs" target="_blank"
                >FAQs</a
              >
<!--              This can be turned when ready for new FAQ to be deployed-->
<!--                <a @click="$emit('faqs')"-->
<!--                  target="_blank"-->
<!--                  class="text-link"-->
<!--                  >FAQs</a-->
<!--                >-->
            </div>
            <div class="col-12 text-center">
              <LanguageSelect class="border-bottom-0"></LanguageSelect>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import config from "../../config/constants";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import LanguageSelect from "../LanguageSelect";
export default {
  components: { LanguageSelect },
  name: "login",
  mixins: [validationMixin],
  data() {
    return {
      user: {
        email: "",
        password: "",
      },
      newUser: {
        org_code: "4wSZAoYA",
      },
      isSignUp: false,
      organisation: null,
      passwordFieldType: "password",
    };
  },
  validations: {
    user: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  methods: {
    switchForm() {
      this.isSignUp = !this.isSignUp;
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    submitForm() {
      this.$v.user.$touch();
      if (this.$v.user.$anyError) {
        return;
      }
      this.signIn();
    },
    async signIn() {
      let payload = {
        username: this.user.email,
        password: this.user.password,
        encrypt_level: "high",
        platform: "web",
        lang: this.$store.state.lang,
      };
      let v3_ready = await this.$store.dispatch("signIn", {
        router: this.$router,
        payload,
      });
      if (v3_ready === false) {
        this.$emit("not-v3");
      }
    },
    async getOrgLocations() {
      let payload = { org_code: "4wSZAoYA", lang: this.$store.state.lang };
      try {
        let res = await axios.post(
          config.api_env + "/application/api-hc/get-client-signup-options",
          payload,
          config.options
        );
        this.organisation = res.data;
      } catch (e) {
        console.log(e);
      }
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
  },
  computed: {
    ...mapGetters(["hardcoded", "skin"]),
    getLogo() {
      if (this.hardcoded && this.hardcoded.logo) {
        return this.hardcoded.logo;
      } else return this.$store.getters.client.client_logo;
    },
  },
};
</script>
<style lang="less">
.cta-text{
  font-size: 1.8rem;
  font-weight: bold;
}
</style>
