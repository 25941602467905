<template>
  <div id="SingleClinician" class="row">
    <div v-if="loading" class="col-12 coach-modal-loader">
      <Loader class="my-auto" />
    </div>
    <div class="col-12 coach_modal_inner brand-text" v-else>
			<div v-if="status === 'select_slot'" class="input">
				<div class="row no-gutters">
					<div class="col-lg-7 col-md-12 left_col">
						<div class="avatar_wrapper mb-5">
							<div class="avatar_box">
								<img
									:src="coach.avatar_url"
									title="Coach Avatar"
									:alt="coach.name"
								/>
							</div>
							<div class="text">
								<h1>{{ coach.name }}</h1>
								<p>
									<i class="fas fa-check-circle brand-highlight-1"></i>
									{{ coach.qualification }} <br />
									<i class="fas fa-graduation-cap brand-highlight-1"></i>
									{{ coach.degree }}
								</p>
							</div>
						</div>
						<div class="info_wrapper">
							<div v-html="coach.description_long">
							</div>
						</div>
						<div class="buttons-container">
							<b-button @click="closeModal()" variant="cta-outline  shadow filson-book" block>Back</b-button>
						</div>
					</div>
					<div class="col-lg-5 col-md-12 right_col">
						<div class="dates mb-4">
							<h4 class="my-3 text-left">What date suits you ?</h4>
							<div class="row">
								<slick ref="slick" :options="slickOptions" class="w-100">
									<b-button
										@click="selectDate(e)"
										v-for="(e, e_i) in events"
										:key="e_i"
										class="shadow filson-book"
										:variant="
                    selected_date.date === e.date
                      ? 'cta'
                      : 'cta-outline'
                  "
									>{{ getShortDate(e.date) }}</b-button
									>
								</slick>
							</div>
						</div>
						<div v-if="selected_date" class="slots mt-4">
							<h4 class="my-3 text-left">
								What time works best for you on
								{{ getDayStringLong(selected_date.date) }} ?
							</h4>
							<div class="row mb-4">
								<div
									v-for="(t, t_i) in selected_date.slots"
									:key="t_i"
									class="col-4 my-3"
								>
									<b-button
										@click="selectSlot(t)"
										block
										class="shadow filson-book"
										:variant="
                      t.id === selected_slot.id
                        ? 'cta'
                        : 'cta-outline'
                    "
									>{{ getHoursMinutes(t.start, true) }}</b-button
									>
								</div>
							</div>
						</div>
						<div class="selected_slot" v-if="selected_slot">
							<p class="my-3 text-left">
								You have selected
								<b>{{ getFormatedDate(selected_slot.start, "DD/MM/YY") }}</b> at
								<b>{{ getHoursMinutes(selected_slot.start, true) }}</b><br/> with
								<b>{{ coach.name }}</b>
							</p>
						</div>
						<div class="info my-3">
              <b-form-checkbox
                  id="pp-cb"
                  v-model="pp_accepted"
                  name="pp-cb"
              >
                I agree to the Privacy Policy <a target="_blank" href="/pdf/DC_PrivacyPolicy.pdf">here</a>
              </b-form-checkbox>
              <b-form-checkbox
                  id="tc-cb"
                  v-model="tc_accepted"
                  name="tc-cb"
              >
                I agree to the Terms of Service <a target="_blank" href="/pdf/DC_TermsOfService.pdf">here</a>
              </b-form-checkbox>
              <br/>
              <p class="text-left">As part of my agreement with the Privacy Policy and Terms of Service, I understand that:</p>
              <ul>
                <li>All appointments are subject to a 48-hour cancellation window prior to your appointment time, no refunds of credits can be applied if you cannot attend your appointment within 48 hours of your appointment window.</li>
                <li>My appointment may be facilitated by a trusted partner of Spectrum.Life, as outlined and referenced as a Facilitated Service as per Clause 1.1 in the Terms of Service, and I understand my data protection relationship with both Spectrum.Life and trusted partners.</li>
                <li>Spectrum.Life makes no warranty that use of the Services should be construed as health advice on any health-related issue if prior consultation has not occurred with your own GP, Health Professional or Consultant, and accessing these services is in conflict with any advice received by your GP, Health Professional or Consultant. </li>
                <li>You have added Next of Kin information in the case of an emergency arising during consultation. No other contact will be made with your next of Kin. Next of Kin information can be provided in Profile Tab. </li>
              </ul>
						</div>
						<div class="bottom_button">
							<b-button
								v-if="!booked && selected_slot"
								@click="bookSlot"
								variant="cta"
								class=" mx-auto px-5 shadow filson-book"
                :disabled="!ppsTcsAccepted"
							>Book Now</b-button>
							<b-button
								v-if="booked"
								class="cta-button mx-auto px-5 shadow filson-book"
								:disabled="true"
							>Booked successfully, thanks </b-button>
						</div>
					</div>
				</div>
			</div>
<!--	BELOW PAYMENTS  -->
			<div v-else class="payment animate__animated animate__fadeIn">
				<DigitalClinicsBookingPayment :timeslot_id="selected_slot.id" />
			</div>
    </div>
  </div>
</template>
<style lang="less">
#clinician_modal {
	text-align: center;
	box-shadow: 0px 0px 100px rgba(0,0,0,0.5) inset;
	.modal-content {
		.modal-body {
			padding: 0px;
		}
	}
}
#SingleClinician {
	.scale-down{
	}
	font-family: "DMSans", Arial, Helvetica, sans-serif;
	b {
		font-family: "FilsonProBold", sans-serif;
	}
	.slick-slider {
		.slick-track {
			padding-bottom: 15px;
			button {
				font-size: 16px;
			}
		}
		.slick-next {
			top: 47%;
			right: -25px;
		}
		.slick-prev {
			top: 47%;
			left: -15px;
		}
	}
	.coach-modal-loader {
		min-height: 800px;
		display: flex;
		align-items: center;
	}
	.coach_modal_inner {
		font-size: 1.6rem;
		.left_col {
			padding: 30px;
			.avatar_wrapper {
				margin-top: 50px;
				.avatar_box {
					text-align: center;
					img {
						border-radius: 50%;
						max-width: 60%;
						box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
					}
				}
			}
			.text {
				h1 {
					margin: 30px 15px 15px;
					font-size: 30px;
				}
			}
			.info_wrapper {
				margin-bottom: 100px;
			}
			.buttons-container {
				position: absolute;
				right: 30px;
				bottom: 30px;
				width: 100px;
			}
		}
		
		.right_col {
			background-color: #f7f7f7;
			padding: 30px 40px;
			.slots {
				transition: all 1s ease-in-out;
			}
			.info {
				text-align: left;
				font-size: 12px;
			}
			.bottom_button {
				margin-top: 30px;
				text-align: right;
			}
		}
	}
}
</style>
<script>
import Loader from "../Loader";
import slick from "vue-slick";
import axios from "axios";
import config from "../../config/constants";
import DigitalClinicsBookingPayment from "./DigitalClinicsBookingPayment";

export default {
  name: "DigitalClinicsSingleClinician",
  components: { Loader, slick, DigitalClinicsBookingPayment },
  props: ["coach_id", "category_id"],
  data() {
    return {
      loading: true,
      coach: {},
      events: [],
      selected_date: false,
      selected_slot: false,
      booked: false,
	    dc_setting: '',
      slickOptions: {
        infinite: false,
        autoplay: false,
        dots: false,
        nextArrow:
          '<button class="slick-next" type="button" style=""><i class="fas fa-caret-right brand-highlight-1"></i></button>',
        prevArrow:
          '<button class="slick-prev" type="button" style=""><i class="fas fa-caret-left brand-highlight-1"></button>',
        slidesToScroll: 3,
        slidesToShow: 3,
        centerPadding: "5",
        adaptiveHeight: true,
      },
	    booking: {},
	    status : 'select_slot',
	    message: '',
      pp_accepted: false,
      tc_accepted: false
    };
  },
  mounted() {
    this.getCoach(this.coach_id, this.category_id);
  },
	computed: {
    ppsTcsAccepted() {
      return this.pp_accepted && this.tc_accepted;
    }
	},
  methods: {
    closeModal() {
      this.$bvModal.hide("clinician_modal");
    },
    async bookSlot() {
		this.logAction('DC Generic Booking Modal Clicked: Booking with ' + this.coach.name);
      let p = {
        path: "api/u/digital-bookings/book-session",
        slot_id: this.selected_slot.id,
      };
      let res = await this.api(p);
      if (res.success) {
				this.booked = true
				this.message = 'Booked successfully'
	      setTimeout(()=>{
		      this.$bvModal.hide("clinician_modal");
		      setTimeout(()=>{
			      this.$router.push({name :'digitalClinicsGeneric'})
		      }, 500)
	      }, 3000)
      } else {
				if(['payg','wallet'].includes(res.dc_setting)){
					this.status = 'require_payment'
					let modal_dialog = document.getElementById("clinician_modal").getElementsByClassName('modal-dialog')[0]
					modal_dialog.classList.remove('modal-xl')
					modal_dialog.classList.add('modal-md')
				}
      }
    },
    selectSlot(t) {
      if (!this.booked) {
        this.selected_slot = t;
      }
    },
    selectDate(e) {
      this.selected_slot = false;
      if (!this.booked) {
        this.selected_date = e;
      }
    },
    setDeliveryType(type) {
      if (!this.booked) {
        this.selected_delivery_type = type;
      }
    },
    async getCoach(id, category_id) {
      let p = {
        path: "api/u/digital-bookings/get-service-provider",
        service_provider_id: id,
	      category_id: category_id
      };
      let res = await this.api(p);
      if (res.success) {
        this.coach = res.service_provider;
        this.events = res.events;
        this.loading = false;
      }
    },
  },
};
</script>
