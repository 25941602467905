<template>
    <div class="HomeFrontDoor py-3 mt-5">
      <div v-if="loading">
        <FrontDoorLoader/>
      </div>
      <div v-else>
        <div class="mb-4 container row d-flex flex-row justify-space-between align-items-center">
            <div>
                <h2 class="home-title">{{ componentText.homeFrontDoorTitle }}</h2>
<!--                <div class="home-title-line bg-brand-secondary"></div>-->
            </div>
        </div>
        <div class="container">
            <div class="home-front-door-card-container" :class="{'two-column' : bannerCards.length === 4}">
                <div v-for="(bannerItem, index) in bannerCards" :key="index">
                    <HomeFrontDoorCard :item='bannerItem'/>
                </div>
            </div>
        </div>
      </div>
    </div>
</template>

<script>
import HomeFrontDoorCard from "@/components/Home/New/HomeFrontDoorCard.vue";
import FrontDoorLoader from "@/components/Home/New/FrontDoorLoader.vue";

export default {
    name: "HomeFrontDoor",
    props: {
    },
    components: {
        HomeFrontDoorCard,
        FrontDoorLoader
    },
    data() {
        return {
            bannerCards: [],
            loading: true
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData() {
            let res = await this.api({
                path: "api/u/home/get-front-door-banner",
            });
            if (res.success) {
                this.bannerCards = res.banner
                this.loading = false;
            }
        },
        getUsername(){
            return this.$store.getters.user.user.first_name
        }
    },
  computed: {
    componentText() {
      return this.getText.dfd;
    },
  }
};
</script>

<style lang="less">
.HomeFrontDoor {
    .home-title-line {
        height: 3px;
        max-width: 140px;
    }

    .home-front-door-card-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: stretch;
        margin: 0 -15px;
        gap: 15px;
    }

    .home-front-door-card-container>* {
        flex: 1 1 25%;
    }
    .home-front-door-card-container.two-column>* {
        flex: 1 1 33.33% ;
    }
    @media only screen and (max-width: 769px){
        .home-front-door-card-container>*{
            flex: 1 1 50% !important;
        }
    }

}
</style>
