<template>
  <div id="PodcastPlayer" :class="{ visable: isVisible, hidden: !isVisible }">
    <div
      class="
        podcast-player-container
        content-container
        d-flex
        flex-row
        justify-content-between
        align-items-center
        pt-5
        pb-3
        px-4
      "
    >
      <audio src="" preload="metadata" id="audio-source"></audio>

      <div
        class="podcast-player-controls align-items-center mr-3 d-none d-md-flex"
      >
        <button class="btn-clear" @click="skipBack()">
          <i
            class="podcast-player-button fas fa-6x fa-angle-left brand-text"
          ></i>
        </button>
        <button class="btn-clear" @click="pauseAudio()" v-if="isPlaying">
          <i class="podcast-player-button fas fa-5x fa-pause brand-text"></i>
        </button>
        <button class="btn-clear" @click="playAudio()" v-else>
          <i class="podcast-player-button fas fa-5x fa-play brand-text"></i>
        </button>
        <button class="btn-clear" @click="skipForward()">
          <i
            class="podcast-player-button fas fa-6x fa-angle-right brand-text"
          ></i>
        </button>
      </div>
      <div class="podcast-player-details w-100">
        <div class="d-flex flex-row align-items-center">
          <div
            class="
              podcast-player-controls
              flex-row
              align-items-center
              mr-3
              d-md-none
            "
          >
            <button class="btn-clear pl-0" @click="skipBack()">
              <i
                class="
                  podcast-player-button
                  fas
                  fa-3x
                  px-0
                  fa-angle-left
                  brand-text
                "
              ></i>
            </button>
            <button class="btn-clear" @click="pauseAudio()" v-if="isPlaying">
              <i
                class="podcast-player-button fas fa-3x fa-pause brand-text"
              ></i>
            </button>
            <button class="btn-clear" @click="playAudio()" v-else>
              <i class="podcast-player-button fas fa-3x fa-play brand-text"></i>
            </button>
            <button class="btn-clear" @click="skipForward()">
              <i
                class="
                  podcast-player-button
                  fas
                  fa-3x
                  px-0
                  fa-angle-right
                  brand-text
                "
              ></i>
            </button>
          </div>
          <h1
            class="
              podcast-player-title
              my-auto
              brand-text
              flex-grow
              text-truncate
              left
            "
          >
            {{podcastTitle}}
          </h1>
          <div class="podcast-player-close d-md-none d-flex ml-auto">
            <button class="btn-clear brand-text pr-0" @click="closePlayer()">
              <i class="fas fa-3x fa-times podcast-player-button ml-3"></i>
            </button>
          </div>
        </div>
        <div
          class="
            podcast-player-timeline-container
            d-flex
            w-100
            flex-row
            align-items-center
          "
        >
          <div class="player-timeline-start">{{ currentTime }}</div>
          <div class="player-timeline w-100">
            <input
              type="range"
              id="seek-slider"
              class="podcast-player-timeline w-100"
              max="100"
              value="0"
              @change="onChange()"
              style="padding: 0px 0px 0px 0px; height: 40px"
            />
          </div>
          <div class="player-timeline-end">{{ endTime }}</div>
        </div>
      </div>
      <div class="podcast-player-close d-none d-md-flex">
        <button class="btn-clear brand-text" @click="closePlayer()">
          <i class="fas fa-times fa-5x podcast-player-button ml-3"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

// @ is an alias to /src
export default {
  name: "PodcastPlayer",
  props: ["src"],

  data() {
    return {
      isVisible: false,
      isPlaying: false,
      title: "",
      currentTime: "0:00",
      endTime: "0:00",
    };
  },
  created() {},
  mounted() {},
  methods: {
    setAudio(queue, position) {
      const self = this;
      this.player.src = queue[position].source;
      this.title = queue[position].title;
      if (this.player.readyState > 0) {
        self.updateDuration();
        self.setSeekerValues();
        this.player.addEventListener("timeupdate", () => {
          self.seeker.value = Math.floor(self.player.currentTime);
          self.currentTime = self.calculateTime(self.seeker.value);
        });
        self.playAudio();
      } else {
        this.player.addEventListener("loadedmetadata", () => {
          self.updateDuration();
          self.setSeekerValues();
          this.player.addEventListener("timeupdate", () => {
            self.seeker.value = Math.floor(self.player.currentTime);
            self.currentTime = self.calculateTime(self.seeker.value);
          });
          self.playAudio();
        });
      }
    },
    pauseAudio() {
      this.player.pause();
      this.isPlaying = false;
    },
    playAudio() {
      this.player.play();
      this.isPlaying = true;
    },
    skipForward() {
      this.player.currentTime += 10.0;
    },
    skipBack() {
      if (this.player.currentTime > 10) {
        this.player.currentTime -= 10.0;
      } else {
        this.player.currentTime = 0;
      }
    },
    closePlayer() {
      document.getElementById("audio-source").pause();
      this.$store.dispatch("setPodcastQueue", []);
      this.isVisible = false;
    },
    onChange(value) {
      let self = this;
      self.player.currentTime = self.seeker.value;
      self.currentTime = self.calculateTime(self.seeker.value);
    },
    setSeekerValues() {
      this.seeker.max = Math.floor(this.player.duration);
    },
    updateDuration() {
      const self = this;
      this.seeker.addEventListener("input", () => {
        self.player.currentTime = self.seeker.value;
        self.currentTime = self.calculateTime(self.seeker.value);
      });
      this.endTime = this.calculateTime(this.player.duration);
    },
    calculateTime(secs) {
      const minutes = Math.floor(secs / 60);
      const seconds = Math.floor(secs % 60);
      const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
      return `${minutes}:${returnedSeconds}`;
    },
  },
  computed: {
    ...mapState(["podcastQueue", "podcastQueuePosition"]),
    player() {
      return document.getElementById("audio-source");
    },
    seeker() {
      return document.getElementById("seek-slider");
    },
    podcastTitle() {
      const limit = 40
      if(this.title.length > limit) {
        return this.title.substring(0,limit) + '...'
      }
      return this.title;
    }
  },
  watch: {
    podcastQueue() {
      if (typeof this.podcastQueue != "undefined") {
        if (this.podcastQueue.length > 0) {
          this.isVisible = true;
          this.setAudio(this.podcastQueue, this.podcastQueuePosition);
        } else {
          this.isVisible = false;
        }
      }
    },
    podcastQueuePosition() {
      if (typeof this.podcastQueue != "undefined") {
        if (this.podcastQueue.length > 0) {
          this.isVisible = true;
          this.setAudio(this.podcastQueue, this.podcastQueuePosition);
        } else {
          this.isVisible = false;
        }
      }
    },
  },
};
</script>
<style lang="less">
.visable {
  display: block;
}
.hidden {
  display: none;
}
#PodcastPlayer {
  position: fixed;
  bottom: 0px;
  left: 0px;
  height: auto;
  width: 100%;
  background: white;
  z-index: 999;
  .podcast-player-controls {
    flex-shrink: 0;
  }
  .podcast-player-button {
    padding: 0 8px;
  }

  .podcast-player-title {
    font-weight: bold;
    font-size: 2.4rem;
    overflow: hidden;
    width: 100%;
  }
  .player-timeline-start {
    min-width: 60px;
    font-size: 1.6rem;
    color: grey;
  }
  .player-timeline-end {
    text-align: center;
    padding-left: 10px;
    font-size: 1.6rem;
    min-width: 60px;
    color: grey;
  }

  .content-container {
    max-width: 1160px;
    margin: auto;
  }
  .btn-clear {
    border: none;
    background-color: transparent;
  }
  .btn-clear:focus {
    border: none;
    outline: none !important;
  }

  // track styling
  input[type="range"]::-moz-range-progress {
    background: #ee3b76;
  }
  input[type="range"]::-webkit-progress-value {
    background: black;
    color: black;
  }

  input[type="range"] {
    height: 39px;
    -webkit-appearance: none;
    width: 100%;
  }
  input[type="range"]:focus {
    outline: none;
  }
  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #ee3b76;
    border-radius: 0px;
    border: 0px solid #010101;
  }
  input[type="range"]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000031;
    border: 3px solid #ee3b76;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    background: #ffffff;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -11.5px;
  }
  input[type="range"]:focus::-webkit-slider-runnable-track {
    background: #ee3b76;
  }
  input[type="range"]::-moz-range-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #ee3b76;
    border-radius: 0px;
    border: 0px solid #010101;
  }
  input[type="range"]::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000031;
    border: 3px solid #ee3b76;
    height: 30px;
    width: 30px;
    border-radius: 30px;
    background: #ffffff;
    cursor: pointer;
  }
  input[type="range"]::-ms-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type="range"]::-ms-fill-lower {
    background: #ee3b76;
    border: 0px solid #010101;
    border-radius: 0px;
    box-shadow: 0px 0px 0px #000000;
  }
  input[type="range"]::-ms-fill-upper {
    background: #ee3b76;
    border: 0px solid #010101;
    border-radius: 0px;
    box-shadow: 0px 0px 0px #000000;
  }
  input[type="range"]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 0px #000031;
    border: 3px solid #ee3b76;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    background: #ffffff;
    cursor: pointer;
  }
  input[type="range"]:focus::-ms-fill-lower {
    background: #ee3b76;
  }
  input[type="range"]:focus::-ms-fill-upper {
    background: #ee3b76;
  }
}
</style>
