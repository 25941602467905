<template>
  <div id="ServicesDisplay" class="py-5" @mouseleave="reset">
      <div v-if="loading"><HomeLoading :mode="'medium'"/></div>
      <div v-else-if="cards.length >= 1">
          <div class="mb-3 sd_container d-flex flex-row justify-content-between align-items-center" style="position: relative">

              <div class="outer-track" ref="outerTrack"  @mousedown="startDragging"  @mousemove="drag" @mouseup="stopDragging" @mouseleave="stopDragging" >
                  <div class="track" ref="track" :style="{width: trackLength}">
                          <div class="track-card" v-for="(card,i) in cards" @mouseover="mouseover(i)" @mouseleave="mouseLeave" :ref="'card-'+i" :class="{ selectedCard : cardSelected === i}" style="position: relative" >
                              <div class="track-card-inner" style="position: relative">
                                  <div class="cardImgHolder">
                                      <div class="cardImg sm-cardImg"
                                           v-bind:style="{ backgroundImage: 'url(' + getSmallImg(card.item_slug) + ')' }"/>
                                      <div class="cardImg lg-cardImg"
                                           v-bind:style="{ backgroundImage: 'url(' + card.featured_img + ')' }"
                                      ></div>
                                  </div>
                                  <div class="cardTextHolder" :class="card.item_slug">
                                      <div class="subtext mr-4">{{getSubText(card.item_slug)}}</div>
                                      <div class="title mr-4 mb-4">{{getTitle(card.item_slug)}}</div>
                                  </div>
                                  <div class="expandedView">
                                      <div class="expandedViewInner">
                                          <div class="expandedViewTitle">{{card.label}}</div>
                                          <div class="expandedBtns">
                                              <div class="moreDetailsBtn" @click="$router.push({name: card.item_slug})">More Details</div>
                                              <img class="expandedPlus" src="/img/home/new/serviceCardPlus.png" alt="plus"/>
                                          </div>
                                          <div class="expandedSubTitle">{{getExpandedSubText(card.item_slug)}}</div>
                                          <div class="expendedDesc">{{card.description}}</div>
                                      </div>

                                  </div>
                              </div>
                          </div>

                  </div>

              </div>
              <div class="left-blur blur"></div>
              <div class="right-blur blur"></div>
              <div class="custom-arrow custom-left" @click="left"><i class="fa-solid fa-chevron-left fa-2xl"></i></div>
              <div class="custom-arrow custom-right" @click="right"><i class="fa-solid fa-chevron-right fa-2xl"></i></div>
          </div>
      </div>
  </div>
</template>

<script>
import HomeLoading from "@/components/Home/New/HomeLoading.vue";

export default {
    name: "ServicesDisplay",
    components: {HomeLoading},
    mounted() {
       this.getData();
    },
    data() {
        return {
            cards: [],
            cardSelected: -1,
            loading: true,
            isDragging: false,
            startClientX: 0,
            scrollLeft: 0,
            expanding: false,
            page: 1,
        };
    },
    computed: {
      trackLength() {
          return ((this.cards.length) * (241 + 10)) - 10 + (803 - 241) + 180 + 'px';
      },
      cardsToDisplay() {
          return (this.$refs.outerTrack.clientWidth - 160) / (241 + 10);
      },
      componentText() {
          return this.getText.dfd;
      },
    },
    watch: {
        cardSelected(val,oldVal) {
            if(val === -1) {
                return;
            }
            const posOfCardStart = 10 + ((val) * (241 + 10));
            const posOfCardEnd = posOfCardStart + 803;
            const trackLength = this.$refs.outerTrack.clientWidth - 160;
            const trackStart = this.$refs.outerTrack.scrollLeft;
            const trackEnd = trackStart + trackLength;
            let scroll = 0;
            if(posOfCardStart < trackStart) {
                scroll = -(trackStart - posOfCardStart);
            }
            if(posOfCardEnd > trackEnd) {
                scroll = (posOfCardEnd - trackEnd);
            }
            console.log(scroll)
            if(scroll !== 0) {
                this.$refs.outerTrack.scrollLeft += scroll;
                // this.scrolledIntoView = true;
                // setTimeout(()=> {
                //     this.scrolledIntoView = false;
                // },1000)
            }
        }
    },
    methods: {
        reset() {
            this.$refs.outerTrack.scrollLeft = 0;
        },
        mouseover(i) {
            this.cardSelected = i;
        },
        mouseLeave() {
            this.cardSelected = -1;
        },
        left() {
            this.$refs.outerTrack.scrollLeft -= 241;
        },
        right() {
            this.$refs.outerTrack.scrollLeft += 241;
        },
        getSmallImg(slug) {
          switch (slug) {
                case 'eap':
                    return 'https://spectrum-life-media.s3.eu-west-1.amazonaws.com/Test/svcs/supportive-people-encouraging-young-woman-during-g-2023-02-02-20-29-05-utc.jpg';
                case 'digitalClinicsGeneric':
                    return 'https://spectrum-life-media.s3.eu-west-1.amazonaws.com/Test/svcs/modern-video-conference-or-online-consultation-wit-2022-12-16-06-41-22-utc.jpg'
                case 'totalMentalHealth':
                    return 'https://spectrum-life-media.s3.eu-west-1.amazonaws.com/Test/svcs/tmh.png'
                case 'beCalm':
                    return 'https://spectrum-life-media.s3.eu-west-1.amazonaws.com/Test/svcs/beCalm.jpg';
                case 'wellbeingSeries':
                    return 'https://healthcoach-media.com/res/FrontDoorBanner/Wellbeing%20Studio.jpg';
                case 'digitalGym':
                    return 'https://spectrum-life-media.s3.eu-west-1.amazonaws.com/Test/svcs/dg-small.jpg'
          }
        },
        getBtnText(slug) {
            switch (slug) {
                case 'eap':
                    return 'See More';
                case 'digitalClinicsGeneric':
                    return 'Book Now'
                case 'totalMentalHealth':
                    return 'Book Now'
                case 'beCalm':
                    return 'Start Now';
                case 'wellbeingSeries':
                    return 'View Courses';
                case 'digitalGym':
                    return 'Start Now'
            }
        },
        getSubText(slug) {
            switch (slug) {
                case 'eap':
                    return 'Call us now';
                case 'digitalClinicsGeneric':
                    return 'Book an appointment'
                case 'totalMentalHealth':
                    return 'Start your Mental Health'
                case 'beCalm':
                    return 'Listen to';
                case 'wellbeingSeries':
                    return 'Watch Now';
                case 'digitalGym':
                    return 'Work-out now in the'
            }
        },
        getExpandedSubText(slug) {
            switch (slug) {
                case 'eap':
                    return 'Call us for';
                case 'digitalClinicsGeneric':
                    return 'Book an appointment'
                case 'totalMentalHealth':
                    return 'Start your Mental Health Journey'
                case 'beCalm':
                    return 'Listen to';
                case 'wellbeingSeries':
                    return 'Watch Now';
                case 'digitalGym':
                    return 'Join a class in our Digital Gym'
            }
        },
        getTitle(slug) {
            switch (slug) {
                case 'eap':
                    return '24/7 Support';
                case 'digitalClinicsGeneric':
                    return 'Digital Clinics'
                case 'totalMentalHealth':
                    return 'Coaching Journey'
                case 'beCalm':
                    return 'Be Calm';
                case 'wellbeingSeries':
                    return 'Wellbeing Series';
                case 'digitalGym':
                    return 'Digital Gym'
            }
        },
        startDragging(event){
            this.isDragging = true;
            this.startClientX = event.clientX;
            this.scrollLeft = this.$refs.outerTrack.scrollLeft;
            setTimeout(()=> {this.isDragging = false},1000)
        },
        drag(event) {
            if(!this.isDragging) return;
            const delta = event.clientX - this.startClientX;
            this.$refs.outerTrack.scrollLeft = this.scrollLeft - delta;
        },
        stopDragging() {
            setTimeout(()=> {this.isDragging = false},1000)
        },
        async getData() {
            let res = await this.api({
                path: "api/u/home/get-front-door-banner",
            });
            if (res.success) {
                this.cards = res.banner.filter(card => card.item_slug !== 'beCalm');
                //this.cards = res.banner.filter(card => card.item_slug === 'digitalGym',);
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="less">
#ServicesDisplay {
  .videoDisplay {
      border-radius: 35px;
  }
  .sd_container {
      width: 98%;
      max-width: 2000px;
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
  }
  .outer-track {
    width: 100%;
    overflow: hidden;
    scroll-behavior: smooth;
    margin-left: 10px;
  }
  .track {
    display: flex;
    cursor: grab;
    user-select: none;
    margin-left: 40px;
    .track-card {
      width: 241px;
      transition: all 1s ease-out;
      border-radius: 20px;
      margin-right: 10px;
      &.selectedCard {
        width: 803px;
      }
      .track-card-inner {
          border-radius: 35px;
          width: 100%;
          height: 384px;

        .cardImgHolder {
            position: relative;
            width: 100%;
            height: 100%;
            .cardImg {
                height: 384px;
                width: 100%;
                border-radius: 35px;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                position: absolute;
                top: 0;
                left: 0;
            }
            .sm-cardImg {
                opacity: 1;
            }
            .lg-cardImg {
                opacity: 0;
                transition: opacity 0.5s ease-in-out;
                transition-delay: 0.5s;
            }
            border-radius: 35px;
            overflow: hidden;
        }
        .cardTextHolder {
            position: absolute;
            font-family: "FilsonPro", sans-serif;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 80%;
            z-index: 2;
            background: linear-gradient(161.38deg, rgba(58, 74, 97, 0) 38.38%, grey 68.64%);
            color: white;
            display: flex;
            border-radius: 0 0 35px 35px;
            align-items: flex-end;
            justify-content: flex-end;
            flex-direction: column;
            .subtext {
                font-size: 12px;
            }
            .title {
                font-size: 22px;
                font-family: FilsonProBold, Arial, Helvetica, sans-serif;
                text-align: right;
            }
            &.selectedCard {
               opacity: 0;
            }
        }
        .expandedView{
          position: absolute;
          z-index: 100;
          top: 0;
          width: 100%;
          transition: all 0.5s linear;
          height: 100%;
           .expandedViewInner {
               font-family: "FilsonPro", sans-serif;
               color: white;
               height: 100%;
               width: 100%;
               position: absolute;
               top: 0;
               * {
                   opacity: 0;
                   transition: opacity 0.5s linear;
                   transition-delay: 0.5s;
               }
               opacity: 0;
               border-radius: 35px;
               background: linear-gradient(83.35deg, #000000 16.67%, rgba(0, 0, 0, 0) 98.17%);
           }
          .expandedViewTitle {
              font-family: FilsonProBold, Arial, Helvetica, sans-serif;
              font-size: 65px;
              color: white;
              margin-top: 100px;
              margin-left: 35px;
          }
          .expandedBtns {
              margin-top: 25px;
              margin-left: 35px;
              margin-bottom: 20px;
          }
          .moreDetailsBtn {
              font-family: "FilsonPro", sans-serif;
              color: white;
              border-radius: 30px;
              font-size: 16px;
              height: 44px;
              line-height: 44px;
              width: 214px;
              margin-right: 30px;
              text-align: center;
              display: inline-block;
              cursor: pointer;
              background: linear-gradient(90deg, #ED3A75 0%, #6A356A 100%), #636363;
          }
          .moreDetailsBtn:hover {
              background: linear-gradient(90deg, #6A356A 0%, #ED3A75 100%), #636363;
          }
          .expandedPlus {
              cursor: pointer;
          }
          .expandedSubTitle {
              margin-left: 35px;
              font-size: 22px;
          }
          .expendedDesc {
              margin-left: 35px;
              font-size: 13px;
              width: 450px;
          }
        }
      }
    }
  }
  .selectedCard {
      .expandedView, .expandedViewInner {
          opacity: 1 !important;
      }
      .expandedViewInner {
          * {
              opacity: 1 !important;
          }
      }
      .cardTextHolder {
          opacity: 0;
      }
      .sm-cardImg {
          opacity: 0 !important;
          transition-delay: 0.5s !important;
      }
      .lg-cardImg {
          opacity: 1 !important;
          transition: none !important;
          transition-delay: 0s !important;
      }
  }
  .digitalClinicsGeneric {
      &.cardTextHolder {
          background: linear-gradient(161.38deg, rgba(58, 74, 97, 0) 38.38%, #3A4A61 68.64%) !important;
      }
      &.card-addition {
          background-color: #3A4A61 !important;
      }
  }
  .eap {
    &.cardTextHolder {
        background: linear-gradient(161.38deg, rgba(58, 74, 97, 0) 38.38%, #8DCFD7 68.64%) !important;
    }
  }
    .digitalGym {
        &.cardTextHolder {
            background: linear-gradient(161.38deg, rgba(52, 29, 79, 0) 38.38%, #341D4F 68.64%) !important;
        }
    }
  .totalMentalHealth {
    &.cardTextHolder {
        background: linear-gradient(161.38deg, rgba(58, 74, 97, 0) 38.38%, #6A356A 68.64%) !important;
    }
  }
  .blur {
      position: absolute;
      height: 100%;
      width: 100px;
      z-index: 100;
      transition: background-color 1s ease;
  }
  .right-blur {
      right: 0;
      top: 0;
      background: rgb(247, 247, 247);
      transition: all 1s ease;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0.0) 5%, rgba(255, 255, 255, 0.7) 35%, rgba(255, 255, 255, 1) 60%);
  }
  .left-blur {
      left: -10px;
      top: 0;
      background: rgb(247, 247, 247);
      transition: all 1s ease;
      background: linear-gradient(270deg, rgba(255, 255, 255, 0.0) 5%, rgba(255, 255, 255, 0.7) 35%, rgba(255, 255, 255, 1) 60%);
  }
  .custom-arrow {
      position: absolute;
      top: 221px;
      width: 50px;
      height: 50px;
      i {
          color: white;
      }
      background-color: rgba(0,0,0,0.3);
      border-radius: 50%;
      z-index: 200;
      display: flex;
      align-items: center;
      justify-content: center;
  }
  .custom-left {
      left: 0;
  }
  .custom-right {
      right: 0;
  }
}
@media only screen and (max-width: 992px) {
    #ServicesDisplay {
        width: 100% !important;
        margin-right: 0 !important;
        padding-right: 0 !important;
        padding-left: 0 !important;
        .outer-track {
            width: 100%;
        }
        .custom-arrow {
            display: none;
        }
        .blur {
            display: none;
        }
    }

}

</style>
