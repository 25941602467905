<template>
  <div id="redirect"></div>
</template>

<script>
export default {
  name: "Redirect",
  mounted() {
    const client = this.$route.params.client;

    if (client === 'hrduo') {
      window.location.href = process.env.VUE_APP_HRDUO_SSO;
    }
  },
};
</script>

<style lang="less"></style>
