<template>
  <b-form-group
    :id="id+'-group'"
    :label="label"
    :label-for="id"
    :class="{required: required}"
  >
    <b-form-textarea
        :id="id"
        v-model="answer"
        :disabled="disabled"
        :rows="rows"
        :class="{incomplete: required && (answer === null || answer === '')}"
    ></b-form-textarea>
  </b-form-group>

</template>

<script>
export default {
  name: "TherapyTextBox",
  props: ["label", "item",'rows', "required","disabled"],
  data() {
    let id = this.genRandonString(10);
    let ans = this.item;
    return {
      id: id,
      answer: ans
    }
  },
  watch: {
    item(val) {
      this.answer = val;
    },
    answer(val) {
      this.$emit('update', val)
    }
  }
}
</script>

<style lang="less">

</style>