<template>
    <div class="bg-white" id="Blank">
        <div class="container py-5">
            <HomeReminders />
            <HomeDigitalClinicsBanner />
            <HomeFrontDoor />
        </div>
        <div class="bg-grey pt-5">
            <HomeFilters />
            <HomeEvents />
            <HomeCardCarousel title="Digital Gym" type="digitalgym"></HomeCardCarousel>
            <HomeCardCarousel title="Sound Space" type="podcasts"></HomeCardCarousel>
            <HomeCardCarousel title="Wellbeing Studio" type="wellbeingstudio"></HomeCardCarousel>
            <HomeCardCarousel title="Helping Others" type="helpingothers"></HomeCardCarousel>

        </div>
    </div>
</template>
<script>
import ItemCard from "@/components/Home/New/ItemCard.vue"
import CategoryCard from "@/components/Home/New/CategoryCard.vue"
import VideoCard from "@/components/Home/New/VideoCard.vue"
import HomeReminders from "@/components/Home/New/HomeReminders.vue"
import HomeCoaching from "@/components/Home/New/HomeCoaching.vue"
import HomeCategories from "@/components/Home/New/HomeCategories.vue"
import HomeVideo from "@/components/Home/New/HomeVideo.vue"
import HomeCardCarousel from "@/components/Home/New/HomeCardCarousel.vue"
import HomeDigitalClinicsBanner from "@/components/Home/New/HomeDigitalClinicsBanner.vue"
import HomeFrontDoor from "@/components/Home/New/HomeFrontDoor.vue"
import HomeFilters from "@/components/Home/New/HomeFilters.vue"
import HomeEvents from "@/components/Home/New/HomeEvents.vue"
import EventBus from "@/helpers/eventbus";

export default {
    components: {
        ItemCard,
        CategoryCard,
        VideoCard,
        HomeReminders,
        HomeCoaching,
        HomeCategories,
        HomeVideo,
        HomeCardCarousel,
        HomeDigitalClinicsBanner,
        HomeFrontDoor,
        HomeFilters,
        HomeEvents,
    },
    name: "Blank",
    data() {
        return {
        };
    },
    mounted() {
        let self = this;
        EventBus.$on("setFilter", function (id) {
            console.log('hello');
            // self.getContent(id);
		});
    },
    beforeDestroy() {

    },
    methods: {
        async getContent(filter_id){
            let self = this;
            let res = await this.api({
                path: "api/u/tagging/get-content",
                tags: [filter_id]
            });
            if (res.success) {
                this.content = res.content;
            }
        },
    },
};
</script>
<style lang="less">
#Blank {
    .home-title {
        font-size: 2.4rem;
        font-family: "FilsonProBold", sans-serif;
        text-align: left;
        margin-bottom: 10px;
    }

    .home-title-underline {
        margin-left: 5px;
        height: 3px;
        width: 50px;
    }
}
</style>