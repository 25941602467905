<template>
  <div id="TherapySelectBackandForward" class="p-5">
    <button class="therapyBtn" @click="$emit('back')">Back</button>
    <button class="therapyBtn" @click="$emit('next')" :disabled="!nextEnabled">Next</button>
  </div>
</template>

<script>
export default {
  name: "TherapySelectBackandForward",
  props: ['nextEnabled'],
  methods: {}
}
</script>

<style lang="less">
#TherapySelectBackandForward {
  display: flex;
  justify-content: space-around;
  .therapyBtn {
    width: 200px !important;
  }
}
</style>