<template>
	<div id="achievements_modal_wrapper">
		<div v-if="loading"></div>
		<div id="modal_content" v-else class="animate__animated animate__fadeIn">
			<div id="all_achievements">
				<div class="p-5">
          <div class="float-right">
            <a @click="closeModal()">
              <i class="fas fa-xmark fa-2xl"></i>
            </a>
          </div>
					<div class="row">
						<div v-for="(a,i) in all_achievements" :key="i" class="col-md-6 px-5 py-2">
							<Badge :badge="a" />
						</div>
					</div>
				</div>
			</div>
			<div class="pb-5 text-center">
				<b-button variant="secondary" class="cta-button" @click="closeModal()">Close</b-button>
			</div>
		</div>
	</div>
</template>
<script>
import Badge from "./Badge.vue";

export default {
	components: {
		Badge
	},
	name: "AllAchievementsModal",
	data() {
		return {
			user_achievements : [],
			all_achievements : [],
			loading : true
		};
	},
	mounted() {
		this.getData()
	},
	beforeDestroy() {
	
	},
	methods: {
		closeModal(){
			this.$bvModal.hide('all_achievements_modal')
		},
		async getData(){
			let params = {
				path: "api/u/coaching/get-all-achievements",
			};
			let res = await this.api(params);
			if (res.success) {
				this.user_achievements = res.user_achievements
				this.all_achievements = res.all_achievements
				this.loading = false
			}
		},
	}
};
</script>
<style lang="less">
#all_achievements_modal {
	.modal-content {
		background-color: transparent;
		border: none;
		padding: 15px;
		border-radius: 15px !important;
		overflow: hidden;
	}
	.modal-body {
		//padding: 15px;
		//border: none;
	}
	.modal-content {
		padding: 0px;
	}
	.modal-footer {
		display: none;
	}
	#achievements_modal_wrapper {
		padding: 15px;
		#modal_content {
			border-radius: 15px !important;
			overflow: hidden;
			background-color: white;
		}
	}
}
</style>