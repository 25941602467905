<template>
  <div id="FamilyMember">
    <div class="delete">
      <i class="fa-solid fa-trash" @click="deleteMember"></i>
    </div>
    <TherapyTextQuestion label="Name" :item="member.name" @update="(data)=> {member.name = data}"/>
    <TherapyDateOfBirth label="Date of Birth" :item="member.date_of_birth" @update="(data)=>{member.date_of_birth = data}"/>
    <TherapyTextQuestion label="Address" :item="member.address" @update="(data)=> {member.address = data}"/>
    <TherapyTextQuestion label="Relationship" :item="member.relationship" @update="(data)=> {member.relationship = data}"/>
    <TherapyTextQuestion label="School/Occupation" :item="member.occupation" @update="(data)=> {member.occupation = data}"/>
    <TherapyChoiceBox label="Parental Responsibility" :item="member.parental_responsibility" :options="['Yes','No']" @update="(data)=> {member.parental_responsibility = data}"/>
  </div>
</template>

<script>
import TherapyTextQuestion from "@/components/TherapyPortal/Questions/TherapyTextQuestion.vue";
import TherapyDateOfBirth from "@/components/TherapyPortal/Questions/TherapyDateOfBirth.vue";
import TherapyChoiceBox from "@/components/TherapyPortal/Questions/TherapyChoiceBox.vue";

export default {
  name: "FamilyMember",
  components: {TherapyChoiceBox, TherapyDateOfBirth, TherapyTextQuestion},
  props: ["item","memberId"],
  data() {
    let id = this.genRandonString(10);
    let ans = this.item;
    return {
      id: id,
      member: ans
    }
  },
  methods: {
    deleteMember() {
      this.$emit('deleteMember')
    }
  }
}
</script>

<style lang="less">
#FamilyMember {
  margin-top: 30px;
  max-width: 500px;
  border-bottom: #eceaea 1px solid;
  position: relative;
  .delete {
    position: absolute;
    top: 0;
    right: 5px;
    cursor: pointer;
    color: darkred;
    font-size: 1.25rem;
  }
}
</style>