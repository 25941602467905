<template>
<div id="TwoFactorLogin">
  <div class="container login">
    <div class="row page-content">
      <div class="col-md-7 login-bg img-part px-0" :class="{'bg-brand-secondary' : skin > 0, 'bg-grad-image-purple' : skin === 0}">
        <div class="login-bg">
          <div class="heart-placeholder">
            <img v-if="hardcoded.spectrum_heart" class="heart-img" :src="hardcoded.spectrum_heart" />
            <img class="heart-img" src="/img/default-asset.svg" v-else/>
          </div>
          <div class="forgot-pass-image"></div>
        </div>
      </div>
      <div class="col-md-5 form min-height">
        <div v-if="hardcoded.login_logos" class="logos d-flex pt-5">
          <div v-for="(logo, i) in hardcoded.login_logos" :key="i" class="d-flex logo px-1">
            <img :src="logo" />
          </div>
        </div>
        <div class="form-content">
          <div
              :class="[
              ($store.getters.client.client_logo.toLowerCase().includes('spectrum-life-logo') ||  $store.getters.client.client_logo.toLowerCase().includes('lloydwhyte')) && skin === 15
                ? 'lw-form-logo'
                : 'form-logo',
            ]"
          >
            <img
                :src="getLogo"
                onerror="javascript:this.src='/img/logo-spectrum-life.svg'"
            />
          </div>
          <h2 class="form-title">
            Enter 6 digit verification code
          </h2>
          <b-form-group
              id="input-group-1"
              :label="'Enter your verification code'"
              label-for="inputEmail"
          >
            <b-form-input
                type="text"
                v-model="code"
                :state="code.length === 6"
                id="inputCode"
                max="6"
                required
                @keydown="editCode"
            ></b-form-input>
          </b-form-group>
          <div col-sm-12 class="submit-form d-flex align-items-center justify-content-center">
            <b-button
                class="cta-button py-3 "
                type="button"
                @click="submitForm"
                :disabled="code.length !== 6"
            >Submit</b-button
            >
          </div>
<!--          <div class="col-12 new-here">-->
<!--            <a class="text-link" @click="resendCode">Resend code</a>-->
<!--          </div>-->
          <div class="col-12 new-here">
            <a class="text-link" @click="$emit('sign-in')">{{componentText.back}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>

import {mapGetters} from "vuex";
import axios from "axios";
import config from "@/config/constants";

export default {
  name: "TwoFactorLogin",
  data() {
    return {
      code: ''
    }
  },
  mounted() {

  },
  methods: {
    editCode(evt) {
      if(isNaN(evt.key) && evt.key !== 'Backspace') {
        evt.preventDefault();
      }
      if(evt.key !== 'Backspace' && this.code.length > 5) {
        evt.preventDefault();
      }
    },
    async submitForm() {
      let payload = {
        token: this.$route.query.oauth,
        code: this.code,
        platform: "web",
      };
      let v3_ready = await this.$store.dispatch("twoFactorSignIn", {
        router: this.$router,
        payload,
      });
      if (v3_ready === false) {
        this.$emit("not-v3");
      }
    },
    async resendCode() {
      const payload = {
        token: this.$route.query.oauth
      }
      let res = await axios.post(
          config.api_env + "/application/api-hc/u/two-factor/resend",
          payload,
          config.options
      );
      if(res.success) {
        await this.$store.dispatch('setWarning', 'Code resent to the mobile device we have on file for you')
      } else {
        await this.$store.dispatch('setWarning', res.data.err)
      }
    }
  },
  computed:{
    ...mapGetters(['hardcoded', 'skin']),
    getLogo(){
      if(this.hardcoded && this.hardcoded.logo){
        return this.hardcoded.logo
      }else return this.$store.getters.client.client_logo
    },
    componentText(){
      return this.getText.notLoggedIn.forgotPassword
    }
  }
}

</script>
<style lang="less">
#TwoFactorLogin {
  #inputCode {
    text-align: center;
  }
}
</style>