<template>
  <div id="LoggedIn" :class="'nav-'+navType">
    <div  class="theme position-relative d-flex" ref="logged_in" v-if="navType === 'versionTwo'">
      <NavigationV2>
        <div class="main">
          <div class="" :class="{ 'no-side-bar': !showSideBar }">
            <ContentRouter />
            <DownloadBanner
                v-if="$store.getters.skin === 8 && $route.name !== 'download'"
            />
            <LayaFooter v-if="$store.getters.skin === 10"></LayaFooter>
            <Footer v-else></Footer>
          </div>
        </div>
      </NavigationV2>
      <PodcastPlayer></PodcastPlayer>
      <CookieBanner></CookieBanner>
    </div>
    <div class="theme position-relative d-flex" ref="logged_in" v-else>
      <div class="aside" v-if="showSideBar">
        <SideNav class="position-fixed"></SideNav>
      </div>
      <div class="main float-lg-right" :class="{ 'no-side-bar': !showSideBar }">
        <b-container fluid class="px-0">
          <Navigation :class="[ $store.getters.skin === 29 ? 'd-flex' : 'd-none d-lg-flex']"></Navigation>
          <MobileNavigation :class="[ $store.getters.skin === 29 ? 'd-none' : 'd-lg-none']"></MobileNavigation>
          <ContentRouter @scroll-to="scrollTo" />
          <DownloadBanner
              v-if="$store.getters.skin === 8 && $route.name !== 'download'"
          />
          <LayaFooter v-if="$store.getters.skin === 10"></LayaFooter>
          <Footer v-else></Footer>
        </b-container>
      </div>
      <PodcastPlayer></PodcastPlayer>
      <CookieBanner></CookieBanner>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavigationV2 from "@/components/NavigationV2.vue";
import TopNav from "@/components/TopNav.vue";
import Navigation from "@/components/Navigation.vue";
import MobileNav from "@/components/MobileNav.vue";
import MobileNavigation from "@/components/MobileNavigation.vue";
import SideNav from "@/components/SideNav.vue";
import ContentRouter from "@/components/ContentRouter.vue";
import Footer from "@/components/Footer.vue";
import DownloadBanner from "@/components/DownloadBanner.vue";
import LayaFooter from "../components/LayaFooter";
import PodcastPlayer from "@/components/SoundSpace/PodcastPlayer.vue";
import CookieBanner from "@/components/CookieBanner.vue";
import EventBus from "@/helpers/eventbus";
import config from "../config/constants";

export default {
  name: "LoggedIn",
  components: {
    LayaFooter,
    TopNav,
    Navigation,
    MobileNav,
    MobileNavigation,
    SideNav,
    ContentRouter,
    Footer,
    DownloadBanner,
    PodcastPlayer,
    CookieBanner,
    NavigationV2
  },
  data() {
    return {};
  },
  created() {
    if (!this.$store.state.user.authenticated) {
      this.$store.dispatch("logout", this.$route);
      this.$router.push("/login").catch((err) => {});
    }
  },
  mounted() {
    this.fetchOAuthParams();
    if(this.$route.name === 'challenges') {
      this.emitChallenges();
    }
    gtag('config', process.env.VUE_APP_GA_TRACKING_ID, {
      'user_id': this.$store.getters.user.user.user_id
    });
  },
  onActive() {},
  onIdle() {
    this.$store.dispatch("logout");
    this.$router.push("/login").catch((err) => {});
  },
  methods: {
    emitChallenges() {
      EventBus.$emit('getChallengesData');
    },
    scrollTo(coordinates) {
      this.$refs.logged_in.parentNode.scrollTop = coordinates;
    },
    getParameterFromURL(paramName) {
        const params = new URLSearchParams(window.location.search);
        return params.get(paramName);
    },
    async fetchOAuthParams() {
      let scope = this.getParameterFromURL('scope');
      let oAuthRedirectGoogle = {
        path: 'api/u/oauth/google/redirect',
        code: this.getParameterFromURL("code"),
        scope: this.getParameterFromURL("scope")
      };

      if (!(oAuthRedirectGoogle.code && oAuthRedirectGoogle.scope)) {
        return;
      }

      try {
        let res = await this.api(oAuthRedirectGoogle);

        if (res.success) {
          if(scope.includes('fitness.activity.read')) {
            this.$router.push("/challenges");
          } else {
            this.$router.push("/");
          }

        } else {
          alert("Error occurred. Please try again.");
        }
      } catch (e) {
        console.log(e);
      }
    },

    getAllowedRoutes() {
      const nav = this.$store.getters.nav;
      let accessValues = {
        wellbeing_series_access: false,
        digital_gym_access: false,
        wellbeing_studio_access: false,
        wellbeing_benefits_access: false,
        my_organisation_access: false,
        eap_access: false,
        events_access: false,
        be_calm_access: false,
        plans_access: false,
      };
      for (const [key, value] of Object.entries(accessValues)) {
        nav.forEach(function (navElement) {
          navElement.items.forEach(function (navItem) {
            //if the formatted nav values and access values match, set access value to true
            if (
              navItem.name.split(" ").join("_").toLowerCase() ===
              key.replace("_access", "")
            ) {
              accessValues[key] = true;
            }
          });
        });
      }
      return accessValues;
    },
  },
  computed: {
    navType() {
      return this.$store.getters.navType;
    },
    theme() {
      let skin = this.$store.getters.client.skin;
      return "theme-" + skin;
    },
    showSideBar() {
      if (
        this.$store.state.accessCode ||
        this.$store.getters.subdomain.toLowerCase().includes("laya-wellbeing")
      ) {
        return false;
      } else return true;
    },
  },
  watch: {
    $store() {
      if (!this.$store.state.user.authenticated) {
        this.$router.push("login").catch((err) => {});
      }
    },
    $route(newVal) {
      if(this.navType === 'versionTwo') {
        document.getElementById('newNavTop').scrollIntoView({ behavior: 'smooth' });
      } else {
        this.scrollTo(0);
      }
      if(newVal.name === 'challenges') {
        this.emitChallenges();
      }
    },
  },
};
</script>
<style lang="less">
@import "../assets/responsiveness";

.aside {
  width: 60px;
  max-width: 60px !important;
}
.main {
  .content-container {
    max-width: 1160px;
    margin: auto;
  }
  .page-bg {
    width: 100%;
    padding-bottom: 30px;
  }
  .bg-white {
    background-color: #fff;
  }
  .bg-grey {
    background-color: #f7f7f7;
  }
  .bg-primary-dark {
    background-color: #3e1954;
  }
  .page-title {
    h2 {
      font-size: 3.8rem;
      letter-spacing: 0.7rem;
      font-family: "FilsonProBold", sans-serif;
    }
  }
  h3 {
    font-size: 2.3rem;
    font-family: "FilsonProBold", sans-serif;
    letter-spacing: 0.3rem;
    padding: 40px 0 30px 0;
  }
  h4 {
    font-family: "DMSans", sans-serif;
    font-size: 1.7rem;
    margin-bottom: 30px;
  }
  .section-header {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    align-items: center;
    flex-direction: row-reverse;
    &.section-header-right {
      flex-direction: row;
      .section-header-title {
        padding-right: 0;
        padding-left: 15px;
        text-transform: capitalize;
      }
    }
    .section-header-title {
      white-space: nowrap;
      padding-right: 10px;
    }
    .section-header-divider {
      height: 1px;
      background: #cdcdcd;
      width: 100%;
      transform: translateY(5px);
    }
  }
}
.pop-up-content {
  background: #fff;
  width: 100%;
  border-radius: 25px !important;
  padding: 30px 20px;
  font-family: "DMSans", sans-serif;
  font-size: 2rem;
  margin-bottom: 30px;
  text-align: center;
  z-index: 10;
  .modal-header {
    text-align: center;
    border-bottom: none;
    .modal-title {
      margin: auto;
      text-align: center;
      font-size: 3rem;
    }
  }
  .modal-footer {
    border-top: none;
    justify-content: center;
    .btn {
      border: none;
      border-radius: 60px;
      font-size: 2rem;
      line-height: 1;
      letter-spacing: 0.2rem;
      border-width: 0;
      padding: 10px 30px;
    }
  }
}
.nav-versionTwo {
  .main {
    width: 100% !important;
    display: inline-block !important;
    background-color: white;
  }
  #Home {
    background-color: white;
  }
}
</style>
