<template >
  <div id="FrontDoorLoading" class="loader" >
    <div class="box-holder row" >
      <div class="col-4 outerBox" >
        <div class="box"></div>
      </div>
      <div class="col-4 outerBox" >
        <div class="box"></div>
      </div>
      <div class="col-4 outerBox" >
        <div class="box"></div>
      </div>
      <div class="col-4 outerBox" >
        <div class="box"></div>
      </div>
      <div class="col-4 outerBox" >
        <div class="box"></div>
      </div>
      <div class="col-4 outerBox" >
        <div class="box"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FrontDoorLoading"
}
</script>

<style lang="less">
#FrontDoorLoading {
  box-sizing: border-box;
  display: flex;
  justify-content: space-evenly;
  width: 100%;

  .box-holder {
    height: 725px;
    width: 100%;
    .outerBox {
      width: 100%;
      height: 50%;
      padding: 20px;
    }
  }
  .box, .line {
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg,
    #f2f2f2,
    #dbdbdb,
    #eaeaea,#f2f2f2);
    animation: gradient 2s ease infinite;
    background-size: 400% 400%;
  }
  .line-holder {
    width: 65%;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
    .line {
      height: 25%;
      width: 100%;
      background-color: black;
    }
  }
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}
</style>