<template>
	<div class="wallet-banner">
		<div class="" v-if="loading"></div>
		<div v-else class="wallet-banner-inner animate__animated animate__fadeIn">
			<div class="row">
				<div class="col-7">
					<div class="saved_card">
						<div v-if="card_type" class="saved_card_inner animate__animated animate__fadeIn">
							<div class="row">
								<div class="col-4">
									<div class="card_icon">
										<img :alt="card_type" :src="'/img/payment-cards-icons/'+card_type+'.svg'" class="img-fluid" />
									</div>
								</div>
								<div class="col-8">
									<div class="card_details">
										<span class="last_four">{{card_last_four}}</span> {{card_exp_date}}
									</div>
								</div>
							</div>
						</div>
					</div>
					<h4 class="balance text-white">
						<span v-if="card_type">Wallet </span>Balance
					</h4>
					<h3 class="balance-amount">
						<animated-number
							:value="amount"
							:formatValue="formatToPrice"
							:duration="300"
						/>
					</h3>
					<br/>
					<h4 class="wallet-id">
						Wallet ID: {{wallet_id}}
					</h4>
				</div>
				<div class="col-5">
					<div class="wallet-right-part">
						<img class="wallet-spectrum-logo" :src="getLogo" />
						<div v-if="link" class="wallet-button">
							<b-button @click="$router.push('/wallet')"  variant="white-outline" class="filson-book shadow">Open Wallet</b-button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import EventBus from "../../../helpers/eventbus";
import axios from "axios";
import config from "../../../config/constants";
import AnimatedNumber from "animated-number-vue";

export default {
	name: "WalletCard",
	props: ['link'],
	components : {
		AnimatedNumber
	},
	data() {
		return {
			loading: true,
			amount: 0,
			currency : 'eur',
			wallet_id : null,
			card_type : null,
			card_last_four : null,
			card_exp_date : null
		}
	},
  computed: {
    getLogo() {
      return this.$store.getters.navType === "versionTwo"? "/img/spectrum_life_logo_dark.png" :"/img/logo-spectrum-life.svg";
    }
  },
	methods: {
		async getWallet() {
			const payload = {
				user_id: this.$store.getters.user_id,
			};
			let res = await axios.post(
				config.api_env + "/application/api/u/wallet/get-wallet",
				payload,
				config.options
			);
			if (res.data.success) {
				this.amount = res.data.amount
				this.currency = res.data.currency
				this.wallet_id = res.data.wallet_id
				this.card_type = res.data.card_type
				this.card_last_four = res.data.card_last_four
				this.card_exp_date = res.data.card_exp_date
				this.loading = false
			}
		},
		load(){
			this.getWallet()
		},
		formatToPrice(value=0) {
			return this.getCurrencyPriceString(this.currency, value, true);
		}
	},
	mounted() {
		this.load();
		const _t = this
		EventBus.$on("loadWallet", function () {
			_t.load();
		});
	},
}
</script>
<style lang="less">
.wallet-banner {
	.wallet-banner-inner {
		background-image: url('/img/digitalClinics/WalletBlob.png');
		background-size: cover;
		background-position: center center;
		background-color: #303f55;
		border-radius: 15px;
		color: white;
		padding: 15px;
		position: relative;
		.saved_card {
			min-height: 5.5rem;
			padding-bottom: 1rem;
			.saved_card_inner {
				.card_icon {
				}
				.card_details {
					padding-top: 3px;
					font-size: 14px;
					.last_four {
						font-family: "FilsonProBold", sans-serif;
					}
				}
			}
		}
    .balance {
      color: white;
    }
		h4.balance {
			margin: 0 0 0 0;
			padding: 0;
			font-family: "FilsonProLight", sans-serif;
			font-size: 1.6rem;
		}
		h3.balance-amount {
			margin: 0;
			padding: 0;
			font-family: "FilsonProBold", sans-serif;
			color: white;
			font-size: 3.5rem;
		}
		h4.wallet-id {
			font-family: "FilsonProLight", sans-serif;
			font-size: 1.2rem;
			margin: 1.9rem 0 0;
		}
		.wallet-right-part {
			text-align: right;
			.wallet-spectrum-logo {
				max-width: 50%;
			}
			.wallet-button {
				position: absolute;
				bottom: 0px;
				right: 15px;
				border: 1px solid white;
				border-radius: 60px;
				.btn {
					color: white !important;
					letter-spacing: normal;
				}
			}
		}
	}
}
</style>
