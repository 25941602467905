<script>
    import {Bar, mixins} from 'vue-chartjs'
    import chartjsPluginAnnotation from 'chartjs-plugin-annotation'

    const { reactiveProp } = mixins


    export default {
        extends: Bar,
        mixins: [reactiveProp],
        props:  {
            chartData: {
                type: Object,
                default: null
            },
            chartOptions: {
                maintainAspectRatio: true,
                responsive: null,
                legend: {
                    display: null
                },
                layout: {
                    padding: {
                        left: null,
                        right: null,
                        top: null,
                        bottom: null
                    },
                },
                scales: {
                    xAxes: [{
                        gridLines: {
                            drawBorder: null,
                            display: null,
                        },
                        ticks: {
                            drawOnChartArea: null,
                            beginAtZero: null,
                            display: null
                        }
                    }],
                    yAxes: [{
                        gridLines: {
                            drawBorder: null,
                            display: null,
                        },
                        ticks: {
                            beginAtZero: null,
                            display: null,
                            suggestedMax: null
                        }
                    }]
                },
                annotation:{
                    annotations: [
                        {
                            type: '',
                            mode: '',
                            scaleID: '',
                            value:  null,
                            borderColor: '',
                            borderWidth:  null,
                            borderDash: [],
                            label: {
                                enabled: null,
                                content: null,
                                position: null,
                                fontFamily: null,
                                backgroundColor: null,
                                fontColor: null,
                                yAdjust: null
                            }
                        }
                    ]
                }
            },
            options: {
                type: Object,
                default: null,
            }
        },
        mounted(){
            this.renderChart(this.chartData, this.options)
        },
        watch:{

        },
        computed:{

        }
    }
</script>