var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-content-center",attrs:{"id":"TrainingPlan"}},[(!_vm.loading)?_c('div',{staticClass:"training-plan-container"},[_c('div',{staticClass:"row h-100"},[_c('div',{staticClass:"\n          training-plan-selection-container\n          bg-brand-primary\n          col-md-4 col-sm-12\n          text-white\n          d-flex\n          flex-column\n          align-items-center\n        "},[_c('div',{staticClass:"\n            training-plans-selection-title\n            text-center text-white\n            mb-5\n            mt-5\n          "},[_vm._v(" "+_vm._s(_vm.componentText.classes)+" ")]),_c('div',{staticClass:"training-plan-selection-items"},[_vm._m(0),_vm._l((_vm.trainingPlan.training_classes),function(planClass,index){return _c('div',{key:index,staticClass:"\n              training-plan-selection-item\n              px-3\n              d-flex\n              flex-row\n              align-items-center\n            ",class:[_vm.currentClassIndex > index ? 'complete' : 'in-progress']},[_c('i',{staticClass:"fa fa-circle py-4",class:{
                'brand-highlight-1': _vm.currentClassIndex === index,
                'training-plan-selection-item-first': index === 0,
                'training-plan-selection-item-last':
                  index === _vm.trainingPlan.training_classes.length - 1,
              },attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"training-plan-selection-item-title py-4",class:{
                'training-plan-selection-item-first': index === 0,
                'training-plan-selection-item-last':
                  index === _vm.trainingPlan.training_classes.length - 1,
              },on:{"click":function($event){return _vm.switchClass(index)}}},[_vm._v(_vm._s(planClass.title))])])}),_vm._m(1)],2)]),_c('div',{staticClass:"col-md-8 col-sm-12"},[_c('div',{staticClass:"training-plan-content-container"},[_c('div',{staticClass:"px-5 mb-5 pb-5"},[_c('div',{staticClass:"training-plan-content-back-container"},[_c('a',{staticClass:"chapters__back-link pb-5 brand-primary",on:{"click":function($event){return _vm.$router.push('/digital-gym?selectedView=%27training_plans%27')}}},[_c('i',{staticClass:"fas fa-arrow-circle-left"}),_vm._v(" Back to Training Plans ")])]),_c('div',{staticClass:"training-plan-content-title-container"},[_c('h2',{staticClass:"training-plan-content-title pb-5"},[_vm._v(" "+_vm._s(_vm.currentClass.title)+" ")])]),(_vm.currentClass.video_url)?_c('div',{staticClass:"training-plan-content-video-container"},[_c('TrainingPlanVideo',{attrs:{"classItem":_vm.currentClass}})],1):_vm._e(),_c('div',{staticClass:"training-plan-content-description brand-text"},[_vm._v(" "+_vm._s(_vm.currentClass.description)+" ")]),_c('div',{staticClass:"training-plan-content-buttons-container d-flex flex-row",class:[
                _vm.currentClassIndex === 0
                  ? 'justify-content-end'
                  : 'justify-content-between' ]},[(_vm.currentClassIndex !== 0)?_c('b-button',{staticClass:"cta-button shadow py-3 px-5 mx-4 my-3 my-md-0",on:{"click":function($event){return _vm.switchClass(_vm.currentClassIndex - 1)}}},[_vm._v(_vm._s(_vm.componentText.buttonPrevious))]):_vm._e(),(
                  _vm.currentClassIndex !==
                  _vm.trainingPlan.training_classes.length - 1
                )?_c('b-button',{staticClass:"cta-button shadow py-3 px-5 mx-4 my-3 my-md-0",on:{"click":function($event){return _vm.switchClass(_vm.currentClassIndex + 1)}}},[_vm._v(_vm._s(_vm.componentText.buttonNext))]):_c('b-button',{staticClass:"cta-button shadow py-3 px-5 mx-4 my-3 my-md-0",on:{"click":function($event){return _vm.finishTrainingPlan()}}},[_vm._v(_vm._s(_vm.componentText.finish))])],1)])])])])]):_c('div',{staticClass:"spinner-container d-flex flex-row align-items-center mt-5"},[_c('div',{staticClass:"mx-auto my-auto"},[_c('b-spinner',{staticClass:"brand-primary"})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"\n              training-plan-selection-item\n              top-cap\n              px-3\n              d-flex\n              flex-row\n              align-items-center\n              border-none\n            "},[_c('i',{staticClass:"fa fa-circle",attrs:{"aria-hidden":"true"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"\n              training-plan-selection-item\n              end-cap\n              px-3\n              d-flex\n              flex-row\n              align-items-center\n              border-none\n              pb-5\n            "},[_c('i',{staticClass:"fa fa-circle",attrs:{"aria-hidden":"true"}})])}]

export { render, staticRenderFns }