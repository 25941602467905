<template>
    <div id="RewardsPopup">
        <div class="pop-up-background" @click="hideExplanationPopup"></div>
        <div class="pop-up-content row px-5" :class="{'bg-grad-image-purple': skin === 0, 'bg-brand-secondary': skin !==0}">
            <div class="col-8 px-3">
                <h3 class="brand-highlight-1">{{componentText.points}}</h3>
            </div>
            <div class="col-4">
                <div class="circle-container">
                    <div class="circle-content">
                        <img src="/img/loggin/stars.svg" />
                    </div>

                    <svg class="circle"
                         width="100%"
                         height="100%"
                         viewBox="0 0 210 220">
                        <defs>
                            <filter id="shadow" x="-100%" y="-100%" width="300%" height="300%">
                                <feGaussianBlur in="SourceAlpha" stdDeviation="6"/>
                                <feOffset dx="0" dy="0" result="offsetblur"/>
                                <feFlood flood-color="#87D2DE"/>
                                <feComposite in2="offsetblur" operator="in"/>
                                <feMerge>
                                    <feMergeNode/>
                                    <feMergeNode in="SourceGraphic"/>
                                </feMerge>
                            </filter>
                        </defs>
                        <circle class="circle__background"
                                cx="100"
                                cy="100"
                                r="90"
                        />
                        <circle stroke-dasharray="400, 560"
                                class="circle__fill"
                                cx="100"
                                cy="100"
                                r="90"
                                style="filter:url(#shadow)"
                        />
                    </svg>
                </div>
            </div>
            <div class="col-12 px-3 api-content">
                <p>{{engagementExplanation.content}}</p>
                <p class="pt-4"><span class="how-to brand-highlight-1">{{componentText.how}}</span></p>
                <p v-for="(activity,i) in engagementExplanation.activities" :key="i" class="px-5">
                    • {{activity.activity}}: <span class="how-to brand-highlight-1">{{activity.points}} pts</span>
                </p>
            </div>
            <b-button class="close-popup" @click="hideExplanationPopup"><font-awesome-icon icon="times-circle"></font-awesome-icon></b-button>
        </div>
    </div>
</template>
<script>
    import axios from "axios";
    import config from "../../config/constants";
    export default {
        name: "RewardsPopup",
        components:{
        },
        data(){
            return {
                engagementExplanation:{}
            }
        },
       async beforeCreate(){
            const payload = {
                user_id: this.$store.getters.user_id
            }
            let res = await axios.post(config.api_env + '/application/api-hc/get-engagement-explanation', payload, config.options)
            if (res.data.success){
                this.engagementExplanation = res.data
            }
        },
        methods:{
            hideExplanationPopup(){
                this.$emit('close')
            }
        },
        computed:{
            skin(){
                return this.$store.getters.skin
            },
            componentText() {
                return this.getText.loggedIn.rewards.rewardsPointsPopup;
            },
        }

    }
</script>
<style lang="less">
    #RewardsPopup{
        display: flex;
        align-items: center;
        z-index: 500;
        position:fixed;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        text-align: center;
        overflow-x: hidden;
        overflow-y: scroll;
        .pop-up-content{
            max-width: 636px;
            width: 100%;
            margin: auto;
            border-radius: 25px;
            padding: 50px 20px;
            font-size: 1.8rem;
            text-align: left;
            z-index: 10;
            color: #fff;
            position: relative;
            api-content{

            }
            p{
                color: #fff;
            }
            h3{
                font-family: FilsonProBold;
                font-size: 3.8rem;
                line-height: 1.25;
                text-align: left;
            }
            .how-to{
                font-size: 2rem;
                font-family: 'FilsonProBold', sans-serif;
            }
            .log-info{
                border-bottom: 1px solid grey;
                :first-child{
                    text-align: left;
                }
                :last-child{
                    text-align: right;
                }
            }
            .close-popup{
                position: absolute;
                top: 0;
                right: 0;
                background-color: transparent;
                width: 50px;
                height:50px;
                svg{
                    width: 30px;
                    height: 30px;
                }
            }
        }
        .circle-container {
            position: relative;
            min-width: 124px;
            width: 124px;
            height: 124px;
            display: flex;
            align-items: center;
            margin-left: auto;
            .circle-content {
                position: absolute;
                width: 100%;
                height: 100%;
                color: #394961;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                text-align: center;
                img{
                    width: 70px;
                    margin:auto;
                    padding-right: 10px;
                }
            }
            .circle-background-dots{
                position: absolute;
                width: 200px;
                height: 160px;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                z-index: -1;
            }
            .circle {
                fill: none;
                stroke-linecap: round;
            }
            .circle__fill {
                stroke-width: 10px;
                transition: 0.4s;
                transform: rotate(-90deg);
                transform-origin: center;
            }
            .circle__background {
                stroke: #cfcfcf;
                stroke-dasharray: 2;
                transform: rotate(-90deg);
                transform-origin: center;
            }
        }
        .pop-up-background{
            width: 100vw;
            height: 100vh;
            position: absolute;
            left: 0;
            right: 0;
            z-index: 5;
            background: transparent;
        }
    }
</style>