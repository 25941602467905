<template>
  <div id="TherapyDateSelect" class="p-5">
    <Loader v-if="loading"/>
    <div v-else-if="!loading && !appointment">
      <div v-if="!showConfirmationPage">
        <div class="question-text">What date suits you best to chat?</div>
        <div v-if="!datesAvailable.length" class="noDates">
          Sorry, there are no dates currently available for this service
        </div>
        <div class="topRow">
          <div>
            <slick id='slick-id' ref="slick" :options="slickOptions" style="max-width: 360px; width: 100%;" class="mx-auto justify-content-start">
              <div v-for="(d,i) in datesAvailable">
                <div  class="date" :key="'card-'+i+d" @click="selectDate(d)" :class="{selected: selectedDate === d}">
                  <div>{{formattedDate(d)}}</div>
                </div>
              </div>
            </slick>
          </div>
        </div>
        <h4 v-if="availableSps.length" class="text-center">Available therapists: </h4>
        <div class="coachHolder mt-5">
          <TherapyOption v-for="(sp) in availableSps"
                         :text="sp.name"
                         :degree="sp.degree"
                         :option-number="sp.id"
                         :selected="sp.id === selectedSpId"
                         :image="sp.image_url"
                         @selectOption="selectCoach"
          />
        </div>
        <div v-if="selectedSpId && selectedDate && availableSlots">
          <div class="question-text mb-2 mt-5">Choose Time</div>
          <div class="slotsHolder mx-auto p-5" >
            <div class="slot" v-for="slot in availableSlots" @click="selectSlot(slot.id)" :class="{selected: slot.id === selectedSlotId}">
              {{formatSlotTime(slot.start)}}
            </div>
          </div>
        </div>
        <div v-if="selectedSpId && selectedDate">
          <button @click="submitTimeslot" class="therapyBtn2 m-auto" :disabled="!selectedSlotId">Next</button>
        </div>
      </div>
      <div v-else class="confirmationHolder">
        <div class="question-text">Appointment Summary</div>
        <p>You have selected: <br>
          <span>{{formattedDate(selectedTsDateText.day)}}</span> at <span>{{selectedTsDateText.time}}</span>
          with <span>{{selectedSp.name}}</span>
        </p>
        <p class="mt-5">By booking an appointment, you agree to the following <br>
          <a class="termsLink" :href="'/therapy-agreement/' + $route.params.slug" target="_blank">terms & conditions.</a></p>

        <p class="mt-5" style="text-decoration: underline">What happens next?</p>

        <ol class="mt-2">
          <li>You'll receive an email booking confirmation and a link to manage your booking.
          </li>
          <li>You will receive your  reminder for your appointment  3 days in advance of your appointment date, this reminder will contain your link to access your appointment session.
          </li>
          <li>5 minutes before your appointment, you should visit your link to make sure everything is working correctly.
          </li>
        </ol>
        <p class="mt-5">If you have any issues or questions, please contact us at <a :href="'mailto:'+contactEmailAddress">{{contactEmailAddress}}</a></p>
        <div class="mt-5">
          <button @click="confirmContinue" class="therapyBtn2 m-auto">Continue</button>
        </div>
      </div>
    </div>
    <div v-else-if="appointment">

      <TherapyPayments :appointment="appointment" @closePayments="" :contact-email-address="contactEmailAddress"/>
    </div>
  </div>
</template>

<script>

import slick from "vue-slick"
import TherapyOption from "@/components/TherapyPortal/Questions/TherapyOption.vue";
import TherapyPayments from "@/components/TherapyPortal/TherapyPayments.vue";
import moment from "moment/moment";
import Loader from "@/components/Loader.vue";
export default {
  name: "TherapyDateSelect",
  props: ['genderOptions'],
  components: {Loader, TherapyPayments, TherapyOption, slick},
  data() {
    return {
      loading: true,
      slickOptions: {
        initialGenderSelected: 'Any',
        initialSlide: 0,
        arrows: true,
        slidesToScroll: 1,
        slidesToShow: 3,
        nextArrow:
            '<div class="slick-arrow-next std-arrow">' +
            '<a class="slick-arrow-container std-arrow-container"><i class="fa-solid fa-caret-right"></i></a>' +
            '</div>',
        prevArrow:
            '<div class="slick-arrow-prev std-arrow">' +
            '<a class="slick-arrow-container std-arrow-container"><i class="fa-solid fa-caret-left"></i></a>' +
            '</div>',
        adaptiveHeight: true,
        infinite: false,
      },
      selectedDate: null,
      selectedGender: 'Any',
      selectedSpId: null,
      selectedSlotId: null,
      sps: [],
      showConfirmationPage: false,
      availableSlots: [],
      appointment: null
    }
  },
  mounted() {
    this.getData();
    this.reInit();
    this.m('start-booking')
  },
  methods: {
    formattedDate(d) {
      const date = moment(d);
      return date.format("DD MMM");
    },
    async getTimeslots() {
      this.availableSlots = [];
      const event_date_id = this.selectedSp.events.filter(ev => ev.date === this.selectedDate);
      const params = {
        event_date_id: event_date_id[0].id,
        path: 'api/u/digital-bookings/cmh-get-timeslots'
      }
      const res = await this.api(params);
      if(res.success) {
        this.availableSlots = res.slots;
      }
    },
    async getData() {
      const params = {
        path: 'api/u/digital-bookings/cmh-service-provider-data',
        gender: this.selectedGender.toLowerCase(),
        slug: this.slug
      }
      const res = await this.api(params);
      if(res.success) {
        this.sps = res.sps;
      }
      this.loading = false;
    },
    confirmContinue() {
      this.m('booking-continue-clicked')
      this.appointment = {
        timeslot: this.selectedTimeslot,
        sp: this.selectedSp
      }
      // this.$emit('setAppointment',{
      //   timeslot: this.selectedTimeslot,
      //   sp: this.selectedSp
      // })
    },
    submitTimeslot() {
      this.m('booking-next-clicked', {
        service: this.slug,
        service_provider_name: this.selectedSp.name,
        service_provider_id: this.selectedSp.id,
        timeslot_time: this.selectedTimeslot.start,
        timeslot_id: this.selectedSlotId
      })
      this.showConfirmationPage = true;
    },
    selectSlot(id) {
      this.selectedSlotId = id;
      this.m('booking-time-chosen',{service: this.slug, service_provider_name: this.selectedSp.name, service_provider_id: this.selectedSp.id, timeslot_time: this.selectedTimeslot.start, timeslot_id: this.selectedSlotId})
    },
    formatSlotTime(t) {
      return t.substring(11,16)
    },
    selectCoach(id) {
      if(this.selectedSpId === id) this.selectedSpId = null;
      this.selectedSpId = id;
      this.m('booking-therapist-chosen',{service: this.slug, service_provider_name: this.selectedSp.name, service_provider_id: this.selectedSp.id})
    },
    selectDate(d) {
      this.selectedDate = d;
      this.m('booking-date-chosen',{date: d, service: this.slug})
    },
    reInit() {
      let self = this;
      if (self.$refs.slick) {
        this.$refs.slick.destroy();
        this.$nextTick(() => {
          self.$refs.slick.create(this.slickOptions);
        });
      }
    },
  },
  watch: {
    showConfirmationPage(val) {
      if(val) {
        this.m('booking-appointment-summary')
      }
    },
    selectedGender() {
      this.getData();
      this.availableSlots = [];
      this.selectedDate = null;
    },
    selectedDate() {
      this.selectedSpId = null;
      this.selectedSlotId = null;
    },
    selectedSpId() {
      this.selectedSlotId = null;
      this.getTimeslots();
    },
    datesAvailable: {
      handler() {
        this.reInit();
      },
      deep: true
    },
    initialGenderSelected(newVal) {
      this.selectedGender = newVal;
    }
  },
  computed: {
    slug() {
      return this.$route.params.slug;
    },
    therapyDetails() {
      return this.getTherapyFromSlug(this.slug);
    },
    contactEmailAddress() {
      return this.therapyDetails.area === 'Neurodiversity' ? 'neurodiversityassessmentbookings@spectrum.life' : 'digitaltherapies@spectrum.life';
    },
    selectedTsDateText() {
      if(this.selectedTimeslot === null) return null;
      return {
        day: this.selectedTimeslot.start.substring(0,10),
        time: this.getFormatedDate(this.selectedTimeslot.start, "h:mm a")
      }
    },
    selectedSp() {
      if(this.selectedSpId === null) return;
      const spIndex = this.sps.findIndex(sp => sp.id === this.selectedSpId);
      return this.sps[spIndex];
    },
    selectedTimeslot() {
      if(this.selectedDate === null || this.selectedSpId === null || this.selectedSlotId === null) return null
      const spIndex = this.availableSlots.findIndex(slot => slot.id === this.selectedSlotId);
      return this.availableSlots[spIndex];
    },
    availableSps() {
      const sps_available = [];
      this.sps.forEach(sp => {
          sp.events.forEach(event => {
            if(event.date.substring(0,10) === this.selectedDate) {
              sps_available.push(sp);
            }
          })
      });
      return sps_available;
    },
    datesAvailable() {
      const dates = [];
      this.sps.forEach(sp => {
          sp.events.forEach(event => {
            if(!dates.includes(event.date)) dates.push(event.date);
          });
      })
      return dates.sort((a, b) => new Date(a) - new Date(b));
    }
  }
}
</script>

<style lang="less">
#TherapyDateSelect {
  .termsLink {
    cursor: pointer;
  }
  .slotsHolder {
    max-width: 400px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    .slot {
      cursor: pointer;
      color: #D60570;
      margin: auto;
      width: 100px;
      height: 50px;
      background-color: white;
      display: flex;
      border: 2px solid #CBCBCB;
      justify-content: center;
      align-items: center;
      font-size: 1.3rem;
      border-radius: 10px;
      font-family: FilsonProBold, Arial, Helvetica, sans-serif;
      &.selected {
        background-color: #D60570;
        color: white;
      }
    }
  }
  .custom-select {
    all: unset;
    .genderFilter {
      margin-left: 15px;
      color: #4CC2F1;
      width: fit-content;
      position: relative;
      z-index: 2;
      background-color: transparent;
      border: none !important;
      &:focus-visible {
        border: none !important;
        outline: none !important;
      }
    }
  }
  .confirmationHolder {
    p {
      margin: auto;
      text-align: center;
    }
    ol {
      margin: auto;
      text-align: justify;
    }
    span {
      font-family: FilsonProBold, Arial, Helvetica, sans-serif;
    }
  }
  .filterRow {
    max-width: 600px;
    font-size: 1.5rem;
  }
  .std-arrow {
    position: absolute;
    padding: 0 !important;
    i {
      color: #D60570;
    }
    &.slick-disabled {
     display: none;
    }
  }
  .slick-arrow-next {
    top: 25px;
    right: -25px;
  }
  .slick-arrow-prev {
    top: 25px;
    left: -25px;
  }
  .noDates {
    text-align: center;
    font-family: FilsonProBold, Arial, Helvetica, sans-serif;
    font-size: 2rem;
    margin-top: 10px;
  }
  .date {
    cursor: pointer;
    margin: auto;
    width: 120px;
    height: 50px;
    border-radius: 20px;
    background-color: white;
    font-size: 1.4rem;
    font-family: FilsonProBold, Arial, Helvetica, sans-serif;
    color: #D60570;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 2px solid #CBCBCB;
    &:hover {
      background-color: darken(white, 1%);
    }
    &.selected {
      background-color: #D60570;
      color: white;
      &:hover {
        background-color: darken(#D60570, 2%);
      }
    }
  }
}
</style>