<template>
    <div id="LiveEvent">
        <section class="spectrum-event bg-grey">
            <section class="spectrum-intro">
                <div class="content-container row event-content py-5">
                    <div class="col-2 return-wrap">
                        <b-button class="return ml-3" @click="goBack()" alt="return to all events">
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                                <g>
                                    <g>
                                        <g>
                                            <path class="circle" fill="#d60570"
                                                d="M24.805 0c13.698 0 24.804 11.105 24.804 24.804 0 13.699-11.106 24.804-24.804 24.804C11.105 49.608 0 38.503 0 24.804 0 11.105 11.106 0 24.805 0z" />
                                        </g>
                                        <g>
                                            <path fill="none" stroke="#f1f1f1" stroke-linecap="round"
                                                stroke-miterlimit="20" stroke-width="2.83"
                                                d="M23.137 13.934v0l-8.908 10.028v0l7.793 10.033v0" />
                                        </g>
                                        <g>
                                            <path fill="none" stroke="#f1f1f1" stroke-linecap="round"
                                                stroke-miterlimit="20" stroke-width="2.83" d="M14.711 23.906h26.893" />
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </b-button>
                    </div>
                    <div class="col-8 event-title pt-5 d-flex flex-column align-items-center">
                        <h2>{{ post.title }}</h2>
                    </div>

                </div>
            </section>
            <section class="bg-white py-5">
                <div class="content-container event-content row py-5" v-if="!loading">


                    <div class="col-12">
                        <div class="event-info">
                            <div v-if="post.format === 'video'">
                              <EventVideo :event="post" class="col-12" />
                            </div>
                            <div v-else>
                                <img class="img-fluid w-100" :src="post.image_url" />
                            </div>
                            <p class="py-5 text-content" v-html="decodeData(post.content)"></p>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    </div>
</template>

<script>

import EventVideo from "../components/LiveEvent/EventVideo";
import config from "@/config/constants";
//TODO:: Consider breaking this into laya / spectrum components
export default {
    name: "CompanyBoardPost",
    components: { EventVideo },
    data() {
        return {
            post: {},
            loading: true,
        };
    },
    mounted() {
        this.getPost();
    },
    methods: {
        goBack() {
          // if(this.$route.query.cat) {
          //   this.$router.push('/company-board/category/'+this.$route.query.cat)
          // } else {
          //   this.$router.push('/company-board/')
          // }
          this.$router.go(-1)
        },
        decodeData(data) {
            try {
                return atob(data);
            } catch (error) {
                return data;
            }
        },

        async getPost() {
            const payload = {
                user_id: this.$store.getters.user_id,
                post_id: this.$route.params.id,
            };
            let res = await this.axios.post(
                process.env.VUE_APP_API_PATH +
                "/application/api/u/company-board/get-post",
                payload, config.options
            );
            if (res.data.success) {
                this.post = res.data.data;
                if(res.data.data.format === 'video') {
                  const link =
                      `<iframe src="${res.data.data.asset_url}" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`
                  this.post.embed_code = btoa(link)
                }
                this.loading = false;
            } else if (res.data.err && res.data.err.includes("3994")) {
                this.$store.dispatch("logout");
                this.$router.push("/login");
            } else {
                this.showMsgModal(
                    "Woops, we couldn't find that post. Please try another!"
                ).then(() => {
                    this.$router.push("/company-board");
                });
            }
        },

    },

};
</script>

<style lang="less">
#LiveEvent {
    font-size: 1.6rem;

    .spectrum-intro {
        .event-title {
            display: flex;
            justify-content: center;
            align-items: center;

            h2 {
                font-family: FilsonProBold, sans-serif;
                letter-spacing: 0;
                font-size: 2.8rem;
                margin-bottom: 0;
            }
        }

        .return-wrap {
            display: flex;
            align-items: center;

            .return {
                position: unset;
            }
        }
    }
    .text-content{
        text-align: left;
        .ql-align-right{
            text-align: right;
        }
        .ql-align-center{
            text-align: center;
        }
        .ql-align-left{
            text-align: left;
        }
    }


    .log-confirmation {
        font-size: 3.3rem;
        font-family: "FilsonProBold", sans-serif;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.8);
        position: fixed;
        top: 0;
        width: 100%;
        text-align: center;
        z-index: 9999;
        padding: 80px 0;
    }

    .calBtn {
        font-size: 1.5rem !important;
    }

    .cta-button {
        padding-top: 12.5px !important;
        padding-bottom: 12.5px !important;
    }

    @media (max-width: 675px) {
        h2 {
            font-size: 3.5rem;
        }

        .event-content {
            .return {
                top: 20px;
                left: 20px;

                svg {
                    height: 30px;
                    width: 30px;
                }
            }
        }
    }
}
</style>
