<template>
	<div class="bg-white p-5">
		<div class="stripe-wrap col-12 px-0">
			<form id="payment-form">
				<div id="payment-element">
					<!--Stripe.js injects the Payment Element-->
				</div>
				<button id="submit">
					<div class="spinner hidden" id="spinner"></div>
					<span id="button-text" class="btn button cta-button px-5 py-3 my-5">Pay now</span>
				</button>
				<div id="payment-message" class="hidden"></div>
			</form>
		</div>
	</div>
</template>
<script>
// parameters added to return url ?payment_intent=pi_3LPRNqG5QBJRW9cP0vWQtGOL&payment_intent_client_secret=pi_3LPRNqG5QBJRW9cP0vWQtGOL_secret_0JDCUJuUw5kfYmArBR9Bhm4O5&redirect_status=succeeded

import EventBus from "@/helpers/eventbus";
import config from "../../config/constants";
import axios from "axios";

export default {
	components: {

	},
	name: "CardPayment",
	data() {
		return {
			formDisabled : false,
			stripe_intent_secret : null
		};
	},
	created() {
		this.loadStripeScript();
	},
	mounted() {
		this.getUrlParams()
	},
	beforeDestroy() {
	
	},
	methods: {
		loadStripeScript() {
			if (!document.getElementById("stripe-script")) {
				let script = document.createElement("script");
				script.type = "text/javascript";
				script.src = "https://js.stripe.com/v3/";
				script.id = "stripe-script";
				document.head.appendChild(script);
			}
			this.$nextTick().then(() => {
				this.checkStripe();
			});
		},
		checkStripe() {
			//check every two seconds to see if the stripe script has loaded and form can be mounted
			if (window.Stripe) {
				this.initStripeForm();
			} else {
				setTimeout(() => this.checkStripe(), 2000);
			}
		},
		async initStripeForm() {
			const payload = {
				user_id: this.$store.getters.user_id,
			};
			let res = await axios.post(
				config.api_env + "/application/api/u/pay/get-item-payment-intent",
				payload,
				config.options
			);
			if(res.data.success){
				console.log(res)
				this.stripe_intent_secret = res.data.client_secret
			} else {
				console.log('whats wrong')
			}
			//create a Stripe client - stripe script is loaded in index.html
			const stripe = window.Stripe(config.stripe_key);
			//create an instance of Stripe elements
			let options = {
				clientSecret : this.stripe_intent_secret
			};
			const elements = stripe.elements(options);
			
			
			const paymentElement = elements.create("payment");
			paymentElement.mount("#payment-element");
			
			const style = {
				base: {
					color: "#32325d",
					fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
					fontSmoothing: "antialiased",
					fontSize: "16px",
					"::placeholder": {
						color: "#aab7c4",
					},
				},
				invalid: {
					color: "#fa755a",
					iconColor: "#fa755a",
				},
			};
			
			// Create an instance of the card Element.
			// let card = elements.create("card", { style: style });
			// // Add an instance of the card Element into the `card-element` <div>.
			// card.mount("#card-element");
			// // Handle real-time validation errors from the card Element.
			// card.addEventListener("change", function (event) {
			// 	let displayError = document.getElementById("card-errors");
			// 	if (event.error) {
			// 		displayError.textContent = event.error.message;
			// 	} else {
			// 		displayError.textContent = "";
			// 	}
			// });
			// Handle form submission.
			// let form = document.getElementById("payment-form");
			// let _this = this;
			// form.addEventListener("submit", function (event) {
			// 	event.preventDefault();
			// 	// _this.$v.$touch();
			// 	if (!_this.formDisabled) {
			// 		_this.formDisabled = true;
			// 		stripe.createToken(card).then(function (result) {
			// 			if (result.error || _this.$v.$invalid) {
			// 				// Inform the user if there was an error.
			// 				let errorElement = document.getElementById("card-errors");
			// 				if (result.error) {
			// 					errorElement.textContent = result.error.message;
			// 				}
			// 				_this.formDisabled = false;
			// 			} else {
			// 				// Send the token to your server.
			// 				_this.submitForm(result.token);
			// 			}
			// 		});
			// 	}
			// });
			
			const form = document.getElementById('payment-form');
			
			form.addEventListener('submit', async (event) => {
				event.preventDefault();
				
				const {error} = await stripe.confirmPayment({
					//`Elements` instance that was used to create the Payment Element
					elements,
					confirmParams: {
						return_url: 'http://localhost:8080/clinics/digital',
					},
				});
				
				if (error) {
					// This point will only be reached if there is an immediate error when
					// confirming the payment. Show error to your customer (for example, payment
					// details incomplete)
					const messageContainer = document.querySelector('#error-message');
					messageContainer.textContent = error.message;
				} else {
					// Your customer will be redirected to your `return_url`. For some payment
					// methods like iDEAL, your customer will be redirected to an intermediate
					// site first to authorize the payment, then redirected to the `return_url`.
				}
			});
			
			
			
			
		},
	},
	props : ['product', 'items', 'return_url'],
	// inject : ['alert'],
	// provide: function () {
	// 	return {
	// 		// alert : this.a
	// 	}
	// },
};
</script>
<style lang="less">

</style>
