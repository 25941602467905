<template>
  <b-form-group
      :id="id + '-group'"
      :label="label"
      :label-for="id"
      :class="{required: required}"
  >
    <b-form-input
        :disabled="disabled"
        type="text"
        :id="id"
        v-model="answer"
        :class="{disabled: disabled, incomplete: incomplete}"
    />
  </b-form-group>
</template>

<script>


export default {
  name: "TherapyTextQuestion",
  props: ["label","item","disabled","required"],
  data() {
    let id = this.genRandonString(10);
    let ans = this.item;
    return {
      id: id,
      answer: ans
    }
  },
  computed: {
    incomplete() {
      return this.required && !this.item;
    }
  },
  watch: {
    item(val) {
      this.answer = val;
    },
    answer(val) {
      this.$emit('update',val)
    }
  }
}
</script>

<style scoped lang="less">

</style>