<template>
  <div id="TherapySignIn">
    <h2 class="mt-3 mb-3">Login</h2>
    <div v-if="!emailAndPasswordVerified">
      <p>Login to access therapy sessions <br> <a href="#" @click="$emit('changeMode')">New here? Click here to signup</a></p>
      <hr />
      <b-form class="my-3">
        <b-form-group
            id="emailGroup"
            label="Email"
            label-for="email"
        >
          <b-form-input
              type="text"
              id="email"
              v-model="email"
              @focus="showLoginError = false"
          />
        </b-form-group>
        <b-form-group
            id="passwordGroup"
            label="Password"
            label-for="password"
        >
          <b-form-input
              type="password"
              id="password"
              v-model="password"
              @focus="showLoginError = false"
          />
        </b-form-group>
        <div class="errorMessage px-5" v-if="showLoginError">
          Sorry, but that email address or password was not valid.  Please try again.  Please note, 5 failed attempts will result in your account being temporarily locked
        </div>
        <a class="mt-1 forgotPw" @mousedown="pwReset">Forgot Password. Click here</a>
      </b-form>
      <hr class="mt-5">
      <button class="therapyBtn mt-5 mx-auto" :disabled="initialLoginButtonDisabled" @click="signUp">Login</button>
    </div>
    <div v-else-if="firstTimeUser">
      <p>Looks like you’re accessing this service for the first time, input the below information to continue:</p>
      <hr>
      <b-form-group
          id="codeGroup"
          label="Therapy Code"
          label-for="code"
      >
        <b-form-input
            type="text"
            id="code"
            v-model="code"
            @focus="therapyCodeError = null"
        />
      </b-form-group>
      <div class="errorMessage px-5" v-if="therapyCodeError">
        {{therapyCodeError}}
      </div>
      <hr class="mt-4">
      <button class="therapyBtn mt-4 mx-auto" :disabled="codesButtonDisabled" @click="verifyCodes">Login</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "TherapySignIn",
  data() {
    return {
      code: '',
      email: '',
      password: '',
      emailAndPasswordVerified: false,
      firstTimeUser: true,
      user: {},
      showLoginError: false,
      therapyCodeError: null,
    }
  },
  methods: {
    pwReset(e) {
      e.preventDefault();
      this.$emit('resetPassword');
    },
    isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    async signUp() {
      try {
        this.showLoginError = false;
        const res = await this.api({
          path: 'api/u/cmh/authenticate',
          username: this.email,
          password: this.password,
          platform: 'web'
        });
        if(res.authenticated) {
          this.m('login', {status: 'successful'})
          await this.$store.dispatch('setTherapyUser', {
            user: res.user
          });
        } else {
          this.showLoginError = true;
        }
      } catch (e) {
        this.showLoginError = true;
      }
    },
    async verifyCodes() {
      this.therapyCodeError = null;
      const res = await this.api({
        path: 'api/u/cmh/validate-therapy-code',
        therapy_code: this.code,
        user_id: this.user.user_token
      });
      if(res.success) {
        await this.$store.dispatch('setTherapyUser', {
          user: this.user,
          therapy: {
            age_group: res.therapy.age_group,
            area: res.therapy.area
          }
        })
      } else {
        this.therapyCodeError = res.error
      }
    }
  },
  computed: {
    codesButtonDisabled() {
      return !this.code;
    },
    initialLoginButtonDisabled() {
      return !this.password || !this.isValidEmail(this.email);
    }
  },
  mounted() {
    this.m('login', {status: 'start'})
  }
}
</script>
<style  lang="less">
#TherapySignIn {
  .forgotPw {
    font-family: DMSans, Arial, Helvetica, sans-serif;
    font-size: 1.5rem;
  }
  .errorMessage {
    text-align: center;
    font-family: FilsonProBold, Arial, Helvetica, sans-serif;
    margin-top: 20px;
    color: darkred;
    font-size: 1.35rem;
  }
}
</style>