<template>
  <div id="FeaturesCarouselCard" @mouseover="showVideo = true" @mouseleave="showVideo = false">
    <div class="w-100 h-100 cardInner">
      <div class="textContainer">
        <div class="title">{{event.title}}</div>
        <div class="subtitle" v-if="author">{{'With '+author.firstname+' '+author.lastname+', '+author.title}}</div>
        <div class="included">Included in <span>Wellbeing<sup>+</sup></span></div>
        <div class="playRow">
          <div class="playReminder">
            <i class="fa-solid fa-circle-play playBtn" @click="goToEvent" v-if="status !== 'not_started'"></i>
            <div v-else class="reminderBtn" style="position: relative" @click="remindMe">
              <span v-if="loading" class="button-loading"></span>
              <span v-else>{{isReminderSet ? "Cancel Reminder" : "Set Reminder" }}</span>
            </div>
          </div>

          <div class="actionText mr-5" @click="goToEvent">{{ status !== 'not_started' ? 'Play Now' : 'More Details'}}</div>
          <i :id="'myListFeatures-'+event.id" class="fa-solid iconBtn" :class="isItemInMyList ? 'fa-circle-check plus':'fa-circle-plus plus'" @click="watchLater"></i>
          <b-tooltip :target="'myListFeatures-'+event.id" triggers="hover" placement="top" custom-class="mylist-tooltip">
            {{isItemInMyList ? 'Remove from My List' : 'Add to My List'}}
          </b-tooltip>
          <i class="fa-solid fa-circle-info info iconBtn" @click="goToEvent"></i>
        </div>
      </div>
      <div class="videoContainer" :class="{hideVideo: hideVideo}">
        <div ref="player"></div>
      </div>
      <div class="backgroundContainer"
           v-bind:style="{ backgroundImage: 'url(' + event.promo_banner_image + ')' }"
      ></div>
    </div>
  </div>
</template>

<script>
import moment from "moment/moment";
import EventBus from "@/helpers/eventbus";
import Vimeo from "@vimeo/player";
import config from "@/config/constants";

export default {
  name: "FeaturesCarouselCard",
  props: ["event", "reminders"],
  data() {
    return {
      loading: false,
      vimeoId: 0,
      showVideo: false,
      width: 0,
      hideVideo: true
    }
  },
  mounted() {
    this.extractVimeoId();
    this.getDimensions();
    window.addEventListener('resize', this.getDimensions);
    EventBus.$on("sideNavExpanded",(expanded) => {
      setTimeout(()=> {
        this.getDimensions()
      }, 2000)
    })
  },
  destroyed() {
    window.removeEventListener('resize', this.getDimensions);
  },
  watch: {
    reminders() {
      setTimeout(()=> {
        this.loading = false;
      }, 500)
    },
    showVideo(bool) {
      if(bool) {
        this.initVideo()
      } else {
        if(this.player) {
         this.player.destroy();
        }
      }
    }
  },
  computed: {
    product_id () {
      return this.event.schedule_type === 'studio' ? 21 : 22;
    },
    isItemInMyList() {
      const isInList = this.$store.getters.myList.filter(item => item.product_id === this.product_id && item.content_id === this.event.id);
      return isInList.length > 0;
    },
    vimeoHeight() {
      if(this.width < 768) return 300;
      if(this.width < 1525) return 500;
      return 650;
    },
    author() {
      if(!this.event.hasOwnProperty('author')) return null;
      if(typeof this.event.author === 'string') return JSON.parse(this.event.author);
      return this.event.author;
    },
    componentText() {
      return this.getText.dfd;
    },
    status() {
      const start = moment(this.event.start);
      const end = moment(this.event.end);
      const now = moment();
      if(now.isBefore(start)) return 'not_started';
      if(now.isAfter(end)) return 'ended';
      return 'live';
    },
    isReminderSet() {
      let reminderSet = false;
      this.reminders.forEach(reminder => {
        if((reminder.type === "ws" || reminder.type === "ws") && reminder.id === this.event.id) reminderSet = true;
      })
      return reminderSet
    }
  },
  methods: {
    async watchLater() {
      if(!this.isItemInMyList) {
        await this.$store.dispatch('addMyListItem',({media_type: 'video', product_id: this.product_id, content_id: this.event.id}));
      } else {
        const myListRecord = this.$store.getters.myList.filter(item => item.product_id === this.product_id && item.content_id === this.event.id);
        await this.$store.dispatch('deleteMyListItem',({id: myListRecord[0].id}));
      }
    },
    getDimensions() {
      this.width = document.documentElement.clientWidth;
    },
    initVideo() {
      this.hideVideo = true;
      if (this.player) this.player.destroy();
      if (this.vimeoId === 0) {
        return;
      }
      this.player = new Vimeo(this.$refs.player, {
        id: this.vimeoId,
        height: this.vimeoHeight,
        autoplay: false,
        controls: false,
        byline: false,
        title: false,
        portrait: false

      });
      this.player.ready().then(()=> {
        this.player.setCurrentTime(65);
        this.player.setMuted(true);
        this.player.play();
        setTimeout(()=> {this.hideVideo = false},250)
      });

    },
    extractVimeoId() { // Check if URL is a Vimeo URL
      if(this.event.embed_video && !this.event.embed_video.includes('vimeo')) {
        this.vimeoId = 0;
      } else {
        if (!/^https?:\/\/(?:www\.)?vimeo\.com\/\d+(?:\?|$)/i.test(this.event.embed_video)) this.vimeoId = 0;
        const match = /[0-9]+/.exec(this.event.embed_video);
        if (!match) {
          // this.vimeoId = 0;
        }
        this.vimeoId = match[0];
      }
    },
    async remindMe() {
      this.loading = true;
      const payload = {
        user_id: this.$store.getters.user_id,
        event_id: this.event.id,
      };
      if (!this.isReminderSet) {
        let res = await this.axios.post(
            process.env.VUE_APP_API_PATH +
            "/application/api/u/studio/register-reminder",
            payload, config.options
        );
        if (res.data.success) {
          EventBus.$emit('updateDFDReminders');
        } else {
          this.loading = false;
        }
      } else {
        let res = await this.axios.post(
            process.env.VUE_APP_API_PATH +
            "/application/api/u/studio/cancel-reminder",
            payload, config.options
        );
        if (res.data.success) {
          EventBus.$emit('updateDFDReminders');
        } else {
          this.loading = false;
        }
      }
    },
    goToEvent(){
      switch(this.event.schedule_type){
        case 'studio':
          this.$router.push('/wellbeing-studio/' + this.event.id + '?back=home');
          break;
        case 'gym':
          this.$router.push('/digital-gym/' + this.event.id + '?back=home');
          break;
      }
    },
  }
}
</script>

<style lang="less">
#FeaturesCarouselCard {
  overflow: hidden;
  height: 500px;
  transition: width 0.2s linear;
  .hideVideo {
    opacity: 0;
    transition: opacity 0.75s linear;
  }
  .cardInner {
    margin: auto;
    position: relative;
    max-width: 2000px;
    min-height: 500px;
    background: linear-gradient(90deg, #112231 40%, rgba(0, 0, 0, 0) 100%);
    .textContainer {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 60%;
      padding-left: 5%;
      color: white;
      z-index: 100;
      .title {
        font-size: 38px;
        font-family: FilsonProBold, Arial, Helvetica, sans-serif;
        margin-top: 80px;
      }
      .subtitle {
        font-size: 20px;
        width: 50%;
      }
      .included {

        font-size: 18px;
        margin-top: 40px;
        margin-bottom: 30px;
        font-family: FilsonProBold, Arial, Helvetica, sans-serif;
        span {
          color: #87D2DD;
        }
      }
      .playRow {
        font-size: 28px;
        font-family: FilsonProBold, Arial, Helvetica, sans-serif;
        display: flex;
        align-items: center;
        justify-content: start;
        margin-top: 20px;
        .plus {
          //margin-left: 40px;
        }
        .actionText {
          cursor: pointer;
        }
        .plus, .info {
          font-size: 40px;
          color: grey;
          margin-right: 20px;
          cursor: pointer;
        }
      }
      .playBtn {
        cursor: pointer;
        font-size: 60px;
        background-clip: text;
        color: transparent;
        margin-right: 30px;
      }
    }
    .backgroundContainer {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      position: absolute;
      top: 0;
      left: 40%;
      width: 60%;
      height: 100%;
      z-index: -2;
    }
    .videoContainer {
      overflow: hidden;
      position: absolute;
      top: 0;
      right: 0;
      aspect-ratio: 16 / 9;
      height: 100%;
      display: flex;
      z-index: -1;
    }
    .reminderBtn {
      font-size: 1.5rem;
      margin-right: 30px;
      height: 43px;
      padding: 10px 20px;
      border-radius: 25px;
      text-align: center;
      width: 175px;
      cursor: pointer;
      background: linear-gradient(90deg, #ED3A75 0%, #B150B1 100%);
      &:hover {
        background: linear-gradient(90deg, #B150B1 0%, #ED3A75 100%);
      }
    }
    .button-loading::after {
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      border: 4px solid transparent;
      border-radius: 50%;
      border-top-color: white;
      border-bottom-color: white;
      animation: button-loading-spinner 1s ease infinite;
    }
  }
  @media only screen and (max-width: 1000px) {
    .textContainer {
      width: 100% !important;
      text-align: center !important;
    }
    .playRow {
      justify-content: center !important;
    }
    .subtitle {
      width: 100% !important;
    }
  }
  @media only screen and (max-width: 767px) {
    height: 400px;
    .textContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .title {
      margin-top: 20px !important;
      font-size: 22px !important;
    }
    .subtitle {
      font-size: 16px !important;
      line-height: 18px !important;
    }
    .playRow {
      display: grid !important;
      grid-template-rows: 1fr;
      grid-template-columns: 1fr 1fr;
      .iconBtn {
        display: none;
      }
      .actionText {
        display: none !important;
      }
      .playReminder {
        grid-column: 1 / span 2;
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        margin-bottom: 15px;
        * {
          margin: 0 !important;
        }
      }
    }
  }

  //vimeo margins
  @media only screen and (min-width: 1525px) {
    .videoContainer {
      top: -75px;
      width: 1155px;
    }
  }
}
</style>