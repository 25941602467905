<template>
        <div class="insights-intro" :class="{'bg-brand-secondary': skin === 8, 'bg-transparent': skin !==8 }">
            <div class="content-container row align-items-center intro-content">
                <div class="col-12 intro-card my-5">
                    <div class="insights-logo mb-5 pb-5 ">
                        <h2>{{$route.meta.title ? $route.meta.title : componentText.title}}.</h2>
                    </div>
                    <div class="insights-illustration" v-if="hardcoded.illustration_insights">
                        <img :src="hardcoded.illustration_insights">
                    </div>
                    <div class="intro-text">
                        <span class="bold">{{componentText.title}}</span> {{componentText.intro}}
                    </div>
                    <div class="start-button mt-5 pt-5">
                        <b-button @click="$emit('start')" class="px-4 py-3">{{componentText.start}}</b-button>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
    // @ is an alias to /src
    import axios from "axios";
    import config from "../../config/constants";
    import {mapGetters} from "vuex"
    export default {
        name: "InsightsIntro",
        computed:{
            ...mapGetters(['hardcoded', 'skin']),
            componentText() {
                return this.getText.loggedIn.insights;
            },
        }
    };
</script>
<style lang="less">
    #Insights {
        .insights-intro {
            position: relative;
            display:flex;
            justify-content: center;
            align-items: center;
            min-height: calc(100vh - 200px);
            //IE styles
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                .content-container{
                    justify-content: center;
                }
            }
            .intro-card {
                max-width: 600px;
                width: 600px;
                position: relative;
                text-align: center;
                .insights-logo {
                    text-align: center;
                    margin: auto;
                    max-width: 350px;
                    width: 100%;
                    position: relative;
                    img {
                        width: 100%;
                    }
                    h2{
                        display: inline-flex;
                        font-family: FilsonProBold, sans-serif;
                        font-size: 7.6rem;
                        color: #fff;
                        i{
                            font-size: 3rem;
                        }
                    }
                }
                .insights-illustration{
                    width: 200px;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: auto;
                    img{
                        width: 100%;
                    }
                }
                .intro-text {
                    font-size: 22px;
                    letter-spacing: 0;
                    font-family: 'DMSans', sans-serif;
                    color: rgba(255, 255, 255, 0.7);
                    max-width: 350px;
                    width: 100%;
                    text-align: center;
                    margin:auto;
                    .bold {
                        font-family: FilsonProBold;
                        color: #fff;
                    }
                }
                .start-button {
                    text-align: center;
                    .btn {
                        font-family: DMSans;
                        border: 1px solid #fff;
                        background-color: transparent;
                    }
                }
                .stars {
                    position: absolute;
                    width: 25%;
                    top: 0;
                }
                .stars-left {
                    left: 0;
                }
                .stars-right {
                    right: 0;
                }
            }
        }
    }
</style>
