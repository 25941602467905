<template>
  <div id="Community">
    <section class="header-container">
      <div
        class="flex-row d-flex content-container align-items-center h-100 w-100"
      >
        <div class="header-text d-flex">
          <div class="row d-lg-none">
            <img
              class="header-small-image"
              src="/img/SpectrumHeartHeadphones.png"
              alt="Headphones icon"
            />
          </div>
          <h1
            class="header-title brand-text"
            v-html="
              'Welcome to your <br /><strong>Health & Wellness Community</strong>'
            "
          ></h1>
        </div>
        <div class="header-image d-none d-lg-block">
          <img
            class="img-fluid"
            src="/img/SpectrumHeartHeadphones.png"
            alt="Headphones"
          />
        </div>
      </div>
    </section>
    <section id="community-container" class="community-container">
      <div class="content-container row">
        <div id="post-container" class="col-sm-12 col-md-8">
          <div class="pr-md-5 pr-sm-0">
            <div id="create-post-container">
              <div v-if="this.user === null">
                <div class="d-flex flex-row spinner-container">
                  <div class="mx-auto my-auto">
                    <b-spinner class="brand-primary posts-spinner"></b-spinner>
                  </div>
                </div>
              </div>
              <NewPost v-bind:user="this.user" v-bind:topics="this.topics" v-else></NewPost>
            </div>
            <hr class="py-3" />
            <div class="posts-container">
              <div
                class="d-flex flex-row spinner-container"
                v-if="isLoadingPosts"
              >
                <div class="mx-auto my-auto">
                  <b-spinner class="brand-primary posts-spinner"></b-spinner>
                </div>
              </div>
              <div
                v-for="(post, index) in posts"
                :key="post.id"
                class="pb-5 post-container"
              >
                <Post v-bind:post="post" v-bind:user="user"></Post>
              </div>
              <div
                class="
                  load-more-posts-container
                  brand-highlight-1
                  clickable
                  pb-5
                "
                v-if="!isLoadingMorePosts"
              >
                <div @click="getMorePosts()" v-if="morePossiblePosts">
                  Load More Posts
                </div>
                <div v-else>No more posts available!</div>
              </div>
              <div v-else>
                <div class="d-flex flex-row spinner-container">
                  <div class="mx-auto my-auto">
                    <b-spinner class="brand-primary posts-spinner"></b-spinner>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="sidebar-container"
          class="sidebar-container col-sm-12 col-md-4"
        >
          <div class="pl-md-5 pl-sm-0">
            <div class="community-topics-selector-container">
              <div class="community-topics-header bg-brand-secondary">
                Topics Feed
              </div>
              <div
                class="
                  community-topics-selectors
                  d-flex
                  flex-column
                  align-items-center
                  border-brand-highlight-1
                  brand-secondary
                "
              >
                <button
                  class="btn shadow border-brand-hilight-1 px-5 py-3 my-4"
                  :class="[
                    selectedTopics.length === 0
                      ? 'cta-button'
                      : 'button-outline',
                  ]"
                  @click="toggleTopic(null)"
                >
                  General
                </button>
                <button
                  class="btn shadow border-brand-highlight-1 px-5 py-3 my-4"
                  :class="[
                    selectedTopics.includes(topic.id)
                      ? 'cta-button'
                      : 'button-outline',
                  ]"
                  v-for="topic in topics"
                  :key="topic.id"
                  @click="toggleTopic(topic.id)"
                >
                  {{ topic.title }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <NewMediaPost v-if="mediaModalIsVisible" v-bind:topics="this.topics"></NewMediaPost>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import config from "../config/constants";
import Post from "../components/Community/Post.vue";
import NewPost from "../components/Community/NewPost.vue";
import NewMediaPost from "../components/Community/NewMediaPost.vue";

export default {
  name: "Community",
  components: {
    NewMediaPost,
    NewPost,
    Post,
  },
  data() {
    return {
      posts: [],
      user: null,
      isLoadingPosts: false,
      isLoadingMorePosts: false,
      isLoadingTopics: false,
      topics: [],
      selectedTopics: [],
      currentPage: 1,
      mediaModalIsVisible: false,
      morePossiblePosts: true,
    };
  },
  mounted() {
    this.getUserProfile();
    this.getTopics();
    this.getPosts();
  },
  methods: {
    async getUserProfile() {
      const payload = {
        user_id: this.$store.getters.user_id,
        profile_id: false,
      };
      let res = await axios.post(
        config.api_env + "/application/api/u/community/get-user-profile",
        payload,
        config.options
      );
      if (res.data.success) {
        this.user = res.data.profile;
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login").catch((err) => {});
      }
    },

    async getPosts() {
      this.isLoadingPosts = true;
      const payload = {
        user_id: this.$store.getters.user_id,
        topics: this.selectedTopics,
        page: this.currentPage,
      };
      let res = await axios.post(
        config.api_env + "/application/api/u/community/get-posts",
        payload,
        config.options
      );
      if (res.data.success) {
        this.posts = res.data.posts;
        this.isLoadingPosts = false;
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login").catch((err) => {});
      }
    },
    async getTopics() {
      this.isLoadingTopics = true;
      const payload = {
        user_id: this.$store.getters.user_id,
      };
      let res = await axios.post(
        config.api_env + "/application/api/u/community/get-topics",
        payload,
        config.options
      );
      if (res.data.success) {
        this.topics = res.data.topics;
        this.isLoadingTopics = false;
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login").catch((err) => {});
      }
    },
    async getMorePosts() {
      this.isLoadingMorePosts = true;
      const payload = {
        user_id: this.$store.getters.user_id,
        topics: this.selectedTopics,
        page: this.currentPage + 1,
      };
      let res = await axios.post(
        config.api_env + "/application/api/u/community/get-posts",
        payload,
        config.options
      );
      if (res.data.success) {
        if (res.data.posts.length === 0) {
          this.morePossiblePosts = false;
        }
        this.posts = this.posts.concat(res.data.posts);
        this.currentPage++;
        this.isLoadingMorePosts = false;
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login").catch((err) => {});
      }
    },
    toggleTopic(id) {
      if (id === null) {
        return (this.selectedTopics = []);
      } else {
        this.selectedTopics = [id];
      }
    },
    removePost(id, index) {
      this.posts.splice(index, 1);
    },
    openMediaModal() {
      this.mediaModalIsVisible = true;
    },
    closeMediaModal() {
      this.mediaModalIsVisible = false;
    },
  },
  computed: {
    componentText() {
      return this.getText.loggedIn;
    },
  },
  watch: {
    selectedTopics: function(){
      this.posts = [];
      this.currentPage = 1;
      this.getPosts();
    },
  },

};
</script>
<style lang="less">
#Community {
  .header-container {
    height: 250px;
    background-color: #fafafa;
    padding: 50px 0;
    display: flex;
    .header-text {
      min-width: 70%;
      max-width: 900px;
      padding: 0 30px;
      .header-title {
        font-size: 4.6rem;
        padding-bottom: 20px;
      }
      .header-content {
        font-size: 1.6rem;
      }
      .header-small-image {
        max-width: 70%;
        margin-left: auto;
        margin-right: auto;
        @media screen and (-ms-high-contrast: active),
          (-ms-high-contrast: none) {
          max-height: 550px;
        }
      }
    }
    .header-image {
      height: auto;
      max-width: 250px;
    }
  }
  .community-container {
    background-color: #eee;
    padding-top: 60px;
    .sidebar-container {
      .community-topics-selector-container {
        .community-topics-header {
          padding: 15px 10px;
          font-weight: bold;
          text-align: center;
          color: white !important;
          font-size: 2rem;
          border-radius: 16px 16px 0 0;
        }
        .community-topics-selectors {
          background-color: white;
          padding: 20px 30px;
          border-radius: 0 0 16px 16px;
          .btn {
            width: 100%;
          }
        }
      }
    }
  }
  .load-more-posts-container {
    text-align: center;
    font-size: 1.6rem;
    font-weight: bold;
  }
  .spinner-container {
    height: 200px;
    margin-bottom: 20px;
    .posts-spinner {
      height: 50px;
      width: 50px;
    }
  }
  .btn {
    padding-top: 12.5px !important;
    padding-bottom: 12.5px !important;
  }
  .button-outline {
    border: 2px solid;
    border-color: #ddd;
    color: inherit;
  }

  .clickable {
    cursor: pointer;
  }
  .fade-in {
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s; /* Firefox < 16 */
    -ms-animation: fadein 1s; /* Internet Explorer */
    -o-animation: fadein 1s; /* Opera < 12.1 */
    animation: fadein 1s;
  }
  .fade-out {
    -webkit-animation: fadeout 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeout 1s; /* Firefox < 16 */
    -ms-animation: fadeout 1s; /* Internet Explorer */
    -o-animation: fadeout 1s; /* Opera < 12.1 */
    animation: fadeout 1s;
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Firefox < 16 */
  @-moz-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Internet Explorer */
  @-ms-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadeout {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  /* Firefox < 16 */
  @-moz-keyframes fadeout {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadeout {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  /* Internet Explorer */
  @-ms-keyframes fadeout {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
}
</style>
