<template>
  <div id="EvolveGoal">
    <div class="goal">
      <div class="goal_header shadow" v-b-toggle="'goal-' + goal.id">
        <div class="goal_icon bg-brand-secondary shadow">
          <img
            class="img-fluid"
            :src="'/img/totalMentalHealth/' + goal.img"
            title="Goal Icon"
          />
        </div>
        <div class="goal-meta">
          <h4 class="goal-title">{{ goal.title }}</h4>
          <p class="goal-progress">
            Achieved {{ tasksComplete }}/{{ numOfTasks }} Tasks
          </p>
          <b-progress
            class="w-100"
            :value="goal.progress"
            max="100"
          ></b-progress>
          <a class="goal-link"
            >Track progess <i class="fas fa-arrow-right"></i
          ></a>
        </div>
      </div>
      <b-collapse :id="'goal-' + goal.id" class="goal_collapse">
        <b-card class="goal_inner_collapse">
          <img class="wave" src="/img/evolve/goal-wave.svg" />
          <img class="sun" src="/img/evolve/sun.svg" />

          <p class="goal-text brand-text mb-4">
            Your <b>Milestones</b> to help guide you on your
            <b>Journey</b>
          </p>
          <ul>
            <li v-for="milestone in goal.milestones" :key="milestone.id">
              <div
                v-b-toggle="'task-' + milestone.id"
                class="milestone bg-white shadow"
              >
                <div class="task-icon">
                  <span class="fa-stack fa-1x">
                    <i
                      class="fas fa-circle fa-stack-2x"
                      :class="{ 'grad-icon': !milestone.disabled }"
                    ></i>
                    <i
                      class="fas fa-stack-1x fa-inverse"
                      :class="{
                        'fa-check': milestone.completed,
                        [typeof milestone.icon !== 'undefined' && milestone.icon  ?  getIconClass(milestone.icon) : 'fa-tasks']: !milestone.completed && !milestone.disabled,
                        'fa-lock': milestone.disabled,
                      }"
                    ></i>
                  </span>
                </div>
                <div class="task-text">
                  {{ milestone.title
                  }}<span class="ml-2"><i class="fas fa-caret-down"></i></span>
                </div>
                <div
                  class="task-time ml-auto"
                  v-if="!milestone.disabled"
                  v-html="friendlyTaskDueDate(milestone)"
                ></div>
              </div>
              <b-collapse :id="'task-' + milestone.id" class="task-child"
                ><div class="task-child-content">
                  <div v-for="task in milestone.tasks" :key="task.id">
                    <EvolveTask
                      @clicked="openTaskModal"
                      :task="task"
                    ></EvolveTask>
                  </div>
                </div>
                <SmartTaskForm :goalId="goal.id" :milestoneId="milestone.id"></SmartTaskForm>
              </b-collapse>
            </li>
          </ul>
        </b-card>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import EventBus from "../../helpers/eventbus";

import moment from "moment";
import EvolveTask from "./EvolveTask.vue";
import SmartTaskForm from "./SmartTaskForm.vue";
import config from "@/config/constants";

export default {
  components: {
    EvolveTask,
    SmartTaskForm,
  },
  name: "EvolveGoal",
  props: ["goal"],
  data() {
    return {
      numOfTasks: null,
      tasksComplete: null,
      widgetTaskIds: [],
    };
  },
  mounted() {
    let taskCounter = 0;
    let completeCounter = 0;
    let widgetTaskIds = [];
    this.goal.milestones.forEach(function (milestone) {
      milestone.tasks.forEach(function (task) {
        taskCounter++;
        if (task.completed) completeCounter++;
        if (task.has_widget) widgetTaskIds.push(task.id);
      });
    });
    this.numOfTasks = taskCounter;
    this.tasksComplete = completeCounter;
  },
  methods: {
    openTaskModal(id) {
      this.$emit("clicked", id);
    },
    getIconClass(html){
      const matches = html.match(/"(.*)"/)
      return typeof matches[1] !== 'undefined' ? matches[1]: 'fa-tasks'
    },
    friendlyTaskDueDate(task) {
      if (task.completed) {
        return "<b>Done</b>";
      }
      if (task.target_date !== false) {
        return (
          "To complete by <b>" +
          moment(task.target_date, "YYYY-MM-DD").format("MMM Do") +
          "</b>"
        );
      }
      if (task.recurring_type !== false && task.recurring_type !== undefined) {
        return "Repeats <b>" + task.recurring_type + "</b>";
      }
      return "";
    },
    async createSmartTask() {
      const payload = {
        user_id: this.$store.getters.user_id,
        title: this.smartTaskTitle,
        content: btoa(this.smartTaskContent),
      };
      let res = await this.axios.post(
        process.env.VUE_APP_API_PATH +
          "/application/api/u/coaching/create-task",
        payload, config.options
      );
      if (res.data.success) {
        EventBus.$emit("getCoachingData");
      } else {
        console.log("failed to create smart task");
      }
    },
  },
  watch: {
    goal(goal) {
      let taskCounter = 0;
      let completeCounter = 0;
      goal.milestones.forEach(function (milestone) {
        milestone.tasks.forEach(function (task) {
          taskCounter++;
          if (task.completed) completeCounter++;
        });
      });
      this.numOfTasks = taskCounter;
      this.tasksComplete = completeCounter;
    },
  },
};
</script>
<style lang="less">
#EvolveGoal {
  .goal {
    margin-top: 30px;
    font-size: 1.6rem;
    .progress-bar {
      background-image: linear-gradient(to right, #8dcfd7, #529af4) !important;
    }
    .goal_header {
      padding: 15px 15px 15px 21%;
      border-radius: 16px;
      background-color: #6b8d9d;
      position: relative;
      display: flex;
      flex-direction: row;
      .goal-title {
        color: white;
        margin-bottom: 5px;
        font-size: 2rem;
        font-family: "FilsonProBold", serif;
      }
      p {
        color: white;
      }
      .goal_icon {
        background-color: #403d56;
        position: absolute;
        left: -15px;
        top: -15px;
        padding: 5px;
        width: 20%;
        height: auto;
        border-radius: 16px;
        overflow: hidden;
      }
      .goal-meta {
        flex-grow: 1;
        margin-left: -10px;
        .goal-progress {
          font-size: 1.2rem;
          font-weight: bold;
        }
        .goal-link {
          font-size: 1.4rem;
          color: #eee;
          float: right;
        }
      }
    }
    .goal_collapse {
      padding: 0px 10px 20px;
      border: none;
      color: white;
      border-radius: 0px 0px 15px 15px;
      overflow: hidden;
      .goal_inner_collapse {
        position: relative;
        background-color: #7b9daf;
        border-radius: 0px 0px 15px 15px;
        border: none;
        padding: 0 15px 15px 15px;
        overflow: hidden;
        p {
          position: relative;
          z-index: 100;
        }
        .wave {
          position: absolute;
          max-width: 100%;
          top: 0;
          left: 0;
          z-index: 1;
        }
        .sun {
          position: absolute;
          top: -35px;
          right: -30px;
          max-width: 250px;
          height: auto;
          z-index: 1;
        }
        ul {
          list-style: none;
          padding: 0px;
          li {
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex-grow: 1;
            .milestone {
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              align-items: center;
              width: 100%;
              padding: 10px 15px;
              border-radius: 10px;
              font-weight: bold;
              z-index: 100;
              .task-text {
                font-family: "FilsonProBold", serif;
                background: linear-gradient(to right, #985582, #d50870);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }
              cursor: pointer;
              .task-icon {
                display: flex;
                padding-right: 10px;
              }
              .task-time {
                font-family: "DMSans", serif;
                align-self: flex-end;
                font-weight: 400;
              }
              .task-text {
                .fas {
                  color: #ccc;
                }
              }
            }
            .milestone.disabled-task {
              cursor: default;
              color: #ddd;
              .fa-circle {
                color: grey;
              }
              .fa-lock {
                color: white;
              }
            }
            .milestone.completed-task {
              color: white;
              .task-text {
                text-decoration: line-through;
              }
            }
            .task-child {
              margin-top: -20px;
              background-color: rgba(255, 255, 255, 0.2);
              width: 100%;
              padding: 10px;
              z-index: 99;
              border-radius: 16px;
              .task-child-content {
                padding-top: 10px;
              }
            }
            img {
              max-width: 30px;
            }
          }
        }
      }
    }
  }
  .goal {
    margin-top: 30px;
    .goal_header {
    }
    .goal_collapse {
      padding: 0px 10px 0px;
      border: none;
      color: white;
      border-radius: 0px 0px 15px 15px;
      overflow: hidden;
      .goal_inner_collapse {
        background-color: #7dc2ca;
        border-radius: 0px 0px 15px 15px;
        border: none;
        padding: 15px;
        p {
          color: white;
        }
        ul {
          list-style: none;
          padding: 0px;
          li {
            border-radius: 10px;
            color: #6b8d9d;
            margin-bottom: 10px;
            font-weight: bold;
            img {
              max-width: 30px;
            }
          }
        }
      }
    }
  }
  .bg-white:hover {
    background-color: #f7f7f7 !important;
  }
}
</style>
