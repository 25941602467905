<template>
  <div id="NeurodiversityBookingFlow" class="p-5">
    <div class="content-container">
      <div class="title" ref="title">How long does it take to get my <span>Neurodiversity Assessment</span>?</div>
      <p>The length of time required is dependent on a few factors. In your initial session the specialist will explain that there a number of questionnaires that need to be completed and reviewed. You will then have a follow-up session scheduled where your assessment will take place. After this session, you will receive an assessment report.</p>
      <div id="timelineHolder" class="py-5 my-5" v-if="innerLength">
        <div id="timeline" :style="{width: timelineWidth + 'px'}">
          <div id="timelineBar" :style="{width: timelineBarWidth}"></div>

          <div class="timelineItem"
               v-for="(item,i) in items" :style="{left: (-(0.1  * timelineWidth) + (i * 0.2 * timelineWidth)) + 'px'}"
               :class="dateClass(item.date)"
          >
            <div class="date">{{convertTimelineDate(item.date)}}</div>
            <div class="timelineImage">
              <i class="fa-solid" :class="item.icon"></i>
            </div>
            <div class="headText mt-2 px-4">{{item.heading}}</div>
            <div class="subtext px-4">{{item.text}}</div>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>

import moment from "moment";

export default {
  name: "NeurodiversityBookingFlow",
  data() {
    return {
      innerLength: 0,
      dates: {
        account_created: '2023-11-15',
        initial_appointment_booked: '2023-12-03',
        initial_session: '2023-12-20',
        complete_questionnaires: null,
        specialist_assessment: null,
        report: null
      },
      items: [
        {date: null, icon: 'fa-rocket', heading: 'Process Started', text: 'You’ve created an account'},
        {date: null, icon: 'fa-check', heading: 'Book Initial Appointment', text: null},
        {date: null, icon: 'fa-calendar-check', heading: 'Initial Session', text: 'A specialist will explain all the steps in detail'},
        {date: null, icon: 'fa-check', heading: 'Complete Questionnaires', text: null},
        {date: null, icon: 'fa-calendar-check', heading: 'Neurodiversity Assessment Session', text: null},
        {date: null, icon: 'fa-clipboard-list', heading: 'Assessment Report & Support', text: null},
      ]
    }
  },
  mounted() {
    window.addEventListener('resize', this.setInnerLength);
    this.getData();
    setTimeout(()=> {
      this.setInnerLength();
    },500)
  },
  destroyed() {
    window.removeEventListener('resize', this.updateTiles);
  },
  computed: {
    timelineBarWidth() {
      let percentage = 10;
      let oneEventAlreadyMarkedAsBeingToday = false;
      this.items.map((item,i) => {
        if(i !== 0 && this.dateClass(item.date) === 'past') percentage += 20;
        if(i !== 0 && this.dateClass(item.date) === 'today') {
          if(oneEventAlreadyMarkedAsBeingToday) {
            percentage += 20;
          } else {
            percentage += 10;
            oneEventAlreadyMarkedAsBeingToday = true;
          }
        }
      })
      return percentage + '%';
    },
    timelineWidth() {
      return (this.innerLength * 0.8);
    }
  },
  methods: {
    async getData() {
      const res = await this.api({
        path: "api/u/cmh/get-neurodiversity-timeline-information",
        age_group: this.$store.getters.user.therapy.age_group
      });
      if(res.success) {
        this.items[0].date = res.data.agreement_date;
        this.items[1].date = res.data.initial_appointment_booked_on;
        this.items[2].date = res.data.initial_appointment_date;
        this.items[4].date = res.data.specialist_assessment_date;
        this.items[3].date = res.data.forms_complete;
      }
    },
    dateClass(inputDate) {
      if(!inputDate) return 'future'
      const parsedInputDate = moment(inputDate, 'YYYY-MM-DD');
      const currentDate = moment();
      if (parsedInputDate.isSame(currentDate, 'day')) {
        return 'today';
      } else if (parsedInputDate.isBefore(currentDate, 'day')) {
        return 'past';
      } else {
        return 'future';
      }
    },
    convertTimelineDate(date) {
      if(!date) return '';
      const parsedDate = moment(date, 'YYYY-MM-DD');
      return parsedDate.format('Do MMM');
    },
    setInnerLength() {
      this.innerLength = this.$refs.title.clientWidth;
    }
  }
}
</script>

<style lang="less">
#NeurodiversityBookingFlow {
  width: 100%;
  background-color: white;
  padding-bottom: 65px !important;
  .title {
    font-size: 2rem;
    text-align: center;
    width: 100%;
    span {
      font-family: FilsonProBold, Arial, Helvetica, sans-serif;
    }
  }
  p {
    max-width: 100% !important;
    font-size: 1.3rem !important;
  }
  #timelineHolder {
    #timeline {
      margin: auto;
      height: 20px;
      background-color: #e8f1fb;
      //background-color: #E83B75;
      border-radius: 10px;
      position: relative;
      .timelineItem {
        position: absolute;
        top: -38px;
        width: 20%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .date {
          height: 24px;
          font-size: 15px;
          font-family: FilsonProBold, Arial, Helvetica, sans-serif;
        }
        .headText {
          font-family: FilsonProBold, Arial, Helvetica, sans-serif;
          font-size: 14px;
          text-align: center;
        }
        .subtext {
          text-align: center;
          font-size: 12px;
        }
        .timelineImage {
          width: 50px;
          height: 50px;
          background-color:  white;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 5px #e8f1fb solid;
          i {
            color: #8DCFD7;
            font-size: 20px;
          }
        }
        &.today {
          .timelineImage {
            border: 5px #E83B75 solid;
            i {
              color: #E83B75;
            }
          }
        }
        &.past {
          .date {
            color: #E83B75;
          }
          .timelineImage {
            background-color: #E83B75;
            border: 5px white solid;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
            i {
              color: white;
            }
          }
        }
      }

      #timelineBar {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        border-radius: 10px;
        background-color: #E83B75;
      }
    }
  }
}
</style>