<template>
  <div class="TherapyChoiceBox">
    <b-form-group v-slot="{ ariaDescribedby }" :label="label" :label-for="id" :class="{required: required, incomplete: required && (answer === null || answer === '')}">
      <b-form-radio
          :id="id+'-'+i"
          v-for="(opt, i) in options"
          v-model="answer" :aria-describedby="ariaDescribedby" :name="id" :value="opt" size="lg"
      >{{opt}}</b-form-radio>
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: "TherapyChoiceBox",
  props: ["label", "item", "options", "required"],
  data() {
    let id = this.genRandonString(10);
    let ans = this.item;
    return {
      id: id,
      answer: ans
    }
  },
  watch: {
    item(val) {
      this.answer = val;
    },
    answer(val) {
      this.$emit('update', val)
    }
  }
}
</script>

<style lang="less">
.TherapyChoiceBox {
  .form-group {
    div {
      display: flex;
      .custom-control {
        margin-right: 10px;
      }
    }
  }
}
</style>