<template>
  <div id="app" :class="appClass">
    <router-view />
    <div class="accessibilityDirect" :class="{expanded: accessibilityButtonExpanded}" v-if="displayAccessibilityRedirect" @click="goToAccessibilitySite" @mouseenter="toggleAccessibilityButton" @mouseleave="toggleAccessibilityButton">
      <i class="fa-solid fa-gear" :class="{'fa-spin': spin}"></i> <span>Accessibility Site</span>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import config from "./config/constants";
import { SocketService } from "@/socket/SocketService";
import {mapGetters} from "vuex";
export default {
  name: "app",
  data() {
    return {
      showBanner: false,
      socketService: null,
      fullStoryAdded: false,
      accessibilityButtonExpanded: false,
      spin: false
    };
  },
  created() {
    let cookieCode = process.env.NODE_ENV === 'production' ? '8ef8a0e2-a935-44bc-a8bb-3a1edb453c8d':'8ef8a0e2-a935-44bc-a8bb-3a1edb453c8d-test';
    var scriptTag = document.createElement('script');
    scriptTag.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
    scriptTag.type = "text/javascript";
    scriptTag.charset = "UTF-8";
    scriptTag.setAttribute("data-domain-script", cookieCode);

    var wrapperFunction = document.createElement('script');
    wrapperFunction.type = "text/javascript";
    wrapperFunction.text = "function OptanonWrapper() { }";

    document.head.appendChild(scriptTag);
    document.head.appendChild(wrapperFunction);

    this.setHotjar();
    //reset empty nav object in store to resolve caching issue
    if (!Array.isArray(this.$store.state.nav)) {
      this.$store.dispatch("clearNav");
    }
    //check if user is using IE
    if (window.document.documentMode) {
      this.$store.commit("setBrowserIE", window.document.documentMode);
    } else {
      this.$store.commit("setBrowserIE", false);
    }
    this.getBranding();
    var APP_ID = process.env.VUE_APP_INTERCOM_APP_ID;
    (function () {
      var w = window;
      var ic = w.Intercom;
      if (typeof ic === "function") {
        ic("reattach_activator");
        ic("update", w.intercomSettings);
      } else {
        var d = document;
        var i = function () {
          i.c(arguments);
        };
        i.q = [];
        i.c = function (args) {
          i.q.push(args);
        };
        w.Intercom = i;
        var l = function () {
          var s = d.createElement("script");
          s.type = "text/javascript";
          s.async = true;
          s.src = "https://widget.intercom.io/widget/" + APP_ID;
          var x = d.getElementsByTagName("script")[0];
          x.parentNode.insertBefore(s, x);
        };
        if (document.readyState === "complete") {
          l();
        } else if (w.attachEvent) {
          w.attachEvent("onload", l);
        } else {
          w.addEventListener("load", l, false);
        }
      }
    })();

    //FullStory
    if(this.dfdHomePage) this.createFullstoryTags();
  },
  mounted() {
    if(this.isAccessibilitySite()) {
      this.loadUserWayAccessibeScript();
      this.loadPostHogScript();
    }
    if (process.env.VUE_APP_WEBSOCKET_ENABLE == 1) {
      this.socketService = new SocketService(this);
    }
    if (this.studentAccessibility) {
      this.loadAccessibeScript();
    }
    this.shouldDisplayCookieBanner();
  },
  methods: {
    loadPostHogScript() {
      let scriptTag = document.createElement('script');
      scriptTag.type = 'text/javascript';
      scriptTag.async = true;
      scriptTag.text = `
        !function(t,e){var o,n,p,r;e.__SV||(window.posthog=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.async=!0,p.src=s.api_host+"/static/array.js",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="posthog",u.people=u.people||[],u.toString=function(t){var e="posthog";return"posthog"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags getFeatureFlag getFeatureFlagPayload reloadFeatureFlags group updateEarlyAccessFeatureEnrollment getEarlyAccessFeatures getActiveMatchingSurveys getSurveys getNextSurveyStep onSessionId".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.posthog||[]);
        posthog.init('phc_C2rRL5yP3Z3KF0PFYVpFIPQIxdmQjTJyUzNlRjOZltO', {api_host: 'https://us.i.posthog.com', person_profiles: 'identified_only'});
      `;
      document.head.appendChild(scriptTag);
    },
    toggleAccessibilityButton() {
      this.accessibilityButtonExpanded = !this.accessibilityButtonExpanded;
      this.spin = true;
      setTimeout(()=> {
        this.spin = false;
      },500)
    },
    goToAccessibilitySite() {
      const parsedUrl = new URL(window.location.href);
      const hostnameParts = parsedUrl.hostname.split('.');
      hostnameParts[0] = 'accessibility';

      let redirectUrl = hostnameParts.join('.').toString();
      const redirectToParam = parsedUrl.pathname !== "/" ? `&redirect_to=${parsedUrl.pathname.substring(1)}` : "";

      if (this.user.authenticated) {
        redirectUrl += `/site/loginas?auth=${this.user.user_id}${redirectToParam}`;
      }
      window.location.href = 'https://'+redirectUrl;
    },
    loadUserWayAccessibeScript() {
      var scriptTag = document.createElement('script');
      scriptTag.src = "https://cdn.userway.org/widget.js";
      scriptTag.type = "text/javascript";
      scriptTag.charset = "UTF-8";
      scriptTag.setAttribute("data-account", 'dmcxpfIZfX');
      document.head.appendChild(scriptTag);
    },
    createFullstoryTags() {
      if(process.env.NODE_ENV === 'production') return;
      if(this.fullStoryAdded) return;
      const fullstoryTag = document.createElement('script');
      fullstoryTag.type = "text/javascript";
      fullstoryTag.text = "window['_fs_host'] = 'fullstory.com';\n" +
          "window['_fs_script'] = 'edge.fullstory.com/s/fs.js';\n" +
          "window['_fs_org'] = 'o-1WYKRQ-na1';\n" +
          "window['_fs_namespace'] = 'FS';\n" +
          "!function(m,n,e,t,l,o,g,y){var s,f,a=function(h){\n" +
          "return!(h in m)||(m.console&&m.console.log&&m.console.log('FullStory namespace conflict. Please set window[\"_fs_namespace\"].'),!1)}(e)\n" +
          ";function p(b){var h,d=[];function j(){h&&(d.forEach((function(b){var d;try{d=b[h[0]]&&b[h[0]](h[1])}catch(h){return void(b[3]&&b[3](h))}\n" +
          "d&&d.then?d.then(b[2],b[3]):b[2]&&b[2](d)})),d.length=0)}function r(b){return function(d){h||(h=[b,d],j())}}return b(r(0),r(1)),{\n" +
          "then:function(b,h){return p((function(r,i){d.push([b,h,r,i]),j()}))}}}a&&(g=m[e]=function(){var b=function(b,d,j,r){function i(i,c){\n" +
          "h(b,d,j,i,c,r)}r=r||2;var c,u=/Async$/;return u.test(b)?(b=b.replace(u,\"\"),\"function\"==typeof Promise?new Promise(i):p(i)):h(b,d,j,c,c,r)}\n" +
          ";function h(h,d,j,r,i,c){return b._api?b._api(h,d,j,r,i,c):(b.q&&b.q.push([h,d,j,r,i,c]),null)}return b.q=[],b}(),y=function(b){function h(h){\n" +
          "\"function\"==typeof h[4]&&h[4](new Error(b))}var d=g.q;if(d){for(var j=0;j<d.length;j++)h(d[j]);d.length=0,d.push=h}},function(){\n" +
          "(o=n.createElement(t)).async=!0,o.crossOrigin=\"anonymous\",o.src=\"https://\"+l,o.onerror=function(){y(\"Error loading \"+l)}\n" +
          ";var b=n.getElementsByTagName(t)[0];b&&b.parentNode?b.parentNode.insertBefore(o,b):n.head.appendChild(o)}(),function(){function b(){}\n" +
          "function h(b,h,d){g(b,h,d,1)}function d(b,d,j){h(\"setProperties\",{type:b,properties:d},j)}function j(b,h){d(\"user\",b,h)}function r(b,h,d){j({\n" +
          "uid:b},d),h&&j(h,d)}g.identify=r,g.setUserVars=j,g.identifyAccount=b,g.clearUserCookie=b,g.setVars=d,g.event=function(b,d,j){h(\"trackEvent\",{\n" +
          "name:b,properties:d},j)},g.anonymize=function(){r(!1)},g.shutdown=function(){h(\"shutdown\")},g.restart=function(){h(\"restart\")},\n" +
          "g.log=function(b,d){h(\"log\",{level:b,msg:d})},g.consent=function(b){h(\"setIdentity\",{consent:!arguments.length||b})}}(),s=\"fetch\",\n" +
          "f=\"XMLHttpRequest\",g._w={},g._w[f]=m[f],g._w[s]=m[s],m[s]&&(m[s]=function(){return g._w[s].apply(this,arguments)}),g._v=\"2.0.0\")\n" +
          "}(window,document,window._fs_namespace,\"script\",window._fs_script);"
      const head = document.getElementsByTagName("head")[0];
      head.insertBefore(fullstoryTag, head.firstChild);
      this.fullStoryAdded = true;
    },
    loadAccessibeScript() {
      let primary = '#3A4A61'
      if(this.$store.getters.subdomain === 'uclemployeesupport') primary = '#381c51';
      var s = document.createElement('script'); var h = document.querySelector('head') || document.body; s.src = 'https://acsbapp.com/apps/app/dist/js/app.js'; s.async = true; s.onload = function () { acsbJS.init({ statementLink: '', footerHtml: '', hideMobile: false, hideTrigger: false, disableBgProcess: false, language: 'en', position: 'left', leadColor: primary, triggerColor: '#e83b75', triggerRadius: '50%', triggerPositionX: 'left', triggerPositionY: 'bottom', triggerIcon: 'people', triggerSize: 'medium', triggerOffsetX: 20, triggerOffsetY: 20, mobile: { triggerSize: 'small', triggerPositionX: 'right', triggerPositionY: 'center', triggerOffsetX: 10, triggerOffsetY: 0, triggerRadius: '50%' } }); }; h.appendChild(s);
      // The CSS below will move the OneTrust icon clear above the accessibility tag
      const cssText = `
        .ot-floating-button {
            bottom: 80px !important;
        }`;
      const styleElement = document.createElement("style");
      styleElement.setAttribute("type", "text/css");
      styleElement.textContent = cssText;
      document.getElementsByTagName("head")[0].appendChild(styleElement);
    },
    async getBranding() {
      const domain = window.location.host
        .split(".")
        .filter((x) => x !== "www")[0];
      if (domain.includes("laya")) {
        this.setMeta(domain, "/img/laya-favicon.png");
      }
      if (domain.includes("focusonyou")) {
        this.setMeta("Focus on You", "/img/favicon.ico");
      }
      if (domain.includes("bupa")) {
        this.setMeta("Digital GP services | Bupa UK", "/img/bupa-favicon.ico");
      }
      const payload = {
        portal_domain: domain,
      };
      this.$store.commit("setDomain", domain);
      let res = await axios.post(
        config.api_env + "/application/api/customisations/get-client-branding",
        payload,
        config.options
      );
      if (res.data.success) {
        this.$store.commit("setSkin", res.data.skin);
        this.$store.commit("setAccessCode", res.data.access_code);
        this.$store.commit("setClientBranding", res.data);
        this.$store.commit("setNavType",res.data.home_page);
        this.$store.commit('setTwoFactorEnabled', res.data.two_factor_enabled);
        if (this.$route.query.lang) {
          this.$store.commit("setLanguage", this.$route.query.lang);
        } else if (!this.$store.state.user.authenticated) {
          this.$store.commit("setLanguage", res.data.lang);
        }
        if (res.data.skin === 13) {
          this.setMeta("SanusX", "/img/sanusx/favicon.png");
        }
      } else {
        if (this.$store.getters.hardcoded && this.$store.getters.hardcoded) {
          this.$store.commit("setSkin", this.$store.getters.hardcoded.skin);
        }
        this.$store.commit("setAccessCode", 0);
      }
    },
    setMeta(title, faviconURL) {
      document.title = title;
      const link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = faviconURL;
      document.getElementsByTagName("head")[0].appendChild(link);
    },
    setHotjar() {
      (function (h, o, t, j, a, r) {
        h.hj =
          h.hj ||
          function () {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: 2202008, hjsv: 6 };
        a = o.getElementsByTagName("head")[0];
        r = o.createElement("script");
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
    },
  },
  computed: {
    ...mapGetters(["user"]),
    displayAccessibilityRedirect() {
      return !this.studentAccessibility && !this.isAccessibilitySite();
    },
    dfdHomePage() {
      return this.$store.getters.user.home_page === 'default';
    },
    studentAccessibility() {
      return this.$store.getters.subdomain === 'uclemployeesupport' || this.$store.getters.subdomain === 'ucl247studentsupport' || this.$store.getters.subdomain === 'lancasteruni'
    },
    skin() {
      return this.$store.getters.skin;
    },
    darkMode() {
      return this.$store.getters.darkMode;
    },
    appClass() {
      let className = 'theme-' + this.skin;
      if(this.darkMode) className += ' theme_dark_mode';
      return className
    }
  },
  watch: {
    dfdHomePage(value) {
      if(value) {
        this.createFullstoryTags();
      }
    }
  },
};
</script>
<style lang="less">
.toggleTooltip {
  .arrow{
    display: none !important;
  }
  .tooltip-inner {
    background-color: grey;
    border-radius: 15px;
    color: white;
    font-size: 1.2rem;
    opacity: 1;
    line-height: 1.2;
    h4 {
      font-size: 1.8rem;
    }
    p{
      text-align: center;
    }
  }
}
.mylist-tooltip{
  .arrow{
    display: none !important;
  }
  .tooltip-inner {
    background-color: grey;
    border-radius: 15px;
    padding: 5px 10px;
    color: white;
    font-size: 1.2rem;
    opacity: 1;
    line-height: 1.2;
    h4 {
      font-size: 1.8rem;
    }
    p{
      text-align: center;
    }
  }
}
@import (once) "./assets/themes.less";

html {
  font-size: 10px;

  @media only screen and (max-width: 576px) {
    font-size: 9px;
  }
}

@font-face {
  font-family: "DMSans";
  src: url("assets/fonts/DMSans-VariableFont_opsz,wght.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url("assets/fonts/Poppins-SemiBold.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "FilsonProBlack";
  src: url("assets/fonts/FilsonProBlack.ttf");
  src: url("assets/fonts/FilsonProBlack.woff");
  src: url("assets/fonts/FilsonProBlack.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "FilsonProBold";
  src: url("assets/fonts/FilsonProBold.ttf");
  src: url("assets/fonts/FilsonProBold.woff");
  src: url("assets/fonts/FilsonProBold.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "FilsonProBook";
  src: url("assets/fonts/FilsonProBook.ttf");
  src: url("assets/fonts/FilsonProBook.woff");
  src: url("assets/fonts/FilsonProBook.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "FilsonProLight";
  src: url("assets/fonts/FilsonProLight.ttf");
  src: url("assets/fonts/FilsonProLight.woff");
  src: url("assets/fonts/FilsonProLight.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lyon';
  src: url("assets/fonts/LyonDisplay-Bold-Trial.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "ff-cocon-web-pro";
  src: url(assets/fonts/CoconPro-Regular.woff);
}
@font-face {
  font-family: "cedarville-cursive";
  src: url("assets/fonts/CedarvilleCursive-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ff-sari-web-bold";
  src: url(assets/fonts/ufonts.com_sari-bold.woff);
}

@font-face {
  font-family: "ff-sari-web-medium";
  src: url(assets/fonts/ufonts.com_sari-medium.woff);
}

@font-face {
  font-family: "ff-sari-web-regular";
  src: url(assets/fonts/ufonts.com_sari-regular.woff);
}

#app {

  height: 100vh;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  //position: relative; // why it doesnt work like usual ?
  overflow-x: hidden;
  background: #fff;

  .page-title h2 {
    text-transform: capitalize;
  }

  a {
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  h2 {
    font-family: FilsonProLight;
    font-size: 40px;
    color: #3a4961;
    text-align: center;
    margin-bottom: 20px;
  }

  p {
    color: #394961;
  }

  .btn {
    border-radius: 60px;
    font-size: 1.2rem;
    line-height: 1;
    letter-spacing: 0.2rem;
    border-width: 0;
    padding: 10px 15px;
    &.border-white {
      border-width: 1px;
      border-color: #fff;

      &.active {
        border-width: 0;
      }
    }

    &:focus {
      box-shadow: none;
    }
  }

  .clickable {
    cursor: pointer;
  }

  //TODO move to skins
  .btn-purple {
    background-color: #e83b75;
    color: #fff;
  }

  input[type="email"]::-ms-clear,
  input[type="text"]::-ms-clear {
    display: none;
  }

  input {
    // set as important as Filson Pro looks broken in input fields
    //TODO:: Discuss font options with Niamh
    font-family: DMSans, sans-serif !important;
  }

  .col-xs-5ths,
  .col-sm-5ths,
  .col-md-5ths,
  .col-lg-5ths {
    position: relative;
    min-height: 1px;
  }

  .col-xs-5ths {
    width: 20%;
    float: left;
  }

  @media (min-width: 768px) {
    .col-sm-5ths {
      width: 20%;
      float: left;
    }
  }

  @media (min-width: 992px) {
    .col-md-5ths {
      width: 20%;
      float: left;
    }
  }

  @media (min-width: 1200px) {
    .col-lg-5ths {
      width: 20%;
      float: left;
    }
  }
}

.questionnaire-modal {
  .modal-content {
    border-radius: 24px;
    overflow: hidden;

    .modal-footer {
      display: none !important;
    }
  }

  .modal-body {
    padding: 0;
  }
}

//TODO move it to somewhere where it makes sense
.modal-content {

  //TODO move to skins
  .btn {
    border-radius: 60px;
    font-size: 1.3rem;
    //font-size: 14px;
    line-height: 1;
    font-family: "FilsonProBold", arial;
    /*letter-spacing: 0.2rem;*/
    //border-width: 2;
    padding: 10px 15px;
    &.border-white {
      border-width: 1px;
      border-color: #fff;

      &.active {
        border-width: 0;
      }
    }

    &:focus {
      box-shadow: none;
    }
  }

  .btn-purple {
    background-color: #e83b75;
    color: #fff;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);

    &:hover {
      //TODO something
      color: #fff;
    }

    &:active {
      color: #fff;

      //TODO something
    }
  }

  .btn-outline-purple {
    background-color: white;
    color: #e83b75;
    border: 2px solid #e83b75;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);

    &:hover {
      background-color: whitesmoke;
      color: #e83b75;
    }

    &:active {
      color: #e83b75;

      //TODO something
    }
  }

  .btn-outline-grey {
    background-color: white;
    color: #808080;
    border: 1px solid #808080;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);

    &:hover {
      background-color: whitesmoke;

      //TODO something
    }

    &:active {
      //TODO something
    }
  }

  &.coach_modal {
    overflow: hidden;
    border: none;

    //box-shadow: 0px 0px 100px rgba(0,0,0,0.5) inset;
    .coach_modal_header {
      color: white;
      padding: 10px 30px;

      h5 {
        font-size: 17px;
      }
    }

    .modal-body {
      padding: 0px 15px;
    }

    .modal-footer {
      display: none;
    }
  }
}

#clinician_modal {
  .modal-dialog {
    transition: all 0.2s ease-in-out !important;
  }
}

.filson-light {
  font-family: 'FilsonProLight', sans-serif !important;
  letter-spacing: normal !important;
}

.filson-book {
  font-family: 'FilsonProBook', sans-serif !important;
  letter-spacing: normal !important;
}

.filson-bold {
  font-family: 'FilsonProBold', sans-serif !important;
  letter-spacing: normal !important;
}
.accessibilityDirect {
  height: 56px;
  width: 56px;
  background-color: white;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 399;
  color: #5640F9;
  font-family: FilsonProBold, Arial, Helvetica, sans-serif;
  border-radius: 30px 0 0 0;
  padding-left: 20px;
  font-size: 16px;
  cursor: pointer;
  transition: width 0.3s ease-in-out;
  span {
    opacity: 0;
    transition-delay: 0;
  }
  i {
    font-size: 20px;
    margin-right: 10px;
  }
  display: flex;
  align-items: center;
  &.expanded {
    width: 236px;
    padding-left: 36px;
    span {
      opacity: 1;
      transition-delay: 0.4s;
    }
  }
}
#my-org-edit-page-modal {
  .modal-title {
    font-size: 1.9rem;
  }
  font-size: 1.8rem;
  input, select, textarea {
    font-size: 1.4rem;
  }
}

</style>
