<template>
  <div id="CustomProgressSlider" @mouseleave="clearSelection" ref="CustomProgressSlider">
    <div id="viewer" ref="viewer" :style="{marginLeft: viewerLeftMargin}">
      <div id="track" ref="track">
        <div
          @mouseover="selectTile(i)"
          @mouseleave="selectTile(-1)"
          class="tile"
          :class="tileSelected === i && tilesToShow > 2 ? 'selected': null"
          :style="{
            width:  getTileWidth(i),
            paddingLeft: tilePaddingLeft + 'px',
            paddingRight: tilePaddingRight + 'px'
          }"
          v-for="(item,i) in items" :key="'slider-card-' + i"
          style="position: relative"
        >
          <ProgressSliderCard
            :path="item.path" :course_image_url="item.dfd_home_image ? item.dfd_home_image : item.course_image_url" :course_progress="item.course_progress" :seconds="item.seconds"
            :course_title="item.course_title" :tile-height="tileHeight" :embed_code="item.embed_code" :selected="tileSelected === i"
          />
        </div>
      </div>
    </div>
    <div class="arrowLeft arrow" :style="{top: (tileHeight / 2) - 30 + 'px'}" :class="{fadeOut: page <= 1}">
      <i class="fa-solid fa-chevron-left" @click="goBack" v-if="page > 1"></i>
    </div>
    <div class="arrowRight arrow" :style="{top: (tileHeight / 2) - 30 + 'px'}" :class="{fadeOut: totalPages <= page}">
      <i class="fa-solid fa-chevron-right" @click="goForward" v-if="totalPages > page"></i>
    </div>
  </div>
</template>

<script>

import ServicesCard from "@/components/Home/New/ServicesCard.vue";
import EventBus from "@/helpers/eventbus";
import ProgressSliderCard from "@/components/Home/New/ProgressSliderCard.vue";

export default {
  name: "CustomProgressSlider",
  components: {
    ServicesCard,
    ProgressSliderCard
  },
  props: {
    items: {
      type: Array
    },
    tileType: {
      type: String
    },
  },
  data() {
    return {
      sliderWidth: 0,
      screenWidth: 0,
      tileSelected: 1,
      sideNavWidth: 0,
      scrolling: false,
      hideCards: true,
      aspectRatio: {
        width: 16,
        height: 9
      },
      page: 1,
      showOptions: {
        tilesToShow: 4,
        responsive: [
          {max: 680, tilesToShow: 2},
          {max: 880, tilesToShow: 3},
          {max: 1400, tilesToShow: 4}
        ]
      },
      testItems: [
        {title: "Tile 1"},
        {title: "Tile 2"},
        {title: "Tile 3"},
        {title: "Tile 4"},
        {title: "Tile 5"},
        {title: "Tile 6"},
        {title: "Tile 7"},
        {title: "Tile 8"},
        {title: "Tile 9"},
        {title: "Tile 10"},
      ]
    }
  },
  methods: {
    goBack() {
      if(this.pages === 1 || this.scrolling) return;
      this.scrolling = true;
      this.$refs.viewer.scrollLeft -= this.sliderWidth;
      this.page = Math.max(1, this.page - 1);
      setTimeout(()=> {
        this.scrolling = false;
      }, 500)
    },
    goForward() {
      if(this.page === this.totalPages || this.scrolling) return;
      this.scrolling = true;
      this.$refs.viewer.scrollLeft += this.sliderWidth;
      this.page = Math.min(this.page + 1, this.totalPages);
      setTimeout(()=> {
        this.scrolling = false;
      }, 500)
    },
    getTileWidth(i) {
      return this.tileWidth + 'px';
    },
    selectTile(i) {
      this.tileSelected = i;
    },
    clearSelection() {
      this.tileSelected = -1;
      this.$refs.viewer.scrollLeft = this.additionalScroll;
    },
    updateTiles() {
      this.screenWidth = Math.min(document.documentElement.clientWidth, 1600) - this.sideNavWidth - 30;
      this.sliderWidth = Math.min(2000, this.screenWidth);
      this.page = 1;
      setTimeout(()=> {
        if(this.$refs.viewer && this.$refs.viewer.hasOwnProperty('scrollLeft')) {
          this.$refs.viewer.scrollLeft = this.additionalScroll;
        }
        this.clearSelection();
      },500)
    },
  },
  computed: {
    viewerLeftMargin() {
      //if(this.sliderWidth < this.screenWidth) return ((this.screenWidth - this.sliderWidth) / 2) + 'px';
      return 0;
    },
    tileHeight() {
      const aspectRatio = this.aspectRatio.width / this.aspectRatio.height
      return Math.floor((this.tileWidth - this.tilePaddingLeft - this.tilePaddingRight) / aspectRatio);
    },
    tilePaddingRight() {
      return Math.floor(this.tileWidth * 0.08);
    },
    tilePaddingLeft() {
      return Math.floor(this.tileWidth * 0.03);
    },
    tileWidth() {
      return Math.floor((this.sliderWidth) / this.tilesToShow);
    },
    tilesToShow() {
      let max = 10000;
      let tilesToShow = this.showOptions.tilesToShow;
      this.showOptions.responsive.forEach(option => {
        if(option.max > this.sliderWidth && option.max < max) {
          max = option.max;
          tilesToShow = option.tilesToShow
        }
      })
      return tilesToShow;
    },
    totalPages() {
      return Math.ceil(this.items.length / this.tilesToShow)
    },
    additionalScroll() {
      if(this.page === 1) return 0;
      return (this.page - 1) * (this.sliderWidth);
    }
  },
  mounted() {
    this.updateTiles();
    EventBus.$on("sideNavExpanded",(expanded) => {
      if(!this.$refs.CustomProgressSlider) {
        return;
      }
      this.sideNavWidth = expanded ? 250 : 0;
      this.updateTiles();
    })
    window.addEventListener('resize', this.updateTiles);
    setTimeout(()=> {
      this.hideCards = false;
    },1000)
  },
  destroyed() {
    window.removeEventListener('resize', this.updateTiles);
  },
  watch: {
    tileSelected(newValue,oldValue) {
      this.setInvisibleCardWith(newValue, oldValue);
    },
    items() {
      this.updateTiles()
    },
    // tileWidth() {
    //   this.$refs.viewer.scrollLeft = (this.tileWidth);
    // }
  }
}
</script>

<style lang="less">
#CustomProgressSlider {
  width: 100%;
  margin-top: 25px;
  margin-bottom: 25px;
  position: relative;
  #viewer {
    max-width: 2000px;
    width: 100%;
    scroll-behavior: smooth;
    height: 100%;
    overflow-x: hidden;
    overflow-y: visible;
  }
  #track {
    display: flex;
    height: 100%;
    width: 50000px;
  }
  .tile {
    clear: both;
    height: fit-content;
    overflow: visible;
    transition: width 0.4s linear, transform 0.2s linear;
  }
  .arrow {
    position: absolute;
    height: 80px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.25;
    z-index: 100;
    transition: opacity 0.5s linear;
    background-color: rgba(0,0,0,0.5);
    &:hover {
      opacity: 1;
      i {
        color: white !important;
        transform: scale(1.4);
      }
    }
    &.fadeOut {
      opacity: 0;
    }
    i {
      transition: transform .2s;
      cursor: pointer;
      color: black;
      font-size: 20px;
    }
  }
  .arrowLeft {
    left: 0;
    border-radius: 0 5px 5px 0;
  }
  .arrowRight {
    right: 0;
    border-radius: 5px 0 0 5px;
  }
}
</style>