<template>
    <div id="Specsavers">
        <div class="store-banner">
            <div class="content-container row">
                <div class="fitbit-logo col-4">
                    <img src="/img/store/specsavers-large.png">
                </div>
                <div class="col-8 banner-image">
                    <img src="/img/store/glasses.png">
                </div>
            </div>
        </div>
        <div class="bg-white">
            <div class="content-container row">
                <div class="col-12 py-5 px-0 return-link">
                    <a class="brand-secondary" @click="$router.push({name: 'wellnessBenefits'})"><font-awesome-icon icon="chevron-left"></font-awesome-icon> {{componentText.back}}</a>
                </div>
                <div class="section-header section-header">
                    <div class="section-header-divider"></div>
                    <h3 class="section-header-title"> {{componentText.title}}</h3>
                </div>
                <div class="col-sm-8 pb-5 pl-0">
                    <div class="description-text" v-html="componentText.content">
                    </div>
                    <div v-if="voucher.voucher" class="voucher py-5">
                        <b-button
                                  @click="downloadVoucher()"
                                  class="cta-button px-5 py-3">
                            {{componentText.download}}
                        </b-button>
                    </div>
                    <div class="voucher-unavailable py-5" v-else>
                        <b-button class="cta-button px-5 py-3" disabled>
                            {{componentText.unavailable}}
                        </b-button>
                        <p>{{voucher.err}}</p>
                    </div>
                </div>
                <div class="col-sm-4 text-center">
                    <div class="image-wrap free-test">
                        <img src="/img/store/free-eye-test.png">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import axios from "axios";
    import config from "../config/constants";
    export default {
        name: "Specsavers",
        data() {
            return {
                voucher: ''
            };
        },
        mounted() {
            this.getSpecsaversVoucher()
        },
        methods: {
            downloadVoucher(){
                //TODO: this function
            },
            async getSpecsaversVoucher(){
                const payload = {
                    user_id: this.$store.getters.user_id
                }
                let res = await axios.post(config.api_env + '/application/api-esp/get-specsavers-voucher', payload, config.options )
                if(res.data.success) {
                    this.voucher = res.data.voucher
                }
            },
        },
        watch:{

        },
        computed:{
            componentText() {
                return this.getText.loggedIn.specsavers;
            },
        }
    };
</script>
<style lang="less">
    #Specsavers {
        .store-banner{
            background-color: #F5F5F5;
            height: 340px;
            overflow: hidden;
            .fitbit-logo{
                justify-content: center;
                align-items: center;
                max-width: 340px;
                display: flex;
                &:before {
                    position: absolute;
                    content: '';
                    left: 100%;
                    width: 0;
                    height: 0;
                    border-top: 340px solid #F5F5F5;
                    border-right: 340px solid transparent;
                    z-index: 5;
                }
                &:after{
                    position: absolute;
                    content: '';
                    left: calc(100% + 170px);
                    background-color:green;
                    height: 100%;
                    width: 14px;
                    transform: skew(-45deg);
                    z-index: 5;
                }
                img{
                    z-index: 6;
                    width: 100%;
                }
            }
            .banner-image{
                z-index: 0;
                width: 100%;
                height: 340px;
                img{

                }
            }
        }
        .return-link, .description-text{
            font-size: 1.6rem;
        }
        .image-wrap{
            max-width: 220px;
            margin: auto;
            img{
                width: 100%;
            }
        }
        .voucher{
            text-align: center;
        }
        .voucher-unavailable{
            font-size: 1.6rem;
            text-align: center;
            p{
                color: red;
            }
        }
    }
</style>
