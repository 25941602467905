<template>
    <div class="HomePodcastCard">
        <div class="podcast-card-podcast-container shadow position-relative rounded-corners" @click="cardClicked">
            <div class="w-100 h-100 clickable bg-brand-secondary podcast-card-inner" @mouseover="playAudio()" @mouseleave="resetAudio()">
                <img class="img-fluid image-class h-100 mx-auto" :src="podcast.thumbnail" v-if="podcast.thumbnail"/>
                <img class="img-fluid image-class-2 h-100 px-5" :src="type === 'Digital Gym' ? '/img/wellbeing-studio/digital-gym.svg' : '/img/wellbeing-studio/wellbeing-studio.svg'" v-else/>
            </div>
            <Transition><div class="podcast-card-duration animate__animated" v-if="podcast.details.duration && !audioIsPlaying">{{podcast.details.duration}}</div></Transition>
            <div class="podcast-card-featured bg-brand-highlight-1 animate__animated" v-if="!audioIsPlaying">Featured</div>
            <div class="podcast-card-playing bg-brand-highlight-1 animate__animated" v-if="audioIsPlaying">Playing</div>
            <div  class="podcast-card-favourite" v-if="!podcast.favourited"><i @click="toggleFavourite" class="fa-regular fa-heart fa-2x"></i></div>
            <div  class="podcast-card-favourite" v-else><i @click="toggleFavourite" class="fa-solid fa-heart fa-2x"></i></div>
            <Transition>
              <div class="podcast-card-play-button text-center animate__animated" v-if="!audioIsPlaying">
                <i class="fas fa-play-circle fa-5x text-white"></i>
              </div>
            </Transition>
            <div><audio :src="podcast.url" ref="audio"></audio></div>
        </div>
        <div class="podcast-card-meta-container">
            <div class="d-flex align-items-center justify-content-between">
              <div class="podcast-card-series">{{podcast.subtitle}}</div>
              <div>
                <i :id="'myListPc-'+unique_id" class="fa-solid myListBtn mr-4" :class="isItemInMyList ? 'fa-circle-check plus':'fa-circle-plus plus'" @click="watchLater"></i>
                <b-tooltip :target="'myListPc-'+unique_id" triggers="hover" placement="top" custom-class="mylist-tooltip">
                  {{isItemInMyList ? 'Remove from My List' : 'Add to My List'}}
                </b-tooltip>
              </div>
            </div>

            <div class="d-flex flex-row flex-nowrap justify-content-between align-items-center">
                <div class="podcast-card-title brand-text clickable">{{podcast.title}}</div>
                <div class="podcast-card-tags">
                    <div class="podcast-card-tag" v-for="(tag, i) in podcast.tags" :key="i" v-if="tag !== type">#{{ tag }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import config from "@/config/constants";

export default {
    name: "PodcastCard",
    props: ['type', 'podcast','swiping','unique_id'],
    data() {
        return {
          loading: false,
          audioIsPlaying: false
        };
    },
    mounted() {
    },
    computed: {
      product_id () {
        return 18;
      },
      isItemInMyList() {
        const isInList = this.$store.getters.myList.filter(item => item.product_id === this.product_id && item.content_id === this.podcast.details.last_episode_id);
        return isInList.length > 0;
      },
    },
    methods: {
      async watchLater() {
        if(!this.isItemInMyList) {
          await this.$store.dispatch('addMyListItem',({media_type: 'podcast', product_id: this.product_id, content_id: this.podcast.details.last_episode_id}));
        } else {
          const myListRecord = this.$store.getters.myList.filter(item => item.product_id === this.product_id && item.content_id === this.podcast.details.last_episode_id);
          await this.$store.dispatch('deleteMyListItem',({id: myListRecord[0].id}));
        }
      },
      cardClicked() {
        if(this.loading || this.swiping) return;
        this.$router.push("/sound-space/"+this.podcast.id+"?play="+this.podcast.details.last_episode_id +"&back=home");
      },
      async toggleFavourite() {
        this.loading = true;
        const payload = {
          user_id: this.$store.getters.user_id,
          podcast_id: this.podcast.id,
        };
        let res = await axios.post(
            config.api_env + "/application/api/u/podcasts/subscribe",
            payload,
            config.options
        );
        if (res.data.success) {
          if (res.data.msg === "Successfully subscribed") {
            this.podcast.favourited = true;
          } else if (res.data.msg === "Successfully unsubscribed") {
            this.podcast.favourited = false;
          }
        }
        this.loading = false;
      },
      playAudio() {
        this.$refs.audio.play();
        this.audioIsPlaying = true;
      },
      resetAudio() {
        this.$refs.audio.pause();
        this.$refs.audio.currentTime = 0;
        this.audioIsPlaying = false;
      }
    }
};
</script>

<style lang="less">
.HomePodcastCard {
    .rounded-corners {
        border-radius: 10px;
    }
    .podcast-card-podcast-container {
        aspect-ratio: 1/1;
        font-family: "FilsonProBold", sans-serif;
        overflow: hidden;
        margin: 10px;
        .podcast-class{
            display: none;
        }
        /*&:hover {
            .podcast-card-play-button{
                pointer-events: none;
                animation-name: fadeOut;
                animation-duration: 0.3s;
            }
            .podcast-card-featured{
                pointer-events: none;
                animation-name: fadeOut;
                animation-duration: 0.3s;
            }
            .podcast-card-duration{
                pointer-events: none;
                animation-name: fadeOut;
                animation-duration: 0.3s;
            }
            .podcast-class{
                display: block;
            }
        }*/
    }
    .image-class-2{
        aspect-ratio: 1;
        margin: 0 auto;
        background-color: white;
    }
    .podcast-card-play-button {
        position: absolute;
        left: 10px;
        bottom: 10px;
    }
    .podcast-card-favourite {
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 3px 3px 0 3px;
      border-radius: 50%;
      background-color: white;
      color: red;
      &:hover {
        cursor: pointer;
      }
    }
    .podcast-card-playing {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-family: "FilsonProBold", sans-serif;
      position: absolute;
      left: 0;
      top: 0;
      margin-left: 10px;
      margin-top: 10px;
      padding: 5px;
      color: white;
      border-radius: 3px;
      background-color: darkgreen !important;
    }
    .podcast-card-featured {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: "FilsonProBold", sans-serif;
        position: absolute;
        left: 0;
        top: 0;
        margin-left: 10px;
        margin-top: 10px;
        padding: 5px;
        color: white;
        border-radius: 3px;
    }

    .podcast-card-duration {
      font-family: 'FilsonProBold', sans-serif;
      position: absolute;
      bottom: 0;
      right: 0;
      margin-right: 10px;
      margin-bottom: 10px;
      padding: 5px;
      background-color: white;
      color: #555;
      font-size: 1.25em;
      min-width: 55px;
      text-align: center;
      transition: 0.5s;
    }

    .podcast-card-meta-container {
        .podcast-card-series {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 1.4rem;
        }

        .podcast-card-title {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-family: "FilsonProBold", sans-serif;
            font-size: 2.2rem;
        }

        .podcast-card-tags {
            .podcast-card-tag {
                font-size: 1.2rem;
                padding: 3px 8px;
                border: 1px solid #555;
                border-radius: 100px;

                max-width: 100px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

                &:hover {
                    background-color: #f1f1f1;
                    cursor: pointer;
                }
            }
        }
    }
  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
  .myListBtn {
    font-size: 2.4rem;
    color: grey !important;
    cursor: pointer;
  }
  @media only screen and (max-width: 767px) {
    .podcast-card-podcast-container {
      aspect-ratio: 16/9 !important;
    }
  }
}
</style>
