<template>
  <div id="YourActivities">
    <ChallengesHeader :title="sectionTitle" :font-size="'1.5'"/>
    <div v-if="deviceConnected" class="px-5">
      <div class="challengesButton mt-3 mb-3 w-25 syncBtn" @click="sync">Sync
        <i class="fa-solid fa-rotate" :class="{syncing: syncing}"></i>
      </div>
    </div>
    <Integrations v-if="!deviceConnected" @connected="deviceConnected = true"/>
    <div class="activityCardHolder">
      <div v-if="total_activities === 0" class="noActivitiesText">
        You do not have any activities yet!
      </div>
      <div v-for="(activity,i) in activities">
        <ActivitiesCard  :activity="activity" :key="'activity'+i"/>
      </div>
      <b-pagination
          v-if="total_activities && total_activities > activities_per_page"
          v-model="current_page"
          :total-rows="total_activities"
          :per-page="activities_per_page"
          size="lg"
          @change="changePage"
      ></b-pagination>
    </div>
  </div>
</template>

<script>

import ChallengesHeader from "@/components/Challenges/ChallengesHeader.vue";
import Integrations from "@/components/Challenges/YourActivitiesViews/Integrations.vue";
import ActivitiesCard from "@/components/Challenges/YourActivitiesViews/ActivitiesCard.vue";
import Eventbus from "@/helpers/eventbus";
export default {
  name: "YourActivities",
  props: ['activities','deviceConnected','syncing','total_activities', 'activities_per_page'],
  components: {ChallengesHeader, Integrations, ActivitiesCard},
  data(){
    return {
      per_page: 10,
      current_page: 1
    }
  },
  methods: {
    sync() {
      Eventbus.$emit('syncChallengesData');
    },
    changePage(evt) {
      this.$emit('changePage',evt)
    }
  },
  computed: {
    sectionTitle() {
      if(!this.deviceConnected) return "Connect Your Device";
      return "Your Activities";
    }
  }
}
</script>
<style lang="less">
#YourActivities {
  width: 100%;
  min-height: 500px;
  .noActivitiesText {
    color: white;
    font-size: 1.8rem;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .activityCardHolder {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center
  }
  .syncBtn {
    color: white;
    padding: 10px;
  }
  .syncing {
      animation: rotation 2s infinite linear;
  }
  .pagination {
    background-color: black;
    li {
      span, button {
        background-color: black;
        color: white;
      }
    }
    .active {
      button {
        color: white;
        background: linear-gradient(90deg, #6A356A 0%, #ED3A75 100%);
        border-color: white;
      }
    }
    .page-item {
      &.disabled {
        span {
          color: grey !important;
        }
      }
    }
  }
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
}
</style>