<template>
  <div id="LiveEvent">
    <div id="WDLiveEvent">
      <section class="spectrum-event bg-grey" >
        <section class="spectrum-intro">
          <div class="content-container row event-content py-5">
            <div class="col-2 return-wrap">
              <b-button
                  class="return ml-3"
                  @click="goBack()"
                  alt="return to all events"
              >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    viewBox="0 0 50 50"
                >
                  <g>
                    <g>
                      <g>
                        <path
                            class="circle"
                            fill="#d60570"
                            d="M24.805 0c13.698 0 24.804 11.105 24.804 24.804 0 13.699-11.106 24.804-24.804 24.804C11.105 49.608 0 38.503 0 24.804 0 11.105 11.106 0 24.805 0z"
                        />
                      </g>
                      <g>
                        <path
                            fill="none"
                            stroke="#f1f1f1"
                            stroke-linecap="round"
                            stroke-miterlimit="20"
                            stroke-width="2.83"
                            d="M23.137 13.934v0l-8.908 10.028v0l7.793 10.033v0"
                        />
                      </g>
                      <g>
                        <path
                            fill="none"
                            stroke="#f1f1f1"
                            stroke-linecap="round"
                            stroke-miterlimit="20"
                            stroke-width="2.83"
                            d="M14.711 23.906h26.893"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </b-button>
            </div>
            <div class="col-8 event-title pt-5">
              <h2>{{ event.title }}</h2>
            </div>
            <div
                class="col-2 py-3"
            >
              <img
                  src="/img/wellbeing-studio/womens-health.png"
                  style="max-height: 100px"
              />
            </div>
          </div>
        </section>
        <section class="bg-white py-5">
          <div class="content-container event-content row py-5" v-if="!loading">
            <EventVideo :event="event" class="col-12" v-if="shouldRenderIFrame" />
            <span class="not-available" v-else>
            <h3>{{ componentText.unavailable }}</h3>
          </span>
            <div class="col-12">
              <div class="event-info">
                <p class="date-title mt-5">
                  With {{ event.instructor }}
                </p>
                <p class="py-5" v-html="event.description"></p>
                <div class="btn-wrap pb-5" v-if="showReminder">
                  <div v-if="$store.getters.user.access_code_user" class="d-flex align-items-center justify-content-center">
                    <a :href="event.google_cal_link" target="_blank"><b-button class="calBtn btn shadow py-3 px-5 mx-4 my-3 my-md-0 btn-secondary cta-button">
                      Add to Google Calendar
                    </b-button></a>
                    <a :href="event.ical_link" target="_blank"><b-button class="calBtn btn shadow py-3 px-5 mx-4 my-3 my-md-0 btn-secondary cta-button">
                      Add to Outlook Calendar
                    </b-button></a>
                  </div>

                  <b-button v-else
                            @click="remindMe"
                            class="btn shadow py-3 px-5 mx-4 my-3 my-md-0 btn-secondary cta-button"
                            :class="{ 'btn-outline': event.reminder_registered }"
                  >{{
                      !event.reminder_registered
                          ? componentText.remind
                          : componentText.cancel
                    }}</b-button
                  >
                </div>
                <div v-else>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <div v-if="reminderConfirmation" class="log-confirmation">
        {{ componentText.remindConfirm }} <i class="fas fa-check"></i>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import EventVideo from "../components/LiveEvent/EventVideo";
import config from "@/config/constants";
//TODO:: Consider breaking this into laya / spectrum components
export default {
  name: "LiveEvent",
  components: { EventVideo },
  data() {
    return {
      event: {},
      loading: true,
      disclaimerVisible: false,
      reminderConfirmation: false,
      shouldRenderIFrame: false,
    };
  },
  mounted() {
    this.getEvent();
  },
  methods: {
    goBack() {
      this.$router.push("/womens-day-series").catch((err) => {});
    },

    async getEvent() {
      const payload = {
        user_id: this.$store.getters.user_id,
        event_id: this.$route.params.id,
        access_code_user: this.$store.getters.user.access_code_user
      };
      let res = await this.axios.post(
          process.env.VUE_APP_API_PATH +
          "/application/api/u/studio/get-womens-day-virtual-event",
          payload, config.options
      );
      if (res.data.success) {
        this.event = res.data.event;
        this.loading = false;
        this.setRefreshTimer();
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } else {
        this.showMsgModal(
            "Woops, we couldn't find that event. Please try another!"
        ).then(() => {
          this.$route.path.includes("gym")
              ? this.$router.push("/digital-gym")
              : this.$router.push("/womens-day-series");
        });
      }
    },
    async favourite() {
      const payload = {
        user_id: this.$store.getters.user_id,
        event_id: this.$route.params.id,
      };
      let res = await this.axios.post(
          process.env.VUE_APP_API_PATH +
          "/application/api/u/studio/toggle-favourite-gym-event",
          payload, config.options
      );
      if (res.data.success) {
        this.event.is_favourited = res.data.is_favourite;
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      }
    },

    setRefreshTimer() {
      let self = this;
      if (this.event.embed_code === false) {
        const eventStartTime = moment(this.event.start_date_time)
            .subtract(1, "minutes")
            .format("X");
        const now = moment().format("X");
        const diff = eventStartTime - now;
        if (now < eventStartTime && diff * 1000 < 2147483647) {
          setTimeout(function () {
            self.getEvent();
          }, diff * 1000);
        }
      } else {
        this.shouldRenderIFrame = true;
      }
    },

    async remindMe() {
      const payload = {
        user_id: this.$store.getters.user_id,
        event_id: this.$route.params.id,
      };
      if (!this.event.reminder_registered) {
        let res = await this.axios.post(
            process.env.VUE_APP_API_PATH +
            "/application/api/u/studio/register-reminder",
            payload, config.options
        );
        if (res.data.success) {
          this.event.reminder_registered = true;
          this.reminderConfirmation = true;
          setTimeout(() => (this.reminderConfirmation = false), 5000);
        }
      } else {
        let res = await this.axios.post(
            process.env.VUE_APP_API_PATH +
            "/application/api/u/studio/cancel-reminder",
            payload, config.options
        );
        if (res.data.success) {
          this.event.reminder_registered = false;
        }
      }
    },
  },
  computed: {
    videoAvailable() {
      const now = moment();
      const vidAvailable = moment(this.event.start_date_time);
      return now > vidAvailable || now.isSame(vidAvailable, "day");
    },
    showReminder() {
      const now = moment();
      const vidAvailable = moment(this.event.start_date_time);
      return now < vidAvailable;
    },
    decodedEmbed() {
      return this.event.embed_code ? atob(this.event.embed_code) : "";
    },
    componentText() {
      return this.getText.liveEvent;
    },
  },
};
</script>

<style lang="less">
#WDLiveEvent {
  font-size: 1.6rem;
  .spectrum-intro {
    .event-title {
      display: flex;
      justify-content: center;
      align-items: center;
      h2 {
        font-family: FilsonProBold, sans-serif;
        letter-spacing: 0;
        font-size: 2.8rem;
        margin-bottom: 0;
      }
    }
    .return-wrap {
      display: flex;
      align-items: center;
      .return {
        position: unset;
      }
    }
  }
  .event-content {
    position: relative;
    .return {
      background: transparent;
      position: absolute;
      top: 30px;
      left: 30px;
      .circle {
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
      }
      &:hover {
        .circle {
          fill: darken(#d60570, 10%);
        }
      }
    }
    .video-wrap {
      text-align: center;
      max-width: 100%;
      margin: auto;
      .aspect-ratio {
        max-width: 600px;
        width: 100%;
        position: relative;
        margin: auto;
        &:after {
          padding-top: 56.4%;
          display: block;
          content: "";
        }
        iframe {
          width: 100%;
          height: 100%;
          border-radius: 30px;
          border: 1px solid #afb0b2;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }
    }
    .text-link {
      font-family: FilsonProBold;
    }
    #disclaimer {
      max-width: 600px;
      margin: auto;
      h4 {
        font-size: 1.6rem;
        margin-bottom: 10px;
      }
      p,
      li {
        font-size: 1.4rem;
        text-align: left;
      }
    }
    .not-available {
      max-width: 600px;
      margin: auto;
      text-align: center;
      h3 {
        padding: 0;
      }
    }
    .event-info {
      max-width: 600px;
      margin: auto;
      text-align: center;
      svg {
        transform: translateY(-3px);
        margin-right: 5px;
      }
      i {
        font-size: 2.1rem;
        color: #999b9e;
      }
      .date-title {
        font-size: 1.7rem;
        letter-spacing: -0.4px;
      }
      .cta-button {
        font-size: 2rem;
      }
    }
  }
  .spectrum-event {
    .not-available h3 {
      letter-spacing: 0;
      font-size: 2.1rem;
    }
  }
  .log-confirmation {
    font-size: 3.3rem;
    font-family: "FilsonProBold", sans-serif;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    width: 100%;
    text-align: center;
    z-index: 9999;
    padding: 80px 0;
  }
  .calBtn {
    font-size: 1.5rem !important;
  }
  .cta-button{
    padding-top: 12.5px !important;
    padding-bottom: 12.5px !important;
  }
  @media (max-width: 675px) {
    h2 {
      font-size: 3.5rem;
    }
    .event-content {
      .return {
        top: 20px;
        left: 20px;
        svg {
          height: 30px;
          width: 30px;
        }
      }
    }
  }
}
</style>
