<template>
  <div id="TherapyTerms" class="p-5" :class="{locked: isAccepted, whiteBg: therapy_type}">
    <div class="content-container position-relative" v-if="!loading">
      <button class="therapyBtn2" @click="$router.push('/start')" style="width: 150px; position: absolute; right: 20px; top: 20px" v-if="isAccepted && !therapy_type && !this.standAloneMode">Back</button>
      <h3 ref="title">Our Agreement</h3>

      <p>{{applicableHeadingText}}</p>

      <div v-for="(term,i) in applicableAgreement">
        <h4>{{i + 1}}. {{term.title}}</h4>
        <div v-html="term.html"></div>
        <div class="termsTickBoxRow d-flex">
          <span class="mr-3" v-if="isSpecialistAssessment">By continuing with your booking , you are agreeing to the terms and conditions</span>
          <span class="mr-3" v-else>{{ term.acceptance_text ? term.acceptance_text : 'Ticking this box indicates that you have read, understood and agree to this' }}</span>
          <div class="checkBoxBtn" :class="{selected: term.accepted || isAccepted}" @click="toggleTermAccepted(i)" v-if="!isSpecialistAssessment">
            <i class="fa-solid fa-check"></i>
          </div>
        </div>
      </div>
      <h4>{{applicableAgreement.length + 1}}. Privacy Statement and Terms and Conditions</h4>
      <div class="termsTickBoxRow d-flex">
        <span class="mr-3">{{ isSpecialistAssessment ? 'By continuing with your booking , you are agreeing to our ' :'I agree to the ' }}<a href="/privacy-policy/" target="_blank">privacy statement</a></span>
        <div v-if="!isSpecialistAssessment" class="checkBoxBtn" :class="{selected: acceptedPrivacy || isAccepted}" @click="acceptedPrivacy = !acceptedPrivacy">
          <i class="fa-solid fa-check"></i>
        </div>
      </div>
      <div class="termsTickBoxRow d-flex">
        <span class="mr-3">{{ isSpecialistAssessment ? 'By continuing with your booking , you are agreeing to our ' :'I agree to the ' }}<a href="/terms-and-conditions/" target="_blank">terms and conditions</a></span>
        <div v-if="!isSpecialistAssessment" class="checkBoxBtn" :class="{selected: acceptedTandCs || isAccepted}" @click="acceptedTandCs = !acceptedTandCs">
          <i class="fa-solid fa-check"></i>
        </div>
      </div>
      <button class="therapyBtn my-5" @click="acceptAll" v-if="!isAccepted && !isSpecialistAssessment">Accept All Terms</button>
      <div class="mb-5" v-if="!isSpecialistAssessment">
        <label>Date Completed</label>
        <div class="today">{{ isAccepted ? completionDate : today }}</div>
        <div class="signatureHolder mt-3">
          <label>{{ isAccepted ? 'Signature' : 'Add Signature' }}</label><br>
          <input class="signatureInput mt-2" v-model="signature" :disabled="isAccepted"/>
        </div>
      </div>

      <div class="mt-5 d-flex justify-content-start btnBox" v-if="!standAloneMode">
        <button class="therapyBtn" :disabled="buttonDisabled" @click="submitForm">{{isAccepted ? 'Close' : 'Submit Form' }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment/moment";
import EventBus from "@/helpers/eventbus";
import router from "../../router";

export default {
  name: "TherapyTerms",
  props: {
    therapy_type: {
      default: null
    },
    age_group: {
      default: null
    },
    service_name: {
      default: null
    }
  },
  data() {
    return {
      loading: true,
      isAccepted: false,
      completionDate: '',
      acceptedTandCs: false,
      acceptedPrivacy: false,
      signature: '',
      terms: {
        therapy: {
          adult: [
            {accepted: false, acceptance_text: null, title: 'First Steps: Specialist Assessment Session', html: `<p>The first step for the digital therapy service we offer for adults is a Specialist Assessment, where we explore the presenting issue(s) in more detail in order to determine the most appropriate next steps for your needs. In certain cases, we may conclude that we are unable to offer what we feel is required and in this situation we will do our best to signpost to another appropriate service/provider.</p>`},
            {accepted: false, acceptance_text: null, title: 'Next Steps', html: `<p>Following the Specialist Assessment, if we are able to help then we offer various different types of support and therapeutic intervention that we will discuss in more detail with you depending on what therapeutic approach we feel would be of benefit.  </p><p>We will always explain what we believe is the best plan of care for your needs and agree this collaboratively with you with your hopes and goals in mind. </p>`},
            {accepted: false, acceptance_text: null, title: 'Sessions and Appointments ', html: `<p>Appointment duration will vary according to what the purpose of the session is. </p><ul><li>Specialist assessment appointments (the first session) will last around one hour. </li><li>Therapy and intervention appointments will ordinarily be 50 minutes in duration, once per week or fortnight, at a time agreed with your practitioner.</li></ul>`},
            {accepted: false, acceptance_text: null, title: 'Cancellation Policy', html: `<p>As your session time will be kept for you, please provide at least 24 hours’ notice if you need to cancel or reschedule. Cancellation with less than 24 hours’ notice will be considered a used session as it is very difficult to fill a slot at such short notice.  </p><p>If you fail to attend two appointments from a scheduled series of appointments without providing at least 24 hours’ notice of cancellation prior to the time of the appointment, your practitioner has the right to discharge you with immediate effect. In this instance all remaining appointments will be cancelled.  </p>`},
            {accepted: false, acceptance_text: null, title: 'Professional Records', html: `<p>It is a requirement for us to keep appropriate records of our sessions. Your records are maintained by your practitioner and are only accessible to the clinical team within the service. In line with data protection legislation, information is stored confidentially on a computer, secured by the use of passwords and a firewall with a secure access log, and is only shared in very specific and tightly controlled circumstances with your consent.</p>`},
            {accepted: false, acceptance_text: null, title: 'Use of Your Data', html: `<p>Your practitioner may collect a small amount of data to help us understand if services are being effective. This data will be utilised and reviewed with you during sessions and will be held within your record.</p><p>From time to time, we may utilise data gathered from clients across our service on an aggregated basis to assist with clinical audit and service improvement. Overall results from analysis of this aggregated data may sometimes be released into the public sphere to assist with clinical research and business development, with your data forming a very small part of a large dataset and in no way being individually identifiable to anyone outside of the service. </p>`},
            // {accepted: false, acceptance_text: null, title: 'Recording Sessions ', html: `<p>With your consent, we may record sessions for the purposes of quality assurance, service improvement and training. This ensures that we are able to provide the highest quality, most effective clinical care. Anyone viewing a recording is bound to keep its contents confidential and it will be kept and handled in a secure manner. </p><p>The recording will be used for no other purpose than described without your permission and you have the right to revoke this consent at any time by informing the practitioner you are working with. In this event, all session recordings will be permanently erased.</p><p>All recorded content will be erased routinely after 6 months. </p>`},
            {accepted: false, acceptance_text: null, title: 'Confidentiality ', html: `<p>As a general rule, your practitioner will keep the information you share with them in your sessions private.</p><p>There are, however, exceptions to this rule that are important for you to understand before you share your personal information in a session:</p><ul><li>If you are doing things that could cause serious harm to you or someone else, even if you do not intend to harm yourself or another person. This may include anything which the law states is an offence.</li><li>If you tell us you plan to cause serious harm to yourself or plan to end your life. In this situation, we must make sure that you are protected.</li><li>If you tell us that you plan to cause serious harm to someone else who can be identified.</li><li>If you tell us that a person under 18 years old is being abused – physically, sexually or emotionally – or that they have been abused in the past.</li><li>A legal order is issued overruling confidentiality.</li></ul><p>It is important to understand that in the situations mentioned above, this would be openly discussed with you where possible if any of the above issues come up.</p>`},
            {accepted: false, acceptance_text: null, title: 'Consent to agreement and terms ', html: `
<p>As a general rule, your practitioner will keep the information you share with them in your sessions private.</p><ol><li>There are however exceptions to this rule that are important for you to understand before you share your personal information in a session. These are:</li><li>If a legal order is issued overruling confidentiality.</li><li>It is important to understand that in the exceptions mentioned above, this will be openly discussed with you where possible, should any of the exceptions arise.</li><ol/>
`},
            {accepted: false, acceptance_text: 'Ticking this box indicates that you have read, understood and consent to us communicating with your Family Doctor / GP ', title: 'Details of GP / Family Doctor', html: `<ul><li>Name of GP/Family Doctor</li><li>Name of Surgery/Practice </li><li>Address of Surgery/Practice </li><li>Telephone number of Surgery/Practice </li></ul>`},
          ],
          child: [
            {accepted: false, acceptance_text: null, title: 'First Steps: Specialist Assessment Session ', html: `<p>The first step for any input in relation to children and young people’s wellbeing is a specialist assessment where we explore the presenting issue(s) in more detail in order to determine the most appropriate service for you and/or your child’s needs. In certain cases, we may conclude that we are unable to offer what we feel is required and in this situation we will do our best to signpost to another appropriate service/provider. </p>`},
            {accepted: false, acceptance_text: null, title: 'Next Steps', html: `<p>Following the specialist assessment, if we are able to help then we offer various different types of therapy, support and intervention that we will discuss in more detail with you if we feel that these would be of benefit.  </p><p>We will always explain what we believe is the best plan of care for you and/or your child’s needs and agree this collaboratively with you and/or your child with your hopes and goals in mind. </p>`},
            {accepted: false, acceptance_text: null, title: 'Sessions and Appointments', html: `<p>Appointment duration will vary according to what the purpose of the session is.</p><ul><li>Specialist assessment appointments (the first session) will last around one hour.</li><li>Therapy and intervention appointments will ordinarily be 50 minutes in duration, once per week, at a time agreed with your practitioner.</li></ul>`},
            {accepted: false, acceptance_text: null, title: 'Cancellation Policy', html: `<p>As your session time will be kept for you, please provide<span> at least 24 hours’ notice</span> if you need to cancel or reschedule. Cancellation with less than 24 hours’ notice will be considered a used session as it is very difficult to fill a slot at such short notice.</p><p>If you fail to attend two appointments from a scheduled series of appointments without providing at least 24 hours’ notice of cancellation prior to the time of the appointment, your practitioner has the right to discharge you with immediate effect. In this instance all remaining appointments will be cancelled.</p>`},
            {accepted: false, acceptance_text: null, title: 'Professional Records', html: `<p>It is a requirement for us to keep appropriate records of our sessions. Your records are maintained by your practitioner and are only accessible to the clinical team within the service. In line with data protection legislation, information is stored confidentially on a computer, secured by the use of passwords and a firewall with a secure access log, and is only shared in very specific and tightly controlled circumstances with your consent.</p>`},
            {accepted: false, acceptance_text: null, title: 'Use of Your Data', html: `<p>Your practitioner may collect a small amount of data to help us understand if services are being effective. This data will be utilised and reviewed with you during sessions and will be held within your records.</p><p>From time to time, we may utilise data gathered from clients across our service on an aggregated basis to assist with clinical audit and service improvement. Overall results from analysis of this aggregated data may sometimes be released into the public sphere to assist with clinical research and business development, with your data forming a very small part of a large dataset and in no way being individually identifiable to anyone outside of the service.</p>`},
            // {accepted: false, acceptance_text: null, title: 'Recording Sessions', html: `<p>With your consent, we may record sessions for the purposes of quality assurance, service improvement and training. This ensures that we are able to provide the highest quality, most effective clinical care. Anyone viewing a recording is bound to keep its contents confidential and it will be kept and handled in a secure manner.</p><p>The recording will be used for no other purpose than described without your permission and you have the right to revoke this consent at any time by informing the practitioner you are working with. In this event, all session recordings will be permanently erased.</p><p>All recorded content will be erased routinely after 6 months. </p>`},
            {accepted: false, acceptance_text: null, title: 'Confidentiality', html: `<p>As a general rule, your practitioner will keep the information you share with them in your sessions private.</p><p>There are however exceptions to this rule that are important for you to understand before you share your personal information in a session. These are:</p><ol><li>If a legal order is issued overruling confidentiality.</li><li>It is important to understand that in the exceptions mentioned above, this will be openly discussed with you where possible, should any of the exceptions arise.</li><ol/>`},
            {accepted: false, acceptance_text: null, title: 'Communicating with parents/carers/guardians', html: `<p>Except for situations such as the ones mentioned above, practitioners will discuss with children and young people what the child/young person would like to share with their parents/carers/guardians, and how. The practitioner may recommend that it would be best that parents/carers/guardians are contacted and involved. This will always be decided collaboratively.</p>`},
            {accepted: false, acceptance_text: null, title: 'Parents/Carers/Guardians', html: `<p class="bold">Please note all parents/carers/guardians must understand the below and tick the box to indicate:</p><ul><li>I have read and understood the above agreement completely and agree to its terms.</li><li>I understand that the practitioner is responsible for maintaining all professional standards set forth in the ethical principles of their professional association as well as through legislation.</li><li>I understand that all information shared between the practitioner and my child is confidential and no information will be released without our consent, where possible.</li></ul>`},
            {accepted: false, acceptance_text: 'Ticking this box indicates that you have read, understood and consent to us communicating with your Family Doctor / GP ', title: 'Details of GP / Family Doctor', html: `<ul><li>Name of GP/Family Doctor </li><li>Name of Surgery/Practice </li><li>Address of Surgery/Practice </li><li>Telephone number of Surgery/Practice</li></ul>`},
          ],
        },
        neurodiversity: {
          adult: [
            {accepted: false, acceptance_text: null, title: 'First Steps: Initial Consultation or Specialist Assessment Session ', html: `<p>The first step for the assessment of neurodivergence we offer for adults is either an Initial Consultation (where there is a clear indication for a specific type of assessment) or a Specialist Assessment, where we explore the presenting issue(s) in more detail in order to determine the most appropriate next steps for your needs. In certain cases, we may conclude that we are unable to offer what we feel is required and in this situation we will do our best to signpost to another appropriate service/provider. </p>`},
            {accepted: false, acceptance_text: null, title: 'Next Steps ', html: `<p>Following the Initial Consultation / Specialist Assessment, if we are able to help then we offer various different types of assessment, support and intervention that we will discuss in more detail with you if we feel that these would be of benefit.</p><p>Further assessment may be recommended following the Initial Consultation / Specialist Assessment, such as exploration of neurodivergence (e.g. autism, ADHD). These are packages of assessment for specific presenting issues and will be discussed with you in terms of what they involve and what is required during the Initial Consultation / Specialist Assessment. Once an assessment has been completed, additional therapy, support, or intervention may also be recommended.</p><p>We will always explain what we believe is the best plan of care for your needs and agree this collaboratively with you with your hopes and goals in mind.</p>`},
            {accepted: false, acceptance_text: null, title: 'Sessions and Appointments', html: `<p>Appointment duration will vary according to what the purpose of the session is. </p><ul><li>Specialist assessment appointments (the first session) will last around one hour. </li><li>Therapy and intervention appointments will ordinarily be 50 minutes in duration, once per week, at a time agreed with your practitioner. </li><li>Sessions that form part of a neurodivergence assessment will vary in length depending on which part of the assessment is being undertaken. This will be explained to you before the assessment commences.</li></ul>`},
            {accepted: false, acceptance_text: null, title: 'Cancellation Policy', html: `<p>As your session time will be kept for you, please provide<span> at least 24 hours’ notice</span> if you need to cancel or reschedule. Cancellation with less than 24 hours’ notice will be considered a used session as it is very difficult to fill a slot at such short notice.</p><p>If you fail to attend two appointments from a scheduled series of appointments without providing at least 24 hours’ notice of cancellation prior to the time of the appointment, your practitioner has the right to discharge you with immediate effect. In this instance all remaining appointments will be cancelled.</p>`},
            {accepted: false, acceptance_text: null, title: 'Professional Records', html: `<p>It is a requirement for us to keep appropriate records of our sessions. Your records are maintained by your practitioner and are only accessible to the clinical team within the service. In line with data protection legislation, information is stored confidentially on a computer, secured by the use of passwords and a firewall with a secure access log, and is only shared in very specific and tightly controlled circumstances with your consent.</p>`},
            {accepted: false, acceptance_text: null, title: 'Use of Your Data', html: `<p>Your practitioner may collect a small amount of data to help us understand if services are being effective. This data will be utilised and reviewed with you during sessions and will be held within your record.</p><p>From time to time, we may utilise data gathered from clients across our service on an aggregated basis to assist with clinical audit and service improvement. Overall results from analysis of this aggregated data may sometimes be released into the public sphere to assist with clinical research and business development, with your data forming a very small part of a large dataset and in no way being individually identifiable to anyone outside of the service.</p>`},
            // {accepted: false, acceptance_text: null, title: 'Recording Sessions', html: `<p>With your consent, we may record sessions for the purposes of quality assurance, service improvement and training. This ensures that we are able to provide the highest quality, most effective clinical care. Anyone viewing a recording is bound to keep its contents confidential and it will be kept and handled in a secure manner.  </p><p>The recording will be used for no other purpose than described without your permission and you have the right to revoke this consent at any time by informing the practitioner you are working with. In this event, all session recordings will be permanently erased.  </p><p>All recorded content will be erased routinely after 6 months</p>`},
            {accepted: false, acceptance_text: null, title: 'Confidentiality', html: `<p>As a general rule, your practitioner will keep the information you share with them in your sessions private.</p><p>There are however exceptions to this rule that are important for you to understand before you share your personal information in a session. These are:</p><ol><li>If a legal order is issued overruling confidentiality.</li><li>It is important to understand that in the exceptions mentioned above, this will be openly discussed with you where possible, should any of the exceptions arise.</li><ol/>`},
            {accepted: false, acceptance_text: null, title: 'Consent to agreement and terms', html: `<p class="bold">Please you must read and understand the below and tick the box to indicate:</p><ul><li>I have read and understood the above agreement completely and agree to its terms.</li><li>I understand that the practitioner is responsible for maintaining all professional standards set forth in the ethical principles of their professional association as well as through legislation. </li><li>I understand that all information shared between the practitioner and myself is confidential and no information will be released without my consent, where possible.</li></ul>`},
            {accepted: false, acceptance_text: 'Ticking this box indicates that you have read, understood and consent to us communicating with your Family Doctor / GP ', title: 'Details of GP / Family Doctor ', html: `<ul><li>Name of GP/Family Doctor </li><li>Name of Surgery/Practice</li><li>Address of Surgery/Practice </li><li>Telephone number of Surgery/Practice </li></ul>`},
          ],
          child: [
            {accepted: false, acceptance_text: null, title: 'First Steps: Initial Consultation or Specialist Assessment Session', html: `<p>The first step for the assessment of neurodivergence we offer for children and young people is either an Initial Consultation (where there is a clear indication for a specific type of assessment) or a Specialist Assessment, where we explore the presenting issue(s) in more detail in order to determine the most appropriate next steps for your child’s needs. In certain cases, we may conclude that we are unable to offer what we feel is required and in this situation we will do our best to signpost to another appropriate service/provider. </p>`},
            {accepted: false, acceptance_text: null, title: 'Next Steps ', html: `<p>Following the Initial Consultation / Specialist Assessment, if we are able to help then we offer various different types of assessment, support and intervention that we will discuss in more detail with you if we feel that these would be of benefit.  </p><p>Further assessment may be recommended following the Initial Consultation / Specialist Assessment, such as exploration of neurodivergence (e.g. autism, ADHD). These are packages of assessment for specific presenting issues and will be discussed with you in terms of what they involve and what is required during the Initial Consultation / Specialist Assessment. Once an assessment has been completed, additional therapy, support, or intervention may also be recommended.  </p><p>We will always explain what we believe is the best plan of care for your child and family’s needs and agree this collaboratively with you and your child with your hopes and goals in mind. </p>`},
            {accepted: false, acceptance_text: null, title: 'Sessions and Appointments', html: `<p>Appointment duration will vary according to what the purpose of the session is:</p><ul><li>Initial Consultation / Specialist Assessment appointments (the first session) will last around one hour.</li><li>Sessions that form part of a neurodivergence assessment will vary in length depending on which part of the assessment is being undertaken. This will be explained to you before the assessment commences.</li></ul>`},
            {accepted: false, acceptance_text: null, title: 'Cancellation Policy', html: `<p>As your session time will be kept for you, please provide <span>at least 24 hours’ notice</span> if you need to cancel or reschedule. Cancellation with less than 24 hours’ notice will be considered a used session as it is very difficult to fill a slot at such short notice.</p><p>If you fail to attend two appointments from a scheduled series of appointments without providing at least 24 hours’ notice of cancellation prior to the time of the appointment, your practitioner has the right to discharge you with immediate effect. In this instance all remaining appointments will be cancelled. </p>`},
            {accepted: false, acceptance_text: null, title: 'Professional Records', html: `<p>It is a requirement for us to keep appropriate records of our sessions. Your records are maintained by your practitioner and are only accessible to the clinical team within the service. In line with data protection legislation, information is stored confidentially on a computer, secured by the use of passwords and a firewall with a secure access log, and is only shared in very specific and tightly controlled circumstances with your consent.</p>`},
            {accepted: false, acceptance_text: null, title: 'Use of Your Data', html: `<p>Your practitioner may collect a small amount of data to help us understand if services are being effective. This data will be utilised and reviewed with you and your child during sessions and will be held within your child’s record.</p><p>From time to time, we may utilise data gathered from clients across our service on an aggregated basis to assist with clinical audit and service improvement. Overall results from analysis of this aggregated data may sometimes be released into the public sphere to assist with clinical research and business development, with your data forming a very small part of a large dataset and in no way being individually identifiable to anyone outside of the service.</p>`},
            // {accepted: false, acceptance_text: null, title: 'Recording Sessions', html: `<p>With your consent, we may record sessions for the purposes of quality assurance, service improvement and training. This ensures that we are able to provide the highest quality, most effective clinical care. Anyone viewing a recording is bound to keep its contents confidential and it will be kept and handled in a secure manner.</p><p>The recording will be used for no other purpose than described without your permission and you have the right to revoke this consent at any time by informing the practitioner you are working with. In this event, all session recordings will be permanently erased.</p><p>All recorded content will be erased routinely after 6 months.</p>`},
            {accepted: false, acceptance_text: null, title: 'Confidentiality', html: `<p>As a general rule, your practitioner will keep the information you share with them in your sessions private.</p><p>There are however exceptions to this rule that are important for you to understand before you share your personal information in a session. These are:</p><ol><li>If a legal order is issued overruling confidentiality.</li><li>It is important to understand that in the exceptions mentioned above, this will be openly discussed with you where possible, should any of the exceptions arise.</li><ol/>`},
            {accepted: false, acceptance_text: null, title: 'Communicating with parents/carers/guardians', html: `<p>Except for situations such as the ones mentioned above, practitioners will discuss with children and young people what they would like to share with their parents/carers/guardians, and how. The practitioner may recommend that it would be best that parents/carers/guardians are contacted and involved. This will always be decided collaboratively.</p>`},
            {accepted: false, acceptance_text: null, title: 'Parents/Carers/Guardians', html: `<p><span>Please note all parents/carers/guardians must understand the below and tick the box to indicate: </span></p><ul><li>I have read and understood the above agreement completely and agree to its terms.</li><li>I understand that the practitioner is responsible for maintaining all professional standards set forth in the ethical principles of their professional association as well as through legislation.</li><li>I understand that all information shared between the practitioner and my child is confidential and no information will be released without our consent, where possible.</li></ul>`},
            {accepted: false, acceptance_text: 'Ticking this box indicates that you have read, understood and consent to us communicating with your Family Doctor / GP ', title: 'Details of GP / Family Doctor ', html: `<ul><li>Name of GP/Family Doctor </li><li>Name of Surgery/Practice</li><li>Address of Surgery/Practice </li><li>Telephone number of Surgery/Practice </li></ul>`},
          ],
        }
      },
      services: {},
      headingText: {
        therapy: {
          adult: 'This agreement sets out the terms and conditions of the digital therapy sessions we offer adults here at Spectrum.Life. This must be completed prior to the commencement of any input from our practitioners. ',
          child: 'This agreement sets out the terms and conditions of the digital therapy sessions we offer children, young people and their families here at Spectrum.Life. This must be completed prior to the commencement of any input from our practitioners. '
        },
        neurodiversity: {
          adult: 'This agreement sets out the terms and conditions of the neurodiversity assessment and intervention sessions we offer adults here at Spectrum.Life. This must be completed prior to the commencement of any input from our practitioners.',
          child: 'This agreement sets out the terms and conditions of the neurodiversity assessment and intervention sessions we offer children, young people and their families here at Spectrum.Life. This must be completed prior to the commencement of any input from our practitioners. '
        }
      }
    }
  },
  mounted() {
    this.getAgreement();
  },
  computed: {
    isSpecialistAssessment() {
      return this.$route.params.slug === 'cmh_assessment';
    },
    standAloneMode() {
      return this.$route.name === 'therapyAgreement';
    },
    therapy() {
      if(this.isSpecialistAssessment) {
        return {
          area: 'Therapy',
          age_group: 'Adult'
        }
      }
      if(this.standAloneMode) {
        const i = this.services.findIndex(svc => svc.global_event_category === this.$route.params.slug);
        return this.services[i]
      }
      if(this.therapy_type === null || this.age_group === null) {
        return this.$store.getters.user.therapy;
      }
      return {
        area: this.therapy_type,
        age_group: this.age_group
      }
    },
    applicableHeadingText() {
      return this.headingText[this.therapy.area.toLowerCase()][this.therapy.age_group.toLowerCase()];
    },
    applicableAgreement() {
      return this.terms[this.therapy.area.toLowerCase()][this.therapy.age_group.toLowerCase()];
    },
    buttonDisabled() {
      if(this.isAccepted) return false;
      if(!this.signature.length) return true;
      if(!this.acceptedPrivacy || !this.acceptedTandCs) return true;
      let disabled = false;
      this.applicableAgreement.map(term => {
        if(!term.accepted) disabled = true;
      })
      return disabled;
    },
    today() {
      return moment().format('DD/MM/YYYY');
    }
  },
  methods: {
    async getServices() {
      const res = await this.api({
        path: "api/u/cmh/get-services-in-progress"
      });
      this.services = res.data;
      this.loading = false;
    },
    async getAgreement(disable_matomo_firing = false) {
      const res = await this.api({
        path: 'api/u/cmh/get-agreement'
      })
      if(res.success) {
        if(res.data.length) {
          res.data.forEach(agreement => {
            if (agreement.service_category_slug === this.$route.params.slug) {
              this.isAccepted = true;
              this.signature = agreement.signature;
              this.completionDate = agreement.created_on;
            }
          })
          if(!disable_matomo_firing) {
            this.m(this.isAccepted ? 'agreement-form-review' : 'agreement-form-start', {service: this.service_name})
          }
        }
      }
      if(this.standAloneMode) {
        await this.getServices();
      } else {
        this.loading = false;
      }
    },
    acceptAll() {
      this.terms[this.therapy.area.toLowerCase()][this.therapy.age_group.toLowerCase()].forEach(term => term.accepted = true);
      this.acceptedPrivacy = true;
      this.acceptedTandCs = true;
    },
    toggleTermAccepted(i) {
      if(this.isAccepted) return;
      this.terms[this.therapy.area.toLowerCase()][this.therapy.age_group.toLowerCase()][i].accepted = !this.terms[this.therapy.area.toLowerCase()][this.therapy.age_group.toLowerCase()][i].accepted;
    },
    async submitForm() {
      if(this.isAccepted) {
        EventBus.$emit('toggleViewAgreement', true, true);
        return;
      }
      const res = await this.api({
        path: 'api/u/cmh/save-agreement',
        signature: this.signature,
        gl_category_slug: this.$route.params.slug
      });
      if(this.isAccepted && !this.therapy_type)  {
        this.$router.push('/start');
      }
      if(res.success) {
        this.m('agreement-form-sucessful-submit',{service: this.service_name})
        await this.getAgreement(true);
      }
    }
  },
  watch: {
    loading() {
      this.$nextTick(()=> {
        this.$refs.title.scrollIntoView({behavior: 'smooth'});
      })
    }
  }
}
</script>

<style lang="less">
#TherapyTerms {
  background-color: #F3F5F8;
  &.whiteBg {
    background-color: transparent;
  }
  &.locked {
    .checkBoxBtn {
      background-color: grey !important;
      border-color: white;
      cursor: initial !important;
    }
  }
  p {
    &.bold, span {
      font-family: FilsonProBold, Arial, Helvetica, sans-serif;
    }
    font-size: 1.3rem !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    max-width: 100% !important;
  }
  ol {
    max-width: 100% !important;
  }
  ul li, ol li {
    font-size: 1.3rem !important;
    color: #394961;
    width: 100%;
  }
  .signatureInput {
    border: 1px solid #F0EFEF;
    width: 300px;
    padding: 20px 10px 20px 15px;
    font-size: 2rem;
    line-height: 3.2rem;
    font-family: "cedarville-cursive",serif !important;
  }
  .termsTickBoxRow {
    font-size: 1.3rem;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }
  .today {
    margin-top: 8px;
    font-size: 1.25rem;
  }
}
</style>