<template>
  <div id="mindfulness">
    <div class="page-header bg-transparent">
      <div class="content-container row">
        <b-row class="topCard" id="topCard">
          <b-col class="topCardText d-flex flex-column justify-content-between p-5" offset-md="1" sm="12" md="4">
            <div class="topCardTitle">Mindfulness with Bressi</div>
            <div class="iconRow d-flex ">
              <div class="mr-3"><i class="fas fa-podcast fa-lg"></i> 4 Episode</div>
              <div><i class="fas fa-eye fa-lg"></i> 65 Views</div>
            </div>
            <p>Neuroscientists tell us repeatedly that we have an old brain for a new world – one that has not yet evolved sufficiently to keep up...</p>
            <div><b-btn class="playBtn px-5 py-4" @click="toggleVideoMode">{{videoStatus === 'Paused'?'Play':'Pause'}} Episode {{selectedEpisode.episodeNumber}} <i :class="videoClass"></i></b-btn></div>
          </b-col>
          <b-col class="d-flex align-items-center justify-content-center" sm="12" md="6">
            <div>
              <video
                  width="100%"
                  controls
                  :src="selectedEpisode.link"
                  ref="episodeVideo"
                  @pause="setVideoStatus"
                  @play="setVideoStatus"
              >
              </video>
            </div>
          </b-col>
        </b-row>

        <b-row class="episodeContainer">
          <b-row v-for="(episode,i) in episodes" class="episodeCard" :key="'epi-'+i">
            <b-col cols="3" class="episodeImageContainer d-flex align-items-center justify-content-center">
              <img src="/img/bressi.jpg">
            </b-col>
            <b-col cols="8" class="d-flex episodeText flex-column">
              <div class="episodeTitle">{{episode.title}}</div>
              <div class="d-flex mt-2">
                <div class="mr-4 episodeDate"><i class="fa-solid fa-calendar mr-2"></i>{{episode.date}}</div>
                <div class="episodeDuration"><i class="fa-solid fa-clock mr-2"></i>{{episode.duration}}</div>
              </div>
              <div class="episodeDescription mt-2">{{episode.description}}</div>
            </b-col>
            <b-col cols="12" class="d-flex justify-content-end episodeBtnRow">
              <b-btn class="mr-5" @click="changeSelectedEpisode(episode.episodeNumber)">Watch Now <i class="fa-solid fa-play ml-3"></i></b-btn>
            </b-col>
          </b-row>

        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "mindfulness",
  data() {
    return {
      videoStatus: 'Paused',
      selectedEpisodeNumber: 1,
      episodes: [
        {
          title: "Autopilot",
          date: "03/10/22",
          duration: "19:55",
          episodeNumber: 1,
          description: "We often apply archaic and automatic decision-making processes (fight or flight) to challenging situations. This session supports the development of skills to pay full conscious attention to the here and now - to begin making healthier, more consciously aware choices.",
          link: "https://spectrum-life-media.s3.eu-west-1.amazonaws.com/CONTENT/WebinarRecordings/Building+New+Routines+and+Responding+to+Change+-+Meeting+Recording.mp4"
        },
        {
          title: "We see the world not as it is, but as we are",
          date: "10/10/22",
          duration: "35:40",
          episodeNumber: 2,
          description: "How we view the world through our own unique lens and filters, quite often dictates our emotional responses to challenging situations. This session trains in how to fire and wire new neural pathways - to better manage future challenging situations.",
          link: "https://spectrum-life-media.s3.eu-west-1.amazonaws.com/CONTENT/WebinarRecordings/Managing+Stress+-+Meeting+Recording.mp4"
        },
        {
          title: "The Aversion Diversion",
          date: "03/10/22",
          duration: "19:55",
          episodeNumber: 3,
          description: "Our culture facilitates addictive behavioural patterns that allow us to escape from stress temporarily. This session shows how stress and anxiety escalate if we avoid facing them – and how to alter the connection with thoughts and feelings, so they no longer hold you back.",
          link: "https://spectrum-life-media.s3.eu-west-1.amazonaws.com/CONTENT/WebinarRecordings/Understanding+Anxiety+-+Meeting+Recording.mp4"
        },
        {
          title: "We see the world not as it is, but as we are",
          date: "10/10/22",
          duration: "35:40",
          episodeNumber: 4,
          description: "Repeated exposure to the same thoughts or behaviours gets bedded down neuro-physiologically. This session trains in how to change these neural pathways, and by consequence, their relationship to stress – to build a stronger sense of self and mindful mastery in decision-making.",
          link: "https://spectrum-life-media.s3.eu-west-1.amazonaws.com/CONTENT/WebinarRecordings/Building+New+Routines+and+Responding+to+Change+-+Meeting+Recording.mp4"
        }
      ],
      }
    },
  methods: {
    changeSelectedEpisode(newNum) {
      this.selectedEpisodeNumber = newNum;
      document.getElementById('mindfulness').scrollIntoView({ behavior: 'smooth' });
      this.$refs.episodeVideo.pause();
      this.setVideoStatus();
    },
    setVideoStatus() {
      if(this.$refs.episodeVideo.paused) {
        this.videoStatus = "Paused";
      } else {
        this.videoStatus = "Playing";
      }
    },
    toggleVideoMode() {
      if(this.$refs.episodeVideo.paused) {
        this.$refs.episodeVideo.play();
        this.videoStatus = "Playing";
      } else {
        this.$refs.episodeVideo.pause();
        this.videoStatus = "Paused";
      }
    }
  },
  computed: {
    selectedEpisode() {
      return this.episodes[this.selectedEpisodeNumber - 1];
    },
    videoClass() {
      if(this.videoStatus === 'Paused') {
        return 'fa-solid fa-play ml-3'
      } else {
        return 'fa-solid fa-pause ml-3'
      }
    }
  }
};
</script>
<style lang="less">
#mindfulness{
  font-family: ff-sari-web-regular, sans-serif;
  color: #3A4961;
  .page-header {
    padding: 60px 0 0 0 ;
  }
  .topCard {
    video {
      border: 4px solid #426CAF;
    }
    .topCardText {
      .iconRow {
        font-size: 1.4em;
      }
      p {
        font-family: ff-sari-web-regular, sans-serif;
        font-size: 1.4em;
      }
      .topCardTitle {
        font-size: 2.8em;
        font-family: ff-sari-web-bold;
      }
      .playBtn {
        font-size: 1.4em;
      }
    }
    margin: auto;
  }
  .episodeContainer {
    box-sizing: border-box;
    margin: 100px auto;
    .episodeCard {
      border: 1px solid #eaeaea;
      margin: 0px auto 40px;
      border-radius: 30px;
      box-shadow: 0 .5rem 1.2rem rgba(0,0,0,.2);
      padding-top: 30px;
      max-width: 800px;
      .episodeText {
        padding-top: 25px;
        .episodeTitle {
          font-family: ff-sari-web-bold;
          font-size: 1.9em;
        }
        .episodeDescription {
          font-size: 1.4em;
          font-family: ff-sari-web-regular, sans-serif;
        }
        .episodeDate, .episodeDuration {
          font-size: 1.1em;
          font-family: ff-sari-web-regular, sans-serif;
        }
      }
      .episodeBtnRow {
        padding: 20px;
      }
      .episodeImageContainer {
        padding: 20px 20px 0px 20px;
      img {
        max-width: 100%;
      }
    }
    }


  }
}
</style>

