<template>
  <div id="NewPost">
    <div class="new-post-container">
      <div class="d-flex flex-row justify-content-end">
        <div class="d-flex flex-row new-post-header-container">
          <span class="new-post-title brand-secondary">Create a post</span>
          <span class="new-post-topic-selector ml-auto"
            >posting to
            <v-select
              v-model="selectedTopic"
              :options="topicSelectionOptions"
              :closeOnSelect="true"
              v-if="this.topics.length !== 0"
              label="label"
            ></v-select
            ><b-spinner v-else></b-spinner>
          </span>
        </div>
      </div>
      <div class="d-flex flex-row">
        <div class="new-post-avatar-container">
          <img
            class="img-fluid"
            :src="user.image_url"
            v-if="user.image_url !== null"
          />
          <img
            class="img-fluid user-avatar shadow"
            :src="
              'https://eu.ui-avatars.com/api/?name=' +
              user.firstname +
              '+' +
              user.lastname
            "
            v-else
          />
        </div>
        <div class="new-post-content-container">
          <div>
            <editor
              api-key="b4gid2ob2kaldj1kqtkuyxxt9jjv4xop73ank75kchgdimcy"
              v-model="text"
              :init="{
                height: 300,
                menubar: false,
                plugins: 'autolink link paste',
                toolbar: 'bold autolink italic underline',
                branding: false,
                statusbar: false,
                paste_as_text: true,
                paste_block_drop: true,
              }"
            />
          </div>
          <div class="new-post-disclaimer">
            Posts are <b>public</b> to everyone in your organisation.
          </div>
        </div>
      </div>
      <div class="d-flex flex-row justify-content-end">
        <div
          class="new-post-buttons d-flex flex-row justify-content-between mx-0"
        >
          <button class="btn cta-button px-5 py-3" @click="openModal()">
            <span class="fade-in">
              Picture/Video
              <i class="pl-3 fas fa-camera btn-icon"></i>
            </span>
          </button>
          <button class="btn cta-button px-5 py-3" @click="createPost()">
            <span v-if="!isSending" class="fade-in">Post</span
            ><span v-else
              ><b-spinner
                class="white button-spinner"
                v-if="sendSuccess === null"
              ></b-spinner>
              <span v-if="sendSuccess === true"
                >Sent <i class="fas fa-check button-icon"></i>
              </span>
              <span v-if="sendSuccess === false">
                <i class="fas fa-times button-icon"></i>
              </span>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import config from "../../config/constants";
import Editor from "@tinymce/tinymce-vue";

export default {
  name: "NewPost",
  props: ["user", "topics"],
  components: {
    Editor,
  },
  data() {
    return {
      text: "",
      content: "",
      sendSuccess: null,
      isSending: false,
      topicSelectionOptions: [],
      selectedTopic: { label: "General", id: null },
    };
  },
  mounted() {
    let ret = [{ label: "General", id: null }];
    this.topics.forEach(function (topic) {
      ret.push({ label: topic.title, id: topic.id });
    });
    this.topicSelectionOptions = ret;
  },
  methods: {
    log() {
      console.log(this.text);
    },
    async createPost() {
      if (this.text !== "") {
        const self = this;
        this.isSending = true;

        const payload = {
          user_id: this.$store.getters.user_id,
          post: {
            content: btoa(this.text),
            topic_id: this.selectedTopic.id,
            type: "studio",
          },
        };
        let res = await axios.post(
          config.api_env + "/application/api/u/community/create-post",
          payload,
          config.options
        );
        if (res.data.success) {
          this.sendSuccess = true;
          setTimeout(function () {
            self.text = "";
            self.$parent.getPosts();
            self.isSending = false;
          }, 500);
        } else if (res.data.err && res.data.err.includes("3994")) {
          this.isSending = false;
          this.$store.dispatch("logout");
          this.$router.push("/login").catch((err) => {});
        }
      }
    },
    openModal() {
      const scrollTo = document.getElementById("Community");
      scrollTo.scrollIntoView({ behavior: "smooth" });
      this.$parent.openMediaModal();
    },
  },
  computed: {
    componentText() {
      return this.getText.loggedIn;
    },
  },
  watch: {
    topics: function (topics) {
      let ret = [{ label: "General", id: null }];
      topics.forEach(function (topic) {
        ret.push({ label: topic.title, id: topic.id });
      });
      this.topicSelectionOptions = ret;
    },
  },
};
</script>
<style lang="less">
#NewPost {
  padding-bottom: 20px;
  .new-post-container {
    background-color: white;
    border-radius: 32px;
    height: auto;
    padding: 20px;
    .new-post-header-container {
      width: 85%;
      .new-post-title {
        font-style: "Filson Pro Book", sans-serif;
        font-weight: 600;
        font-size: 2rem;
      }
      .new-post-topic-selector {
        font-size: 1.8rem;
      }
    }
    .new-post-avatar-container {
      max-width: 120px;
      padding-right: 20px;
      .img-fluid {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 100px;
      }
    }
    .new-post-content-container {
      padding-top: 10px;
      width: 85%;
      .new-post-content-input {
        font-size: 1.6rem;
        outline: none;
        border: 1px solid lightsteelblue;
        border-radius: 12px;
        padding: 10px;
        min-height: 160px;
        resize: none;
      }
      .new-post-disclaimer {
        padding: 10px 0;
        font-size: 1.6rem;
        text-align: right;
      }
    }
    .new-post-buttons {
      width: 85%;
      padding-top: 10px;
      .btn {
        min-width: 200px !important;
      }
    }
  }
  .button-spinner {
    height: 16px;
    width: 16px;
    position: relative;
    top: 3px;
  }
}
</style>
