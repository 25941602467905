<template>
    <div id="be-calm">
        <div class="page-header bg-grey py-5">
            <div class="content-container row">
                <div class="page-title col-12 col-md-5">
                    <b-button class="return p-0 pb-4" @click="$router.push('/be-calm')" alt="return to all courses">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.13 35.13"><defs></defs><title>arrow-left</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><circle class="cls-1" cx="17.56" cy="17.56" r="17.56"/><rect class="cls-2" x="9.82" y="16.6" width="15.95" height="2.13"/><polygon class="cls-2" points="14.22 23.47 8.05 17.5 14.22 11.53 15.7 13.06 11.11 17.5 15.7 21.94 14.22 23.47"/></g></g></svg>
                    </b-button>
                    <h2 class="pb-4 pt-3">{{course.course_title}}</h2>
                    <p v-html="course.course_description"></p>
                    <p class="my-5">
                        <span class="duration"><i class="far fa-clock"></i> {{course.course_duration}}</span>
                        <span class="sessions px-5"><i class="fa fa-volume-up"></i> {{course.course_sessions}} sessions</span>
                    </p>
                    <b-button class="cta-button py-4 px-5" @click="startSessions()">Start sessions</b-button>
                </div>
                <div class="col-md-7 d-none d-md-flex pr-5 icons">
                    <!-- TODO:: Sort course images -->
                    <div class="be-calm-icon mr-5">
                        <img src="/img/be-calm/becalm-icon.svg">
                    </div>
                    <div class="course-icon">
                        <img :src="course.course_img_url">
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-white py-5" v-if="!courseLoading">
            <div class="content-container row py-5 my-5">
                <div class="col-md-4">
                    <div class="session-menu">
                        <div v-for="(session, i) in sessions"
                             :key="i"
                             class="session-item mb-4 pb-2"
                             :class="{'active': activeSessionIndex === i, 'complete': activeSessionIndex > i}"
                             @click="selectSession(i)"
                        >
                            <div class="session-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.01 23.41"><defs></defs><title>audio-pink</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M16.7,11.69V20a3.19,3.19,0,0,1-1.84,3.08,2.78,2.78,0,0,1-3-.22C10.57,21.94,9.3,20.92,8,19.94c-.76-.58-1.51-1.16-2.28-1.73a.78.78,0,0,0-.44-.15H3a2.87,2.87,0,0,1-3-3Q0,11.68,0,8.3a2.91,2.91,0,0,1,3-3c.74,0,1.48,0,2.22,0a1,1,0,0,0,.58-.2C7.72,3.65,9.61,2.2,11.49.75a3.18,3.18,0,0,1,4,0A3.31,3.31,0,0,1,16.7,3.39C16.69,6.16,16.7,8.93,16.7,11.69ZM3,15.17h.36c.84,0,1.69,0,2.54,0a2,2,0,0,1,1.31.47q3,2.32,6.12,4.64c.09.07.27.15.33.11a.73.73,0,0,0,.22-.36.94.94,0,0,0,0-.29V3.59c0-.22.14-.55-.15-.63s-.41.13-.58.26c-2,1.52-4,3-6,4.58A1.9,1.9,0,0,1,6,8.2H3.37L3,8.22Z"/><path class="cls-1" d="M29,12.22a11.38,11.38,0,0,1-4.25,8.46,1.3,1.3,0,0,1-1.39.27,1.33,1.33,0,0,1-.94-1.14,1.4,1.4,0,0,1,.45-1.2c.38-.39.78-.75,1.14-1.16a8.54,8.54,0,0,0,2.06-7.19,8.83,8.83,0,0,0-2.86-5.19,2.86,2.86,0,0,1-.67-.77A1.28,1.28,0,0,1,23,2.62a1.43,1.43,0,0,1,1.83.09,15.49,15.49,0,0,1,1.75,1.78A11.69,11.69,0,0,1,29,12.22Z"/><path class="cls-1" d="M23.9,11.64a6.53,6.53,0,0,1-2.45,5.08,1.4,1.4,0,0,1-1.95-.21,1.26,1.26,0,0,1,.12-2A3.68,3.68,0,0,0,21,12.14a3.46,3.46,0,0,0-1.26-3.22,1.44,1.44,0,0,1-.39-1.86,1.41,1.41,0,0,1,2.18-.36,6.32,6.32,0,0,1,2.24,3.89C23.84,11,23.87,11.36,23.9,11.64Z"/></g></g></svg>
                            </div>
                            <div class="session-index">
                                <h4 class="mb-0 mr-2">{{i + 1}}: </h4>
                            </div>
                            <div class="session-title">
                                <h4 class="mb-0">{{session.session_title}}</h4>
                                <p>{{session.session_duration}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="media" class="col-md-7 offset-1 pl-5">
                    <div v-if="activeSession.session_type === 'video'">
                        <GAVideoPlayer :autoplay="activeSessionIndex > 0" :key="activeSessionIndex" :video-class="'media-player'" ref="media" :src="activeSession.asset_url"/>
                    </div>
                    <div v-else-if="activeSession.session_type === 'audio'">
                        <audio controls :autoplay="activeSessionIndex > 0" :key="activeSessionIndex" class="media-player" ref="media">
                            <source :src="activeSession.asset_url" type="audio/mpeg">
                        </audio>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-white" v-else>
            <b-spinner></b-spinner>
        </div>
        <b-modal id="completed-modal" hide-header hide-footer centered>
            <div class="icon-wrap mb-5">
                <img src="/img/be-calm/be-calm-icon-popup.svg">
            </div>
            <p class="mb-2">You have completed all</p>
            <h2>Be Calm</h2>
            <p>'{{course.course_title}}' sessions</p>
            <b-button @click="backToBeCalm()" class="cta-button px-5 py-3 my-5">Explore Be Calm</b-button>
            <div class="close"><a @click="closeModal()"><i class="fas fa-times"></i></a></div>
        </b-modal>
    </div>
</template>

<script>
    import axios from "axios";
    import config from "@/config/constants";
    import GAVideoPlayer from "../components/GAVideoPlayer.vue";
    export default {
        name: "BeCalmCourse",
      components: {GAVideoPlayer},
        data(){
            return{
                course:{},
                sessions:[],
                courseLoading: true,
                activeSessionIndex: 0
            }
        },
        mounted(){
            this.getCourse()
        },
        methods:{
            async getCourse(){

                const payload = {
                    user_id: this.$store.getters.user_id,
                    course_id: this.$route.params.id
                }
                let res = await axios.post(config.api_env + '/application/api/u/resources/get-be-calm-course', payload, config.options)
                if(res.data.success){
                    this.sessions = res.data.modules
                    this.course = res.data.courses
                    this.courseLoading = false
                }
                this.activeSessionIndex = 0
            },
            startSessions(){
                this.activeSessionIndex = 0
                const scrollTo =  document.getElementById("media")
                scrollTo.scrollIntoView({behavior: 'smooth'});
                this.$nextTick().then(()=>{
                    this.$refs.media.play()
                })
            },
            selectSession(index){
                this.activeSessionIndex = index
                const scrollTo =  document.getElementById("media")
                scrollTo.scrollIntoView({behavior: 'smooth'});
            },
            nextSession(){
                if((this.activeSessionIndex + 1) < this.sessions.length) {
                    this.activeSessionIndex++
                }else{
                    this.$bvModal.show('completed-modal')
                }
            },
            backToBeCalm(){
                this.$bvModal.hide('completed-modal')
                this.$router.push('/be-calm')
            },
            closeModal(){
                this.$bvModal.hide('completed-modal')
            }
        },
        computed:{
            componentText() {
                return this.getText.loggedIn.beCalm;
            },
            activeSession(){
                return this.sessions[this.activeSessionIndex]
            }
        },
        watch:{
            activeSession(newSesh){
                const payload = {
                    user_id: this.$store.getters.user_id,
                    module_id: newSesh.session_id
                }
                axios.post(config.api_env + '/application/api/u/resources/log-module', payload)
            }
        }
    }
</script>

<style lang="less">
     #be-calm{
         .return{
             background-color: transparent;
             svg{
                 width: 35px;
                 .cls-1{
                     fill:#ea3975;
                 }
                 .cls-2{
                     fill:#fff;
                 }
             }
             &:hover{
                 .cls-1{
                     fill: darken(#ea3975, 10%);
                 }
             }
         }
         .icons {
             position: relative;
             .be-calm-icon {
                 max-width: 310px;
                 width: 100%;
                 align-self: center;
                 margin-left: auto;
                 img {
                     width: 100%;
                 }
             }
             .course-icon{
                 max-width: 140px;
                 width: 100%;
                 position: absolute;
                 right: 0;
                 top: -40px;
                 //TODO:: fix display when Niamh figures out icon
                 display: none;
                 img{
                     width: 100%;
                 }
             }
         }
         .session-menu{
             position: relative;
             &:before{
                 content: "";
                 position: absolute;
                 left: 50px;
                 top: 8px;
                 height: calc(100% - 50px);
                 width: 1px;
                 background-color: #cccccc;
             }
             .session-item {
                 display: flex;
                 flex-direction: row;
                 color: #e6007e;
                 cursor: pointer;
                 .session-icon{
                     font-size: 2.5rem;
                     margin-right: 50px;
                     position: relative;
                     display: flex;
                     align-items: flex-start;
                     svg{
                         width: 28px;
                         position: relative;
                         .cls-1{fill:#e6007e;}
                     }
                     &:after{
                         position: absolute;
                         top: 8px;
                         right: -28px;
                         content: "";
                         border-radius: 50%;
                         width: 10px;
                         height: 10px;
                         background-color: #cccccc;
                     }
                 }
                 h4 {
                     font-family: FilsonProBold, sans-serif;
                 }
                 p {
                     font-size: 1.6rem;
                 }
                 &.active, &:hover{
                     color: darken(#e6007e, 10%);
                     .session-icon svg{
                         .cls-1{fill:darken(#e6007e, 10%);}
                     }
                     .session-icon:after{
                         background-color: darken(#e6007e, 10%);
                     }
                 }
                 &.complete{
                     color: #cccccc;
                     p{
                         color: #cccccc;
                     }
                     svg{
                         .cls-1{fill:#cccccc;}
                     }
                 }
             }
         }
         .media-player{
             width: 100%;
             max-width: 100%;
             border-radius: 30px;
             &:focus{
                 outline: none;
             }
         }
     }
    #completed-modal{
        .modal-content {
            border-radius: 30px;
            text-align: center;
            height: 400px;
            max-width: 600px;
            width: 100%;
            position: relative;
            border-width: 0;
            color: #3a4961;
            .modal-body{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
            }
            .icon-wrap{
                width: 150px;
                img{
                    width: 100%;
                }
            }
            h2{
                font-size: 2.3rem;
                font-family: FilsonProBold, sans-serif;
            }
            p{
                font-size: 2rem;
                font-family: DMSans, sans-serif;
            }
            .cta-button{
                color: #e6007e;
                background-color: #fff;
                border: 1px solid  #e6007e;
                border-radius: 30px;
                font-size: 1.6rem;
                font-family: FilsonProBold, sans-serif;
                &:hover{
                    background-color: #e6007e;
                    color: #fff
                }
            }
            .close{
                position: absolute;
                top: 15px;
                right: 20px;
                color: #e6007e;
                opacity: 1;
                cursor: pointer;
                font-size: 2.8rem;
                &:hover{
                    color: darken(#e6007e, 10%)
                }
            }
        }
    }
</style>