<template>
	<div id="wallet-page">
		<div v-if="loading">
			<Loader />
		</div>
		<div v-else class="content-container">
			<div class="row">
				<div class="col-12">
					<h3 class="underlined-title brand-text">My Wallet</h3>
				</div>
				<div class="col-sm-12 col-md-4">
					<WalletCard class="mb-5" :link="false" />
				</div>
				<div class="col-sm-12 col-md-8">
					<div class="promo-banner">
						<WalletPromoBanner />
					</div>
				</div>
			</div>
		</div>
		<div class="lower-section">
			<div class="content-container">
				<div class="row">
					
					<div class="col-sm-6 col-xs-6 promo-item" @click="openRedeemVoucherModal">
						<div class="promo-item-inner">
							<div class="row no-gutters">
								<div class="col-3 icon"><i class="fas fa-envelope-open-dollar"></i></div>
								<div class="col-9 brand-text">
									<h4 >Redeem Voucher</h4>
									<p>Add your voucher code here to redeem it's value and add to your wallet funds</p>
								</div>
							</div>
						</div>
					</div>
					
					<div v-if="card_type === null" class="col-sm-6 col-xs-6 promo-item" @click="openAddCardModal">
						<div class="promo-item-inner animate__animated animate__fadeIn">
							<div class="row no-gutters">
								<div class="col-3 icon"><i class="fas fa-credit-card"></i></div>
								<div class="col-9 brand-text">
									<h4>Add card</h4>
									<p>Save your credit card details to make card purchases faster in the future</p>
								</div>
							</div>
						</div>
					</div>
					
					<div v-else class="col-sm-6 col-xs-6 promo-item" @click="deleteSavedCard()">
						<div class="promo-item-inner animate__animated animate__fadeIn">
							<div class="row no-gutters">
								<div class="col-3 icon"><i class="fas fa-credit-card"></i></div>
								<div class="col-9 brand-text">
									<h4>Remove card</h4>
									<p>Remove your saved card</p>
								</div>
							</div>
						</div>
					</div>
					
<!--					<div class="col-sm-6 col-xs-6 promo-item">-->
<!--						<div class="promo-item-inner">-->
<!--							<div class="row no-gutters">-->
<!--								<div class="col-3 icon"><i class="fas fa-cogs"></i></div>-->
<!--								<div class="col-9 brand-text">-->
<!--									<h4>Settings</h4>-->
<!--									<p>Click here to edit your profile settings</p>-->
<!--								</div>-->
<!--							</div>-->
<!--						</div>-->
<!--					</div>-->
					
					<div class="col-sm-6 col-xs-6 promo-item" @click="openPurchaseSessionsModal(1)">
						<div class="promo-item-inner">
							<div class="row no-gutters">
								<div class="col-3 icon">
									<i class="fas fa-gift"></i>
									<div class="number_thing bg-brand-highlight-1">
										<span>1</span>
									</div>
								</div>
								<div class="col-9 brand-text">
									<h4>Single Digital Clinic Session</h4>
									<p>Purchase the value of one session (excl. VAT) using your payment card</p>
                  <p class="vat-disclosure">Additional VAT costs may be included at checkout depending on selected service</p>
								</div>
							</div>
						</div>
					</div>
					
					<div class="col-sm-6 col-xs-6 promo-item" @click="openPurchaseSessionsModal(3)">
						<div class="promo-item-inner">
							<div class="row no-gutters">
								<div class="col-3 icon">
									<i class="fas fa-gift"></i>
									<div class="number_thing bg-brand-highlight-1">
										<span>3</span>
									</div>
								</div>
								<div class="col-9 brand-text">
									<h4>3 Digital Clinic Sessions bundle</h4>
                  <p>Purchase the value of 3 sessions (excl. VAT) and receive a 5% discount</p>
                  <p class="vat-disclosure">Additional VAT costs may be included at checkout depending on selected service</p>
								</div>
							</div>
						</div>
					</div>
					
					<div class="col-sm-6 col-xs-6 promo-item" @click="openPurchaseSessionsModal(6)">
						<div class="promo-item-inner">
							<div class="row no-gutters">
								<div class="col-3 icon">
									<i class="fas fa-gift"></i>
									<div class="number_thing bg-brand-highlight-1">
										<span>6</span>
									</div>
								</div>
								<div class="col-9 brand-text">
									<h4>6 Digital Clinic Sessions bundle</h4>
                  <p>Purchase the value of 6 sessions (excl. VAT) and receive a 10% discount</p>
                  <p class="vat-disclosure">Additional VAT costs may be included at checkout depending on selected service</p>
								</div>
							</div>
						</div>
					</div>
				
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import WalletCard from "../components/DigitalClinics/Landing/WalletCard";
import WalletPromoBanner from "../components/WalletPromoBanner";
import AddCard from "../components/Stripe/AddCard";
import PurchaseSessions from "../components/Stripe/PurchaseSessions";
import EventBus from "../helpers/eventbus";
import axios from "axios";
import config from "../config/constants";
import Loader from "../components/Loader";

export default {
	name: "Wallet",
	components: {
		WalletCard,
		WalletPromoBanner,
		PurchaseSessions,
		Loader
	},
	data() {
		return {
			loading: true,
			card_type: null,
			currency : 'EUR'
		}
	},
	methods: {
		async openRedeemVoucherModal(){
			await this.voucherModal();
			EventBus.$emit("loadWallet");
		},
		async openAddCardModal(){
			const content = this.$createElement( AddCard, { props: {close_function: () => { this.$bvModal.hide("add_card_modal") }}})
			const skin = this.$store.getters.skin;
			let opts = {
				title: "Payment",
				hideHeader: false,
				hideFooter: true,
				modalClass: "card_modal wallet_modal theme-" + skin,
				headerClass: "card_modal_header d-none",
				footerClass: "d-none",
				size: "md",
				buttonSize: "sm",
				centered: true,
				contentClass: "card_modal",
				id : 'add_card_modal'
			}
			await this.$bvModal.msgBoxOk(content, opts);
			EventBus.$emit("loadWallet");
			await this.getWallet()
		},
		loadStripeScript() {
			if (!document.getElementById("stripe-script")) {
				let script = document.createElement("script");
				script.type = "text/javascript";
				script.src = "https://js.stripe.com/v3/";
				script.id = "stripe-script";
				document.head.appendChild(script);
			}
			// this.$nextTick().then(() => {
			// 	this.checkStripe();
			// });
		},
		async openPurchaseSessionsModal(number_of_sessions){
			const content = this.$createElement( PurchaseSessions, { props: {number_of_sessions : number_of_sessions}})
			const skin = this.$store.getters.skin;
			let opts = {
				title: "Purchase Sessions",
				hideHeader: false,
				hideFooter: true,
				modalClass: "purchase_sessions_modal wallet_modal theme-" + skin,
				headerClass: "purchase_sessions_modal_header d-none",
				footerClass: "d-none",
				size: "md",
				buttonSize: "sm",
				centered: true,
				contentClass: "purchase_sessions_modal",
				id : 'purchase_sessions_modal'
			}
			await this.$bvModal.msgBoxOk(content, opts);
			EventBus.$emit("loadWallet");
		},
		async deleteSavedCard(){
			const payload = {
				user_id: this.$store.getters.user_id,
			};
			let res = await axios.post(
				config.api_env + "/application/api/u/wallet/remove-saved-card",
				payload,
				config.options
			);
			if (res.data.success) {
				this.card_type = res.data.card_type
			}
			EventBus.$emit("loadWallet");
			await this.getWallet()
		},
		async getWallet() {
			//TODO probably move wallet data to vuex, to be accessible across the application
			const payload = {
				user_id: this.$store.getters.user_id,
			};
			let res = await axios.post(
				config.api_env + "/application/api/u/wallet/get-wallet",
				payload,
				config.options
			);
			if (res.data.success) {
				this.card_type = res.data.card_type
				this.currency = res.data.currency
				this.loading = false
			}
		},
	},
	mounted() {
		this.getWallet()
		this.loadStripeScript()
	},
}
</script>

<style lang="less">
#wallet-page {
	background: #f8f8f8;
	padding: 0 10px;
	h3.underlined-title {
		letter-spacing: normal;
		font-size: 22px;
		&:after {
			content : ' ';
			display: block;
			width: 60px;
			height: 5px;
		}
	}
	.lower-section {
		padding-top: 2em;
		.promo-item {
			padding: 15px;
			.promo-item-inner {
				height: 100%;
				&:hover {
					cursor: pointer;
				}
				background-color: white;
				border-radius: 10px;
				border: 1px solid rgba(56, 28, 82, 0.2);
				.icon {
					padding: 0 10px;
					text-align: center;
					position: relative;
					i {
						font-size: 35px;
						color: rgba(56, 28, 82, 0.5);
						margin: 25px auto 0;
					}
					.number_thing {
						position: absolute;
						display: inline-block;
						left: 25%;
						top: 45%;
						height: 16px;
						width: 16px;
						border-radius: 50%;
						color: white;
						padding-top: 1px;
						box-shadow: 0px 0px 8px rgba(255,255,255, 0.9);
					}
				}
				h4 {
					font-family: "FilsonProBold", sans-serif;
					margin: 15px 0 0;
				}
				p {
					font-size: 13px;
					padding-right: 15px;
					min-height: 3em;
          margin-bottom: 5px;
				}
        .vat-disclosure {
          font-size: 11px !important;
          min-height: 2.5em !important;
          text-align: justify;
        }
			}
		}
	}
}
</style>
