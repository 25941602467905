<template>
  <div id="ActivitiesCard">
    <img class="backgroundImg" :src="item.img_url" :alt="activity.type"/>
    <div class="textContainer">
      <div class="innerContainer">
        <div class="topRow">
          <div v-if="distance" class="title">{{unit === "Kilometers" ?  distance : convertMetersToMiles(distance * 1000)}}{{unit === "Kilometers"? "km":" Miles"}}<span> {{item.description}}</span></div>
          <div v-else class="title">{{item.description}}</div>
        </div>
        <div class="dateAndTime" v-if="dateAndTime">{{dateAndTime}}</div>
        <div class="stats">
          <div v-if="time">
            <span>Time:</span> {{time}}
          </div>
          <div v-if="activity.pace">
            <span>Pace:</span> {{activity.pace}}
          </div>
          <div v-if="activity.steps && item.steps">
            <span>Steps:</span> {{activity.steps}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import moment from "moment";

export default {
  name: 'ActivitiesCard',
  props: ['activity'],
  data() {
    return {
      default_items: {
        walking: {
          img_url: '/img/challenges/types/steps.png',
          description: 'Walk',
          encouragement: 'Well Done!',
          steps: true
        },
        running: {
          img_url: '/img/challenges/run.png',
          description: 'Run',
          encouragement: 'Nice job!',
          steps: false
        },
        cycling: {
          img_url: '/img/challenges/cycle.png',
          description: 'Cycle',
          encouragement: 'You nailed it!',
          steps: false
        },
        swimming: {
          img_url: '/img/challenges/swim.png',
          description: 'Swim',
          encouragement: 'Keep up the pace!',
          steps: false
        },
        strength_training: {
          img_url: '/img/challenges/strength.png',
          description: 'Strength Building',
          encouragement: 'Great stuff!',
          steps: false
        },
        pilates: {
          img_url: '/img/challenges/pilates.jpg',
          description: 'Pilates',
          encouragement: 'Great stuff!',
          steps: false
        },
        yoga: {
          img_url: '/img/challenges/yoga.jpg',
          description: 'Yoga',
          encouragement: 'Great stuff!',
          steps: false
        },
        surfing: {
          img_url: '/img/challenges/surfing.jpg',
          description: 'Surf',
          encouragement: 'Great stuff!',
          steps: false
        },
        class: {
          img_url: '/img/challenges/dg_cropped.webp',
          description: this.activity.title,
          encouragement: 'Great stuff!',
          steps: false
        }
      }
    }
  },
  computed: {
    unit() {
      return this.$store.getters.distanceUnit;
    },
    dateAndTime() {
      return this.getDateWithoutYear(this.activity.start_time) +' '+ this.getHoursMinutes(this.activity.start_time);
    },
    distance() {
      if(!this.activity.distance_meters) return null;
      return this.activity.distance_meters / 1000;
    },
    item() {
      let type;
      if(this.activity.activity_type) type = this.activity.activity_type.value.toLowerCase();
      if(this.activity.event_id) type = 'class'
      return this.default_items[type]
    },
    time() {
      if(!this.activity.end_time || !this.activity.start_time) return null;
      const time = moment.duration(moment(this.activity.end_time).diff(moment(this.activity.start_time)));
      let string = '';
      const hours = Math.floor(time.asHours());
      const minutes = Math.floor(time.asMinutes() - (hours * 60));
      const seconds = time.asSeconds() % 60;
      if(hours >= 1) string = hours + "h ";
      string += minutes + "m "
      string += seconds + "s"
      return string
    }
  }
}
</script>
<style scoped lang="less">
#ActivitiesCard {
  position: relative;
  width: 340px;
  height: 115px;
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 15px;
  color: white;
  .backgroundImg {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .textContainer {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 2;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 1.51%, #45433D 30.19%, #292824 98.46%);
    align-items: center;
    justify-content: end;
    display: flex;
    .innerContainer {
      width: 65%;
      height: 100%;
      padding: 10px 15px;
      flex-direction: column;
      justify-content: space-evenly;
      display: flex;
      .dateAndTime {
        font-size: 1.1rem;
      }
      .topRow {
        .title {
          font-size: 1.8rem;
          font-family: FilsonProBold, Arial, Helvetica, sans-serif;
          span {
            font-family: 'DMSans', sans-serif;
            font-size: 1.6rem;
          }
        }
      }
      .encouragement {
        font-size: 1.4rem;
      }
      .stats {
        display: flex;
        flex-direction: column;
        justify-content: start;
        font-size: 1.2rem;
        div {
          margin-right: 10px;
        }
        span {
          font-family: FilsonProBold, Arial, Helvetica, sans-serif;
        }
      }
    }
  }
}
</style>