<template>
<div id="ActivityChallenge">
  <i class="fa-regular fa-arrow-left-long challengesBack" style="font-size: 1.5rem" @click="$emit('back')"></i>
  <div class="title">Activity Challenge</div>
  <div class="description">
    The activity challenge will track all activities tracked by any attached wearables including runs, cycles, swims and tracked gym sessions, etc.
  </div>
  <div class="description">
    This challenge will also track any Digital Gym classes you attend on the Spectrum.Life platform.
  </div>
  <div class="minuteHolder">
    <b-input type="number" v-model="minutes" @keydown="handleMinutesInput" autocomplete="off" min="1"></b-input>
    <div class="unit">Weekly Minutes of activity</div>
  </div>
  <ChallengeDatePicker ref="datePicker" :class="{invalid: !passedValidation}"/>
  <div class="challengesButton mt-5" :class="{invalid: !passedValidation}" @click="create">Create Challenge</div>
</div>
</template>

<script>
import ChallengeDatePicker from "@/components/Challenges/NewChallenges/ChallengeDatePicker.vue";

export default {
  name: "ActivityChallenge",
  components: {ChallengeDatePicker},
  data() {
    return {
      minutes: null,
    }
  },
  computed: {
    startDate() {
      return this.$refs.datePicker.startDate;
    },
    endDate() {
      return this.$refs.datePicker.endDate;
    },
    passedValidation() {
      return this.minutes && this.startDate && this.endDate;
    }
  },
  methods: {
    create() {
      this.$parent.createChallenge({
        elapsed_time_seconds: this.minutes * 60,
        start_date_time: this.startDate + ' 00:00:00',
        end_date_time: this.endDate + ' 00:00:00'
      })
    },
    handleMinutesInput(evt) {
      if((!this.minutes && Number.parseInt(evt.key) === 0) || evt.key === '-') {
        evt.preventDefault();
        return;
      }
      if(this.distance % 1 !== 0 && !isNaN(Number.parseInt(evt.key))) {
        const distance = this.distance.toString();
        if(distance.substr(distance.indexOf('.'),distance.length).length >= 3) {
          evt.preventDefault();
        }
      }
    },
  }
}
</script>

<style lang="less">
#ActivityChallenge {
  .minuteHolder {
    display: flex;
    align-items: center;
    justify-content: center;
    .unit {
      font-size: 1.5rem;
    }
    input {
      background-color: transparent !important;
      padding: 15px 10px;
      width: 75px;
      color: white;
      font-size: 1.4rem;
      margin-right: 25px;
    }
  }
}
</style>