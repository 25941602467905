<template>
  <div id="StudioOverview">
    <div class="content-container row d-flex flex-row flex-wrap py-5 mt-5">
      <div class="col-md-6 col-sm-12">
        <div class="banner_inner">
          <img
            class="img-fluid"
            src="https://healthcoach-media.com/res/images/Image-1-min.jpg"
          />
          <div class="banner_text">
            <h2 class="mt-3">Wellbeing Series</h2>
            <p>
              In the Wellbeing Studio, this year is more exciting and relevant
              than ever before. Instead of following a monthly theme, the series
              this year will focus on key days in the national and international
              health and wellbeing calendars and social calendars.
            </p>
            <br />
            <a
              href="/wellbeing-studio"
              class="btn btn-block cta-button py-3 px-5"
              >Enter Wellbeing Series</a
            >
          </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-12">
        <div class="banner_inner">
          <img
            class="img-fluid"
            src="https://healthcoach-media.com/res/images/Image-2-min.jpg"
          />
          <div class="banner_text">
            <h2 class="mt-3">Digital Gym</h2>
            <p>
              Our Digital Gym gives you access to live and on demand fitness
              classes, with a weekly schedule to choose from. All classes are
              delivered by handpicked experts who will demonstrate a safe and
              maintainable way to tackle fitness at home.
            </p>
            <br />
            <a href="/digital-gym" class="btn btn-block cta-button py-3 px-5"
              >Enter Digital Gym</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "StudioOverview",
  data() {
    return {
      loading: true,
    };
  },
  mounted() {},
  methods: {},
  computed: {
    ...mapGetters(["skin", "user_id"]),
    componentText() {
      return this.getText.wellbeingStudio;
    },
  },
};
</script>

<style lang="less">
#StudioOverview {
  .banner_inner {
    overflow: hidden;
    margin: 20px 60px;
    border-radius: 25px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);

    img {
      margin-bottom: 15px;
    }
    .banner_text {
      padding: 15px 15px;
      p {
        margin-top: 16px;
        font-family: ff-sari-web-regular, sans-serif;
        font-size: 15px;
      }
      h2 {
        text-align: left;
        font-family: ff-sari-web-regular, sans-serif;
      }
    }
  }
  @media (max-width: 768px) {
    .banner_inner {
      margin: 20px;
    }
  }
}
</style>
