<template>
  <div id="TherapyLogin">
    <div class="loginBox">
      <div class="innerLoginBox">
        <div class="d-flex justify-content-center align-items-center mb-3">
          <img src="/img/Spectrum-Life-Logo.png" alt="Spectrum.Life logo" style="width: 50%">
        </div>
        <hr />
        <TherapyPasswordResetRequest v-if="requestPasswordReset" @changeMode="requestPasswordReset = false"/>
        <TherapyPasswordReset v-else-if="isPasswordReset"/>
        <TherapySignIn v-else-if="signIn" @changeMode="signIn = !signIn" @resetPassword="requestPasswordReset = true"/>
        <TherapySignUp v-else @changeMode="signIn = !signIn" @loggedIn=""/>
      </div>
    </div>
  </div>
</template>

<script>
import TherapySignIn from "./SignUp/TherapySignIn.vue";
import TherapySignUp from "./SignUp/TherapySignUp.vue";
import TherapyPasswordReset from "./TherapyPasswordReset.vue";
import TherapyPasswordResetRequest from "@/components/TherapyPortal/SignUp/TherapyPasswordResetRequest.vue";

export default {
  name: 'TherapyLogin',
  components: {TherapyPasswordResetRequest, TherapyPasswordReset, TherapySignUp, TherapySignIn},
  data() {
    return {
      signIn: true,
      requestPasswordReset: false
    }
  },
  methods: {

  },
  computed: {
    isPasswordReset() {
      return this.$route.name === 'reset'
    }
  }
}
</script>

<style  lang="less">
#TherapyLogin {
  font-family: "FilsonPro", sans-serif;
  background-image: url("/img/therapy/login-background.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding-top: 30px;
  padding-bottom: 20px;
  a {
    color: #D60570;
    &:hover {
      color: darken(#D60570, 5%);
    }
  }
  h2 {
    font-size: 3rem;
    font-family: FilsonProBold, Arial, Helvetica, sans-serif;
  }
  p {
    text-align: center;
    font-size: 1.75rem;
  }
  hr {
    width: 100%;
    border-top: 1px solid lightgray !important;
  }
  .loginBox {
    width: 90%;
    max-width: 600px;
    background-color: white;
    margin: auto;
    border-radius: 20px;
    padding: 40px;
  }
  .innerLoginBox {
    display: flex;
    flex-direction: column;
    width: 95%;
    max-width: 490px;
    min-width: fit-content;
    margin: auto;
  }
  .form-group {
    margin-top: 15px;
    label {
      font-size: 1.3rem;
      color: #8E8F93;
      margin-left: 20px;
    }
  }
  input, .form-control {
    all: unset;
    width: 100%;
    box-sizing: border-box;
    padding: 5px 5px 5px 15px !important;
    height: 55px !important;
    border-radius: 30px !important;
    margin: auto;
    font-family: 'DMSans', sans-serif;
    font-size: 1.4rem;
    border: 1px solid #D0D5DA;
    background-color: #EEEEF3 !important;
  }
  button {
    max-width: 400px;
    min-width: 250px;
    margin: auto;
  }
}
</style>