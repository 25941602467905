<template>
    <div id="RewardsDetails" class="bg-white">
        <div class="content-container row pr-4">
            <div class="col-12 back-to-rewards py-5">
                <a @click="$emit('back')" class="brand-secondary">
                    <font-awesome-icon icon="chevron-left"></font-awesome-icon>
                    {{componentText.back}}
                </a>
            </div>
            <div class="col-12 col-md-7 ">
                <div class="reward-info mx-0 row align-items-center">
                    <div class="col-12 col-md-6 reward-image pb-4">
                        <img :src="reward.reward_image_url" />
                    </div>
                    <div class="col-12 col-md-6 pb-5 reward-title">
                        {{reward.reward_title}}
                    </div>
                    <div class="col-12">
                        <p v-html="reward.reward_description"></p>
                    </div>
                    <div class="col-12 valid-until brand-secondary py-5" >
                        <font-awesome-icon icon="clock"></font-awesome-icon> {{componentText.valid}} {{validUntil}}
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-5 voucher-code px-5 py-5">
                <p class="py-4 my-5">{{componentText.code}}</p>
                <h3 class="py-4 my-5 mx-2 mx-sm-5 mx-md-2 mx-lg-5">{{reward.voucher_code}}</h3>
            </div>
            <div class="col-12 py-5 pl-0 pl-md-4 pr-0 mt-5">
                <div class="terms-card px-5 py-5">
                    <h4 class="mb-4">{{componentText.terms}}</h4>
                    <div class="terms" v-html="reward.terms"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import axios from "axios";
    import config from "../../config/constants";
    import moment from "moment"
    export default {
        name: "RewardsDetails",
        components: {

        },
        props:{
            reward:{}
        },
        data() {
            return {

            };
        },
        mounted() {
        },
        methods: {

        },
        watch:{

        },
        computed:{
            validUntil(){
                return moment(this.reward.valid_date).format('ddd, DD MMM YYYY')
            },
            componentText() {
                return this.getText.loggedIn.rewards.rewardsDetails;
            },
        }
    };
</script>
<style lang="less">
    #RewardsDetails {
        .back-to-rewards {
            a {
                font-size: 1.6rem;
            }
        }
        .reward-info {
            font-size: 1.7rem;
            font-family: DMSans;
            .reward-image {
                img {
                    width: 100%;
                }
            }
            .reward-title {
                font-family: FilsonProBold, sans-serif;
                font-size: 4rem;
                line-height: 1;
            }
            .valid-until{
                font-family: FilsonProBold;
                font-size: 2rem;
            }
        }
        .voucher-code{
            text-align: center;
            background: #F8F8F9;
            border-radius: 0 55px 55px 0;
            p{
                font-family: FilsonProLight, sans-serif;
                font-size: 2.8rem;
            }
            h3{
                letter-spacing: 0;
                border-top: 1px solid #cccccc;
                border-bottom: 1px solid #cccccc;
                line-height: 1;
            }
        }
        .terms-card{
            font-size: 1.7rem;
            font-family: DMSans;
            background: #F8F8F9;
            border-left-width: 10px;
            border-left-style: solid;
            h4{
                font-family: FilsonProBold;
                font-size: 2rem;
            }
        }
        @media only screen and (max-width: 768px){
            .reward-info {
                .reward-image {
                    text-align: center;
                    img {
                        margin:auto;
                        max-width: 300px;
                    }
                }
            }
        }
    }
</style>
