<template>
  <div id="SideNav" :class="[$store.getters.skin !== 20 ? 'bg-fade-vertical' : 'bg-brand-secondary']">
    <b-nav vertical fill class="pt-5">
      <b-nav-item @click="navigate({name:'home'})" class="mb-5 home " v-if="$store.getters.skin !== 20">
        <img src="/img/default-asset.svg" v-if="skin === 0 || skin === 8"/>
        <img src="/img/default-asset-generic.svg" v-else/>
      </b-nav-item>
      <b-nav-item
        @click="navigate('/settings')"
        class="mb-5 mx-2 pb-4 border-bottom"
        v-if="$store.getters.skin !== 20 && hiddenSettings && !$store.getters.user.access_code_user"
        >
        <img v-if="skin === 0 && !darkMode" src="/img/settings.svg"/>
        <img v-else-if="skin === 7" src="/img/settings-7.svg"/>
        <img src="/img/settings-generic.svg" v-else/>
      </b-nav-item>
      <b-nav-item href="/faqs" target="_blank" class="mb-5 mx-2 pb-4 border-bottom" v-if="$store.getters.skin !== 20 && $store.getters.skin !== 25">
        <img v-if="skin === 0 && !darkMode" src="/img/faq.svg">
        <img v-else-if="skin === 7" src="/img/faq-7.svg"/>
        <img src="/img/faq-generic.svg" v-else/>
      </b-nav-item>
      <b-nav-item class="mb-5 mx-2 pb-4" @click="logOut">
        <img v-if="skin === 0 && !darkMode" src="/img/logout.svg">
        <img v-else-if="skin === 7" src="/img/logout-7.svg" />
        <img src="/img/logout-generic.svg" v-else>
      </b-nav-item>
    </b-nav>
  </div>
</template>

<script>
export default {
  name: "SideNav",
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    logOut() {
      this.$store.dispatch("logout");
      this.$router.push("/login").catch(err => {});
    },
    navigate(url) {
      this.$router.push(url).catch(err => {});
    }
  },
  computed: {
      darkMode() {
        return this.$store.getters.darkMode
      },
      skin(){
          return this.$store.getters.skin
      },
      hiddenSettings(){
          /**
           * Hide away UHI's settings sidebar item
           */
          if (
            (process.env.NODE_ENV == 'production' && this.$store.getters.user.user_client_id == 3822) ||
            this.$store.getters.user.user_client_id == 6016
          ){
            return false;
          }
          return true;
      },
  }
};
</script>
<style lang="less">
#SideNav {
  height: 100vh;
  width: 60px;
  left: 0;
  z-index: 10;
  .nav-item.home {
    height: 120px;
    .nav-link {
      width: 40px;
    }
  }
  .nav-item {
    height: 57px;
    .nav-link {
      padding: 0;
      width: 31px;
      margin: auto;
    }
  }
}
</style>
