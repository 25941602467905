var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"NewPost"}},[_c('div',{staticClass:"new-post-container"},[_c('div',{staticClass:"d-flex flex-row justify-content-end"},[_c('div',{staticClass:"d-flex flex-row new-post-header-container"},[_c('span',{staticClass:"new-post-title brand-secondary"},[_vm._v("Create a post")]),_c('span',{staticClass:"new-post-topic-selector ml-auto"},[_vm._v("posting to "),(this.topics.length !== 0)?_c('v-select',{attrs:{"options":_vm.topicSelectionOptions,"closeOnSelect":true,"label":"label"},model:{value:(_vm.selectedTopic),callback:function ($$v) {_vm.selectedTopic=$$v},expression:"selectedTopic"}}):_c('b-spinner')],1)])]),_c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"new-post-avatar-container"},[(_vm.user.image_url !== null)?_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.user.image_url}}):_c('img',{staticClass:"img-fluid user-avatar shadow",attrs:{"src":'https://eu.ui-avatars.com/api/?name=' +
            _vm.user.firstname +
            '+' +
            _vm.user.lastname}})]),_c('div',{staticClass:"new-post-content-container"},[_c('div',[_c('editor',{attrs:{"api-key":"b4gid2ob2kaldj1kqtkuyxxt9jjv4xop73ank75kchgdimcy","init":{
              height: 300,
              menubar: false,
              plugins: 'autolink link paste',
              toolbar: 'bold autolink italic underline',
              branding: false,
              statusbar: false,
              paste_as_text: true,
              paste_block_drop: true,
            }},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}})],1),_vm._m(0)])]),_c('div',{staticClass:"d-flex flex-row justify-content-end"},[_c('div',{staticClass:"new-post-buttons d-flex flex-row justify-content-between mx-0"},[_c('button',{staticClass:"btn cta-button px-5 py-3",on:{"click":function($event){return _vm.openModal()}}},[_vm._m(1)]),_c('button',{staticClass:"btn cta-button px-5 py-3",on:{"click":function($event){return _vm.createPost()}}},[(!_vm.isSending)?_c('span',{staticClass:"fade-in"},[_vm._v("Post")]):_c('span',[(_vm.sendSuccess === null)?_c('b-spinner',{staticClass:"white button-spinner"}):_vm._e(),(_vm.sendSuccess === true)?_c('span',[_vm._v("Sent "),_c('i',{staticClass:"fas fa-check button-icon"})]):_vm._e(),(_vm.sendSuccess === false)?_c('span',[_c('i',{staticClass:"fas fa-times button-icon"})]):_vm._e()],1)])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"new-post-disclaimer"},[_vm._v(" Posts are "),_c('b',[_vm._v("public")]),_vm._v(" to everyone in your organisation. ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"fade-in"},[_vm._v(" Picture/Video "),_c('i',{staticClass:"pl-3 fas fa-camera btn-icon"})])}]

export { render, staticRenderFns }