<template>
	<div id="GpHome">
		<div class="" v-if="loading">
			<br />
			<br />
			<Loader />
		</div>
		<div class="animate__animated animate__fadeIn" v-else>
			<div class="cancel-toast-message animate__animated animate__ bg-brand-primary" v-if="showCancelToast">
				Your appointment has been cancelled</div>
			<section id="GpBanner" class="gp-home-banner-container">
				<div class="content-container">
					<div class="row gp-home-banner mx-0 d-flex align-items-center">
						<div class="gp-home-banner-text col-md-7 col-sm-12">
							<h1 class="gp-home-banner-title brand-primary">Digital GP<br> services</h1>
							<p class="pb-5">
								Get support from wherever you are in the UK with phone and video appointments.
							</p>
							<div v-if="!bookingCanceled && bookings.length === 0">
								<b-button variant="cta"
									@click="$router.push({ name: 'gpSelect', params: { appt_type: 'gp' } })"
									v-if="!hasGpBooking" class="mr-3 mb-2" squared>Book
									with a
									GP <i class="fa-solid fa-chevron-right"></i></b-button>
								<b-button variant="cta-outline" class="mb-2"
									@click="$router.push({ name: 'gpSelect', params: { appt_type: 'physio' } })"
									v-if="!hasPhysioBooking" squared>Book
									with a
									Physio <i class="fa-solid fa-chevron-right"></i></b-button>
							</div>

						</div>
					</div>
				</div>
			</section>
			<section class="bg-white py-5" v-if="bookings.length > 0 || bookingCanceled">
				<div class="content-container">
					<div class="d-flex flex-row justify-content-between align-items-center mt-4 title-padding">
						<h2 class="section-title mb-0 text-left">Your appointments</h2>
						<div class="d-none d-md-flex" v-if="bookings.length > 0 || bookingCanceled">
							<b-button variant="cta" @click="$router.push({ name: 'gpSelect', params: { appt_type: 'gp' } })"
								v-if="!hasGpBooking" class="mr-3" squared>Book
								with a
								GP <i class="fa-solid fa-chevron-right"></i></b-button>
							<b-button variant="cta" class=""
								@click="$router.push({ name: 'gpSelect', params: { appt_type: 'physio' } })"
								v-if="!hasPhysioBooking" squared>Book
								with a
								Physio <i class="fa-solid fa-chevron-right"></i></b-button>
						</div>
					</div>
					<div class="row" v-if="bookings.length > 0">
						<div v-for="(booking, i) in bookings" class="col-md-6 col-sm-12 pb-3">
							<div class="appointment-card bg-grey">
								<div class="d-flex flex-row justify-content-between align-items-center pb-3">
									<h3>{{ getFriendlyDateText(booking.appt_date) }}</h3>
									<a @click="openCancelModal(booking)" v-if="shouldShowCancelLink(booking.appt_date)">Cancel appointment</a>
								</div>
								<div class="appointment-card-details">
									<h4>{{ booking.appt_type === 'gp' ? 'GP' : 'Physio' }} appointment</h4>
									<div><i class="fa-regular fa-user" /> {{ booking.clinician_name }}</div>
									<div><i class="fa-regular fa-clock" /> {{ getFormatedDate(booking.appt_date, 'h.mma') }} - {{ getEndTime(booking.appt_date) }}
									</div>
									<div><i class="fa-regular fa-video" /> {{ booking.appt_delivery === 'video' ? 'Video' :
										'Audio' }} call</div>
									<b-button variant="cta" size="lg" class="mt-5" squared @click="openModal(booking)">Join
										appointment</b-button>
								</div>
							</div>
						</div>
					</div>
					<div class="flex flex-column brand-text" v-else>
						<div class="no-appointments mb-3">
							You have no upcoming appointments.

						</div>
						<div class="d-flex flex-column justify-content-start align-items-start d-md-none"
							v-if="bookings.length > 0 || bookingCanceled">
							<b-button variant="cta" @click="$router.push({ name: 'gpSelect', params: { appt_type: 'gp' } })"
								v-if="!hasGpBooking" class="mr-3 mb-3 fixed-width" squared>Book
								with a
								GP <i class="fa-solid fa-chevron-right"></i></b-button>
							<b-button variant="cta" class="mb-3 fixed-width"
								@click="$router.push({ name: 'gpSelect', params: { appt_type: 'physio' } })"
								v-if="!hasPhysioBooking" squared>Book
								with a
								Physio <i class="fa-solid fa-chevron-right"></i></b-button>
						</div>
					</div>
				</div>
			</section>
			<section class="bg-grey py-5">
				<div class="content-container py-5">
					<h2 class="section-title">How our digital appointments work</h2>
					<div class="row d-flex align-items-stretch">
						<div class="col-md-4 col-sm-12 mb-3">
							<div class="info-card">
								<img src="/img/gp/choose_appointment.svg">
								<h3>1. Choose your appointment</h3>
								<p>Select a video or phone appointment at a time and date that suits you. We'll send you an
									email confirmation with a link to a questionnaire.</p>
							</div>
						</div>
						<div class="col-md-4 col-sm-12 mb-3">
							<div class="info-card">
								<img src="/img/gp/complete_questionnaire.svg">
								<h3>2. Complete questionnaire</h3>
								<p>Tell us about your symptoms or reason for booking. We'll ask you some questions about
									your
									medical history too.</p>
							</div>
						</div>
						<div class="col-md-4 col-sm-12 mb-3">
							<div class="info-card">
								<img src="/img/gp/speak_to_gp.svg">
								<h3>3. Speak to an expert</h3>
								<p>Talk to a GP about your next steps. This could be further tests, a prescription or a
									referral
									to a specialist. </p>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
		<b-modal id="cancel-modal" v-bind:modal-class="'cancel-modal ' + getTheme()" hide-footer hide-header centered>
			<div class="d-flex flex-row justify-content-end">
				<span class="close-button mr-2" @click="$bvModal.hide('cancel-modal')"><i
						class="fas fa-times brand-highlight-1"></i></span>
			</div>
			<div class="d-flex flex-column justify-content-center align-items-center w-100" v-if="bookingToCancel">
				<h3 class="section-title mt-3">Are you sure you want to cancel?</h3>
				<div class="pb-3"><b>Appointment details</b></div>
				<div class="pb-2"><b>Clinician: </b>{{ bookingToCancel.clinician_name }}</div>
				<div class="pb-2"><b>Date: </b>{{ getFormatedDate(bookingToCancel.appt_date, 'DD MMMM YYYY') }}
				</div>
				<div class="pb-2"><b>Time: </b>{{ getFormatedDate(bookingToCancel.appt_date, 'h.mma') }}</div>
				<b-button variant="cta" size="lg" class="my-5" squared @click="cancelBooking(bookingToCancel)"><span
						v-if="!bookingCancelLoading">Yes, cancel
						appointment</span>
					<b-spinner style="width: 1.4rem; height: 1.4rem;" v-else></b-spinner>
				</b-button>
			</div>
		</b-modal>
	</div>
</template>

<script>
import axios from "axios";
import config from "../config/constants";
import slick from "vue-slick";
import MeetingRoomModal from "../components/GP/MeetingRoomModal.vue";
import AudioMeetingRoomModal from "../components/GP/AudioMeetingRoomModal.vue";
import EarlyModal from "../components/GP/EarlyModal.vue";
import RedirectModal from "../components/GP/RedirectModal.vue";
import ErrorModal from "../components/GP/ErrorModal.vue";

import Loader from "../components/Loader.vue";
import moment from "moment";

export default {
	name: "GpHome",
	components: {
		slick,
		Loader,
		RedirectModal,
		ErrorModal
	},
	data() {
		return {
			loading: true,
			bookings: [],
			bookingCanceled: false,
			bookingToCancel: false,
			bookingCancelLoading: false,
			showCancelToast: false,
		};
	},
	mounted() {
		this.getBookings();
	},
	methods: {
		getEndTime(startTime) {
			let time = moment(startTime);
			time.add(15, 'm');
			return time.format('h.mma');
		},
		shouldShowCancelLink(startTime){
			return !(moment().isAfter(moment(startTime)))
		},
		async getBookings() {
			const payload = {
				user_id: this.$store.getters.user_id,
			};
			let res = await axios.post(
				config.api_env + "/application/api/u/gp/get-bookings",
				payload,
				config.options
			);
			this.loading = false;
			if (res.data.success) {
				this.bookings = res.data.bookings.sort((a, b) => new Date(a.appt_date) - new Date(b.appt_date));
			} else if (res.data.start_sso_journey) {
				this.openErrorModal('Your session has expired', "Please log in and try again.", true);
			} else if (res.data.err && res.data.err.includes("3994")) {
				this.openErrorModal('Your session has expired', "Please log in and try again.", true);
			} else {
				this.openErrorModal("Sorry, we've had a problem", "Please try again. <br /> <br /> If the problem persists please call us on <b>0345 608 0898</b>. We’re here to help Mon-Fri 8am-6pm, Sat 8am-4pm");
			}
		},
		async cancelBooking() {
			let self = this;
			this.bookingCancelLoading = true;
			const payload = {
				user_id: this.$store.getters.user_id,
				booking_id: this.bookingToCancel.id
			};
			let res = await axios.post(
				config.api_env + "/application/api/u/gp/cancel-booking",
				payload,
				config.options
			);
			if (res.data.success) {
				this.getBookings().then(() => {
					this.$bvModal.hide('cancel-modal');
					this.bookingCancelLoading = false;
					this.bookingCanceled = true;
					this.showCancelToast = true;
					setTimeout(() => {
						self.showCancelToast = false;
					}, 5000);
				});
			} else if (res.data.start_sso_journey) {
				this.openErrorModal('Your session has expired', "Please log in and try again", true);
			} else if (res.data.err && res.data.err.includes("3994")) {
				this.openErrorModal('Your session has expired', "Please log in and try again", true);
			} else{
				this.openErrorModal("Sorry, we've had a problem", "Please try again. <br /> <br /> If the problem persists please call us on <b>0345 608 0898</b>. We’re here to help Mon-Fri 8am-6pm, Sat 8am-4pm");
			}

		},
		openCancelModal(booking) {
			this.bookingToCancel = booking;
			this.$bvModal.show("cancel-modal");
		},
		openModal(booking) {
			console.log('openModal -> ', booking)
			if (true) {
				if(booking.appt_delivery === 'phone'){
					this.openAudioBookingModal(booking);
				} else {
					this.openBookingModal(booking);
				}
			} else {
				this.openBookingModal(booking, {});
			}
		},
		openRedirectModal() {
			const content = this.$createElement(RedirectModal)
			const skin = this.$store.getters.skin;
			let opts = {
				hideHeader: true,
				hideFooter: true,
				modalClass: "coach_modal theme-" + skin,
				size: "sm",
				buttonSize: "sm",
				centered: true,
				contentClass: "coach_modal",
				id: 'redirect-modal'
			}
			return this.$bvModal.msgBoxOk(content, opts);
		},
		openErrorModal(title, error, redirect) {
			const content = this.$createElement(ErrorModal, { props: {title, error, redirect}})
			const skin = this.$store.getters.skin;
			let opts = {
				hideHeader: true,
				hideFooter: true,
				modalClass: "coach_modal theme-" + skin,
				size: "md",
				buttonSize: "sm",
				centered: true,
				contentClass: "coach_modal",
				id: 'error-modal'
			}
			return this.$bvModal.msgBoxOk(content, opts);
		},
		openBookingModal(booking) {
			const content = this.$createElement(MeetingRoomModal, { props: { booking: booking } })
			const skin = this.$store.getters.skin;
			let opts = {
				title: "My Coaching Session",
				hideHeader: true,
				hideFooter: true,
				modalClass: "coach_modal theme-" + skin,
				headerClass: "coach_modal_header bg-brand-secondary d-flex justify-content-center",
				size: "lg",
				buttonSize: "sm",
				centered: true,
				contentClass: "coach_modal",
				id: 'bupa_gp_modal'
			}
			return this.$bvModal.msgBoxOk(content, opts);
		},
		openAudioBookingModal(booking) {
			const content = this.$createElement(AudioMeetingRoomModal, { props: { booking: booking } })
			const skin = this.$store.getters.skin;
			let opts = {
				title: "My Coaching Session",
				hideHeader: true,
				hideFooter: true,
				modalClass: "coach_modal theme-" + skin,
				headerClass: "coach_modal_header bg-brand-secondary d-flex justify-content-center",
				size: "lg",
				buttonSize: "sm",
				centered: true,
				contentClass: "coach_modal",
				id: 'bupa_gp_modal'
			}
			return this.$bvModal.msgBoxOk(content, opts);
		},
		openEarlyModal(booking) {
			const content = this.$createElement(EarlyModal, { props: { booking: booking } })
			const skin = this.$store.getters.skin;
			let opts = {
				title: "My Session",
				hideHeader: true,
				hideFooter: true,
				modalClass: "coach_modal theme-" + skin,
				headerClass: "coach_modal_header bg-brand-secondary d-flex justify-content-center",
				size: "md",
				buttonSize: "sm",
				centered: true,
				contentClass: "coach_modal",
				id: 'digital_clinics_early_modal'
			}
			return this.$bvModal.msgBoxOk(content, opts);
		},
	},
	computed: {
		hasGpBooking() {
			return this.bookings.filter((booking) => booking.appt_type === 'gp').length > 0;
		},
		hasPhysioBooking() {
			return this.bookings.filter((booking) => booking.appt_type === 'physio').length > 0;
		},
	},
};
</script>
<style lang="less">
#cancel-modal.cancel-modal {
	font-size: 1.6rem;

	.modal-dialog {
		padding-bottom: 0 !important;
	}

	.modal-content {}

	.section-title {
		font-size: 2rem;
		font-family: "Montserrat", serif;
		font-weight: 700;
		letter-spacing: 0;
		margin-bottom: 40px;
	}

	b {
		font-family: 'Montserrat', serif;
	}

	i {
		font-size: 2rem;
	}

	.fa-times {
		cursor: pointer;
	}
}

#GpHome {
	font-family: "Montserrat", serif !important;
	.cancel-toast-message {
		z-index: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 15px;
		font-size: 1.6rem;
		color: white;
	}

	.gp-home-banner-container {
		background-image: url("/img/gp/gp_home.jpg");
		background-position: center top;
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;

		.gp-home-banner-text {
			margin-top: 50px;
			margin-bottom: 50px;
			background-color: rgba(255, 255, 255, 0.7);
			font-size: 1.6rem;
			z-index: 2;
			padding: 35px;
			border-radius: 12px;
			max-width: 500px;

			.gp-home-banner-title {
				font-family: 'Montserrat', sans-serif;
				font-weight: 700;
				font-size: 5rem;
				padding-bottom: 15px;
			}

			p {
				font-family: 'Montserrat', sans-serif;
				font-weight: 600;
				margin-bottom: 0;
			}
		}

		div {
			z-index: 2;
		}
	}

	.coverage-container {
		width: 500px;
		position: absolute;
		bottom: 0px;
		right: 0;
		font-size: 1.6rem;
		color: white;
	}

	.bupa-select {
		font-size: 1.8rem;
	}

	.appointment-card {
		font-size: 1.6rem;
		padding: 20px;

		h3 {
			font-size: 2.5rem;
			padding-top: 0;
			padding-bottom: 0;
			letter-spacing: normal;
			color: #212529;
		}

		h3:first-letter {
			text-transform: uppercase;
		}

		div {
			padding-bottom: 5px;
		}

		.appointment-card-details {
			border-top: 1px solid #ccc;

			h4 {
				font-family: "Montserrat", serif;
				padding-top: 15px;
				margin-bottom: 10px;
			}

			i {
				font-size: 1.6rem;
				padding-right: 10px;
			}

			div {
				display: flex;
				flex-direction: row;
				align-items: center;
			}
		}
	}

	.info-card {
		height: 100%;
		font-size: 1.6rem;
		padding: 30px 20px;
		background-color: white;

		img {
			padding-bottom: 15px;
		}

		h3 {
			font-size: 1.6rem;
			padding-top: 0;
			margin-bottom: 15px;
			padding-bottom: 0;
			letter-spacing: normal;
		}
	}


	.section-title {
		font-size: 2.8rem;
		font-family: "Montserrat", serif;
		font-weight: 700;
		letter-spacing: 0;
		margin-bottom: 50px;
	}

	.title-padding {
		padding-bottom: 50px;
	}

	.fixed-width {
		width: 100%;
		max-width: 160px;
	}

	a {
		text-decoration: underline;
	}

	.no-appointments {
		background-color: #ddedf8;
		padding: 20px;
		font-size: 1.6rem;
		font-weight: bold;
		font-family: "Montserrat", serif;
	}
	.btn{
		min-height: 50px;
	}


}
</style>
