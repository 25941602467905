<template>
  <div id="AudioRoomContainer" class="pb-5">
    <div class="d-flex justify-content-center align-items-cente loader-container" v-if="loading">
      <Loader />
    </div>
    <div v-else class="container">
      <transition name="fade">
        <div key="2">
          <AudioRoom
	          :local_audio_track="local_audio_track"
	          :access_token="access_token"
	          :clinician_name="clinician_name"
            @terminateVideoAndAudio="destroyAll"
          />
        </div>
      </transition>

    </div>
  </div>
</template>

<script>

import Loader from "@/components/Loader";
import AudioRoom from "./AudioRoom";

const { LocalAudioTrack, createLocalAudioTrack } = require('twilio-video');

export default {
  name: "AudioRoomContainer",
  components: { Loader, AudioRoom },
  props: ['booking_id'],
  data() {
    return {
      loading: true,
	    access_token: null,
      local_audio_track: null,
	    clinician_name: ''
    }
  },
  mounted() {
    this.getBooking()
  },
  provide: function () {
    return {
      createLocalAudio: this.createLocalAudio,
      destroyLocalAudio: this.destroyLocalAudio,
    }
  },
  beforeDestroy() {
    this.destroyLocalAudio()
  },
  methods: {
    destroyAll() {
      this.destroyLocalAudio();
    },
    async getBooking() {
      let params = {
        path: "api/u/gp/get-booking",
        booking_id: this.booking_id,
      }
      let res = await this.api(params)
      if (res.success === true) {
        this.access_token = res.jwt;
				this.clinician_name = res.booking.clinician_name
      }
	    this.loading = false;
    },
    async createLocalAudio() {
      try {
        if (this.local_audio_track instanceof LocalAudioTrack) {
          console.log('Local audio track already created ....')
        } else {
          this.local_audio_track = await createLocalAudioTrack({
            audio: true,
            dominantSpeaker: true,
	          getUserMedia: {
							audio : true
	          },
            maxAudioBitrate: 16000, //For music remove this line
            networkQuality: {
              local: 1,
              remote: 1
            },
          });
        }
        return true;
      }
      catch (exception_var) {
        console.log('create local audio exception', exception_var)
      }
      finally {
        console.log('create local audio track', this.local_audio_track instanceof LocalAudioTrack)
      }
    },
	  
    // DESTROY LOCAL MEDIA TRACKS
    async destroyLocalAudio() {
      try {
        if (this.local_audio_track instanceof LocalAudioTrack) {
          this.local_audio_track.stop()
          this.local_audio_track.detach()
          this.local_audio_track = false
        } else {
          console.log('Local audio track not here ....')
        }
      }
      catch (exception_var) {
        console.log('destroy audio video exception: ', exception_var)
      }
      finally {
        console.log('destroy audio video track res: ', this.local_video_track instanceof LocalAudioTrack)
      }
    },
  }
};
</script>

<style scoped lang="less">
#AudioRoomContainer {
  font-size: 1.6rem;

  //.loader-container {
  //  height: 600px;
  //}

  .img_wrapper {
    text-align: center;
    padding: 0px 20px;

    img {
      margin-bottom: 20px;
      border-radius: 50%;
      border: 4px solid #eee;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.09);
    }
  }

  .meeting_time {
    padding: 30px;

    .meeting_time_inner {
      box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
      background-color: #403D56;
      color: white;

      div {
        padding: 6px 10px;
      }

      margin-bottom: 15px;
      border-radius: 15px 15px 0px 15px;

      .day {
        font-size: 17px;
      }

      .hour {
        font-weight: lighter;
        font-size: 14px;
      }

      .date {
        font-size: 30px;
      }

      .month {
        font-size: 17px;
        font-weight: lighter;
      }

      .start_booking {
        position: relative;
        padding: 0px;
        min-height: 25px;

        .start_booking_inner {
          position: absolute;
          right: 0px;
          bottom: 0px;
          background-color: #E83B75;
          padding: 7px 25px;
          border-radius: 15px 0px 0px 0px;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  .clock_icon {
    text-align: center;
    font-size: 50px;
  }

  .bookings-wrap {
    width: 100%;

    .booking {
      width: 100%;
      border-left-style: solid;
      border-left-width: 10px;
      font-size: 2rem;
      cursor: pointer;
      border-color: #d60570;
      color: #fff;
      font-family: ff-cocon-web-pro, sans-serif;
      background-color: #cecece;
    }
  }
}
</style>
