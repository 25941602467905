<template>
	<div class="stripe_pay_with_saved_card">
		<div v-if="!loading" class=" animate__animated animate__fadeIn">
			<div class="payment">
				<div class="">
					<b-button disabled v-if="this.status === 'loading'" variant="cta" class="filson-book shadow" @click="payWithSavedCard">Processing <i class="fa-solid fa-circle-notch fa-spin"></i></b-button>
					<div v-else-if="this.status === 'success'">
						<p>{{ui_message}}</p>
						<b-button variant="cta" class="filson-book shadow" @click="closeModal">Done</b-button>
					</div>
					<div v-else-if="status === 'authentication_required'" class=" animate__animated animate__fadeIn">
						<p>Authentication required, please wait ...</p>
						<i class="fa-solid fa-circle-notch fa-spin fa-3x"></i>
					</div>
					<div v-else class=" animate__animated animate__fadeIn">
						<p>{{ui_message}}</p>
						<b-button variant="cta" class="filson-book shadow" @click="closeModal">Close</b-button>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>
<script>
import axios from "axios";
import config from "../../config/constants";

export default {
	components: {
	
	},
	name: "StripePayWithSavedCard",
	data() {
		return {
			loading: false,
			currency: 'EUR',
			payment_intent: '',
			status : 'loading', // ['input','loading','success','failed', 'authentication_required'],
			ui_message : ''
		};
	},
	mounted() {
		this.payWithSavedCard()
	},
  props: {
    timeslot_id: [Number, String],
    wallet_amount: [Number, String],
    card_amount: [Number, String],
    return_url: String,
    price: [Number, String],
    is_cmh: {
      type: Boolean,
      default: false
    }
  },
	methods: {
		closeModal() {
			this.$bvModal.hide("clinician_modal");
			setTimeout(()=>{
        if(this.is_cmh) {
          this.$router.push({path: '/service-dashboard/'+this.$route.params.slug})
        } else {
          this.$router.push({name :'digitalClinicsGeneric'})
        }
			}, 500)
		},
		async payWithSavedCard(){
			// this.status = 'loading'
			let res = await this.api({
				path: "api/u/digital-bookings/combined-payment-with-saved-card",
				timeslot_id: this.timeslot_id,
				wallet : this.wallet_amount,
				card : this.card_amount,
				price : this.price
			});
			if (res.success === false) {
				this.message = 'Something went wrong'
				return
			}
			if (res.success) {
				this.ui_message = 'Payment Succeeded'
				this.status = 'success'
				setTimeout(()=>{
					this.closeModal()
				}, 3000)
			} else {
				if(res.status === 'authentication_required'){
					this.status = 'authentication_required'
					this.payment_intent = res.intent
					this.checkStripe()
				} else {
					this.ui_message = 'Something went wrong'
					this.status = 'failed'
				}
			}
		},
		checkStripe() {
			if (window.Stripe) {
				this.initStripeForm();
			} else {
				setTimeout(() => this.checkStripe(), 500);
			}
		},
		async initStripeForm() {
			const stripe = window.Stripe(config.stripe_key);
			const _this = this
			stripe.confirmCardPayment(this.payment_intent.client_secret, {
				payment_method: this.payment_intent.last_payment_error.payment_method.id
			}).then(function(result) {
				if (result.error) {
					// Show error to your customer
					_this.ui_message = result.error.message
					_this.status = 'failed'
				} else {
					if (result.paymentIntent.status === 'succeeded') {
						// request the backend with payment_id to process payment
						_this.status = 'success'
						_this.ui_message = 'Payment Succeeded'
						setTimeout(()=>{
							_this.$bvModal.hide("clinician_modal");
							setTimeout(()=>{
                if(this.is_cmh) {
                  _this.$router.push({path: '/service-dashboard/'+this.$route.params.slug})
                } else {
                  _this.$router.push({name :'digitalClinicsGeneric'})
                }
							}, 500)
						}, 3000)
					}
				}
			});
		},
	},
};
</script>
<style lang="less">
.stripe_pay_with_saved_card{
	h4 {
		text-align: center;
		font-size: 2rem;
		font-family: "FilsonProBold", sans-serif;
	}
	p {
		font-size: 14px;
		padding: 20px 50px;
	}
	.success i {
		font-size: 70px;
	}
	&.success i {
		color: forestgreen;
	}
	#submit {
		font-size: 14px;
		font-family: "FilsonProLight", sans-serif;
	}
	#payment_form_message {
		font-size: 14px;
		margin-top: 2rem;
	}
}
</style>
