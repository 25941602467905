<template>
<div class="video-player" ref="videoContainer">
    <div class="video-player__container" 
    :class="{ hide_template: getIsHideNavigation }" 
    @dblclick="onFullScreen"> 

    <button class="btn btn--clear p-0 video-player__play-btn"  
    v-on:click="onPlayButtonClick"> 

    <img  v-if="src && src !== 'REST'" class="video-player__play-img"
    :class="{ active: isPlay }" 
    :src="'/img/play' + (user_skin_id > 0 ? '-generic-transparent' : '') + '.svg'"
    alt="play icon" 
    v-show="!isPlay"> 

    <img  v-if="src && src !== 'REST'" class="video-player__play-img player-menu__pause-img"
    :class="{ active: isPlay }" 
    :src="'/img/pause' + (user_skin_id > 0 ? '-generic-transparent' : '') + '.svg'"
    alt="play icon" v-show="isPlay"> 
    </button>

    <video v-if="src != null && poster" class="align-items-center video-player__content"
    :class="{full_screen: isFullScreen}" 
    width="100%" 
    :loop="false" 
    :controls="false"
           :poster="poster"
    ref="videoPlayer" 
    @timeupdate="onTimeupdate">
    <source :src="src +'#t=2'" >
    </video>
        <video v-if="src != null && !poster" class="align-items-center video-player__content"
               :class="{full_screen: isFullScreen}"
               width="100%"
               :loop="false"
               :controls="false"
               :poster="poster"
               ref="videoPlayer"
               @timeupdate="onTimeupdate">
            <source :src="src +'#t=2'" >
        </video>
        <div class="video__error brand-highlight-1"  v-if="src == null">
        Video not found
        </div>


    <div  v-if="src != null" class="video-player__menu hide-start bg-brand-primary"
    :class="{ active: !isPlay }"> 
    <div class="video-player__left-navigation"> 
        <div class="video-player__progress-bar" 
        @mousedown="onProgressBarClick"> 
        <div class="video-player__slider" ref="videoProgressBar"></div> 
        <div class="video-player__current-progress bg-brand-highlight-1" ref="videoProgress"></div>
        </div> 
    <button class="btn btn--clear p-0" v-on:click="onPlayButtonClick"> 
        <img class="player-menu__img" 
        :class="{ active: isPlay }" 
        :src="'/img/play' + (user_skin_id > 0 ? user_skin_id < 7 ? '-generic' : '-7' : '') + '.svg'"
        alt="play icon" 
        v-show="!isPlay"> 
        <img class="player-menu__img" 
        :class="{ active: isPlay }" 
        :src="'/img/pause' + (user_skin_id > 0 ? user_skin_id < 7 ? '-generic' : '-7' : '') + '.svg'"
        alt="play icon" 
        v-show="isPlay"> 
        </button>
    <div class="video-player__volume-bar"
    @mousedown="onVolumeBarClick" 
    ref="volumeBar"> 
    <div class="video-player__current-volume bg-brand-highlight-1"
    ref="currentVolume"></div> 
</div> 
</div> 
<button class="btn btn--clear p-0 video-player__full-screen" v-on:click="onFullScreen">
<svg class="player-menu__full-screen" height="100%" version="1.1" viewBox="0 0 36 36" width="100%">
        <g class="ytp-fullscreen-button-corner-0">
                <use class="ytp-svg-shadow" xlink:href="#ytp-id-24"></use>
                <path class="ytp-svg-fill" d="m 10,16 2,0 0,-4 4,0 0,-2 L 10,10 l 0,6 0,0 z" id="ytp-id-24"></path>
            </g>
        <g class="ytp-fullscreen-button-corner-1">
                <use class="ytp-svg-shadow" xlink:href="#ytp-id-25"></use>
                <path class="ytp-svg-fill" d="m 20,10 0,2 4,0 0,4 2,0 L 26,10 l -6,0 0,0 z" id="ytp-id-25"></path>
            </g>
        <g class="ytp-fullscreen-button-corner-2">
                <use class="ytp-svg-shadow" xlink:href="#ytp-id-26"></use>
                <path class="ytp-svg-fill" d="m 24,24 -4,0 0,2 L 26,26 l 0,-6 -2,0 0,4 0,0 z" id="ytp-id-26"></path>
            </g>
        <g class="ytp-fullscreen-button-corner-3">
                <use class="ytp-svg-shadow" xlink:href="#ytp-id-27"></use>
                <path class="ytp-svg-fill" d="M 12,20 10,20 10,26 l 6,0 0,-2 -4,0 0,-4 0,0 z" id="ytp-id-27"></path>
            </g>
    </svg>
</button>
</div> 
</div> 
</div>
</template>

<script>
    export default{
        name: "VideoPlayer",
        props: ["src", "poster"],
        computed: {
            getIsHideNavigation() {
                return !this.isHideTemplate && this.isFullScreen;
            },
        },

        data() {
            return {
                progressInterval: null,
                isPlay: false,
                isFullScreen: false,
                isHideTemplate: false,
                user_skin_id: this.$store.getters.skin,
                mouseData: {
                    x: 0,
                    y: 0
                },
            };
        },
        mounted () {
            if (this.src && this.src !== 'REST') {
                this.init();
            }

        },

        watch: {
            src() {
                this.$refs.videoPlayer.load()
                if (this.src && this.src !== 'REST') {
                    this.init();
                }
            }
        },

        methods: {
            init() {
                const volumeBar = this.$refs.volumeBar;

                if (volumeBar) {
                    const volumeBarWidth = volumeBar.getBoundingClientRect().width;
                    this.setCurrentVolumeWidth(volumeBarWidth);
                }

                this.resetVideo();
            },

            resetVideo() {
                this.isPlay = false;
            },

            onTimeupdate() {
                this.setCurrentProgressWidth();
            },

            onPlayButtonClick () {
                const video = this.$refs.videoPlayer;

                if (video.paused) {
                    video.play();
                    this.isPlay = true;
                } else {
                    video.pause();
                    this.isPlay = false;
                }
            },

            setCurrentProgressWidth() {
                const video = this.$refs.videoPlayer;

                if (video) {
                    this.$refs.videoProgress.style.width = video.currentTime / video.duration * 100 + '%'
                }
            },

            onProgressBarClick(event) {
                this.setCurrentVideoTime(event);

            },

            setCurrentVideoTime(event) {
                const progressBarWidth = this.$refs.videoProgressBar.getBoundingClientRect().width;
                const video = this.$refs.videoPlayer;
                const {offsetX} = event;

                if (video) {
                    video.currentTime = (video.duration * (offsetX / progressBarWidth));
                    this.setCurrentProgressWidth();
                }
            },

            onVolumeBarClick() {
                this.setCurrentVoulume();

                document.addEventListener("mousemove", this.setVolumeOnMouseMove, true);
                document.addEventListener("mouseup", () => {
                    document.removeEventListener("mousemove", this.setVolumeOnMouseMove, true);
                });
            },

            setVolumeOnMouseMove(eventMuseMove) {
                const clientRect = this.$refs.volumeBar.getBoundingClientRect();
                const volumeBarWidth = clientRect.width;
                const volumeBarOffsetLeft = clientRect.left;
                const currentVolumePosition = eventMuseMove.clientX - volumeBarOffsetLeft;

                if (((currentVolumePosition) >= 0) && ((currentVolumePosition) <= volumeBarWidth)) {
                    const video = this.$refs.videoPlayer;

                    if (video) {
                        video.volume = (currentVolumePosition) / volumeBarWidth;
                        this.setCurrentVolumeWidth(volumeBarWidth);
                    }

                }
            },

            setCurrentVoulume() {
                const volumeBarWidth = this.$refs.volumeBar.getBoundingClientRect().width;
                const video = this.$refs.videoPlayer;
                const {offsetX} = event;

                if (video) {
                    video.volume = offsetX / volumeBarWidth;
                    this.setCurrentVolumeWidth(volumeBarWidth);
                }
            },

            setCurrentVolumeWidth(volumeBarWidth) {
                const video = this.$refs.videoPlayer;
                if (video) {
                    this.$refs.currentVolume.style.width = volumeBarWidth * video.volume + '%';
                }
            },

            onFullScreen() {
                if (this.isFullScreen) {
                    this.makeSimpleScreen();
                    document.removeEventListener("mousemove", this.showElement, true);
                } else {
                    this.makeFullScreen();
                }
            },

            makeFullScreen() {
                const videoContainer = this.$refs.videoContainer;

                if (videoContainer.requestFullscreen) {
                    videoContainer.requestFullscreen();
                } else if (videoContainer.mozRequestFullScreen) {
                    videoContainer.mozRequestFullScreen();
                } else if (videoContainer.webkitRequestFullScreen) {
                    videoContainer.webkitRequestFullScreen();
                } else if (videoContainer.msRequestFullscreen) {
                    videoContainer.msRequestFullscreen();
                }

                document.addEventListener("webkitfullscreenchange", this.onChangeFullScreen, false);
                document.addEventListener("mozfullscreenchange", this.onChangeFullScreen, false);
                document.addEventListener("fullscreenchange", this.onChangeFullScreen, false);
                document.addEventListener("MSFullscreenChange", this.onChangeFullScreen, false);
                document.addEventListener("mousemove", this.showElement, true);
            },

            makeSimpleScreen() {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            },

            onChangeFullScreen() {
                this.isFullScreen = !this.isFullScreen;

                if (!this.isFullScreen) {
                    document.removeEventListener("mousemove", this.showElement, true);
                }
            },

            showElement(event) {
                const {offsetX, offsetY} = event;
                this.mouseData.x = offsetX;
                this.mouseData.y = offsetY;


                this.isHideTemplate = true;
                setTimeout(() => {
                    if (this.mouseData.x === offsetX && this.mouseData.y === offsetY) {
                        this.hideElement();
                    }
                }, 0);
            },

            hideElement: function () {
                setTimeout(() => {
                    this.isHideTemplate = false;
                }, 1500);
            }
        }}
</script>
<style lang="less">
    .video-player__container {
        position: relative;
        overflow: hidden;
        border-radius: 12px;
    }

    .video__error {
        text-align: center;
        height: 100px;
    }

    .video-player__container video {
        border-radius: 12px;
        object-fit: fill;
    }

    .video-player__play-btn {
        width: 43px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
    }

    .video-player__play-img.active {
        opacity: 0;
        will-change: opacity;
        transition: opacity 0.5s;
        transition-delay: 0.3s;
    }

    .video-player__container:hover .video-player__play-img.active {
        opacity: 1;
        transition-delay: 0s;
    }

    .video-player__container:hover .video-player__play-img.active.player-menu__pause-img {
        opacity: 0.7;
    }

    .video-player__left-navigation {
        display: flex;
        align-items: center;
    }

    .video-player__menu {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px 5px 20px;

        position: absolute;
        bottom: 5px;
        left: 0;
        width: 100%;
        height: 50px;

        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;

        opacity: 0;

        transform: translateY(100%);
        transition: transform 0.5s, opacity 0.5s;
        transition-delay: 0.3s;
    }

    .video-player__menu.active {
        opacity: 1;
        transform: translateY(0);

    }

    .video-player__menu.hide-start {
        transform: translateY(100%)

    }
    .video-player__container:hover .video-player__menu {
        opacity: 1;
        transform: translateY(0);
        transition-delay: 0s;
    }

    .player-menu__img {
        width: 20px;
    }

    .video-player__content {
        position: relative;
    }

    .video-player__progress-bar {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 7px;
        background-color: #fff;
        opacity: 0.8;
        cursor: pointer;
    }

    .video-player__current-progress {
        position: absolute;
        top: 0;
        left: 0;
        height: 7px;
        transition: width 0.1s;
    }

    .video-player__current-progress::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 7px;
        background-color: #FFF;
        opacity: 0;
        transition: opacity 0.3s;
    }

    .video-player__progress-bar:hover .video-player__current-progress::after {
        opacity: 1;
    }

    .video-player__volume-bar {
        width: 100px;
        height: 7px;
        position: relative;
        background-color: #87D2DE;
        border-radius: 5px;
        margin-left: 15px;
        overflow: hidden;
        cursor: pointer;
    }

    .video-player__current-volume {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        background-color: #EF3A78;
    }

    .video-player__current-volume::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 7px;
        background-color: #FFF;
        opacity: 0;
        transition: opacity 0.3s;
    }

    .player-menu__full-screen {
        width: 40px;
        fill: #EE3A76;
    }

    .video-player__volume-bar:hover .video-player__current-volume::after {
        opacity: 1;
    }

    .video-player__full-screen img {
        width: 45px;
    }

    .full_screen {
        height: 100vh;
    }

    .hide_template.video-player__container:hover .active.player-menu__pause-img {
        opacity: 0;
    }

    .hide_template.video-player__container:hover .active.player-menu__pause-img:hover {
        opacity: 0.8;
    }

    .hide_template.video-player__container:hover .video-player__menu {
        opacity: 0;
        transform: translateY(100%);
        cursor: none;
    }

    .hide_template.video-player__container:hover .video-player__menu.active {
        opacity: 1;
        transform: translateY(0);
    }

    .hide_template.video-player__container:hover .video-player__menu:hover {
        opacity: 1;
        transform: translateY(0);
        cursor: none;
    }

    @media all and (max-width: 767px){
        .video-player__volume-bar,
        .player-menu__pause-img,
        .video-player__full-screen {
            display: none;
        }
    }
</style>