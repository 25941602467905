<template>
  <div id="ReminderHolder">
    <div class="d-flex justify-content-start align-items-center w-100" style="position: relative">
      <div class="reminderTab" :class="{active: view === 'new'}" @click="toggleView">NEW</div>
      <div class="reminderTab" :class="{active: view !== 'new'}" @click="toggleView">READ</div>
      <div style="position: absolute; font-size: 14px; right: 5px; top: 7px; cursor: pointer" @click="$emit('close')">X</div>
    </div>
    <div class="reminderHolderInner mt-1">
      <div v-if="syncing"  class="w-100 p-5 noNotifications d-flex justify-content-center align-items-center">
        <b-spinner label="Spinning"></b-spinner>
      </div>
      <div v-else-if="!notifications_to_view.length" class="w-100 p-5 noNotifications">
        You do not have any {{view}} notifications to view.
      </div>
      <Notification v-for="notification in notifications_to_view" :notification="notification"/>
    </div>
  </div>
</template>

<script>
import Notification from "@/components/Notification.vue";
import EventBus from "@/helpers/eventbus";
import moment from "moment";

export default {
  name: "ReminderHolder",
  components: {Notification},
  data() {
    return {
      notifications: [],
      syncing: false,
      view: "new"
    }
  },
  mounted() {
    EventBus.$on('getReminders',this.getReminders);
  },
  computed: {
    notifications_to_view() {
      const now = moment();
      if(this.view === 'new') {
        return this.notifications.filter(not => {
          if(!not.readAt) return true;
          const readAtTime = moment(not.readAt, 'YYYY-MM-DD HH:mm:ss');
          const difference = now.diff(readAtTime, 'minutes');
          return difference < 5
        })
      } else {
        return this.notifications.filter(not => {
          const readAtTime = moment(not.readAt, 'YYYY-MM-DD HH:mm:ss');
          const difference = now.diff(readAtTime, 'minutes');
          return difference > 5
        })
      }
    }
  },
  methods: {
    toggleView() {
      if(this.view === 'new') {
        this.view = 'read';
      } else {
        this.view = 'new';
      }
    },
    async getReminders() {
      if(this.syncing) return;
      this.syncing = true;
      const res = await this.api({
        path: '/api/u/notifications'
      })
      this.notifications = res.data;
      this.syncing = false;
    }
  }
}
</script>

<style lang="less">
#ReminderHolder {
  background-color: #292824;
  display: flex;
  min-width: 250px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3px 3px;
  .noNotifications {
    font-size: 1.4rem;
    color: white;
    min-width: 250px;
  }
  .reminderTab {
    padding: 5px;
    margin-right: 10px;
    margin-left: 5px;
    color: white;
    font-size: 1.3rem;
    &.active {
      border-bottom: 1px solid white;
    }
  }
}
</style>