<template>
  <div id="OauthAdditionalDetails">
    <div class="imageContainer"></div>
    <div class="loginContainer">
      <div class="innerLoginContainer">
        <div class="logoContainer">
          <img :src="getLogo" onerror="javascript:this.src='/img/logo-spectrum-life.svg'" alt="company logo"/>
        </div>

          <div>
            <h4 class="text-center">We just need a few more details from you to your registration...</h4>
          </div>
          <div v-if="error_message">
            <p style="color: red; font-size: 1.5rem" class="text-center py-2">{{error_message}}</p>
          </div>
          <div>
            <b-form class="mt-5 px-5">
              <b-form-group
                  id="input-group-4"
                  :label="componentText.orgCode"
                  label-for="org_code"
              >
                <b-form-input
                    type="text"
                    id="org_code"
                    v-model="org_code"
                    @change="getOrgLocations"
                    required
                    :class="{invalid: org_code_invalid}"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                  id="input-group-71"
                  :label="'Select gender'"
                  label-for="gender"
              ><b-form-select
                  id="gender"
                  v-model="gender"
                  class="form-control"
                  required
                  :class="{invalid: gender == null}"
              >
                <option :value="null" disabled>Select gender... </option>
                <option
                    v-for="g in gender_options"
                    :value="g"
                >{{ g }}</option
                >
              </b-form-select>
              </b-form-group>
              <b-form-group
                  v-if="showLocationsSelect"
                  id="input-group-7"
                  :label="componentText.location"
                  label-for="locations"
              ><b-form-select
                  id="locations"
                  v-model="location"
                  class="form-control"
                  required
                  :class="{invalid: location == null}"
              >
                <option :value="null" disabled>Select a location... </option>
                <option
                    v-for="location in organisation.locations"
                    :value="location.location_id"
                >{{ location.location_name }}</option
                >
              </b-form-select>
              </b-form-group>
              <b-form-group
                  v-if="showMemberLevels"
                  id="input-group-7"
                  :label="componentText.memberLevel"
                  label-for="mls"
              ><b-form-select
                  id="mls"
                  v-model="member_level"
                  class="form-control"
                  required
                  :class="{invalid: member_level == null}"
              >
                <option :value="null" disabled>Select a member level... </option>
                <option
                    v-for="ml in organisation.client_member_levels"
                    :value="ml.level_identifier"
                >{{ ml.display_name }}</option
                >
              </b-form-select>
              </b-form-group>
            </b-form>
            <div
                id="signup-button"
                class="submit-form d-flex align-items-center justify-content-center"
            >
              <b-button
                  @click="completeRegistration"
                  class="py-3 signUpBtn"
                  :disabled="signUpDisabled"
              >{{ componentText.buttonText }}</b-button>
            </div>
          </div>

      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import axios from "axios";
import config from "@/config/constants";

export default {
  name: "OauthAdditionalDetails",
  data() {
    return {
      org_code: null,
      org_code_invalid: true,
      organisation: null,
      gender: null,
      gender_options: ['Male','Female','Other'],
      location: null,
      member_level: null,
      phone: null,
      error_message: null
    }
  },
  mounted() {
  },
  methods: {
    async completeRegistration() {
      if(this.signUpDisabled) return;
      let payload = {
        organisation_code: this.org_code,
        language: this.$store.state.lang,
        location_id: this.location,
        phone: "000000000",
        gender: this.gender.toLowerCase(),
        member_level: this.member_level,
        platform: "web",
        oauth_access_token: this.$store.getters.temporaryToken
      }
      try {
        const res = await axios.post(
            config.api_env + "/application/api/u/oauth/google/signup",
            payload,
            config.options
        );
        if(res.data.success) {
          const signIn = await this.$store.dispatch('oauthSignIn',res.data.log_in_res);
          if(signIn) await this.$router.push('/')
        } else {
          this.error_message = res.data.message;
        }
      } catch (e) {
        this.error_message = 'Unauthorized. Please try to obtain a new access token.'
      }
    },
    async getOrgLocations() {
      let payload = {
        org_code: this.org_code,
        lang: this.$store.state.lang
      };
      try {
        let res = await axios.post(
            config.api_env + "/application/api-hc/get-client-signup-options",
            payload,
            config.options
        );
        if (res.data.success) {
          this.organisation = res.data;
          this.org_code_invalid = false;
          this.member_level = null;
          if (this.organisation.locations.length > 1) {
            this.location = null;
            this.locationValid = false;
          } else if (this.organisation.locations.length === 1) {
            this.location = this.organisation.locations[0].location_id;
            this.locationValid = true;
          }
          if(this.organisation.client_member_levels.length === 1) {
            this.member_level = this.organisation.client_member_levels[0].level_identifier;
          }
        } else {
          this.popUpContent = {
            header: "Oops!",
            message: res.data.err,
            buttonText: "OK"
          };
          this.signUpError = true;
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
  watch: {
    error_message(newValue) {
      if(newValue === null) return;
      setTimeout(()=> {
        this.error_message = null;
      },6000)
    }
  },
  computed: {
    ...mapGetters(["hardcoded", "skin","client"]),
    signUpDisabled() {
      if(this.org_code_invalid || !this.memberLevelValid || !this.locationValid || this.gender === null) return true;

    },
    locationValid() {
      if(this.org_code_invalid || !this.organisation) return false;
      if(!this.showLocationsSelect) return true;
      return this.location !== null;
    },
    memberLevelValid() {
      if(this.org_code_invalid || !this.organisation) return false;
      if(!this.showMemberLevels) return true;
      return this.member_level !== null;
    },
    showLocationsSelect() {
      return this.organisation && this.organisation.locations.length > 1;
    },
    showMemberLevels() {
      return this.organisation && this.organisation.member_levels.length > 1;
    },
    getLogo() {
      if (this.hardcoded && this.hardcoded.logo) {
        return this.hardcoded.logo;
      } else return this.$store.getters.client.client_logo;
    },
    componentText() {
      return this.getText.notLoggedIn.signUp;
    }
  }
}
</script>

<style lang="less">
  #OauthAdditionalDetails {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: relative;
    .logoContainer {
      height: 100px;
      width: 100%;
      img {
        float: right;
        width: 40%;
        margin-right: 10px;
      }
    }
    .imageContainer{
      background-image: url("https://spectrum-life-media.s3.eu-west-1.amazonaws.com/dfd_services/ds.webp");
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      top: 0;
      background-position: center;
      height: 110%;
      width: 135%;
      margin-right: -30%;
      margin-bottom: -20%;
      z-index: 1;
    }
    .loginContainer {
      position: absolute;
      border-radius: 30px;
      top: 5%;
      left: 4%;
      width: 45%;
      min-width: 500px;
      max-width: 650px;
      height: 90%;
      background-color: white;
      padding: 20px 5px;
      z-index: 3;
      overflow: hidden;
      .innerLoginContainer {
        height: 100%;
        width: 100%;
        overflow: auto;
        &::-webkit-scrollbar {
          width: 12px;
        }
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.5);
          border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb {
          background: linear-gradient(90deg, #ED3A75 0%, #6A356A 100%);
          -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.5);
          border-radius: 10px;
        }
      }
    }
    .signUpBtn {
      width: 100%;
      max-width: 150px;
      border-radius: 10px;
      padding: 15px 0 !important;
      font-family: "FilsonPro", sans-serif;
      font-size: 1.75rem;
      background: linear-gradient(90deg, #ED3A75 0%, #6A356A 100%);
      &.disabled {
        background: grey !important;
      }
    }
    .form-group {

      position: relative;
      border-radius: 15px;
      margin-top: 25px;
      label {
        position: absolute;
        top: -7px;
        left: 10px;
        background: white;
        font-size: 1.3rem;
        line-height: 1.2rem;
      }
      input, input:focus {
        border: 1px #DDDDDB solid;
        padding: 20px 15px !important;
        border-radius: 10px;
        font-size: 16px;
        line-height: 15px;
        &.invalid {
          border: 1px darkred solid;
        }
      }
      select {
        border: 1px #DDDDDB solid;
        border-radius: 10px;
        font-size: 16px;
        padding: 10px;
        height: fit-content;
        &.invalid {
          border: 1px darkred solid;
        }
      }
      .eye {
        position: absolute;
        top: 5px;
        right: 15px;
        font-size: 20px;
      }

    }

    @media screen and (min-height: 750px) {
      .loginContainer {
        height: calc(100% - 55px);
        top: 50px;
      }

    }
    @media screen and (max-width: 767px) {
      .loginContainer {
        left: 10px;
        width: calc(100% - 20px);
        min-width: calc(100% - 20px);
      }
    }
  }
</style>