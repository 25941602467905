<template>
    <div id="DischargedCoacheeModal">
        <b-card hide-header class="discharged-card text-center">
            <b-card-text class="mt-3">
                <h4>Hello there! Hope this message finds you very well. We wanted to keep you posted that your previous coaching journey has now been marked as closed due to inactivity. Remember, you are always welcome to re-start your wellbeing journey with our Total Mental Health coaching team. Simply click the button below to get started! We can’t wait to engage with you again 😊</h4>
            </b-card-text>
            <b-button size="lg" class="mt-3 mb-3 pt-4 pr-5 pl-5 bg-brand-primary" @click="reStart">Re-Start</b-button>
        </b-card>
    </div>
</template>
<script>
import ConfirmDischargedCoacheeModal from "./ConfirmDischargedCoacheeModal.vue";

export default {
    components: {
        ConfirmDischargedCoacheeModal
    },
    name: "DischargedCoacheeModal",
    methods: {
        reStart() {
            const content = this.$createElement(ConfirmDischargedCoacheeModal)
            const skin = this.$store.getters.skin;
            let opts = {
                title: "My Coaching Session",
                hideHeader: false,
                hideFooter: true,
                modalClass: "coach_modal theme-" + skin,
                headerClass: "coach_modal_header bg-brand-secondary d-flex justify-content-center",
                size: "lg",
                buttonSize: "sm",
                centered: true,
                contentClass: "coach_modal",
                id: 'confirm_discharged_coachee_modal'
            }
            this.$bvModal.msgBoxOk(content, opts);
        },
    }
};
</script>
<style lang="less">
#DischargedCoacheeModal {
    .card.discharged-card {
        margin: 0em -1em 0em -1em !important;
    }
    max-height: 85vh;
    overflow-y: auto;
    overflow-x: hidden;
}
</style>