<template>
  <div id="TherapyForm" class="p-5" ref="formTop" :class="{whiteBg: therapy_type}">
    <div class="content-container position-relative" v-if="!loading">
      <div class="back-button" :style="{top: top + 'px', width: 'fit-content'}">
        <div class="d-flex flex-column align-items-end">
          <div style="width: 200px;float: right; margin-right: 20px; text-align: center; font-size: 1.3rem; font-family: FilsonProBold, Arial, Helvetica, sans-serif">
            {{percentageComplete}}% complete
          </div>
          <button class="therapyBtn2" @click="handleBack" style="width: 200px;float: right; margin-right: 20px">Close</button>
        </div>
      </div>

      <div v-for="p in form.preamble">
        <h4>{{ p.title }}</h4>
        <div v-html="p.html"></div>
      </div>
      <hr>

      <div v-for="section in form.form_groups" class="formSection" v-if="extracted">
        <h4>{{section.header}}</h4>
        <div v-if="section.additional_text" v-html="section.additional_text"></div>
        <div v-for="q in section.questions">
          <TherapyTextQuestion :required="q.required" :label="q.label" :item="q.answer" @update="(data)=> q.answer = data" v-if="q.type === 'text'" :disabled="q.id === 'personal-email'"/>
          <TherapyDateOfBirth :required="q.required" :label="q.label" :item="q.answer" @update="(data)=> q.answer = data" v-if="q.type === 'dob'" :over18-validation="q.required && q.id === 'emergency-contact-dob'"/>
          <TherapyPhoneNo :required="q.required" :label="q.label" :item="q.answer" @update="(data)=> q.answer = data" v-if="q.type === 'phone'"/>
          <TherapyChoiceBox :required="q.required" :label="q.label" :item="q.answer" :options="q.options" @update="(data)=> q.answer = data" v-if="q.type === 'choice'"/>
          <TherapyTextBox :required="q.required" :label="q.label" :item="q.answer" @update="(data)=> q.answer = data" :rows="10" v-if="q.type === 'longText'"/>
          <div v-if="q.explanation" class="explanation">{{q.explanation}}</div>
        </div>
      </div>

      <div class="formSection" v-if="form.include_family_members">
        <h4 v-if="$store.getters.user.therapy.age_group === 'Adult'">Optional: </h4>
        <h4>Who else is in the family (Can add up to 5 rows) </h4>
        <div v-for="(member,index) in form.family_members" :key="'fm-'+genRandonString(5)">
          <FamilyMember  :item="member" @update="(data)=>member = data" @deleteMember="deleteMember(index)" :key="'family-'+index"/>
        </div>
        <div class="my-4 addFamilyMemberBox" v-if="form.family_members.length < 5" @click="addMember">
          <i class="fa-solid fa-circle-plus"></i> Add Another Family Member
        </div>
      </div>
      <button class="therapyBtn my-5" :disabled="buttonDisabled" @click="submitForm">
        <span v-if="formSubmitted" class="button-loading"></span>
        <span v-else-if="formSaved">Saved!</span>
        <span v-else>Submit Form</span>
      </button>
    </div>
  </div>
</template>

<script>

import TherapyTextQuestion from "./Questions/TherapyTextQuestion.vue";
import TherapyPhoneNo from "./Questions/TherapyPhoneNo.vue";
import TherapyChoiceBox from "./Questions/TherapyChoiceBox.vue";
import TherapyDateOfBirth from "./Questions/TherapyDateOfBirth.vue";
import TherapyTextBox from "./Questions/TherapyTextBox.vue";
import FamilyMember from "./Questions/FamilyMember.vue";
import forms from '../../assets/therapyForms.json'
import EventBus from "../../helpers/eventbus";

export default {
  name: "TherapyForm",
  props: {
    therapy_type: {
      default: null
    },
    age_group: {
      default: null
    },
    form_type: {
      default: null
    },
    service_name: {
      default: null
    }
  },
  components: {
    FamilyMember, TherapyTextBox, TherapyDateOfBirth, TherapyChoiceBox, TherapyPhoneNo, TherapyTextQuestion},
  data() {
    return {
      loading: false,
      forms: forms,
      form: {
      },
      extracted: false,
      form_exists_in_db: false,
      formSubmitted: false,
      formSaved: false,
      family_member_template: {
        name: '',
        dateOfBirth: {
          day: '',
          month: '',
          year: ''
        },
        address: '',
        relationship: '',
        occupation: '',
        parental_responsibility: null
      },
      top: 0
    }
  },
  beforeDestroy() {
    this.form.form_groups.forEach(form => {
      form.questions.forEach(q=> {
        q.answer = '';
      })
    });
    if(this.form.family_members) {
      this.form.family_members.forEach(member => {
        for (const key in member) {
          if(key === 'dateOfBirth') {
            member[key] = {
                  day: '',
                  month: '',
                  year: ''
            }
          } else {
            member[key] = ''
          }
        }
      })
    }
    this.extracted = false;
    window.removeEventListener('scroll', this.handleScroll, true);
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true);
    this.handleScroll();
    this.mountFormTemplate();
    this.getData();

  },
  methods: {
    handleScroll() {
      const app = window.document.getElementById('app');
      if(this.form_type) {
        if(app.scrollTop > 80) {
          this.top = -35 + app.scrollTop - 140;
        } else {
          this.top = -35;
        }
      } else {
        if(app.scrollTop > 80) {
          this.top = -35 + app.scrollTop - 85;
        } else {
          this.top = -35;
        }
      }
    },
    async handleBack() {
      const result = await this.showConfirmModal("Are you sure you want to leave this page?  Any unsaved changes will be lost.");
      if(result) EventBus.$emit('toggleIntakeForm');
    },
    mountFormTemplate(data = false) {
      if(this.$route.params.id === 'intake' || this.form_type === 'intake') {
        this.form = this.forms['intake_'+this.therapyType.area.toLowerCase()+'_'+this.therapyType.age_group.toLowerCase()];
      }
      if(data) {
        this.unpackageAnswers(data)
      } else {
        this.extracted = true;
        this.form.form_groups.forEach(group => {
          group.questions.forEach(q => {
            if(q.id === 'personal-name' && (q.answer === null || q.answer === '')) q.answer = this.$store.getters.user.user.first_name + " " + this.$store.getters.user.user.last_name
          })
        })
      }
      this.form.form_groups.forEach(group => {
        group.questions.forEach(q => {
          if(q.id === 'personal-email') q.answer = this.$store.getters.user.user.email;
        })
      })
    },
    async getData() {
      let params = {
        path: 'api/u/cmh/get-intake-form',
        form_name: this.formName
      }
      const res = await this.api(params)
      if(res.success) {
        this.form_exists_in_db = true;
        this.m('intake-form-review',{service: this.service_name})
        this.mountFormTemplate(JSON.parse(atob(res.data.content)));
        this.loading = false;
      } else {
        this.m('intake-form-start',{service: this.service_name})
        this.mountFormTemplate();
        this.loading = false;
      }
    },
    unpackageAnswers(data) {
      if(this.form.include_family_members) {
        this.form.family_members = data.family_members;
      }
      this.form.form_groups.forEach((form) => {
        form.questions.forEach((q) => {
          if(data.questions.hasOwnProperty(q.id)) {
            q.answer = data.questions[q.id]
          }
        })
      });
      this.extracted = true;
    },
    packageAnswers() {
      const questions = {};
      this.form.form_groups.forEach(form => {
        form.questions.forEach(q => {
          questions[q.id] = q.answer;
        })
      });
      const jsonString = JSON.stringify({
        family_members: this.form.family_members,
        questions: questions
      });
      return btoa(unescape(encodeURIComponent(jsonString)));
    },
    async submitForm() {
      const answers = this.packageAnswers();
      this.formSubmitted = true;
      const res = await this.api({
        path: this.form_exists_in_db ? 'api/u/cmh/update-intake-form':'api/u/cmh/save-intake-form',
        form_name: this.formName,
        content: answers
      });
      if(res.success) {
        this.m(this.percentageCompleteUnformatted === 1 ? 'intake-form-sucessful-submit' : 'intake-form-in-progress-submit',{service: this.service_name})
        this.formSaved = true;
        this.formSubmitted = false;
        EventBus.$emit('toggleIntakeForm');
      }
    },
    deleteMember(index) {
      this.$set(this.form.family_members, index, { ...this.form.family_members[index] });
      this.form.family_members.splice(index,1)
    },
    addMember() {
      const index = this.form.family_members.length;
      this.$set(this.form.family_members, index, { ...this.form.family_members[index] });
    }
  },
  watch: {
    formSaved(val) {
      if(val) {
        setTimeout(()=> {this.formSaved=false}, 5000);
      }
    }
  },
  computed: {
    percentageCompleteUnformatted() {
      let numberOfQuestions = 0;
      let numberOfAnswersGiven = 0;
      this.form.form_groups.forEach(group => {
        group.questions.forEach(q => {
          if(q.required) {
            numberOfQuestions++;
            if(q.type === 'dob') {
              const dob = q.answer.split('-');
              let ans = true;
              dob.forEach((item,i) => {
                if(item === '' || item === null) ans = false;
                if(i === 2 && item.length !== 4) ans = false;
              });
              if(q.id === 'emergency-contact-dob' && ans) {
                ans = this.isOver18(q.answer);
              }
              if(ans) numberOfAnswersGiven++;
            } else if(q.type === 'phone') {
              if(q.answer.countryCode && q.answer.phone) numberOfAnswersGiven++;
            } else if(q.answer !== null && q.answer !== '') {
              numberOfAnswersGiven++
            }
          }
        })
      });
      return numberOfAnswersGiven / numberOfQuestions;
    },
    percentageComplete() {
      return Number(this.percentageCompleteUnformatted * 100).toFixed(0);
    },
    formName() {
      if(this.$route.params.id === 'intake' || this.form_type === 'intake') {
        return 'intake_'+this.therapyType.area.toLowerCase()+'_'+this.therapyType.age_group.toLowerCase();
      }
    },
    therapyType() {
      if(this.therapy_type === null || this.age_group === null) {
        return this.$store.getters.user.therapy;
      }
      return {
        area: this.therapy_type,
        age_group: this.age_group
      }
    },
    buttonDisabled() {

    }
  }
}
</script>

<style lang="less">
#TherapyForm {
  background-color: #F3F5F8;
  &.whiteBg {
    background-color: transparent;
  }
  width: 100%;
  position: relative;
  .explanation {
    font-size: 1.3rem;
  }
  .back-button {
    position: absolute;
    right: 0;
    transition: top 0.3s linear;
  }
  @media only screen and (max-width: 992px) {
    .back-button {
      right: 20px;
    }
  }
  @media only screen and (max-width: 767px) {
    .back-button {
      display: none !important;
    }
  }
  .form-group {
    &.required {
      label:not(.custom-control-label) {
        &::after {
          content: '*';
          margin-left: 4px;
          color: darkred;
          font-size: 1.2rem;
        }
      }
    }
  }
  p {
    max-width: 100% !important;
    &.bold {
      font-family: FilsonProBold, Arial, Helvetica, sans-serif;
    }
  }
  input {
    background-color: white;
  }
}
</style>