<template>
  <div id="CommunityPost">
    <div class="w-100" style="background-color: #eee">
      <div class="content-container pt-5 back-button-container">
        <button class="btn-clear" @click="goBack()">
          <i class="fas fa-5x fa-chevron-left header-back"></i>
        </button>
      </div>
    </div>
    <section class="community-post-container h-100">
      <div class="content-container row">
        <div id="post-container" class="col-sm-10 mx-auto">
          <div class="posts-container" v-if="!isLoadingPost && !isLoadingUser">
            <Post v-bind:post="post" v-bind:user="user"></Post>
          </div>
          <div class="row pt-5" v-else>
            <b-spinner class="brand-primary mx-auto mt-5"></b-spinner>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import config from "../config/constants";
import Post from "../components/Community/Post.vue";

export default {
  name: "CommunityPost",
  components: {
    Post,
  },
  data() {
    return {
      post: null,
      user: null,
      isLoadingPost: true,
      isLoadingUser: true,
    };
  },
  mounted() {
    this.getUserProfile();
    this.getPost();
  },
  methods: {
    async getUserProfile() {
      const payload = {
        user_id: this.$store.getters.user_id,
      };
      let res = await axios.post(
        config.api_env + "/application/api/u/community/get-user-profile",
        payload,
        config.options
      );
      if (res.data.success) {
        this.user = res.data.profile;
        console.log(this.user);
        this.isLoadingUser = false;
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login").catch((err) => {});
      }
    },
    async getPost() {
      const payload = {
        user_id: this.$store.getters.user_id,
        post_id: this.$route.params.id,
      };
      let res = await axios.post(
        config.api_env + "/application/api/u/community/get-posts",
        payload,
        config.options
      );
      if (res.data.success) {
        this.post = res.data.posts[0];
        console.log(this.post);
        this.isLoadingPost = false;
      } else if (res.data.err && res.data.err.includes("3994")) {
        this.$store.dispatch("logout");
        this.$router.push("/login").catch((err) => {});
      }
    },
    removePost(id, index) {
      this.posts.splice(index, 1);
    },

    goBack() {
      this.$router.push("/community");
    },
  },
  computed: {
    componentText() {
      return this.getText.loggedIn;
    },
  },
};
</script>
<style lang="less">
#CommunityPost {
  .community-post-container {
    background-color: #eee;
    padding-top: 10px;
    padding-bottom: 60px;
  }
  .spinner-container {
    height: 200px;
    margin-bottom: 20px;
    .posts-spinner {
      height: 50px;
      width: 50px;
    }
  }
  .btn {
    padding-top: 12.5px !important;
    padding-bottom: 12.5px !important;
  }
  .btn-clear {
    border: none;
    background-color: transparent;
  }
  .btn-clear:focus {
    border: none;
    outline: none !important;
  }

  .fade-in {
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s; /* Firefox < 16 */
    -ms-animation: fadein 1s; /* Internet Explorer */
    -o-animation: fadein 1s; /* Opera < 12.1 */
    animation: fadein 1s;
  }
  .fade-out {
    -webkit-animation: fadeout 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeout 1s; /* Firefox < 16 */
    -ms-animation: fadeout 1s; /* Internet Explorer */
    -o-animation: fadeout 1s; /* Opera < 12.1 */
    animation: fadeout 1s;
  }
  .back-button-container {
    .header-back {
      color: #707070;
    }
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Firefox < 16 */
  @-moz-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Internet Explorer */
  @-ms-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadeout {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  /* Firefox < 16 */
  @-moz-keyframes fadeout {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadeout {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  /* Internet Explorer */
  @-ms-keyframes fadeout {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
}
</style>
