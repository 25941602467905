<template>
  <div id="EapBanner">
    <div class="innerBannerContainer row mt-5">
      <div class="col-5 py-4 textContainer">
        <h2 class="ml-5">{{ componentText.eap.title }}</h2>
        <p class="ml-5">{{ componentText.eap.text }}</p>
      </div>
      <div class="col-7 contactSection" >
          <div class="btnContainer">
              <b-btn variant="cta" class="btn callBtn w-100" @click="makeCall">
                  <div class="btnText">
                      <i class="fa-solid fa-phone fa-xl mr-2"></i>
                      {{ componentText.eap.callNow }}</div>
              </b-btn>
          </div>
          <div class="btnContainer">
            <b-btn variant="cta-outline" class="btn callBtn w-100" @click="callback">
                <div></div>
                <div class="btnText"><i class="fa-solid fa-phone-volume fa-xl mr-2"></i>{{ componentText.eap.callBack }}</div>
            </b-btn>
          </div>
          <div class="btnContainer" v-if="live_chat">
              <b-btn variant="cta" class="btn callBtn w-100" @click="chat">
                  <div class="btnText"><i class="fa-solid fa-comment-dots fa-xl mr-2"></i>{{ componentText.eap.chat }}</div>
              </b-btn>
          </div>
      </div>

    </div>
  </div>
</template>

<script>
import EapCallBackModal from "@/components/Home/New/EapCallBackModal.vue";
import axios from "axios";
import config from "@/config/constants";

export default {
  name: "EapBanner",
  data() {
    return {
        landg_eap: false,
        contentLoading: true,
        live_chat: false,
        services_offered: []
    }
  },
  components: {
    EapCallBackModal
  },
  mounted() {
      const myStyle = document.createElement('style');
      myStyle.innerHTML = `
        #launcher { visibility: hidden}
      `;
      document.head.appendChild(myStyle);
      this.myStyle = myStyle;
      this.getEapContent();
      window.addEventListener("load", function(){
          if(window.$zopim) {
              window.$zopim.livechat.setLanguage(vm.$store.state.lang)
              window.$zopim.livechat.setGreetings({
                  'online': vm.componentText.zendeskTitle,
              })
              window.$zopim.livechat.window.setTitle(vm.componentText.zendeskTitle)
              window.$zopim.livechat.prechatForm.setGreetings(vm.componentText.zendeskIntro)
              window.$zopim.livechat.setOnStatus(()=> {console.log('status change')});
          }
      });
      this.enableZendeskWidget();
      if(this.$route.query.callback) {
          this.callbackSelected = true;
      }
  },
  destroyed(){
    //remove zendesk
    if(window.$zopim) {
        window.$zopim.livechat.hideAll()
    }
    if(this.myStyle) {
        document.head.removeChild(this.myStyle);
        this.myStyle = null;
    }
},
  methods: {
    chat() {
        window.$zopim.livechat.window.show();
    },
    async getEapContent(){
        const payload = {
            user_id: this.$store.getters.user_id
        }
        let res= await axios.post(config.api_env + '/application/api/u/customisations/get-eap-content', payload, config.options)
        if(res.data.success){
            this.landg_eap = res.data.landg_eap;
            this.services_offered = res.data.eap_customisation.services_offered.items;
            this.contentLoading = false;
        }
        if(res.data.eap_customisation.defaults.live_chat){
            this.live_chat = true;
        }
    },
    enableZendeskWidget() {
        let key = "231b7a97-95a0-4837-86a2-5be70b3b9db1";
        // the if statement below changes the ZenDesk key for L&G users
        if(this.landg_eap)
        {
            key = "a04aaa15-6e9e-4947-a98d-e5560870d7a9";
        }
        window.$zopim ||
        (function(d, s) {
            var z = (window.$zopim = function(c) {
                    z._.push(c);
                }),
                $ = (z.s = d.createElement(s)),
                e = d.getElementsByTagName(s)[0];
            z.set = function(o) {
                z.set._.push(o);
            };
            z._ = [];
            z.set._ = [];
            $.async = !0;
            $.setAttribute("charset", "utf-8");
            $.setAttribute("id", "ze-snippet");
            $.src = "https://static.zdassets.com/ekr/snippet.js?key=" + key;
            z.t = +new Date();
            $.type = "text/javascript";
            e.parentNode.insertBefore($, e);
        })(document, "script");
    },
    makeCall() {
      // window.open('tel:900300400')
      this.$router.push('/eap?makecall=true')
    },
    callback() {
          const skin = this.$store.getters.skin;
          const content = this.$createElement(EapCallBackModal, {
              props: {
                  title: 'Request a Callback',
                  services_offered: this.services_offered
              }
          });
          let opts = {
              hideHeader: false,
              hideFooter: true,
              modalClass: "eap-callback theme-"+skin,
              headerClass: "eap-callback d-none",
              footerClass: "d-none",
              size: "lg",
              buttonSize: "sm",
              centered: true,
              contentClass: "eap-callback theme-" + skin,
              id : 'eap-callback',
          }
          return this.$bvModal.msgBoxOk(content, opts);
      },
  },
  computed: {
    componentText() {
      return this.getText.dfd;
    },
  }
}
</script>

<style lang="less">

#EapBanner {
  margin-bottom: 30px;
  .innerBannerContainer {
    background-color: white;
    box-shadow: 2px 3px 2px 1px #aaaaaa;
    border-top: 1px #f5f3f3 solid;
    border-radius: 4px;
    min-height: 170px;
    .textContainer {
      h2 {
        font-family: "FilsonProBold", sans-serif;
        font-size: 2.75em;
        text-align: left;
        margin-top: 10px;
      }
      p {
        width: 85%;
        font-family: "DMSans", sans-serif;
        font-size: 1.5em;
      }
    }
    .contactSection {
      background: url('/img/home/new/eapBannerBg.png');
      background-size: cover;
      border-top-left-radius: 90px;
      border-bottom-left-radius: 5px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }
    .btnContainer {
      padding: 5px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      div {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .btnText {
        min-height: 30px;
        font-size: 1.35rem;
      }
    }
    .callBtn {
      box-shadow: 1px 3px 2px #aaaaaa;
      padding: 5px !important;
    }
    .bubbleBtn {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      border: 1px #f5f3f3 solid;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 1px 3px 2px #aaaaaa;
      background-color: white;
    }
    @media only screen and (max-width: 992px) {
      .contactSection {
        grid-template-columns: none;
        grid-template-rows: 1fr 1fr 1fr;
      }
      .callBtn {
        max-width: 200px !important;
      }
    }
    @media only screen and (min-width: 1400px) {

      .btn {
        font-size: 1.5em !important;
      }
    }
    @media only screen and (max-width: 767px) {
        width: 95% !important;
        margin: auto !important;
      .iconContainer {
        justify-content: space-evenly !important;
        margin-bottom: 10px !important;
      }
      .contactSection {
        background: none !important;
        padding: 8px !important;
      }
    }
  }

}

</style>