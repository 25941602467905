<template>
  <div id="Challenges" :style="{marginTop: topNavHeight + 'px'}" :class="{showChallenges: showChallenges}">
    <div class="innerBox">
      <div class="challengeNav d-flex justify-content-between align-items-center">
        <div>
          <i class="fa-solid fa-xmark closeBtn" @click="closeChallenges"></i>
        </div>
        <div>
          <div style="position: relative" id="toggleMetric">
            <i class="fa-solid fa-gear" @click="toggleUnit" :class="{change: changeUnit}" style="font-size: 1.5rem; color: white; cursor: pointer"></i>
          </div>
          <b-tooltip target="toggleMetric" triggers="hover" placement="top" custom-class="toggleTooltip">
            Change to {{ unit === 'Kilometers' ? 'Miles' : 'Kilometers' }}
          </b-tooltip>
        </div>
      </div>
      <div class="intro mb-3">
        <ChallengeYourselfDashboard v-if="createChallengeMode" @back="createChallengeMode = false" :activity_types="activity_types" @created="challengeHasBeenCreated"/>
        <div v-else>
          <ChallengeYourselfCard v-if="!createChallengeMode" @createNewChallenge="createChallengeMode = true"/>
          <div  v-for="challenge in myChallenges" ref="challengesList" id="challengesList">
            <div class="challengeTitle">{{getChallengeCardTitle(challenge.type)}}</div>
            <ChallengeProgressCard :challenge="challenge" @deleteChallenge="deleteChallenge"/>
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <b-pagination
                v-if="total_challenges && total_challenges > challenges_per_page"
                v-model="challenge_page"
                :total-rows="total_challenges"
                :per-page="challenges_per_page"
                size="lg"
                @change="changeChallengePage"
            ></b-pagination>
          </div>

        </div>
      </div>
      <div ref="activitiesBar"></div>
      <YourActivities
          @changePage="changePage"
          :activities="activities" :device-connected="deviceConnected"
          :syncing="syncing" :total_activities="total_activities" :activities_per_page="activities_per_page"
      />
    </div>
  </div>
</template>

<script>

import ChallengeYourselfCard from "@/components/Challenges/ChallengeYourselfCard.vue";
import YourActivities from "@/components/Challenges/YourActivities.vue";
import ChallengeYourselfDashboard from "@/components/Challenges/YourActivitiesViews/ChallengeYourselfDashboard.vue";
import ChallengeProgressCard from "@/components/Challenges/ChallengeProgressCard.vue";
import Eventbus from "@/helpers/eventbus";
export default {
  name: "Challenges",
  props: ['topNavHeight','showChallenges'],
  components: {
    ChallengeYourselfCard,
    YourActivities,
    ChallengeYourselfDashboard,
    ChallengeProgressCard
  },
  data() {
    return {
      changeUnit: false,
      createChallengeMode: false,
      myChallenges: [],
      deviceConnected: false,
      syncing: false,
      activities: [],
      current_page: 1,
      total_activities: 0,
      activities_per_page: 10,
      activity_types: [],
      total_challenges: 0,
      challenges_per_page: 10,
      total_challenge_pages: 0,
      challenge_page: 1
    }
  },
  mounted() {
    Eventbus.$on('getChallengesData',this.getActivities);
    Eventbus.$on('syncChallengesData',this.syncChallengesData);
    Eventbus.$on('deviceConnected', this.getActivities);
    Eventbus.$on('deviceDisconnected', this.disconnectDevice);
    this.getUserChallenges();
    this.getActivityTypes();
    this.getActivities();
    this.isOauthUser();
    if(this.$route.path === '/challenges') {
      this.deviceConnected = true;
      this.syncChallengesData();
    }
  },
  methods: {
    async isOauthUser() {
      let res = await this.api({
        path: "api/u/oauth/google"
      })
      if(res.success) {
        if(res.data.created_on) this.deviceConnected = true;
      }
    },
    toggleUnit() {
      this.$store.dispatch('toggleDistanceUnit');
      this.changeUnit = true;
      setTimeout(()=> { this.changeUnit = false },1000);
    },
    async deleteChallenge(id) {
      let res = await this.api({
        path: "api/u/challenges/" + id + "/delete",
        id: id
      });
      if(res.success) {
        this.myChallenges = [];
        await this.getUserChallenges()
      }
    },
    changePage(page) {
      this.current_page = page;
    },
    challengeHasBeenCreated() {
      this.createChallengeMode = false;
      this.getUserChallenges();
    },
    // async getActivityTypes() {
    //   let res = await this.api({
    //     path: "api/u/activity-types",
    //   });
    //   if (res.success) this.activity_types = res.data;
    // },
    getActivityTypes() {
      this.activity_types = [
          {'label':'Cycling','value':'cycling'},
          {'label':'Running','value':'running'},
          {'label':'Swimming','value':'swimming'},
          {'label':'Walking','value':'walking'},
        ];
    },
    disconnectDevice() {
      this.deviceConnected = false
    },
    async syncChallengesData() {
      this.syncing = true;
      try {
        let res = await this.api({
          path: "api/u/activities/sync",
          page: this.current_page
        });
        if(res.success) {
          this.deviceConnected = true;
          await this.getActivities();
        } else {
          if(res.message && res.message.includes("Authorization required")) {
            if (res.auth_url) {
              // window.open(res.auth_url, "_blank");
              window.location.href = res.auth_url;
            }
          }
          this.syncing = false;
        }
      } catch (e) {
        this.syncing = false;
      }
    },
    async getActivities() {
      this.syncing = true;
      this.activities = [];
      try {
        let res = await this.api({
          path: "api/u/activities",
          page: this.current_page
        });
        if (res.success) {
          this.activities = res.data;
          this.total_activities = res.meta.pagination.total;
          this.activities_per_page = res.meta.pagination.per_page;
          this.syncing = false;
          this.scrollToRef('activitiesBar')
        } else {
          this.deviceConnected = false;
          this.syncing = false;
        }
      } catch (e) {
        this.syncing = false;
      }
    },
    async changeChallengePage(page) {
      await this.getUserChallenges(page, true)
    },
    async getUserChallenges(pageNumber = 1, scrollToTop = false) {
      try {
        let res = await this.api({
          path: "api/u/challenges",
          page: pageNumber
        });
        if (res.success) {
          this.myChallenges = res.data;
          this.challenges_per_page = res.meta.pagination.per_page;
          this.total_challenges = res.meta.pagination.total;
          this.total_challenge_pages = res.meta.pagination.total_pages;
          this.challenge_page = res.meta.pagination.current_page;
          if(scrollToTop) {
            const el = document.getElementById('challengesList')
            el.scrollIntoView({behavior: "smooth"})
          }
        } else {

        }
      } catch (e) {

      }
    },
    getChallengeCardTitle(type) {
      switch (type) {
        case 'steps': return 'Your Step Challenge';
        case 'activity': return 'Your Activity Challenge';
        case 'distance': return 'Your Distance Activity'
      }
    },
    closeChallenges() {
      this.$emit('closeChallenges')
    }
  },
  computed: {
    unit() {
      return this.$store.getters.distanceUnit;
    }
  },
  watch: {
    current_page() {
      this.getActivities();
    }
  }
}
</script>

<style lang="less">
#Challenges {

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.5);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(90deg, #ED3A75 0%, #6A356A 100%);
    -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.5);
    border-radius: 10px;
  }
  height: 0;
  width: 0;
  position: absolute;
  top: 0;
  right: 0;
  background-color: black;
  transition: width 0.25s ease-in;
  z-index: 200;
  overflow-y: scroll;
  &.showChallenges {
    width: 380px;
    height: 100%;
    min-height: 100%;
    .innerBox {
      opacity: 1 !important;
      transition: opacity 0.25s linear;
      transition-delay: 0.3s;
    }
  }
  * {
    max-width: 100%;
  }
  .innerBox {
    opacity: 0;
    width: 100%;
    height: fit-content;
    .challengeNav {
      padding: 15px;
      justify-content: start;
      align-items: center;
      display: flex;
      .closeBtn {
        cursor: pointer;
        color: white;
        font-size: 18px;
      }
    }
    .intro {
      padding: 5px 10px;
    }
  }
  .pagination {
    background-color: black;
    li {
      span, button {
        background-color: black;
        color: white;
      }
    }
    .active {
      button {
        color: white;
        background: linear-gradient(90deg, #6A356A 0%, #ED3A75 100%);
        border-color: white;
      }
    }
    .page-item {
      &.disabled {
        span {
          color: grey !important;
        }
      }
    }
  }
  .challengesButton {
    width: 100%;
    font-size: 1.4rem;
    padding: 6px 0;
    text-align: center;
    border-radius: 10px;
    background: linear-gradient(90deg, #ED3A75 0%, #6A356A 100%);
    cursor: pointer;
    &:hover {
      background: linear-gradient(90deg, #6A356A 0%, #ED3A75 100%);
    }
    &.invalid {
      background: grey !important;
    }
  }
  .challengeTitle {
    color: white;
    font-size: 1.5rem;
    text-align: center;
  }
  .change {
    transform: rotate(360deg);
    transition: transform 1s;
  }
  .challengesBack {
    position: absolute;
    top: 0;
    right: 5px;
    cursor: pointer;
  }

}
</style>