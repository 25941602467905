<template>
  <div id="MyProgress" class="container">
<!--    <section>-->
<!--      <div class="section-header">Stay on Track</div>-->
<!--      <div v-if="showInProgress">You currently don't have have any courses in progress.</div>-->
<!--      <div class="courseView">-->

<!--      </div>-->
<!--      <div class="beCalmView">-->

<!--      </div>-->
<!--    </section>-->
    <section>
      <div class="section-header pt-3">Jump back in</div>
      <div class="section-sub-header" v-if="studio_events.length">Studio Events and Classes</div>
      <div class="courseDisplay" v-if="studio_events.length">
        <CustomProgressSlider :items="studioEventsData"/>
      </div>
      <div class="section-sub-header">My Courses</div>
      <div v-if="!showInProgress" class="sectionEmpty">You do not have any courses in progress.</div>
      <div v-else class="courseDisplay">
        <div v-if="showInProgress">
          <CustomProgressSlider :items="inProgress"/>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import config from "@/config/constants";
import {mapGetters} from "vuex";
import MyProgressCourseCard from "@/components/MyProgressCourseCard.vue";
import CustomProgressSlider from "@/components/Home/New/CustomProgressSlider.vue";

export default {
  name: "MyProgress",
  components: {MyProgressCourseCard, CustomProgressSlider},
  data() {
    return {
      loading: true,
      courses: [],
      be_calm: [],
      studio_events: [],
      studioItemsCalled: false,
      wbCalled: false,
      bcCalled: false
    }
  },
  mounted() {
    this.getItemsFromNav(this.nav);
    this.getItemsFromNav(this.sideNav);
  },
  methods: {
    getItemsFromNav(nav) {
      nav.forEach(navItem => {
        if(navItem.hasOwnProperty('sub_items')) {
          navItem.sub_items.forEach(subItem => {
            this.callEndPointFromSlug(subItem.item_slug);
          })
        } else {
          this.callEndPointFromSlug(navItem.item_slug)
        }
      })
    },
    callEndPointFromSlug(slug) {
      switch(slug) {
        case "beCalm":
          this.getBeCalmCourses();
          break;
        case "wellbeing":
          this.getWellbeingCourses();
          break;
        case "digitalGym":
          this.getStudioEvents();
          break;
        case "wellbeingSeries":
          this.getStudioEvents();
          break;
      }
    },
    async getWellbeingCourses() {
      if(this.wbCalled) return;
      this.wbCalled = true;
      const payload = {
        user_id: this.$store.getters.user_id,
        limit: null,
        channel: "surge",
        override_enrollment_requirement: true
      };
      let res = await axios.post(
          config.api_env + "/application/api-hc/list-surge-courses",
          payload,
          config.options
      );
      if(res.data.courses) {
        this.courses = res.data.courses.map(course => {
          return {...course, path: '/wellbeing/' + course.course_id + '?back=my-progress'}
        });
      }
    },
    async getBeCalmCourses(){
      if(this.bcCalled) return;
      this.bcCalled = true;
      const payload = {
        user_id: this.$store.getters.user_id,
        channel: "be_calm",
        limit: 0,
        override_enrollment_requirement: true
      }
      let res = await axios.post(config.api_env + '/application/api/u/resources/get-be-calm-courses', payload, config.options)
      if(res.data.success){
        this.be_calm = res.data.courses.map(course => {
          return {...course, path: '/be-calm/' + course.course_id + '?back=my-progress'}
        });
      }
    },
    async getStudioEvents() {
      if(this.studioItemsCalled) return;
      this.studioItemsCalled = true;
      const ids = this.studioEventProgressData.map(i => i.id)
      let res = await this.api({
        path: "api/u/studio/get-studio-events-by-ids",
        event_ids: ids
      });
      if(res.success) this.studio_events = res.events;
    }
  },
  computed: {
    ...mapGetters(["nav","sideNav","user"]),
    courseProgress() {
      return this.user.user.course_progress;
    },
    inProgress() {
      return this.inProgressCourses.concat(this.inProgressBeCalm)
    },
    inProgressCourses() {
      return this.courses.filter(course => course.course_progress > 0 && course.course_progress < 100)
    },
    completedCourses() {
      return this.courses.filter(course => course.course_progress === 100)
    },
    inProgressBeCalm() {
      return this.be_calm.filter(course => course.course_progress > 0 && course.course_progress < 100)

    },
    completedBeCalm() {
      return this.be_calm.filter(course => course.course_progress === 100)
    },
    showInProgress() {
      return this.inProgressCourses.length + this.inProgressBeCalm.length > 0;
    },
    studioEventProgressData() {
      if(!this.courseProgress) return null;
      return  this.courseProgress.filter(item => item.channel === 'dg' || item.channel === 'ds')
    },
    studioEventsData() {
      if(!this.studio_events.length || !this.studioEventProgressData) return null;
      return this.studioEventProgressData.map(item => {
        const i = this.studio_events.findIndex(a => a.id === item.id);
        const path = item.channel === 'dg' ?
            '/digital-gym/' + item.id : '/wellbeing-studio/' + item.id;
        const progress = Math.round(item.percent * 100,0);
        return {
          ...item,
          course_image_url: this.studio_events[i].thumbnail,
          embed_code: this.studio_events[i].embed_code,
          course_title: this.studio_events[i].title,
          path: path,
          course_progress: progress
        }
      });
    },
    showCompleted() {
      return this.completedCourses.length + this.completedBeCalm.length > 0;

    }
  }
}
</script>

<style scoped lang="less">
#MyProgress {
  height: 100%;
  width: 100%;
  background: white;
  &.container {
    max-width: 1600px;
  }
  section {
    margin-top: 10px;
    .section-header {
      font-size: 2.5rem;
      font-family: FilsonProBold, Arial, Helvetica, sans-serif;
    }
    .section-sub-header {
      font-size: 1.8rem;
      font-family: FilsonProBold, Arial, Helvetica, sans-serif;
      padding-left: 10px;
      margin-top: 10px;
    }
    .sectionEmpty {
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: 1.9rem;
    }
  }
  .courseDisplay {
    display: flex;
    flex-direction: column;
    .sub-header {
      font-size: 1.75rem;
      margin-top: 10px;
    }
    .courseHolder {
      width: 100%;
    }
  }

}
</style>