<template>
  <div>
    <div v-if="shouldShowMore">
      <div class="d-inline" v-html="text"></div>
      <a class="brand-primary" @click="showLess()">Show Less</a>
    </div>
    <div v-else>
      <div class="d-inline" v-html="lessText"></div>
      <a class="brand-primary" @click="showMore()" v-if="!textSmallerThanLimit"
        >Show More</a
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "PodcastPlayer",

  props: ["text", "limit"],

  data() {
    return {
      shouldShowMore: false,
    };
  },
  methods: {
    showMore() {
      this.shouldShowMore = true;
    },
    showLess() {
      this.shouldShowMore = false;
    },
  },
  computed: {
    textSmallerThanLimit() {
      return parseInt(this.text.length) <= parseInt(this.limit);
    },
    lessText() {
      if (this.textSmallerThanLimit) {
        return this.text.slice(0, this.limit);
      } else {
        return this.text.slice(0, this.limit) + "...";
      }
    },
  },
};
</script>
<style scoped lang="less">
</style>
