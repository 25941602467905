<template>
  <b-form-group
      :id="id+'-group'"
      :label="label"
      :label-for="id"
      class="phoneGroup"
      :class="{required: required}"
  >
    <b-form-select
        class="form-control"
        type="text"
        :id="id"
        v-model="selectedCountryCode"
        :class="{incomplete: required && !selectedCountryCode}"
    >
      <b-form-select-option v-for="cc in countryCodes" :value="cc.dial_code">{{cc.name}} - {{cc.dial_code}}</b-form-select-option>
    </b-form-select>
    <b-form-input
        type="text"
        id="phone"
        v-model="phoneNumber"
        @keydown="handleInput"
        :class="{incomplete: required && !phoneNumber}"
    />
  </b-form-group>
</template>
<script>

import country_codes from '../../../assets/country_codes_tel.json'
export default {
  name: "TherapyPhoneNo",
  props: ["label", "required","item"],
  data() {
    let id = this.genRandonString(10);
    return {
      id: id,
      selectedCountryCode: '',
      phoneNumber: '',
      countryCodes: [],
    }
  },
  mounted() {
    this.countryCodes = country_codes;
  },
  computed: {
    answer() {
      return {countryCode: this.selectedCountryCode, phone: this.phoneNumber};
    }
  },
  methods: {
    handleInput(evt) {
      if(isNaN(evt.key) && evt.key !== 'Backspace' && evt.key !== 'Tab') {
        evt.preventDefault();
      }
    },
    extractNumber() {
      this.selectedCountryCode = this.item.countryCode;
      this.phoneNumber = this.item.phone;
    }
  },
  watch: {
      item(val) {
        this.extractNumber();
      },
      answer(val) {
        this.$emit('update',val)
      }
    }
  }
</script>
<style lang="less">
.phoneGroup {
    div {
      display: flex;
      select {
        width: 150px;
        margin-right: 10px;
      }
      input {
        width: 340px;
      }
    }
}
</style>