<template>
  <div id="Notification" @click="$router.push(notification.event.actionUrl.href)" :class="{new: notification.readAt === null}" class="mx-auto p-3">
    <div class="title">
      {{title}}
    </div>
    <div class="date">{{dateAndTime}}</div>
    <div class="body">{{body}}</div>
  </div>
</template>

<script>
export default {
  name: "Notification",
  props: ['notification'],
  data() {
    return {

    }
  },
  computed: {
    iconClass() {
      switch(this.type) {
        case "digital_gym": return "fa-solid fa-calendar-check";
        case "wellbeing_studio": return "fa-solid fa-calendar-check";
        case "coaching_booking": return "fa-solid fa-calendar-check";
        case "coaching_message": return "fa-solid fa-comment";
        case "digital_clinics": return "fa-solid fa-calendar-check";
      }
    },
    type() {
      if(this.notification.event.hasOwnProperty("digital_gym")) return "digital_gym";
      if(this.notification.event.hasOwnProperty('wellbeing_studio')) return "wellbeing_studio";
      if(this.notification.event.hasOwnProperty('coaching_booking')) return "coaching_booking";
      if(this.notification.event.hasOwnProperty('unread_message_from_coaching')) return "coaching_message";
      if(this.notification.event.hasOwnProperty('digital_clinics')) return "digital_clinics";
    },
    eventImage() {
      if (this.type == 'digital_gym') return this.notification.event.digital_gym.thumbnail;
      if (this.type == 'wellbeing_studio') return this.notification.event.wellbeing_studio.thumbnail;
    },
    body() {
      if(this.type === 'coaching_message') return "Message from " + this.notification.event.unread_message_from_coaching.coach_identity;
      return this.notification.body;
    },
    title() {
      if(this.type === 'coaching_booking') return "Coaching Appointment";
      if(this.type === 'coaching_message') return "Coaching Message";
      return this.notification.event[this.type].title;
    },
    dateAndTime() {
      let startDate = this.notification.event.startDateTime;
      return this.getDateWithoutYear(startDate) +' '+ this.getHoursMinutes(startDate);
    }
  }

}
</script>

<style lang="less">
#Notification {
  width: 95%;
  border: 1px solid #D7D7D7;
  border-radius: 10px;
  margin-top: 10px;
  background: #3A4A61;
  cursor: pointer;
  padding: 4px;
  .date {
    color: #adabab;
    font-size: 1.3rem;
  }
  .title {
    font-family: FilsonProBold, Arial, Helvetica, sans-serif;
    color: white;
    margin-bottom: 5px;
    font-size: 1.5rem;
  }
  .body {
    color: white;
    font-size: 1.4rem;
  }
  .iconHolder {
    i {
      color: #9F9E93 !important;
    }
  }
}
</style>