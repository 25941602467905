<template>
    <div id="settings" class="container-fluid px-0">
        <div class="main no-side-bar">
            <div v-if="$store.state.user.authenticated">
                <TopNav></TopNav>
                <MobileNav></MobileNav>
            </div>
            <div class="page-header bg-brand-secondary">
                <div class="content-container container">
                    <div class="page-title col-12">
                        <h2>Datenschutzrichtlinie</h2>
                    </div>
                </div>
            </div>
            <div class="content-wrap">
                <div class="content-container row py-5">
                    <div class="content-card p-5">
                        <div class="content-inner p-md-5 m-md-5 p-0 m-0">
                            <h4>
                                1 In aller Kürze
                            </h4>
                            <p>
                                Die Sanus X-Plattform (die "Plattform") bietet Ihnen Zugang zu einer
                                Wellbeing- und Gesundheitsplattform. Die Nutzung wird Ihnen durch Ihren
                                Arbeitgeber ermöglicht, um einen gesunden Lebensstil zu fördern. Über eine
                                Teilnahme entscheiden Sie stets freiwillig. In dieser Datenschutzrichtlinie
                                erklären wir Ihnen, wie wir Ihre personenbezogenen Daten im Zusammenhang
                                mit der Bereitstellung der Plattform verarbeiten.
                            </p>
                            <p>
                                Der hohe Stellenwert, den der Schutz Ihrer Privatsphäre einnimmt, ist uns
                                bewusst. Besonders wichtig ist uns der Schutz Ihrer gesundheitsbezogenen
                                Informationen, die für manche Verarbeitungszwecke benötigt werden. Wir
                                unternehmen alle erforderlichen Schritte, um den hohen Anforderungen des
                                jeweils geltenden europäischen und österreichischen Datenschutzrechts
                                gerecht zu werden und Ihre Daten möglichst sicher zu verwalten.
                            </p>
                            <h4>
                                2 Verantwortlicher, Datenschutzbeauftragter und Kontakt
                            </h4>
                            <p>
                                Verantwortlicher im Sinne der Datenschutz-Grundverordnung ("DSGVO") für die
                                unter Punkt 3 näher beschriebenen Verarbeitungszwecke ist die
                            </p>
                            <h4 stlye="text-align: center">
                                Spectrum Wellness Limited t/a Spectrum.Life
                            </h4>
                            <p stlye="text-align: center">
                                95 Merrion Square, Dublin 2
                            </p>
                            <p stlye="text-align: center">
                                D02KT67, Ireland.
                            </p>
                            <p stlye="text-align: center">
                                ("Spectrum.Life")
                            </p>
                            <p>
                                Im Folgenden auch als "wir" bezeichnet.
                            </p>
                            <p>
                                Wenn Sie Fragen oder Bedenken zum Datenschutz haben oder Ihre Rechte in
                                Bezug auf Ihre persönlichen Daten wahrnehmen möchten, wenden Sie sich bitte
                                an unseren Datenschutzbeauftragten unter josephkeown@spectrum.life.
                            </p>
                            <p>
                                Für sonstige Anfragen zur Plattform, wenden Sie sich bitte an:
                                support@spectrum.life.
                            </p>
                            <h4>
                                3 Wie wir Ihre Daten verwenden
                            </h4>
                            <h4>
                                3.1 Ihre Daten werden zu folgenden Zwecken verarbeitet
                            </h4>
                            <p>
                                Im Folgenden wird erklärt, wie wir Ihre personenbezogenen Daten verwenden,
                                wenn Sie:
                            </p>
                            <p>
                                - sich für die Plattform registrieren;
                            </p>
                            <p>
                                - auf online abrufbare Dienste zugreifen;
                            </p>
                            <p>
                                - Dienste auf der Plattform buchen.
                            </p>
                            <p>
                                Ihre personenbezogenen Daten werden zusätzlich für Analysezwecke
                                verarbeitet, um die Services zu verbessern (siehe dazu 3.1.4).
                            </p>
                            <h4>
                                3.1.1 Registrierung und Nutzung eines persönlichen Kontos
                            </h4>
                            <p>
                                Um Zugang zu nicht öffentlichen Inhalten auf der Plattform zu erhalten,
                                müssen Sie zunächst ein Konto einrichten, indem Sie sich auf der Plattform
                                registrieren.
                            </p>
                            <p>
                                Für diese Zwecke werden folgende Daten verwendet:
                            </p>
                            <p>
                                Vorname, Nachname, Email-Adresse, Organisationscode (=mit diesem sind Sie
                                Ihrem Unternehmen zugeordnet), Passwörter. Diese Daten werden im Folgenden
                                gemeinsam auch als "Ihre Profildaten" bezeichnet.
                            </p>
                            <p>
                                Rechtsgrundlage für die Verarbeitung ist:
                            </p>
                            <p>
                                Wenn Sie ein persönliches Konto anlegen, erhalten Sie ein eingeschränktes
                                Nutzungerecht an den nicht öffentlichen Inhalten. Wir verarbeiten Ihre
                                Daten, um Ihnen dieses Nutzungerecht zur Verfügung stellen zu können und
                                daher gem Art 6 Abs 1 lit b DSGVO. Die Verarbeitung Ihrer Daten erfolgt
                                aber auch im berechtigten Geschäftsinteresse, Kunden der Plattform Services
                                zur Verfügung zu stellen (gem Art 6 Abs 1 lit f DSGVO).
                            </p>
                            <p>
                                Die Speicherdauer der Daten beträgt:
                            </p>
                            <p>
                                Grundsätzlich löschen oder anonymisieren wir Ihre personenbezogenen Daten,
                                sobald sie für die Zwecke, für die wir sie erhoben oder verwendet haben,
                                nicht mehr erforderlich sind. Im Zusammenhang mit Ihrer Registrierung für
                                unseren geschützten Bereich und Ihre Kontonutzung, speichern wir Ihre Daten
                                jedenfalls solange, bis Sie Ihre Registrierung widerrufen, oder wir
                                gesetzlich zur Aufbewahrung dieser Daten verpflichtet sind.
                            </p>
                            <h4>
                                3.1.2 Nutzung von online abrufbaren Diensten
                            </h4>
                            <p>
                                Im registrierten Bereich werden Ihnen die Dienste der Plattform angeboten.
                                Sie können diese beliebig und grundsätzlich auch zu jeder Zeit über Ihr
                                persönliches Konto abrufen. Daneben haben Sie die Möglichkeit, sich für
                                (insgesamt zehn) Live-Events zu registrieren. Die Ihnen auf der Plattform
                                angebotenen Dienste werden gemäß Ihrem Nutzungsverhalten an Ihre
                                individuellen Bedürfnisse angepasst.
                            </p>
                            <p>
                                Für diese Zwecke werden folgende Daten verwendet:
                            </p>
                            <p>
                                Ihre Profildaten, Ihre Buchungs- und Nutzungshistorie der Plattform (inkl
                                statistischer Daten zu Ihren Log-Ins und der Verweildauer der gebuchten
                                oder aufgerufenen Dienste) und alle Angaben, die Sie im Rahmen der Nutzung
                                der Dienste auf der Plattform bekanntgeben.
                            </p>
                            <p>
                                Sofern für die Verwendung eines bestimmten Dienstes auf der Plattform
                                sinnvoll und Sie uns diese Daten freiwillig bekanntgeben, verwenden wir
                                darüber hinaus Ihre Größe, Gewicht, Körperfett, Blutdruck, Glukose,
                                Cholesterinspiegel, Triglyceride, Taillenumfang, Schritte, aktive Minuten,
                                konsumierte Lebensmittel, verbrauchte und verbrannte Kalorien, Dauer
                                bestimmter Übungen, Gehentfernung und Herzfrequenz. Bitte beachten Sie: Bei
                                manchen dieser Daten handelt es sich um Ihre Gesundheitsdaten.
                            </p>
                            <p>
                                Die Verarbeitung Ihrer (Gesundheits)daten ist stets freiwillig. Wenn Sie
                                uns diese Daten zur Verfügung stellen, können wir unser Angebot für Sie
                                personalisieren. Das bedeutet, wir können Ihnen unser Angebot in einer
                                Weise zeigen, wie es Ihren Bedürfnissen und Interessen entspricht.
                            </p>
                            <p>
                                Rechtsgrundlage für die Verarbeitung ist:
                            </p>
                            <p>
                                Wenn Sie ein persönliches Konto anlegen, erhalten Sie ein eingeschränktes
                                Nutzungsrecht an den nicht öffentlichen Inhalten. Wir verarbeiten Ihre
                                Daten, um Ihnen dieses Nutzungsrecht zur Verfügung stellen zu können und
                                daher gem Art 6 Abs 1 lit b DSGVO. Die Verarbeitung Ihrer Daten erfolgt
                                aber auch im berechtigten Geschäftsinteresse, Kunden der Plattform Services
                                zur Verfügung zu stellen (gem Art 6 Abs 1 lit f DSGVO).
                            </p>
                            <p>
                                Die Verarbeitung Ihrer Gesundheitsdaten erfolgt stets freiwillig und
                                ausschließlich nachdem wir Sie gesondert um Ihre Einwilligung gemäß Art 6
                                Abs 1 lit a und Art 9 Abs 2 lit a DSGVO ersucht haben. Sie können eine
                                erteilte Einwilligung jederzeit ohne Angabe von Gründen widerrufen. Bitte
                                beachten Sie, dass im Fall Ihres Widerrufs die Erbringung des gebuchten
                                Dienstes uU nicht möglich ist.
                            </p>
                            <p>
                                Die Speicherdauer der Daten beträgt:
                            </p>
                            <p>
                                Grundsätzlich löschen oder anonymisieren wir Ihre personenbezogenen Daten,
                                sobald sie für die Zwecke, für die wir sie erhoben oder verwendet haben,
                                nicht mehr erforderlich sind. Im Zusammenhang mit Ihrer Registrierung für
                                unseren geschützten Bereich und Ihre Kontonutzung, speichern wir Ihre Daten
                                jedenfalls solange, bis Sie Ihre Registrierung widerrufen, oder wir
                                gesetzlich zur Aufbewahrung dieser Daten verpflichtet sind.
                            </p>
                            <h4>
                                3.1.3 Einen Dienst auf der Plattform buchen
                            </h4>
                            <p>
                                Sie können über diese Plattform Zugang zu gewissen, für Sie
                                maßgeschneiderten Diensten (wie etwa Einzelcoachings, Lebensberatung
                            </p>
                            <p>
                                usw.) erhalten. Von uns erhalten Sie dafür Kontaktdaten (Telefonnummern)
                                von professionellen Dienstleistern. Eine Buchung können Sie direkt mit dem
                                von Ihnen ausgesuchten Dienstleister vereinbaren.
                            </p>
                            <p>
                                Für diese Zwecke werden folgende Daten verwendet:
                            </p>
                            <p>
                                Um Ihnen die Buchung zu ermöglichen, verarbeiten wir Ihre Profildaten und
                                Ihre Buchungshistorie (inkl Anzahl und Verweildauer der gebuchten Dienste).
                            </p>
                            <p>
                                Die Verarbeitung Ihrer (Gesundheits)daten ist erforderlich, damit wir Ihnen
                                den Zugang zu den maßgeschneiderten Diensten ermöglichen können.
                            </p>
                            <p>
                                Rechtsgrundlage für die Verarbeitung ist:
                            </p>
                            <p>
                                Wir verarbeiten Ihre Daten, um Ihre gebuchten Dienste anbieten zu können.
                                Die Verarbeitung erfolgt daher gem Art 6 Abs 1 lit b DSGVO.
                            </p>
                            <p>
                                Die Verarbeitung Ihrer Gesundheitsdaten erfolgt stets freiwillig und
                                ausschließlich nachdem wir Sie gesondert um Ihre Einwilligung gemäß Art 6
                                Abs 1 lit a und Art 9 Abs 2 lit a DSGVO ersucht haben. Sie können eine
                                erteilte Einwilligung jederzeit ohne Angabe von Gründen widerrufen. Bitte
                                beachten Sie, dass im Fall Ihres Widerrufs die Erbringung des gebuchten
                                Dienstes uU nicht möglich ist.
                            </p>
                            <p>
                                Die Speicherdauer der Daten beträgt:
                            </p>
                            <p>
                                Grundsätzlich werden Ihre personenbezogenen Daten anonymisiert oder
                                gelöscht, sobald sie für die Zwecke, für die sie erhoben oder verwendet
                                wurden, nicht mehr erforderlich sind. Im Zusammenhang mit Ihren Buchungen
                                speichern wir Ihre Daten jedenfalls solange, wie dies zur Durchführung
                                Ihrer Buchung notwendig ist, oder Sie Ihre Einwilligung zur
                                Gesundheitsdatenverarbeitung widerrufen, oder wir gesetzlich zur
                                Aufbewahrung dieser Daten verpflichtet sind. In der Regel unterliegen die
                                Daten zu Ihrer Bestellung einer 7-jährigen Aufbewahrungspflicht.
                            </p>
                            <h4>
                                3.1.4 Analysezwecke
                            </h4>
                            <p>
                                Ihre Daten über die Nutzung der auf der Plattform angebotenen Dienste
                                zeigen uns, wie zufrieden Sie mit den angebotenen Diensten sind und wie die
                                Plattform weiterhin verbessert werden kann. Mit diesen Daten werden
                                Statistiken erstellt, um das Nutzerverhalten auf der Plattform auszuwerten
                                und an die Wünsche und Bedürfnisse der Nutzer anpassen zu können.
                            </p>
                            <p>
                                Für diese Zwecke werden folgende Daten verwendet:
                            </p>
                            <p>
                                Technische Daten (IP-Adresse, HTTP-Header-Felder, Bildschirmauflösung und
                                Farbtiefe, Flash-Version, Betriebssystem, Browserversion, etc);
                                Nutzungsdaten (Dauer des Besuchs, Klicks, etc).
                            </p>
                            <p>
                                Rechtsgrundlage für die Verarbeitung ist:
                            </p>
                            <p>
                                Die Optimierung der Plattform liegt in unserem berechtigten Interesse. Die
                                Verarbeitung erfolgt daher gem Art 6 Abs 1 lit f DSGVO.
                            </p>
                            <p>
                                Die Speicherdauer der Daten beträgt:
                            </p>
                            <p>
                                Grundsätzlich werden Ihre personenbezogenen Daten anonymisiert oder
                                gelöscht, sobald sie für die Zwecke, für die sie erhoben oder verwendet
                                wurden, nicht mehr erforderlich sind.
                            </p>
                            <h4>
                                4 E-Mail-Kommunikation
                            </h4>
                            <p>
                                Wenn Sie die Plattform nutzen, senden wir Ihnen von Zeit zu Zeit E-Mails
                                mit Informationen zu Ihrem Konto, den genutzten Diensten und den getätigten
                                Buchungen und Ihren Abonnements.
                            </p>
                            <p>
                                Außerdem informieren wir interessierte Nutzer in unserem E-Mail-Newsletter
                                über neue Angebote auf unserer Plattform, aktuelle Umfragen und sonstige
                                Neuigkeiten im Zusammenhang mit unseren Produkten und Dienstleistungen. Um
                                unseren Newsletter abzubestellen, antworten Sie auf einen unserer
                                Newsletter einfach mit dem Wort "Abbestellen" (ohne Anführungszeichen). Wir
                                werden Ihre Daten dann aus der Empfängerliste löschen.
                            </p>
                            <h4>
                                5 Datenempfänger / Empfängerkategorien
                            </h4>
                            <p>
                                Wir geben Ihre personenbezogenen Daten an Dritte nur weiter, soweit dies
                                zur Erfüllung (vor)vertraglicher oder gesetzlicher Pflichten erforderlich,
                                zur Wahrung unserer berechtigten Interessen gerechtfertigt oder im Rahmen
                                einer erteilten Einwilligung zulässig ist. In allen Fällen, in denen wir
                                Ihre Daten Empfängern weitergeben, tragen wir stets dafür Sorge, dass dies
                                nur auf Basis gesetzlicher Grundlagen erfolgt und sichergestellt ist, dass
                                der Schutz Ihrer Daten gewahrt bleibt.
                            </p>
                            <p>
                                Ihre Daten werden an folgende Empfänger weitergegeben:
                            </p>
                            <p>
                                Dienste-Anbieter: Sofern Sie an den auf der Plattform angezeigten
                                maßgeschneiderten Diensten (wie etwa Einzelcoachings, Lebensberatung usw.)
                                interessiert sind, und Sie in der Folge mit einem Dienstleister in Kontakt
                                treten, werden Ihre Profildaten an den von Ihnen gebuchten
                                (Gesundheits-)dienstleister übermittelt. Diese Datenweitergabe ist zur
                                Vermittlung der Gesundheitsdienstleistung über unsere Plattform
                                erforderlich: Um Ihre Buchung
                            </p>
                            <p>
                                abzuschließen, übermitteln wir die relevanten Buchungsdaten an den von
                                Ihnen gebuchten Dienstleistungsanbieter. Diese Dienstleister verarbeiten
                                Ihre Gesundheitsdaten als selbständige Verantwortliche. Nähere
                                Informationen zum Datenschutz entnehmen Sie bitte den Datenschutzhinweisen
                                der einzelnen Dienstleister.
                            </p>
                            <p>
                                Arbeitgeber: Die Nutzung der Plattform wird Ihnen durch Ihren Arbeitgeber
                                ermöglicht, um einen gesunden Lebensstil zu fördern. Ihr Arbeitgeber
                                bekommt Informationen, dass Sie sich für die Plattform registriert haben
                                und über die Tatsache, dass Sie Dienste gebucht haben. Welchen Dienst Sie
                                konkret gebucht haben, erfährt Ihr Arbeitgeber nicht. Sofern Sie an
                                Live-Events teilnehmen, könnte diese Teilnahme Ihrem unternehmerischen
                                Umfeld bekannt werden (etwa durch die Teilnahme von Kollegen am gleichen
                                Event).
                            </p>
                            <p>
                                Sanus X: Bekommt als Anbieter der Plattform ausschließlich aggregierte und
                                insofern anonymisierte Daten. Dies bedeutet, dass diese Daten nicht mehr zu
                                Ihnen zurückführbar sind. Beispielsweise handelt es sich dabei, um
                                statistische Auswertungen, woran Interesse von einer Mehrheit der Nutzer
                                besteht und welche Dienste kaum von Nutzern aufgerufen werden. Durch diese
                                Daten können die Plattform und das Serviceangebot optimiert werden.
                            </p>
                            <p>
                                Auftragsverarbeiter: Wir sind für die Erbringung unseres Dienstes auf
                                Dienstleister angewiesen, die uns bei der Organisation und Bereitstellung
                                unseres Angebots unterstützen.
                            </p>
                            <h4>
                                6 Datensicherheit und internationaler Datentransfer
                            </h4>
                            <p>
                                Ihre Daten werden von uns grundsätzlich innerhalb der Europäischen Union
                                verarbeitet. Die Spectrum.Life-Server befinden sich innerhalb der
                                Europäischen Union.
                            </p>
                            <p>
                                Der Komplexität heutiger Datenverarbeitungsprozesse ist es jedoch
                                geschuldet, dass wir uns mitunter Dienstleister bedienen und diese mit der
                                Verarbeitung Ihrer Daten beauftragen. Manche dieser Dienstleister befinden
                                sich außerhalb des Gebiets der Europäischen Union bzw. des Europäischen
                                Wirtschaftsraumes. In allen Fällen der Inanspruchnahme von Dienstleistern
                                tragen wir jedoch stets dafür Sorge, dass das europäische Datenschutzniveau
                                und die europäischen Datensicherheitsstandards gewahrt bleiben. Wenn Sie
                                näheres darüber erfahren wollen, wie und in welchem Umfang wir Ihre Daten
                                verarbeiten oder an Dienstleister weitergeben und welche Schutzgarantien
                                wir hierbei ergriffen haben, wenden Sie sich bitte an dem oben angegeben
                                Kontakt.
                            </p>
                            <p>
                                Wir setzen auf technische und organisatorische Maßnahmen, Richtlinien und
                                andere Vorkehrungen, um Ihre personenbezogenen Daten vor unerlaubtem
                                Zugriff, unsachgemäßer Verwendung, Offenlegung, Verlust oder Vernichtung zu
                                schützen. Um die Vertraulichkeit Ihrer personenbezogenen Daten
                                sicherzustellen, benutzen wir am Markt übliche Firewalls und
                                Schutzmechanismen auf der Basis von
                            </p>
                            <p>
                                Passwörtern. Es liegt jedoch in Ihrer eigenen, persönlichen Verantwortung
                                sicherzustellen, dass der von Ihnen verwendete Computer / das von Ihnen
                                verwendete Device angemessen vor bösartiger Software wie Trojanern,
                                Computerviren und "Würmern" gesichert und geschützt ist. Sie sind sich der
                                Tatsache bewusst, dass ohne angemessene Sicherheitsmaßnahmen (wie ein
                                sicher konfigurierter Webbrowser, eine aktuelle Anti-Viren-Software, eine
                                aktuelle Firewall-Software, aber auch der Verzicht auf Software aus
                                zweifelhaften Quellen) das Risiko besteht, dass Daten und Passwörter, die
                                Sie zum Schutz Ihrer Daten verwenden, in die Hände unbefugter Dritter
                                gelangen können.
                            </p>
                            <h4>
                                7 Cookies
                            </h4>
                            <p>
                                Wir verwenden auf der Plattform keine Cookies.
                            </p>
                            <h4>
                                8 Ihre Rechte
                            </h4>
                            <p>
                                Sie können Auskunft zur Herkunft, zu den Kategorien, zur Speicherdauer, zu
                                den Empfängern, zum Zweck der zu Ihrer Person und zu Ihrem Geschäftsfall
                                von uns verarbeiteten Daten und zur Art dieser Verarbeitung verlangen.
                            </p>
                            <p>
                                Falls wir Daten zu Ihrer Person verarbeiten, die unrichtig oder
                                unvollständig sind, so können Sie deren Berichtigung oder Vervollständigung
                                verlangen. Sie können auch die Löschung unrechtmäßig verarbeiteter Daten
                                verlangen. Bitte beachten Sie aber, dass dies nur auf unrichtige,
                                unvollständige oder unrechtmäßig verarbeitete Daten zutrifft. Ist unklar,
                                ob die zu Ihrer Person verarbeiteten Daten unrichtig oder unvollständig
                                sind oder unrechtmäßig verarbeitet werden, so können Sie die Einschränkung
                                der Verarbeitung Ihrer Daten bis zur endgültigen Klärung dieser Frage
                                verlangen. Wir ersuchen Sie zu beachten, dass diese Rechte einander
                                ergänzen, sodass Sie nur entweder die Berichtigung bzw. Vervollständigung
                                Ihrer Daten oder deren Löschung verlangen können. Auch wenn die Daten zu
                                Ihrer Person richtig und vollständig sind und von uns rechtmäßig
                                verarbeitet werden, können Sie der Verarbeitung dieser Daten in besonderen,
                                von Ihnen begründeten Einzelfällen widersprechen. Ebenso können Sie
                                widersprechen, wenn Sie von uns Direkt-Marketing beziehen und diese in
                                Zukunft nicht mehr erhalten möchten.
                            </p>
                            <p>
                                Sie können die von uns zu Ihrer Person verarbeiteten Daten, sofern wir
                                diese von Ihnen selbst erhalten haben, in einem von uns bestimmten,
                                maschinenlesbaren Format erhalten oder uns mit der direkten Übermittlung
                                dieser Daten an einen von Ihnen gewählten Dritten beauftragen, sofern
                                dieser Empfänger uns dies aus technischer Sicht ermöglicht und der
                                Datenübertragung weder ein unvertretbarer Aufwand noch gesetzliche oder
                                sonstige Verschwiegenheitspflichten oder Vertraulichkeitserwägungen von
                                unserer Seite oder von dritten Personen entgegenstehen. Sie haben das Recht
                                keiner ausschließlich auf einer automatisierten Verarbeitung basierenden
                                Entscheidung unterworfen zu werden. Wir verwenden keine automatisierte
                                Entscheidungsfindung ohne Ihre vorherige Einwilligung.
                            </p>
                            <p>
                                Bei all Ihren Anliegen ersuchen wir Sie uns unter den obenstehend
                                ausgewiesenen Kontaktdaten zu kontaktieren, wobei wir Sie hierbei stets um
                                einen Beleg Ihrer Identität, etwa durch Übermittlung einer elektronischen
                                Ausweiskopie, ersuchen.
                            </p>
                            <p>
                                Auch wenn wir uns bestmöglich um den Schutz und die Integrität Ihrer Daten
                                bemühen, können Meinungsverschiedenheiten über die Art, wie wir Ihre Daten
                                verwenden, nicht ausgeschlossen werden. Sind Sie der Ansicht, dass wir Ihre
                                Daten in nicht zulässiger Weise verwenden, so steht Ihnen das Recht auf
                                Beschwerdeerhebung bei der österreichischen Datenschutzbehörde offen.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer v-if="$store.state.user.authenticated"></Footer>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import TopNav from "@/components/TopNav.vue";
    import MobileNav from "@/components/MobileNav.vue";
    import Footer from "../components/Footer";
    export default {
        name: "Privacy",
        components: {
            Footer,
            TopNav,
            MobileNav,
        },
        data() {
            return {

            };
        },
        mounted() {
            if(this.$route.query.lang) {
                this.$store.commit('setLanguage', this.$route.query.lang)
            }
        },
        methods: {
        },
        computed: {
            componentText() {
                return this.getText.privacy;
            },
        },
        watch:{
            skin(newVal){
                this.$store.commit("setSkin", newVal)
            }
        }
    };
</script>
<style lang="less" scoped>
    #settings {
        font-size: 1.6rem;
        font-family: "DMSans", sans-serif;
        .content-wrap{
            background: #f4f4f4;
            .content-card{
                transform: translateY(-80px);
                background: #ffffff;
                max-width: 1000px;
                margin: auto;
            }
        }
        h4, strong{
            font-family: FilsonProBold, sans-serif;
        }
        h4{
            margin: 20px 0 10px 0;
        }
        @media (max-width: 992px){
            .page-header{
                padding-top: 100px;
            }
            .content-card {
                transform: translateY(0);
            }
        }
    }
</style>
